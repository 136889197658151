<template>
  <section v-if="serviceCenterLandingPage && serviceCenterLandingPage.publication" class="faqs">
    <div class="row faqs__content faqs__search">
      <div class="col-lg-6 col-md-6 col-xs-6">
        <m-input
          type="text"
          label="Wat zoekt u?"
          position="top"
          placeholder="Type het onderwerp van uw vraag.."
          :value="searchText"
          :model="searchText"
          @input="searchInput($event.target.value)"
          @keyup.enter="searchFaqs"
        />
      </div>
      <div class="grid-align-start">
        <m-button icon-name="search" icon-position="start" class="searchBtn" @click="searchFaqs" />
      </div>
    </div>
    <div v-if="detailsBlock" class="row">
      <div class="grid-align-start faqs__padding">
        <m-button inverted icon-name="chevron-left" icon-position="start" @click="backToLandingPage">
          <template v-slot:text>Terug</template>
        </m-button>
      </div>
      <div v-if="selectedFaq.name" class="col-md-12 faqs__padding">
        <h2>{{ selectedFaq.name }}</h2>
        <span class="col-md-12 content" v-html="selectedFaq.content" />
      </div>
    </div>
    <div v-if="showFaqs" class="row faqs__content">
      <v-heading v-if="detailsBlock && selectedFaq.name" class="col-md-12" :level="3"> Gerelateerde vragen </v-heading>
      <div class="col-md-12">
        <h2 v-if="detailsBlock && collectionDetails && !selectedFaq.name">Categorie: {{ selectedCollection.name }}</h2>
      </div>
      <div class="col-md-12">
        <h2 v-if="detailsBlock && showSearchResults && !selectedFaq.name">Zoekresultaten voor ‘{{ searchText }}’</h2>
      </div>

      <div v-for="question in computedFaqs" :key="question.id" class="col-lg-6 col-md-6 col-xs-6">
        <div class="grid-align-start">
          <m-button inverted icon-name="thumb-tack" icon-position="start" @click="goToDetails(question)">
            <template v-slot:text>{{ question.name }}</template>
          </m-button>
        </div>
      </div>
    </div>
    <div v-if="showCollections" class="row faqs__content">
      <v-heading class="col-md-12" :level="3"> Onderwerpen </v-heading>
      <div
        v-for="(collection, index) in serviceCenterLandingPage.publication.collections"
        :key="index"
        class="grid-align-start collections"
      >
        <m-button emphasis link @click="goToCollectionDetails(collection, $event)">
          <template v-slot:text>{{ collection.name }}</template>
        </m-button>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'Faqs',
  components: {},
  setup() {
    const store = useStore();

    const serviceCenterLandingPage = computed(() => store.getters['patient/getServiceCenterDetails']);
    const serviceCenterSearchDetails = computed(() => store.getters['patient/getServiceCenterSearchDetails']);
    const serviceCenterRelatedQuestions = computed(() => store.getters['patient/getServiceCenterRelatedQuestions']);

    const fetchServiceCenterDetails = () => store.dispatch('patient/fetchServiceCenterDetails');
    const fetchServiceCenterRelatedFaqs = (value) => store.dispatch('patient/fetchServiceCenterRelatedFaqs', value);
    const fetchServiceCenterSearchFaqs = (value) => store.dispatch('patient/fetchServiceCenterSearchFaqs', value);

    return {
      serviceCenterLandingPage,
      serviceCenterSearchDetails,
      serviceCenterRelatedQuestions,
      fetchServiceCenterDetails,
      fetchServiceCenterRelatedFaqs,
      fetchServiceCenterSearchFaqs
    };
  },
  data() {
    return {
      searchText: '',
      showFaqs: true,
      showCollections: true,
      detailsBlock: false,
      collectionDetails: false,
      computedFaqs: [],
      selectedCollection: {},
      selectedFaq: {},
      showSearchResults: false
    };
  },
  created() {
    this.fetchServiceCenterDetails().then(() => {
      if (this.serviceCenterLandingPage && this.serviceCenterLandingPage.publication) {
        this.loadComputedFaqs();
      }
    });
  },
  methods: {
    loadComputedFaqs() {
      this.computedFaqs = Object.assign([], this.serviceCenterLandingPage.publication.mostpopular);
      if (this.detailsBlock) {
        this.computedFaqs = Object.assign([], this.serviceCenterRelatedQuestions);
        if (this.selectedFaq.name && this.showSearchResults) {
          this.computedFaqs = Object.assign([], this.serviceCenterSearchDetails);
        }
        if (this.selectedFaq.name && this.selectedCollection.name && this.collectionDetails) {
          this.computedFaqs = Object.assign([], this.selectedCollection.mostpopularItems);
        }
      }
    },
    goToDetails(question) {
      this.fetchServiceCenterRelatedFaqs({ articleId: question.id, collectionId: question.collections[0].id }).then(
        () => {
          this.detailsBlock = true;
          this.loadComputedFaqs();
          this.selectedFaq = question;
          this.showCollections = false;
        }
      );
    },
    backToLandingPage() {
      this.detailsBlock = true;
      this.showCollections = false;
      if (this.selectedFaq.name && this.showSearchResults) {
        this.loadComputedFaqs();
        this.collectionDetails = false;
        this.selectedFaq = {};
      } else if (this.selectedFaq.name && this.selectedCollection.name && this.collectionDetails) {
        this.loadComputedFaqs();
        this.collectionDetails = true;
        this.selectedFaq = {};
      } else {
        this.detailsBlock = false;
        this.showCollections = true;
        this.showSearchResults = false;
        this.loadComputedFaqs();
        this.collectionDetails = false;
        this.selectedCollection = {};
        this.selectedFaq = {};
      }
    },
    goToCollectionDetails(collection, event) {
      event.preventDefault();
      this.selectedCollection = collection;
      this.computedFaqs = Object.assign([], collection.mostpopularItems);
      this.detailsBlock = true;
      this.collectionDetails = true;
      this.showCollections = false;
    },
    searchFaqs() {
      if (!this.searchText) {
        return;
      }
      this.fetchServiceCenterSearchFaqs(this.searchText).then(() => {
        if (this.serviceCenterSearchDetails) {
          this.computedFaqs = Object.assign([], this.serviceCenterSearchDetails);
        }
      });
      this.selectedFaq = {};
      this.collectionDetails = false;
      this.detailsBlock = true;
      this.showSearchResults = true;
      this.showCollections = false;
    },
    searchInput(value) {
      this.searchText = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.faqs {
  &__content {
    padding: 16px 24px 24px 24px;
  }
  &__search {
    background-color: $aqua;
  }
  &__padding {
    padding: 16px 24px 0 24px;
  }
  &__content {
    color: #143344;
    font-size: map-get(map-get($type-settings, 0), font-size);
    line-height: map-get(map-get($type-settings, 0), line-height);
  }
  .searchBtn {
    margin: 33px 12px 12px 12px;
  }
  .collections {
    margin: 0 24px 24px 0;
  }
  .content {
    :deep(p) {
      overflow-x: auto;
    }
  }
}
</style>
