import axios from 'axios'
export default {
  namespaced: true,
  state: {
    newsItem: {},
    newsItems: [],
    latestFeaturedNewsItem: {}
  },
  mutations: {
    setNewsItem (state, payload) {
      state.newsItem = payload
    },
    setNewsItems (state, payload) {
      state.newsItems = payload
    },
    setLatestFeaturedNewsItem (state, payload) {
      state.latestFeaturedNewsItem = payload
    }
  },
  actions: {
    fetchNewsItem ({commit, state}, Id) {
      return axios.get('/News/Item/' + Id).then((response) => {
        commit('setNewsItem', response.data.NewsDetails)
      })
    },
    fetchNewsItems ({commit, state}, limit) {
      return axios.get('/News/list', {params: {max: limit}}).then((response) => {
        commit('setNewsItems', response.data.NewsList)
      })
    },
    fetchLatestFeaturedNewsItem ({commit, state}, limit) {
      return axios.get('/News/GetLatestFeaturedNews').then((response) => {
        commit('setLatestFeaturedNewsItem', response.data.News)
      })
    }
  },
  getters: {
    getNewsItem: state => {
      return state.newsItem
    },
    getNewsItems: state => {
      return state.newsItems
    },
    getLatestFeaturedNewsItem: state => {
      return state.latestFeaturedNewsItem
    }
  }
}
