<template>
  <div class="product-variants">
    <table>
      <colgroup>
        <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
      </colgroup>
      <thead>
        <tr>
          <th v-if="headerVisibleStatus" />
          <th>Artikelcode</th>
          <th>Omschrijving</th>
          <th>Verpakking</th>
          <th v-if="isCustomerFlow || isBundle" class="product-variants__center">Vergoed</th>
          <th v-if="isBundle" class="product-variants__center">Aantal*</th>
          <th v-if="!isReplenishmentFlow" class="product-variants__center">Favoriet</th>
          <th v-if="!isBundle && !isAssortmentFlow" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(productVariant, index) in productVariants" :key="index">
          <td v-if="productVariant.imageUrl !== ''">
            <div class="product-variants__imgWrapper">
              <img :src="productVariant.imageUrl" />
            </div>
          </td>
          <td :class="{ clickable: isBundle }" @click="goToBaseProductDetailsPage(productVariant.baseProductId)">
            {{ productVariant.productCode }}
          </td>
          <td>{{ productVariant.title }}</td>
          <td>{{ productVariant.packagingUnit }} {{ productVariant.packagingLabel }}</td>
          <td v-if="isCustomerFlow || isBundle" class="product-variants__center">
            <v-clipboard-alert-icon
              v-if="!productVariant.isInsured"
              class="not-insured"
              title="Niet vergoed"
            /><v-clipboard-check-icon v-if="productVariant.isInsured" class="is-insured" title="Vergoed" />
          </td>
          <td v-if="isBundle" class="product-variants__center">
            {{ productVariant.amount }}
          </td>
          <td
            v-if="!isReplenishmentFlow"
            id="favoriteIcon"
            class="product-variants__center"
            @click="favorite(productVariant, baseProduct)"
          >
            <component
              :is="getEnumValue(favoriteStatusEnum, String(productVariant.isFavourite))"
              class="favorite-icon"
            />
          </td>
          <td v-if="!isBundle && productVariant.orderable && !isAssortmentFlow">
            <v-button md cta class="product-variants__order" @click.prevent="order(productVariant.productId, count)">
              Bestellen
            </v-button>
          </td>
          <td v-if="!isBundle && !productVariant.orderable && !isAssortmentFlow">Niet bestelbaar</td>
        </tr>
      </tbody>
    </table>
    <v-overlay v-if="showProductFavourites">
      <v-product-favourites
        :base-product="selectedBaseProductForFavorite"
        :product="selectedProductForFavorite"
        :product-id="productId"
        @success="successSaveFavorite(...arguments)"
        @cancel="closeOverlay"
      />
    </v-overlay>
  </div>
</template>

<script>
import VClipboardCheckIcon from 'icons/ClipboardCheck';
import VClipboardAlertIcon from 'icons/ClipboardAlert';
import VHeartIcon from 'icons/Heart';
import VHeartOutlineIcon from 'icons/HeartOutline';
import VProductFavourites from './ProductFavourites';
import router from '@/router';

export default {
  name: 'ProductVariants',
  components: {
    VHeartIcon,
    VHeartOutlineIcon,
    VProductFavourites,
    VClipboardCheckIcon,
    VClipboardAlertIcon
  },
  props: {
    productVariants: Array,
    isBundle: {
      type: Boolean,
      default: false
    },
    baseProduct: {
      type: Object
    }
  },
  data() {
    return {
      deliveryStatusClassEnum: {
        0: 'green',
        1: 'yellow',
        2: 'red'
      },
      favoriteStatusEnum: {
        false: 'v-heart-outline-icon',
        true: 'v-heart-icon'
      },
      count: 1,
      showProductFavourites: false,
      productId: null,
      bpId: '',
      selectedProductForFavorite: null,
      selectedBaseProductForFavorite: null
    };
  },
  computed: {
    headerVisibleStatus() {
      let status = false;
      for (let i = 0; i < this.productVariants.length; i++) {
        if (this.productVariants[i].imageUrl !== '') {
          status = true;
          break;
        }
      }
      return status;
    },
    isReplenishmentFlow() {
      return router.currentRoute.value.meta.flow === 'replenishment';
    },
    isAssortmentFlow() {
      return router.currentRoute.value.meta.flow === 'assortment';
    },
    isCustomerFlow() {
      return router.currentRoute.value.meta.flow === 'customer';
    }
  },
  methods: {
    /**
     * @function getEnumValue
     * @param {object} - enum list
     * @param {string} - enum key
     * @returns {any} - value that matches the key
     */
    getEnumValue(enumList, enumKey) {
      if (enumKey === 'null' || enumKey === '') {
        return enumList.false;
      }
      for (const key in enumList) {
        if (enumList.hasOwnProperty(key)) {
          if (key === enumKey) {
            return enumList[key];
          }
        }
      }
    },
    favorite(product, baseProduct) {
      const productId = product.productId;

      this.selectedProductForFavorite = product;
      this.selectedBaseProductForFavorite = baseProduct;
      this.productId = productId;
      this.showProductFavourites = true;
    },
    order(productId, count) {
      this.$emit('order', { productId, count });
    },
    successSaveFavorite(isAdded) {
      this.selectedProductForFavorite = null;
      this.selectedBaseProductForFavorite = null;
      this.productId = null;
      this.closeOverlay();
      this.$emit('favouriteSuccess');
    },
    closeOverlay() {
      this.showProductFavourites = false;
    },
    goToBaseProductDetailsPage(baseProductId) {
      if (baseProductId && this.isBundle) {
        router.push('/catalog/baseProduct/' + baseProductId);
      }
    }
  }
};
</script>
<style land="css">
.product-variants .overlay > div {
  overflow-y: auto;
  max-height: 500px;
  min-width: 800px;
  padding: 2rem !important;
}
</style>

<style lang="scss" scoped>
.product-variants {
  // Width of each table column
  $table-columns: (
    1: 10%,
    2: 80%,
    3: 5%,
    4: 5%
  ) !default;

  table {
    table-layout: auto;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 20px;

    @include min-screen(grid-breakpoint(md)) {
      table-layout: auto;
    }

    // Generate classes for each column and assign width
    @each $table-column-key, $table-column-value in $table-columns {
      .table-col-#{$table-column-key} {
        width: $table-column-value;
      }
    }

    > thead > tr {
      background: $teal-lighter;
      > td,
      > th {
        font-size: 16px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 15px 5px 15px 15px;
        text-align: left;
      }
    }

    > tbody {
      background: $white;

      > tr:nth-child(2n) {
        background: $grayish-cyan-light;
      }

      > tr {
        td:first-child {
          color: $teal-darkest;
        }
        > td,
        > th {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $gray-darkest;
          padding: 15px 5px 15px 15px;
        }
      }
      .favorite-icon {
        fill: $cyan-dark;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
  &__center {
    text-align: center !important;
    .material-design-icon {
      display: inline-block;
    }
  }
  &__order {
    float: right;
  }
  &__imgWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    img {
      max-height: 50px;
      max-width: 50px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }
  .is-insured {
    margin-right: 10px;
    fill: $greenish;
  }
  .not-insured {
    margin-right: 10px;
    fill: $red;
  }
  .clickable {
    cursor: pointer;
  }
}
</style>
