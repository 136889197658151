<template>
  <div class="patientMeasurements">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="3">
          {{ measurements.title }}
        </v-heading>
      </div>
      <div class="col-xs-12 col-md-6">
        <v-button-dropdown
          :options="measurementTypes"
          :button-props="{ hollow: true, inverted: true, alt: true, md: true }"
          class="patientMeasurements__button"
        >
          <v-plus-circle-icon class="patientMeasurements__icon" />{{ measurements.buttonLabel }}
        </v-button-dropdown>
      </div>
    </div>
    <!-- <div class="row grid-align-center grid-padding callout" v-if="measurements.data.rows.length === 0">
      <div class="col-xs-8"> Er is geen pomp Meetmomenten.</div>
    </div> -->
    <v-table>
      <thead>
        <v-header-row :data="measurements.header" />
      </thead>
    </v-table>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import VPlusCircleIcon from 'icons/PlusCircle'
import VTable from '@/components/shared/table/Table'
import VHeaderRow from '@/components/shared/table/rows/HeaderRow'
import VButtonDropdown from '@/components/shared/ButtonDropdown'
export default {
  name: 'VPatientMeasurements',
  components: {
    VPlusCircleIcon,
    VTable,
    VHeaderRow,
    VButtonDropdown
  },
  setup() {
    const store = useStore();

    const measurementTypes = computed(() => store.getters['patient/getMeasurementTypes']);
    const measurements = computed(() => store.getters['patient/getMeasurements']);

    return {
      measurementTypes,
      measurements
    }
  },
  data () {
    return {}
  }
}
</script>

<style land="css">
  .patientMeasurements .patientMeasurements__infoIcon .material-design-icon__svg {
    width: 35px !important;
    height: 35px !important;
    fill: #148c78 !important;
  }
  .patientMeasurements .patientMeasurements__closeIcon .material-design-icon__svg {
    fill: #148c78 !important;
  }
  .patientMeasurements .form-group__icon {
    background-color: #fafafa;
    border-radius: 4px;
    height: 30px;
  }
  .patientMeasurements .select-simple .form-group__icon {
    top: calc(1% - 0px);
  }
  .patientMeasurements .select-simple select {
    border-radius: 4px;
    height: 30px;
  }
  .patientMeasurements .ui.fluid.dropdown > .dropdown.icon {
    width: 35px;
    height: 40px;
    margin-right: -14px;
    border-radius: 4px;
    background-color: #fafafa;
  }
  .patientMeasurements .ui.fluid.search.selection.dropdown:hover, .patientMeasurements .ui.fluid.search.selection.dropdown.active.visible {
    border-color: #006983;
  }
</style>

<style lang="scss" scoped>
.patientMeasurements {
  margin-bottom: 24px;

  &__infoIcon {
    padding: 20px;
    float: left;
  }

  &__closeIcon {
    float: right;
    margin-top: -35px;
    cursor: pointer;  
  }

  &__button {
    @include max-screen(grid-breakpoint(md)) {
      margin-bottom: 1rem;
      align-self: flex-start;
    }

    @include min-screen(grid-breakpoint(md)) {
      align-self: flex-end;
    }
    z-index: 975 !important;
  }
    
  &__icon {
    margin-right: 5px;
  }

  .patientMeasurements__table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 32px;
    text-align: left;
    > thead > tr {
      background-color: $table-header-background;

      > td, > th {
        vertical-align: baseline;
        text-align: left;
        padding: 10px;
        color: $gray-darkest;
        font-size: type-scale(0);
        line-height: line-height(0);
        font-weight: 900;
      }
    }
  }
}
</style>
