<template>
  <div
    class="form-group"
    :class="{
      'form-group--focused': isFocused,
      'form-group--hasvalue': value,
      'form-group--disabled': disabled,
      'form-group--error': error
    }"
  >
    <label v-if="label" :for="selectid">
      {{ label }}
      <span v-if="rule.required && label" class="asterisk"> *</span>
    </label>

    <div class="form-group__input">
      <select
        :id="selectid"
        v-model="value"
        :name="selectid"
        :disabled="disabled"
        @focus="focus"
        @blur="blur"
        @change="change"
      >
        <option :value="undefiend" disabled v-if="placeholder">{{ placeholder }}</option>
        <slot>
          <option v-for="(option, index) in options" :key="index" :value="option.value">
            {{ option.title }}
          </option>
        </slot>
      </select>
    </div>
    <div v-if="errorMessage" class="form-group__details">
      <div class="form-group__messages">
        <div class="form-group__error"><v-error-icon></v-error-icon> {{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { generateYupSchema } from '@/composables/globalHelperFunctions';
import { ref, watch, onBeforeMount } from 'vue';
import VErrorIcon from 'icons/AlertCircle';

export default {
  name: 'VSelectGroup',
  components: {
    VErrorIcon
  },
  props: {
    /**
     * @member {array} options
     * @type {object} option
     * @property {number} option.id
     * @property {string} option.title
     */
    options: {
      type: Array
    },
    disabled: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    initselected: {
      type: [Number, String, Boolean]
    },
    selectid: {
      default: '',
      type: String
    },
    error: {
      default: false,
      type: Boolean
    },
    rule: {
      default: () => ({ required: false }),
      type: Object
    },
    overwriteValue: {
      type: [Number, String, Boolean]
    },
    placeholder: {
      type: String
    }
  },
  setup(props) {
    const schema = ref(generateYupSchema({ label: props.label ? props.label : null, rules: props.rule }));
    const { value, errorMessage, validate } = useField(props.selectid, schema);
    watch(
      () => [props.overwriteValue, props.rule],
      ([overwriteValue, rule]) => {
        schema.value = generateYupSchema({ label: props.label ? props.label : null, rules: rule });
        if (overwriteValue) {
          value.value = overwriteValue;
        }
      }
    );

    onBeforeMount(() => {
      if (props.initselected) {
        value.value = props.initselected;
      }
      if (typeof props.initselected === 'boolean') {
        value.value = props.initselected;
      }
    });

    return {
      value,
      errorMessage,
      validate
    };
  },
  data() {
    return {
      isFocused: false
    };
  },
  methods: {
    focus() {
      this.isFocused = true;
    },
    blur() {
      this.isFocused = false;
      this.validate();
      this.$emit('blur');
    },
    change(e) {
      if (e.target.value) {
        this.$emit('change', e);
        this.validate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  $root: &;
  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
  .form-group__details:before {
    background-image: none;
    content: none;
  }
  &.form-group--hasvalue label {
    padding: 0px;
    transform: translate3d(0, -25px, 0) scale(0.75);
  }
  &.form-group--focused label {
    padding: 0px;
    transform: translate3d(0, -25px, 0) scale(0.75);
  }
  &.form-group--disabled {
    background: $gray-lighter;
    border-radius: 8px;
    label {
      padding: 5px 0px 0px 5px;
      transform: translate3d(0, -25px, 0) scale(0.75);
    }
    select {
      padding: 0px 20px 0px 10px;
      opacity: 1;
      pointer-events: none !important;
      background-color: $gray-lighter;
      border-color: $grayish-lighter;
    }
  }
  #{$root}__details {
    &--hidden {
      min-height: auto;
    }
  }
  label {
    padding: 10px 0px 0px 5px;
    height: 40px;
    font-size: 14px;
    padding-top: 17px;
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    min-height: 50px;
    color: $gray-dark;
    padding-left: 10px;
    border: solid 1px;
    border-color: $gray-lighter;
    border-radius: 3px;
    transition: opacity 0.2s ease-in-out;
    box-sizing: border-box;
    margin: 0px;
  }
}

select::-ms-expand {
  display: none; // ie remove arrow
}
</style>
