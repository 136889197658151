<template>
  <div class="registrationDepartmentDetails row grid-align-center">
    <v-delete-icon
      v-if="showDelete"
      class="registrationDepartmentDetails__delete"
      @click.prevent="deleteDepartment()"
    />
    <div class="col-md-6 registrationDepartmentDetails__block">
      <div class="registrationDepartmentDetails__block__header">
        {{ componentTexts.title }}
      </div>
      <div class="registrationDepartmentDetails__block__content">
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-6">
            <v-input-group-simple
              :reference="formConfig.name.alias"
              v-model="formConfig.name.model"
              :initvalue="formConfig.name.model"
              :rule="formConfig.name.rule"
              :inputid="formConfig.name.ref + parentIndex + index"
              :placeholder="componentTexts.namePlaceholder"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-6">
            <v-input-group-simple
              :reference="formConfig.email.alias"
              v-model="formConfig.email.model"
              :initvalue="formConfig.email.model"
              :rule="formConfig.email.rule"
              :inputid="formConfig.email.ref + parentIndex + index"
              :placeholder="componentTexts.emailPlaceholder"
              @blur="validateField"
            />
          </div>
        </div>
        <div
          v-if="!prescriberRegistrationDetails.showRoleSelection"
          class="row grid-padding registrationDepartmentDetails__block__content__section"
        >
          <div class="col-xs-12">
            <label>Vul hier uw depotnummer in (indien van toepassing):</label>
          </div>
          <div class="col-xs-6">
            <v-input-group-simple
              :reference="formConfig.depotNumber.alias"
              v-model="formConfig.depotNumber.model"
              :initvalue="formConfig.depotNumber.model"
              :inputid="formConfig.depotNumber.ref + parentIndex + index"
              placeholder="Depotnummer (18******)"
              @blur="validateField"
            />
          </div>
        </div>
      </div>
      <div v-if="showWarranterQuestion" class="registrationDepartmentDetails__block__warranter">
        <label>Werkt u op deze afdeling onder volmacht?*</label>
        <span class="registrationDepartmentDetails__block__warranter__radio form-group__radio">
          <input
            :id="'warranterTrue' + parentIndex + index"
            :checked="departmentDetails.isAnswered === true"
            :name="'warranter' + parentIndex + index"
            type="radio"
            value="true"
            @change="checkWarranterAnswer(true)"
          />
          <label class="warranter-label" :for="'warranterTrue' + parentIndex + index"><span></span>Ja</label>
        </span>
        <span class="registrationDepartmentDetails__block__warranter__radio form-group__radio">
          <input
            :id="'warranterFalse' + parentIndex + index"
            :checked="departmentDetails.isAnswered === false"
            :name="'warranter' + parentIndex + index"
            type="radio"
            value="false"
            @change="checkWarranterAnswer(false)"
          />
          <label class="warranter-label" :for="'warranterFalse' + parentIndex + index"><span></span>Nee</label>
        </span>
      </div>
      <div
        v-if="departmentDetails.showSearchWarranterDetails"
        class="registrationDepartmentDetails__block__warranterDetails"
      >
        <div class="registrationDepartmentDetails__block__header">Gegevens volmachtgever</div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-6">
            <v-input-group-simple
              :reference="formConfig.searchEmail.alias"
              :error="formConfig.searchEmail.error"
              :error-message="formConfig.searchEmail.errorMessage"
              :rule="formConfig.searchEmail.rule"
              :inputid="formConfig.searchEmail.ref + parentIndex + index"
              placeholder="E-mailadres volmachtgever*"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-6">
            <v-button
              lg
              secondary
              class="searchemail_text"
              @click.prevent="getWarranterDetails(formConfig.searchEmail.ref, parentIndex, index)"
            >
              <img class="magnify-scan" src="@/static/img/magnify-scan.svg" />Controleer e-mailadres
            </v-button>
          </div>
        </div>
      </div>
      <div
        v-if="departmentDetails.showWarranterDetails && !departmentDetails.showSearchWarranterDetails"
        class="registrationDepartmentDetails__block__warranterDetails"
      >
        <div class="registrationDepartmentDetails__block__header">Gegevens volmachtgever</div>
        <div
          v-if="departmentDetails.warranterSuccessMessage === true"
          class="row grid-align-center"
          :class="{ 'flipInX animated': animated }"
        >
          <div class="col-md-2 registrationDepartmentDetails__successMessage">
            <v-check-circle-icon class="registrationDepartmentDetails__successMessage__icon" />
          </div>
          <div class="col-md-10 registrationDepartmentDetails__successMessage">
            <span>
              E-mailadres geverifieerd. Uw volmachtgever ontvangt
              <br />
              per mail een bevestiging van deze volmachtaanvraag.
            </span>
          </div>
        </div>
        <div
          v-if="!departmentDetails.warranterSuccessMessage"
          class="row grid-align-center registrationDepartmentDetails__warningMessage"
          :class="{ 'flipInX animated': animated }"
        >
          <div class="col-md-12">
            <v-warning-message
              :warning="'Let op!'"
              :message="'Dit e-mailadres van uw volmachtgever is niet bij ons bekend. Om uw account te kunnen activeren dient uw volmachtgever zich tevens te hebben geregistreerd en bij ons bekend te zijn met het door uw ingevoerde e-mailadres.'"
              :html="true"
              :is-urgent="false"
            />
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4 registrationDepartmentDetails__block__content__section__gender">
            <span class="form-group__radio">
              <input
                :id="'prescriberGenderMale' + parentIndex + index"
                :checked="departmentDetails.prescriberTitleId === 1"
                :name="'gender' + parentIndex + index"
                type="radio"
                value="De heer"
                @change="checkRadioAnswer(1)"
              />
              <label :for="'prescriberGenderMale' + parentIndex + index"><span></span>De heer</label>
            </span>
            <span class="form-group__radio">
              <input
                :id="'prescriberGenderFemale' + parentIndex + index"
                :checked="departmentDetails.prescriberTitleId === 2"
                :name="'gender' + parentIndex + index"
                type="radio"
                value="Mevrouw"
                @change="checkRadioAnswer(2)"
              />
              <label :for="'prescriberGenderFemale' + parentIndex + index"><span></span>Mevrouw</label>
            </span>
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4">
            <v-input-group-simple
              :reference="formConfig.prescriberInitials.alias"
              v-model="formConfig.prescriberInitials.model"
              :disabled="departmentDetails.warranterSuccessMessage"
              :initvalue="formConfig.prescriberInitials.model"
              :rule="formConfig.prescriberInitials.rule"
              :inputid="formConfig.prescriberInitials.ref + parentIndex + index"
              placeholder="Voorletters*"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple
              :reference="formConfig.prescriberPrePosition.alias"
              v-model="formConfig.prescriberPrePosition.model"
              :disabled="departmentDetails.warranterSuccessMessage"
              :initvalue="formConfig.prescriberPrePosition.model"
              :rule="formConfig.prescriberPrePosition.rule"
              :inputid="formConfig.prescriberPrePosition.ref + parentIndex + index"
              placeholder="Tussenvoegsel"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple
              :reference="formConfig.prescriberLastName.alias"
              v-model="formConfig.prescriberLastName.model"
              :disabled="departmentDetails.warranterSuccessMessage"
              :initvalue="formConfig.prescriberLastName.model"
              :rule="formConfig.prescriberLastName.rule"
              :inputid="formConfig.prescriberLastName.ref + parentIndex + index"
              placeholder="Achternaam*"
              @blur="validateField"
            />
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4">
            <v-input-group-simple
              v-model="formConfig.prescriberEmail.model"
              :disabled="showWarranterDetails"
              :initvalue="formConfig.prescriberEmail.model"
              :rule="formConfig.prescriberEmail.rule"
              :inputid="formConfig.prescriberEmail.ref + parentIndex + index"
              placeholder="E-mailadres volmachtgever*"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple
              :reference="formConfig.prescriberPhoneNumber.alias"
              v-model="formConfig.prescriberPhoneNumber.model"
              :disabled="departmentDetails.warranterSuccessMessage"
              :initvalue="formConfig.prescriberPhoneNumber.model"
              :rule="formConfig.prescriberPhoneNumber.rule"
              :inputid="formConfig.prescriberPhoneNumber.ref + parentIndex + index"
              placeholder="Telefoonnummer volmachtgever*"
              @blur="validateField"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple
              :reference="formConfig.prescriberBigCode.alias"
              v-model="formConfig.prescriberBigCode.model"
              :disabled="departmentDetails.warranterSuccessMessage"
              :initvalue="formConfig.prescriberBigCode.model"
              :rule="formConfig.prescriberBigCode.rule"
              :inputid="formConfig.prescriberBigCode.ref + parentIndex + index"
              placeholder="BIG-nummer*"
              @blur="validateField"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate';
import VDeleteIcon from 'icons/Delete';
import { useStore } from 'vuex';
import { computed } from 'vue';
import $ from 'jquery';
import VCheckCircleIcon from 'icons/CheckCircle';
import VWarningMessage from '@/components/shared/WarningMessage';

export default {
  name: 'VRegistrationDepartmentDetails',
  components: {
    VDeleteIcon,
    VCheckCircleIcon,
    VWarningMessage
  },
  props: {
    departmentDetails: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    parentIndex: {
      type: Number,
      required: true
    },
    showDelete: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const { validate } = useForm();
    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const warranterSuccessMessage = computed(() => store.getters['prescriberRegistration/getWarranterSuccessMessage']);

    const fetchWarranterDetails = (value) => store.dispatch('prescriberRegistration/fetchWarranterDetails', value);
    const setPrescriberRegistrationDepartmentDetails = (value) =>
      store.commit('prescriberRegistration/setPrescriberRegistrationDepartmentDetails', value);
    const deleteDepartmentDetails = (value) => store.commit('prescriberRegistration/deleteDepartmentDetails', value);

    return {
      prescriberRegistrationDetails,
      warranterSuccessMessage,
      fetchWarranterDetails,
      setPrescriberRegistrationDepartmentDetails,
      deleteDepartmentDetails
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      formConfig: {},
      showWarranterQuestion: true,
      showWarranterDetails: true,
      showSearchWarranterDetails: true,
      animated: false,
      isAnswered: false
    };
  },
  computed: {
    componentTexts() {
      return !this.prescriberRegistrationDetails.showRoleSelection
        ? { title: 'Afdeling', namePlaceholder: 'Afdelingsnaam*', emailPlaceholder: 'Uw eigen zakelijke mailadres*' }
        : { title: 'Locatie', namePlaceholder: 'Locatienaam*', emailPlaceholder: 'Zakelijke e-mailadres*' };
    }
  },
  watch: {
    showWarranterDetails(value) {
      if (value) {
        this.extendFormConfig();
        this.showSearchWarranterDetails = false;
        if (this.departmentDetails.PrescriberEmail !== null) {
          this.successMessage = true;
          this.animated = true;
          setTimeout(() => {
            this.animated = false;
          }, 2000);
        }
      } else if (!this.showSearchWarranterDetails) {
        this.resetFormConfigValues();
      }
    }
  },
  created() {
    this.formConfig = {
      name: {
        model: this.departmentDetails.name,
        ref: 'name',
        label: 'Afdelingsnaam* ',
        attached: true,
        alias: 'Afdelingsnaam',
        rule: {
          required: true
        },
        error: false
      },
      email: {
        model: this.departmentDetails.email,
        ref: 'email',
        label: 'E-mail afdeling*',
        attached: true,
        alias: 'E-mail afdeling',
        rule: { required: true, email: true, regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/ },
        error: false
      },
      depotNumber: {
        model: this.departmentDetails.depotNumber,
        ref: 'depotNumber',
        label: '',
        attached: true,
        alias: 'depotNumber'
      }
    };
    let huisartsRoleStatus = false;
    for (let i = 1; i < this.prescriberRegistrationDetails.gpRoles.length; i++) {
      if (this.prescriberRegistrationDetails.gpRoles[i].active) {
        huisartsRoleStatus = true;
        break;
      }
    }
    if (
      (this.prescriberRegistrationDetails.userType || huisartsRoleStatus) &&
      !this.departmentDetails.showWarranterDetails
    ) {
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'isAnswered',
          value: false
        }
      });
      this.showWarranterQuestion = false;
      this.showWarranterDetails = false;
      this.showSearchWarranterDetails = true;
      this.extendFormConfig();
    } else if (this.departmentDetails.showWarranterDetails) {
      this.showWarranterQuestion = false;
      this.showWarranterDetails = true;
      this.showSearchWarranterDetails = false;
      this.extendFormConfig();
    } else {
      this.showWarranterQuestion = true;
      this.showWarranterDetails = false;
      this.showSearchWarranterDetails = false;
    }
    this.savePrescriberRegistrationDepartmentDetails({
      parentId: this.parentIndex,
      id: this.index,
      details: {
        key: 'showSearchWarranterDetails',
        value: this.showSearchWarranterDetails
      }
    });
    if (this.departmentDetails.isAnswered) {
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'isAnswered',
          value: true
        }
      });
    }
  },
  methods: {
    checkRadioAnswer(value) {
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'prescriberTitleId',
          value: value
        }
      });
    },
    checkWarranterAnswer(value) {
      if (value) {
        this.extendFormConfig();
        this.showSearchWarranterDetails = true;
        this.isAnswered = true;
      } else {
        this.resetFormConfigValues();
        this.showSearchWarranterDetails = false;
        this.isAnswered = false;
      }
      this.showWarranterDetails = false;
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'showWarranterDetails',
          value: false
        }
      });
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'showSearchWarranterDetails',
          value: value
        }
      });
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'isAnswered',
          value: value
        }
      });
    },
    getWarranterDetails(ref, parentIndex, index) {
      let id = ref + parentIndex + index;
      let warranterEmail = document.getElementById(id).value;
      if (warranterEmail) {
        this.fetchWarranterDetails(warranterEmail).then((response) => {
          let warranterDetails = response.data.WarranterDetails;
          let prescriberKeys = {
            prescriberTitleId: warranterDetails ? (warranterDetails.TitleId ? warranterDetails.TitleId : 1) : 1,
            prescriberInitials: warranterDetails ? warranterDetails.Initials : '',
            prescriberFirstName: warranterDetails ? warranterDetails.FirstName : '',
            prescriberPrePosition: warranterDetails ? warranterDetails.PrePosition : '',
            prescriberLastName: warranterDetails ? warranterDetails.LastName : '',
            prescriberEmail: warranterDetails ? warranterDetails.Email : warranterEmail,
            prescriberPhoneNumber: warranterDetails ? warranterDetails.PhoneNumber : '',
            prescriberBigCode: warranterDetails ? warranterDetails.BigCode : '',
            prescriberExternalId: warranterDetails ? warranterDetails.ExternalId : '',
            validated: true,
            showWarranterDetails: true,
            showSearchWarranterDetails: false,
            isAnswered: true,
            warranterSuccessMessage: warranterDetails ? warranterDetails.SuccessMessage : false
          };
          for (let key in prescriberKeys) {
            this.savePrescriberRegistrationDepartmentDetails({
              parentId: parentIndex,
              id: index,
              details: {
                key: key,
                value: prescriberKeys[key]
              }
            });
          }
          this.showWarranterDetails = true;
          this.showSearchWarranterDetails = false;
          this.isAnswered = true;
        });
      }
    },
    validateField(ref, model) {
      const actualReference = ref.substring(0, ref.length - 2);
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: actualReference,
          value: model
        }
      });
    },
    async validateAllFields() {
      const result = await this.validate();
      if (result.valid) {
        this.setValidationStatus(true);
      } else {
      }
    },
    savePrescriberRegistrationDepartmentDetails(prescriberRegistrationDepartmentDetails) {
      this.setPrescriberRegistrationDepartmentDetails({
        data: prescriberRegistrationDepartmentDetails,
        type: this.type
      });
    },
    deleteDepartment() {
      this.deleteDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        type: this.type
      });
    },
    setValidationStatus(active) {
      this.savePrescriberRegistrationDepartmentDetails({
        parentId: this.parentIndex,
        id: this.index,
        details: {
          key: 'validated',
          value: active
        }
      });
    },
    extendFormConfig() {
      this.resetFormConfigValues();
      if (this.showWarranterDetails) {
        $.extend(true, this.formConfig, {
          prescriberInitials: {
            model: this.departmentDetails.prescriberInitials,
            ref: 'prescriberInitials',
            label: 'Voorletters*',
            attached: true,
            alias: 'Voorletters',
            rule: {
              required: false
            },
            error: false
          },
          prescriberFirstName: {
            model: this.departmentDetails.prescriberFirstName,
            ref: 'prescriberFirstName',
            label: 'Voornaam',
            attached: true,
            alias: 'Voornaam',
            rule: {
              required: false
            },
            error: false
          },
          prescriberPrePosition: {
            model: this.departmentDetails.prescriberPrePosition,
            ref: 'prescriberPrePosition',
            label: 'Tussenvoegsel',
            attached: true,
            alias: 'Tussenvoegsel',
            rule: {
              required: false
            },
            error: false
          },
          prescriberLastName: {
            model: this.departmentDetails.prescriberLastName,
            ref: 'prescriberLastName',
            label: 'Achternaam*',
            attached: true,
            alias: 'Achternaam',
            rule: {
              required: false
            },
            error: false
          },
          prescriberEmail: {
            model: this.departmentDetails.prescriberEmail,
            ref: 'prescriberEmail',
            label: 'E-mailadres volmachtgever*',
            attached: true,
            alias: 'E-mailadres volmachtgever',
            rule: { required: true, email: true, regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/ },
            error: false
          },
          prescriberPhoneNumber: {
            model: this.departmentDetails.prescriberPhoneNumber,
            ref: 'prescriberPhoneNumber',
            label: 'Telefoonnummer (vast) volmachtgever',
            attached: true,
            alias: 'Telefoonnummer volmachtgever',
            rule: {
              required: false,
              regex: /^0\d{9}$/
            },
            error: false
          },
          prescriberBigCode: {
            model: this.departmentDetails.prescriberBigCode,
            ref: 'prescriberBigCode',
            label: 'BIG-nummer*',
            attached: true,
            alias: 'BIG-nummer',
            rule: {
              required: false
            },
            error: false
          }
        });
      } else {
        this.formConfig.searchEmail = {
          model: this.departmentDetails.prescriberEmail,
          ref: 'searchEmail',
          label: 'E-mailadres volmachtgever*',
          attached: true,
          alias: 'E-mailadres volmachtgever',
          rule: { required: true, email: true, regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/ },
          error: false
        };
      }
    },
    // Funtion to reset form config
    resetFormConfigValues() {
      for (let key in this.formConfig) {
        if (
          this.formConfig[key].ref !== 'name' &&
          this.formConfig[key].ref !== 'email' &&
          this.formConfig[key].ref !== 'depotNumber'
        ) {
          delete this.formConfig[key];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.registrationDepartmentDetails {
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__warranter {
      display: flex;
      justify-content: center;
      background-color: $teal-lighter;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px 20px 10px 20px;
      text-align: center;
      color: $gray-darkest;
      &__radio {
        display: inline-block;
        vertical-align: sub;
      }
      legend {
        display: inline-block;
      }
    }
    &__warranterDetails {
      padding-bottom: 30px;
    }
    &__content {
      padding-top: 30px;
      padding-bottom: 30px;
      &__section {
        margin: 15px;
        span {
          float: left;
          margin: 12px 8px 0 0;
        }
        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }
    }
  }
  &__delete {
    fill: $teal-darkest;
    position: absolute;
    cursor: pointer;
    margin-top: -3%;
    right: 22%;
  }
  &__successMessage {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 30px 0px 30px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      margin-right: -100px;
      vertical-align: middle;
      :deep(svg) {
        width: 40px;
        height: 40px;
      }
    }
  }
  &__warningMessage {
    width: 90%;
    margin: 30px auto 20px 30px;
  }
  :deep(.form-group) {
    padding: 0px;
    width: 100%;
    &__icon {
      margin: -5px 15px 0px 0px;
    }
    &__radio {
      input[type='radio'] + label {
        color: $gray-dark;
        font-size: 16px;
      }
      input[type='radio']:checked + label > span > span {
        display: none;
      }
    }
  }
}
.magnify-scan {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
  margin-right: 15px;
}
.warranter-label {
  padding: 0 5px 0 5px;
}
</style>
