import axios from 'axios';
import { refreshShoppingCart } from './shoppingCartRefreshRequested$';

export function clearShoppingCart(shoppingCart) {
  if (shoppingCart === null) {
    return;
  }

  // Get data from argument
  var command = {
    customerId: shoppingCart.customerId
  };

  // We assume the external call will succeed, so can empty the current shoppingCart just fine.
  shoppingCart.items = [];

  // External call - also include a refresh after response just to ensure we have the latest version
  axios.post('ShoppingCart/ClearShoppingCart', command).then((response) => {
    // force rebind of shopping cart
    refreshShoppingCart();
    return response;
  });
}
