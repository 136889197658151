import axios from 'axios';
import moment from 'moment';
import { dayOfWeekTranslator } from '../../../../services/date.service';

/**
 * @typedef Order
 * @type {object}
 * @property {number} articleId - id of the article
 * @property {string} desc - description of product
 * @property {number} count - amount of products
 */

export default {
  namespaced: true,
  state: {
    /**
     * @param {Order[]}
     */
    orders: [],
    // Used to set 6 consecutive delivery dates
    possibleDeliveryDates: [],
    // Used to show/hide delivery date section
    showDeliveryDate: false,
    // Used to set delivery date selected from delivery date dropdown
    selectedDeliveryDate: null,
    checkoutDetails: {},
    cartOpen: false,
    orderClicked: false
  },
  mutations: {
    setCheckoutDetails(state, checkoutDetails) {
      state.checkoutDetails = checkoutDetails;
      state.checkoutDetails.orderNumber = '';
    },
    setSelectedAddress(state, addressValue) {
      state.checkoutDetails.SelectedAddress = addressValue;
    },
    setRepeatService(state, repeatService) {
      state.checkoutDetails.RepeatService = repeatService;
    },
    setRemarks(state, remarks) {
      state.checkoutDetails.Remarks = remarks;
    },
    setOrderNumber(state, orderNumber) {
      state.checkoutDetails.orderNumber = orderNumber;
    },
    setPossibleDeliveryDates(state, payload) {
      if (payload !== null) {
        state.selectedDeliveryDate = payload[0].Key;
      }
      state.possibleDeliveryDates = payload;
    },
    setNeighborsDeliveryAllowed(state, neighborsDeliveryAllowedStatus) {
      state.checkoutDetails.NeighborsDeliveryAllowed = neighborsDeliveryAllowedStatus;
    },
    setCheckoutProductQuantity(state, productDetails) {
      const productIndex = state.checkoutDetails.ShoppingcartProducts.findIndex(
        (product) => product.CartId === productDetails.cartId
      );
      state.checkoutDetails.ShoppingcartProducts[productIndex]['Quantity'] = productDetails.amount;
    },
    setCartOpenStatus(state, payload) {
      state.cartOpen = payload;
    },
    setOrderClicked(state, payload) {
      state.orderClicked = payload;
    },
    resetCheckoutDetails(state) {
      state.checkoutDetails = {};
    }
  },
  actions: {
    fetchATPDetail({ commit, state }, orderDetails) {
      return axios.get(
        'ShoppingCart/GetATPCheck?productId=' + orderDetails.productId + '&quantity=' + orderDetails.quantity
      );
    },
    fetchCheckoutDetails({ commit, dispatch, state }, requiredIds) {
      return axios
        .get(
          'Checkout/GetShoppingcartDetails?prescriberId=' +
            requiredIds.prescriberId +
            '&patientId=' +
            (requiredIds.patientId || 0)
        )
        .then((response) => {
          commit('setCheckoutDetails', response.data);
          return response.data;
        });
    },
    fetchPossibleDeliveryDates({ commit }) {
      axios.get('ShoppingCart/GetPossibleDeliveryDates').then((response) => {
        if (response.data.Success) {
          let translatedDats = [];
          let dutchWeekDay = '';
          response.data.PossibleDeliveryDates.forEach((dt, index) => {
            dutchWeekDay = dayOfWeekTranslator(new Date(dt), 'nl');
            dutchWeekDay = dutchWeekDay.concat(' ', moment(dt).format('DD-MM'));
            translatedDats.push({ Key: dt, Value: dutchWeekDay });
          });
          commit('setPossibleDeliveryDates', translatedDats);
        }
      });
    }
  },
  getters: {
    getCartOpenStatus: (state) => {
      return state.cartOpen;
    },
    getOrderClicked: (state) => {
      return state.orderClicked;
    },
    getOrders: (state) => {
      return state.orders;
    },
    getCheckoutHomeAddress: (state) => {
      return state.checkoutDetails.HomeAddress;
    },
    getCheckoutDeliveryAddress: (state) => {
      return state.checkoutDetails.DeliveryAddress;
    },
    getCheckoutDifferentAddress: (state) => {
      return state.checkoutDetails.DifferentAddress;
    },
    getSelectedAddress: (state) => {
      return state.checkoutDetails.SelectedAddress;
    },
    getOrderNumber: (state) => {
      return state.checkoutDetails.orderNumber;
    },
    getKwartaalServiceStatus: (state) => {
      return state.checkoutDetails.KwartaalService;
    },
    getKwartaalServiceDetails: (state) => {
      return state.checkoutDetails.RepeatOrderSitecoreConfiguration;
    },
    getFaqs: (state) => {
      return state.checkoutDetails.faq;
    },
    getRemarks: (state) => {
      return state.checkoutDetails.Remarks;
    },
    getPossibleDeliveryDates: (state) => {
      return state.possibleDeliveryDates;
    },
    getRepeatService: (state) => {
      return state.checkoutDetails.RepeatService;
    },
    getNeighborsDeliveryAllowed: (state) => {
      return state.checkoutDetails.NeighborsDeliveryAllowed;
    },
    getButtonText: (state) => {
      return state.checkoutDetails.BackToProductOverview;
    },
    getPopupMessage: (state) => {
      return state.checkoutDetails.DeleteProductPopupMessage;
    },
    getDepotIssueCheckboxDetails: (state) => {
      return state.checkoutDetails.DepotIssueCheckboxDetails;
    },
    getUserWarehouseStatus: (state) => {
      return state.checkoutDetails.UserWarehouseStatus;
    },
    getOrderConfirmationMessage: (state) => {
      return state.checkoutDetails.OrderConfirmationMessage;
    },
    getOrderInformativeMessage: (state) => {
      return state.checkoutDetails.OrderInformativeMessage;
    }
  }
};
