<template>
  <div v-clickout="closeDropdown" class="dropdown">
    <v-button v-bind="buttonProps" @click.prevent="toggleDropdown">
      <slot /><v-arrow-icon class="dropdown__icon" :class="{ active: showDropdown }" />
    </v-button>
    <transition-group appear tag="div" name="stagger">
      <div v-show="showDropdown" key="1" :style="{ width: optionsWidth, top: optionsTop }" class="dropdown__options">
        <ul class="dropdown__list">
          <li v-for="(item, index) in options" :key="index" class="dropdown__list__item" @click="onSelect(item.id)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </transition-group>
  </div>
</template>

<script>
import VArrowIcon from 'icons/MenuDown';

export default {
  name: 'VDropdown',
  components: {
    VArrowIcon
  },
  props: {
    /**
     * @member {array} options
     * @type {object} option
     * @property {number} option.id
     * @property {string} option.title
     */
    options: {
      required: true,
      type: Array
    },
    /**
     * @member {object} props
     */
    buttonProps: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      showDropdown: false,
      optionsWidth: 0,
      optionsTop: 0
    };
  },
  mounted() {
    this.optionsWidth = this.$el.clientWidth + 'px';
  },
  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.optionsWidth = this.$el.clientWidth + 'px';
      this.optionsTop = this.$el.clientHeight + 'px';
      this.showDropdown = !this.showDropdown;
    },
    onSelect(id) {
      this.$emit('onSelect', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  .btn {
    position: relative;
  }

  &__options {
    position: absolute;
    padding: 6px;
    margin: 0;
    z-index: 1;
    border: 1px solid $gray-light;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: $white;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    text-align: left;
    font-weight: normal;
    color: $gray-darkest;
    padding-left: 12px;

    &__item {
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 10px;

      &:hover {
        color: $teal-darkest;
      }
    }
  }

  &__icon {
    fill: $teal-darkest;
    display: block;
    transition: transform 0.2s ease-out;

    &.active {
      transform: rotate(-180deg);
    }
  }
}

.stagger-enter-active,
.stagger-leave-active {
  transition: opacity 0.6s ease-out, transform 0.3s ease-out;
  overflow: hidden;
}

.stagger-enter,
.stagger-leave-to {
  transform: translate(0px, -20px);
  height: 0;
  border: 0;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, height 0.1s 0.1s ease-out, border 0.3s ease-out;
}
</style>
