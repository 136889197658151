<template>
  <section class="header">
    <slot />
  </section>
</template>

<script>

import { computed } from 'vue'
import { useStore } from "vuex";

export default {
  name: 'VHeader',
  setup() {
    const store = useStore();
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);

    return {
      patientGegevens
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
section.header {
  position: relative;
  background: $search-primary-background;
  transition: $search-transition;
  width:100%;
  display: inline-table !important;
  align-items: center;
  justify-content: flex-start;
}
</style>
