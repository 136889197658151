<template>
  <generic-modal
    :has-close-button="true"
    @close="closeAuthenticationModal"
  >
    <template #header>
      <template v-if="!authIsEnabled">
        {{ twoFactorAuthenticationSettingsConfiguration.enable2FaPopupTitle }}
      </template>
      <template v-if="authIsEnabled">
        {{ twoFactorAuthenticationSettingsConfiguration.enable2FaPopupSuccessTitle }}
      </template>
    </template>
    <template #body>
      <div v-if="!authIsEnabled">
        <p>{{ twoFactorAuthenticationSettingsConfiguration.enable2FaPopupDescription }}</p>
        <v-input-group-simple-2
          id="authorizationCode"
          v-model:value="authorizationCode"
          maxlength="6"
          placeholder="Uw verificatiecode"
          data-vv-as="Authorizatie code"
          :overwrite-value="authorizationCode"
          :rules="{
            required: false
          }"
        />
        <small
          v-if="authenticationNotEnabled && !showResendText"
          class="notification-text"
        >{{ twoFactorAuthenticationSettingsConfiguration.invalid2FaCode }}</small>
        <small
          v-if="showResendText && !authenticationNotEnabled"
          class="notification-text"
        >Er is een nieuwe code verstuurd naar uw email.</small>
        <v-button
          class="resend-button"
          transparent
          hollow
          @click="resendVerificationCode()"
        >
          {{ twoFactorAuthenticationSettingsConfiguration.resend2FaCodeButtonLabel }}
        </v-button>
      </div>
      <div
        v-if="authIsEnabled"
        class="confirmation"
      >
        <div class="col-xs-2">
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            /><path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <p class="col-xs-8">
          {{ twoFactorAuthenticationSettingsConfiguration.enable2FaPopupSuccessDescription }}
        </p>
      </div>
    </template>
    <template
      v-if="!authIsEnabled"
      #footer
    >
      <v-button
        md
        hollow
        transparent
        @click="closeAuthenticationModal"
      >
        Annuleren
      </v-button>
      <v-button
        :disabled="authorizationCode.length <= 5"
        md
        primary
        @click="enable2FA()"
      >
        Bevestigen
      </v-button>
    </template>
  </generic-modal>
</template>

<script>
  import GenericModal from '@/components/shared/GenericModal'
  import { tap } from 'rxjs/operators';
  import {
    currentUserIdentity$,
    twoFactorAuthenticationSettingsConfiguration$,
    enable2FactorAuthentication,
    twoFactorAuthenticationNotEnabledEvent$,
    twoFactorAuthenticationEnabledEvent$ } from '@/services';
  import { ref } from 'vue';

export default {
  name: 'PrescriberEnableAuthModal',
  components: {
    GenericModal
  },
  setup() {
    const currentUserIdentity = ref(null);
    const twoFactorAuthenticationSettingsConfiguration = ref(null);
    const twoFactorAuthenticationEnabledEvent = ref(null);
    const authorizationCode = ref('');
    const authenticationNotEnabled = ref(false);
    const showResendText = ref(false);

    currentUserIdentity$.subscribe(value => currentUserIdentity.value = value);
    twoFactorAuthenticationSettingsConfiguration$.subscribe(value => twoFactorAuthenticationSettingsConfiguration.value = value);
    twoFactorAuthenticationEnabledEvent$.subscribe(value => twoFactorAuthenticationEnabledEvent.value = value);

    twoFactorAuthenticationNotEnabledEvent$.pipe(
      tap(e => {
        authorizationCode.value = '';
        authenticationNotEnabled.value = true;
        showResendText.value = false;
      })
    );

    return {
      currentUserIdentity,
      twoFactorAuthenticationSettingsConfiguration,
      twoFactorAuthenticationEnabledEvent,
      authorizationCode,
      authenticationNotEnabled,
      showResendText
    }
  },
  data () {
    return {
      openAuthenticationModal: false,
    }
  },
  computed: {
    authIsEnabled () {
      return this.currentUserIdentity.twoFactorAuthentication.enabled;
    }
  },
  mounted () {
    enable2FactorAuthentication();
  },
  methods: {
    closeAuthenticationModal () {
      this.$emit('closeAuthenticationModal', false);
    },
    resendVerificationCode () {
      enable2FactorAuthentication();
      this.showResendText = true
      this.authenticationNotEnabled = false
    },
    async enable2FA () {
      await enable2FactorAuthentication(this.authorizationCode);
    }
  }
}
</script>

<style lang="scss" scoped>
  .confirmation {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .resend-button {
    color: $black;
    text-decoration: underline;
    font-weight: 400;
    padding: 10px 0 10px 0;
  }
  .notification-text {
    color: $red;
    display: block;
  }
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: $white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $cyan-darker;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $cyan-darker;
    fill: none;
    animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
  }
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $cyan-darker;
    }
  }
</style>
