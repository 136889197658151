import { router$ } from '@/router/router$';
import { publishBehavior, map, refCount } from 'rxjs/operators';

export const assortmentFeatureEnabled$ = router$.pipe(
  map((route) => {
    return route && route.name.indexOf('aanvullenmagazijn') === 0;
  }),
  publishBehavior(false),
  refCount()
);
