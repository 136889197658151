import { publishBehavior, switchMap, refCount } from 'rxjs/operators';
import { activePpwRecId$ } from '@/services/activePpwRecId$';
import { fetchQuery } from '@/services/messaging/fetchQuery';

export const activeLicensesByCurrentDepartment$ = activePpwRecId$.pipe(
  switchMap((activePpwRecId) => {
    return fetchQuery("GetLicensesByCurrentDepartment", {PpwRecId: activePpwRecId})
  }),
  publishBehavior([]),
  refCount()
);
