<template>
  <div v-if="departmentColleagues" class="forwardModal">
    <v-suggest-dropdown
      v-if="departmentColleaguesForSuggestBox && institutionColleaguesForSuggestBox"
      placeholder="Zoek op naam"
      suggest-header="Mijn afdeling"
      :options="departmentColleaguesForSuggestBox"
      suggest-additional-header="Overige afdelingen"
      :additional-options="institutionColleaguesForSuggestBox"
      @select-option="selectOption($event)"
    />
    <transition name="fade">
      <div v-if="select.colleagueSelected">
        <v-textarea-group
          v-model="select.comment"
          :class="{ redborder: charactersRemaining < 0 }"
          label="Op- of aanmerkingen voor uw collega"
          inputid="Op- of aanmerkingen voor uw collega"
          @change="$emit('promptComponentData', select)"
        />
        <small v-if="allowedCharacterLimit" class="characters-remaining">
          <b>{{ charactersRemaining }} </b> tekens resterend
        </small>
        <small v-else class="characters-remaining__red">
          <b>{{ Math.abs(charactersRemaining) }} </b> tekens teveel
        </small>
      </div>
    </transition>
  </div>
</template>

<script>
import VSuggestDropdown from '@/components/shared/SuggestDropdown';
import { departmentColleagues$, institutionColleagues$ } from '@/services';
import { ref } from 'vue';

export default {
  name: 'LicenseForwardModal',
  components: {
    VSuggestDropdown
  },
  setup() {
    const departmentColleagues = ref(null);
    const institutionColleagues = ref(null);

    departmentColleagues$.subscribe((value) => (departmentColleagues.value = value));
    institutionColleagues$.subscribe((value) => (institutionColleagues.value = value));

    return {
      departmentColleagues,
      institutionColleagues
    };
  },
  data() {
    return {
      select: {
        colleagueSelected: null,
        comment: ''
      },
      characterLimit: 160
    };
  },
  computed: {
    departmentColleaguesForSuggestBox() {
      return (this.departmentColleagues || []).map((colleague) => {
        return { ...colleague, title: colleague.name || '?' };
      });
    },
    institutionColleaguesForSuggestBox() {
      return (this.institutionColleagues || []).map((colleague) => {
        return { ...colleague, title: colleague.name || '?' };
      });
    },
    charactersRemaining() {
      return this.characterLimit - this.select.comment.length;
    },
    allowedCharacterLimit() {
      return this.select.comment.length <= this.characterLimit;
    }
  },
  methods: {
    selectOption(option) {
      this.select.colleagueSelected = option;
      this.$emit('promptComponentData', this.select);
    }
  }
};
</script>

<style lang="scss" scoped>
.forwardModal {
  margin: 20px 0px 20px 0px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.form-group {
  display: block;
  width: 100%;
  :deep(.form-group__details) {
    display: none;
  }
}
textarea {
  min-width: 100%;
  max-width: 100%;
}

.characters-remaining {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 0 0;
  &__red {
    color: $red;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    b {
      padding: 0 3px 0 0;
    }
  }
  b {
    padding: 0 3px 0 0;
  }
}

.redborder {
  :deep(.form-group__input) {
    border: 1px solid $red;
  }
}
</style>
