import { Subject } from 'rxjs';

// Subject to hold closedevents
const subject$ = new Subject();

// Helper method to manually push events, needs to be removed when events arrive from server.
export function pushDepartmentChangedEvent (e) {
  subject$.next(e);
}

// Observable for components towork with
export const departmentChangedEventStream$ = subject$.asObservable();
