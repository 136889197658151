<template>
  <div class="mDropdown">
    <mds-dropdown v-bind="$attrs" v-on="$attrs">
      <span slot="content">
        <slot name="content" />
      </span>
    </mds-dropdown>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { MdsDropdown } from '@mds/dropdown';

export default {
  name: 'Dropdown',
  props: {
    item: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {},
  created() {}
};
</script>
