<template>
  <div class="sidebar-wrapper">
    <sidebar-menu
      v-if="navigationArray"
      class="navigation"
      :menu="navigationArray"
      :hide-toggle="true"
      :theme="'white-theme'"
      :collapsed="collapsed"
      :width="width"
      :width-collapsed="widthCollapsed"
      @item-click="onItemClick"
    >
      <template v-slot:header>
        <span class="navigation__header">
          <router-link to="/">
            <img v-if="!collapsed" class="navigation-logo" alt="Mediq TEFA" src="@/static/img/mediq_logo.svg" />
          </router-link>
          <img
            v-if="!collapsed"
            class="arrow-icon"
            src="@/static/img/icons/arrow-collapse-left.svg"
            @click="collapseSidebar"
          />
          <div v-if="collapsed" class="hamburger-icon" @click="collapseSidebar">
            <img src="@/static/img/icons/hamburger.svg" />
          </div>
        </span>
      </template>
      <template v-slot:dropdown-icon>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#73c8d2" width="24" height="24" viewBox="0 0 24 24">
            <path d="M7,10L12,15L17,10H7Z" />
          </svg>
        </span>
      </template>
    </sidebar-menu>
  </div>
</template>

<script>
import router from '@/router';
import { pushDepartmentChangedEvent } from './departmentChangedEventStream';
import { availableDepartments$, activeDepartment$, left$ } from '@/services';
import { tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { formLogout, currentUserIdentity$, availablePortals$, pushActivePortalCode } from '@/services';

import { ref, watchEffect, onMounted, onUnmounted, markRaw, computed } from 'vue';
import { useStore, mapActions } from 'vuex';

export default {
  props: {
    hideToggle: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'white-theme'
    },
    width: {
      type: String,
      default: '310px'
    },
    widthCollapsed: {
      type: String,
      default: '80px'
    },
    showOneChild: {
      type: Boolean,
      default: true
    },
    auth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const store = useStore();
    const updateResults = (value) => store.dispatch('navigation/updateResults', value);
    const getFeedbackConfiguration = () => store.dispatch('feedback/getFeedbackConfiguration');
    const selectedDepartmentChange = (value) => store.dispatch('login/selectedDepartmentChange', value);
    const fetchDepartmentResults = () => store.dispatch('login/fetchDepartmentResults');
    const fetchResults = () => store.dispatch('navigation/fetchResults');
    const setPrescriberChatIframe = (value) => store.commit('prescriber/setPrescriberChatIframe', value);
    const showOneChildStatus = computed(() => store.getters['navigation/getShowOneChildStatus']);

    // Define reactive variables
    const availableDepartments = ref(null);
    const activeDepartment = ref(null);
    const availablePortals = ref(null);
    const user = ref(null);
    const results = ref(null);
    const navigationArray = ref(null);

    const initNavigation = (results) => {
      navigationArray.value = (results.navigationItems || []).map(({ type, title, childNodes, relativeUri }) => ({
        child: childNodes
          ? childNodes.map(({ title, type, relativeUri }) => ({ title, type, href: relativeUri }))
          : childNodes,
        href: relativeUri,
        title,
        type,
        icon: {
          element: 'img',
          attributes: {
            src: `/icons/${type.toLowerCase()}.svg`
          }
        }
      }));
      const itemHeaderObject = {
        component: markRaw({ template: `<small class="department-subheader">Mijn afdeling</small>` })
      };
      const logoutObject = {
        title: 'Uitloggen',
        type: 'Logout',
        href: '/logout',
        icon: {
          element: 'img',
          attributes: {
            src: `/icons/logout.svg`
          }
        }
      };
      const userObject = {
        title: results.user ? results.user.fullName : '',
        type: 'user',
        child: [{ title: 'Mijn gegevens', href: '/gegevens' }],
        icon: {
          element: 'img',
          attributes: {
            src: `/icons/user.svg`
          }
        }
      };

      const instititionIds = availableDepartments.value.map(({ ppwRecId, name }) => ({
        ppwRecId: ppwRecId,
        title: name
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }));
      const addDepartmentSelector = instititionIds.map((id) => ({ ...id, id: 'department-selector' }));
      let institutionObject = {
        title: instititionIds.length > 0 ? instititionIds[0].title : '',
        child: addDepartmentSelector,
        class: 'department-items',
        icon: {
          element: 'img',
          attributes: {
            src: `/icons/building.svg`
          }
        }
      };
      if (availablePortals.value.portals.length > 1) {
        const portalDetails = availablePortals.value.portals.map(({ code, displayName }) => ({
          code: code,
          title: displayName
        }));
        const addPortalSelector = portalDetails.map((id) => ({ ...id, id: 'portal-selector' }));
        let activePortalIndex = portalDetails.findIndex(
          (portal) => portal.code === availablePortals.value.activePortalCode
        );
        let portalObject = {
          title:
            portalDetails.length > 0 && activePortalIndex > 0
              ? portalDetails[activePortalIndex].title
              : portalDetails[0].title,
          child: addPortalSelector,
          class: 'department-items',
          icon: {
            element: 'img',
            attributes: {
              src: `/icons/medical-bag.svg`
            }
          }
        };
        navigationArray.value.unshift(userObject, institutionObject, portalObject);
      } else {
        navigationArray.value.unshift(userObject, institutionObject);
      }
      navigationArray.value.push(logoutObject);
      navigationArray.value.splice(1, 0, itemHeaderObject);
      let departmentArray = navigationArray.value[2].child;
      const selectedNavigationDepartment = departmentArray.find(
        (childItem) => childItem.ppwRecId === activeDepartment.value.ppwRecId
      );
      // Remove selected department from current index
      departmentArray.filter((item) => item !== selectedNavigationDepartment);
      navigationArray.value[2].child = departmentArray;
      navigationArray.value[2].title = selectedNavigationDepartment ? selectedNavigationDepartment.title : '';
    };

    watchEffect(() => {
      const combinedStream = combineLatest([currentUserIdentity$, left$, availablePortals$]);

      const subscription = combinedStream
        .pipe(
          tap((data) => {
            updateResults(data[1]).then((results) => {
              const navdata = { ...data[1], user: data[0], availablePortals: data[2] };
              initNavigation(navdata);
            });
          })
        )
        .subscribe();

      // Unsubscribe when component is unmounted
      onUnmounted(() => {
        subscription.unsubscribe();
      });
    });

    // Fetch initial values
    const fetchInitialValues = () => {
      availableDepartments$.subscribe((value) => {
        availableDepartments.value = value;
      });

      activeDepartment$.subscribe((value) => {
        activeDepartment.value = value;
      });

      availablePortals$.subscribe((value) => {
        availablePortals.value = value;
      });

      currentUserIdentity$.subscribe((value) => {
        user.value = value;
      });
    };

    // Call fetchInitialValues when component is mounted
    onMounted(fetchInitialValues);

    return {
      showOneChildStatus,
      updateResults,
      getFeedbackConfiguration,
      selectedDepartmentChange,
      fetchDepartmentResults,
      fetchResults,
      setPrescriberChatIframe,
      availableDepartments,
      activeDepartment,
      availablePortals,
      user,
      results,
      ...mapActions('navigation', ['fetchResults']),
      ...mapActions('login', ['logOut', 'selectedDepartmentChange', 'fetchDepartmentResults']),
      initNavigation,
      navigationArray
    };
  },
  data() {
    return {
      collapsed: false
    };
  },
  watch: {
    collapsed(sidebarCollapsedState) {
      window.localStorage.setItem('sidebar-collapsed', JSON.stringify(sidebarCollapsedState));
    },
    showOneChildStatus(value) {
      if (!value) {
        this.navigationArray.forEach((element) => {
          if (element.href === '/') {
            element.href = null;
          }
        });
      }
    }
  },
  mounted() {
    this.collapsed = JSON.parse(window.localStorage.getItem('sidebar-collapsed'));
    this.getFeedbackConfiguration();
  },
  methods: {
    collapseSidebar() {
      this.collapsed = !this.collapsed;
      this.$emit('sidebarToggleEvent');
    },
    onItemClick(event, item, node) {
      if (item.id === 'portal-selector') {
        this.changePortal(item.code);
      }
      if (item.id === 'department-selector') {
        const items = document.querySelector('.active-class');
        if (items !== null) {
          items.classList.remove('active-class');
        }
        event.target.parentElement.classList.add('active-class');
        this.selectedDepartmentChange(item.ppwRecId).then((response) => {
          router.push('/');
          this.fetchDepartmentResults();
          this.fetchResults();
          pushDepartmentChangedEvent({});
        });
      }
      if (item.type === 'Logout') {
        formLogout().then((r) => {
          this.setPrescriberChatIframe(false);
          window.location.replace('/?' + Math.random());
        });
      }
      if (item.type === 'GiveYourOpinion') {
        this.$emitter.emit('showFeedbackForm');
      }
      if (item.type === 'Pien') {
        window.open(item.href); // Added for E40-7629
      }
      if (item.type === 'SSO') {
        router.push('/');
        window.open(item.href, '_blank');
      }
      setTimeout(() => {
        if (item && item.class) {
          let activeDept = event.target.textContent;
          let aTags = document.getElementsByClassName('vsm--link_level-2');
          let requiredDepartment = '';
          for (let i = 0; i < aTags.length; i++) {
            if (aTags[i].textContent.localeCompare(activeDept)) {
              requiredDepartment = aTags[i];
              break;
            }
          }
          if (requiredDepartment) {
            requiredDepartment.parentElement.classList.add('active-class');
          }
        }
      }, 250);
    },
    changePortal(portalCode) {
      pushActivePortalCode(portalCode);
      location.replace('/'); // reload page, right now there are too much dependencies on the damn store.
    }
  }
};
</script>

<style lang="scss">
.v-sidebar-menu .vsm--link_level-2 {
  .vsm--title {
    font-size: 14px;
    line-height: 20px;
    white-space: normal;
    pointer-events: none;
  }
}
.v-sidebar-menu .vsm--link_level-1::after,
.v-sidebar-menu .vsm--link_level-2::after {
  content: '';
  display: block;
  border-bottom: 1px solid #ebebeb;
  left: 30px; /* Adjust as needed */
  right: 30px; /* Adjust as needed */
  position: absolute;
  margin-top: 64px;
}
.v-sidebar-menu .vsm--link_open::after {
  border-bottom: none !important;
}
.v-sidebar-menu .vsm--scroll {
  position: relative;
}
.v-sidebar-menu.vsm_collapsed .vsm--scroll {
  position: unset;
}
.sidebar-wrapper {
  .navigation__header {
    .navigation-logo {
      float: left;
    }
  }
  .vsm--dropdown,
  .v-sidebar-menu {
    .vsm--list,
    .vsm--scroll {
      .vsm--item {
        .vsm--link_exact-active {
          font-weight: 400;
          border-left: 3px solid $teal-darkest;
          &:hover {
            border-left: 3px solid $teal-darkest;
          }
        }
        .vsm--title {
          span {
            line-height: 20px;
            white-space: initial;
            pointer-events: none;
          }
        }
      }
    }
  }
  .active-class {
    border-left: 3px solid $teal-darkest;
    a {
      border-left: none !important;
    }
  }
  .navigation-logo {
    max-width: 140px;
    padding-left: 20px;
  }

  .navigation {
    &__header {
      padding-top: 15px;
      height: 85px;
    }
  }

  *:focus {
    outline: none;
  }

  .vsm--title {
    white-space: initial;
    line-height: 20px;
  }

  .v-sidebar-menu {
    z-index: 90;
    .vsm--arrow_open {
      transform: rotate(-180deg);
      transform-origin: 50% 50%;
    }
    .vsm--arrow_slot {
      width: 24px;
      height: 24px;
    }
    .vsm--dropdown {
      > .vsm--list,
      .vsm--scroll {
        padding: 10px 5px 5px 0px;
      }
    }
    &.vsm_white-theme {
      .vsm--link {
        transition: 0s;
        border-left: 3px solid transparent;
      }
      .vsm--link:hover {
        transition: 0s;
        background-color: transparent;
        border-left: 3px solid $gray-light;
      }
      .vsm--dropdown {
        background: $grayish-semi-med-light;
        .vsm--list {
          background: $grayish-semi-med-light;
        }
      }
      .vsm--link_level-1 {
        .vsm--icon {
          background: transparent;
          width: 20px;
          height: 20px;
        }
        &.vsm--link_active {
          box-shadow: none;
          .vsm--icon {
            background: transparent;
          }
        }
        &.department-items {
          margin-top: 7px;
        }
      }
      &.vsm_collapsed {
        .department-title {
          padding: 0 0 0 30px;
          span {
            font-size: 14px;
          }
        }
        .department-subheader {
          display: none;
        }
        .vsm--link_level-1 {
          &.vsm--link_hover {
            .vsm--icon {
              background: transparent;
            }
          }
          :hover {
            background: transparent;
          }
        }
        .vsm--link_hover {
          &.vsm--icon {
            background: transparent;
          }
          .vsm--icon {
            filter: brightness(0) invert(1);
          }
        }
        .vsm--icon {
          color: $white;
        }
        .vsm--link {
          color: $white;
        }
        .vsm--link_level-2 {
          color: $gray-darkest;
        }
      }
      &.vsm_expanded {
        .department-title {
          margin-top: -27px;
          span {
            padding-top: 30px;
            font-size: 14px;
          }
        }
        .vsm--item_open {
          .vsm--link {
            &.active {
              transition: 0s;
              background-color: transparent;
              border-left: 3px solid $teal-darkest;
            }
          }
          .vsm--link_level-1 {
            background: $teal-darkest;
            .vsm--icon {
              filter: brightness(0) invert(1);
              background: transparent;
            }
            svg {
              fill: $white;
            }
          }
          ::after {
            border: none;
          }
        }
        .vsm--link_open {
          background-color: $teal-darkest !important;
        }
      }
      .vsm--link {
        color: $gray-darkest;
        font-weight: 400;
        padding-left: 30px;
        min-height: 65px;
        &.active {
          transition: 0s;
          background-color: transparent;
          border-left: 3px solid $gray-light;
        }
      }
      .vsm--mobile-bg {
        background-color: $teal-darkest;
        color: $white;
        padding: 10px;
      }
      .vsm--mobile-item {
        max-width: 370px !important;
      }
    }
    .vsm--title_hidden {
      display: none;
    }
    .vsm--item_mobile {
      &:hover {
        background-color: $teal-darkest !important;
        .vsm--icon {
          color: $white !important;
        }
        .vsm--mobile-bg {
          background-color: $teal-darkest !important;
        }
      }
      .vsm--title {
        width: 370px !important;
      }
      .vsm--mobile-bg {
        width: 450px !important;
      }
      .vsm--child_mobile {
        width: 370px !important;
      }
    }
  }

  .arrow-icon {
    padding: 12px 20px 12px 12px;
    float: right;
    color: $teal-darkest;
    cursor: pointer;
  }

  .hamburger-icon {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin-left: 26px;
    margin-top: 10px;
    background: $teal-darkest;
    border-radius: 90px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .svg-inline--fa .fa-bars .fa-w-14 .fa-lg {
    display: flex;
    vertical-align: middle;
  }

  svg.fa-bars {
    display: flex;
    vertical-align: middle;
  }

  .department-subheader {
    padding-left: 60px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: $dark-green;
    position: relative;
    top: 7px;
  }
}
</style>
