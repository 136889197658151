<template>
  <section class="main grid-justify-content-center row grid-padding">
    <div class="col-xs-12 logo-container row grid-justify-content-center">
      <router-link to="/">
        <img class="navigation-logo" alt="Mediq TEFA" src="@/static/img/mediq_logo.svg" />
      </router-link>
    </div>
    <div class="col-xs-12 nav-bar">
      <span>{{ pageTitle }}</span>
      <router-link to="/">
        <v-arrow-left-icon />
        {{ navigationBackText }}
      </router-link>
    </div>
    <router-view />
  </section>
</template>

<script>
import VArrowLeftIcon from 'icons/ChevronLeft';

export default {
  name: 'Mainstandalone',
  components: {
    VArrowLeftIcon
  },
  beforeRouteUpdate(to, from, next) {
    this.routerCurrentPath = to.path;
    next();
  },
  data() {
    return {
      pageTitle: '',
      navigationBackText: ''
    };
  }
};
</script>

<style lang="scss" scoped>
.main {
  background-color: $teal-lightest;
  overflow: hidden;

  .logo-container {
    height: 100px;
    background-color: $white;

    .navigation-logo {
      max-width: 140px;
      height: 48px;
    }
  }

  .nav-bar {
    height: 77px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    background: $teal-darkest;

    > span,
    a {
      align-self: center;
      color: $white;
    }

    > span {
      font-size: type-scale(4);
    }

    > a {
      text-decoration: none;
      font-size: type-scale(0);
      display: flex;
      align-items: center;
      .chevron-left-icon {
        height: 24px;
      }
    }
  }
}
</style>
