import './styles/normalize.scss';
import 'lato-font';
import 'icons/styles.css';
import './styles/main.scss';
import './styles/animate.css';
import './styles/pagination.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-select/dist/vue-select.css';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import './styles/tooltip.css';

import VueImageZoomer from 'vue-image-zoomer';
import 'vue-image-zoomer/dist/style.css';
import './styles/imageZoomer.css';

import { VueRecaptcha } from 'vue-recaptcha';
import { ModelSelect } from 'vue-search-select';

import Vue3CountryIntl from 'vue3-country-intl';
import 'vue3-country-intl/lib/vue3-country-intl.css';

// shared component styles
import '@/components/shared/form/FormGroup.scss';

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { initAxios } from '@/services';
import { createGtm } from '@gtm-support/vue-gtm';

import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import router from './router';
import mitt from 'mitt';
import vClickout from 'vue3-clickout';

// prescriber-design-system integration
import PdsNavigation from '@mds/prescriber-design-system';

import IdlePlugin from './plugins/idle'; // Custom idle plugin

initAxios();

export const app = createApp(App);
const emitter = mitt();

app.use(store);
app.use(router);
app.use(
  createGtm({
    id: 'GTM-5JZVM3L',
    queryParams: {},
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: []
  })
);
app.use(VueSidebarMenu);
app.use(FloatingVue);
app.use(VueImageZoomer);
app.use(vClickout);
app.use(PdsNavigation);
app.use(IdlePlugin, { // Use the custom idle plugin
  idleTime: 8 * 60 * 60 * 1000, // 8 hours
  onIdle: () => {
    console.log('User has been idle for 8 hours, reloading the page.');
    window.location.reload(true);
  }
});

// shared
import Button from '@/components/shared/Button';
import Heading from '@/components/shared/Heading';
import InputGroup from '@/components/shared/form/InputGroup';
import InputGroupSimple from '@/components/shared/form/simple/InputGroup';
import InputGroupSimple2 from '@/components/shared/form/simple/InputGroup2';
import SelectGroup from '@/components/shared/form/SelectGroup';
import TextareaGroup from '@/components/shared/form/TextareaGroup';
import Paragraph from '@/components/shared/Paragraph';
import Overlay from '@/components/shared/Overlay';
import mNavigation from '@/components/sandbox/webcomponents/mNavigation';
import mDropdown from '@/components/sandbox/webcomponents/mDropdown';
import mLayout from '@/components/sandbox/webcomponents/mLayout';
import mButton from '@/components/shared/webcomponents/mButton';
import mSelect from '@/components/shared/webcomponents/mSelect';
import mInput from '@/components/shared/webcomponents/mInput';
import Navigation from '@/components/shared/webcomponents/PrescriberDesignSystem/Navigation';
import { MdsAlert } from '@mds/alert';

app.config.productionTip = false;

// register vue components global
app.component(Button.name, Button);
app.component(Heading.name, Heading);
app.component(InputGroup.name, InputGroup);
app.component(Paragraph.name, Paragraph);
app.component(Overlay.name, Overlay);
app.component(InputGroupSimple.name, InputGroupSimple);
app.component(InputGroupSimple2.name, InputGroupSimple2);
app.component(SelectGroup.name, SelectGroup);
app.component(TextareaGroup.name, TextareaGroup);
app.component('MNavigation', mNavigation);
app.component('MDropdown', mDropdown);
app.component('MLayout', mLayout);
app.component('MButton', mButton);
app.component('MSelect', mSelect);
app.component('MInput', mInput);
app.component('MdsAlert', MdsAlert);
app.component('VueRecaptcha', VueRecaptcha);
app.component('ModelSelect', ModelSelect);
app.component('Navigation', Navigation);
app.component(Vue3CountryIntl.name, Vue3CountryIntl);

// Filters
import { formatDate } from './filters/dateUtils.js';

// Google recaptcha site key
app.config.globalProperties.$googleRecaptchaSiteKey = '6LecOWEUAAAAAL9AUSqbWz_ngQb-pBvIVWFiYQGn';
app.config.globalProperties.$featureFlag = [];
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$formatDate = formatDate;

app.mount('#app');
