<template>
  <div class="order-history">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="3"> Bestellingen </v-heading>
      </div>
      <div
        v-if="isActiveLicense && !patientGegevens.IsDeceased && !isArchived"
        class="col-xs-12 col-md-6 grid-align-end"
      >
        <v-button id="orderBtn" md cta icon-left @click.prevent="goToCatalog()">
          <v-plus-circle-icon />
          Nieuwe bestelling
        </v-button>
      </div>
    </div>
    <div v-if="orderHistory.length === 0" class="row grid-align-center grid-padding callout">
      <div class="col-xs-8">
        Er zijn geen bestellingen geplaatst binnen uw instelling of de patiënt wenst geen gegevens te delen.
      </div>
    </div>
    <table v-else class="order-history__table">
      <thead>
        <v-header-row :data="orderHeader" />
      </thead>
      <v-order-history-entry v-for="(item, index) in ordersByCustomFilter" :key="index" :index="index" :entry="item">
        <template v-slot:details>
          <v-order-history-entry-products
            v-if="item.customerOrderLines && item.customerOrderLines.length > 0"
            :products="item.customerOrderLines"
            :show-re-order="isActiveLicense"
            :deliveryaddress="item.deliveryAddress"
            :patient-deceased-status="patientGegevens.IsDeceased"
            :is-archived="isArchived"
            :is-equipment-order="item.isEquipmentOrder"
          />
        </template>
      </v-order-history-entry>
    </table>
    <!--Show More/Less Functionality -->
    <div v-if="orderHistory.length > filterCountDefault" class="row grid-justify-content-center">
      <div>
        <v-button hollow md class="filter-count-button" @click="toggleFilterCount">
          <template v-if="!filterCount">
            {{ filterCountButtonTextOptions.less }}
          </template>
          <template v-if="filterCount > 0">
            {{ filterCountButtonTextOptions.more }}&nbsp;
            <span v-if="orderHistory.length > filterCountDefault">({{ orderHistory.length }})</span>
          </template>
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { mapActions, useStore } from 'vuex';
import { computed } from 'vue';
import VOrderHistoryEntry from '@/components/sections/patientOrderHistory/PatientOrderHistoryEntry.vue';
import VOrderHistoryEntryProducts from '@/components/sections/patientOrderHistory/PatientOrderHistoryEntryProducts.vue';
import VPlusCircleIcon from 'icons/PlusCircle';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';

export default {
  name: 'PatientOrderHistory',
  components: {
    VOrderHistoryEntry,
    VOrderHistoryEntryProducts,
    VPlusCircleIcon,
    VHeaderRow
  },
  props: {
    isActiveLicense: {
      default: false,
      type: Boolean
    },
    isArchived: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();

    const orderHistory = computed(() => store.getters['patientOrderHistory/getOrderHistory']);
    const demoFlowStatus = computed(() => store.getters['navigation/getDemoFlowStatus']);
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);

    return {
      orderHistory,
      demoFlowStatus,
      patientGegevens,
      ...mapActions({
        getOrderHistory: 'patientOrderHistory/getOrderHistory'
      })
    };
  },
  data() {
    return {
      orderHeader: ['', 'Bestel nr.', 'Besteld door', 'Type', 'Besteld op', 'Levering', 'Status'],
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      filterCountDefault: 5,
      filterCount: null
    };
  },
  computed: {
    /**
     * @returns {array} - list with orders filtered by dynamic value of count and sliced by dynamic value of filtercount
     */
    ordersByCustomFilter() {
      const computedOrders = this.orderHistory;
      if (computedOrders.length > 0) {
        // Slice the orders by the filter count if active else return all
        return this.filterCount ? computedOrders.slice(0, this.filterCount) : computedOrders;
      } else {
        return [];
      }
    }
  },
  watch: {},
  created() {
    this.getOrderHistory();
    this.filterCount = this.filterCountDefault;
  },
  methods: {
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    goToCatalog() {
      router.push('/catalog');
    }
  }
};
</script>

<style lang="scss" scoped>
.order-history {
  &__table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 24px;
    text-align: left;
    table-layout: auto !important;
    :deep(.order-history__entry__data > td:first-child) {
      cursor: pointer;
    }
  }
}
</style>
