import axios from 'axios'
export default {
  namespaced: true,
  state: {
    contactInfo: {},
    subjectTypes : {} = [
      { 'text' : 'Bestellen', 'value' : 0 },
      { 'text' : 'Levering', 'value' : 1 },
      { 'text' : 'Producten en/of assortiment', 'value' : 2 },
      { 'text' : 'Gebruik voorschrijfportaal', 'value' : 3 },
    ]
  },
  mutations: {
    setContactInfo (state, payload) {
      state.contactInfo = payload
    }
  },
  actions: {
    submitContactForm ({ commit, state }, data) {
      return axios.post('ContactForm/Send', data)
    },
    fetchContactInfo ({ commit }) {
      return axios.get('ContactForm/GetContactInfo').then((response) => {
        commit('setContactInfo', response.data.ContactInfo)
      })
    }
  },
  getters: {
    getContactInfo: state => {
      return state.contactInfo
    },
    getSubjectTypes: state => {
      return state.subjectTypes
    }
  }
}
