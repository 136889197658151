<template>
  <div
    id="catalogBundles"
    class="bundles"
  >
    <legend class="bundles__Title">
      Bestelpakketten
    </legend>
    <transition-group
      class="bundles__list"
      tag="ul"
      name="transition-up-down"
    >
      <v-bundle
        v-for="bundle in bundles"
        :key="bundle.illnessBundleId"
        :bundle="bundle"
      />
    </transition-group>
  </div>
</template>

<script>
import VBundle from './Bundle'

export default {
  name: 'Bundles',
  components: {
    VBundle
  },
  props: {
    bundles: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  unmounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bundles {
  &__list {
    padding: 0;
    column-count: 2;
  }

  &__Title {
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $teal-darkest;
    margin: 0px 0px 15px 0px;
  }
}
</style>
