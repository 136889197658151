<template>
  <section v-if="user" class="container stand-alone-accountability">
    <div class="row">
      <div class="col-xs-12 col-md-9">
        <v-heading :level="3"> Machtiging toevoegen </v-heading>
      </div>
      <div class="col-md-3 grid-align-end">
        <v-button link @click="openForwardModal">
          <v-forward-icon class="icon-color-teal" />Doorsturen naar collega
        </v-button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <v-heading :level="4">
          {{ licenseWithTreatmentAreaName }}
        </v-heading>
      </div>
    </div>
    <table id="step1" class="table">
      <colgroup>
        <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
      </colgroup>
      <thead class="head">
        <tr>
          <th>Machtiging gegevens</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <!--WLZ QUESTION -->
            <div class="mb" v-if="isWLZ">
              <legend class="bold">
                Deze patiënt heeft een WLZ-indicatie. Stuur de rekening naar de volgende instelling:
              </legend>
              <model-list-select
                placeholder="Zoek op Instelling, plaats of postcode"
                :list="WLZList"
                option-value="value"
                option-text="text"
                @searchchange="searchWLZItem"
                v-model="WLZItem"
              >
              </model-list-select>
            </div>
            <!--ZVZ QUESTION -->
            <div class="mb" v-if="isZVZ">
              <legend class="bold">
                De gekozen medische hulpmiddelen en/of medicatie kunnen niet direct naar de zorgverzekering van de
                patiënt worden gedeclareerd door Mediq. Stuur de factuur naar de volgende instelling:
              </legend>
              <model-list-select
                placeholder="Zoek op Instelling, plaats of postcode"
                :list="ZVZList"
                option-value="value"
                option-text="text"
                @searchchange="searchZVZItem"
                v-model="ZVZItem"
              >
              </model-list-select>
            </div>

            <div v-if="(isWLZ && WLZArg.WLZId) || !isWLZ" name="card-animation">
              <fieldset v-for="(question, indexquestion) in questions" :key="indexquestion" :data-index="indexquestion">
                <legend v-if="question.QuestionId != '1000'">
                  {{ question.Tekst }}
                </legend>
                <p v-if="question.Opmerking.length">
                  {{ question.Opmerking }}
                </p>
                <div v-for="(answer, index) in question.Antwoorden" :key="index" :data-index="index">

                  <!-- FREQUENCY INDICATOR -->
                  <div class="frequency-indicator" v-if="answer.TypeCode === 'Frequency'">
                    <form @submit="submitFrequencyAnswer" :name="question.AccountabilityLineId" :answerId="answer.AnswerId">
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <v-select-group
                          label="Kies aantal"
                          :selectid="'frequencyValue'"
                          @change="updateFrequency"
                          style="max-width: 150px;"
                        >
                          <option v-for="n in 99" :key="n" :value="n">{{n}}</option>
                        </v-select-group>
                        <label for="intervalNumber" style="padding-top: 17px;">keer per</label>
                        <v-select-group
                          label="Kies hoeveelheid"
                          :selectid="'intervalNumber'"
                          @change="updateIntervalNumber"
                          style="max-width: 150px;"
                          class="frequency-indicator-label"
                        >
                          <option v-for="n in 99" :key="n" :value="n">{{n}}</option>
                        </v-select-group>
                        <v-select-group
                          label="Dagen/weken"
                          :selectid="'intervalType'"
                          @change="updateIntervalType"
                          style="max-width: 200px;"
                        >
                          <option value="D">Dagen</option>
                          <option value="W">Weken</option>
                        </v-select-group>
                        <v-button class="mt-1" type="submit" :id="answer.AnswerId + 'btn'" lg hollow :disabled="frequencyIndicator.length == 0" style="margin-top: 27px;">Ga verder</v-button>
                      </div>
                      <p style="margin-left: 10px;" v-show="frequencyIndicator.length > 0">Gekozen frequency: {{frequencyValue}} keer per {{ intervalNumber }} {{ intervalTypeFull }}</p>
                    </form>
                  </div>

                  <!--STANDARD (RADIO) QUESTION-->
                  <div v-if="answer.TypeCode === 'Standard'" class="form-group__radio">
                    <input
                      :id="answer.AnswerId"
                      :checked="
                        question.ResultaatAnswers.length > 0 &&
                        question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                      "
                      :name="question.AccountabilityLineId"
                      type="radio"
                      @change="checkRadioAnswer"
                    />
                    <label :for="answer.AnswerId"><span></span>{{ answer.AntwoordTekst }}</label>
                  </div>

                  <!--STANDARD (RADIO) QUESTION WITH OPEN (TextInput) -->
                  <div v-if="answer.TypeCode === 'OpenStandaard'">
                    <div class="form-group__radio">
                      <input
                        :id="answer.AnswerId"
                        :checked="
                          checkRadioStatus(question.ResultaatAnswers, answer.AnswerId, question.AccountabilityLineId)
                        "
                        :name="question.AccountabilityLineId"
                        type="radio"
                        @change="checkedRadioAnswerStandard(answer.AnswerId, question.AccountabilityLineId)"
                      />
                      <label :for="answer.AnswerId"><span></span>{{ answer.AntwoordTekst }}</label>
                    </div>
                    <form
                      :id="'form' + question.AccountabilityLineId"
                      class="openquestion"
                      style="display: none"
                      :name="question.AccountabilityLineId"
                      @submit="submitTypeQuestionOpen"
                    >
                      <div class="row">
                        <div class="col-xs-12 col-md-8">
                          <div v-if="question.ResultaatAnswers.length > 0">
                            <v-input-group-simple
                              type="text"
                              :inputid="answer.AnswerId + 'txt'"
                              :initvalue="question.ResultaatAnswers[0].Resultaat"
                              sm
                              alt
                              @input="
                                ChangeRadioText(
                                  $event,
                                  question.ResultaatAnswers[0].Resultaat,
                                  question.AccountabilityLineId
                                )
                              "
                            />
                          </div>
                          <div v-else>
                            <v-input-group-simple
                              type="text"
                              :inputid="answer.AnswerId + 'txt'"
                              sm
                              alt
                              @input="input"
                            />
                          </div>
                          <div :id="'div' + question.AccountabilityLineId" class="details" style="display: none">
                            <div class="stand-alone-accountability__error">
                              Het is verplicht dit veld in te vullen en op de knop 'Ga verder' te klikken.
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                          <div
                            v-if="
                              question.ResultaatAnswers.length > 0 &&
                              question.ResultaatAnswers[0].AnswerTypeId === '268'
                            "
                          >
                            <div v-if="currentRadioAnswerId === answer.AnswerId && isRadioTextChanged === true">
                              <v-button
                                :id="answer.AnswerId + 'btn'"
                                type="submit"
                                lg
                                hollow
                                :disabled="getNextRadioButtonStatus(answer.AnswerId)"
                              >
                                Ga verder
                              </v-button>
                            </div>
                            <div v-else>
                              <v-button :id="answer.AnswerId + 'btn'" type="submit" lg hollow> Opgeslagen </v-button>
                            </div>
                          </div>
                          <div v-else>
                            <v-button
                              :id="answer.AnswerId + 'btn'"
                              type="submit"
                              lg
                              hollow
                              :disabled="getNextRadioButtonStatus(answer.AnswerId)"
                            >
                              Ga verder
                            </v-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <!--DROPDOWN QUESTION -->
                  <v-select-group
                    v-if="answer.TypeCode === 'Dropdown'"
                    placeholder="Maak een keuze"
                    :selectid="question.AccountabilityLineId.toString()"
                    :data-answerid="answer.AnswerId"
                    :initselected="
                      question.ResultaatAnswers.length > 0 && question.ResultaatAnswers[0].AnswerListItemId
                    "
                    @change="checkSelectAnswer($event)"
                  >
                    <option
                      v-for="(option, indexoptions) in answer.AntwoordLijst"
                      :key="indexoptions"
                      :data-index="indexoptions"
                      :initvalue="option.AnswerListItemId"
                      :value="option.AnswerListItemId"
                      :data-answerid="answer.AnswerId"
                    >
                      {{ option.Omschrijving }}
                    </option>
                  </v-select-group>

                  <!--OPEN (TextInput) QUESTION -->
                  <form
                    v-if="answer.TypeCode === 'Open'"
                    :name="question.AccountabilityLineId"
                    @submit="submitTypeQuestionOpen"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div v-if="question.ResultaatAnswers.length > 0">
                          <v-input-group-simple
                            type="text"
                            :inputid="answer.AnswerId"
                            :initvalue="question.ResultaatAnswers[0].Resultaat"
                            sm
                            alt
                            @input="
                              ChangeText($event, question.ResultaatAnswers[0].Resultaat, question.AccountabilityLineId)
                            "
                          />
                        </div>
                        <div v-else>
                          <v-input-group-simple type="text" :inputid="answer.AnswerId" sm alt @input="input" />
                        </div>
                        <div :id="'div' + question.AccountabilityLineId" class="details" style="display: none">
                          <div class="stand-alone-accountability__error">
                            Het is verplicht dit veld in te vullen en op de knop 'Ga verder' te klikken.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div v-if="question.ResultaatAnswers.length > 0">
                          <div v-if="currentAnswerId === answer.AnswerId && isTextChanged === true">
                            <v-button
                              :id="answer.AnswerId + 'btn'"
                              type="submit"
                              lg
                              hollow
                              :disabled="getNextButtonStatus(answer.AnswerId)"
                            >
                              Ga verder
                            </v-button>
                          </div>
                          <div v-else>
                            <v-button :id="answer.AnswerId + 'btn'" type="submit" lg hollow> Opgeslagen </v-button>
                          </div>
                        </div>
                        <div v-else>
                          <v-button
                            :id="answer.AnswerId + 'btn'"
                            type="submit"
                            lg
                            hollow
                            :disabled="getNextButtonStatus(answer.AnswerId)"
                          >
                            Ga verder
                          </v-button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <!--CHECKBOX QUESTION and also handle text box also-->
                  <form
                    v-if="answer.TypeCode === 'Checkbox' || answer.TypeCode === 'CheckboxWithTextbox'"
                    :name="question.AccountabilityLineId"
                    @submit="submitCheckbox($event, answer)"
                  >
                    <div v-for="(checkbox, index) in answer.AntwoordLijst" :key="index" class="row margin-bottom">
                      <div class="col-md-4">
                        <v-checkbox-group
                          :label="checkbox.Omschrijving"
                          :inputid="checkbox.AnswerListItemId"
                          :initvalue="getCheckboxStatus(question.ResultaatAnswers, checkbox, answer)"
                          :overwrite-value="checkbox.IsOpen"
                          :name="answer.AnswerId"
                          @update:model-value="change($event, checkbox, answer.AnswerId, answer.TypeCode)"
                        />
                      </div>
                      <!-- Text box option for each checkbox option-->
                      <div
                        :id="'form' + checkbox.AnswerListItemId"
                        class="col-md-6 openquestion"
                        :name="question.AccountabilityLineId"
                        style="display: none"
                      >
                        <div
                          v-if="
                            question.ResultaatAnswers.length > 0 &&
                            checkCheckBoxTextAns(question.ResultaatAnswers, checkbox.AnswerListItemId, 'status')
                          "
                        >
                          <v-input-group-simple
                            :placeholder="checkbox.Placeholder"
                            type="text"
                            :inputid="'text' + checkbox.AnswerListItemId"
                            :initvalue="
                              checkCheckBoxTextAns(question.ResultaatAnswers, checkbox.AnswerListItemId, 'value')
                            "
                            sm
                            alt
                            @input="inputCheckBox(...arguments, answer.AnswerId)"
                          />
                        </div>
                        <div v-else>
                          <v-input-group-simple
                            :placeholder="checkbox.Placeholder"
                            type="text"
                            :inputid="'text' + checkbox.AnswerListItemId"
                            sm
                            alt
                            @input="inputCheckBox(...arguments, answer.AnswerId)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div :id="'remark-' + question.AccountabilityLineId" class="col-xs-12 remark hide-msg">
                        *Selecteer ten minste één antwoord.
                      </div>
                      <div class="col-xs-3">
                        <v-button :id="answer.AnswerId + 'btn'" type="submit" md hollow> Volgende vraag </v-button>
                      </div>
                    </div>
                  </form>

                  <!--DATE QUESTION-->
                  <div v-if="answer.TypeCode === 'Date'" class="form-group__date">
                    <v-input-date-picker
                      :id="answer.AnswerId"
                      ref="date"
                      type="date"
                      name="dateQuestion"
                      :custom-key="question.AccountabilityLineId"
                      :customObject="question.ResultaatAnswers"
                      autofocus
                      :empty-date="!getDateValue(question.ResultaatAnswers, 'Resultaat')"
                      :value="getDateValue(question.ResultaatAnswers, 'Resultaat')"
                      @selected="handleDateAnswer"
                    />
                  </div>

                  <!-- PersonalAccountabilityProductGroup2 QUESTION-->
                  <form
                    v-if="answer.TypeCode === 'PersonalAccountabilityProductGroup2'"
                    :id="answer.AnswerId"
                    :name="question.AccountabilityLineId"
                    @submit="submitPersonalAccountabilityProductGroup2($event)"
                  >
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Productgroep</div>
                      <div class="col-md-6">
                        <v-select-group
                          v-if="answer.ProductGroupList && answer.ProductGroupList.length > 0"
                          placeholder="Maak een keuze"
                          :selectid="'selectProduct-' + question.AccountabilityLineId"
                          :data-answerid="answer.AnswerId"
                          :initselected="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].ProductGroup.length > 0 &&
                            question.ResultaatAnswers[0].ProductGroup[0].ProductGroupId
                          "
                        >
                          <option
                            v-for="(option, indexoptions) in answer.ProductGroupList"
                            :key="indexoptions"
                            :data-index="indexoptions"
                            :initvalue="option.ProductGroupId"
                            :value="option.ProductGroupId"
                            :data-answerid="answer.AnswerId"
                          >
                            {{ option.Name }}
                          </option>
                        </v-select-group>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">
                        Aantal stuks extra per periode
                      </div>
                      <div class="col-md-6 groupText">
                        <div
                          v-if="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                          "
                        >
                          <v-input-group-simple
                            type="number"
                            :inputid="'Aantal-' + question.AccountabilityLineId.toString()"
                            :initvalue="question.ResultaatAnswers[0].Aantal"
                            sm
                            alt
                          />
                        </div>
                        <div v-else>
                          <v-input-group-simple
                            type="number"
                            :inputid="'Aantal-' + question.AccountabilityLineId.toString()"
                            sm
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Periode</div>
                      <div class="col-md-6">
                        <v-select-group
                          v-if="answer.PeriodList && answer.PeriodList.length > 0"
                          placeholder="Maak een keuze"
                          :selectid="'selectPeriod-' + question.AccountabilityLineId.toString()"
                          :data-answerid="answer.AnswerId"
                          :initselected="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].Period.length > 0 &&
                            question.ResultaatAnswers[0].Period[0].PeriodId
                          "
                        >
                          <option
                            v-for="(option, indexoptions) in answer.PeriodList"
                            :key="indexoptions"
                            :data-index="indexoptions"
                            :initvalue="option.PeriodId"
                            :value="option.PeriodId"
                            :data-answerid="answer.AnswerId"
                          >
                            {{ option.Description }}
                          </option>
                        </v-select-group>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div :id="'remark-' + question.AccountabilityLineId" class="col-xs-12 remark hide-msg">
                        *Gelieve alle velden in te vullen.
                      </div>
                      <div class="col-xs-4">
                        <v-button type="submit" lg hollow> Volgende vraag </v-button>
                      </div>
                    </div>
                  </form>

                  <!-- PersonalAccountabilityProductGroup3 QUESTION-->
                  <form
                    v-if="answer.TypeCode === 'PersonalAccountabilityProductGroup3'"
                    :id="answer.AnswerId"
                    :name="question.AccountabilityLineId"
                    @submit="submitPersonalAccountabilityProductGroup3($event)"
                  >
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Productgroep</div>
                      <div class="col-md-6">
                        <v-select-group
                          v-if="answer.ProductGroupList && answer.ProductGroupList.length > 0"
                          placeholder="Maak een keuze"
                          :selectid="'selectProduct-' + question.AccountabilityLineId"
                          :data-answerid="answer.AnswerId"
                          :initselected="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].ProductGroup.length > 0 &&
                            question.ResultaatAnswers[0].ProductGroup[0].ProductGroupId
                          "
                        >
                          <option
                            v-for="(option, indexoptions) in answer.ProductGroupList"
                            :key="indexoptions"
                            :data-index="indexoptions"
                            :initvalue="option.ProductGroupId"
                            :value="option.ProductGroupId"
                            :data-answerid="answer.AnswerId"
                          >
                            {{ option.Name }}
                          </option>
                        </v-select-group>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">
                        Aantal stuks extra per periode
                      </div>
                      <div class="col-md-6 groupText">
                        <div
                          v-if="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                          "
                        >
                          <v-input-group-simple
                            type="number"
                            :inputid="'Aantal-' + question.AccountabilityLineId.toString()"
                            :initvalue="question.ResultaatAnswers[0].Aantal.toString()"
                            sm
                            alt
                          />
                        </div>
                        <div v-else>
                          <v-input-group-simple
                            type="number"
                            :inputid="'Aantal-' + question.AccountabilityLineId.toString()"
                            sm
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Periode</div>
                      <div class="col-md-6">
                        <v-select-group
                          v-if="answer.PeriodList && answer.PeriodList.length > 0"
                          placeholder="Maak een keuze"
                          :selectid="'selectPeriod-' + question.AccountabilityLineId.toString()"
                          :data-answerid="answer.AnswerId"
                          :initselected="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].Period.length > 0 &&
                            question.ResultaatAnswers[0].Period[0].PeriodId
                          "
                        >
                          <option
                            v-for="(option, indexoptions) in answer.PeriodList"
                            :key="indexoptions"
                            :data-index="indexoptions"
                            :initvalue="option.PeriodId"
                            :value="option.PeriodId"
                            :data-answerid="answer.AnswerId"
                          >
                            {{ option.Description }}
                          </option>
                        </v-select-group>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Startdatum</div>
                      <div class="col-md-6">
                        <div class="form-group__date">
                          <v-input-date-picker
                            :id="'startDate-' + question.AccountabilityLineId"
                            ref="date"
                            type="date"
                            :name="'startDate-' + question.AccountabilityLineId"
                            :custom-key="question.AccountabilityLineId"
                            autofocus
                            :value="getDateValue(question.ResultaatAnswers, 'Startdatum')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div class="col-md-3 stand-alone-accountability__categoryLabel">Einddatum</div>
                      <div class="col-md-6">
                        <div class="form-group__date">
                          <v-input-date-picker
                            :id="'endDate-' + question.AccountabilityLineId"
                            ref="date"
                            type="date"
                            :name="'endDate-' + question.AccountabilityLineId"
                            :custom-key="question.AccountabilityLineId"
                            autofocus
                            :value="getDateValue(question.ResultaatAnswers, 'Einddatum')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row grid-margin grid-padding">
                      <div :id="'remark-' + question.AccountabilityLineId" class="col-xs-12 remark hide-msg">
                        *Gelieve alle velden in te vullen. Einddatum mag niet eerder zijn dan de startdatum.
                      </div>
                      <div class="col-xs-4">
                        <v-button type="submit" lg hollow> Volgende vraag </v-button>
                      </div>
                    </div>
                  </form>

                  <!-- File upload Question-->
                  <div
                    v-if="answer.TypeCode === 'FileUpload'"
                    class="row grid-margin grid-padding stand-alone-accountability__fileUpload"
                  >
                    <div
                      v-for="(dataFile, index) in question.ResultaatAnswers"
                      v-show="question.ResultaatAnswers.length > 0"
                      :key="index"
                      class="form-group"
                    >
                      <fieldset>
                        <legend>Geüploade bestanden</legend>
                        <img v-if="fileExtention(dataFile.Resultaat) === 'pdf'" src="@/static/img/pdf-icon.png" />
                        <img v-else src="@/static/img/img-icon.svg" />
                        <a>{{ dataFile.Resultaat }}</a>
                        <div class="stand-alone-accountability__fileUpload__date">
                          Geüpload op {{ moment(dataFile.FileUploadedDate).format('DD-MM-YYYY') }}
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-xs-12">
                      <v-input-file-upload
                        :id="answer.AnswerId"
                        :name="question.AccountabilityLineId"
                        :uploaded-files-data="[]"
                        :allowed-extenstions="allowedFileExtenstions"
                        :extension-error-message="extensionErrorMessage"
                        drop-area-secondary-message="(max. 2MB voor pdf en 5mb voor afbeelding; alleen .pdf, png, jpeg, jpg)"
                        @upload-success="submitUploadedFiles"
                      />
                      <div
                        v-if="showUploadFileErrorMessage"
                        class="stand-alone-accountability__fileUpload__errorMessage"
                      >
                        * Dit veld is verplicht.
                      </div>
                    </div>
                  </div>
                  <!-- list search answer-->
                  <div class="mb" v-if="question.AnswerTypeListSearch > 0">
                    <v-list-answer-type
                      :listSearchItems="ListSearchItems"
                      :accountabilityResult="question.ResultaatAnswers[0]"
                      @selected="onListSearchChange(question.AccountabilityLineId, answer.AnswerId, $event)"
                    ></v-list-answer-type>
                  </div>
                  <v-warning-message
                    v-if="
                      question.ResultaatAnswers.length > 0 &&
                      question.ResultaatAnswers[0].AnswerId === answer.AnswerId &&
                      answer.Opmerking
                    "
                    :message="answer.Opmerking"
                    :html="true"
                  ></v-warning-message>
                </div>
              </fieldset>

              <!--Start DATE QUESTION-->
              <div v-if="showStartDate()" class="form-group__date">
                <v-input-date-picker
                  ref="startDate"
                  type="date"
                  name="startDate"
                  label="Startdatum therapie:"
                  autofocus
                  custom-date-range
                  accountabiliy-start-date
                  @selected="handleStartDate"
                />
              </div>

            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-if="isLastAnswer && canSign" id="step2" class="table stand-alone-accountability__signature">
      <thead class="head">
        <tr>
          <th colspan="2">Handtekening</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="hasMultipleSignees">
          <td colspan="2">
            <v-select-group
              label="Beschikbare volmachtgevers"
              selectid="warranter"
              initselected=""
              @change="setSelectedSigneePpwRecId($event)"
              placeholder="Selecteer volmachtgever"
            >
              <option
                v-for="(signee, index) in signees"
                :key="index"
                :data-index="index"
                :initvalue="signee.ppwRecId"
                :value="signee.ppwRecId"
              >
                {{ signee.name }}
              </option>
            </v-select-group>
          </td>
        </tr>
        <tr v-if="selectedSignee">
          <td>
            <prescriber-signature :user-id="selectedSignee.userId" /><br /><br />
            <span v-if="selectedSignee.userId !== user.id">Volmachtgever: {{ selectedSignee.name }}</span>
          </td>
          <td>Door te klikken op de knop ondertekenen, signeert u deze machtiging met uw digitale handtekening.</td>
        </tr>
      </tbody>
    </table>
    <div id="step3" class="row">
      <div class="grid-align-start">
        <v-button
          md
          secondary
          nowrap
          class="save-accountability"
          :class="{ 'btn--disabled': isSignButtonDisabled() }"
          @click.once="saveAccountability"
        >
          <sync-loader v-if="isLoading" />
          <div v-else>ONDERTEKENEN</div>
        </v-button>
      </div>
      <div class="col grid-align-start">
        <a class="cancel-accountability" @click="cancelAccountability">Annuleren</a>
      </div>
      <div class="col-md-6 grid-align-end">
        <v-button link @click="openForwardModal">
          <v-forward-icon class="icon-color-teal" />Doorsturen naar collega
        </v-button>
      </div>
    </div>
    <!-- Modal section -->
    <generic-modal v-if="fwAccountabilityPopUp">
      <template #header>
        {{ forwardAccountabilityPopup.PopupTitle }}
      </template>
      <template #body>
        <p>{{ forwardAccountabilityPopup.PopupText }}</p>
      </template>
      <template #footer>
        <router-link :to="'/patientdashboard'" class="btn btn--primary btn--md">
          {{ forwardAccountabilityPopup.PopupButton }}
        </router-link>
      </template>
    </generic-modal>
    <generic-modal v-if="illnessIdStatus">
      <template #header> Ondertekenen niet mogelijk </template>
      <template #body>
        <p>
          De vragenlijst is zodanig ingevuld dat hiervoor geen machtiging aangemaakt kan worden. Wijzig de vragenlijst
          of sluit de vragenlijst af.
        </p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="cancelAccountability()"> Terug naar patiënt </v-button>
        <v-button md primary @click.prevent="closeIllnessPopUp()"> Vragenlijst wijzigen </v-button>
      </template>
    </generic-modal>
    <generic-modal v-if="showPumpAssignPopUp">
      <template #header> Ondertekening succesvol. </template>
      <template #body>
        <p>
          Uw machtiging is toegevoegd aan het dashboard van de patiënt.
          <span v-if="pumpAssignment">U kunt nu een pomp toevoegen aan de machtiging.</span>
        </p>
      </template>
      <template #footer>
        <router-link :to="'/patientdashboard'" class="btn btn--hollow btn--transparent btn--md">
          {{ forwardAccountabilityPopup.PopupButton }}
        </router-link>
        <v-button v-if="pumpAssignment" md primary @click.prevent="goToPumpAssign()"> Naar pomp toevoegen </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import router from '@/router';
import { useStore, mapActions } from 'vuex';
import { computed, ref } from 'vue';
import PrescriberSignature from '@/components/sections/prescriber/PrescriberSignature';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VButton from '@/components/shared/Button.vue';
import VForwardModal from './ForwardModal';
import VForwardIcon from 'icons/ArrowRightBoldBox';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import moment from 'moment';
import VWarningMessage from '@/components/shared/WarningMessage';
import VInputFileUpload from '@/components/shared/form/simple/InputFileUpload';
import shared from '@/components/shared/shared';
import { localDateToJson, stringToLocalDate } from '@/services/date.service';
import GenericModal from '@/components/shared/GenericModal';
import { BasicSelect, ModelListSelect } from 'vue-search-select';
import VListAnswerType from './ListSearchAnswerType';
import {
  shoppingCart$,
  addToShoppingCart,
  addIllnessBundleToShoppingCart,
  activePpwRecId$,
  currentUserIdentity$,
  getAccountabilityRootStatus,
  getSignees,
  invalidateLicenseOverviews$
} from '@/services';
import { router$ } from '@/router/router$';
import { map, filter, publishReplay, switchMap, refCount } from 'rxjs/operators';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
  name: 'StandaloneAccountability',
  components: {
    VButton,
    VCheckboxGroup,
    VForwardIcon,
    VInputDatePicker,
    VWarningMessage,
    GenericModal,
    VInputFileUpload,
    PrescriberSignature,
    SyncLoader,
    VListAnswerType,
    ModelListSelect
  },
  setup() {
    const store = useStore();
    const user = ref(null);
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);
    const accountabilityId = ref(null);
    const accountability = ref(null);
    const treatmentAreaId = ref(null);
    const signees = ref([]);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const licenseWithTreatmentAreaName = computed(
      () => store.getters['accountability/getLicenseWithTreatmentAreaName']
    );
    const questions = computed(() => store.getters['accountability/getQuestions']);
    const isLastAnswer = computed(() => store.getters['accountability/getIsLastAnswer']);
    const isSingleAccountability = computed(() => store.getters['accountability/getIsSingleAccountability']);
    const isNewAccountability = computed(() => store.getters['accountability/getIsNewAccountability']);
    const isStartDate = computed(() => store.getters['accountability/getIsStartDate']);
    const isWLZ = computed(() => store.getters['accountability/getIsWLZ']);
    const isZVZ = computed(() => store.getters['accountability/getIsZVZ']);

    const forwardAccountabilityPopup = computed(() => store.getters['accountability/getForwardAccountabilityPopup']);
    const siteCoreState = store.state.navigation.siteCode;
    const ListSearchItems = computed(() => store.getters['accountability/getListSearchItems']);

    const getCurrentPatient = async () => await store.dispatch('patient/getCurrentPatient');
    const setIsLastAnswer = (value) => store.commit('accountability/setIsLastAnswer', value);
    const setQuestions = (value) => store.commit('accountability/setQuestions', value);
    const getQuestions = async (value) => await store.dispatch('accountability/getQuestions', value);
    const setWLZId = (value) => store.dispatch('accountability/setWLZId', value);
    const setZVZId = (value) => store.dispatch('accountability/setZVZId', value);
    const setAnswer = (value) => store.dispatch('accountability/setAnswer', value);
    const cancelAccountabilityForm = (value) => store.dispatch('accountability/cancelAccountabilityForm', value);
    const saveStandAloneAccountability = (value) =>
      store.dispatch('accountability/saveStandAloneAccountability', value);
    const setCartOpenStatus = (value) => store.commit('cart/setCartOpenStatus', value);
    const saveAccountabilityUpload = (value) => store.dispatch('accountability/saveAccountabilityUpload', value);

    const accountabilityId$ = router$.pipe(
      map((route) => route && route.params.id),
      filter((accountabilityId) => !!accountabilityId)
    );

    const loadAccountability = async (accountabilityId) => {
      // initialize accountability / questions
      var accountabilityRootStatus = await getAccountabilityRootStatus(accountabilityId);
      if (accountabilityRootStatus === null) {
        // todo: reset page (/s very acceptable: _we like happy flows_)
        return;
      }

      const response = await getQuestions(accountabilityId);
      return response;
    };

    const accountability$ = accountabilityId$.pipe(
      switchMap((accountabilityId) => loadAccountability(accountabilityId)),
      publishReplay(1),
      refCount()
    );

    const treatmentAreaId$ = accountability$.pipe(map((accountability) => accountability.TreatmentAreaId));

    const signees$ = treatmentAreaId$.pipe(
      switchMap((treatmentAreaId) => getSignees(treatmentAreaId)),
      publishReplay(1),
      refCount()
    );

    accountabilityId$.subscribe((id) => (accountabilityId.value = id));
    treatmentAreaId$.subscribe((id) => (treatmentAreaId.value = id));
    signees$.subscribe((data) => (signees.value = data));
    currentUserIdentity$.subscribe((userData) => (user.value = userData));
    activePpwRecId$.subscribe((value) => (activePpwRecId.value = value));
    shoppingCart$.subscribe((value) => (shoppingCart.value = value));
    accountability$.subscribe((value) => (accountability.value = value));

    return {
      store,
      ListSearchItems,
      patientGegevens,
      licenseWithTreatmentAreaName,
      questions,
      isLastAnswer,
      isSingleAccountability,
      isNewAccountability,
      isStartDate,
      isWLZ,
      isZVZ,
      forwardAccountabilityPopup,
      siteCoreState,
      getCurrentPatient,
      setIsLastAnswer,
      setQuestions,
      getQuestions,
      setWLZId,
      setZVZId,
      setAnswer,
      cancelAccountabilityForm,
      saveStandAloneAccountability,
      setCartOpenStatus,
      saveAccountabilityUpload,
      ...mapActions({
        forwardAccountability: 'accountability/forwardAccountability'
      }),
      accountabilityId,
      treatmentAreaId,
      signees,
      user,
      activePpwRecId,
      shoppingCart,
      accountability
    };
  },
  data() {
    return {
      pumpAssignment: null,
      searchLicenseText: 'Geen machtiging beschikbaar',
      startDate: null,
      therapyStartDate:null,
      illnessIdStatus: false,
      buttonDisableStatus: false,
      isTextChanged: false,
      currentAnswerId: 0,
      stringItem: '',
      WLZItem: {
        value: null,
        text: null
      },
      WLZArg: {
        WLZId: null
      },
      ZVZItem: {
        value: null,
        text: null
      },
      ZVZArg: {
        ZVZId: null
      },
      ListSearchItem: {
        value: null,
        text: null
      },
      isRadioTextChanged: false,
      currentRadioAnswerId: 0,
      isOpenTextValidated: true,
      fwAccountabilityPopUp: false,
      showUploadFileErrorMessage: false,
      showPumpAssignPopUp: false,
      selectedSigneePpwRecId: '',
      isLoading: false,
      allowedFileExtenstions: ['pdf', 'jpg', 'jpeg', 'png'],
      extensionErrorMessage:
        'Het geüploade document heeft niet de juiste extensie. U kunt alleen PDF, JPEG, JPG, of PNG documenten uploaden.',
      ZVZList: [],
      WLZList: [],
      frequencyValue: 2,
      intervalNumber: 10,
      intervalType: 'D',
      frequencyIndicator: ''
    }
  },
  computed: {
    canSign() {
      return this.signees && this.signees.length > 0;
    },
    hasMultipleSignees() {
      return this.signees && this.signees.length > 1;
    },
    hasSingleSignee() {
      return this.signees && this.signees.length === 1;
    },
    selectedSignee() {
      if (this.hasSingleSignee) {
        return this.signees[0];
      } else if (this.hasMultipleSignees) {
        return this.signees.find((v) => v.ppwRecId == this.selectedSigneePpwRecId);
      }

      return null;
    },
    isSignable() {
      return this.isLastAnswer && this.canSign && this.selectedSignee;
    },
    intervalTypeFull() {
      const types = {
        'D': 'Dagen',
        'W': 'Weken',
      };
      return types[this.intervalType] || this.intervalType;
    },
  },
  watch: {
    isLastAnswer(value) {
      const self = this;
      if (value) {
        // Checking the illenss error pop up possibility after every question submit and last answer submit
        this.showIllnessPopUp();
        this.checkUploadFileStatus(self);
      }
    },
    WLZItem(wlzItem) {
      if (wlzItem.value === null || wlzItem.value === undefined || wlzItem.value === '') {
        this.WLZList = [];
        return;
      }
      this.WLZArg.WLZId = wlzItem.value;
      this.WLZArg.AccountabilityId = this.accountabilityId;
      this.setWLZId(this.WLZArg);
    },
    ZVZItem(zvzItem) {
      if (zvzItem.value == null || zvzItem.value == undefined || zvzItem.value == '') {
        this.ZVZList = [];
        return;
      }
      this.ZVZArg.ZVZId = zvzItem.value;
      this.ZVZArg.AccountabilityId = this.accountabilityId;
      this.store.dispatch('accountability/setZVZId', this.ZVZArg);
    }
  },
  async created() {
    // Dispatching server calls to fetch Patient and accountability questions details
    await this.getCurrentPatient();
  },
  unmounted() {
    this.setIsLastAnswer(false);
    this.setQuestions({});
  },
  methods: {
    updateFrequency(event) {
      this.frequencyValue = event.target.value;
      this.updateFrequencyIndicator();
    },
    updateIntervalNumber(event) {
      this.intervalNumber = event.target.value;
      this.updateFrequencyIndicator();
    },
    updateIntervalType(event) {
      this.intervalType = event.target.value;
      this.updateFrequencyIndicator();
    },
    updateFrequencyIndicator() {
      this.frequencyIndicator = `${this.frequencyValue.toString().padStart(2, '0')}${this.intervalNumber.toString().padStart(2, '0')}${this.intervalType}`;
    },
    async submitFrequencyAnswer(e) {
      e.preventDefault();
      let answerId = e.currentTarget.getAttribute("answerId");
      let answerObject = {
        'AccountabilityLineId': e.currentTarget.name,
        'Resultaten': [{
          'AnswerId': answerId,
          'Resultaat': this.frequencyIndicator
        }]
      };
      this.submitAnswers(answerObject);
    },
    frequencyChecked() {
      return !this.frequencyValue || !this.intervalNumber || !this.intervalType;
    },
    async submitTypeQuestionOpen(e) {
      e.preventDefault();
      let answerId = e.currentTarget.elements[0].id;
      let divid = 'div' + e.currentTarget.name;
      let divelement = document.getElementById(divid);
      if (divelement) {
        divelement.style.display = 'none';
      }
      if (this.isTextChanged === true) {
        this.isTextChanged = false;
        this.buttonDisableStatus = false;
      }
      if (this.isRadioTextChanged === true) {
        this.isRadioTextChanged = false;
        this.buttonDisableStatus = false;
      }
      let answerObject = {
        'AccountabilityLineId': e.currentTarget.name,
        'Resultaten': [{
          'AnswerId': answerId,
          'Resultaat': e.currentTarget.elements[0].value
        }]
      };
      this.submitAnswers(answerObject);
    },

    async loadAccountability (accountabilityId) {
      // initialize accountability / questions
      var accountabilityRootStatus = await getAccountabilityRootStatus(accountabilityId);
      if(accountabilityRootStatus === null) {
        // todo: reset page (/s very acceptable: _we like happy flows_)
        return;
      }

      const response = await store.dispatch('accountability/getQuestions', this.accountabilityId);
      return response;
    },
    fileExtention: shared.fileExtention,
    onListSearchChange(lineId, ansId, selectedValue) {
      let answerObject = {
        AccountabilityLineId: lineId,
        ForProductSelection: false,
        Resultaten: [
          {
            AnswerId: ansId,
            Resultaat: selectedValue,
            ListSearchItem: selectedValue
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    // Function to handle accountability radio type question answer
    checkRadioAnswer(e) {
      // Check whether radio open text is visible or not, if so hide the block
      let formDivId = 'form' + e.currentTarget.name;
      let formDiv = document.getElementById(formDivId);
      if (formDiv) {
        formDiv.style.display = 'none';
      }
      let answerObject = {
        AccountabilityLineId: e.currentTarget.name,
        Resultaten: [
          {
            AnswerId: document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id,
            Resultaat: ' '
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    ChangeText(event, result, accountabilityLineId) {
      let answerId = event;
      let answerText = document.getElementById(answerId) ? document.getElementById(answerId).value.trimEnd() : '';
      this.currentAnswerId = answerId;
      let divid = 'div' + accountabilityLineId;
      let divelement = document.getElementById(divid);
      if (result !== answerText) {
        this.isTextChanged = true;
        if (divelement) {
          divelement.style.display = 'block';
        }
        this.buttonDisableStatus = true;
      } else {
        this.buttonDisableStatus = false;
        this.isTextChanged = false;
        if (divelement) {
          divelement.style.display = 'none';
        }
      }
    },
    ChangeRadioText(event, result, accountabilityLineId) {
      let answerId = event;
      let answerText = document.getElementById(answerId) ? document.getElementById(answerId).value.trimEnd() : '';
      this.currentRadioAnswerId = answerId.replace('txt', '');
      let divid = 'div' + accountabilityLineId;
      let divelement = document.getElementById(divid);
      if (result !== answerText) {
        this.isRadioTextChanged = true;
        this.buttonDisableStatus = true;
        if (divelement) {
          divelement.style.display = 'block';
        }
      } else {
        this.isRadioTextChanged = false;
        this.buttonDisableStatus = false;
        if (divelement) {
          divelement.style.display = 'none';
        }
      }
    },
    // Function to handle accountability select type question answer
    checkSelectAnswer(e) {
      if (e.currentTarget && e.currentTarget.options) {
        let answerObject = {
          AccountabilityLineId: e.currentTarget.name,
          Resultaten: [
            {
              AnswerId: e.currentTarget.options[e.currentTarget.selectedIndex].dataset.answerid,
              Resultaat: '',
              AnswerListItemId: e.currentTarget.options[e.currentTarget.selectedIndex].value
            }
          ]
        };
        this.submitAnswers(answerObject);
      }
    },
    // Function to handle accountability submit type question answer
    submitTypeQuestionOpen(e) {
      e.preventDefault();
      let answerId = e.currentTarget.elements[0].id;
      answerId = answerId.replace('txt', '');
      let divid = 'div' + e.currentTarget.name;
      let divelement = document.getElementById(divid);
      if (divelement) {
        divelement.style.display = 'none';
      }
      if (this.isTextChanged === true) {
        this.isTextChanged = false;
        this.buttonDisableStatus = false;
      }
      if (this.isRadioTextChanged === true) {
        this.isRadioTextChanged = false;
        this.buttonDisableStatus = false;
      }
      let answerObject = {
        AccountabilityLineId: e.currentTarget.name,
        Resultaten: [
          {
            AnswerId: answerId,
            Resultaat: e.currentTarget.elements[0].value
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    // Function to handle accountability check box type question answers
    submitCheckbox(e, answer) {
      e.preventDefault();
      let result = [];
      for (let i = 0; i < e.currentTarget.elements.length - 1; i++) {
        if (e.currentTarget.elements[i].value === 'true') {
          result.push({
            AnswerListItemId: e.currentTarget.elements[i].id,
            Resultaat: e.currentTarget.elements[i].value,
            AnswerId: answer.AnswerId
          });
        }
      }
      let openCheckbox = answer.AntwoordLijst.filter((opencheck) => opencheck.IsOpen);
      if (result.length > 0) {
        // Check whether text box option selected or not, if so store the text box value to the result
        if (openCheckbox.length > 0) {
          for (let j = 0; j < result.length; j++) {
            result[j].AnswerListItemText = document.getElementById('text' + result[j].AnswerListItemId).value;
          }
        }
        // If the question type is checkbox with text get all the input value for each option
        if (answer.TypeCode === 'CheckboxWithTextbox') {
          for (let j = 0; j < result.length; j++) {
            result[j].AnswerListItemText = document.getElementById('text' + result[j].AnswerListItemId).value;
          }
        }
        $('#remark-' + e.currentTarget.name).hide();
        let answerObject = {
          AccountabilityLineId: e.currentTarget.name,
          Resultaten: result
        };
        this.submitAnswers(answerObject);
      } else {
        $('#remark-' + e.currentTarget.name).show();
      }
    },
    // Function to send user answers to the server and get the next question
    async submitAnswers(answerObject) {
      this.buttonDisableStatus = false;
      answerObject.ForProductSelection = false;
      await this.setAnswer(answerObject);
      await this.getQuestions(this.accountabilityId);
      await this.scrollToBottom();
    },
    // Function to cancel the accountability and redirect user to the patient dashboard
    cancelAccountability() {
      this.cancelAccountabilityForm(this.accountabilityId).then(() => {
        router.push('/patientdashboard');
      });
    },
    // Function to save the accoutability
    saveAccountability() {
      let isAnswerExtensionTypeTherapyStartDate = false
      this.isLoading = true;
      let isBlock = false;
      for (let i = 0; i < this.questions.length; i++) {
        let lineId = this.questions[i].AccountabilityLineId;
        let divid = 'div' + lineId;
        let divelement = document.getElementById(divid);
        if (divelement && divelement.style.display === 'block') {
          isBlock = true;
          this.scrollToTop();
          return;
        }
      }
      if (isBlock === false) {
        this.questions.forEach(answersList => {
          let result = answersList.ResultaatAnswers.filter(f => f.IsAnswerExtensionTypeTherapyStartDate == true)
          if (result && result[0] && result[0].IsAnswerExtensionTypeTherapyStartDate) {
            isAnswerExtensionTypeTherapyStartDate = true
          }
        });
        if (!this.startDate) {
          this.startDate = new Date();
          this.startDate = moment(this.startDate).format('DD-MM-YYYY');
        }

        if (isAnswerExtensionTypeTherapyStartDate && this.therapyStartDate) {
            this.startDate = this.therapyStartDate
          } 

        const startDate = stringToLocalDate(this.startDate);
        const startDateJson = localDateToJson(startDate);

        const accountabilityDetails = {
          startDate: startDateJson,
          accountabilityId: this.accountabilityId,
          signeePpwRecId: this.selectedSignee.ppwRecId
        };

        this.saveStandAloneAccountability(accountabilityDetails).then((response) => {
          if (this.siteCoreState === 'MRT') {
            axios
              .get('License/GetAccountabilityOrderDetails?AccountabilityId=' + this.accountabilityId)
              .then((orderDetails) => {
                let gotoCheckoutPage = false;
                let illnessBundleIds = orderDetails.data.AccountabilityOrderDetails.IllnessBundleIds;
                let productIds = orderDetails.data.AccountabilityOrderDetails.ProductIds;
                for (let i = 0; i < illnessBundleIds.length; i++) {
                  addIllnessBundleToShoppingCart(this.shoppingCart, illnessBundleIds[i]).then((response) => {
                    this.setCartOpenStatus(false);
                    this.shoppingCart.items.forEach((item) => {
                      this.$gtm.trackEvent({
                        event: 'ButtonClick',
                        event_category: 'Product cart',
                        event_action: 'Bestellen',
                        event_label: `${item.productId} ${item.productTitle}`,
                        event_value: String(item.quantity)
                      });
                    });
                  });
                  gotoCheckoutPage = true;
                }
                for (let j = 0; j < productIds.length; j++) {
                  addToShoppingCart(this.shoppingCart, productIds[j], 1, this.activePpwRecId);
                  gotoCheckoutPage = true;
                }
                if (gotoCheckoutPage === true) {
                  router.push('/bestellijst');
                  return;
                }
              });
          }
          if (response.data && response.data.pumpAssignment) {
            this.pumpAssignment = response.data && response.data.pumpAssignment;

            invalidateLicenseOverviews$();
            this.isLoading = false;
            this.showPumpAssignPopUp = true;
          } else {
            router.push('/machtigingondertekend');
          }
        });
      } else {
        this.scrollToTop();
      }
    },
    goToPumpAssign() {
      router.push('/pumpassignment/verantwoording/' + this.pumpAssignment.licenseId);
    },
    async goToPatientDashboard(patientId) {
      await this.setActivePatient(patientId);
      await router.push('/patientdashboard');
    },
    // Function to get the checbox status based on the API response data
    getCheckboxStatus(resultArray, checkbox, answer) {
      let state = false;
      for (let i = 0; i < resultArray.length; i++) {
        if (resultArray[i].AnswerListItemId === checkbox.AnswerListItemId) {
          state = true;
          setTimeout(() => {
            this.change(true, checkbox, answer.AnswerId, answer.TypeCode);
          }, 1000);
        }
      }
      return state;
    },
    // Function to forward an accountability to other prescriber of the same department
    async openForwardModal() {
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Machtiging doorsturen',
          message: 'Naar welke collega wilt u de machtiging doorsturen?',
          confirmButton: 'Doorsturen',
          component: VForwardModal,
          resolve,
          reject
        });
      })
        .then((select) => {
          this.forwardAccountability({
            accountabilityId: this.accountabilityId,
            ppwRecId: select.colleagueSelected.ppwRecId,
            comment: select.comment
          })
            .then(() => {
              this.fwAccountabilityPopUp = true;
            })
            .catch(() => {
              this.$emitter.emit('alert', { type: 3, message: 'Uw conceptaanvraag is mislukt.' });
            });
        })
        .catch(() => {});
    },
    // Function to handle date type question answer
    handleDateAnswer(answerId, date, lineId) {
      this.therapyStartDate = date
      let answerObject = {
        AccountabilityLineId: lineId,
        ForProductSelection: false,
        Resultaten: [
          {
            AnswerId: answerId,
            Resultaat: date
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    // Function to handle start date value
    handleStartDate(date) {
      this.startDate = date;
    },
    // Function to convert API response date string to required format for datePicker
    getDateValue(responseResult, key) {
      if (key !== 'Resultaat' && responseResult.length > 0 && responseResult[0][key]) {
        let temp = moment(responseResult[0][key]).format('DD-MM-YYYY');
        temp = temp.split('-');
        return temp[1] + '/' + temp[0] + '/' + temp[2];
      }
      if (responseResult.length > 0 && responseResult[0][key]) {
        let temp = responseResult[0][key];
        temp = temp.split('-');
        return temp[1] + '/' + temp[0] + '/' + temp[2];
      }
      return '';
    },
    // Function to handle PersonalAccountabilityProductGroup2 type question
    submitPersonalAccountabilityProductGroup2(event) {
      event.preventDefault();
      let ProductGroupId = $('#selectProduct-' + event.target.name)
        .find(':selected')
        .val();
      let Aantal = $('#Aantal-' + event.target.name).val();
      let PeriodId = $('#selectPeriod-' + event.target.name)
        .find(':selected')
        .val();
      if (!ProductGroupId || !Aantal || !PeriodId) {
        $('#remark-' + event.target.name).show();
        return;
      }
      $('#remark-' + event.target.name).hide();
      let answerObject = {
        AccountabilityLineId: parseInt(event.target.name),
        ForProductSelection: false,
        Resultaten: [
          {
            AnswerId: event.target.id,
            Resultaat: '',
            AnswerListItemId: '',
            ProductGroupId: ProductGroupId,
            Aantal: Aantal,
            PeriodId: PeriodId
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    // Function to handle PersonalAccountabilityProductGroup3 type question
    submitPersonalAccountabilityProductGroup3(event) {
      event.preventDefault();
      let ProductGroupId = $('#selectProduct-' + event.target.name)
        .find(':selected')
        .val();
      let Aantal = $('#Aantal-' + event.target.name).val();
      let PeriodId = $('#selectPeriod-' + event.target.name)
        .find(':selected')
        .val();
      let Startdatum = $('#startDate-' + event.target.name).val();
      let Einddatum = $('#endDate-' + event.target.name).val();

      if (!ProductGroupId || !Aantal || !PeriodId || !Startdatum || !Einddatum) {
        $('#remark-' + event.target.name).show();
        return;
      }
      if (!this.compareTwoDates(Startdatum, Einddatum)) {
        $('#remark-' + event.target.name).show();
        return;
      }

      const startDate = stringToLocalDate(Startdatum);
      const startDateJson = localDateToJson(startDate);

      const endDate = stringToLocalDate(Einddatum);
      const endDateJson = localDateToJson(endDate);

      $('#remark-' + event.target.name).hide();
      let answerObject = {
        AccountabilityLineId: parseInt(event.target.name),
        ForProductSelection: false,
        Resultaten: [
          {
            AnswerId: event.target.id,
            Resultaat: '',
            AnswerListItemId: '',
            ProductGroupId: ProductGroupId,
            Aantal: Aantal,
            PeriodId: PeriodId,
            Startdatum: startDateJson,
            Einddatum: endDateJson
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    // Function to compare two string dates in which end date is either earliar to start date or not
    compareTwoDates(startDate, endDate) {
      let startDateRequiredFormat = startDate.split('-');
      startDateRequiredFormat =
        startDateRequiredFormat[2] + '/' + startDateRequiredFormat[1] + '/' + startDateRequiredFormat[0];
      let endDateRequiredFormat = endDate.split('-');
      endDateRequiredFormat =
        endDateRequiredFormat[2] + '/' + endDateRequiredFormat[1] + '/' + endDateRequiredFormat[0];
      return endDateRequiredFormat > startDateRequiredFormat;
    },
    // Function to scroll to the bottom of the page
    async scrollToBottom() {
      $('html, body').animate({ scrollTop: $(document).height() }, 'slow');
    },
    async scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    },
    // Function to show start date input field
    showStartDate() {
      return this.isLastAnswer && this.isStartDate;
    },
    // Function to enable/disable the save button
    isSignButtonDisabled() {
      return this.buttonDisableStatus || !this.isSignable;
    },
    // Function to show illness related pop up
    showIllnessPopUp() {
      let count = 0;
      if (this.isLastAnswer) {
        for (let i = 0; i < this.questions.length; i++) {
          for (let j = 0; j < this.questions[i].ResultaatAnswers.length; j++) {
            if (this.questions[i].ResultaatAnswers[j].HasIllness) {
              // If atleast one question had illness id allowing to sign the accountability
              count = count + 1;
              this.illnessIdStatus = false;
              this.buttonDisableStatus = false;
              return;
            }
          }
        }
      }
      if (count === 0) {
        this.illnessIdStatus = true;
        this.buttonDisableStatus = true;
      }
    },
    // Function to close the illeness related pop up
    closeIllnessPopUp() {
      this.illnessIdStatus = false;
    },
    input(idOrEvent, value) {
      let id;

      if (typeof idOrEvent === 'string') {
        // If idOrEvent is a string, it's the ID
        id = idOrEvent;
      } else {
        // If idOrEvent is not a string, it's the event object
        id = idOrEvent.target.id;
        value = idOrEvent.target.value;
      }
      id = id.replace('txt', '');
      let btnId = '#' + id + 'btn';
      if (value && value !== '') {
        $(btnId).removeClass('btn--disabled');
      } else {
        $(btnId).addClass('btn--disabled');
      }
    },
    inputCheckBox(id, value, nextBtnId) {
      let btnId = '#' + nextBtnId + 'btn';
      if (value && value !== '') {
        $(btnId).removeClass('btn--disabled');
      } else {
        $(btnId).addClass('btn--disabled');
      }
    },
    getNextButtonStatus(id) {
      if (id) {
        let value = document.getElementById(id) ? document.getElementById(id).value : '';
        return value === undefined || value === '' || value === null;
      }
      return true;
    },
    getNextRadioButtonStatus(id) {
      if (id) {
        let txtId = id + 'txt';
        let value = document.getElementById(txtId) ? document.getElementById(txtId).value : '';
        return value === undefined || value === '' || value === null;
      }
      return true;
    },
    // Function to handle accountability radio standard type question answer
    checkedRadioAnswerStandard(radioId, nameId) {
      let radioButton = document.getElementById(radioId);
      let formDivId = 'form' + nameId;
      let formDiv = document.getElementById(formDivId);
      if (radioButton) {
        formDiv.style.display = radioButton.checked ? 'block' : 'none';
        let divid = 'div' + nameId;
        let divelement = document.getElementById(divid);
        if (divelement) {
          divelement.style.display = 'none';
        }
      }
    },
    // Function to set/unset checkbox text box and the next question button status
    change(active, checkbox, nextBtnId, answerType) {
      let formDivId = 'form' + checkbox.AnswerListItemId;
      let formDiv = document.getElementById(formDivId);
      if (formDiv) {
        let btnId = '#' + nextBtnId + 'btn';
        if ((answerType === 'CheckboxWithTextbox' || checkbox.IsOpen) && active) {
          formDiv.style.display = 'block';
          $(btnId).addClass('btn--disabled');
        } else {
          formDiv.style.display = 'none';
          $(btnId).removeClass('btn--disabled');
        }
      }
    },
    /**
     * @function getOpenTextItemId
     * @description Function to get the open text check box details from the list
     */
    getOpenTextItemId(checkboxAnswerList) {
      let answerListId = '';
      for (let i = 0; i < checkboxAnswerList.length; i++) {
        if (checkboxAnswerList[i].IsOpen) {
          answerListId = checkboxAnswerList[i].AnswerListItemId;
          break;
        }
      }
      return answerListId;
    },
    /**
     * @function checkCheckBoxTextAns
     * @description Function to get the checkbox text ans from the answers result
     */
    checkCheckBoxTextAns(ansList, listId, type) {
      let status = false;
      let value = '';
      for (let i = 0; i < ansList.length; i++) {
        if (listId === ansList[i].AnswerListItemId) {
          status = true;
          value = ansList[i].AnswerListItemText;
        }
      }
      if (type === 'status') {
        return status;
      }
      return value;
    },
    /**
     * @function checkRadioStatus
     * @description Function to load the text box ans for the radio button on edit flow
     */
    checkRadioStatus(resultList, ansId, lineId) {
      let txtId = ansId + 'txt';
      let value = document.getElementById(txtId) ? document.getElementById(txtId).value.trimEnd() : '';
      let status = false;
      let divid = 'div' + lineId;
      let divelement = document.getElementById(divid);
      if (resultList.length > 0 && resultList[0].AnswerId === ansId) {
        if (resultList[0].AnswerId === ansId) {
          status = true;
          this.checkedRadioAnswerStandard(ansId, lineId);
          if (divelement && value === '') {
            divelement.style.display = 'block';
          }
          if (divelement && resultList[0].Resultaat === value) {
            divelement.style.display = 'none';
          }
          if (divelement && resultList[0].Resultaat !== value) {
            divelement.style.display = 'block';
          }
        }
      }
      return status;
    },
    submitUploadedFiles(fmData, requiredDetails) {
      this.saveAccountabilityUpload(fmData).then((response) => {
        if (response && response.data) {
          this.submitAnswers({
            AccountabilityLineId: requiredDetails.name,
            Resultaten: [
              {
                AnswerId: requiredDetails.id,
                Resultaat: response.data.FileName,
                Pdf: response.data.Pdf
              }
            ]
          });
        }
      });
    },
    // Function to convert datetime to required specific format
    moment(...args) {
      return moment(...args);
    },
    checkUploadFileStatus(self) {
      let uploadFileStatus = false;
      for (let i = 0; i < self.questions.length; i++) {
        for (let j = 0; j < self.questions[i].Antwoorden.length; j++) {
          if (
            self.questions[i].Antwoorden[j].TypeCode === 'FileUpload' &&
            self.questions[i].ResultaatAnswers.length === 0
          ) {
            uploadFileStatus = true;
            break;
          }
        }
        if (uploadFileStatus) {
          break;
        }
      }
      if (uploadFileStatus) {
        this.setIsLastAnswer(false);
        this.showUploadFileErrorMessage = true;
      } else {
        this.showUploadFileErrorMessage = false;
      }
    },
    setSelectedSigneePpwRecId(event) {
      if (!event.currentTarget.value) {
        return;
      }
      this.selectedSigneePpwRecId = event.currentTarget.value;
    },
    searchZVZItem(value) {
      if (value.length < 3) {
        if (this.ZVZItem.value == null || this.ZVZItem.value == undefined || this.ZVZItem.value == '') {
          this.ZVZList = [];
        }

        return;
      }

      axios
        .get('VragensetVerantwoording/SearchZVZInstitutions?query=' + value)
        .then((result) => {
          this.ZVZList = result.data;
          if (!this.ZVZList.length) {
            this.ZVZItem.text = null;
            this.ZVZItem.value = null;
          }
        })
        .catch(() => {
          this.ZVZList = [];
          this.ZVZItem.text = null;
          this.ZVZItem.value = null;
        });
    },
    searchWLZItem(value) {
      if (value.length < 3) {
        if (this.WLZItem.value == null || this.WLZItem.value == undefined || this.WLZItem.value == '') {
          this.WLZList = [];
        }

        return;
      }

      axios
        .get('VragensetVerantwoording/SearchWLZInstitutions?query=' + value)
        .then((result) => {
          this.WLZList = result.data;
          if (!this.WLZList.length) {
            this.WLZItem.text = null;
            this.WLZItem.value = null;
          }
        })
        .catch(() => {
          this.WLZList = [];
          this.WLZItem.text = null;
          this.WLZItem.value = null;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-animation-enter-active,
.card-animation-leave-active {
  transition: all 0.5s ease;
}
.card-animation-enter,
.card-animation-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.stand-alone-accountability {
  .frequency-indicator-label {
    font-size: 10px !important;
  }
  .frequency-indicator-btn {
    margin-bottom: 0px !important;
  }
  .margin-bottom {
    margin-bottom: 15px;
  }
  &__categoryLabel {
    padding-top: 30px;
    font-weight: 500;
  }
  &__popup {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  &__backToCatalog {
    display: flex;
    justify-content: center !important;
  }
  &__signature {
    img {
      border: solid 2px $grayish-med-light;
      vertical-align: text-top;
      max-width: 350px !important;
      height: auto;
      width: 100%;
    }
    :deep(.form-group) {
      padding: 0px;
      label {
        font-weight: 900;
        color: $gray-dark;
        position: initial;
        transform: none !important;
        line-height: normal;
        background: transparent;
      }
      &__icon {
        margin: 25px 10px 0 0;
      }
      .form-group__details {
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }
    }
  }
  &__fileUpload {
    .form-group {
      padding: 0px;
    }
    fieldset {
      padding: 6px 0 10px !important;
    }
    a {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      margin: 0px;
      pointer-events: none;
    }
    img {
      vertical-align: middle;
      height: 32px;
      display: inline-block;
    }
    legend {
      margin: 10px 0px 10px 0px;
    }
    :deep(.uploadBox) {
      padding: 0px;
    }
    &__date {
      margin: 10px 0px 10px 5px !important;
      color: $gray;
    }
    &__errorMessage {
      color: $red;
    }
  }
  .hide-msg {
    display: none;
  }
  .save-accountability {
    color: $white;
  }
  .cancel-accountability {
    color: $cyan-med-dark;
    text-decoration: underline !important;
    line-height: 1.5rem;
    text-decoration: none;
    font-weight: 300;
    display: block;
    cursor: pointer;
    margin: 0.5rem 0.5rem 0.5rem 1.5rem;
  }
  // Width of each table column
  $table-columns: (
    1: 40px,
    2: 30%,
    3: 30%,
    4: 15%,
    5: 15%,
    6: 50px
  ) !default;
  table {
    table-layout: auto !important;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: left;

    // Generate classes for each column and assign width
    @each $table-column-key, $table-column-value in $table-columns {
      .table-col-#{$table-column-key} {
        width: $table-column-value;
      }
    }

    > thead > tr {
      background-color: rgba(0, 0, 0, 0.03);

      > td,
      > th {
        vertical-align: baseline;
        text-align: left;
        padding: 10px;
        color: $gray-darkest;
        font-size: type-scale(0);
        line-height: line-height(0);
        font-weight: normal;
      }
    }

    > tbody {
      background: $white;

      > tr:first-child {
        > td,
        > th {
          vertical-align: baseline;
          text-align: left;
          padding: 16px 10px;
        }
      }

      > tr:last-child td {
        padding: 10px 10px 16px;
      }

      &.action-required {
        background-color: $pink;
      }
    }

    .hidden {
      display: none;
    }
  }
  .startDateCalender {
    margin-top: 45px !important;
  }
  form.openquestion {
    padding-bottom: 16px;
  }
  fieldset {
    margin: 0;
    padding: 6px 0 20px;
    border: 0;

    legend {
      font-weight: bold;
      line-height: 1.4;
      color: $gray-darkest;
      font-size: inherit;
    }

    div {
      margin: 0;
    }

    form {
      width: 100%;
    }
  }
  .form-group #menu-down-icon {
    fill: $aqua;
  }
  .remark {
    font-size: 0.8rem;
    color: $red;
    padding: 0 0 1rem calc(20px + 0.5rem);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s ease-out, bottom 0.25s ease-out;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    bottom: -100px;
  }
  .icon-color-teal {
    padding-right: 6px;
    fill: $teal-darkest;
  }
  .bold {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .mb {
    margin-bottom: 30px;
  }
  &__error {
    font-size: $form-group-details-font-size;
    min-height: 26px;
    padding-top: 10px;
    color: $red;
    transition: $form-group-error-transition;
  }
  :deep(.form-group-simple input) {
    margin-right: 10px;
  }
  .groupText {
    :deep(.form-group-simple) {
      margin-top: 15px;
    }
  }
  .form-group {
    &__date {
      .form-group-simple input {
        width: 100%;
      }
    }
  }
  .btn {
    margin-bottom: 10px;
  }
}
.details {
  background: none;
}
</style>
