<template>
  <section class="container checkout">
    <!-- Checkout Page top section -->
    <div class="row grid-margin">
      <div class="col-xs-12 col-md-9">
        <v-heading :level="1"> Bestellijst </v-heading>
      </div>
      <div class="col-md-3 grid-align-end">
        <v-button md primary icon-left @click.prevent="goToCatalog()">
          <v-arrow-left-icon />
          {{ buttonText }}
        </v-button>
      </div>
    </div>
    <!-- Depot Issue Checkbox section -->
    <div v-if="showDepotIssueBlock" class="row grid-margin">
      <div class="col-md-12">
        <table v-if="displayDepotIssueCheckbox" class="table">
          <thead class="head">
            <tr>
              <th>{{ depotIssueCheckboxDetails.DepotIssueCheckboxTitle }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-checkbox-group
                  :label="depotIssueCheckboxDetails.DepotIssueCheckboxText"
                  :inputid="'checkDepotIssue'"
                  :overwrite-value="depotIssueCheckboxChecked"
                  :initvalue="depotIssueCheckboxChecked"
                  v-model="depotIssueCheckboxChecked"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Products section -->
    <div class="row grid-margin">
      <div class="col-xs-12 col-md-12" id="checkoutProducts">
        <v-checkout-product
          v-for="shoppingCartItem in shoppingCart.items"
          :key="shoppingCartItem.productId"
          :product="shoppingCartItem"
          @update="handleShoppingCartItemUpdate"
          @remove="handleShoppingCartItemRemove"
          @add="handleShoppingCartItemAdd"
          ref="checkoutProduct"
        ></v-checkout-product>
      </div>
    </div>
    <!-- Addresses section -->
    <div class="row grid-margin">
      <div v-if="showAddress" class="col-xs-12">
        <!-- Address section -->
        <div v-if="!assortmentFeatureEnabled" id="checkoutLevering" class="checkoutAddress">
          <table class="table">
            <colgroup>
              <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
            </colgroup>
            <thead class="head">
              <tr>
                <th>Levering</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <legend>Gewenste afleverlocatie</legend>
                  <div class="form-group__radio">
                    <input id="homeAddress" name="deliveryaddress" type="radio" checked @change="checkRadioAddress" />
                    <label for="homeAddress"><span></span>Woonadres</label>
                  </div>
                  <div v-if="deliveryAddress && deliveryAddress.AdresId !== 0" class="form-group__radio">
                    <input id="deliveryAddress" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                    <label for="deliveryAddress"><span></span>Afwijkend afleveradres</label>
                  </div>
                  <div class="form-group__radio">
                    <input id="differentAddress" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                    <label for="differentAddress"><span></span>Eenmalig afwijkend adres</label>
                  </div>
                </td>
                <td>
                  <legend>Afleveradres</legend>
                  <div v-if="SelectedAddress === 0">
                    {{ homeAddress.Straatnaam }} {{ homeAddress.Huisnummer }}
                    {{ homeAddress.Toevoeging }}
                    <br />
                    {{ homeAddress.Postcode }} {{ homeAddress.Woonplaats }}
                    <br />
                    {{ homeAddress.Land }}
                  </div>
                  <div v-if="SelectedAddress === 1 && deliveryAddress && deliveryAddress.AdresId !== 0">
                    {{ deliveryAddress.Straatnaam }}
                    {{ deliveryAddress.Huisnummer }}
                    {{ deliveryAddress.Toevoeging }}
                    <br />
                    {{ deliveryAddress.Postcode }}
                    {{ deliveryAddress.Woonplaats }}
                    <br />
                    {{ deliveryAddress.Land }}
                  </div>
                  <div v-if="SelectedAddress === 2">
                    <div class="row grid-margin">
                      <div class="col-xs-12">
                        <v-input-group-simple-2
                          id="Postcode"
                          v-model:value="differentAddress.PostCode"
                          type="text"
                          maxlength="7"
                          :rules="{
                            required: true,
                            regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
                          }"
                          label="Postcode*"
                          @blur="autoFillAddress()"
                          @input="updateAddressDetails('PostCode', $event)"
                        />
                      </div>
                    </div>
                    <div class="row grid-margin">
                      <div class="col-xs-12">
                        <v-input-group-simple-2
                          id="Huisnummer"
                          v-model:value="differentAddress.Huisnummer"
                          type="text"
                          :rules="{
                            required: true
                          }"
                          label="Huisnummer*"
                          @blur="autoFillAddress()"
                          @input="updateAddressDetails('Huisnummer', $event)"
                        />
                      </div>
                    </div>
                    <div class="row grid-margin">
                      <div class="col-xs-12">
                        <v-input-group-simple-2
                          id="Toevoeging"
                          v-model:value="differentAddress.Toevoeging"
                          type="text"
                          :rules="{
                            required: false
                          }"
                          label="Toevoeging"
                          @input="updateAddressDetails('Toevoeging', $event)"
                        />
                      </div>
                    </div>
                    <div class="row grid-margin">
                      <div class="col-xs-12">
                        <v-input-group-simple-2
                          id="Straatnaam"
                          v-model:value="differentAddress.Straatnaam"
                          type="text"
                          :rules="{
                            required: true
                          }"
                          label="Straatnaam*"
                          :disabled="addressLookupSuccessful"
                          @input="updateAddressDetails('Straatnaam', $event)"
                        />
                      </div>
                    </div>
                    <div class="row grid-margin">
                      <div class="col-xs-12">
                        <v-input-group-simple-2
                          id="Woonplaats"
                          v-model:value="differentAddress.Woonplaats"
                          type="text"
                          :rules="{
                            required: true
                          }"
                          label="Woonplaats*"
                          :disabled="addressLookupSuccessful"
                          @input="updateAddressDetails('Woonplaats', $event)"
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="showNeighborsDeliveryAllowed">
                    <legend>Afleveren bij de buren</legend>
                    <span class="checkoutProduct__extraOptions__checkbox">
                      <v-checkbox-group
                        label="Niemand thuis? Geef dan hier
                                      toestemming om het pakket bij de buren te leveren"
                        inputid="NeighborsDeliveryAllowed"
                        :initvalue="neighborDeliveryStatus"
                        v-model="neighborDeliveryStatus"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row grid-margin checkout__otherOptions">
      <div class="col-md-12">
        <!-- Service section -->
        <table v-if="!assortmentFeatureEnabled && kwartaalService" class="table">
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead class="head">
            <tr>
              <th>{{ kwartaalServiceDetails.Title }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ kwartaalServiceDetails.Information }}
              </td>
            </tr>
            <tr class="serviceBackground">
              <td>
                <v-checkbox-group
                  inputid="repeatService"
                  :initvalue="repeatServiceStatus"
                  v-model="repeatServiceStatus"
                >
                  {{ kwartaalServiceDetails.Description }}
                </v-checkbox-group>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Order Number section -->
        <table v-if="assortmentFeatureEnabled" class="table">
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead class="head">
            <tr>
              <th>Ordernummer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-xs-12">
                    <v-input-group-simple-2
                      id="Ordernummer"
                      v-model:value="orderNumberModel"
                      maxlength="128"
                      :rules="{
                        required: false,
                        regex: [/^[a-zA-Z0-9]*$/i]
                      }"
                      placeholder="Vul hier uw ordernummer of betalingskenmerk in."
                      class="orderNummer"
                      type="text"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Kies uw gewenste bezorgmoment section -->
        <table id="deliveryTimings" class="table" v-if="deliveryDateFeature && possibleDeliveryDates.length > 0">
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead class="head">
            <tr>
              <th>Kies uw gewenste bezorgmoment</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-xs-12 checkout__deliveryDates">
                    <v-select-group
                      selectid="PossibleDeliveryDates"
                      :initselected="possibleDeliveryDates[0].Key"
                      :overwrite-value="possibleDeliveryDates[0].Key"
                      @change="setSelectedDeliveryDate($event)"
                    >
                      <option
                        v-for="(item, index) in possibleDeliveryDates"
                        :key="index"
                        :data-index="index"
                        :value="item.Key"
                      >
                        {{ item.Value }}
                      </option>
                    </v-select-group>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="showTimeWindowCheck" class="timeWindow">
              <td>
                <v-checkbox-group
                  v-model="isTimeWindowDelivery"
                  label="Eerst mogelijke bezorgmoment op deze dag"
                  :overwrite-value="isTimeWindowDelivery"
                  inputid="timeWindow"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <!-- careprogram section -->
        <v-table
          v-for="(careProgram, careProgramKey) in careProgramDetails"
          :key="careProgramKey"
          :class="{ disableCareProgram: !customerMarketingPermission }"
        >
          <v-care-programme-row :data="careProgram" :index="careProgramKey" @care-program-action="careProgramAction" />
        </v-table>
        <!-- FAQs Section -->
        <table v-if="!assortmentFeatureEnabled" class="table">
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead class="head">
            <tr>
              <th>Veelgestelde vragen</th>
            </tr>
          </thead>
          <tbody>
            <v-faq-row
              v-for="(faq, index) in faqsByCustomFilter"
              :key="index"
              :faq="faq"
              :index="index"
              :total-faq="faqsByCustomFilter.length"
            />
          </tbody>
          <!--Show More/Less Functionality -->
          <div v-if="faqs && faqs.length > filterCountDefault" class="row grid-justify-content-center">
            <v-button hollow md class="filter-count-button" @click="toggleFilterCount">
              <template v-if="!filterCount">
                {{ filterCountButtonTextOptions.less }}
              </template>
              <template v-if="filterCount > 0">
                {{ filterCountButtonTextOptions.more }}&nbsp;
                <span v-if="faqs.length > filterCountDefault">({{ faqs.length }})</span>
              </template>
            </v-button>
          </div>
        </table>
        <!-- Remarks section -->
        <table class="table">
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead class="head">
            <tr>
              <th>Opmerkingen (optioneel)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea
                  id="checkout-remarks"
                  class="checkout__textarea"
                  placeholder="Opmerkingen"
                  @input="setRemarks($event.target.value)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="checkoutOrder" class="row grid-margin">
      <div class="col-xs-8">
        <a class="back-to-catalog" @click="goToCatalog">
          <v-arrow-left-icon />
          <div class="backText">{{ buttonText }}</div>
        </a>
      </div>
      <div class="col-xs-4">
        <v-button md cta class="save-checkout" @click.prevent="saveCheckout"> Bestelling versturen </v-button>
      </div>
    </div>
    <!-- Modals section -->
    <generic-modal v-if="noProductOverlay">
      <template #header> Let op! </template>
      <template #body>
        <p>{{ popupMessage }}</p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="closeModal"> Annuleren </v-button>
        <v-button md primary @click="goToCatalog()"> Bevestigen </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import router from '@/router';
import VArrowLeftIcon from 'icons/ArrowLeft';
import VCheckoutProduct from './CheckoutProduct';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VFaqRow from './FaqRow';
import VTable from '@/components/shared/table/Table';
import VCareProgrammeRow from '@/components/sections/patient/CareProgrammeRow';
import GenericModal from '@/components/shared/GenericModal';
import { useForm } from 'vee-validate';

import {
  removeFromShoppingCart,
  activeCustomerId$,
  shoppingCart$,
  addToShoppingCart,
  clearShoppingCart,
  updateShoppingCartItem,
  currentUserIdentity$,
  activePpwRecId$,
  assortmentFeatureEnabled$,
  checkout,
  checkoutSuccessful$
} from '@/services';
import { BehaviorSubject } from 'rxjs';
import { tap, filter, map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { $validateAllComponents } from '@/validate/index.js';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Checkout',
  components: {
    VArrowLeftIcon,
    VCheckoutProduct,
    VCheckboxGroup,
    VFaqRow,
    VTable,
    VCareProgrammeRow,
    GenericModal
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);
    const shoppingCartChanged = ref(null);
    const assortmentFeatureEnabled = ref(null);
    const activeCustomerId = ref(null);
    const prescriberDetails = ref(null);
    const activePpwRecId = ref(null);
    const state = store.state;
    const noProductOverlay = ref(false);
    const popupMessage = ref(null);
    const submitOrderPopupMessage = ref(null);
    const patientId = ref(null);
    const neighborDeliveryStatus = ref(false);
    const repeatServiceStatus = ref(false);

    const homeAddress = computed(() => store.getters['cart/getCheckoutHomeAddress']);
    const deliveryAddress = computed(() => store.getters['cart/getCheckoutDeliveryAddress']);
    const differentAddress = computed(() => store.getters['cart/getCheckoutDifferentAddress']);
    const patientDetails = computed(() => store.getters['patient/getPatientGegevens']);
    const siteCode = computed(() => store.getters['navigation/getSiteCode']);
    const SelectedAddress = computed(() => store.getters['cart/getSelectedAddress']);
    const orderNumber = computed(() => store.getters['cart/getOrderNumber']);
    const kwartaalService = computed(() => store.getters['cart/getKwartaalServiceStatus']);
    const kwartaalServiceDetails = computed(() => store.getters['cart/getKwartaalServiceDetails']);
    const faqs = computed(() => store.getters['cart/getFaqs']);
    const leveringOptions = computed(() => store.getters['cart/getLeveringOptions']);
    const remarks = computed(() => store.getters['cart/getRemarks']);
    const NeighborsDeliveryAllowed = computed(() => store.getters['cart/getNeighborsDeliveryAllowed']);
    neighborDeliveryStatus.value = NeighborsDeliveryAllowed;
    const RepeatService = computed(() => store.getters['cart/getRepeatService']);
    repeatServiceStatus.value = RepeatService;
    const userMarketingPermission = computed(() => store.getters['navigation/getUserMarketingPermission']);
    const careProgramDetails = computed(() => store.getters['patient/getCareProgramDetails']);
    const buttonText = computed(() => store.getters['cart/getButtonText']);
    const possibleDeliveryDates = computed(() => store.getters['cart/getPossibleDeliveryDates']);
    const depotIssueCheckboxDetails = computed(() => store.getters['cart/getDepotIssueCheckboxDetails']);
    const userWarehouseStatus = computed(() => store.getters['cart/getUserWarehouseStatus']);

    const fetchCheckoutDetailsStore = (requiredIds) => store.dispatch('cart/fetchCheckoutDetails', requiredIds);
    const setSelectedAddress = (value) => store.commit('cart/setSelectedAddress', value);
    const setOrderNumber = (value) => store.commit('cart/setOrderNumber', value);
    const setRepeatService = (value) => store.commit('cart/setRepeatService', value);
    const setNeighborsDeliveryAllowed = (value) => store.commit('cart/setNeighborsDeliveryAllowed', value);
    const setRemarks = (value) => store.commit('cart/setRemarks', value);
    const setLeveringSubOptions = (value) => store.commit('cart/setLeveringSubOptions', value);
    const setLeveringOptions = (value) => store.commit('cart/setLeveringOptions', value);
    const setCustomerMarketingPermissionSubOption = (value) =>
      store.commit('patient/setCustomerMarketingPermissionSubOption', value);
    const fetchAddressLookup = (value) => store.dispatch('patient/fetchAddressLookup', value);

    store.dispatch('cart/fetchPossibleDeliveryDates');

    shoppingCart$.subscribe((value) => (shoppingCart.value = value));

    shoppingCart$
      .pipe(
        map((m) => (m ? m.items.length : 0)),
        distinctUntilChanged(),
        filter((m) => m > 0)
      )
      .subscribe((value) => (shoppingCartChanged.value = value));

    assortmentFeatureEnabled$.subscribe((value) => (assortmentFeatureEnabled.value = value));

    assortmentFeatureEnabled$
      .pipe(
        switchMap((enabled) => {
          return enabled ? new BehaviorSubject(null) : activeCustomerId$;
        })
      )
      .subscribe((value) => (activeCustomerId.value = value));

    currentUserIdentity$.subscribe((value) => (prescriberDetails.value = value));
    activePpwRecId$.subscribe((value) => (activePpwRecId.value = value));

    checkoutSuccessful$
      .pipe(
        tap((e) => {
          router.push({
            path: '/orderconfirmation',
            query: {
              orderId: e.data.orderIds
            }
          });
          return null;
        })
      )
      .subscribe();
    // Dispatching server calls to fetch Prescriber, Patient details
    // and using those details to fetch checkout page required details
    store.dispatch('navigation/fetchResults').then(() => {
      if (!assortmentFeatureEnabled.value) {
        store.dispatch('patient/getCurrentPatient').then((response) => {
          patientId.value = patientDetails.value.PatientId;
          fetchCheckoutDetails();
        });
      } else {
        fetchCheckoutDetails();
      }
    });
    function fetchCheckoutDetails() {
      let requiredIds = {
        prescriberId: prescriberDetails.value.id,
        patientId: activeCustomerId.value
      };
      fetchCheckoutDetailsStore(requiredIds).then((response) => {
        popupMessage.value = response.DeleteProductPopupMessage;
        noProductOverlay.value = false;
        /*
          // Original code: it is showing the wrong popup. Once fixed we can put the code back in although needs to be based on observable, not on promise.
          response.DeleteProductPopupMessage &&
          (!this.shoppingCart || (this.shoppingCart.items && this.shoppingCart.items.length === 0));
        */
        submitOrderPopupMessage.value = response.SubmitOrderPopupMessage;
      });
    }

    return {
      state,
      homeAddress,
      deliveryAddress,
      differentAddress,
      patientDetails,
      siteCode,
      SelectedAddress,
      orderNumber,
      kwartaalService,
      kwartaalServiceDetails,
      faqs,
      leveringOptions,
      remarks,
      NeighborsDeliveryAllowed,
      userMarketingPermission,
      careProgramDetails,
      buttonText,
      possibleDeliveryDates,
      depotIssueCheckboxDetails,
      userWarehouseStatus,
      fetchCheckoutDetailsStore,
      setSelectedAddress,
      setOrderNumber,
      setRepeatService,
      setNeighborsDeliveryAllowed,
      setRemarks,
      setLeveringSubOptions,
      setLeveringOptions,
      setCustomerMarketingPermissionSubOption,
      fetchAddressLookup,
      shoppingCart,
      shoppingCartChanged,
      assortmentFeatureEnabled,
      activeCustomerId,
      prescriberDetails,
      activePpwRecId,
      noProductOverlay,
      popupMessage,
      submitOrderPopupMessage,
      patientId,
      neighborDeliveryStatus,
      repeatServiceStatus
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      orderNumberModel: '',
      isTimeWindowDelivery: false,
      addressLookup: null,
      filterCountDefault: 5,
      filterCount: null,
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      // ShowNeighborsDeliveryAllowed: true,
      currentRoute: '',
      checkDepotIssue: false
    };
  },
  watch: {
    neighborDeliveryStatus(val) {
      this.setNeighborsDeliveryAllowed(val);
    },
    repeatServiceStatus(val) {
      this.setRepeatService(val);
    }
  },
  computed: {
    addressLookupSuccessful() {
      return this.addressLookup !== null;
    },
    /**
     * @returns {array} - list with faqs filtered by dynamic value of count and sliced by dynamic value of filtercount
     */
    faqsByCustomFilter() {
      const computedFaqs = this.faqs;
      if (computedFaqs && computedFaqs.length > 0) {
        // Slice the faqs by the filter count if active else return all
        return this.filterCount ? computedFaqs.slice(0, this.filterCount) : computedFaqs;
      } else {
        return [];
      }
    },
    /**
     * @returns {boolean} - true when any shopping cart product is not given to the patient
     * */
    requireDelivery() {
      var itemsNotGivenToPatient = this.shoppingCart.items.filter((item) => item.givenToPatient !== true);
      return itemsNotGivenToPatient.length > 0;
    },
    deliveryDateFeature() {
      return this.shoppingCart.items.filter((item) => item.givenToPatient !== true).length > 0;
    },
    showNeighborsDeliveryAllowed() {
      return this.requireDelivery;
    },
    showAddress() {
      return this.requireDelivery;
    },
    isDemo() {
      return (
        this.state.navigation.demo && this.state.navigation.demoActivated && this.state.navigation.demoFlow.catalogFlow
      );
    },
    showTimeWindowCheck() {
      return this.shoppingCart.items.every((item) => item.isNutritionProduct === false) &&
        this.state.cart.selectedDeliveryDate === this.possibleDeliveryDates[0].Key
        ? true
        : false;
    },
    displayDepotIssueCheckbox() {
      for (let i = 0; i < this.shoppingCart.items.length; i++) {
        var itemRef = this.shoppingCart.items[i];
        if (itemRef.supportGivenToPatient) {
          return true;
        }
      }
      return false;
    },
    depotIssueCheckboxChecked: {
      get: function () {
        for (let i = 0; i < this.shoppingCart.items.length; i++) {
          var itemRef = this.shoppingCart.items[i];
          if (!itemRef.supportGivenToPatient) {
            continue;
          }

          if (!itemRef.givenToPatient || !itemRef.fillUpStock) {
            return false;
          }
        }
        return true;
      },
      set: function (value) {
        for (let i = 0; i < this.shoppingCart.items.length; i++) {
          var itemRef = this.shoppingCart.items[i];
          if (itemRef.supportGivenToPatient && itemRef.givenToPatient != value) {
            itemRef.givenToPatient = value;

            if (itemRef.supportFillUpStock) {
              itemRef.fillUpStock = value;
            }

            this.handleShoppingCartItemUpdate(itemRef);
          }
        }
      }
    },
    orderNumberAdded() {
      return this.orderNumberModel.length;
    },
    customerMarketingPermission() {
      let status = false;
      if (this.userMarketingPermission !== null) {
        for (let key in this.userMarketingPermission) {
          if (this.userMarketingPermission[key]) {
            status = true;
            break;
          }
        }
      }
      return status;
    },
    showDepotIssueBlock() {
      return (
        this.userWarehouseStatus &&
        this.depotIssueCheckboxDetails &&
        this.depotIssueCheckboxDetails.ShowDepotIssueCheckbox &&
        !this.assortmentFeatureEnabled
      );
    },
    /**
     * @function isInfuusPortal - function to check whether to portal is infuus or not
     */
    isInfuusPortal() {
      return this.state.navigation.siteCode === 'MIF';
    },
    /**
     * @function isDiabetesPortal - function to check whether to portal is diabetes or not
     */
    isDiabetesPortal() {
      return this.state.navigation.siteCode === 'XDD';
    },
    /**
     * @function isWISPortal - function to check whether to portal is WIS or not
     */
    isWISPortal() {
      return this.state.navigation.siteCode === 'WIS';
    }
  },
  created() {
    this.filterCount = this.filterCountDefault;
    this.currentRoute = router.currentRoute.value.name;
  },
  methods: {
    updateAddressDetails(key, event) {
      this.differentAddress[key] = event.target.value;
    },
    closeModal() {
      this.noProductOverlay = false;
    },
    /**
     * @function fetchCheckoutDetails - Function to fetch checkout page details
     */
    fetchCheckoutDetails() {
      let requiredIds = {
        prescriberId: this.prescriberDetails.id,
        patientId: this.activeCustomerId
      };
      this.fetchCheckoutDetailsStore(requiredIds).then((response) => {
        this.popupMessage = response.DeleteProductPopupMessage;
        this.noProductOverlay = false;
        /*
          // Original code: it is showing the wrong popup. Once fixed we can put the code back in although needs to be based on observable, not on promise.
          response.DeleteProductPopupMessage &&
          (!this.shoppingCart || (this.shoppingCart.items && this.shoppingCart.items.length === 0));
        */
        this.submitOrderPopupMessage = response.SubmitOrderPopupMessage;
      });
    },
    handleShoppingCartItemUpdate(item) {
      updateShoppingCartItem(this.shoppingCart, item, this.activePpwRecId);
    },
    handleShoppingCartItemRemove(item) {
      // We want the UI to be fast and not wait for debounced stuff.
      const index = this.shoppingCart.items.indexOf(item);
      if (index >= 0) {
        this.shoppingCart.items.splice(index, 1);
      }

      removeFromShoppingCart(this.shoppingCart, item.cartId);
    },
    handleShoppingCartItemAdd(item) {
      addToShoppingCart(this.shoppingCart, item.productId, item.count, this.activePpwRecId);
    },
    handleClearShoppingCart() {
      clearShoppingCart(this.shoppingCart).then(() => {
        this.closeModal();
      });
    },
    internalSaveCheckout(customerId) {
      let command = {
        customerId: customerId,
        deliveryDate: this.state.cart.selectedDeliveryDate,
        remark: this.state.cart.checkoutDetails.Remarks,
        orderNumber: this.state.cart.checkoutDetails.orderNumber,
        isRepeating: this.state.cart.checkoutDetails.RepeatService,
        NeighborsDeliveryAllowed: this.state.cart.checkoutDetails.NeighborsDeliveryAllowed,
        addressType: this.state.cart.checkoutDetails.SelectedAddress,
        CustomerMarketingPermission: null,
        address: null
      };
      if (this.state.cart.checkoutDetails.SelectedAddress === 0) {
        command.address = this.state.cart.checkoutDetails.HomeAddress;
      }
      if (this.state.cart.checkoutDetails.SelectedAddress === 1) {
        command.address = this.state.cart.checkoutDetails.DeliveryAddress;
      }
      if (this.state.cart.checkoutDetails.SelectedAddress === 2) {
        command.address = this.state.cart.checkoutDetails.DifferentAddress;
      }
      Object.keys(command.address).forEach((key) => {
        let newKey = key.toLowerCase();
        command.address[newKey] = command.address[key];
        delete command.address[key];
      });
      if (this.state.patient.customerMarketingPermission) {
        command.CustomerMarketingPermission = this.state.patient.customerMarketingPermission;
      } else {
        command.CustomerMarketingPermission = this.state.patient.patientGegevens.CustomerMarketingPermission;
      }
      // isTimeWindowDelivery value
      command.isTimeWindowDelivery = this.isTimeWindowDelivery;

      return checkout(command);
    },
    // Function to create order and send details to the server
    saveCheckout() {
      $validateAllComponents(this).then((result) => {
        if (result === false) {
          // Form is invalid. Exiting.
          return;
        }

        if (this.SelectedAddress === 2 || this.assortmentFeatureEnabled) {
          // Only difference in original code was that `validateFields` set the country - looks lke we can assume it is always NL.
          this.differentAddress.Land = 'NL';
        }

        if (this.assortmentFeatureEnabled) {
          this.setOrderNumber(this.orderNumberModel);
        }
        this.internalSaveCheckout(this.shoppingCart.customerId);
      });
    },
    // Function to update address details based on the user address radio option selection
    checkRadioAddress(e) {
      let checkedAddress = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      if (checkedAddress === 'homeAddress') {
        this.setSelectedAddress(0);
      }
      if (checkedAddress === 'deliveryAddress') {
        this.setSelectedAddress(1);
      }
      if (checkedAddress === 'differentAddress') {
        this.setSelectedAddress(2);
      }
    },
    /**
     * @function autoFillAddress - Function to auto fill the address
     */
    autoFillAddress() {
      if (!this.differentAddress.PostCode || !this.differentAddress.Huisnummer) return;
      const value = {
        postcode: this.differentAddress.PostCode,
        huisnummer: this.differentAddress.Huisnummer,
        land: 'NL'
      };
      this.fetchAddressLookup(value)
        .then((response) => {
          var hasAddress = response.data.City !== null;

          this.differentAddress.Straatnaam = hasAddress ? response.data.Street : '';
          this.differentAddress.Woonplaats = hasAddress ? response.data.City : '';

          this.addressLookup = hasAddress ? response.data : null;
          this.validateAllFields();
        })
        .catch(() => {
          this.addressLookup = null;
          this.differentAddress.Straatnaam = '';
          this.differentAddress.Woonplaats = '';
        });
    },
    async validateAllFields() {
      const result = await this.validate();
    },
    /**
     * @function goToCatalog - Function to rediect to catalog page
     */
    goToCatalog() {
      this.noProductOverlay = false;
      if (router.currentRoute.value.name !== 'aanvullenmagazijnCheckout') {
        router.push('/catalog');
      } else {
        this.patientId = null;
        router.push('/aanvullenmagazijn');
      }
    },
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    setSelectedDeliveryDate(e) {
      if (!e.currentTarget.value) {
        return;
      }
      this.state.cart.selectedDeliveryDate = e.currentTarget.value;
      if (e.currentTarget.value !== this.possibleDeliveryDates[0].key) {
        this.isTimeWindowDelivery = false;
      }
    },
    careProgramAction(actionDetails) {
      this.setCustomerMarketingPermissionSubOption(actionDetails);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout {
  color: $gray-darkest;
  .heading {
    margin: 0px;
  }
  .filter-count-button {
    margin: 20px;
  }
  .serviceBackground,
  .timeWindow {
    background-color: $light-blue-gray;
  }
  &__textarea {
    border-radius: 4px;
    border: 1px solid $gray;
    width: 100%;
    padding: 10px;
    color: $gray-darkest;
    &:focus-visible {
      outline: $gray;
    }
  }
  &__otherOptions {
    table {
      margin-bottom: 24px;
    }
  }
  .back-to-catalog {
    cursor: pointer;
  }
  .backText {
    margin: 4px 24px;
    text-decoration: underline;
  }
  .material-design-icon {
    fill: $black;
    float: left;
  }
  // Width of each table column
  $table-columns: (
    1: 40px,
    2: 30%,
    3: 30%,
    4: 15%,
    5: 15%,
    6: 50px
  ) !default;
  table {
    background-color: $grayish-very-light;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    table-layout: auto !important;
    width: 100%;
    border-collapse: collapse;
    // Generate classes for each column and assign width
    @each $table-column-key, $table-column-value in $table-columns {
      .table-col-#{$table-column-key} {
        width: $table-column-value;
      }
    }
    > thead > tr {
      background-color: $grayish-very-light;
      height: 54px;
      margin-bottom: 20px;

      > td,
      > th {
        font-size: 16px;
        color: $grayish-very-dark;
        font-weight: 400;
        padding: 20px;
        float: left;
      }
    }
    > tbody {
      background: $white;
      legend {
        margin: 0 0 20px;
      }

      > tr:first-child {
        > td,
        > th {
          vertical-align: baseline;
          text-align: left;
          padding: 16px 16px;
          width: 33%;
          max-width: 300px;
        }
      }

      > tr:last-child td {
        padding: 16px 16px;
      }

      &.action-required {
        background-color: $pink;
      }
    }
    .hidden {
      display: none;
    }
  }
  .checkoutAddress {
    table {
      > tbody {
        background: $white;
        legend {
          font-size: 16px;
          font-weight: bold;
        }

        > tr:first-child {
          > td,
          > th {
            vertical-align: baseline;
            text-align: left;
            padding: 16px 20px;
          }
        }

        > tr:last-child td {
          padding: 10px 20px 16px;
        }
      }
    }
  }
  .save-checkout {
    z-index: 99;
    position: fixed;
    top: 90%;
    right: 8%;
  }
  .disableCareProgram {
    display: none;
  }
  :deep(.checkbox-group) {
    &__label {
      &__inner {
        color: $gray-darkest;
      }
    }
  }
  :deep(.asterisk) {
    display: none;
  }
  :deep(.careProgramme-row) {
    background: rgba(0, 0, 0, 0.03) !important;
  }
  :deep(.form-group) {
    padding: 0px;
    input {
      border-style: none;
      border: 1px solid $gray-darkest;
      border-radius: 5px;
      padding: 10px 0px 10px 15px;
      color: $gray-darkest;
      height: auto;
    }
    label {
      position: absolute;
      top: -20px;
      left: 10px;
      background: $white;
      width: auto;
      padding: 5px;
      font-size: 12px;
      color: $gray-darkest;
    }
    &--focused {
      label {
        font-size: 12px;
        color: $gray-darkest;
        transform: none;
        width: auto;
        max-width: 100%;
      }
    }
    &--hasvalue {
      label {
        font-size: 12px;
        color: $gray-darkest;
        transform: none;
        width: auto;
        max-width: 100%;
      }
    }
    &__details {
      min-height: 0 !important;
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
      .form-group {
        &__error {
          padding-bottom: 8px;
        }
      }
    }
    &__icon {
      margin: -5px 10px 0px 0px;
    }
  }
  &__deliveryDates {
    :deep(.form-group) {
      select {
        margin: 0px;
      }
      label {
        padding-left: 10px;
        margin-top: -8px;
      }
      &--hasvalue {
        label {
          display: none;
        }
      }
    }
  }
}
</style>
