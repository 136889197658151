<template>
  <span class="dropdown">
    <form class="search-input" @submit.prevent="selectInput">
      <input
        id="search"
        v-model="input"
        maxlength="160"
        :placeholder="placeholder"
        type="search"
        autocomplete="off"
        spellcheck="false"
      />
      <div class="button">
        <transition name="fade">
          <span v-show="selected"><v-check-icon class="check-icon" /></span>
        </transition>
        <transition name="fade">
          <span v-show="!selected"><v-search-icon class="search-icon" @click.prevent="selectInput" /></span>
        </transition>
      </div>
    </form>
    <transition name="fade">
      <label v-if="triggerList && !selected">
        <ul>
          <!-- Primary Options -->
          <div v-if="resultList">
            <li v-show="suggestHeader" class="suggestion-header">{{ suggestHeader }}</li>
            <li v-for="(item, index) in resultList" :key="index" class="suggestion-item" @click="selectionUser(item)">
              <div>{{ item.Initials }}, {{ item.LastName }}</div>
            </li>
          </div>

          <!-- Divider-->
          <div v-if="additionalOptions">
            <li class="divider" />
          </div>

          <!-- Additional Options -->
          <div v-if="additionalOptions">
            <li v-show="suggestAdditionalHeader" class="suggestion-header">{{ suggestAdditionalHeader }}</li>
            <li
              v-for="(departmentOpt, index) in additionalOptions"
              :key="index"
              class="suggestion-item"
              @click="selectionDepartment(departmentOpt)"
            >
              {{ departmentOpt.DepartmentName }} - {{ departmentOpt.InstitutionName }}
            </li>
          </div>
        </ul>
      </label>
    </transition>
  </span>
</template>

<script>
import VSearchIcon from 'icons/Magnify';
import VCheckIcon from 'icons/Check';

export default {
  name: 'SearchDropdown',
  components: {
    VSearchIcon,
    VCheckIcon
  },
  props: {
    trigger: {
      required: false,
      type: Number,
      default: 2
    },
    options: {
      required: true,
      type: Array,
      default: () => []
    },
    suggestHeader: {
      required: false,
      type: String
    },
    additionalOptions: {
      required: false,
      type: Array,
      default: () => []
    },
    suggestAdditionalHeader: {
      required: false,
      type: String
    },
    emailValidation: {
      required: false,
      type: Boolean,
      default: false
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Zoek'
    }
  },
  data() {
    return {
      input: '',
      triggerList: false,
      selected: false,
      results: [],
      additionalResults: [],
      errorMessage: '',
      isEmailvalid: false
    };
  },
  computed: {
    resultList() {
      return this.options;
    },
    additionalResultList() {
      return this.additionalOptions;
    }
  },
  mounted() {
    // initialize arrays
    this.results = this.options;
    this.additionalResults = this.additionalOptions;
  },
  methods: {
    highlightText(item) {
      let pattern = new RegExp(this.input, 'i');
      let term = item.match(pattern);
      return item.replace(term, '<b>' + term + '</b>');
    },
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.input)) {
        this.errorMessage = '';
        this.isEmailvalid = true;
        this.triggerList = true;
        this.$emit('searchEmail', this.input, this.isEmailvalid, '');
      } else {
        this.errorMessage = 'Gelieve een geldig e-mailadres in te geven';
        this.isEmailvalid = false;
        this.triggerList = false;
        this.$emit('searchEmail', this.input, this.isEmailvalid, this.errorMessage);
      }
    },
    selectionUser(option) {
      this.selected = true;
      this.$emit('selectOption', option, true);
      this.input = option.Initials + ', ' + option.LastName;
    },
    selectionDepartment(option) {
      this.selected = true;
      this.$emit('selectOption', option, false);
      this.input = option.DepartmentName;
    },
    selectInput() {
      this.$emit('searchEmail', this.input, false, '');
      this.validateEmail();
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
}
.dropdown:hover {
  cursor: pointer;
}
.dropdown > a {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: $white;
  border: 1px solid $gray-lightest;
  padding: 6px 20px 6px 10px;
  border-radius: 4px;
  display: inline-block;
  color: $black;
  text-decoration: none;
}
.dropdown > a:before {
  position: absolute;
  right: 7px;
  top: 12px;
  content: ' ';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $black;
}
.dropdown ul {
  top: 28px;
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  left: 0px;
  width: 100%;
  list-style: none;
  padding: 4px 0px;
  display: block;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
}
.dropdown ul li {
  display: block;
  padding: 6px 20px;
  white-space: nowrap;
  min-width: 100px;
}
.dropdown ul .suggestion-item:hover {
  background-color: $gray-lightest;
  cursor: pointer;
}
.dropdown ul li a {
  text-decoration: none;
  display: block;
  color: $black;
}
.dropdown .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: $teal;
  font-size: 1px;
  padding: 0;
}
.dropdown .suggestion-header {
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $teal-dark;
}
.search-input {
  position: relative;
  input {
    display: block;
    width: 100%;
    appearance: none;
    border: 1px solid $gray;
    border-radius: 3px;
    outline: 0;
    padding: 0.75rem 3rem 0.75rem 0.9375rem;
    font-family: Lato;
    &:focus {
      border-color: $gray-dark;
      color: $black;
    }
  }
  .button {
    position: absolute;
    top: 5px;
    right: -10px;
    bottom: 0;
    width: 3rem;
    appearance: none;
    border: 0;
    padding: 0;
    border-radius: 0 3px 3px 0;
    background: 0 0;
    outline: 0;
    .search-icon {
      fill: $white;
      margin-top: 3px;
      background: $teal-darkest;
      height: 41px;
      width: 38px;
      margin-block-end: inherit;
      margin-right: 10px;
      margin-top: -5px;
    }
    .check-icon {
      fill: $teal-dark;
      margin-top: 3px;
    }
  }
}
.material-design-icon__svg {
  margin-left: 12px !important;
  margin-top: 5px !important;
}
</style>
