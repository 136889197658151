import { from, combineLatest, of, Subject } from 'rxjs';
import { activeCustomerId$ } from '@/services/activeCustomer$';
import {
  publishReplay,
  refCount,
  switchMap,
  startWith,
  publishBehavior,
  mergeAll
} from 'rxjs/operators';
import { fetchQuery } from '@/services/messaging/fetchQuery';
import { activePpwRecId$ } from '@/services/activePpwRecId$';
import {
  transferredCustomerEvent$,
  transferredCustomerWithdrawnEvent$
} from '@/services/crm/events$'

function getLicenseOverviews(customerId, ppwRecId) {
  return fetchQuery("GetLicenseOverviews", {
    customerId,
    ppwRecId
  });
}

const invalidate$ = new Subject();

export function invalidateLicenseOverviews$() {
  invalidate$.next(null);
}

const reloadLicenseOverview$ = of(
  invalidate$,
  transferredCustomerEvent$,
  transferredCustomerWithdrawnEvent$
).pipe(
  mergeAll()
);

export const licenseOverviews$ = combineLatest([activeCustomerId$, activePpwRecId$]).pipe(
  switchMap(value => {
    const customerId = value[0];
    const ppwRecId = value[1];

    return reloadLicenseOverview$.pipe(
      startWith(null),
      switchMap(() => from(getLicenseOverviews(customerId, ppwRecId))),
      publishBehavior(null),
      refCount()
    );
  }),
  publishReplay(1),
  refCount()
);
