export default {
  data () {
    return {
      staggerDelayCounter: 50,
      staggerDelayIncrement: 0
    }
  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
      //  el.style.transform = 'translate(0px, -20px)'
    },
    enter (el, done) {
      this.staggerDelayCounter += this.staggerDelayIncrement
      setTimeout(() => {
        el.style.opacity = 1
        //  el.style.transform = 'translate(0px, 0px)'
      }, this.staggerDelayCounter)
    },
    //  useful when adding new items to the staggerItems array
    resetStaggerTimer () {
      this.staggerDelayCounter = 0
    }
  }
}
