<template>
  <tr
    type
    class="table__header"
  >
    <th
      v-for="(headerItem, index) in parsedData"
      :key="index"
      class="table__header__cell"
      :class="{'minWidth': showSortingFeature(headerItem.title)}"
    >
      <span
        v-if="showSortingFeature(headerItem.title)"
        @click.prevent="toggle(headerItem)"
      >
        {{ headerItem.title }}
        <v-sort-ascending-icon
          v-if="isAscending(headerItem.title)"
          :class="{'inactive-sort': (selected !== headerItem.title)}"
        />
        <v-sort-descending-icon
          v-else
          :class="{'inactive-sort': (selected !== headerItem.title)}"
        />
      </span>
      <span v-else>
        {{ headerItem.title }}
      </span>
    </th>
  </tr>
</template>

<script>
import VSortAscendingIcon from 'icons/SortDescending' // reversed because of issue with npm package
import VSortDescendingIcon from 'icons/SortAscending' // reversed because of issue with npm package

export default {
  name: 'VHeaderRow',
  components: { VSortAscendingIcon, VSortDescendingIcon },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showSort: {
      type: Boolean,
      default: false
    },
    page: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selected: null,
      propertyInfo: {}
    }
  },
  computed: {
    parsedData () {
      return this.data.map(headerItem => {
        if (typeof headerItem === 'string') {
          return {
            title: headerItem,
            valueFn: (item) => item[headerItem]
          };
        }

        return headerItem;
      })
    }
  },
  methods: {
    isAscending (propertyName) {
      if (this.propertyInfo.hasOwnProperty(propertyName)) {
        return this.propertyInfo[propertyName].ascending;
      }

      return true;
    },
    /**
     * @function showSortingFeature
     * @description check whether to show sort option or not
     */
    showSortingFeature (option) {
      if (this.page === 'Pompenoverzicht' && option !== '') {
        return true
      }
      if (this.page === 'PumpAssignment' && option !== '') {
        return true
      }
      if (this.page === 'Rapportage' && option !== '') {
        return option === 'A-Z'
        || option === 'Geboortedatum'
      }
      if (this.showSort) {
        return option === 'Startdatum' || option === 'Einddatum'
      }
      return false
    },
    /**
     * @function toggle
     * @description funciton to toggle the sort icon
     */
    toggle (headerItem) {
      var propertyName = headerItem.title;

      if (!this.propertyInfo.hasOwnProperty(propertyName)) {
        this.propertyInfo[propertyName] = {
          ascending: true // initial sort order
        }
      }

      if (this.selected !== propertyName) {
        this.selected = propertyName
      } else {
        this.propertyInfo[propertyName].ascending = !this.propertyInfo[propertyName].ascending;
      }

      let sortDetails = {
        option: propertyName,
        isAscending: this.propertyInfo[propertyName].ascending,
        valueFn: headerItem.valueFn
      }

      this.$forceUpdate();
      this.$emit('sortByField', sortDetails)
    }
  }
}
</script>

<style lang="scss" scoped>
.table__header {
  border: $table-header-border;
  background-color: $table-header-background;

  > th:first-child {
    width: 41px;
  }
  > td, > th {
    vertical-align: baseline;
    text-align: left;
    padding: $table-row-license-cell-padding;
    color: $gray-darkest;
    font-size: type-scale(0);
    line-height: line-height(0);
    font-weight: 900;
    > span {
      cursor: pointer;
    }
    > span > .material-design-icon {
      display: inline-flex;
      vertical-align: middle;
      margin-left: 5px;
      justify-content: center;

      :deep(svg) {
        display: block;
        fill: $teal;
      }
    }

  }

  &__cell {
    color: $table-header-cell-color;
    font-size: $table-header-cell-font-size;
    line-height: $table-header-cell-line-height;
    font-weight: $table-header-cell-font-weight;
    text-align: $table-header-cell-text-align;
    padding: $table-header-cell-padding;
  }
  .minWidth {
    min-width: 150px !important;
  }
}

.inactive-sort {
  opacity:0.5;
}
</style>
