<template>
  <table class="table">
    <slot />
  </table>
</template>

<script>
export default {
  name: 'VTable'
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}
</style>
