<template>
  <fieldset id="catalogFilters" class="filters">
    <h1>Filters</h1>
    <v-checkbox-group
      v-for="(filter, index) in filters"
      :key="index"
      :label="filter.label"
      :inputid="'filter' + index"
      :initvalue="selected.indexOf(filter) > -1"
      :overwrite-value="selected.indexOf(filter) > -1"
      @update="change(filter, $event)"
    />
  </fieldset>
</template>

<script>
import VCheckboxGroup from './CheckboxGroup';

export default {
  name: 'VFilters',
  components: {
    VCheckboxGroup
  },
  props: {
    filters: Array,
    value: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      selected: []
    };
  },
  methods: {
    change(filter, active) {
      if (active) {
        this.selected.push(filter);
      } else {
        const index = this.selected.findIndex((item) => item.bundleId === filter.bundleId);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
      this.$emit('update:modelValue', this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
.filters {
  border: none;
  margin: 0;
  padding: 0;

  > h1 {
    font-size: type-scale(2);
    margin-top: 0;
    margin-bottom: 10px;
    color: $gray-darkest;
  }

  .checkbox-group {
    margin-bottom: 10px;
  }
}
</style>
