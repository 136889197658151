<template>
  <div
    class="form-group-simple"
    :class="{
      'form-group-simple--completed': completed
    }"
  >
    <label for="Postcode">{{ label }}</label>

    <input
      v-model="inputValue"
      :class="errorMessage ? 'error' : ''"
      :name="name"
      type="text"
      label="Postcode"
      placeholder="1234 AA"
      @blur="blur"
      @input="update($event.target.value)"
    />
    <div v-if="errorMessage" class="form-group-simple__details">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          <span> <v-error-icon />{{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { ref, onBeforeMount, watch, getCurrentInstance } from 'vue';
import VErrorIcon from 'icons/AlertCircle';
import { generateYupSchema } from '@/composables/globalHelperFunctions';

export default {
  name: 'VInputPostalcodeSimple',
  components: {
    VErrorIcon
  },
  props: {
    name: {
      default: 'Postcode',
      type: String
    },
    id: {
      default: '',
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    value: {
      default: null,
      type: String
    },
    overwriteValue: {
      default: null,
      type: String
    },
    rules: {
      default: null,
      type: Object
    }
  },
  setup(props) {
    const instance = getCurrentInstance();
    const inputValue = ref(props.value);
    const schema = ref(generateYupSchema(props));
    const { value, errorMessage, validate } = useField(props.id, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema(props);
      }
    );
    const completed = ref(false);

    const focus = () => {};

    const blur = () => {
      validate();
    };

    const update = async (newValue) => {
      if (newValue.length > 4 && newValue.charAt(4) !== ' ') {
        newValue = newValue.substr(0, 4) + ' ' + newValue.substr(4);
      }
      inputValue.value = newValue;
      value.value = newValue;
      let result = await validate();
      instance.emit('updateValidation', result.valid);
    };

    onBeforeMount(() => {
      if (props.value) {
        value.value = ref(props.value);
      }
    });

    return {
      inputValue,
      completed,
      focus,
      blur,
      update,
      errorMessage
    };
  }
};
</script>
<style lang="scss" scoped>
.error {
  border: 1px solid $pure-red;
}
.form-group-simple {
  position: relative;
  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
}
</style>
