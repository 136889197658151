import { from } from 'rxjs';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { getMyDepartmentTexts } from './getMyDepartmentTexts';
import { activePpwRecId$ } from "../activePpwRecId$"


export const myDepartmentTexts$ = activePpwRecId$.pipe(
  switchMap(v => from(getMyDepartmentTexts())),
  publishReplay(1),
  refCount()
);
