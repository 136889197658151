<template>
  <div>
    <li class="product">
      <a class="product__link" href="" @click.prevent="goToBaseProductDetailPage">
        <div class="product__img-wrapper">
          <img :src="baseProduct.imageUrl" @error="imageLoadError" />
        </div>
        <div class="product__text col">
          <strong class="product__text__name">{{ baseProduct.title }} </strong><br />
          <strong class="product__text__manufacturer">{{ baseProduct.manufName }}</strong>
        </div>
        <v-button v-if="!baseProduct.isExpanded" primary md data-icon="cart" @click.prevent="toggle">{{
          buttonTextOptions.more
        }}</v-button>
        <v-button v-else primary md data-icon="cart" @click.prevent="toggle">{{ buttonTextOptions.less }}</v-button>
      </a>
      <div v-if="baseProductDetail" v-show="baseProduct.isExpanded" class="product__productInformation">
        <v-product-variants
          v-if="baseProductDetail.products && baseProductDetail.products.length > 0"
          :product-variants="baseProductDetail.products"
          :base-product="baseProductDetail"
          @favourite-success="computeBaseProductDetails"
          @order="order"
        />
      </div>
    </li>
    <div v-if="isPrompt" class="product__overlay_div">
      <div class="product__overlay_box">
        <h3 class="product__closeOverlay" @click="closeOverlay()">X</h3>
        <div class="product__v_overlay">
          <div class="row grid-margin">
            <div class="col product__overlay_title">Product valt niet binnen de machtiging(en).</div>
          </div>
          <div class="row grid-margin">
            <div class="col product__overlay_text">
              Zonder machtiging ontvangt de patiënt geen vergoeding van de zorgverzekeraar. Weet u zeker dat u wilt
              doorgaan?
            </div>
          </div>
          <div class="row grid-margin">
            <div class="col-xs-6">
              <v-button
                class="btn--hollow product__noBorder product__continue_button"
                @click.prevent="continueAddProduct"
              >
                Toch bestellen
              </v-button>
            </div>
            <div class="col-xs-2">&nbsp;</div>
            <div class="col-xs-4">
              <router-link to="/patientdashboard" class="btn btn--secondary product__dashboard_button">
                Machtiging aanmaken
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="product__invisible_div" @click="closeOverlay()" />
    </div>
  </div>
</template>

<script>
import router from '@/router';
import VProductVariants from './productDetail/ProductVariants';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import {
  shoppingCart$,
  addToShoppingCart,
  currentUserIdentity$,
  activePpwRecId$,
  getReplenishmentBaseProductDetail,
  getBaseProductDetails
} from '@/services';

export default {
  name: 'VProduct',
  components: {
    VProductVariants
  },
  props: {
    baseProduct: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);
    const user = ref(null);
    const activePpwRecId = ref(null);

    const overlayActive = computed(() => store.getters['catalog/getOverlay']);
    const setOrderSuccessStatus = (value) => store.commit('catalog/setOrderSuccessStatus', value);
    const setOrderDetails = (value) => store.commit('catalog/setOrderDetails', value);
    const setOrderClicked = (value) => store.commit('cart/setOrderClicked', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
    });

    currentUserIdentity$.subscribe((value) => {
      user.value = value;
    });

    activePpwRecId$.subscribe((value) => {
      activePpwRecId.value = value;
    });

    return {
      overlayActive,
      setOrderSuccessStatus,
      setOrderDetails,
      setOrderClicked,
      shoppingCart,
      user,
      activePpwRecId
    };
  },
  data() {
    return {
      baseProductDetail: null,
      isExpanded: false,
      buttonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      isPrompt: false,
      currentOrderDetails: {}
    };
  },
  computed: {
    isReplenishmentFlow() {
      return router.currentRoute.value.meta.flow === 'replenishment';
    },
    isAssortmentFlow() {
      return router.currentRoute.value.meta.flow === 'assortment';
    },
    isCustomerFlow() {
      return router.currentRoute.value.meta.flow === 'customer';
    }
  },
  watch: {
    baseProductDetail(value) {
      if (
        value &&
        value.products &&
        value.products.length === 0 &&
        this.baseProduct.baseProductId === value.baseProductId
      ) {
        this.goToBaseProductDetailPage();
      }
    },
    overlayActive(value) {
      if (value) {
        this.baseProduct.isExpanded = false;
        this.baseProductDetail = null;
      }
    }
  },
  methods: {
    imageLoadError() {
      console.warn('No product image');
    },
    goToBaseProductDetailPage() {
      if (this.isCustomerFlow) {
        router.push('/catalog/baseProduct/' + this.baseProduct.baseProductId);
      }
      if (this.isReplenishmentFlow) {
        router.push('/aanvullenmagazijn/baseProduct/' + this.baseProduct.baseProductId);
      }
      if (this.isAssortmentFlow) {
        router.push('/assortiment/baseProduct/' + this.baseProduct.baseProductId);
      }
    },
    toggle(e) {
      e.stopPropagation();
      this.baseProduct.isExpanded = !this.baseProduct.isExpanded;
      if (this.baseProduct.isExpanded && !this.baseProductDetail) {
        this.computeBaseProductDetails();
      }
    },
    computeBaseProductDetails() {
      // fetch and assign product detail
      (this.isReplenishmentFlow
        ? getReplenishmentBaseProductDetail(this.baseProduct.baseProductId)
        : getBaseProductDetails(this.baseProduct.baseProductId, this.isCustomerFlow)
      ).then((response) => {
        this.baseProductDetail = response;
      });
    },
    // Function to show an alert message
    showAlert(productId) {
      this.setOrderSuccessStatus(true);
      let orderedProduct = this.baseProductDetail.products.find((x) => x.productId === productId);
      this.setOrderDetails(orderedProduct);
      setTimeout(() => {
        this.setOrderSuccessStatus(false);
        this.setOrderDetails({});
      }, 2500);
    },
    // Function to add product to the cart
    order({ productId, count }) {
      this.setOrderClicked(true);
      let order = this.baseProductDetail;
      if (order) {
        let isInsuredProd =
          this.baseProductDetail.products.filter((x) => x.productId === productId && x.isInsured === true).length > 0;
        if (!isInsuredProd && this.isCustomerFlow) {
          this.isPrompt = true;
          this.currentOrderDetails = {
            productId: productId,
            amount: count
          };
        } else {
          this.isPrompt = false;
          order.isInsured = isInsuredProd;
          order.addedToBasket = true;
          order.count = this.count;
          addToShoppingCart(this.shoppingCart, productId, count, this.activePpwRecId);
          this.showAlert(productId);
        }
      }
    },
    continueAddProduct() {
      this.setOrderClicked(true);
      this.isPrompt = false;
      addToShoppingCart(
        this.shoppingCart,
        this.currentOrderDetails.productId,
        this.currentOrderDetails.amount,
        this.activePpwRecId
      );
      this.showAlert(this.currentOrderDetails.productId);
      this.currentOrderDetails = {};
    },
    closeOverlay() {
      this.isPrompt = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  background: $white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.49);
  margin-bottom: 10px;
  padding: 16px;
  overflow: hidden;
  &__productInformation {
    margin-top: 10px;
  }
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }
  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    min-height: 80px;
    min-width: 80px;

    img {
      max-height: 80px;
      max-width: 80px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;

    &__name {
      color: $gray-darkest;
      font-weight: normal;
      font-size: type-scale(1);
      line-height: line-height(1);
      padding: 0px 10px 0px 10px;
    }

    &__manufacturer {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: $gray-dark;
      padding: 0px 10px 0px 10px;
    }

    &__props {
      margin: 0;
      padding: 0px 0px 0px 15px;
      list-style-type: disc;
      color: $gray;
      font-size: type-scale(-1);
      line-height: line-height(-1);
      font-style: italic;
      width: 100%;
      &__li {
        float: left;
        width: 50%;
        word-wrap: break-word;
      }
    }
  }
  &__v_overlay {
    width: 460px;
  }
  &__noBorder {
    border: none;
    margin: 5px 0px 0px 0px;
  }
  &__dashboard_button {
    width: 220px;
    height: 40px;
    border-radius: 3px;
    background-color: $teal-darkest;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }
  &__continue_button {
    width: 150px;
    height: 30px;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $cyan;
  }
  &__overlay_title {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $cyan;
    font-weight: 300;
  }
  &__overlay_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $gray-darkest;
  }
  &__closeOverlay {
    float: right;
    margin: -5px 0px 0px 0px;
    cursor: pointer;
  }
  &__overlay_div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999 !important;
  }
  &__overlay_box {
    position: relative;
    z-index: 99999998 !important;
    text-align: left;
    padding: 2rem;
    background: $white;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
  }
  &__invisible_div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
