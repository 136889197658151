import { registerEvent } from '@/services/messaging/clientEventMethods';
import { map, merge } from 'rxjs/operators';

export const userLoggedInEvent$ = registerEvent("UserLoggedInEvent");
export const userLoggedOutEvent$ = registerEvent("UserLoggedOutEvent");
export const userNotLoggedInInvalidUsernameEvent$ = registerEvent("UserNotLoggedInInvalidUsernameEvent");
export const userNotLoggedInEmptyCodeEvent$ = registerEvent("UserNotLoggedInEmptyCodeEvent");
export const userNotLoggedInInvalidCodeEvent$ = registerEvent("UserNotLoggedInInvalidCodeEvent");
export const userNotLoggedInInvalidPasswordEvent$ = registerEvent("UserNotLoggedInInvalidPasswordEvent");

export const userLoginState = {
  LoggedIn: 1 << 0,
  InvalidUsername: 1 << 1,
  EmptyCode: 1 << 2,
  InvalidCode: 1 << 3,
  InvalidPassword: 1 << 4,
  LoggedOut: 1 << 5,
}

export const userLoginStateChange$ = merge([
  userLoggedInEvent$.pipe(map(e => userLoginState.LoggedIn)),
  userNotLoggedInInvalidUsernameEvent$.pipe(map(e => userLoginState.InvalidUsername)),
  userNotLoggedInEmptyCodeEvent$.pipe(map(e => userLoginState.EmptyCode)),
  userNotLoggedInInvalidCodeEvent$.pipe(map(e => userLoginState.InvalidCode)),
  userNotLoggedInInvalidPasswordEvent$.pipe(map(e => userLoginState.InvalidPassword)),
  userLoggedOutEvent$.pipe(map(e => userLoginState.LoggedOut))]
);
