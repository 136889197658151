import { from, combineLatest } from 'rxjs';
import axios from 'axios';
import { switchMap, publishBehavior, refCount } from 'rxjs/operators';
import { shoppingCartRefreshRequested$ } from './shoppingCartRefreshRequested$';

export const assortmentShoppingCart$ = combineLatest([from([0]), shoppingCartRefreshRequested$]).pipe(
  switchMap((_) => {
    return from(
      axios.get('ShoppingCart/GetReplenishmentShoppingCart').then((response) => {
        // mold the current response into a more appropriate `items`
        return {
          customerId: null, // replenishment...
          items: response.data
        };
      })
    );
  }),
  publishBehavior({
    customerId: null, // replenishment...
    items: []
  }),
  refCount()
);
