<template>
  <section class="UsernamePasswordLogin">
    <form class="UsernamePasswordLogin__wrapper" @submit.prevent="onLogin">
      <v-heading :level="1" inverted>Inloggen</v-heading>
      <div v-if="!showAuthenticationField">
        <v-input-group-simple-2
          id="username"
          v-model="username"
          class="input"
          type="text"
          label="Gebruikersnaam"
          data-vv-as="Gebruikersnaam"
          placeholder="Uw gebruikersnaam"
          :rules="{
            required: true
          }"
        />
        <v-input-group-simple-2
          id="password"
          :key="inputId"
          v-model="password"
          :show-hide-password-icon="true"
          class="input"
          :type="passwordType"
          label="Wachtwoord"
          data-vv-as="Wachtwoord"
          placeholder="Uw wachtwoord"
          :rules="{
            required: true
          }"
        />
      </div>
      <div v-if="showAuthenticationField">
        <p>
          <b>{{ authenticationConfiguration.title2Fa }}</b>
        </p>
        <p>
          <b>{{ authenticationConfiguration.description2Fa }}</b>
        </p>
        <div class="verification">
          <p class="verification__label">
            {{ authenticationConfiguration.label2Fa }}
          </p>
          <input
            id="verificationCode"
            v-model="verificationCode"
            maxlength="6"
            placeholder="Uw verificatiecode"
            data-vv-as="verificatie code"
            :overwriteValue="verificationCode"
            :rules="{
              required: true
            }"
          />
          <v-button class="resend-button" @click="resendVerificationCode()">
            {{ authenticationConfiguration.resend2FaCodeButtonLabel }}
          </v-button>
          <small v-if="invalidVerificationCode">Verificatie code opnieuw verzonden</small>
        </div>
      </div>
      <div class="row action-buttons grid-margin grid-align-center">
        <div class="col-xs-12 col-md-6">
          <v-button id="loginButton" secondary lg disabled> Inloggen </v-button>
        </div>
        <div v-if="!showAuthenticationField" class="col-xs-12 col-md-6">
          <router-link :to="{ name: 'herstelwachtwoord' }" class="text-link"> Wachtwoord vergeten </router-link>
        </div>
      </div>
      <div v-if="!showAuthenticationField" class="wrong-credentials">
        <p v-if="invalidUsername || (invalidPassword && invalidUsername)">
          Deze gebruikersnaam is niet bekend, controleer uw gegevens.
        </p>
        <p v-if="invalidPassword && !invalidUsername">
          Uw wachtwoord is niet correct. Probeer het nogmaals of klik op 'Wachtwoord vergeten'.
        </p>
      </div>
      <div v-if="!showAuthenticationField && registrationDetails">
        <v-paragraph class="notregister"> Nog niet geregistreerd? </v-paragraph>
        <a :href="registrationDetails.regUrl" target="_blank" class="btn btn--hollow btn--lg">{{
          registrationDetails.regText
        }}</a>
      </div>
    </form>
  </section>
</template>
<script>
import VParagraph from '../../shared/Paragraph.vue';
import { tap } from 'rxjs/operators';
// eslint-disable-next-line no-unused-vars

import {
  formLogin,
  isLoggedIn$,
  authenticationConfiguration$,
  userNotLoggedInEmptyCodeEvent$,
  userNotLoggedInInvalidUsernameEvent$,
  userNotLoggedInInvalidPasswordEvent$,
  userNotLoggedInInvalidCodeEvent$,
  storeForceSecurityPolicySelection
} from '@/services';
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'UsernamePasswordLogin',
  components: {
    VParagraph
  },
  setup() {
    const store = useStore();
    const isLoggedIn = ref(false);
    const invalidVerificationCode = ref(null);
    const invalidPassword = ref(false);
    const invalidUsername = ref(false);
    const showAuthenticationField = ref(false);
    const authenticationConfiguration = ref(null);

    const portalLogo = computed(() => store.getters['navigation/getPortalLogo']);
    const backgroundLogo = computed(() => store.getters['navigation/getBackgroundLogo']);
    const registrationDetails = computed(() => store.getters['navigation/getRegistrationDetails']);

    const subscriptions = () => {
      // isLoggedIn subscription
      watchEffect(() => {
        isLoggedIn.value = isLoggedIn$.value;
      });

      // invalidVerificationCode subscription
      watchEffect(() => {
        invalidVerificationCode.value = userNotLoggedInInvalidCodeEvent$.value;
        if (invalidVerificationCode.value) {
          // Reset verification code
          this.verificationCode = '';
        }
      });

      // userNotLoggedInEmptyCodeEvent subscription
      watchEffect(() => {
        showAuthenticationField.value = userNotLoggedInEmptyCodeEvent$.value;
      });

      // authenticationConfiguration subscription
      watchEffect(() => {
        authenticationConfiguration.value = authenticationConfiguration$.value;
      });
    };

    // Call subscriptions when component is mounted
    subscriptions();
    userNotLoggedInInvalidUsernameEvent$.subscribe((data) => {
      invalidUsername.value = data;
      if (invalidUsername.value) {
        invalidUsername.value = true;
      }
    });
    userNotLoggedInInvalidPasswordEvent$.subscribe((data) => {
      invalidPassword.value = data;
      if (invalidPassword.value) {
        invalidUsername.value = false;
        invalidPassword.value = true;
      }
    });

    return {
      portalLogo,
      backgroundLogo,
      registrationDetails,
      isLoggedIn,
      invalidVerificationCode,
      invalidPassword,
      invalidUsername,
      showAuthenticationField,
      authenticationConfiguration
    };
  },
  data() {
    return {
      errorMessage: '',
      showPassword: false,
      username: null,
      password: null,
      showResendNotification: false,
      passwordType: 'password',
      verificationCode: '',
      inputId: 0
    };
  },
  watch: {
    username(value) {
      if (value && this.password) {
        document.getElementById('loginButton').classList.remove('btn--disabled');
      } else {
        document.getElementById('loginButton').classList.add('btn--disabled');
      }
    },
    password(value) {
      if (value && this.username) {
        document.getElementById('loginButton').classList.remove('btn--disabled');
      } else {
        document.getElementById('loginButton').classList.add('btn--disabled');
      }
    }
  },
  mounted() {
    document.addEventListener('onautocomplete', function (e) {
      if (e.target.id === 'username' && e.target.hasAttribute('autocompleted')) {
        document.getElementById('loginButton').classList.remove('btn--disabled');
      }
    });
  },
  methods: {
    onLogin() {
      if (this.showAuthenticationField === true) {
        formLogin(this.username, this.password, this.verificationCode);
      } else {
        formLogin(this.username, this.password);
      }
      storeForceSecurityPolicySelection(true);
    },
    resendVerificationCode() {
      formLogin(this.username, this.password);
    },
    changePasswordVisibility(e) {
      this.showPassword = !this.showPassword;
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.inputId += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.UsernamePasswordLogin {
  a.text-link {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  a.btn.btn--hollow.btn--lg {
    border-color: $white;
    color: $white;
    font-weight: normal;
  }

  &__wrapper {
    margin: 10px;
    .input {
      margin-bottom: 1rem;
    }
    .notregister {
      margin-bottom: 0.5rem;
      font-weight: 900;
    }
  }
  .verification {
    padding: 10px 0 20px 0;
    &__label {
      font-weight: bold;
    }
  }
  .resend-button {
    padding: 10px 0 10px 0;
    color: $black;
    text-decoration: underline;
    font-weight: 400;
  }
  .wrong-credentials {
    padding: 20px 0 20px 0;
    p {
      color: $pure-red;
    }
  }
}
</style>
