import { getFromWebAPi } from '@/services/messaging/fetchQuery';
import router from '@/router';

export function getOsaLicenseOverviewDetails(licenseId) {
  let type = router.currentRoute.value.name;
  let computedApiCall = 'osa/GetEvaluationLicenseOverview?licenseId=';
  if (type === 'readout') {
    computedApiCall = 'osa/GetReadoutLicenseOverview?licenseId=';
  }
  if (type === 'posa-spt') {
    computedApiCall = 'EvaluationSpt/GetEvaluationSptLicenseOverview?licenseId=';
  }
  computedApiCall = computedApiCall + licenseId;
  return getFromWebAPi(computedApiCall).then((response) => {
    if (response && response.Success) {
      if (type === 'evaluation') {
        return response.EvaluationLicenseOverview;
      } else if (type === 'readout') {
        return response.ReadoutLicenseOverview;
      } else {
        return response.EvaluationSptLicenseOverview;
      }
    }
  });
}
