<template>
  <div>
    <div v-if="accountabilityId" class="row full-width-header">
      <div class="col">
        <v-header :title="title" :is-new-accountability="isNewAccountability" :accountability-id="accountabilityId" />
        <div class="row grid-padding">
          <div class="col-xs-12 col-md-10 col-lg-6">
            <div class="row">
              <div class="col-xs-12 col-md-8">
                <v-heading :level="3"> Verantwoorden </v-heading>
              </div>
              <div class="col-xs-12 col-md-4 grid-justify-content-center grid-align-end">
                <router-link class="link" :to="'/machtiging/' + accountabilityId"> wijzigen </router-link>
              </div>
            </div>
            <table id="step1" class="accountability-overview">
              <tr v-for="(question, indexquestion) in questions" :key="indexquestion" :data-index="indexquestion">
                <td v-if="!question.IsLaatste">
                  {{ question.Tekst }}
                </td>
                <template v-for="(answer, index) in question.Antwoorden">
                  <td
                    v-if="answer.TypeCode === 'Standard' && question.ResultaatAnswers[0].AnswerId === answer.AnswerId"
                    :key="index"
                  >
                    {{ answer.AntwoordTekst }}
                  </td>
                  <td
                    v-if="
                      (answer.TypeCode === 'OpenStandaard' || answer.TypeCode === 'Open') &&
                      question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                    "
                    :key="index"
                  >
                    {{ answer.AntwoordTekst }}
                    <div
                      v-if="
                        question.ResultaatAnswers.length > 0 &&
                        question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                      "
                      class="openquestion"
                    >
                      <div
                        v-if="
                          question.ResultaatAnswers.length > 0 &&
                          question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                        "
                      >
                        {{ question.ResultaatAnswers[0].Resultaat }}
                      </div>
                    </div>
                  </td>
                  <td v-if="answer.TypeCode === 'Dropdown'" :key="index">
                    <div
                      v-for="(option, indexoptions) in answer.AntwoordLijst"
                      :key="indexoptions"
                      :data-index="indexoptions"
                    >
                      <div
                        v-if="
                          question.ResultaatAnswers.length > 0 &&
                          question.ResultaatAnswers[0].AnswerListItemId === option.AnswerListItemId
                        "
                      >
                        {{ option.Omschrijving }}
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
            </table>
            <div v-if="!isSingleAccountability" class="row">
              <div class="col-xs-12 col-md-8">
                <v-heading :level="3"> Producten </v-heading>
              </div>
              <div class="col-xs-12 col-md-4 grid-justify-content-center grid-align-end">
                <router-link class="link" :to="'/machtiging/' + accountabilityId">
                  Meer producten toevoegen
                </router-link>
              </div>
            </div>
            <div v-if="!isSingleAccountability" tagelement="section" class="products-selected">
              <strong class="products-selected__daily-dosage">Geselecteerd voor totaal dagdosering</strong>
              <v-empty-message v-if="productsSelectedDailyDosage.length < 1">
                Geen producten geselecteerd met totaal dagdosering
              </v-empty-message>
              <ul class="products-selected__list daily_dosage">
                <v-product
                  v-for="(product, index) in productsSelectedDailyDosage"
                  :key="product.ProductId"
                  :data="product"
                  selected
                  :alt="product.Smaakbox"
                >
                  <template v-slot:actions>
                    <div v-if="productsSelectedDailyDosage.length === index + 1" class="product__text__dosage">
                      Totaal dosering: {{ product.Basket_UnitOfMeasure }}
                    </div>
                  </template>
                </v-product>
              </ul>
              <div class="products-seperator" />
              <v-empty-message v-if="productsSelectedNoDailyDosage.length < 1">
                Geen producten geselecteerd zonder totaal dagdosering
              </v-empty-message>
              <ul class="products-selected__list">
                <v-product
                  v-for="product in productsSelectedNoDailyDosage"
                  :key="product.ProductId"
                  :data="product"
                  selected
                  :alt="product.Smaakbox"
                >
                  <template v-slot:actions>
                    <div class="product__text__dosage">
                      {{ product.Basket_UnitOfMeasure }}
                    </div>
                  </template>
                </v-product>
              </ul>
            </div>
            <form v-if="formConfig" name="accountabilityOverview" @submit.prevent="validateBeforeSubmit">
              <template v-if="VTPlusVisibilityStatus">
                <div class="information-container">
                  <v-heading :level="3">
                    {{ patientGegevens.CareProgramInfo.VTPlus.Header }}
                  </v-heading>
                  <div class="vtplusvoorwaarden">
                    <div class="rte" v-html="patientGegevens.CareProgramInfo.VTPlus.Text" />
                  </div>
                  <v-checkbox-group
                    v-model="formConfig.IsVTPlus.model"
                    :error="!!formErrors[formConfig.IsVTPlus.ref]"
                    :error-message="formErrors[formConfig.IsVTPlus.ref]"
                    :rule="formConfig.IsVTPlus.rule"
                    :label="patientGegevens.CareProgramInfo.VTPlus.Footer"
                    :inputid="formConfig.IsVTPlus.ref"
                    :initvalue="formConfig.IsVTPlus.model"
                  />
                </div>
              </template>
              <v-heading :level="3"> Bezorging </v-heading>
              <div v-if="patientGegevens.WoonAdres" class="form-group__radio">
                <input id="woonadres" name="deliveryaddress" type="radio" checked @change="checkRadioAddress" />
                <label for="woonadres">
                  <span></span>
                  {{ patientGegevens.WoonAdres.Straatnaam }}
                  {{ patientGegevens.WoonAdres.Huisnummer }}
                  {{ patientGegevens.WoonAdres.Toevoeging }}, {{ patientGegevens.WoonAdres.Postcode }},
                  {{ patientGegevens.WoonAdres.Woonplaats }} ({{ patientGegevens.WoonAdres.Land }})
                </label>
              </div>
              <div v-if="patientGegevens.AfwijkendeAdres" class="form-group__radio">
                <input id="afwijkendwoonadres" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                <label for="afwijkendwoonadres">
                  <span></span>
                  {{ patientGegevens.AfwijkendeAdres.Straatnaam }}
                  {{ patientGegevens.AfwijkendeAdres.Huisnummer }}
                  {{ patientGegevens.AfwijkendeAdres.Toevoeging }}, {{ patientGegevens.AfwijkendeAdres.Postcode }},
                  {{ patientGegevens.AfwijkendeAdres.Woonplaats }} ({{ patientGegevens.AfwijkendeAdres.Land }})
                </label>
              </div>
              <div class="form-group__radio">
                <input id="differentaddress" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                <label for="differentaddress"><span></span> Ander bezorgadres </label>
              </div>
              <transition name="fade">
                <div v-if="differentAddress" class="extrafields">
                  <div class="row grid-margin">
                    <div class="col-xs-4">
                      <v-input-group
                        v-model="formData[formConfig.newAddressPostcode.ref]"
                        maxlength="7"
                        initvalue=""
                        :rule="formConfig.newAddressPostcode.rule"
                        :label="formConfig.newAddressPostcode.label"
                        :inputid="formConfig.newAddressPostcode.ref"
                        @blur="
                          validateField(formConfig.newAddressPostcode.ref, formData[formConfig.newAddressPostcode.ref]);
                          autoFillAddress();
                        "
                      />
                    </div>
                    <div class="col-xs-4">
                      <v-input-group
                        v-model="formData[formConfig.newAddressHuisnummer.ref]"
                        initvalue=""
                        :rule="formConfig.newAddressHuisnummer.rule"
                        :label="formConfig.newAddressHuisnummer.label"
                        :inputid="formConfig.newAddressHuisnummer.ref"
                        @blur="
                          validateField(
                            formConfig.newAddressHuisnummer.ref,
                            formData[formConfig.newAddressHuisnummer.ref]
                          );
                          autoFillAddress();
                        "
                      />
                    </div>
                    <div class="col-xs-4">
                      <v-input-group
                        v-model="formData[formConfig.newAddressToevoeging.ref]"
                        initvalue=""
                        :rule="formConfig.newAddressToevoeging.rule"
                        :label="formConfig.newAddressToevoeging.label"
                        :inputid="formConfig.newAddressToevoeging.ref"
                        @blur="
                          validateField(
                            formConfig.newAddressToevoeging.ref,
                            formData[formConfig.newAddressToevoeging.ref]
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row grid-margin">
                    <div class="col">
                      <v-input-group
                        v-model="formData[formConfig.newAddressStraatnaam.ref]"
                        initvalue=""
                        :rule="formConfig.newAddressStraatnaam.rule"
                        :label="formConfig.newAddressStraatnaam.label"
                        :inputid="formConfig.newAddressStraatnaam.ref"
                        :disabled="formConfig.newAddressStraatnaam.disabled"
                        :overwrite-value="oStreet"
                        @blur="
                          validateField(
                            formConfig.newAddressStraatnaam.ref,
                            formData[formConfig.newAddressStraatnaam.ref]
                          )
                        "
                      />
                    </div>
                    <div class="col">
                      <v-input-group
                        v-model="formData[formConfig.newAddressWoonplaats.ref]"
                        initvalue=""
                        :rule="formConfig.newAddressWoonplaats.rule"
                        :label="formConfig.newAddressWoonplaats.label"
                        :inputid="formConfig.newAddressWoonplaats.ref"
                        :disabled="formConfig.newAddressWoonplaats.disabled"
                        :overwrite-value="oCity"
                        @blur="
                          validateField(
                            formConfig.newAddressWoonplaats.ref,
                            formData[formConfig.newAddressWoonplaats.ref]
                          )
                        "
                      />
                    </div>
                    <div v-if="stamDataLanden.Landen" class="col">
                      <v-select-group
                        v-model="formData[formConfig.newAddressLand.ref]"
                        :label="formConfig.newAddressLand.label"
                        :selectid="formConfig.newAddressLand.ref"
                        :initselected="'NL'"
                      >
                        <option
                          v-for="(item, index) in stamDataLanden.Landen"
                          :key="index"
                          :data-index="index"
                          :value="item.IsoCode"
                        >
                          {{ item.Naam }}
                        </option>
                      </v-select-group>
                    </div>
                  </div>
                </div>
              </transition>
              <div v-if="hasAccessories" class="form-group__radio">
                <input id="hospital" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                <label for="hospital"><span></span> {{ formConfig.hospitalDelivery.label }} </label>
              </div>
              <transition name="fade">
                <div v-if="hospitalAddress" class="extrafields">
                  <legend>Afspreken met:</legend>
                  <div class="row grid-margin">
                    <div class="col">
                      <v-input-group
                        v-model="formData[formConfig.hospitalNameDepartment.ref]"
                        initvalue=""
                        :rule="formConfig.hospitalNameDepartment.rule"
                        :label="formConfig.hospitalNameDepartment.label"
                        :inputid="formConfig.hospitalNameDepartment.ref"
                        @blur="
                          validateField(
                            formConfig.hospitalNameDepartment.ref,
                            formData[formConfig.hospitalNameDepartment.ref]
                          )
                        "
                      />
                    </div>
                    <div class="col">
                      <v-input-group
                        v-model="formData[formConfig.hospitalTelephone.ref]"
                        initvalue=""
                        :rule="formConfig.hospitalTelephone.rule"
                        :label="formConfig.hospitalTelephone.label"
                        :inputid="formConfig.hospitalTelephone.ref"
                        @blur="
                          validateField(formConfig.hospitalTelephone.ref, formData[formConfig.hospitalTelephone.ref])
                        "
                      />
                    </div>
                  </div>
                </div>
              </transition>
              <div v-if="hasAccessories" class="information-container">
                <v-heading :level="3"> Aanvullende informatie gewenst </v-heading>
                <v-paragraph>
                  Voor de patient zijn toebehoren geselecteerd. Om u en de patient beter van dienst te kunnen zijn
                  verzoeken wij u de volgende gegevens in te vullen.
                </v-paragraph>
                <legend>Gewenst aflevermoment (datum en dagdeel)</legend>
                <div class="row grid-margin">
                  <div class="col">
                    <v-input-date-picker
                      id="deliveryDay"
                      ref="date"
                      type="date"
                      name="deliveryDay"
                      autofocus
                      empty-date
                      :disable-next-date="computedNextDayStatus"
                      :date-between-range="dateBetweenRange"
                      :holiday-dates="holidayDates"
                      @update-validation="updateValidation"
                    />
                  </div>
                  <div class="col">
                    <v-select-group
                      v-model="formData[formConfig.deliveryTime.ref]"
                      :label="formConfig.deliveryTime.label"
                      selectid="deliveryTime"
                      :error="!!formErrors[formConfig.deliveryTime.ref]"
                      :error-message="formErrors[formConfig.deliveryTime.ref]"
                      initselected=""
                      @blur="validateField(formConfig.deliveryTime.ref, formData[formConfig.deliveryTime.ref])"
                    >
                      <option value="no_preference">Geen voorkeur</option>
                      <option value="morning">Ochtend</option>
                      <option value="afternoon">Middag</option>
                    </v-select-group>
                  </div>
                </div>
                <div v-if="computedNextDayStatus" class="row grid-margin">
                  <div class="col">
                    <v-warning-message
                      :class="{ 'zoomOut animated': animated }"
                      :warning="'Bestellingen na 17:00 uur kunnen niet meer de volgende werkdag geleverd worden. Kies een andere dag.'"
                      :html="false"
                      :is-urgent="false"
                    />
                  </div>
                </div>
                <div class="row grid-margin">
                  <div class="col">
                    <v-checkbox-group
                      v-model="formConfig.instruction.model"
                      :label="formConfig.instruction.label"
                      :inputid="formConfig.instruction.ref"
                    />
                  </div>
                </div>
                <div v-if="showInstructionOptions" class="row grid-margin">
                  <div class="col instruction-type">
                    {{ deliveryInstructionLabels.SubHeader }}
                    <div class="form-group__radio">
                      <input
                        id="deliveryinstruction-house"
                        v-model="formData[formConfig.instructionType.ref]"
                        name="deliveryInstruction"
                        type="radio"
                        value="2"
                        @change="deliveryInstructionErrorMsg = 1"
                      />
                      <label for="deliveryinstruction-house">
                        <span></span> {{ deliveryInstructionLabels.HomeVisit }}
                      </label>
                    </div>
                    <div class="form-group__radio">
                      <input
                        id="deliveryinstruction-videocall"
                        v-model="formData[formConfig.instructionType.ref]"
                        name="deliveryInstruction"
                        type="radio"
                        value="1"
                        @change="deliveryInstructionErrorMsg = 2"
                      />
                      <label for="deliveryinstruction-videocall">
                        <span></span> {{ deliveryInstructionLabels.VideoCall }}
                      </label>
                    </div>
                    <div class="form-group__radio">
                      <input
                        id="deliveryinstruction-hospital"
                        v-model="formData[formConfig.instructionType.ref]"
                        name="deliveryInstruction"
                        type="radio"
                        value="3"
                        @change="deliveryInstructionErrorMsg = 3"
                      />
                      <label for="deliveryinstruction-hospital">
                        <span></span> {{ deliveryInstructionLabels.HospitalVisit }}
                      </label>
                    </div>
                    <div
                      v-show="formSubmitted && showInstructionOptions && deliveryInstructionErrorMsg === 0"
                      class="form-group-simple__details"
                    >
                      <div class="form-group-simple__messages">
                        <div class="form-group-simple__error">Kies één van de opties hierboven om verder te gaan.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row grid-margin">
                  <div class="col form-group__radio delivery_rdb_question">
                    <v-checkbox-group
                      v-model="isCheckThuiszorg"
                      :initvalue="isCheckThuiszorg"
                      :label="formConfig.homecare.label"
                      :inputid="'chkThuiszorg'"
                    />
                  </div>
                </div>
                <div v-if="thuiszorgQuestion">
                  <div class="row">
                    <div class="col form-group__radio delivery_rdb_answer">
                      <input
                        id="ja"
                        v-model="formData[formConfig.homecare.ref]"
                        name="Homecare"
                        value="1"
                        type="radio"
                        @change="checkThuiszorg"
                      />
                      <label for="ja"><span></span> Ja </label>
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="thuiszorgFields" class="row grid-margin delivery_txt_answer">
                      <div class="col">
                        <v-input-group
                          v-model="formData[formConfig.nameThuiszorg.ref]"
                          :initvalue="formData[formConfig.nameThuiszorg.ref]"
                          :rule="formConfig.nameThuiszorg.rule"
                          :label="formConfig.nameThuiszorg.label"
                          :inputid="formConfig.nameThuiszorg.ref"
                          @blur="validateField(formConfig.nameThuiszorg.ref, formData[formConfig.nameThuiszorg.ref])"
                        />
                      </div>
                      <div class="col">
                        <v-input-group
                          v-model="formData[formConfig.telephoneThuiszorg.ref]"
                          :initvalue="formData[formConfig.telephoneThuiszorg.ref]"
                          :rule="formConfig.telephoneThuiszorg.rule"
                          :label="formConfig.telephoneThuiszorg.label"
                          :inputid="formConfig.telephoneThuiszorg.ref"
                          @blur="
                            validateField(
                              formConfig.telephoneThuiszorg.ref,
                              formData[formConfig.telephoneThuiszorg.ref]
                            )
                          "
                        />
                      </div>
                    </div>
                  </transition>
                  <div class="row">
                    <div class="col form-group__radio delivery_rdb_answer">
                      <input
                        id="nee"
                        v-model="formData[formConfig.homecare.ref]"
                        name="Homecare"
                        value="2"
                        type="radio"
                        @change="checkThuiszorg"
                      />
                      <label for="nee"><span></span> Nee </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col form-group__radio delivery_rdb_answer">
                      <input
                        id="nietbekend"
                        v-model="formData[formConfig.homecare.ref]"
                        name="Homecare"
                        value="3"
                        type="radio"
                        @change="checkThuiszorg"
                      />
                      <label for="nietbekend"><span></span> Niet bekend </label>
                    </div>
                  </div>
                </div>
              </div>
              <div id="step2">
                <v-heading :level="3"> Voorgeschreven voeding direct leveren? </v-heading>
                <div class="form-group__radio">
                  <input
                    id="isdirectdelivery-yes"
                    v-model="formData[formConfig.isdirectdelivery.ref]"
                    name="isdirectdelivery"
                    type="radio"
                    value="true"
                    checked
                    @change="showDirectDeliveryMsg = true"
                  />
                  <label for="isdirectdelivery-yes"><span></span> Ja</label>
                </div>
                <div class="form-group__radio">
                  <input
                    id="isdirectdelivery-no"
                    v-model="formData[formConfig.isdirectdelivery.ref]"
                    name="isdirectdelivery"
                    type="radio"
                    value="false"
                    @change="showDirectDeliveryMsg = false"
                  />
                  <label for="isdirectdelivery-no"><span></span> Nee</label>
                </div>
              </div>
              <v-warning-message v-if="!showDirectDeliveryMsg" :message="texts.directDeliveryText" :html="true" />
              <div v-if="showDirectDeliveryMsg" class="information-container">
                <v-heading :level="3">
                  {{ texts.repeatOrderTitle }}
                </v-heading>
                <v-paragraph>{{ texts.repeatOrderDescription }}</v-paragraph>
                <div class="row grid-margin">
                  <div class="col">
                    <v-checkbox-group
                      v-model="repeatOrder"
                      :label="texts.repeatOrderCheckboxText"
                      inputid="repeatOrder"
                      :overwrite-value="repeatOrder"
                    />
                  </div>
                </div>
              </div>
              <v-heading :level="3"> Opmerkingen </v-heading>
              <v-textarea-group
                v-model="formData[formConfig.comment.ref]"
                :rule="formConfig.comment.ref"
                :label="formConfig.comment.label"
                :inputid="formConfig.comment.ref"
                @input="saveInput"
              />
              <div v-if="showExtendLicensesBlock" class="row">
                <v-heading :level="3" class="extHeader">
                  {{ ExtendLicenseMsg.Header }}
                </v-heading>
                <div class="col-md-12 rte">
                  <v-paragraph>{{ ExtendLicenseMsg.Text }}</v-paragraph>
                </div>
                <!--DROPDOWN QUESTION -->
                <v-select-group
                  v-model="extendLicensesMonth"
                  label="Maak een keuze"
                  selectid="extendLicensesMonth"
                  :initselected="extendLicensesMonth"
                  @change="checkSelectAnswer($event)"
                >
                  <option
                    v-for="(monthOption, monIndex) in extendLicenseOptions"
                    :key="monIndex"
                    :initvalue="extendLicensesMonth"
                    :value="monthOption.value"
                    :selected="monIndex === 0"
                  >
                    {{ monthOption.label }}
                  </option>
                </v-select-group>
                <span class="extendLicenseFromEndDate">
                  <v-checkbox-group
                    v-model="extendLicenseFromEndDate"
                    label="Aansluitend verlengen na de einddatum van de huidige machtiging."
                    inputid="extendLicenseFromEndDate"
                    :overwrite-value="extendLicenseFromEndDate"
                  />
                </span>
              </div>
              <table v-if="canSign && hasMultipleSignees" class="table prescriber__signature">
                <tbody>
                  <tr v-if="hasMultipleSignees">
                    <td colspan="2">
                      <v-select-group
                        label="Beschikbare volmachtgevers"
                        selectid="warranter"
                        initselected=""
                        @change="setSelectedSigneePpwRecId($event)"
                        placeholder="Selecteer volmachtgever"
                      >
                        <option
                          v-for="(signee, index) in signees"
                          :key="index"
                          :data-index="index"
                          :initvalue="signee.ppwRecId"
                          :value="signee.ppwRecId"
                        >
                          {{ signee.name }}
                        </option>
                      </v-select-group>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div id="step3" class="row saveAcc">
                <v-button
                  :disabled="accountabilitySigned || !selectedSignee"
                  tagelement="button"
                  lg
                  cta
                  @click.once="gtmTrackSigning"
                >
                  Ondertekenen
                </v-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <generic-modal v-if="orderIsSaved">
      <template #body>
        <v-paragraph v-if="isNewAccountability">
          Order voor {{ patientGegevens.Voorletters }} {{ patientGegevens.Tussenvoegsel }}
          {{ patientGegevens.Achternaam }} is verzonden. Ga terug naar:
        </v-paragraph>
        <v-paragraph v-if="!isNewAccountability">
          Uw wijziging/verlenging is doorgevoerd voor {{ patientGegevens.Voorletters }}
          {{ patientGegevens.Tussenvoegsel }} {{ patientGegevens.Achternaam }}
        </v-paragraph>
      </template>
      <template #footer>
        <router-link to="/" class="btn btn--hollow btn--transparent btn--md"> Mijn Dashboard </router-link>
        <router-link to="/patientdashboard" class="btn btn--primary btn--md"> Naar patiëntendashboard </router-link>
      </template>
    </generic-modal>
  </div>
</template>
<script>
import $ from 'jquery';
import { useStore, mapActions } from 'vuex';
import { computed, ref } from 'vue';
import { useForm } from 'vee-validate';
import VProduct from './selectProduct/Product';
import VEmptyMessage from './selectProduct/EmptyMessage';
import GenericModal from '@/components/shared/GenericModal';
import VWarningMessage from '@/components/shared/WarningMessage';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VButton from '@/components/shared/Button.vue';
import VHeader from '@/components/sections/patient/accountability/PatientAccountabilityHeader';
import VParagraph from '@/components/shared/Paragraph';
import VTextareaGroup from '@/components/shared/form/TextareaGroup';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import { localDateToJson, stringToLocalDate } from '@/services/date.service';
import { getAccountabilityRootStatus, currentUserIdentity$, getSignees } from '@/services';
import { router$ } from '@/router/router$';
import { map, filter, switchMap, publishReplay, refCount } from 'rxjs/operators';

export default {
  name: 'PatientAccountabilityOverview',
  components: {
    VTextareaGroup,
    VParagraph,
    VButton,
    VEmptyMessage,
    VCheckboxGroup,
    VHeader,
    VProduct,
    VInputDatePicker,
    VWarningMessage,
    GenericModal
  },
  setup() {
    const store = useStore();
    const user = ref(null);
    const accountabilityId = ref(null);
    const productSelectionAccountabilityId = ref(null);
    const treatmentAreaId = ref(null);
    const signees = ref([]);
    const holidayDates = ref(null);

    const isCheckThuiszorg = ref(false);
    const thuiszorgQuestion = ref(false);
    const thuiszorgFields = ref(false);
    const extendLicensesMonth = ref(1);
    const isVTPlus = ref(null);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const deliveryInstructionLabels = computed(() => store.getters['accountability/getDeliveryInstruction']);
    const hasAccessories = computed(() => store.getters['accountability/getIsAccessoriesSelected']);

    const formConfig = ref({
      newAddressPostcode: {
        ref: 'Postcode',
        label: 'Postcode',
        rule: {
          required: true,
          regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/
        },
        attached: false
      },
      newAddressHuisnummer: {
        ref: 'Huisnummer',
        label: 'Huisnummer',
        rule: {
          required: true
        },
        attached: false
      },
      newAddressToevoeging: {
        ref: 'Toevoeging',
        label: 'Toevoeging',
        rule: {
          required: false
        },
        attached: false
      },
      newAddressStraatnaam: {
        ref: 'Straatnaam',
        label: 'Straatnaam',
        rule: {
          required: true
        },
        attached: false
      },
      newAddressWoonplaats: {
        ref: 'Woonplaats',
        label: 'Woonplaats',
        rule: {
          required: true
        },
        attached: false
      },
      newAddressLand: {
        ref: 'Land',
        label: 'Land',
        rule: {},
        attached: false
      },
      hospitalDelivery: {
        ref: 'hospital',
        label: 'Ziekenhuis',
        rule: {},
        attached: false
      },
      hospitalNameDepartment: {
        ref: 'Naam / Afdeling ziekenhuis',
        label: 'Naam / Afdeling',
        rule: {
          required: true
        },
        attached: false
      },
      hospitalTelephone: {
        ref: 'Afdeling telefoonnummer',
        label: 'Afdeling telefoonnummer',
        rule: {
          required: true,
          regex: /^(?=^.{11}$)0\d+[- ]\d+$|^(?=^.{10}$)0\d+$/
        },
        attached: false
      },
      deliveryTime: {
        ref: 'Dagdeel Aflevermoment',
        label: 'Dagdeel',
        rule: {
          required: false
        },
        attached: hasAccessories
      },
      instruction: {
        ref: 'instructie',
        model: false,
        label: deliveryInstructionLabels.value.Header,
        rule: {},
        attached: false
      },
      homecare: {
        ref: 'Homecare',
        label: 'Is thuiszorg ingeschakeld?',
        rule: {},
        attached: false
      },
      nameThuiszorg: {
        ref: 'Naam thuiszorgorganisatie',
        label: 'Naam thuiszorgorganisatie',
        rule: {
          required: true
        },
        attached: thuiszorgFields
      },
      telephoneThuiszorg: {
        ref: 'Telefoon thuiszorgorganisatie',
        label: 'Telefoon thuiszorgorganisatie',
        rule: {
          required: true
        },
        attached: false
      },
      comment: {
        ref: 'comment',
        label: 'Vul hier een eventuele opmerking in, bijv. voor- afkeur voor smaken',
        rule: {},
        attached: false
      },
      IsVTPlus: {
        ref: 'IsVTPlus',
        model: false,
        alias: '',
        rule: {},
        attached: false
      },
      isdirectdelivery: {
        ref: 'isdirectdelivery',
        model: patientGegevens.value.isdirectdelivery,
        alias: '',
        rule: {},
        attached: false
      },
      instructionType: {
        ref: 'instructionType',
        alias: '',
        rule: {},
        attached: false
      },
      extendLicense: {
        ref: 'extendLicense',
        model: extendLicensesMonth.value,
        label: '',
        rule: {},
        attached: false
      }
    });
    const formErrors = ref({});
    const formData = ref({
      VTPlusVoorwaarden1: null,
      VTPlusVoorwaarden2: null,
      VTPlusVoorwaarden3: null,
      isdirectdelivery: 'true'
    });

    const stamDataLanden = computed(() => store.getters['navigation/getStamDataLanden']);
    const productsSelectedNoDailyDosage = computed(
      () => store.getters['selectProduct/getProductsSelectedNoDailyDosage']
    );
    const productsSelectedDailyDosage = computed(() => store.getters['selectProduct/getProductsSelectedDailyDosage']);
    const questions = computed(() => store.getters['accountability/getQuestions']);
    const isSingleAccountability = computed(() => store.getters['accountability/getIsSingleAccountability']);
    const isNewAccountability = computed(() => store.getters['accountability/getIsNewAccountability']);
    const title = computed(() => store.getters['accountability/getTitleAccountability']);
    const texts = computed(() => store.getters['accountability/getTexts']);
    const userMarketingPermission = computed(() => store.getters['navigation/getUserMarketingPermission']);
    const showExtendLicensesBlock = computed(() => store.getters['accountability/getShowExtendLicensesBlock']);
    const ExtendLicenseMsg = computed(() => store.getters['accountability/getExtendLicenseMsg']);
    const isStartDate = computed(() => store.getters['accountability/getIsStartDate']);
    const customStartDate = computed(() => store.getters['accountability/getStartDateForTwoColumnAccountability']);
    const deliveryDetails = computed(() => store.getters['accountability/getDeliveryDetails']);

    const fetchTexts = async () => await store.dispatch('accountability/fetchTexts');
    const getCurrentPatient = async () => await store.dispatch('patient/getCurrentPatient');
    const fetchCalendarDates = async () => await store.dispatch('accountability/fetchCalendarDates');
    const getQuestions = (value) => store.dispatch('accountability/getQuestions', value);
    const fetchStamDataLanden = async () => await store.dispatch('navigation/fetchStamDataLanden');
    const fetchProductsSelected = async (value) => await store.dispatch('selectProduct/fetchProductsSelected', value);
    const setShowExtendLicensesBlock = (value) => store.commit('accountability/setShowExtendLicensesBlock', value);
    const fetchAddressLookup = (value) => store.dispatch('patient/fetchAddressLookup', value);
    const setQuestions = (value) => store.commit('accountability/setQuestions', value);
    const resetProductsAndFilters = () => store.commit('selectProduct/resetProductsAndFilters');

    const accountabilityId$ = router$.pipe(
      map((route) => route && route.params.id / 1),
      filter((accountabilityId) => !!accountabilityId)
    );
    const productSelectionAccountabilityId$ = accountabilityId$.pipe(
      switchMap((accountabilityId) => getAccountabilityRootStatus(accountabilityId)),
      map((accountabilityRootStatus) =>
        accountabilityRootStatus.productSelection ? accountabilityRootStatus.productSelection.accountabilityId : null
      )
    );

    productSelectionAccountabilityId$.subscribe((id) => (productSelectionAccountabilityId.value = id));

    const loadAccountability = async (accountabilityId) => {
      await fetchTexts();
      await getCurrentPatient();
      holidayDates.value = await fetchCalendarDates();
      const response = await getQuestions(accountabilityId);

      if (patientGegevens.value.CustomerMarketingPermission) {
        formConfig.value.IsVTPlus.model = patientGegevens.value.CustomerMarketingPermission.VTPlus;
      }
      if (
        deliveryDetails.value &&
        Object.keys(deliveryDetails.value).length > 0 &&
        deliveryDetails.value.HomeCare === 1
      ) {
        isCheckThuiszorg.value = true;
        thuiszorgQuestion.value = true;
        formData.value[formConfig.value.homecare.ref] = deliveryDetails.value.HomeCare;
        thuiszorgFields.value = true;
        formData.value[formConfig.value.nameThuiszorg.ref] = deliveryDetails.value.HomeCareOrganizationName;
        formData.value[formConfig.value.telephoneThuiszorg.ref] = deliveryDetails.value.PhoneOfHomeCareOrganization;
        formConfig.value.nameThuiszorg.rule.required = true;
        formConfig.value.telephoneThuiszorg.rule = true;
      } else if (
        deliveryDetails.value &&
        Object.keys(deliveryDetails.value).length > 0 &&
        deliveryDetails.value.HomeCare !== 0
      ) {
        isCheckThuiszorg.value = true;
        thuiszorgQuestion.value = true;
        formData.value[formConfig.value.homecare.ref] = deliveryDetails.value.HomeCare;
        thuiszorgFields.value = false;
        formData.value[formConfig.value.nameThuiszorg.ref] = '';
        formData.value[formConfig.value.telephoneThuiszorg.ref] = '';
        formConfig.value.nameThuiszorg.ref = false;
        formConfig.value.telephoneThuiszorg.ref = false;
      }
      // check if call for Landen is necessary
      if (!stamDataLanden.value.Landen) {
        await fetchStamDataLanden();
      }

      if (!isSingleAccountability.value && productSelectionAccountabilityId.value) {
        await fetchProductsSelected(productSelectionAccountabilityId.value);
      }

      // set original vtplus state, if true then dont show vtplus
      isVTPlus.value = patientGegevens.value.IsVTPlus;

      formErrors.value = {
        [formConfig.value.newAddressPostcode.ref]: undefined,
        [formConfig.value.newAddressHuisnummer.ref]: undefined,
        [formConfig.value.newAddressToevoeging.ref]: undefined,
        [formConfig.value.newAddressStraatnaam.ref]: undefined,
        [formConfig.value.newAddressWoonplaats.ref]: undefined,
        [formConfig.value.newAddressLand.ref]: undefined,
        [formConfig.value.hospitalNameDepartment.ref]: undefined,
        [formConfig.value.hospitalTelephone.ref]: undefined,
        [formConfig.value.deliveryTime.ref]: undefined,
        [formConfig.value.nameThuiszorg.ref]: undefined,
        [formConfig.value.telephoneThuiszorg.ref]: undefined,
        [formConfig.value.comment.ref]: undefined,
        [formConfig.value.IsVTPlus.ref]: undefined,
        [formConfig.value.extendLicense.ref]: undefined
      };
      formData.value.address = formData.value.address = patientGegevens.value.WoonAdres;

      if (showExtendLicensesBlock.value) {
        $('html, body').animate({ scrollTop: $(document).height() }, 'slow');
      }

      return response;
    };

    const accountability$ = accountabilityId$.pipe(
      switchMap((accountabilityId) => loadAccountability(accountabilityId)),
      publishReplay(1),
      refCount()
    );

    const treatmentAreaId$ = accountability$.pipe(map((accountability) => accountability.TreatmentAreaId));

    const signees$ = treatmentAreaId$.pipe(
      switchMap((treatmentAreaId) => getSignees(treatmentAreaId)),
      publishReplay(1),
      refCount()
    );

    accountabilityId$.subscribe((id) => (accountabilityId.value = id));
    treatmentAreaId$.subscribe((id) => (treatmentAreaId.value = id));
    signees$.subscribe((data) => (signees.value = data));
    currentUserIdentity$.subscribe((userData) => (user.value = userData));

    return {
      patientGegevens,
      stamDataLanden,
      productsSelectedNoDailyDosage,
      productsSelectedDailyDosage,
      questions,
      isSingleAccountability,
      hasAccessories,
      isNewAccountability,
      title,
      texts,
      userMarketingPermission,
      showExtendLicensesBlock,
      ExtendLicenseMsg,
      isStartDate,
      customStartDate,
      deliveryInstructionLabels,
      deliveryDetails,
      fetchTexts,
      getCurrentPatient,
      fetchCalendarDates,
      getQuestions,
      fetchStamDataLanden,
      fetchProductsSelected,
      setShowExtendLicensesBlock,
      fetchAddressLookup,
      setQuestions,
      resetProductsAndFilters,
      ...mapActions('patient', ['fetchAllPatientFormData', 'fetchAllPatientFormData']),
      ...mapActions('accountability', ['submitAndSignAccountabilityForm']),
      accountabilityId,
      treatmentAreaId,
      signees,
      productSelectionAccountabilityId,
      user,
      formConfig,
      formErrors,
      isCheckThuiszorg,
      thuiszorgQuestion,
      formData,
      thuiszorgFields,
      extendLicensesMonth
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      dateBetweenRange: 2,
      showDirectDeliveryMsg: true,
      holidayDates: [],
      differentAddress: false,
      hospitalAddress: false,
      orderIsSaved: false,
      accountabilitySigned: false,
      addressLookup: {
        City: null,
        Street: null
      },
      oStreet: '',
      oCity: '',
      extendLicenseOptions: [
        { value: 1, label: '1 maand' },
        { value: 2, label: '2 maanden' },
        { value: 3, label: '3 maanden' },
        { value: 6, label: '6 maanden' },
        { value: 12, label: '12 maanden' }
      ],
      deliveryInstructionErrorMsg: 0,
      formSubmitted: false,
      showInstructionOptions: false,
      extendLicenseFromEndDate: false,
      repeatOrder: false,
      selectedSigneePpwRecId: ''
    };
  },
  computed: {
    computedNextDayStatus() {
      let currentDate = new Date();
      return currentDate.getHours() > 16 ? true : false;
    },
    canSign() {
      return this.signees && this.signees.length > 0;
    },
    hasMultipleSignees() {
      return this.signees && this.signees.length > 1;
    },
    hasSingleSignee() {
      return this.signees && this.signees.length === 1;
    },
    selectedSignee() {
      if (this.hasSingleSignee) {
        return this.signees[0];
      } else if (this.hasMultipleSignees) {
        return this.signees.find((v) => v.ppwRecId == this.selectedSigneePpwRecId);
      }

      return null;
    },
    isSignable() {
      return this.isLastAnswer && this.canSign && this.selectedSignee;
    },
    // E40-7258 currently removing VTPlus as per requirement may be in future will revert back
    VTPlusVisibilityStatus() {
      return (
        this.userMarketingPermission &&
        this.patientGegevens.HasAgreedToShareData &&
        !this.hasAccessories &&
        this.patientGegevens.CareProgramInfo &&
        this.patientGegevens.CareProgramInfo.VTPlus
      );
    }
  },
  watch: {
    addressLookup() {
      if (this.addressLookup.Street && this.addressLookup.City) {
        this.formData[this.formConfig.newAddressStraatnaam.ref] = this.addressLookup.Street;
        this.oStreet = this.addressLookup.Street;
        this.oCity = this.addressLookup.City;
        this.formData[this.formConfig.newAddressWoonplaats.ref] = this.addressLookup.City;
        this.formConfig.newAddressStraatnaam.overwriteValue = this.addressLookup.Street;
        this.formConfig.newAddressWoonplaats.overwriteValue = this.addressLookup.City;
        this.validateField(
          this.formConfig.newAddressStraatnaam.ref,
          this.formData[this.formConfig.newAddressStraatnaam.ref]
        );
        this.validateField(
          this.formConfig.newAddressWoonplaats.ref,
          this.formData[this.formConfig.newAddressWoonplaats.ref]
        );
      } else {
        this.formConfig.newAddressStraatnaam.overwriteValue = null;
        this.formConfig.newAddressWoonplaats.overwriteValue = null;
        this.oStreet = null;
        this.oCity = null;
      }
    },
    // Set 'repeatOrder' status to false on the change of status 'showDirectDeliveryMsg'
    showDirectDeliveryMsg(value) {
      this.repeatOrder = false;
    },
    isCheckThuiszorg(value) {
      if (!value) {
        this.formData[this.formConfig.homecare.ref] = 0;
      }
      this.thuiszorgQuestion = value;
    },
    'formConfig.instruction.model': {
      handler(value) {
        this.showInstructionOptions = value;
        if (!value) {
          this.deliveryInstructionErrorMsg = 0;
          this.formSubmitted = false;
          this.formData[this.formConfig.instructionType.ref] = null;
        } else {
          this.formData[this.formConfig.instructionType.ref] = value;
        }
      },
      deep: true
    }
  },
  async created() {},
  unmounted() {
    this.setQuestions([]);
    this.resetProductsAndFilters();
  },
  methods: {
    updateValidation(date) {
      const d = stringToLocalDate(date);
      const dJson = localDateToJson(d);
      this.formData['Datum Aflevermoment'] = dJson;
    },
    checkRadioAddress(e) {
      this.formConfig.newAddressPostcode.rule.required = false;
      this.formConfig.newAddressPostcode.rule.regex = false;
      this.formConfig.newAddressHuisnummer.rule.required = false;
      this.formConfig.newAddressToevoeging.rule.required = false;
      this.formConfig.newAddressStraatnaam.rule.required = false;
      this.formConfig.newAddressWoonplaats.rule.required = false;
      this.formConfig.newAddressLand.rule.required = false;
      this.formConfig.hospitalNameDepartment.rule.required = false;
      this.formConfig.hospitalTelephone.rule.required = false;
      let checkedAddress = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      if (checkedAddress === 'woonadres') {
        this.formData.address = this.patientGegevens.WoonAdres;
        this.differentAddress = false;
        this.hospitalAddress = false;
      } else if (checkedAddress === 'afwijkendwoonadres') {
        this.formData.address = this.patientGegevens.AfwijkendeAdres;
        this.differentAddress = false;
        this.hospitalAddress = false;
      } else if (checkedAddress === 'differentaddress') {
        this.formData.address = {
          [this.formConfig.newAddressPostcode.ref]: '',
          [this.formConfig.newAddressHuisnummer.ref]: '',
          [this.formConfig.newAddressToevoeging.ref]: '',
          [this.formConfig.newAddressStraatnaam.ref]: '',
          [this.formConfig.newAddressWoonplaats.ref]: '',
          [this.formConfig.newAddressLand.ref]: 'NL'
        };
        this.formData[this.formConfig.newAddressLand.ref] = 'NL';
        this.differentAddress = true;
        this.hospitalAddress = false;
        this.formConfig.newAddressPostcode.rule.required = true;
        this.formConfig.newAddressPostcode.rule.regex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        this.formConfig.newAddressHuisnummer.rule.required = true;
        this.formConfig.newAddressToevoeging.rule.required = false;
        this.formConfig.newAddressStraatnaam.rule.required = true;
        this.formConfig.newAddressWoonplaats.rule.required = true;
        this.formConfig.newAddressLand.rule.required = true;
        this.formConfig.hospitalNameDepartment.rule.required = false;
        this.formConfig.hospitalTelephone.rule.required = false;
      } else if (checkedAddress === 'hospital') {
        this.formData.address = {};
        this.differentAddress = false;
        this.hospitalAddress = true;
        this.formConfig.hospitalNameDepartment.rule.required = true;
        this.formConfig.hospitalTelephone.rule.required = true;
        this.formConfig.hospitalTelephone.regex = /^(?=^.{11}$)0\d+[- ]\d+$|^(?=^.{10}$)0\d+$/;
      } else {
        this.differentAddress = false;
        this.hospitalAddress = false;
      }
    },
    checkThuiszorg(e) {
      let fieldName = e.currentTarget.value;
      if (fieldName === '1') {
        this.thuiszorgFields = true;
        this.formConfig.nameThuiszorg.rule.required = true;
        this.formConfig.telephoneThuiszorg.required = true;
      } else {
        this.thuiszorgFields = false;
        this.formConfig.nameThuiszorg.rule.required = false;
        this.formConfig.telephoneThuiszorg.rule.required = false;
      }
    },
    validateBeforeSubmit() {
      this.formSubmitted = true;
      this.validateAllFields();
    },
    async validateAllFields() {
      const result = await this.validate();
      if (
        result.valid &&
        !(this.formSubmitted && this.showInstructionOptions && this.deliveryInstructionErrorMsg === 0)
      ) {
        this.accountabilitySigned = true;
        this.formData.isvtplus = this.formConfig.IsVTPlus.model;
        if (
          !this.formData.address.Postcode ||
          !this.formData.address.Huisnummer ||
          !this.formData.address.Straatnaam ||
          !this.formData.address.Woonplaats
        ) {
          this.formData.address = {
            [this.formConfig.newAddressPostcode.ref]: this.formData.Postcode,
            [this.formConfig.newAddressHuisnummer.ref]: this.formData.Huisnummer,
            [this.formConfig.newAddressToevoeging.ref]: this.formData.Toevoeging,
            [this.formConfig.newAddressStraatnaam.ref]: this.formData.Straatnaam,
            [this.formConfig.newAddressWoonplaats.ref]: this.formData.Woonplaats,
            [this.formConfig.newAddressLand.ref]: this.formData.Land
          };
        }
        if (this.differentAddress) {
          let diffAddress =
            'Afwijkend Afleveradres: ' +
            this.formData.address[[this.formConfig.newAddressPostcode.ref]] +
            ', ' +
            this.formData.address[[this.formConfig.newAddressHuisnummer.ref]];
          if (this.formData.address[[this.formConfig.newAddressToevoeging.ref]]) {
            diffAddress = diffAddress + ' ' + this.formData.address[[this.formConfig.newAddressToevoeging.ref]];
          }
          diffAddress =
            diffAddress +
            ', ' +
            this.formData.address[[this.formConfig.newAddressStraatnaam.ref]] +
            ', ' +
            this.formData.address[[this.formConfig.newAddressWoonplaats.ref]] +
            ', ' +
            this.formData.address[[this.formConfig.newAddressLand.ref]];
          if (this.formData[[this.formConfig.comment.ref]]) {
            this.formData[[this.formConfig.comment.ref]] =
              'Remarks: ' + this.formData[[this.formConfig.comment.ref]] + ', ' + diffAddress;
          } else {
            this.formData[[this.formConfig.comment.ref]] = diffAddress;
          }
        }
        if (this.isStartDate && this.customStartDate !== null) {
          const startDate = stringToLocalDate(this.customStartDate);
          const startDateJson = localDateToJson(startDate);

          this.formData.startDate = startDateJson;
        }
        if (this.showExtendLicensesBlock) {
          this.formData[[this.formConfig.extendLicense.ref]] = this.extendLicensesMonth;
          this.formData.extendLicenseFromEndDate = this.extendLicenseFromEndDate;
          if (!this.extendLicenseFromEndDate) {
            const startDateJson = localDateToJson(new Date());

            this.formData.startDate = startDateJson;
          }
        }
        this.formData.signeePpwRecId = this.selectedSignee.ppwRecId;
        this.formData.repeatOrder = this.repeatOrder;
        this.formData.accountabilityId = this.accountabilityId;
        this.submitAndSignAccountabilityForm(this.formData).then(() => {
          this.$emit('success');
          this.orderIsSaved = true;
          this.setShowExtendLicensesBlock(false);
        });
      } else {
      }
    },
    validateField(ref, model) {
      this.formSubmitted = false;
    },
    gtmTrackSigning() {
      if (this.$gtm) {
        this.$gtm.trackEvent({
          event: 'ButtonClick',
          event_category: 'Dieetpreparaat aanvragen',
          event_action: 'Ondertekenen'
        });
      }
    },
    toggleCheckboxVTPlus(active) {
      if (active) {
        this.formConfig.VTPlusVoorwaarden1.rule = {
          alias: this.formConfig.VTPlusVoorwaarden1.alias
        };
        this.formConfig.VTPlusVoorwaarden2.rule = {
          alias: this.formConfig.VTPlusVoorwaarden2.alias
        };
        this.formConfig.VTPlusVoorwaarden3.rule = {
          alias: this.formConfig.VTPlusVoorwaarden3.alias
        };
      } else {
        this.formConfig.VTPlusVoorwaarden1.rule.required = false;
        this.formConfig.VTPlusVoorwaarden2.ref.required = false;
        this.formConfig.VTPlusVoorwaarden3.ref.required = false;
      }
    },
    autoFillAddress() {
      if (
        this.formData[this.formConfig.newAddressPostcode.ref] &&
        this.formData[this.formConfig.newAddressHuisnummer.ref] &&
        this.formData[this.formConfig.newAddressLand.ref]
      ) {
        this.fetchAddressLookup({
          postcode: this.formData[this.formConfig.newAddressPostcode.ref],
          huisnummer: this.formData[this.formConfig.newAddressHuisnummer.ref],
          land: this.formData[this.formConfig.newAddressLand.ref]
        })
          .then((response) => {
            this.addressLookup = response.data;
            if (this.addressLookup.City === null) {
              this.formConfig.newAddressStraatnaam.disabled = false;
              this.formConfig.newAddressWoonplaats.disabled = false;
            } else {
              this.formConfig.newAddressStraatnaam.disabled = true;
              this.formConfig.newAddressWoonplaats.disabled = true;
            }
          })
          .catch(() => {
            this.addressLookup = { City: null, Street: null };
            this.formConfig.newAddressStraatnaam.disabled = false;
            this.formConfig.newAddressWoonplaats.disabled = false;
          });
      }
    },
    checkSelectAnswer(e) {
      if (e.currentTarget.value) {
        this.extendLicensesMonth = parseInt(e.currentTarget.value, 10);
      }
    },
    setSelectedSigneePpwRecId(event) {
      if (!event.currentTarget.value) {
        return;
      }
      this.selectedSigneePpwRecId = event.currentTarget.value;
    },
    saveInput(event) {
      this.formData[[this.formConfig.comment.ref]] = event.target ? event.target.value : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriber__signature {
  img {
    border: solid 2px $grayish-med-light;
    vertical-align: text-top;
    max-width: 350px !important;
    height: auto;
    width: 100%;
  }
  :deep(.form-group) {
    padding: 0px;
    label {
      font-weight: 900;
      color: $gray-dark;
      position: initial;
      transform: none !important;
      line-height: normal;
      background: transparent;
    }
    &__icon {
      margin: 25px 10px 0 0;
    }
    .form-group__details {
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
    }
  }
}
// Width of each table column
$table-columns: (
  1: 40px,
  2: 30%,
  3: 30%,
  4: 15%,
  5: 15%,
  6: 50px
) !default;
table {
  table-layout: auto !important;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  text-align: left;
  // Generate classes for each column and assign width
  @each $table-column-key, $table-column-value in $table-columns {
    .table-col-#{$table-column-key} {
      width: $table-column-value;
    }
  }
  > tbody {
    > tr:first-child {
      > td,
      > th {
        vertical-align: baseline;
        text-align: left;
        padding: 16px 10px;
      }
    }
    > tr:last-child td {
      padding: 10px 10px 16px;
    }
  }

  .hidden {
    display: none;
  }
}
.extendLicenseFromEndDate {
  margin: 10px 0px 20px 0px;
}
.saveAcc {
  margin: 24px 0 24px 0;
}
.extHeader {
  min-width: 200px;
}
.full-width-header {
  width: 100%;
}
.inline {
  display: inline-flex;
}
.main__view {
  padding: 0;
  h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.products-selected {
  background: $white;
  border: 1px solid $gray-light;
  padding: 16px;
  &__list {
    position: relative;
    padding: 0;
    margin: 0;
    min-height: 100px;
    &.daily_dosage {
      .product__text__dosage {
        color: $gray-darkest;
        position: absolute;
        bottom: -15px;
        right: 0;
      }
    }
  }
}
.products-seperator {
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: solid 2px $gray-dark;
}
.link {
  text-decoration: underline;
  color: $teal-darkest;
  text-align: end;
}
legend {
  font-weight: bold;
  color: $gray-dark;
}
.extrafields {
  margin: 0 28px;
  color: $gray-dark;
}
.datepicker {
  margin-top: 18px;
}
.accountability-overview {
  background: $white;
  border: 1px solid $gray-light;
  color: $gray-dark;
  margin-bottom: 16px;
  td {
    padding: 16px;
    &:nth-child(2) {
      font-weight: bold;
      min-width: 200px;
    }
  }
}
.information-container {
  border: 1px solid $teal;
  background: $teal-lighter;
  padding: 16px;
  margin: 16px 0 0;
  h3 {
    margin: 0;
    color: $gray-dark;
    font-weight: bold;
  }
}
.instruction-type {
  margin: 0px 0px 20px 34px !important;
  font-size: 14px;
  color: #333333;
  line-height: 1.5em;
}
.fade-enter-active,
.fade-leave-active {
  max-height: 500px;
  transition: all 0.65s ease-in;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
  transition: all 0.45s ease-out;
  overflow: hidden;
}
:deep(.checkbox-group .checkbox-group__label__inner) {
  white-space: nowrap;
  line-height: 20px !important;
}
.vtplusvoorwaarden {
  color: $gray-dark;
  padding-bottom: 20px;
}
.extendLicenses {
  color: $gray-dark;
  padding-bottom: 20px;
}
:deep(.form-group) {
  input {
    background: transparent;
  }
}
.rte {
  p {
    margin-bottom: 0.5rem;
  }
}
.delivery_rdb_question {
  align-items: flex-start;
}
.delivery_rdb_answer {
  align-items: start;
  margin-left: 30px;
}
.delivery_txt_answer {
  margin-left: 35px;
}
:deep(.form-group) {
  .form-group__input {
    input {
      border-bottom: 1px solid #666;
    }
  }
}
:deep(.form-group--error) {
  .form-group__input {
    input {
      border-bottom: none !important;
    }
  }
}
</style>
