import axios from 'axios';
export default {
  namespaced: true,
  state: {
    questions: {},
    isLastAnswer: false,
    isAllowedToProceed: false,
    isAccessoriesSelected: false,
    productSelectionId: '',
    accountabilities: [],
    isSingleAccountability: false,
    isNewAccountability: true,
    titleAccountability: '',
    licenseWithTreatmentAreaName: '',
    texts: {},
    pdfFileToken: null,
    pdf: null,
    deliveryInstruction: {},
    deliveryDetails: {},
    treatmentAreaWiseAccoutabilityTemplates: [],
    isStartDate: false,
    showPopUp: false,
    showExtendLicensesBlock: false,
    ExtendLicenseMsg: {},
    startDateForTwoColumnAccountabiliy: null,
    isWLZ: false,
    isZVZ: false,
    SetWLZ: null,
    SetZVZ: null,
    listSearchItems: null,
    ForwardAccountabilityPopup: {},
    AccountabilityTexts: null,
    accountabilityId: null,
    calendarEvent: {}
  },
  mutations: {
    setIsWLZ(state, payload) {
      state.isWLZ = payload;
    },
    setIsZVZ(state, payload) {
      state.isZVZ = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setProductSelectionId(state, payload) {
      state.productSelectionId = payload;
    },
    setIsSingleAccountability(state, payload) {
      state.isSingleAccountability = payload;
    },
    setIsAllowedToProceed(state, payload) {
      state.isAllowedToProceed = payload;
    },
    setIsAccessoriesSelected(state, payload) {
      state.isAccessoriesSelected = payload;
    },
    setIsLastAnswer(state, payload) {
      state.isLastAnswer = payload;
    },
    setAccountabilities(state, payload) {
      state.accountabilities = payload;
    },
    setIsNewAccountability(state, payload) {
      state.isNewAccountability = payload;
    },
    setTitleAccountability(state, payload) {
      state.titleAccountability = payload;
    },
    setLicenseWithTreatmentAreaName(state, payload) {
      state.licenseWithTreatmentAreaName = payload;
    },
    setTexts(state, payload) {
      state.texts = payload;
    },
    setPdfFileToken(state, payload) {
      state.pdfFileToken = payload;
    },
    setPdf(state, payload) {
      state.pdf = payload;
    },
    resetTreatmentAreaWiseTemplates(state, payload) {
      state.treatmentAreaWiseAccoutabilityTemplates = payload;
    },
    setIsStartDate(state, payload) {
      state.isStartDate = payload;
    },
    setLicenseStopPopUpStatus(state, payload) {
      state.showPopUp = payload;
    },
    setShowExtendLicensesBlock(state, payload) {
      state.showExtendLicensesBlock = payload;
    },
    setExtendLicenseMsg(state, payload) {
      state.ExtendLicenseMsg = payload;
    },
    setStartDateForTwoColumnAccountability(state, payload) {
      state.startDateForTwoColumnAccountabiliy = payload;
    },
    setForwardAccountabilityPopup (state, payload) {
      state.ForwardAccountabilityPopup = payload
    },
    setAccountabilityTexts(state, payload) {
      state.AccountabilityTexts = payload;
    },
    setDeliveryInstruction(state, payload) {
      state.deliveryInstruction = payload;
    },
    setDeliveryDetails(state, payload) {
      state.deliveryDetails = payload;
    },
    setAccountabilityId(state, payload) {
      state.accountabilityId = payload;
    },
    setCalendarEvent(state, payload){
      state.calendarEvent = payload
    },
    setListSearchItems(state, payload){
      state.listSearchItems = payload.data
    }
  },
  actions: {
    fetchAccountabilities({ commit, state }) {
      // to set the button with accountabilities on the patient Dashboard
      return axios.get('Startverantwoording/Init').then((response) => {
        commit('setAccountabilities', response.data.Templates);
      });
    },
    startNewAccountability({ commit, state }, id) {
      return axios.post('StartVerantwoording/Start', { TemplateId: id }).then((response) => {
        if (response.data.productSelection !== null) {
          commit('setIsSingleAccountability', false);
          commit('setProductSelectionId', response.data.productSelection.accountabilityId);
        } else {
          commit('setIsSingleAccountability', true);
          commit('setProductSelectionId', '');
        }

        return response.data;
      });
    },
    setAccountability({ commit, state }, data) {
      // commit('setIsSingleAccountability', true)
      return axios.post('VragensetVerantwoording/Set', { AccountabilityId: data }).then((response) => {
        if (response.data.ProductSelectionAccountabilityId > 0) {
          commit('setIsSingleAccountability', false);
          commit('setProductSelectionId', response.data.ProductSelectionAccountabilityId);
        } else if (response.data.ProductSelectionAccountabilityId === 0) {
          commit('setIsSingleAccountability', true);
        }
      });
    },
    getQuestions ({ commit, state }, accountabilityId) {
      return axios.get('VragensetVerantwoording/Init?accountabilityId=' + accountabilityId)
        .then(response => {
          commit('setQuestions', {})
          commit('setQuestions', response.data.Groepen[0].Vragen)
          commit('setIsNewAccountability', response.data.IsNewAccountability)
          commit('setTitleAccountability', response.data.Name)
          commit('setLicenseWithTreatmentAreaName', response.data.LicenseWithTreatmentAreaName)
          commit('setIsStartDate', response.data.IsStartDate)
          commit('setIsWLZ', response.data.IsWLZ)
          commit('setIsZVZ', response.data.IsZVZ)
          commit('setForwardAccountabilityPopup', response.data.ForwardAccountabilityPopup)
          commit('setAccountabilityTexts', response.data.AccountabilityTexts)
          commit('setDeliveryInstruction', response.data.DeliveryInstruction)
          commit('setDeliveryDetails', response.data.DeliveryDetails)
          commit('setAccountabilityId', response.data.AccountabilityId)
          if (response.data.Groepen[0].Vragen[response.data.Groepen[0].Vragen.length - 1].IsLaatste) {
            commit('setIsLastAnswer', true)
          } else {
            commit('setIsLastAnswer', false)
          }
          if (response.data.IsAllowedToProceed) {
            commit('setIsAllowedToProceed', true)
          } else {
            commit('setIsAllowedToProceed', false)
          }
          if (response.data.IsAccessoriesSelected) {
            commit('setIsAccessoriesSelected', true)
          } else {
            commit('setIsAccessoriesSelected', false)
          }
          if (response.data.Groepen[0].Vragen) {
            var res = response.data.Groepen[0].Vragen;
            res.forEach((question) => {
              if (question.AnswerTypeListSearch) {
                axios
                  .get('VragensetVerantwoording/GetListSearchItems?listSearchId=' + question.AnswerTypeListSearch)
                  .then((response) => {
                    commit('setListSearchItems', response);
                    return;
                  });
              }
            });
          }

        return response.data;
      });
    },
    setWLZId ({ commit, state }, data) {
      return axios.post('VragensetVerantwoording/SetWLZ', data)
    },
    setZVZId({ commit, state }, data) {
      return axios.post('VragensetVerantwoording/SetZVZ', data);
    },
    setAnswer({ commit, state }, data) {
      return axios.post('VragensetVerantwoording/BeantwoordVraag', data).then((response) => response.data);
    },
    submitAndSignAccountabilityForm({ commit, state }, data) {
      return axios.post('License/SignAndCreate', data);
    },
    requestAccountabilityCopy({ commit, state }, props) {
      return axios.post('License/Change', props).then((response) => {
        // when the templateId is no longer valid but when there is a new one found
        if (response.data.FoundNewTemplate) {
          return new Promise((resolve, reject) => {
            this.$emitter.emit('prompt', {
              title: 'Wijzigen of verlengen niet mogelijk',
              message: response.data.PopupText,
              confirmButton: 'Start een nieuwe machtiging',
              resolve,
              reject
            });
          })
            .then(() => {
              return this.dispatch('accountability/requestAccountabilityCopy', {
                AccountabilityId: props.AccountabilityId,
                UseNewTemplate: true
              });
            })
            .catch(() => {
              return Promise.reject('cancel');
            });
        }
        if (response.data.ProductSelectionAccountabilityId > 0) {
          commit('setProductSelectionId', response.data.ProductSelectionAccountabilityId);
          commit('setIsSingleAccountability', false);
        } else if (
          response.data.ProductSelectionAccountabilityId === 0 ||
          !response.data.ProductSelectionAccountabilityId
        ) {
          commit('setProductSelectionId', '');
          commit('setIsSingleAccountability', true);
        }
        commit('setExtendLicenseMsg', response.data.ExtendLicenseMsg);

        return response;
      });
    },
    cancelAccountabilityForm({ commit, state }, accountabilityId) {
      return axios.post('License/CancelAccountability', {
        accountabilityId: accountabilityId / 1
      });
    },
    forwardAccountability({ commit }, data) {
      return axios.post('Accountability/Doorsturen', {
        PpwRecId: data.ppwRecId,
        Comment: data.comment,
        AccountabilityId: data.accountabilityId
      });
    },
    fetchTexts({ commit }) {
      return axios.get('License/GetTexts').then((response) => {
        commit('setTexts', response.data);
      });
    },
    fetchPdfFileToken({ commit }, id) {
      return axios.get('Accountability/GetPdfFileToken', { params: { id } }).then((response) => {
        commit('setPdfFileToken', response.data);
      });
    },
    fetchPdf({ commit }, details) {
      let accountabilityId = details.accountabilityId;
      let externalId = details.external;
      let licenseId = details.licenseId;
      return axios
        .get('Accountability/Pdf?accountabilityId=' + accountabilityId, {
          params: { externalId, licenseId },
          responseType: 'arraybuffer'
        })
        .then((response) => {
          commit('setPdf', response.data);
        });
    },
    saveStandAloneAccountability({ commit }, details) {
      return axios.post('License/CreateAccountability', details);
    },
    saveAccountabilityFiles({ commit }, fileDetails) {
      let formDetails = fileDetails.formData;
      let documentTypeId = 0;
      if (fileDetails.documentType) {
        documentTypeId = fileDetails.documentType;
      }
      let apiCall =
        'Accountability/UploadAccountabilityDocument?accountabilityId=' +
        fileDetails.accountabilityId +
        '&licenseId=' +
        fileDetails.licenseId +
        '&documentType=' +
        fileDetails.documentType;
      return axios.post(apiCall, formDetails, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    deleteAccountabilityFile({ commit }, attachmentId) {
      return axios.post('Accountability/DeleteFile?attachmentId=' + attachmentId);
    },
    fetchAddressLookup({ commit }, { postcode, huisnummer, land }) {
      return axios.get('Address/Lookup', {
        params: {
          Zipcode: postcode,
          Number: huisnummer,
          CountryCode: land
        }
      });
    },
    fetchCalendarDates({ commit }) {
      return axios.get('CalendarEvent/GetCalendarEvent').then((response) => {
        commit('setCalendarEvent', response.data);
        return response.data;
      });
    },
    saveAccountabilityUpload({ commit }, fileDetails) {
      return axios.post('VragensetVerantwoording/FileUpload', fileDetails, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  },
  getters: {
    getQuestions: (state) => {
      return state.questions;
    },
    getIsAllowedToProceed: (state) => {
      return state.isAllowedToProceed;
    },
    getIsAccessoriesSelected: (state) => {
      return state.isAccessoriesSelected;
    },
    getIsLastAnswer: (state) => {
      return state.isLastAnswer;
    },
    getProductSelectionId: (state) => {
      return state.productSelectionId;
    },
    getIsSingleAccountability: (state) => {
      return state.isSingleAccountability;
    },
    getAccountabilities: (state) => {
      return state.accountabilities.map((accountability) => {
        return {
          id: accountability.TemplateId,
          title: accountability.Naam,
          accountabilityColumn: accountability.AccountabilityColumn
        };
      });
    },
    getIsNewAccountability: (state) => {
      return state.isNewAccountability;
    },
    getTitleAccountability: (state) => {
      return state.titleAccountability;
    },
    getLicenseWithTreatmentAreaName: (state) => {
      return state.licenseWithTreatmentAreaName;
    },
    getTexts: (state) => {
      return state.texts;
    },
    getPdfFileToken: (state) => {
      return state.pdfFileToken;
    },
    getPdf: (state) => {
      return state.pdf;
    },
    getTreatmentAreaWiseAccountabilityTemplates: (state) => {
      const templatesByArea = {};

      state.accountabilities.forEach((template) => {
        const area = template.TreatmentAreaDescription;
        if (!templatesByArea[area]) {
          templatesByArea[area] = [];
        }
        templatesByArea[area].push(template);
      });

      // Convert object to array of objects and sort
      const sortedTemplates = Object.entries(templatesByArea)
        .map(([area, templates]) => ({
          TreatmentAreaDescription: area,
          Templates: templates.sort((a, b) => a.Naam.localeCompare(b.Naam))
        }))
        .sort((a, b) => a.TreatmentAreaDescription.localeCompare(b.TreatmentAreaDescription));

      return sortedTemplates;
    },
    getIsStartDate: (state) => {
      return state.isStartDate;
    },
    getIsWLZ: (state) => {
      return state.isWLZ;
    },
    getIsZVZ: (state) => {
      return state.isZVZ;
    },
    getLicenseStopPopUpStatus: state => {
      return state.showPopUp
    },
    getShowExtendLicensesBlock: (state) => {
      return state.showExtendLicensesBlock;
    },
    getExtendLicenseMsg: (state) => {
      return state.ExtendLicenseMsg;
    },
    getStartDateForTwoColumnAccountability: (state) => {
      return state.startDateForTwoColumnAccountabiliy;
    },
    getForwardAccountabilityPopup: (state) => {
      return state.ForwardAccountabilityPopup;
    },
    getAccountabilityTexts: (state) => {
      return state.AccountabilityTexts;
    },
    // Get label for the delivery instruction
    getDeliveryInstruction: (state) => {
      return state.deliveryInstruction;
    },
    getDeliveryDetails: (state) => {
      return state.deliveryDetails;
    },
    getAccountabilityId: (state) => {
      return state.accountabilityId;
    },
    getCalendarEvent: state => {
      return state.calendarEvent
    },
    getListSearchItems: state => {
      return state.listSearchItems
    }
  }
}
