<template>
  <span class="dropdown">
    <form class="search-input">
      <input
        id="search"
        v-model="input"
        maxlength="160"
        :placeholder="placeholder"
        type="search"
        autocomplete="off"
        spellcheck="false"
        @keypress="deselection"
      />
      <div class="button">
        <transition name="fade">
          <span v-show="selected"><v-check-icon class="check-icon" /></span>
        </transition>
        <transition name="fade">
          <span v-show="!selected && input.length === 0"><v-search-icon class="search-icon" /></span>
        </transition>
        <transition name="fade">
          <span v-show="!selected && input.length > 1">
            <v-clear-icon class="search-icon" @click.prevent="clearInput()" />
          </span>
        </transition>
      </div>
    </form>
    <transition name="fade">
      <label v-if="input.length > trigger && !selected">
        <ul>
          <!-- Primary Options -->
          <div v-if="resultList">
            <li v-show="suggestHeader" class="suggestion-header">{{ suggestHeader }}</li>
            <li v-for="(item, index) in resultList" :key="index" class="suggestion-item" @click="selection(item)">
              <div v-html="highlightText(item.title)" />
            </li>
          </div>

          <!-- Divider-->
          <div v-if="resultList && additionalResultList">
            <li class="divider" />
          </div>

          <!-- Additional Options -->
          <div v-if="additionalResultList">
            <li v-show="suggestAdditionalHeader" class="suggestion-header">{{ suggestAdditionalHeader }}</li>
            <li
              v-for="(item, index) in additionalResultList"
              :key="index"
              class="suggestion-item"
              @click="selection(item)"
            >
              <div v-html="highlightText(item.title)" />
            </li>
          </div>
        </ul>
      </label>
    </transition>
  </span>
</template>

<script>
import VSearchIcon from 'icons/Magnify';
import VCheckIcon from 'icons/Check';
import VClearIcon from 'icons/Close';

export default {
  name: 'SuggestDropdown',
  components: {
    VSearchIcon,
    VCheckIcon,
    VClearIcon
  },
  props: {
    trigger: {
      required: false,
      type: Number,
      default: 2
    },
    options: {
      required: true,
      type: Array,
      default: () => []
    },
    suggestHeader: {
      required: false,
      type: String
    },
    additionalOptions: {
      required: false,
      type: Array,
      default: () => []
    },
    suggestAdditionalHeader: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Zoek'
    }
  },
  data() {
    return {
      input: '',
      selected: false,
      results: [],
      additionalResults: []
    };
  },
  computed: {
    resultList() {
      return this.filteredList(this.results);
    },
    additionalResultList() {
      return this.filteredList(this.additionalResults);
    }
  },
  watch: {
    additionalOptions(value) {
      this.additionalResults = value;
    }
  },
  mounted() {
    // initialize arrays
    this.results = this.options;
    this.additionalResults = this.additionalOptions;
  },
  methods: {
    highlightText(item) {
      let pattern = new RegExp(this.input, 'i');
      let term = item.match(pattern);
      return item.replace(term, '<b>' + term + '</b>');
    },
    filteredList(result) {
      if (result) {
        return result.filter((option) => {
          return option.title.toLowerCase().includes(this.input.toLowerCase());
        });
      }
      return result;
    },
    selection(option) {
      this.selected = true;
      this.$emit('selectOption', option);
      this.input = option.title;
    },
    deselection() {
      this.selected = false;
      this.$emit('selectOption', null);
    },
    clearInput() {
      this.input = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 100%;
}
.dropdown:hover {
  cursor: pointer;
}
.dropdown > a {
  font-size: 14px;
  background-color: $white;
  border: 1px solid $gray-lightest;
  padding: 6px 20px 6px 10px;
  border-radius: 4px;
  display: inline-block;
  color: $black;
  text-decoration: none;
}
.dropdown > a:before {
  position: absolute;
  right: 7px;
  top: 12px;
  content: ' ';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $black;
}
.dropdown ul {
  top: 28px;
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  left: 0px;
  width: 100%;
  list-style: none;
  padding: 4px 0px;
  display: block;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
}
.dropdown ul li {
  display: block;
  padding: 6px 20px;
  white-space: nowrap;
  min-width: 100px;
}
.dropdown ul .suggestion-item:hover {
  background-color: $gray-lightest;
  cursor: pointer;
}
.dropdown ul li a {
  text-decoration: none;
  display: block;
  color: $black;
}
.dropdown .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: $teal;
  font-size: 1px;
  padding: 0;
}
.dropdown .suggestion-header {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $teal-dark;
}
.search-input {
  position: relative;
  input {
    display: block;
    width: 100%;
    appearance: none;
    border: 1px solid $gray;
    border-radius: 3px;
    outline: 0;
    padding: 0.75rem 3rem 0.75rem 0.9375rem;
    &:focus {
      border-color: $gray-dark;
      color: $black;
    }
  }
  .button {
    position: absolute;
    top: 5px;
    right: -10px;
    bottom: 0;
    width: 3rem;
    appearance: none;
    border: 0;
    padding: 0;
    border-radius: 0 3px 3px 0;
    background: 0 0;
    outline: 0;
    .search-icon {
      fill: $gray-dark;
      margin-top: 3px;
    }
    .check-icon {
      fill: $teal-dark;
      margin-top: 3px;
    }
  }
}
.material-design-icon__svg {
  margin-left: 12px !important;
  margin-top: 5px !important;
}
</style>
