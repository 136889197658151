<template>
  <section class="container pumpOverview">
    <h1>Pompenoverzicht</h1>
    <div>
      <pump-search @reset-filters="computeOverview" @search-pump="searchPump" />
      <v-table>
        <thead>
          <v-header-row
            :data="pumpsHeader"
            :show-sort="true"
            :page="'Pompenoverzicht'"
            @sort-by-field="sortPumpsByField"
          />
        </thead>
        <v-pump-overview-row
          v-for="(row, index) in updatedPumps"
          :key="index"
          :data="row"
          :index="index"
          :user-warehouse-id="userWarehouseId"
          @retrieve="retrievePump"
          @reload-overview="reloadOverview"
        />
      </v-table>
    </div>
    <div class="pumpOverview__reportAbnormality__link">
      <span v-if="overviewPumps.cmsTexts" @click.prevent="toggleReportAbnormality">{{
        overviewPumps.cmsTexts.reportAbnormalityLinkText
      }}</span>
    </div>
    <div v-show="showReportAbnormality">
      <v-table>
        <tr>
          <th />
        </tr>
        <tr>
          <th v-if="overviewPumps.cmsTexts" class="pumpOverview__reportAbnormality__text">
            {{ overviewPumps.cmsTexts.reportAbnormalityExplanationText }}
          </th>
        </tr>
        <tr>
          <td>
            <div class="col-xs-8 pumpOverview__selectPump">
              <v-select-group
                v-model="currentPumpId"
                selectid="PumpId"
                :initselected="'0'"
                placeholder="Selecteer pomp"
              >
                <option
                  v-for="(item, index) in overviewPumps.allPumps"
                  :key="index + 1"
                  :data-index="index + 1"
                  :value="item.equipmentID"
                >
                  {{ item.pumpDesc }} {{ item.serialNo }}
                </option>
              </v-select-group>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <textarea
              id="pumpOverview-ReportAbnormality"
              v-model="abnormalityRemark"
              class="pumpOverview__textarea"
              placeholder="Typ hier uw bericht…"
              :overwriteValue="abnormalityRemark"
              @input="setRemarks($event.target.value)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-button
              tagelement="button"
              class="btn btn--secondary btn--lq pumpOverview__AbnormalityButton"
              :disabled="abnormalityRemark == ''"
              @click="reportAbnormality()"
            >
              Versturen
            </v-button>
          </td>
        </tr>
      </v-table>
    </div>
    <v-overlay v-if="retrieveOverlay && Object.keys(selectedPumpToRetrieve).length !== 0">
      <v-pump-retrieve-form
        :data="selectedPumpToRetrieve"
        @success="closeRetrieveOverlay"
        @close-modal="closeRetrieveOverlay"
      />
    </v-overlay>
  </section>
</template>

<script>
import axios from 'axios';
import VPumpOverviewRow from './PumpOverviewRow';
import VTable from '@/components/shared/table/Table';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VPumpRetrieveForm from './PumpRetrieveForm';
import PumpSearch from './PumpSearch';

import { currentUserIdentity$ } from '@/services';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'PumpOverview',
  components: {
    VPumpOverviewRow,
    VTable,
    VHeaderRow,
    VPumpRetrieveForm,
    PumpSearch
  },
  setup() {
    const store = useStore();
    const user = ref(null);

    const fetchResults = () => store.dispatch('navigation/fetchResults');
    currentUserIdentity$.subscribe((value) => (user.value = value));

    return {
      fetchResults,
      user
    };
  },
  data() {
    return {
      pumpsHeader: ['', 'Omschrijving', 'Serienummer', 'Locatie', 'Onderhoud nodig in', ''],
      pumpsHeadersForSorting: {
        Omschrijving: 'pumpDesc',
        Serienummer: 'serialNo',
        Locatie: 'equipmentStatus',
        'Onderhoud nodig in': 'daysLeftForMaintenance'
      },
      selectedPumpToRetrieve: {},
      retrieveOverlay: false,
      isExpanded: false,
      selected: null,
      currentPumpId: null,
      abnormalityRemark: '',
      showReportAbnormality: false,
      updatedPumps: [],
      overviewPumps: {
        cmsTexts: {}
      },
      currentPumpData: {},
      userWarehouseId: ''
    };
  },
  mounted() {
    this.fetchPumps();
  },
  methods: {
    searchPump(searchDetails) {
      this.computeOverview();
      if (
        (!searchDetails.searchText || (searchDetails && searchDetails.searchText.length < 2)) &&
        searchDetails.status === 'Toon Alles'
      ) {
        return;
      }
      if (searchDetails.searchText && searchDetails.searchText.length > 2) {
        const lowerCaseSearchText = searchDetails.searchText.toLowerCase();
        this.updatedPumps = this.updatedPumps.filter((l) => {
          return (
            l.pumpDesc.toLowerCase().includes(lowerCaseSearchText) ||
            (l.serialNo !== null && l.serialNo.toLowerCase().includes(lowerCaseSearchText))
          );
        });
      }
      if (searchDetails.status && searchDetails.status !== 'Toon Alles') {
        this.updatedPumps = this.updatedPumps.filter((l) => {
          if (searchDetails.status === 'Available') {
            return l.equipmentStatus === searchDetails.status || l.equipmentStatus === 'Lend';
          }
          if (searchDetails.status === 'Allocated') {
            return l.equipmentStatus === searchDetails.status || l.equipmentStatus === 'LendAndAssigned';
          }
          return l.equipmentStatus === searchDetails.status;
        });
      }
    },
    retrievePump(pumpData) {
      this.selectedPumpToRetrieve = this.getSpecificPump(pumpData);
      this.currentPumpData = pumpData;
      this.showRetrieveOverlay();
    },
    fetchPumps() {
      this.fetchResults().then(() => {
        this.fetchOverviewPumps().then(() => {
          this.computeOverview();
        });
      });
    },
    computeOverview() {
      this.updatedPumps = [];
      const initialStatusSorting = { Available: 1, Allocated: 2, 'Naar Mediq': 3, default: Infinity };
      this.updatedPumps = this.overviewPumps.allPumps.map((x) =>
        x.equipmentStatus === 'RequestMaintenance' ||
        x.equipmentStatus === 'RequestRepair' ||
        x.equipmentStatus === 'Pickup'
          ? { ...x, equipmentStatus: 'Naar Mediq' }
          : x
      );
      this.updatedPumps.sort(function (a, b) {
        return (
          (initialStatusSorting[a.equipmentStatus] || initialStatusSorting.default) -
            (initialStatusSorting[b.equipmentStatus] || initialStatusSorting.default) ||
          a.equipmentStatus.localeCompare(b.equipmentStatus) ||
          a.pumpDesc.localeCompare(b.pumpDesc)
        );
      });
    },
    showRetrieveOverlay() {
      this.retrieveOverlay = true;
    },
    closeRetrieveOverlay() {
      this.selectedPumpToRetrieve = {};
      this.retrieveOverlay = false;
      this.fetchOverviewPumps(this.currentPumpData);
      this.$router.go();
    },
    sortPumpsByField(sortFieldDetails) {
      let key = sortFieldDetails.option;
      let value = this.pumpsHeadersForSorting[key];
      this.updatedPumps.sort(function (a, b) {
        const leftValue = a[value];
        const rightValue = b[value];
        if (!leftValue) {
          return 1;
        }
        if (!rightValue) {
          return -1;
        }
        if (sortFieldDetails.isAscending) {
          if (typeof leftValue === 'number' && typeof rightValue === 'number') {
            return leftValue < rightValue ? -1 : 1;
          } else {
            return leftValue.localeCompare(rightValue);
          }
        } else {
          if (typeof leftValue === 'number' && typeof rightValue === 'number') {
            return leftValue > rightValue ? -1 : 1;
          } else {
            return rightValue.localeCompare(leftValue);
          }
        }
      });
    },
    setRemarks(remarks) {
      this.abnormalityRemark = remarks;
    },
    reportAbnormality() {
      let requiredFields = {
        currentPumpId: this.currentPumpId,
        abnormalityRemark: this.abnormalityRemark
      };
      axios.post('Equipment/EmailReportAbnormality', requiredFields).then((response) => {
        this.showAlert();
        this.abnormalityRemark = '';
        this.currentPumpId = '0';
      });
    },
    toggleReportAbnormality() {
      this.showReportAbnormality = !this.showReportAbnormality;
    },
    showAlert() {
      this.$emitter.emit('alert', {
        message: 'Uw bericht is verzonden en wordt zo snel mogelijk opgepakt door Mediq.'
      });
    },
    fetchOverviewPumps() {
      return axios.get('Equipment/GetPumpOverview').then(
        (response) => {
          this.overviewPumps = response.data;
          this.userWarehouseId = response.data.userWarehouseId;
        },
        (error) => {
          return error.response;
        }
      );
    },
    getSpecificPump(pumpData) {
      return this.overviewPumps.allPumps.find((pump) => pump.equipmentID === pumpData.id);
    },
    reloadOverview() {
      this.fetchOverviewPumps();
    }
  }
};
</script>
<style lang="css">
.pumpOverview .overlay > div {
  max-height: 720px;
}
.pumpOverview .pumpOverview__selectPump .form-group label {
  padding-left: 10px;
  margin-top: -8px;
}
.pumpOverview .pumpOverview__sselectPump .form-group--hasvalue label {
  display: none;
}
.pumpOverview .form-group .form-group__details:before {
  background: none;
}
.pumpOverview .form-group .form-group__details:after {
  background: none;
}
.pumpOverview .form-group {
  padding: 0px;
}
.pumpOverview .form-group__icon {
  margin: -5px 10px 0px 0px;
}
</style>
<style lang="scss" scoped>
.pumpOverview {
  h1 {
    color: $teal-darkest;
    font-size: type-scale(4);
    font-weight: 300;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    background: $white;
    margin-bottom: 1.8rem;
    th,
    td {
      vertical-align: baseline;
      text-align: left;
      padding: 12px 20px;
      font-weight: normal;
    }
    a {
      color: $teal-darkest;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    thead {
      padding-bottom: 2px; // TODO: 2px margin-bottom
      td,
      th {
        background-color: $teal-lighter;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    tbody {
      tr {
        border: 1px solid $gray-light;
      }
      th,
      td {
        padding-top: 17px;
        padding-bottom: 32px;
      }
    }
  }
  &__reportAbnormality {
    &__link {
      color: $teal-darkest;
      text-decoration: underline;
      margin-bottom: 25px;
      cursor: pointer;
    }
    &__text {
      color: $gray-darkest;
    }
  }
  &__textarea {
    padding: 0px;
    height: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid $gray;
    width: 100%;
    color: $gray-darkest;
    min-height: 200px;
    padding: 10px;
    resize: none;
  }
  &__AbnormalityButton {
    float: right;
    width: auto;
  }
}
</style>
