import { activePpwRecId$ } from '@/services/activePpwRecId$';
import { fetchQuery } from '@/services/messaging/fetchQuery';
import { publishBehavior, refCount, switchMap } from 'rxjs/operators';

export const ccpSsoDetails$ = activePpwRecId$
.pipe(
  switchMap(activePpwRecId => fetchQuery("GetSSODetails", {})),
  publishBehavior(null),
  refCount()
);
