<template>
  <div>
    <div
      class="form-group-simple input"
      sm=""
    >
      <input
        id="search"
        v-model="search"
        class="input"
        type="text"
        placeholder="Zoek naar producten"
      >
    </div>
    <div class="products">
      <v-empty-message v-if="data.length < 1">
        Geen producten
      </v-empty-message>
      <transition-group
        v-if="displayProducts"
        class="products__list"
        tag="ul"
        name="transition-down-up"
      >
        <v-product
          v-for="product in filteredProductList"
          :key="product.ProductId"
          :data="product"
          :alt="product.Smaakbox"
        >
          <template v-slot:actions>
            <v-button
              slot="actions"
              secondary
              class="products__add"
              @click.prevent="moveProduct(product.ProductId, 'products', 'productsSelected')"
            >
              <v-plus-icon />
            </v-button>
          </template>
        </v-product>
      </transition-group>
    </div>
  </div>
</template>

<script>
import VPlusIcon from 'icons/Plus'
import VProduct from './Product'
import VEmptyMessage from './EmptyMessage'
import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'VProducts',
  components: {
    VProduct,
    VPlusIcon,
    VEmptyMessage
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    productSelectionAccountabilityId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const state = store.state;
    const search = ref('');

    const moveProductStore = (value) => store.dispatch('selectProduct/moveProduct', value);
    const postProductsSelected = (value) => store.dispatch('selectProduct/postProductsSelected', value);

    return {
      state,
      search,
      moveProductStore,
      postProductsSelected
    }
  },
  computed: {
    displayProducts () {
      let questions = this.state.selectProduct.filters

      let isShow = questions.length > 0 && questions[questions.length - 1].IsBeantwoord
      if (this.search.length > 2) {
        return true;
      } else if ((isShow || this.state.selectProduct.defaultSearch) && this.search.length === 0 && questions.length !== 1) {
        return true;
      } else if (isShow && this.search.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    filteredProductList () {
      const productSet = this.data;

      var term = this.search.toLowerCase();
      return productSet.filter(el => el.Name.toLowerCase().indexOf(term) >= 0);
    }
  },
  created () {
    this.dsearch = this.state.selectProduct.defaultSearch
  },
  methods: {
    /**
     * @function moveProduct
     * @param {number} productId - productId of the product that needs to be moved
     * @param {string} from - Array in state from which the product to be moved
     * @param {string} to - Array in state where the product needs to be moved to
     */
    moveProduct (productId, from, to) {
      this.moveProductStore({
        productId: productId,
        from: from,
        to: to
      })
      this.postProductsSelected(this.productSelectionAccountabilityId)
    },
    filter (productSet) {
      let questions = this.state.selectProduct.filters
      let isShow = questions[questions.length - 1].IsBeantwoord

      if (this.search.length > 2) {
        this.dsearch = true
      } else if ((isShow || this.state.selectProduct.defaultSearch) && this.search.length === 0 && questions.length !== 1) {
        this.dsearch = true
      } else if (isShow && this.search.length === 0) {
        this.dsearch = true
      } else {
        this.dsearch = false
      }

      if (this.search) {
        var term = this.search.toLowerCase()
        productSet = productSet.filter(function (el) {
          return el.Name.toLowerCase().indexOf(term) >= 0
        })
      }
      return productSet
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  min-height: 100px;
  margin: 15px 0px 15px 0px;
  
  &__list {
    margin: 0;
    padding: 0;
  }

  & .product {
    margin-bottom: 5px;
    padding-right: 20px;
    padding-left: 20px;
    overflow: hidden;
  }

  &__add {
    padding: 4px;
  }
}
</style>
