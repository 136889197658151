<template>
  <button
    class="token"
    @click="$emit('remove')"
  >
    {{ name }}<v-close class="token__icon" />
  </button>
</template>

<script>
import VClose from 'icons/Close'

export default {
  name: 'VToken',
  components: {
    VClose
  },
  props: {
    name: String
  }
}
</script>

<style lang="scss" scoped>
.token {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $gray-darkest;
  padding: 5px 4px 5px 6px;
  margin: 6px 12px 6px 0px;
  border-radius: 8px;
  border: solid 1px $teal-darkest;
  background: transparent;
  white-space: nowrap;

  &__icon {
    fill: $teal-darkest;
    display: inline-flex;
    width: 1em;
    margin-left: 2px;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
