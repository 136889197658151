import axios from 'axios'
export default {
  namespaced: true,
  state: {
    feedbackConfiguration: {}
  },
  mutations: {
    setFeedbackConfiguration (state, payload) {
      state.feedbackConfiguration = payload
    }
  },
  actions: {
    getFeedbackConfiguration ({ commit }) {
      return axios.get('Feedback/GetFeedbackConfiguration').then((response) => {
        commit('setFeedbackConfiguration', response.data.FeedbackConfiguration)
      })
    },
    saveFeedback ({ commit }, feedbackInput) {
      return axios.post('Feedback/SaveFeedback', feedbackInput)
    }
  },
  getters: {
    getFeedbackConfiguration: state => {
      return state.feedbackConfiguration
    }
  }
}
