<template>
  <div class="patientTransfer">
    <v-button
      v-if="customerSecurityPolicySettings && !transferredTo && transferredFrom.length === 0"
      class="patientTransferBtn"
      tagelement="button"
      md
      secondary
      icon-left
      hollow
      @click="showOverlay"
    >
      <patient-icon />
      Patiënt overdragen
    </v-button>
    <v-overlay-large v-if="patientTransferOverlayActive" :title="patientTransferPopUpTexts.Title" @close="closeOverlay">
      <patient-transfer-form @success="closeOverlay" />
    </v-overlay-large>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import patientIcon from 'icons/AccountConvert';
import VOverlayLarge from '@/components/shared/OverlayLarge.vue';
import PatientTransferForm from './PatientTransferForm.vue';
import { customerSecurityPolicySettings$ } from '@/services';

export default {
  name: 'PatientTransferButton',
  components: {
    patientIcon,
    VOverlayLarge,
    PatientTransferForm
  },
  props: {
    showTransferButton: {
      type: Boolean,
      required: true
    },
    changeUserForPatientTransfer: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const customerSecurityPolicySettings = ref(null);

    const patientTransferPopUpTexts = computed(() => store.getters['patienttransfer/getPatientTransferPopUpTexts']);

    customerSecurityPolicySettings$.subscribe((value) => (customerSecurityPolicySettings.value = value));

    store.dispatch('patienttransfer/fetchPatientTransferPopUpTexts');

    return {
      patientTransferPopUpTexts,
      customerSecurityPolicySettings
    };
  },
  data() {
    return {
      patientTransferOverlayActive: false
    };
  },
  computed: {
    transferredTo() {
      return (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.transferredTo) || null;
    },
    transferredFrom() {
      return (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.transferredFrom) || [];
    }
  },
  watch: {
    changeUserForPatientTransfer(value) {
      if (value) {
        this.showOverlay();
      }
    }
  },
  methods: {
    showOverlay() {
      this.patientTransferOverlayActive = true;
    },
    closeOverlay() {
      this.patientTransferOverlayActive = false;
      this.$emit('close');
    }
  }
};
</script>
