<template>
    <section class="container closed-Department">
      <div class="row grid-margin grid-align-center">
        <div class="col-xs-12 col-md-2 col-lg-4" />
        <div class="col-xs-12 col-md-8 col-lg-4">
          <img
            src="@/static/img/closed-department.svg"
            alt="closed Department"
          >
          <v-warning-message
            warning="De afdeling waarmee u verbonden bent, is gesloten en niet langer beschikbaar"
            message="Neem contact op met het klantcontact via het <b><i><u><a href=https://mediq.nl/contact/zorgprofessional>contactformulier</a></u></i></b> voor hulp bij het opnieuw toewijzen van uw profiel aan een actieve afdeling."
            :html="true"
          />
        </div>
        <div class="col-xs-12 col-md-2 col-lg-4" />
      </div>
      
      <div class="row grid-justify-content-center">
        <v-button
          tagelement="button"
          md
          primary
          @click="goToLogin()"
        >
          Terug naar login
        </v-button>
      </div>
    </section>
  </template>
  <script>
  import router from '@/router'
  import VWarningMessage from '@/components/shared/WarningMessage'
  import { formLogout } from '@/services';
  export default {
    name: 'VClosedDepartment',
    components: {
      VWarningMessage,
    },
    props: {},
    methods: {
       /**
       * @function goToLogin - Fuction to redirect prescriber to Login page
       */
      goToLogin () {
        formLogout().then((r) => {
          window.location.replace('/?' + Math.random());
        });
      }
    }
  }
  </script>
  <style lang="scss">

  .closed-Department {
    position: absolute;
    background-color: $teal-lightest;
    color: $gray-darkest;
  }
</style>
  