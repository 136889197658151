import { combineLatest } from 'rxjs';
import { availableDepartments$ } from './availableDepartments$';
import { storedActiveDepartment$ } from './storedActiveDepartment$';
import { distinctUntilChanged, refCount, publishReplay, map, switchMap } from 'rxjs/operators';
import store from '@/store';

export const activeDepartment$ = combineLatest([availableDepartments$, storedActiveDepartment$])
  .pipe(
    map(data => {
      const availableDepartments = data[0];
      const storedActiveDepartment = data[1];

      if(!availableDepartments || availableDepartments.length === 0) {
        return null;
      }

      if (!storedActiveDepartment) {
        return availableDepartments[0];
      }

      var validIndexOf = availableDepartments.map(d => d.ppwRecId).indexOf(storedActiveDepartment);
      if (validIndexOf >= 0) {
        return availableDepartments[validIndexOf];
      }

      return availableDepartments[0];
    }),
    distinctUntilChanged((a, b) => {
      if (a === b) return true;
      if (a && b && a.ppwRecId === b.ppwRecId) return true;
      return false;
    }),
    switchMap(department => {
      // Navigation.vue currently is the glue between frontend and backend.
      // Backend server might not know anything about current selected department. To ensure it does we force it with this store call.
      if (department) {
        return store.dispatch('login/selectedDepartmentChange', department.ppwRecId).then(result => department);
      }

      return Promise.resolve(null);
    }),
    publishReplay(1),
    refCount()
  );
