<template>
  <div class="col-md-12 progressSteps">
    <div class="progressSteps__root">
      <div class="progressSteps__container">
        <ul class="progressSteps__progressbar">
          <li
            v-for="(step, index) in steps"
            :key="index"
            :class="{'active': step.active}"
          >
            <div class="progressSteps__title">
              {{ step.title }}
            </div>
            <div class="progressSteps__description">
              {{ step.description }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressSteps',
  props: {
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.progressSteps {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  width: 100%;
  color: $teal-darkest;
  &__container {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 20px;
  }
  &__progressbar {
    counter-reset: step;
    list-style-type: none;
    li {
      float: left;
      width: 16%;
      position: relative;
      text-align: center;
    }
    li:before {
      content: '';
      counter-increment: step;
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      line-height: 27px;
      background: $white;
      text-align: center;
      font-weight: bold;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    li:after {
      content: '';
      position: absolute;
      width:100%;
      height: 3px;
      background: $white;
      top: 15px;
      left: -50%;
      z-index: -1;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    li.active:before {
      background: $teal-darkest;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    li.active:after {
      background: $teal-darkest;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    li:first-child:after {
      content: none;
    }
  }
  &__title {
    font-weight: $heading-font-weight;
  }
  @media screen and (max-width: 680px) {
    &__description {
      display: none;
    }
  }
}
</style>
