<template>
  <div class="form-group-simple">
    <div class="uploadBox">
      <form role="form" enctype="multipart/form-data">
        <div v-if="!itemsAdded" class="uploadBoxMain">
          <div class="form-group">
            <div
              class="dropArea"
              :class="dragging ? 'dropAreaDragging' : ''"
              @ondragover="onChange"
              @dragenter="dragging = true"
              @dragend="dragging = false"
              @dragleave="dragging = false"
            >
              <v-cloud />
              <div class="primaryMessage">
                {{ dropAreaPrimaryMessage }}
              </div>
              <input :id="id" type="file" :name="name" required multiple @change="onChange" />
              <div class="secondaryMessage">
                {{ dropAreaSecondaryMessage }}
              </div>
              <div class="orMessage">of</div>
              <button class="btn btn--primary btn--md">Bladeren</button>
            </div>
          </div>
        </div>
        <div v-else class="uploadBoxMain">
          <div class="fileDetails">
            <strong>{{ fileNameMessage }}</strong>
          </div>
          <ol>
            <li v-for="itemsName in itemsNames" :key="itemsName">
              {{ itemsName }}
            </li>
          </ol>
          <div v-if="isLoaderVisible" class="loader">
            <div class="loaderImg" />
          </div>
        </div>
        <div v-if="successMsg !== ''" class="successMsg">
          {{ successMsg }}
        </div>
        <div v-if="errorMsg !== ''" class="errorMsg">
          {{ fileUploadErrorMessage }}:<br />{{ errorMsg }} <br />{{ retryErrorMessage }}
        </div>
        <div v-if="itemsAdded && itemsAdded < minItems" class="errorMsg">
          {{ minFilesErrorMessage }}: {{ minItems }}. <br />{{ retryErrorMessage }}
        </div>
        <div v-if="itemsAdded && itemsAdded > maxItems" class="errorMsg">
          {{ maxFilesErrorMessage }}: {{ maxItems }}. <br />{{ retryErrorMessage }}
        </div>
        <div v-if="validationError || isIncorrectPdf" class="errorMsg">
          {{ extensionErrorMessage }}
        </div>
        <div v-if="sizeError" class="errorMsg">
          {{ sizeErrorMessage }}
        </div>
        <div v-if="fileAlreadyExistsError" class="errorMsg">
          {{ fileExistsMessage }}
        </div>
        <div v-if="specialCharectersError" class="errorMsg">
          {{ specialCharectersErrorMessage }}
        </div>
        <div class="row grid-justify-content-right uploadButtons">
          <button
            type="button"
            class="btn btn--hollow btn--md"
            :class="{ 'btn--disabled': !itemsAdded }"
            @click="removeItems"
          >
            {{ cancelButtonMessage }}
          </button>
          <button
            type="button"
            class="btn btn--primary btn--md"
            :class="{
              'btn--disabled':
                itemsAdded < minItems ||
                itemsAdded > maxItems ||
                validationError ||
                sizeError ||
                fileAlreadyExistsError ||
                specialCharectersError
            }"
            @click="onSubmit"
          >
            {{ uploadButtonMessage }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VCloud from 'icons/CloudUpload';
import store from '@/store';

export default {
  name: 'InputFileUpload',
  components: {
    VCloud
  },
  props: {
    minItems: {
      type: Number,
      default: 1
    },
    maxItems: {
      type: Number,
      default: 100
    },
    dropAreaPrimaryMessage: {
      type: String,
      default: 'Sleep hier uw bestand'
    },
    dropAreaSecondaryMessage: {
      type: String,
      default: '(max. 2MB; alleen .pdf)'
    },
    fileNameMessage: {
      type: String,
      default: 'Geüploade bestanden'
    },
    uploadButtonMessage: {
      type: String,
      default: 'Uploaden'
    },
    cancelButtonMessage: {
      type: String,
      default: 'Annuleren'
    },
    fileUploadErrorMessage: {
      type: String,
      default: 'An error has occurred'
    },
    minFilesErrorMessage: {
      type: String,
      default: 'Minimum files that need to be added to uploader'
    },
    maxFilesErrorMessage: {
      type: String,
      default: 'Het maximaal aantal te uploaden documenten is'
    },
    retryErrorMessage: {
      type: String,
      default: 'Verwijder de documenten door op annuleren te drukken en het opnieuw te proberen.'
    },
    extensionErrorMessage: {
      type: String,
      default: 'Het geüploade document heeft niet de juiste extensie. U kunt alleen .pdf documenten uploaden.'
    },
    sizeErrorMessage: {
      type: String,
      default: 'Het geüploade document is te groot. Het document mag niet groter zijn dan 2MB.'
    },
    specialCharectersErrorMessage: {
      type: String,
      default:
        "Het is niet toegestaan om speciale tekens (bijvoorbeeld:  é,è,ë,ê,ç,@,&,%,',”) te gebruiken in de bestandsnaam. De volgende tekens zijn wel toegestaan: ‘.' ‘,’ ‘-’ ‘_’ ‘(’ ')’. Pas de bestandsnaam aan en upload opnieuw."
    },
    allowedExtenstions: {
      type: Array,
      default: () => ['pdf']
    },
    fileExistsMessage: {
      type: String,
      default: 'Bestandsnaam met een zelfde titel is all geüpload. Past de titel aan of upload een ander document.'
    },
    uploadedFilesData: {
      type: Array,
      default: () => []
    },
    registration: {
      type: Boolean,
      default: false
    },
    saveImagePreview: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'items'
    },
    name: {
      type: Number,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isIncorrectPdf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragging: false,
      items: [],
      itemsAdded: '',
      itemsNames: [],
      itemsSizes: [],
      formData: '',
      successMsg: '',
      errorMsg: '',
      isLoaderVisible: false,
      validationError: false,
      specialCharectersError: false,
      sizeError: false,
      fileAlreadyExistsError: false,
      maximumFileSize: 2,
      imageMaximumSize: 5,
      imageExtensions: ['jpg', 'png', 'jpeg']
    };
  },
  methods: {
    // http://scratch99.com/web-development/javascript/convert-bytes-to-mb-kb/
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i === 0) return bytes + ' ' + sizes[i];
      if (parseInt(bytes / Math.pow(1024, i)) >= this.maximumFileSize && sizes[i] === 'MB') {
        this.sizeErrorMessage =
          'Het geüploade document is te groot. Het document mag niet groter zijn dan ' + this.maximumFileSize + 'MB.';
        this.sizeError = true;
      }
      return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    },
    onChange(e) {
      this.successMsg = '';
      this.errorMsg = '';
      this.formData = new FormData();
      let files = e.target.files || e.dataTransfer.files;
      this.itemsAdded = files.length;
      if (this.saveImagePreview) {
        store.commit('prescriberRegistration/setUploadedImagePreview', {
          pdf: files[0].name.split('.').pop().toLowerCase() === 'pdf',
          image: window.URL.createObjectURL(files[0])
        });
      }
      for (let x in files) {
        if (!isNaN(x)) {
          if (this.multiple) {
            let uploadedFile = e.target.files[x] || e.dataTransfer.files[x];
            this.items.push(uploadedFile);
          } else {
            this.items = e.target.files[x] || e.dataTransfer.files[x];
          }
          this.itemsNames[x] = files[x].name;
          // will be removed once pdfs have max 5mb size.
          if (this.imageExtensions.includes(files[x].name.split('.').pop().toLowerCase())) {
            this.maximumFileSize = this.imageMaximumSize;
          } else {
            this.maximumFileSize = 2;
          }
          if (!this.allowedExtenstions.includes(files[x].name.split('.').pop().toLowerCase())) {
            this.validationError = true;
            break;
          }
          let specialCharecterRegex = /[`À-ȕ!@#$%^&*+\=\[\]{};':"\\|<>\/?~]/;
          if (specialCharecterRegex.test(files[x].name.split('.')[0])) {
            this.specialCharectersError = true;
            break;
          }
          for (let i = 0; i < this.uploadedFilesData.length; i++) {
            if (
              files[x].name === this.uploadedFilesData[i].title ||
              files[x].name === this.uploadedFilesData[i].Title
            ) {
              this.fileAlreadyExistsError = true;
              break;
            }
          }
          this.itemsSizes[x] = this.bytesToSize(files[x].size);
          if (this.registration) {
            this.$emit('saveFileName', this.items.name);
          }
        }
      }
      if (this.multiple) {
        for (let y in this.items) {
          this.formData.append('files', this.items[y]);
        }
      } else {
        this.formData.append('files', this.items);
      }
    },
    removeItems() {
      this.items = [];
      this.itemsAdded = '';
      this.itemsNames = [];
      this.itemsSizes = [];
      this.dragging = false;
      this.validationError = false;
      this.sizeError = false;
      this.fileAlreadyExistsError = false;
      this.isIncorrectPdf = false;
      this.specialCharectersError = false;
    },
    onSubmit() {
      this.$emit('uploadSuccess', this.formData, { id: this.id, name: this.name });
      this.removeItems();
    }
  }
};
</script>
<style>
.uploadBox .uploadButtons {
  margin: 24px 0px;
}
.uploadBox .uploadButtons .btn {
  margin: 0px 0px 0px 16px !important;
}
.uploadBox .btn {
  margin: auto;
}
.uploadBox .material-design-icon {
  fill: black;
  display: inline-block;
}
.uploadBox .material-design-icon svg {
  width: 43px !important;
  height: 26px !important;
}
.uploadBox .primaryMessage {
  font-size: 16px;
  padding: 5px 0 5px 0;
}
.uploadBox .secondaryMessage {
  font-size: 12px;
}
.uploadBox .orMessage {
  font-size: 12px;
  padding: 5px 0 5px 0;
}
.uploadBox {
  padding: 0px 75px 20px 50px;
}
.uploadBox .uploadBoxMain .fileDetails {
  font-size: 16px;
  padding: 24px 0px 0px 24px;
  text-decoration: underline;
}
.uploadBox .uploadBoxMain ol {
  list-style: decimal;
  margin: 16px 40px;
}
.uploadBox .uploadBoxMain ol > li {
  padding: 0 0 5px 0;
}
.uploadBox .uploadBoxMain {
  width: 100%;
  text-align: left;
  padding: 40px 0 40px 0;
  padding: 0 1em 1em 1em;
  border: dashed 1px #a5a5a5;
}
/* Drag and drop */
.uploadBox .dropArea {
  width: 100%;
  text-align: center;
  padding: 40px 0 40px 0;
}
.uploadBox .dropArea input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
/* End drag and drop */
/* Loader */
.uploadBox .loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0.9;
}
.uploadBox .loaderImg {
  border: 8px solid #eee;
  border-top: 8px solid #00adce;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End Loader */
.uploadBox .errorMsg {
  font-size: 14px;
  color: #b42314;
  margin: 16px 0 0 16px;
}
.uploadBox .successMsg {
  font-size: 14px;
}
</style>
