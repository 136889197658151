<template>
  <div class="mNavigation">
    <mds-navigation
      v-bind="$attrs"
      data-site-name="Mediq"
      data-url-home="/"
      data-breakpoint="1280"
      v-on="$attrs"
      @click.prevent="click"
    >
      <nav slot="content">
        <mds-navigation-link
          v-for="(menuItem, menuIndex) in $attrs.content"
          slot="content"
          :key="'Dropdown' + menuIndex"
          :title="menuItem.title"
          :text="menuItem.title"
        >
          <span slot="submenu">
            <m-dropdown v-if="menuItem.child && menuItem.child.length > 0" data-type="menu">
              <template v-slot:content>
                <mds-navigation-link
                  v-for="(menuSubItem, menuSubItemIndex) in menuItem.child"
                  slot="content"
                  :key="menuSubItemIndex"
                  :item="JSON.stringify(menuSubItem)"
                  href=""
                  :text="menuSubItem.title"
                  :current="menuSubItem.active"
                />
              </template>
            </m-dropdown>
          </span>
        </mds-navigation-link>
      </nav>
      <nav slot="corporate">
        <mds-navigation-link
          v-for="(menuItem, menuIndex) in $attrs.corporate"
          slot="corporate"
          :key="'Normal' + menuIndex"
          :item="JSON.stringify(menuItem)"
          href=""
          :title="menuItem.title"
          :text="menuItem.title"
        />
      </nav>
      <nav slot="commerce">
        <mds-navigation-link v-if="$attrs.commerce" slot="commerce" text="Mijn Mediq" icon="single-user">
          <span slot="submenu">
            <m-dropdown data-type="menu" :data-title="$attrs.commerce.title">
              <template v-slot:content>
                <mds-navigation-link
                  v-for="(subItem, index) in $attrs.commerce.child"
                  slot="content"
                  :key="index"
                  :item="JSON.stringify(subItem)"
                  href=""
                  :text="subItem.title"
                />
              </template>
            </m-dropdown>
          </span>
        </mds-navigation-link>
      </nav>
    </mds-navigation>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { MdsNavigation } from '@mds/navigation';
export default {
  name: 'Navigation',
  computed: {},
  created() {},
  methods: {
    click(event) {
      event.preventDefault();
      let itemValue = event.target.getAttribute('item')
        ? event.target.getAttribute('item')
        : event.target.getAttribute('data-url-home');
      this.$emit('item-click', this.isValidJSON(itemValue) ? JSON.parse(itemValue) : itemValue);
    },
    isValidJSON(obj) {
      try {
        JSON.parse(obj);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mNavigation {
  :deep(nav) {
    display: inherit;
  }
}
</style>
