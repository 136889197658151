import axios from 'axios';

const getInitialState = () => {
  return {
    products: [],
    originalProductIds: [],
    productsSelected: [],
    filters: [],
    productDosageGroupAmount: 1,
    productCode: null,
    illnessId: null,
    defaultSearch: true
  };
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState());
    },
    setProductCode(state, productCode) {
      state.productCode = productCode;
    },
    setIllnessId(state, illnessId) {
      state.illnessId = illnessId;
    },
    removeProducts(state) {
      state.products = [];
    },
    removeFilters(state) {
      state.filters = [];
    },
    setDefaultSearch(state, value) {
      state.defaultSearch = value;
    },
    resetProductsAndFilters(state) {
      state.products = [];
      state.productsSelected = [];
      state.filters = [];
    }
  },
  actions: {
    getDefaultSearch({ state }) {
      return false;
    },
    /**
     * @function fetchProducts
     * @param {object} context
     * @param {object} data
     * @param {number} productCode - productCode is a number to find products.
     * @param {number} illnessId - illnessId is a number linked to a Therapy.
     * @description Get products for displaying in the Accountability
     * Products are returned by the API in sorted order as follows:
     * Product(s) with Smaakbox = true.
     * If present, then typically at most 1 such product.
     * Product(s) with Smaakbox = false, sorted alphabetically by Name (A-Z).
     */
    fetchProducts({ state }, data) {
      return axios.get('Products/Get', { params: { productCode: data.productCode } }).then((response) => {
        state.products = response.data.Products.map((product) => {
          // Define and set the default Basket_DosageGroup to false for each product
          product.Basket_DosageGroup = false;
          // Define and set the default Basket_DosageAmount to the first unit of measure for each product
          product.Basket_DosageAmount = product.UnitsOfMeasure[0];
          // The product needs the matching Therapy number.
          // This will be send to the API when the product is selected.
          // Define and set Basket_IllnessId
          product.Basket_IllnessId = data.illnessId;
          return product;
        });
        // Create an originalProductsId that can be used for faster comparisments
        state.originalProductIds = response.data.Products.map((product) => product.ProductId);
        // Remove products that are already in the productsSelected array to avoid duplicates on page refreshes
        // Important to first fetchProductsSelected so it isn't empty
        const productsSelectedIds = state.productsSelected.map((product) => product.ProductId);
        state.products = state.products.filter((product) => !productsSelectedIds.includes(product.ProductId));
      });
    },
    /**
     * @function removeProductSelected
     * @param {object} context
     * @param {number} productId
     * @description Remove product by productId from the productSelected array
     */
    removeProductSelected({ state }, productId) {
      const product = state.productsSelected.find((product) => product.ProductId === productId);
      const productIndex = state.productsSelected.indexOf(product);
      state.productsSelected.splice(productIndex, 1);
    },
    /**
     * @function moveProduct
     * @param {object} context
     * @param {object} data
     * @param {string} data.from - Array in state from which the product to be moved
     * @param {string} data.to - Array in state where the product needs to be moved to
     * @param {number} data.productId - productId of the product that needs to be moved
     */
    moveProduct({ state }, data) {
      const product = state[data.from].find((product) => product.ProductId === data.productId);
      const productIndex = state[data.from].indexOf(product);
      state[data.to].push(product);
      state[data.from].splice(productIndex, 1);
    },
    /**
     * @function toggleDosageGroup
     * @param {object} context
     * @param {number} productId
     * @description Toggle the property DosageGroup (Boolean) from a product
     */
    toggleDosageGroup({ state, dispatch }, productId) {
      const product = state.productsSelected.find((product) => product.ProductId === productId);
      product.Basket_DosageGroup = !product.Basket_DosageGroup;
    },
    /**
     * @function setDosageAmount
     * @param {object} context
     * @param {object} data
     * @param {number} data.productId
     * @param {number} data.dosage
     * @description Set dosage per product by productId
     */
    setDosageAmount({ state }, data) {
      const product = state.productsSelected.find((product) => product.ProductId === data.productId);
      product.Basket_DosageAmount = data.dosage;
    },
    /**
     * @function setDosageGroupAmount
     * @param {object} context
     * @param {number} dosage
     * @description Set dosage for the dosageGroup
     */
    setDosageGroupAmount({ state, dispatch }, dosage) {
      state.productDosageGroupAmount = dosage;
    },
    /**
     * @function getDosageGroupAmount
     * @param {object} context
     * @description Default is null if there are no products that are part of DosageGroup
     */
    getDosageGroupAmount({ state }) {
      // Get the Basket_DosageAmount for the products that are selected for total dailydosage
      // If the boolean Basket_DosageGroup is true then the product is part of total dailydosage
      // Only the first product find will be used to decide what the total dailydosage is because they all have the same amount
      const productInDosageGroup = state.productsSelected.find((product) => product.Basket_DosageGroup);
      state.productDosageGroupAmount =
        productInDosageGroup !== undefined
          ? productInDosageGroup.Basket_DosageAmount
          : getInitialState().productDosageGroupAmount;
    },
    // add the dosageGroupAmount to the products that have DosageGroup set to true
    /**
     * @function addDosageGroupAmountToProducts
     * @param {object} context
     * @description Add the DosageAmount to the DosageGroup of productsSelected
     */
    addDosageGroupAmountToProducts({ state }) {
      const products = state.productsSelected.filter((product) => product.Basket_DosageGroup);
      products.forEach((product) => {
        product.Basket_DosageAmount = state.productDosageGroupAmount;
      });
    },
    /**
     * @function fetchFilters
     * @param {object} context
     * @description Get all the filters and set them to the state also set the IllnessId and ProductCode
     */
    fetchFilters({ state, commit }, productSelectionAccountabilityId) {
      return axios
        .get('VragensetVerantwoording/Init?accountabilityId=' + productSelectionAccountabilityId)
        .then((response) => {
          // Backend logic: Groepen always has a length of 1 in response.data
          const questions = response.data.Groepen[0].Vragen;
          // Backend logic: Remove the last question it is always empty
          const lastQuestion = questions.find((question) => question.IsLaatste === true);
          const lastQuestionIndex = questions.indexOf(lastQuestion);
          if (lastQuestionIndex > -1) {
            questions.splice(lastQuestionIndex, 1);
          }
          // "Questions" will now be named filters in the application
          state.filters = questions;
          // Evaluate if there is a new productCode and IllnessId
          // Get the last relevant question
          const lastFilledInQuestion = state.filters[state.filters.length - 1];
          // Evaluate if there is an answer
          if (lastFilledInQuestion.ResultaatAnswers.length > 0 && lastFilledInQuestion.ResultaatAnswers[0].AnswerId) {
            const answerId = lastFilledInQuestion.ResultaatAnswers[0].AnswerId;
            const answer = lastFilledInQuestion.Antwoorden.find((answer) => answerId === answer.AnswerId);
            commit('setIllnessId', answer.IllnessId);
            commit('setProductCode', answer.LphCode);
          } else {
            commit('setIllnessId', null);
            commit('setProductCode', null);
          }
        });
    },
    /**
     * @function postFilter
     * @param {object} context
     * @param {object} filter
     * @param {number} filter.AccountabilityLineId
     * @param {number} filter.Answer.AnswerId
     * @description Post a filter
     * After the post the filters might get updated so doing fetchFilters is wise
     */
    postFilter({ state }, filter) {
      const postDataModel = {
        AccountabilityLineId: filter.AccountabilityLineId,
        ForProductSelection: true,
        Resultaten: [
          {
            AnswerId: filter.Answer.AnswerId,
            Resultaat: ''
          }
        ]
      };
      return axios.post('VragensetVerantwoording/BeantwoordVraag', postDataModel);
    },
    /**
     * @function fetchProductsSelected
     * @param {object} context
     * @description fetch all the productsSelected and dispatch getDosageGroupAmount and addDosageGroupAmountToProducts
     */
    fetchProductsSelected({ state, dispatch }, productSelectionAccountabilityId) {
      return axios
        .get('Basket/GetProducts?productSelectionAccountabilityId=' + productSelectionAccountabilityId)
        .then((response) => {
          state.productsSelected = response.data.Products.map((product) => {
            product.Basket_DosageAmount = product.Basket_UnitOfMeasure;
            return product;
          });
          dispatch('getDosageGroupAmount');
          dispatch('addDosageGroupAmountToProducts');
        });
    },
    /**
     * @function postProductsSelected
     * @param {object} context
     * @description Post the products that are selected using the data model the API expects
     */
    postProductsSelected({ state }, productSelectionAccountabilityId) {
      const postDataModel = {
        ProductSelectionAccountabilityId: productSelectionAccountabilityId,
        Products: state.productsSelected.map((product) => {
          const newProduct = {};
          newProduct.ProductId = product.ProductId;
          newProduct.DosageGroup = product.Basket_DosageGroup;
          newProduct.UnitOfMeasure = product.Basket_DosageAmount;
          newProduct.IllnessId = product.Basket_IllnessId;
          return newProduct;
        })
      };
      return axios.post('Basket/SetProducts', postDataModel);
    }
  },
  getters: {
    getProducts: (state) => {
      return state.products.filter((product) => {
        // Business logic: only show products once in either the products array or productsSelected array
        // Return all products that are not in productsSelected array
        return !state.productsSelected.map((product) => product.ProductId).includes(product.ProductId);
      });
    },
    getProductsSelected: (state) => {
      return state.productsSelected;
    },
    getProductsSelectedNoDailyDosage: (state) => {
      return state.productsSelected.filter((product) => product.Basket_DosageGroup === false);
    },
    getProductsSelectedDailyDosage: (state) => {
      return state.productsSelected.filter((product) => product.Basket_DosageGroup);
    },
    getFilters: (state) => {
      return state.filters;
    },
    getOriginalProductIds: (state) => {
      return state.originalProductIds;
    },
    getProductDosageGroupAmount: (state) => {
      return state.productDosageGroupAmount;
    },
    getProductCode: (state) => {
      return state.productCode;
    },
    getIllnessId: (state) => {
      return state.illnessId;
    }
  }
};
