<template>
  <div>
    <router-view />
    <feedback />
    <todoEmailNotification />
    <alert />
    <modal />
    <prompt />
  </div>
</template>

<script>
import Alert from '@/components/shared/Alert.vue'
import Modal from '@/components/shared/Modal.vue'
import Prompt from '@/components/shared/Prompt.vue'
import Feedback from '@/components/sections/feedback/FeedbackForm.vue'
import TodoEmailNotification from '@/components/sections/prescriber/TodoEmailNotification.vue'
import { isLoggedIn$ } from '@/services';
import { ref, onMounted } from 'vue';

export default {
  name: 'App',
  components: {
    Alert,
    Modal,
    Prompt,
    Feedback,
    TodoEmailNotification
  },
  setup() {
    // Define reactive variable for isLoggedIn
    const isLoggedIn = ref(false);

    // Fetch initial value of isLoggedIn
    const fetchInitialValue = () => {
      isLoggedIn$.subscribe(value => {
        isLoggedIn.value = value;
      });
    };

    // Call fetchInitialValue when component is mounted
    onMounted(fetchInitialValue);

    return {
      isLoggedIn
    };
  },
  mounted () {
    let host = window.location.host
    document.title = 'Mediq Voorschrijfportaal'
    
    // Google Tag Manager
    let gtmScript = document.createElement('script')
    gtmScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5JZVM3L');"
    document.head.appendChild(gtmScript)
    let gtmNoScript = document.createElement('noscript')
    gtmNoScript.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5JZVM3L' height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    document.body.appendChild(gtmNoScript)
    // End Google Tag Manager

  }
}
</script>
