<template>
  <generic-modal :has-close-button="true" @close="closeAuthenticationModal">
    <template #header>
      <template v-if="!authIsDisabled">
        {{ twoFactorAuthenticationSettingsConfiguration.disable2FaPopupTitle }}
      </template>
      <template v-if="authIsDisabled">
        {{ twoFactorAuthenticationSettingsConfiguration.disable2FaPopupSuccessTitle }}
      </template>
    </template>
    <template #body>
      <div v-if="!authIsDisabled">
        <p>{{ twoFactorAuthenticationSettingsConfiguration.disable2FaPopupDescription }}</p>
        <v-input-group-simple-2
          id="deauthorizationCode"
          v-model:value="deauthorizationCode"
          maxlength="6"
          placeholder="Uw verificatiecode"
          data-vv-as="Uw verificatiecode"
          :overwrite-value="deauthorizationCode"
          :rules="{
            required: false
          }"
        />
        <small v-if="authenticationNotDisabled && !showResendText" class="notification-text">
          {{ twoFactorAuthenticationSettingsConfiguration.invalid2FaCode }}
        </small>
        <small v-if="showResendText && !authenticationNotDisabled" class="notification-text">
          Er is een nieuwe code verstuurd naar uw email.
        </small>
        <v-button class="resend-button" transparent hollow @click="resendVerificationCode()">
          {{ twoFactorAuthenticationSettingsConfiguration.resend2FaCodeButtonLabel }}
        </v-button>
      </div>
      <div v-if="authIsDisabled" class="confirmation">
        <div class="col-xs-2">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
        <p class="col-xs-8">
          {{ twoFactorAuthenticationSettingsConfiguration.disable2FaPopupSuccessDescription }}
        </p>
      </div>
    </template>
    <template v-if="!authIsDisabled" #footer>
      <v-button md hollow transparent @click="closeAuthenticationModal"> Annuleren </v-button>
      <v-button :disabled="deauthorizationCode.length <= 5" md primary @click="disable2FA()"> Bevestigen </v-button>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/shared/GenericModal';
import { tap } from 'rxjs/operators';
import {
  currentUserIdentity$,
  disable2FactorAuthentication,
  twoFactorAuthenticationNotDisabledEvent$,
  twoFactorAuthenticationDisabledEvent$,
  twoFactorAuthenticationSettingsConfiguration$
} from '@/services';
import { ref } from 'vue';

export default {
  name: 'PrescriberDisableAuthModal',
  components: {
    GenericModal
  },
  setup() {
    const currentUserIdentity = ref(null);
    const twoFactorAuthenticationSettingsConfiguration = ref(null);
    const twoFactorAuthenticationDisabledEvent = ref(null);
    const twoFactorAuthenticationNotDisabledEvent = ref(null);
    const deauthorizationCode = ref(null);
    const authenticationNotDisabled = ref(null);
    const showResendText = ref(null);

    currentUserIdentity$.subscribe((value) => (currentUserIdentity.value = value));
    twoFactorAuthenticationSettingsConfiguration$.subscribe(
      (value) => (twoFactorAuthenticationSettingsConfiguration.value = value)
    );
    twoFactorAuthenticationDisabledEvent$.subscribe((value) => (twoFactorAuthenticationDisabledEvent.value = value));

    twoFactorAuthenticationNotDisabledEvent$.pipe(
      tap((e) => {
        this.deauthorizationCode = '';
        this.authenticationNotDisabled = true;
        this.showResendText = false;
      })
    );

    return {
      currentUserIdentity,
      twoFactorAuthenticationSettingsConfiguration,
      twoFactorAuthenticationDisabledEvent,
      twoFactorAuthenticationNotDisabledEvent,
      deauthorizationCode,
      authenticationNotDisabled,
      showResendText
    };
  },
  data() {
    return {};
  },
  computed: {
    authIsDisabled() {
      return !this.currentUserIdentity.twoFactorAuthentication.enabled;
    }
  },
  mounted() {
    disable2FactorAuthentication();
  },
  methods: {
    closeAuthenticationModal() {
      this.$emit('closeAuthenticationModal', false);
    },
    resendVerificationCode() {
      disable2FactorAuthentication();
      this.showResendText = true;
      this.authenticationNotDisabled = false;
    },
    async disable2FA() {
      await disable2FactorAuthentication(this.deauthorizationCode);
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.resend-button {
  color: $black;
  text-decoration: underline;
  font-weight: 400;
  padding: 10px 0 10px 0;
}
.notification-text {
  color: $red;
  display: block;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $cyan-darker;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $cyan-darker;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $cyan-darker;
  }
}
</style>
