<template>
  <section class="container serviceCenter">
    <div class="row">
      <div v-if="!topPage" class="serviceCenter__scrollTop" @click.prevent="scrollToTop">
        <v-scroll-top-icon />
      </div>
      <div class="col-md-12">
        <div class="row">
          <v-heading :level="1"> Service en contact </v-heading>
        </div>
        <div class="row">
          <v-heading :level="3"> Waarmee kunnen we u helpen </v-heading>
        </div>
        <div v-if="serviceCenterDetails && serviceCenterDetails.informationBlock" class="row grid-margin grid-padding">
          <div
            v-for="(serviceItem, index) in serviceCenterDetails.informationBlock.informationTypes"
            :key="index"
            class="col-md-6 card"
          >
            <div class="row">
              <div class="col-md-1">
                <help-icon v-if="serviceItem.type === 'Question'" />
                <play-icon v-else-if="serviceItem.type === 'Video'" />
                <phone-icon v-else-if="serviceItem.type === 'Contact'" />
                <email-icon v-else />
              </div>
              <div class="col-md-11">
                <h3>{{ serviceItem.header }}</h3>
                <p>{{ serviceItem.text }}</p>
              </div>
            </div>
            <mds-button type="link" context="neutral" class="cardBtn" :href="'#' + serviceItem.type">
              <span slot="text">{{ serviceItem.buttonText }}</span>
            </mds-button>
          </div>
        </div>
        <div v-if="siteCode" id="Question" class="row">
          <div class="serviceCenter__block col-xs-12">
            <v-header title="Veelgestelde vragen" class="faqHeader" />
            <faqs />
          </div>
        </div>
        <div id="Video" class="row">
          <div class="serviceCenter__block col-xs-12">
            <v-header title="Instructievideo van het voorschrijfsysteem" />
            <div class="serviceCenter__block__content" v-html="serviceCenterDetails.instructionHTMLContent" />
          </div>
        </div>
        <div id="Contact" class="row">
          <div class="serviceCenter__block col-xs-12">
            <contact-details />
          </div>
        </div>
        <div id="Message" class="row">
          <div class="serviceCenter__block col-xs-12">
            <contact />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import Contact from '@/components/sections/contact/Contact';
import ContactDetails from '@/components/sections/contact/ContactDetails';
import faqs from '@/components/sections/serviceCenter/faqs.vue';
import VHeader from '@/components/shared/Header.vue';
import VScrollTopIcon from 'icons/ArrowUpBoldBox';
import helpIcon from 'icons/HelpCircle';
import emailIcon from 'icons/Email';
import phoneIcon from 'icons/Phone';
import playIcon from 'icons/PlayCircleOutline';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'ServiceCenter',
  components: {
    faqs,
    Contact,
    ContactDetails,
    VHeader,
    VScrollTopIcon,
    helpIcon,
    emailIcon,
    phoneIcon,
    playIcon
  },
  setup() {
    const store = useStore();
    const siteCode = computed(() => store.getters['navigation/getSiteCode']);

    return {
      siteCode
    };
  },
  data() {
    return {
      topPage: false,
      serviceCenterDetails: {}
    };
  },
  created() {
    window.addEventListener('scroll', () => {
      this.topPage = this.topVisible();
    });

    axios.get('ServiceCenter/GetServiceCenterInformation').then((response) => {
      if (response && response.data) {
        this.serviceCenterDetails = response.data;
      }
    });
  },
  methods: {
    topVisible() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      return scrollY === 0;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.serviceCenter {
  .faqHeader {
    margin-bottom: 0px !important;
  }
  .card {
    color: $teal-darkest;
    background-color: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    .material-design-icon {
      fill: $teal-darkest;
      margin: 16px 16px 16px 16px;
      :deep(svg) {
        height: 28px;
        width: 28px;
      }
    }
    p {
      min-height: 150px;
    }
  }
  .cardBtn {
    position: absolute;
    right: 16px;
    bottom: 24px;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 24px 0px 24px 0px;
    &__content {
      padding: 0 24px 24px 24px;
    }
  }
  &__scrollTop {
    bottom: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    .material-design-icon {
      fill: $aqua;
      :deep(svg) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
