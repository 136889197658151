<template>
  <div class="mSelect">
    <mds-select v-if="optionsStringType" v-bind="$attrs" v-on="$attrs" @change="changeStringType">
      <option
        v-for="(item, index) in $attrs.options"
        :key="index"
        :value="item"
        :selected="item === $attrs.value ? true : false"
      >
        {{ item }}
      </option>
    </mds-select>
    <mds-select v-else v-bind="$attrs" v-on="$attrs" @change="changeObjectType">
      <option
        v-for="(item, index) in $attrs.options"
        :key="index"
        :value="item.id"
        :selected="item.id === $attrs.value ? true : false"
      >
        {{ item.value }}
      </option>
    </mds-select>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { MdsSelect } from '@mds/select';

export default {
  name: 'MSelect',
  computed: {
    optionsStringType() {
      return typeof this.$attrs.options[0] === 'string' ? true : false;
    }
  },
  created() {},
  methods: {
    changeStringType(event) {
      event.preventDefault();
      this.$emit('changeOption', event.target.value);
    },
    changeObjectType(event) {
      event.preventDefault();
      let selectedOption = this.$attrs.options.find((option) => {
        return option.id === parseInt(event.target.value, 10);
      });
      this.$emit('changeOption', selectedOption);
    }
  }
};
</script>
