<template>
  <div class="row grid-margin pumpSearch">
    <div class="col-xs-6 col-md-6 pumpSearch__filters">
      <label class="pumpSearch__labels">Zoeken op apparaten</label>
      <v-input-group-simple-2
        id="pumpSearch"
        :value="pumpSearch.searchText"
        v-model="pumpSearch.searchText"
        :overwrite-value="pumpSearch.searchText"
        :rules="{
          required: false
        }"
        placeholder="Zoeken op omschrijving of serienummer"
        :show-search-icon="!(awaitingSearch || searchLoadingStatus)"
        @input="handleSyncLoader($event)"
      />
      <sync-loader
        v-if="awaitingSearch || searchLoadingStatus"
        :color="color"
        class="pumpSearch__filters__search-icon"
      />
    </div>
    <div class="col-xs-12 col-md-2">
      <label class="pumpSearch__labels">Status</label>
      <v-select-group
        v-model="pumpSearch.status"
        :overwrite-value="pumpSearch.status"
        :initselected="pumpSearch.status"
        @change.stop="filterStatus"
        selectid="pumpSearch"
      >
        <option value="Toon Alles">Toon Alles</option>
        <option value="Available">In depot</option>
        <option value="Allocated">Bij patiënt</option>
        <option value="Naar Mediq">Naar Mediq</option>
      </v-select-group>
    </div>
    <div class="col-md-4 grid-align-end">
      <v-button class="btn btn--hollow btn--md pumpSearch__resetFilters" @click.prevent="resetFilters">
        <v-reset-icon />
        Reset filters
      </v-button>
    </div>
  </div>
</template>

<script>
import VResetIcon from 'icons/Refresh';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
  name: 'PumpSearch',
  components: {
    VResetIcon,
    SyncLoader
  },
  props: {},
  data() {
    return {
      color: '#73c8d2',
      searchLoadingStatus: false,
      awaitingSearch: false,
      typeSleep: 0,
      pumpSearch: {
        searchText: null,
        status: 'Toon Alles'
      }
    };
  },
  watch: {
    'pumpSearch.searchText'(value) {
      this.$emit('searchPump', this.pumpSearch);
    }
  },
  methods: {
    /**
     * @function resetFilters
     * @description Reset all filters
     */
    resetFilters() {
      this.pumpSearch = {
        searchText: '',
        status: 'Toon Alles'
      };
      this.$emit('resetFilters');
    },
    filterStatus($event) {
      this.pumpSearch.status = $event.target.value;
      this.$emit('searchPump', this.pumpSearch);
    },
    /**
     * @function handleSyncLoader
     * @description Function to handle sync loader
     */
    handleSyncLoader(event) {
      if (event.target.value.length > 2) {
        this.searchLoadingStatus = true;
      } else {
        this.searchLoadingStatus = false;
      }
      this.finishTyping(event.target.value);
    },
    /**
     * @function finishTyping
     * @description Function to check ther user typing
     */
    finishTyping(term) {
      // Based on research of the average CPM (200) typing speed
      // Haults request to wait till user is finished typing by calculating keystroke time
      this.typeSleep += 1;
      setTimeout(() => {
        this.typeSleep -= 1;
        if (this.typeSleep === 0) {
          this.awaitingSearch = false;
          if (term.length > 2) {
            if (this.searchLoadingStatus || term.length === 0) {
              this.searchLoadingStatus = false;
              this.finishTyping(term);
            }
          }
        }
      }, 300);
      this.awaitingSearch = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.pumpSearch {
  :deep(.form-group) {
    padding: 0px;
  }
  &__labels {
    font-size: 18px;
    font-weight: bold;
    color: $teal-darkest;
    margin-bottom: 10px;
    overflow: hidden;
  }
  &__filters {
    position: relative;
    &__search-icon {
      position: absolute;
      fill: $gray-dark;
      right: 5px;
      top: 45px;
    }
  }
  &__resetFilters {
    margin: 36px 0px 0px 0px;
  }
}
</style>
