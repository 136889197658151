import { shoppingCartRefreshRequested$ } from './shoppingCartRefreshRequested$';
import { combineLatest, from } from 'rxjs';
import { map, publishBehavior, refCount, switchMap } from 'rxjs/operators';
import axios from 'axios';
import { activeCustomerId$ } from '@/services/activeCustomer$';

const emptyShoppingCart = {
  customerId: 0,
  items: []
};

function createItem(responseItem) {
  var givenToPatient = responseItem.givenToPatient;
  var fillUpStock = responseItem.fillUpStock;

  return {
    ...responseItem,
    set givenToPatient (value)
    {
      givenToPatient = value;

      if (givenToPatient === false)
      {
        this.serialNumber = '';
        this.fillUpStock = false;
      }
      else
      {
        this.instructionInput = false;

        if (this.supportFillUpStock && this.supportAutoFillUpStockWhenGivenToPatient)
        {
          this.fillUpStock = value;
        }
      }
    },
    get givenToPatient() {
      return givenToPatient;
    },
    set fillUpStock (value) {
      fillUpStock = value;

      if (value === false) {
        // Reset warehouse
        this.warehouseId = undefined;
      }
      else {
        this.warehouseId = this.defaultWarehouseId;
      }
    },
    get fillUpStock() {
      return fillUpStock;
    }
  };
}

export const b2cShoppingCart$ = combineLatest([activeCustomerId$, shoppingCartRefreshRequested$]).pipe(
  map(([activeCustomerId, refreshRequested]) => {
    return activeCustomerId;
  }),
  // Execute xhr request
  switchMap((customerId) => {
    if(customerId > 0)
    {
      return from(
        axios.get('ShoppingCart/GetCustomerShoppingCart?customerId=' + customerId).then((response) => {
          // mold the current response into a more appropriate `items`
          return {
            customerId: customerId,
            items: response.data.map(item => createItem(item))
          };
        })
      );
    }
    else {
      return Promise.resolve(emptyShoppingCart);
    }
  }),
  // Default value of observable
  publishBehavior(emptyShoppingCart),
  refCount()
);
