<template>
  <form
    method="get"
    class="password-reset-form row grid-padding grid-justify-content-center"
    @submit.prevent="submitForm"
  >
    <v-heading class="col-xs-12" :level="1"> Wachtwoord resetten </v-heading>
    <p class="col-xs-12">
      Vul hieronder uw gebruikersnaam, e-mailadres en een veiligheidscode in. We sturen dan binnen enkele minuten een
      e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.
    </p>
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 col-md-8">
          <div class="row grid-margin">
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Username"
                v-model:value="resetPasswordDetails.Username"
                :rules="{
                  required: true
                }"
                class="input-password"
                placeholder="Gebruikersnaam"
                type="text"
                label="Gebruikersnaam"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Emailaddress"
                v-model:value="resetPasswordDetails.Emailaddress"
                :rules="{
                  required: true,
                  email: true,
                  regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                }"
                class="input-password"
                placeholder="E-mail"
                type="text"
                label="E-mail"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Securitycode"
                v-model:value="resetPasswordDetails.Securitycode"
                :rules="{
                  required: true
                }"
                class="input-password"
                placeholder="Veiligheidscode"
                :show-tooltip="true"
                tooltip-text="Deze veiligheidscode wordt ter controle opnieuw gevraagd bij het invullen van een nieuw wachtwoord. De code mag zelf gekozen worden. Onthoud deze goed."
                type="text"
                label="Veiligheidscode"
                @input="saveInput($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-10 col-md-7 row grid-margin">
      <div class="col-xs-12">
        <v-button type="submit" tagelement="button" cta md :disabled="formSubmitted">Wachtwoord herstellen</v-button>
      </div>
    </div>
  </form>
</template>

<script>
import router from '@/router';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'VResetPassword',
  components: {},
  setup() {
    const store = useStore();
    const submitResetPasswordForm = (data) => store.dispatch('login/submitResetPasswordForm', data);

    return {
      submitResetPasswordForm
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      resetPasswordDetails: {
        Username: undefined,
        Emailaddress: undefined,
        Securitycode: undefined
      },
      formSubmitted: false
    };
  },
  created() {
    this.$parent.$parent.pageTitle = 'Wachtwoord vergeten';
    this.$parent.$parent.navigationBackText = 'Terug naar inloggen';
  },
  methods: {
    submitForm() {
      $validateAllComponents(this).then((validated) => {
        if (validated) {
          this.formSubmitted = true;
          this.submitResetPasswordForm(this.resetPasswordDetails)
            .then(() => {
              // Submission successful
              this.$emitter.emit('alert', {
                message: 'Uw wachtwoord is gereset. U ontvangt een e-mail met instructies.',
                type: 0,
                delay: 5000
              });
              router.push({ path: '/' });
            })
            .catch(() => {
              // Submission failed or denied
              this.$emitter.emit('alert', { message: 'Uw wachtwoord kon niet worden gereset.', type: 3 });
              this.formSubmitted = false;
            });
        }
      });
    },
    /**
     * @function saveInput - Function to save input value to the store
     */
    saveInput(event) {
      this.resetPasswordDetails[event.target.id] = event.target.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: type-scale(6) !important;
  font-weight: 700 !important;
  text-align: center;
  display: block;
  margin-top: 30px !important;

  + p {
    color: $teal-darkest;
    font-size: type-scale(0);
  }
}
.password-reset-form {
  background-color: $white;
  border-radius: 19px;
  max-width: 637px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
