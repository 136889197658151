import axios from 'axios';
import { pushEvent } from './clientEventMethods';

export function dispatchCommand(commandName, cmd) {
  return axios.post('../cmd/' + commandName, cmd).then(response => {
    // take care of event observables
    var events = response.data;

    for(var i = 0; i < events.length; i++) {
      var e = events[i];
      pushEvent(e.name, e.envelope);
    }
    return response;
  });
}
