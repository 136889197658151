<template>
  <div
    class="form-group-simple"
    :class="{
      'form-group-simple--completed': completed,
      disabled: $props.disabled,
      'form-group-simple--alt': alt
    }"
  >
    <label v-if="label" :for="$props.id">
      {{ label }}
      <span v-if="rules && rules.required && label" class="asterisk">* </span>
      <v-information-circle-outline
        v-if="showTooltip"
        v-tooltip="{ content: tooltipText, placement: tooltipPosition }"
        class="form-group-simple__tooltip"
      />
    </label>
    <input
      :maxlength="maxlength"
      :class="errorMessage ? 'error' : ''"
      v-bind="$attrs"
      v-model="inputValue"
      ref="input"
      :id="$props.id"
      :name="$props.id"
      :type="inputType"
      :placeholder="$props.placeholder"
      v-on="$attrs"
      @focus="focus"
      @blur="blur"
      @click="checkCapsLockStatus"
      @keydown="checkCapsLockStatus"
      @input="update($event.target.value)"
    />
    <span v-if="showSearchIcon">
      <v-search-icon class="searchIcon greyColor" />
    </span>
    <span v-if="showHidePasswordIcon" @click="changePasswordVisibility()">
      <v-eye-off v-if="!showPassword" class="passwordIcon greyColor" title="Toon wachtwoord" />
      <v-eye v-else title="Verberg wachtwoord" class="passwordIcon greenColor" />
    </span>
    <div v-if="showAppend" class="input-group-append">
      <div class="input-group-text">
        {{ appendText }}
      </div>
    </div>
    <div v-if="showCapsLockStatus && isCapsLockOnMessageVisible" class="form-group-simple__details">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__notification">
          <span> <v-information-circle-outline /> Je capslock staat aan.</span>
        </div>
      </div>
    </div>
    <div class="form-group-simple__details" v-if="errorMessage">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          <span> <v-error-icon></v-error-icon> {{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VEye from 'icons/EyeOutline';
import VEyeOff from 'icons/EyeOffOutline';
import VSearchIcon from 'icons/Magnify';
import VInformationCircleOutline from 'icons/InformationOutline';
import VErrorIcon from 'icons/AlertCircle';
import { useField } from 'vee-validate';
import { ref, onBeforeMount, watch } from 'vue';
import { generateYupSchema } from '@/composables/globalHelperFunctions';
import { generateYupSchemaForBsn } from '@/components/shared/validationRules/bsnValidationRules';

export default {
  name: 'VInputGroupSimple2',
  inheritedAttrs: true,
  components: {
    VEye,
    VEyeOff,
    VSearchIcon,
    VInformationCircleOutline,
    VErrorIcon
  },
  props: {
    id: {
      default: '',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    type: {
      defualt: 'text',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    alt: {
      default: false,
      type: Boolean
    },
    showAppend: {
      default: false,
      type: Boolean
    },
    appendText: {
      default: '',
      type: String
    },
    value: {
      type: String
    },
    overwriteValue: {
      type: String
    },
    maxlength: {
      default: '',
      type: String
    },
    rules: {
      default: () => ({}),
      type: Object
    },
    showHidePasswordIcon: {
      default: false,
      type: Boolean
    },
    showSearchIcon: {
      default: false,
      type: Boolean
    },
    showTooltip: {
      default: false,
      type: Boolean
    },
    tooltipText: {
      type: String
    },
    tooltipPosition: {
      default: 'right',
      type: String
    },
    showCapsLockStatus: {
      default: false,
      type: Boolean
    },
    useIdForErrorMessage: {
      default: false,
      type: Boolean
    },
    fieldNameForErrorMessage: {
      default: '',
      type: String
    }
  },
  setup(props) {
    const inputValue = ref(props.value);
    const schema =
      props.rules && props.rules.isBsn ? ref(generateYupSchemaForBsn(props)) : ref(generateYupSchema(props));
    const { value, errorMessage, validate } = useField(props.id, schema);

    watch(
      () => props.rules,
      () => {
        if (props.rules && props.rules.isBsn) {
          schema.value = generateYupSchemaForBsn(props);
        } else {
          schema.value = generateYupSchema(props);
        }
      }
    );
    const completed = ref(false);
    const showPassword = ref(false);
    const inputType = ref(props.type);
    const isCapsLockOnMessageVisible = ref(false);

    const focus = () => {};

    const blur = () => {
      isCapsLockOnMessageVisible.value = false;
      validate();
    };

    const checkCapsLockStatus = (event) => {
      isCapsLockOnMessageVisible.value =
        event.target.value.toUpperCase() === event.target.value &&
        event.target.value.toLowerCase() !== event.target.value;
    };

    const changePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
      inputType.value = inputType.value === 'password' ? 'text' : 'password';
    };

    const update = (newValue) => {
      inputValue.value = newValue;
      value.value = newValue;
      validate();
    };

    onBeforeMount(() => {
      inputType.value = props.type;
      if (props.value) {
        value.value = props.value;
      }
    });

    // Watch for changes in the value prop and update inputValue accordingly
    watch(
      () => props.value,
      (newValue) => {
        inputValue.value = newValue;
        value.value = newValue;
        validate();
      }
    );

    return {
      inputValue,
      completed,
      showPassword,
      inputType,
      isCapsLockOnMessageVisible,
      focus,
      blur,
      checkCapsLockStatus,
      update,
      errorMessage,
      changePasswordVisibility
    };
  }
};
</script>
<style lang="scss" scoped>
.error {
  border: 1px solid $pure-red;
}
.passwordIcon {
  fill: black;
  z-index: 100;
  cursor: pointer;
  position: absolute;
  display: flex;
  right: 58px;
  top: 45px;
}
.searchIcon {
  fill: black;
  z-index: 100;
  cursor: pointer;
  position: absolute;
  display: flex;
  right: 30px;
  top: 16px;
}
.greyColor {
  fill: $gray !important;
}
.greenColor {
  fill: $dark-green !important;
}
.form-group-simple {
  position: relative;
  &.input-password {
    label {
      color: $teal-darkest;
      font-size: type-scale(0);
      font-weight: 400;
      margin-bottom: 8px;
    }
    input {
      min-height: unset;
      height: 48px;
      padding: 12px 16px;
      color: $teal-darkest;
    }
    .passwordIcon {
      top: 39px;
      &.greyColor {
        fill: $teal-darkest !important;
      }
    }
  }
  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
  &__notification {
    color: $pure-red;
    .information-outline-icon {
      display: inline-flex;
      width: 16px;
      height: 16px;
      fill: $pure-red;
      vertical-align: bottom;
    }
  }
  &__tooltip {
    fill: $teal-darkest;
    display: inline-flex;
    vertical-align: middle;
  }
  .input-group-append {
    position: absolute;
    display: flex;
    right: 0;
    top: 32px;
  }
  .input-group-text {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 50px;
  }
}

.disabled {
  input {
    background: $gray-lighter;
  }
}
@media (max-width: 490px) and (min-width: 410px) {
  ::-webkit-input-placeholder {
    opacity: 0;
  }
}
</style>
