import { Subject } from 'rxjs';

const events = {};

export function registerEvent(name) {
  const subject$ = new Subject();
  events[name] = {
    push: function (data) {
      subject$.next(data);
    }
  };
  return subject$.asObservable();
}

export function pushEvent(name, data) {
  if(events.hasOwnProperty(name)){
    events[name].push(data);
  }
}
