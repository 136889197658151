<template>
  <section class="container delivery">
    <div class="row">
      <v-heading :level="3">
        {{ customHeaderDetails.pageTitle }}
      </v-heading>
    </div>
    <!-- License data block-->
    <div class="row">
      <div class="delivery__block col-xs-12">
        <div class="delivery__block__header">
          {{ customHeaderDetails.deliveryDetailsHeader }}
        </div>
        <div class="delivery__block__content">
          <div class="row grid-margin delivery__row">
            <div class="col-xs-6">
              <div class="form-group__date">
                <v-input-date-picker
                  id="deliveryDate"
                  ref="date"
                  type="date"
                  :label="customHeaderDetails.dateLabel"
                  name="deliveryDate"
                  autofocus
                  osa-date
                  @selected="handleDate"
                />
              </div>
            </div>
            <div class="col-xs-6">
              <v-input-group-simple-2
                id="licenseId"
                v-model:value="licenseId"
                label="Betreffende machtiging"
                :rules="{
                  required: true
                }"
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Equipment block-->
    <div class="row">
      <div class="delivery__block col-xs-12">
        <div class="delivery__block__header">
          {{ customHeaderDetails.equipmentHeader }}
        </div>
        <div class="delivery__block__content">
          <div class="row grid-margin delivery__row">
            <div class="col-xs-6">
              <label for="selectLicense">Apparaat</label>
              <model-select
                id="device"
                v-model="deviceItem"
                placeholder="Selecteer apparaat"
                :search-text="searchDeviceText"
                :options="osaDeliveryDropdownDetails.deviceDetails"
                :selected-option="deviceItem"
                @blur="onSelect('device')"
              />
            </div>
            <div class="col-xs-6">
              <label for="selectLicense">Type masker</label>
              <model-select
                id="maskType"
                v-model="maskItem"
                placeholder="Selecteer Type masker"
                :search-text="searchMaskTypeText"
                :options="osaDeliveryDropdownDetails.maskTypeDetails"
                :selected-option="maskItem"
                @blur="onSelect('maskType')"
              />
            </div>
          </div>
          <div class="row grid-margin delivery__row">
            <div class="col-xs-6">
              <v-input-group-simple-2
                id="serialNumberDevice"
                v-model:value="osaDeliveryDetails.serialNumberDevice"
                :rules="{
                  required: false
                }"
                placeholder="02468101214161820"
                type="text"
                label="Serienummer apparaat"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-6">
              <label for="comments">Opmerkingen</label>
              <textarea
                id="comments"
                v-model="osaDeliveryDetails.comments"
                rows="5"
                placeholder="Beschrijf hier uw opmerking"
                @input="saveInput($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Final Delivery block-->
    <div v-if="user" class="row">
      <div class="delivery__block col-xs-12">
        <div class="delivery__block__header">
          {{ customHeaderDetails.finalHeader }}
        </div>
        <div class="delivery__block__content">
          <div class="row grid-margin delivery__row">
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="approval"
                :initvalue="approval"
                label="Patiënt heeft mondeling ingestemd om (relevante) data van het apparaat te delen met leverancier, behandelaar en/of zorgverzekeraar."
                inputid="approval"
              />
            </div>
          </div>
          <div
            v-if="user && userDetailsBlock"
            class="row grid-margin delivery__row"
            :class="{ 'fadeInDown animated': animated && userDetailsBlock }"
          >
            <div class="col-xs-6">
              <v-input-group-simple-2
                id="userName"
                v-model:value="user.username"
                :rules="{
                  required: false
                }"
                type="text"
                disabled
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple-2
                id="date"
                v-model:value="currentDate"
                :rules="{
                  required: false
                }"
                type="text"
                disabled
              />
            </div>
          </div>
          <div
            v-if="user && userDetailsBlock"
            class="row grid-margin grid-align-center delivery__row"
            :class="{ 'fadeInDown animated': animated && userDetailsBlock }"
          >
            <div class="delivery__signature">
              <prescriber-signature :user-id="user.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons Block-->
    <div class="row grid-justify-content-right">
      <div class="delivery__actions">
        <v-button md hollow class="delivery__actions__cancelDelivery" @click.prevent="cancelOSADelivery()">
          Annuleren
        </v-button>
        <v-button
          md
          cta
          class="delivery__actions__saveDelivery"
          :disabled="!userDetailsBlock"
          @click.prevent="saveOSADelivery()"
        >
          Verzenden
        </v-button>
      </div>
    </div>
    <!-- Cancel pop up -->
    <generic-modal v-if="showCancelPopUp">
      <template #body>
        <p>Wilt u het formulier annuleren? De gegevens worden niet opgeslagen.</p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="closeModal"> Ga terug </v-button>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Annuleren </v-button>
      </template>
    </generic-modal>
    <!-- Success pop up -->
    <generic-modal v-if="showSuccessPopup">
      <template #body>
        <p>De afleverbon is verzonden en als bijlage aan de machtiging toegevoegd.</p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Naar patiënt dashboard </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import moment from 'moment';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import GenericModal from '@/components/shared/GenericModal';
import { useForm } from 'vee-validate';

import { currentUserIdentity$ } from '@/services';
import PrescriberSignature from '@/components/sections/prescriber/PrescriberSignature';
import { ref } from 'vue';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'Delivery',
  components: {
    VInputDatePicker,
    VCheckboxGroup,
    GenericModal,
    PrescriberSignature
  },
  setup() {
    const user = ref(null);

    currentUserIdentity$.subscribe((value) => (user.value = value));

    return {
      user
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      showCancelPopUp: false,
      showSuccessPopup: false,
      customHeaderDetails: {
        pageTitle: 'Afleverbon OSA/POSA',
        deliveryDetailsHeader: 'Gegevens afleverbon',
        equipmentHeader: 'Geleverde apparatuur',
        finalHeader: 'Ondertekenen en verzenden',
        dateLabel: 'Datum levering'
      },
      animate: false,
      searchDeviceText: 'selecteer apparaat',
      searchMaskTypeText: 'selecteer type masker',
      deviceItem: {
        value: null,
        text: null
      },
      maskItem: {
        value: null,
        text: null
      },
      userDetailsBlock: false,
      saveButtonClicked: false,
      osaDeliveryDetails: {
        deliveryDate: '',
        licenseId: '',
        device: '',
        wifi: true,
        gprs: false,
        serialNumberDevice: '',
        maskType: '',
        comments: ''
      },
      osaDeliveryDropdownDetails: {
        deviceDetails: [
          { value: 1, text: '1701088 LOEWENSTEIN PRISMA SOFT EXCL MODEM CPAP' },
          { value: 2, text: '3066201 LOEWENSTEIN PRISMA BEVOCHTIGER' },
          { value: 3, text: '1700960 LOEWENSTEIN PRISMASMART MAX APAP' },
          { value: 4, text: '1700248 RM S10 AIRSENSE APAP+BEVOCHTIGER' },
          { value: 5, text: '1700966 RM AIRSENSE 10 APAP' },
          { value: 6, text: '1701092 RM AIRSENSE 11 APAP + BEVOCHTIGER' },
          { value: 7, text: '1701093 RM AIRSENSE 11 APAP' },
          { value: 8, text: '1701051 PHILIPS DREAMSTATION AUTO CPAP EXCL BEVOCHTIGER' },
          { value: 9, text: '1701063 PHILIPS DREAMSTATION AUTO CPAP REFURBISH' },
          { value: 10, text: '1700352 PHLPS DS1 BEVOCHTIGER' },
          { value: 11, text: '1701090 PRISMA 25S BILEVEL' },
          { value: 12, text: '1701080 PRIMSA 25ST BILEVEL ST' },
          { value: 13, text: '1701030 RM AIRCURVE S10 VAUTO + UITL SDCRD' },
          { value: 14, text: '1700562 PHLPS DREAMSTATION BIPAP ST' },
          { value: 15, text: '1701050 PHILIPS DREAMSTAT. AUTOBIPAP EX BEV+WIFI' },
          { value: 16, text: '1700752 PHILIPS NIGHTBALANCE SLAAPPOSITIETRAINER' },
          { value: 17, text: '1700487 NIGHTBAL SPT SLEEP POSITION TRNER 125CM' }
        ],
        maskTypeDetails: [
          { value: 1, text: '3065184 FP ESON 2 NSL MASK S' },
          { value: 2, text: '3064555 FP ESON 2 NSL MASK M' },
          { value: 3, text: '3064556 FP ESON 2 NSL MASK L' },
          { value: 4, text: '3053620 PHLPS DREAMWISP NSL MASK P' },
          { value: 5, text: '3053621 PHLPS DREAMWISP NSL MASK S' },
          { value: 6, text: '3053622 PHLPS DREAMWISP NSL MASK M' },
          { value: 7, text: '3065255 PHLPS DREAMWISP NSL MASK L' },
          { value: 8, text: '3053624 PHLPS DREAMWISP NSL MASK XL' },
          { value: 9, text: '3043886 RM MIRAGE FX NSL MASK FOR HER' },
          { value: 10, text: '3043885 RM MIRAGE FX NSL MASK WIDE' },
          { value: 11, text: '3043882 RM MIRAGE FX NSL MASK' },
          { value: 12, text: '3059767 FP EVORA UTN NC MASK S/M/L' },
          { value: 13, text: '3065128 PHLPS THERAPY 3100 UTN SP MASK XS' },
          { value: 14, text: '3065129 PHLPS THERAPY 3100 UTN SP MASK S' },
          { value: 15, text: '3065130 PHLPS THERAPY 3100 UTN SP MASK M' },
          { value: 16, text: '3065132 PHLPS THERAPY 3100 UTN SP MASK L' },
          { value: 17, text: '3065131 PHLPS THERAPY 3100 UTN SP MASK MW' },
          { value: 18, text: '3065150 PHLPS THERAPY 3100 UTN NC MASK XS' },
          { value: 19, text: '3065151 PHLPS THERAPY 3100 UTN NC MASK S' },
          { value: 20, text: '3065152 PHLPS THERAPY 3100 UTN NC MASK M' },
          { value: 21, text: '3065154 PHLPS THERAPY 3100 UTN NC MASK L' },
          { value: 22, text: '3065153 PHLPS THERAPY 3100 UTN NC MASK MW' },
          { value: 23, text: '3054204 PHLPS DREAMWEAR UTN NC MASK M-FRAME S' },
          { value: 24, text: '3054205 PHLPS DREAMWEAR UTN NC MASK M-FRAME M' },
          { value: 25, text: '3054206 PHLPS DREAMWEAR UTN NC MASK M-FRAME L' },
          { value: 26, text: '3054207 PHLPS DREAMWEAR UTN NC MASK M-FRAME MW' },
          { value: 27, text: '3045308 FP OPUS UTN SP MASK S/M/L' },
          { value: 28, text: '3045039 FP BREVIDA UTN NC MASK XS/S/M/L' },
          { value: 29, text: '3054212 PHLPS DREAMWEAR FF MASK M-FRAME S' },
          { value: 30, text: '3054213 PHLPS DREAMWEAR FF MASK M-FRAME M' },
          { value: 31, text: '3054214 PHLPS DREAMWEAR FF MASK M-FRAME L' },
          { value: 32, text: '3054215 PHLPS DREAMWEAR FF MASK M-FRAME MW' },
          { value: 33, text: '3053671 FP VITERA FF MASK S' },
          { value: 34, text: '3053672 FP VITERA FF MASK M' },
          { value: 35, text: '3053673 FP VITERA FF MASK L' },
          { value: 36, text: '3065961 FP EVORA COMPACT FF MASK EXTRA S' },
          { value: 37, text: '3065962 FP EVORA COMPACT FF MASK S/M' },
          { value: 38, text: '3065960 FP EVORA COMPACT FF MASK L' },
          { value: 39, text: '3066489 RM QUATTRO AIR FF MASK XS' },
          { value: 40, text: '3043904 RM QUATTRO AIR FF MASK S' },
          { value: 41, text: '3043907 RM QUATTRO AIR FF MASK FOR HER' },
          { value: 42, text: '3043906 RM QUATTRO AIR FF MASK L' },
          { value: 43, text: '3043905 RM QUATTRO AIR FF MASK M' }
        ]
      },
      approval: false
    };
  },
  computed: {
    currentDate() {
      return moment(new Date()).format('DD-MM-YYYY');
    },
    licenseId() {
      return this.osaDeliveryDetails.licenseId.toString();
    }
  },
  watch: {
    approval(value) {
      if (value) {
        this.animated = true;
        this.userDetailsBlock = true;
        setTimeout(() => {
          this.animated = false;
        }, 1000);
      } else {
        this.userDetailsBlock = false;
      }
    }
  },
  created() {
    this.saveOSADeliveryDetails({ key: 'licenseId', value: router.currentRoute.value.params.licenseId });
  },
  methods: {
    /**
     * @function moment - Function to convert datetime to required specific format
     */
    closeModal() {
      this.showCancelPopUp = false;
      this.showSuccessPopup = false;
    },
    moment(...args) {
      return moment(...args);
    },
    /**
     * @function goToPatientDashboard - Fuction to redirect prescriber to patient dashboard page
     */
    goToPatientDashboard() {
      router.push('/patientdashboard');
    },
    /**
     * @function cancelOSADelivery - Function to show cancel pop up
     */
    cancelOSADelivery() {
      this.showCancelPopUp = true;
    },
    /**
     * @function saveOSADeliveryDetails - Function to save the form details to the store
     */
    saveOSADeliveryDetails(OSADeliveryDetails) {
      this.osaDeliveryDetails[OSADeliveryDetails.key] = OSADeliveryDetails.value;
    },
    /**
     * @function saveOSADelivery - Fucntion to save the Delivery form details
     */
    saveOSADelivery() {
      this.saveButtonClicked = true;
      $validateAllComponents().then((result) => {
        if (!result) {
          return;
        }
        this.osaDeliveryDetails.deliveryDate = moment(this.osaDeliveryDetails.deliveryDate, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        );
        this.osaDeliveryDetails.device = this.deviceItem.text ? this.deviceItem.text : '';
        this.osaDeliveryDetails.maskType = this.maskItem.text ? this.maskItem.text : '';
        axios.post('Osa/SaveDeliveryNote', this.osaDeliveryDetails).then((response) => {
          if (response && response.data && response.data.Success) {
            this.showSuccessPopup = true;
          }
        });
      });
    },
    /**
     * @function handleDate - Function to handle / convert start date value as per requirement format
     */
    handleDate(key, value) {
      this.saveOSADeliveryDetails({
        key,
        value
      });
    },
    /**
     * @function change - Function to set/unset the final approval
     */
    change(key, value) {
      if (key === 'approval') {
        if (value) {
          this.animated = true;
          this.userDetailsBlock = true;
          setTimeout(() => {
            this.animated = false;
          }, 1000);
        } else {
          this.userDetailsBlock = false;
        }
      } else {
        this.saveOSADeliveryDetails({
          key,
          value
        });
      }
    },
    /**
     * @function saveInput - Function to save input value to the store
     */
    saveInput(event) {
      this.saveOSADeliveryDetails({
        key: event.target.id,
        value: event.target.value
      });
    },
    /**
     * @function onSelect - Function to trigger / set few functionality based on device select
     */
    onSelect(key) {
      if (key === 'device') {
        this.saveOSADeliveryDetails({ key: key, value: this.deviceItem.text });
      } else {
        this.saveOSADeliveryDetails({ key: key, value: this.maskItem.text });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery {
  :deep(.checkbox-group__label__inner) {
    line-height: 20px !important;
  }
  color: $grayish-very-dark;
  .ui.fluid.dropdown,
  .ui.selection.dropdown {
    min-height: 50px;
    padding: 16px 16px 16px 16px;
    border: 1px solid #999999;
    :deep(.dropdown.icon) {
      margin-top: -5px;
    }
  }
  &__block {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 0px 0px 30px 0px;
    &__header {
      background-color: $grayish-very-light;
      color: $grayish-very-dark;
      font-size: 18px;
      padding: 20px;
    }
    &__content {
      padding: 10px 10px 10px 10px;
    }
  }
  &__actions {
    display: flex;
    margin: 20px 0px 30px 0px;
    &__cancelDelivery {
      margin-right: 25px;
    }
    z-index: 99;
  }
  &__row {
    margin: 10px 0 10px 0;
    &__radio {
      display: inline-flex;
      span {
        margin: 10px 10px 0px 0px;
        float: left;
      }
    }
    td {
      padding-bottom: 20px;
    }
  }
  &__signature {
    margin: 0 auto;
    img {
      border: solid 2px $grayish-med-light;
      vertical-align: text-top;
      max-width: 350px !important;
      height: auto;
      width: 100%;
    }
  }
  a {
    color: $teal-darkest;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin-bottom: 14px;
  }
  legend {
    color: $grayish-super-dark;
    margin: 10px 0 10px 0;
  }

  #comments {
    width: 100%;
    min-height: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #999999;
    padding: 10px;
  }
}
</style>
