<template>
  <form>
    <v-select
      v-for="(filter, filterKey) in data"
      :key="filterKey"
      v-model="filter.AnswerId"
      :label="filter.Tekst"
      :error-message-hide="true"
      :initselected="prefillOption(filter)"
      :overwrite-value="prefillOption(filter)"
      @change="changeFilter(filter, $event)"
    >
      <option v-for="(option, optionKey) in filter.Antwoorden" :key="optionKey" :value="option.AnswerId">
        {{ option.AntwoordTekst }}
      </option>
    </v-select>
  </form>
</template>

<script>
import VSelect from '@/components/shared/form/SelectGroup';
import { nextTick } from 'vue';

export default {
  name: 'VFilters',
  components: {
    VSelect
  },
  props: {
    data: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    /**
     * @function changeFilter
     * @param {object} filter
     * @param {string} filter.AnswerId
     * @param {object} filter.Antwoorden
     * @param {string} filter.Antwoorden.AnswerId
     */
    changeFilter(filter, event) {
      // Wait for the next Vue tick because change event triggers before v-model value of filter.AnswerId is updated
      nextTick(() => {
        // Find the selected answer and add it as a property to the filter
        if (event.currentTarget.value) {
          filter.Answer = filter.Antwoorden.find((answer) => {
            return answer.AnswerId === event.currentTarget.value;
          });
          this.$emit('changeFilter', filter);
        }
      });
    },
    /**
     * @function changeFilter
     * @param {object} filter
     * @param {string} filter.ResultaatAnswers
     * @param {number} filter.ResultaatAnswers.AnswerId
     * @param {string} filter.ResultaatAnswers.Resultaat
     * @return {string} prefillOption
     */
    prefillOption(filter) {
      if (filter.ResultaatAnswers.length > 0 && filter.ResultaatAnswers[0].AnswerId) {
        return filter.ResultaatAnswers[0].AnswerId;
      } else {
        return '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(.form-group) {
  label {
    color: $gray-dark;
  }
  select {
    margin-bottom: 15px;
  }
}
</style>
