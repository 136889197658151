import axios from 'axios';
import { refreshShoppingCart } from './shoppingCartRefreshRequested$';

export function addIllnessBundleToShoppingCart(shoppingCart, illnessBundleId) {
  if (shoppingCart === null) {
    throw 'ShoppingCart is empty';
  }

  var command = {
      customerId: shoppingCart.customerId,
      illnessBundleId: illnessBundleId
  };

  return axios
    .post('ShoppingCart/AddIllnessBundleToShoppingCart', command)
    .then((response) => {
      refreshShoppingCart();
      return response;
    });
}
