<template>
  <div>
    <patient-header />
    <!-- Action buttons section -->
    <section class="container patient-dashboard-actions">
      <div v-if="!patientGegevens.IsArchived" class="row grid-justify-content-right actions">
        <v-button-dropdown
          v-if="ssoDetails && ssoDetails.length > 0 && !isUserDeviceManager"
          :options="ssoDetails"
          :button-props="{ primary: false, inverted: true, alt: true, md: true, hollow: true }"
          @on-select="goToCareProgram"
        >
          <component :is="'medicalBag'" class="patient-dashboard__medicalBag" />
          Zorgprogramma’s
        </v-button-dropdown>
        <v-button
          v-if="
            licenseOverviews &&
            licenseOverviews.hasActiveLicenses &&
            !patientGegevens.IsDeceased &&
            !isUserDeviceManager
          "
          tagelement="a"
          md
          hollow
          icon-left
          @click.prevent="$router.push('/catalog')"
        >
          <v-plus-circle-icon title="" />
          Nieuwe bestelling
        </v-button>
        <patient-transfer-button
          v-if="!isUserDeviceManager"
          :change-user-for-patient-transfer="changeUserForPatientTransferStatus"
          :show-transfer-button="getShowTransferButtonStatus()"
          @close="closePatientTransfer()"
        />
        <patient-follow :is-following="isFollowed" />
      </div>
    </section>
    <!-- Dashboard section for all user roles excpet device manager role -->
    <section v-if="!isUserDeviceManager" class="container patient-dashboard">
      <!-- Patient transfer/wlz status block-->
      <div v-if="getTrasferWlzBlockVisibility()" class="row">
        <div v-if="wlzStatus" class="row grid-align-center grid-padding callout">
          <div class="col-md-12 patient-dashboard__wlzStatus">
            Deze patiënt heeft een WLZ indicatie (
            <a
              target="_blank"
              href="https://www.rijksoverheid.nl/onderwerpen/verpleeghuizen-en-zorginstellingen/wet-langdurige-zorg-wlz"
              >Lees hier meer over WLZ
            </a>
            ). controleert u alstublieft de zwaarte van de indicatie in uw EPD. U kunt in de meeste gevallen gebruik
            maken van de reguliere aanvraagformulieren. Indien er sprake is van een indicatie met “verblijf én
            behandeling” dient u de artikelen te bestellen via
            <a target="_blank" href="http://www.medbis.nl">MedBIS</a> of kunt u, indien beschikbaar, gebruik maken van
            de “onvergoede” aanvraagformulieren (met een B ervoor).
          </div>
        </div>
        <div
          v-if="
            customerSecurityPolicySettings &&
            customerSecurityPolicySettings.transferredFrom.length > 0 &&
            !customerSecurityPolicySettings.isTransferredToDepartment
          "
        >
          <div class="row grid-align-center grid-padding patient-dashboard__transferStatus callout">
            <div class="col">
              Deze patiënt is overgedragen aan uw afdeling door
              {{ customerSecurityPolicySettings.transferredFrom[0].user.simpleName }}
            </div>
          </div>
        </div>
        <div v-if="customerSecurityPolicySettings && customerSecurityPolicySettings.isTransferredToDepartment">
          <div class="row grid-align-center grid-padding patient-dashboard__transferStatus callout">
            <div class="col">
              Deze patiënt is overgedragen aan afdeling {{ customerSecurityPolicySettings.transferredToDepartmentName }}
            </div>
            <div @click.prevent="showPatientTransferPopUp()">
              <v-edit class="patient-dashboard__transfer__icons" />
            </div>
            <div @click.prevent="showDeletePatientTransferPopUp()">
              <v-delete class="patient-dashboard__transfer__icons" />
            </div>
          </div>
          <v-overlay-large
            v-if="showDeletePatientTransfer"
            :title="'Patiëntenoverdracht intrekken?'"
            @close="closeOverlay"
          >
            <p>
              Weet u zeker dat u {{ patientGegevens.Voorletters }} , {{ patientGegevens.Achternaam }} die overgedragen
              is aan {{ customerSecurityPolicySettings.transferredTo.user.simpleName }} wilt intrekken?
            </p>
            <div class="row grid-justify-content-right">
              <v-button lg hollow transparent @click="closeOverlay"> Annuleren </v-button>
              <v-button class="btn btn--primary btn--lg" @click="deleteUserOfTransferredCustomer()">
                Intrekken
              </v-button>
            </div>
          </v-overlay-large>
        </div>
      </div>
      <!-- Patient licenses details block -->
      <div class="prescription row">
        <div class="col-xs-12 col-md-6">
          <v-heading :level="3"> Machtigingen </v-heading>
        </div>
        <div
          v-if="accountabilities.length > 0 && !patientGegevens.IsDeceased && !patientGegevens.IsArchived"
          class="col-xs-12 col-md-6"
        >
          <v-button-dropdown
            v-if="accountabilities.length < 6"
            :id="getDemoId('dropdown')"
            :options="accountabilities"
            :button-props="{ hollow: true, md: true, iconLeft: true }"
            class="prescription__button"
            @on-select="goToCreateAccountability"
          >
            <v-plus-circle-icon title="" />Nieuwe machtiging
          </v-button-dropdown>
          <div v-else class="patient-dashboard__newMatching">
            <!-- //-->
            <v-button
              :id="getDemoId('button')"
              tagelement="a"
              md
              primary
              hollow
              icon-left
              @click.prevent="goToNieuweMachtiging"
            >
              <v-plus-circle-icon title="" />
              Nieuwe machtiging
            </v-button>
          </div>
        </div>
        <!--Pop up -->
        <generic-modal v-if="showPopUp">
          <template #header> Patiëntinformatie niet compleet </template>
          <template #body>
            <p>
              Niet alle noodzakelijke patiëntinformatie aanwezig, ga naar “Patiëntgegevens wijzigen” om de gegevens aan
              te vullen
            </p>
          </template>
          <template #footer>
            <v-button tagelement="button" md primary @click="editPatientDetails"> Patiëntgegevens wijzigen </v-button>
          </template>
        </generic-modal>
      </div>
      <v-licenses
        v-if="licenseOverviews && licenseOverviews.items.length > 0"
        :is-archived="patientGegevens.IsArchived"
        :license-overviews="licenseOverviews.items"
        :enable-management="true"
        :display-status-icon="true"
        :patient-id="patientGegevens.PatientId"
      />

      <div
        v-if="licenseOverviews && !licenseOverviews.hasActiveLicenses"
        class="callout license-helper"
        :class="{ 'helper-margin': addMarginForButton }"
      >
        <div class="row">
          <small v-html="licenseOverviews.licenseHelperMessage.subMessage" />
        </div>
        {{ licenseOverviews.licenseHelperMessage.message }}
        <mds-navigation-link
          v-if="licenseOverviews.licenseHelperMessage.title != ''"
          :title="licenseOverviews.licenseHelperMessage.title"
          :text="licenseOverviews.licenseHelperMessage.title"
          current
        >
          <span slot="submenu">
            <mds-dropdown data-type="menu">
              <div slot="content">
                <mds-navigation-link
                  v-for="item in licenseOverviews.licenseHelperMessage.items"
                  :key="item"
                  href="#"
                  :text="
                    item.institutionName +
                    '&quot; | &quot;' +
                    item.departmentName +
                    '&quot; | &quot; ' +
                    item.treatmentArea
                  "
                  @click="departmentChange(item)"
                />
              </div>
            </mds-dropdown>
          </span>
        </mds-navigation-link>
      </div>
      <!-- Patient ordered history details block -->
      <v-patient-order-history
        :is-archived="patientGegevens.IsArchived"
        :is-active-license="
          licenseOverviews && (licenseOverviews.hasActiveLicenses || licenseOverviews.otherDeptLicense)
        "
      />
      <!-- Patient recently ordered details block -->
      <v-patient-recently-ordered-products
        :is-active-license="licenseOverviews && licenseOverviews.hasActiveLicenses"
        :patient-id="patientGegevens.PatientId"
      />
      <!-- OSA Details block -->
      <v-osa-details v-if="isShowOsaSectionFeatureEnabled" :osa-overview="osaOverviewDetails" />
      <!--Patient measurement details based on the portal -->
      <v-patient-measurements v-if="patientGegevens.ShowMeasureMoments && !isRespiratory" />
    </section>
    <!-- Dashboard section users with device manager role -->
    <section v-else class="container patient-dashboard">
      <div class="prescription row">
        <div class="col-xs-12 col-md-6">
          <v-heading :level="3"> Apparaten </v-heading>
        </div>
        <div class="col-xs-12 col-md-6 justify-content-end">
          <div>
            <v-button tagelement="a" md cta @click.prevent="$router.push('/apparaattoewijzen')">
              Apparaat toewijzen
            </v-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import VLicenses from '@/components/sections/licenses/Index';
import PatientTransferButton from '@/components/sections/patientTransfer/PatientTransferButton';
import PatientFollow from '@/components/sections/patient/PatientFollow';
import PatientHeader from '@/components/sections/patient/PatientHeader';
import VPatientRecentlyOrderedProducts from '@/components/sections/patientOrderHistory/PatientRecentlyOrderedProducts';
import VPatientOrderHistory from '@/components/sections/patientOrderHistory/PatientOrderHistory';
import VButtonDropdown from '@/components/shared/ButtonDropdown';
import VPlusCircleIcon from 'icons/PlusCircle';
import VPatientMeasurements from '@/components/sections/patientMeasurements/PatientMeasurements';
import VOsaDetails from '@/components/sections/osa/osaDetails';
import medicalBag from 'icons/MedicalBag';
import VEdit from 'icons/Pencil';
import VDelete from 'icons/Cancel';
import VOverlayLarge from '@/components/shared/OverlayLarge.vue';
import GenericModal from '@/components/shared/GenericModal';
import { map } from 'rxjs/operators';
import { pushDepartmentChangedEvent } from '@/components/sections/navigation/departmentChangedEventStream';
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore, mapActions } from 'vuex';

import {
  currentUserIdentity$,
  ccpSsoDetails$,
  licenseOverviews$,
  customerSecurityPolicySettings$,
  activePpwRecId$,
  activeCustomerId$,
  dispatchCommand,
  navigateToAccountability
} from '@/services';

export default {
  name: 'VPatientdashboard',
  components: {
    VLicenses,
    VPatientOrderHistory,
    PatientFollow,
    VButtonDropdown,
    PatientHeader,
    PatientTransferButton,
    VPlusCircleIcon,
    VPatientMeasurements,
    VPatientRecentlyOrderedProducts,
    VEdit,
    VDelete,
    VOverlayLarge,
    medicalBag,
    VOsaDetails,
    GenericModal
  },
  setup() {
    const store = useStore();
    const state = store.state;
    const activePpwRecId = ref(null);
    const activeCustomerId = ref(null);
    const customerSecurityPolicySettings = ref(null);
    const user = ref(null);
    const licenseSubscription = ref(null);
    const licenseOverviews = ref(null);
    const ssoDetails = ref(null);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const accountabilities = computed(
      () => store.getters['accountability/getAccountabilities']
    ); /* actually templates */
    const isFollowed = computed(() => store.getters['patient/getPatientFollowStatus']);
    const wlzStatus = computed(() => store.getters['patient/getWLZStatus']);

    const getCurrentPatient = () => store.dispatch('patient/getCurrentPatient');
    const fetchWLZStatus = () => store.dispatch('patient/fetchWLZStatus', state.patient.patientGegevens.PatientId);
    const fetchAccountabilities = () => store.dispatch('accountability/fetchAccountabilities');
    const fetchResults = () => store.dispatch('navigation/fetchResults');
    const setShowExtendLicensesBlock = (value) => store.commit('accountability/setShowExtendLicensesBlock', value);
    const startNewAccountability = (value) => store.dispatch('accountability/startNewAccountability', value);
    const selectedDepartmentChange = (value) => store.dispatch('login/selectedDepartmentChange', value);
    const fetchDepartmentResults = () => store.dispatch('login/fetchDepartmentResults');

    activePpwRecId$.subscribe((id) => {
      activePpwRecId.value = id;
    });

    activeCustomerId$.subscribe((id) => {
      activeCustomerId.value = id;
    });

    customerSecurityPolicySettings$.subscribe((settings) => {
      customerSecurityPolicySettings.value = settings;
    });

    currentUserIdentity$.subscribe((identity) => {
      user.value = identity;
    });

    // Subscribe when component is mounted
    onMounted(() => {
      licenseSubscription.value = licenseOverviews$.subscribe((overviews) => {
        licenseOverviews.value = overviews;
      });
    });

    // Unsubscribe when component is unmounted
    onBeforeUnmount(() => {
      if (licenseSubscription.value) {
        licenseSubscription.value.unsubscribe();
      }
    });

    ccpSsoDetails$
      .pipe(
        map((data) => {
          if (data === null) {
            return null;
          }

          return data.map((ssoProgram, index) => {
            return {
              ...ssoProgram,
              id: index,
              title: ssoProgram.treatmentArea
            };
          });
        })
      )
      .subscribe((details) => {
        ssoDetails.value = details;
      });

    return {
      state,
      patientGegevens,
      accountabilities,
      isFollowed,
      wlzStatus,
      getCurrentPatient,
      fetchWLZStatus,
      fetchAccountabilities,
      fetchResults,
      setShowExtendLicensesBlock,
      startNewAccountability,
      selectedDepartmentChange,
      fetchDepartmentResults,
      ...mapActions('patient', ['setEndpoints']),
      activePpwRecId,
      activeCustomerId,
      customerSecurityPolicySettings,
      user,
      licenseOverviews,
      ssoDetails
    };
  },
  data() {
    return {
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      filterCountDefault: 5,
      filterCount: null,
      changeUserForPatientTransferStatus: false,
      showDeletePatientTransfer: false,
      showPopUp: false,
      deviceHeader: ['', 'Omschrijving', 'Serienummer', 'Locatie', 'Onderhoudsstatus', 'Gepland onderhoud', ''],
      osaOverviewDetails: {}
    };
  },
  computed: {
    isUserDeviceManager() {
      return this.user.isDeviceManager;
    },
    transferredTo() {
      return (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.transferredTo) || null;
    },
    transferredFrom() {
      return (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.transferredFrom) || [];
    },
    transferredToDepartment() {
      return (
        (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.isTransferredToDepartment) || false
      );
    },
    isRespiratory() {
      return this.state.navigation.siteCode === 'MRT';
    },
    addMarginForButton() {
      return this.licenseOverviews && this.licenseOverviews.items.length > this.filterCountDefault;
    },
    isShowOsaSectionFeatureEnabled() {
      return (
        this.licenseOverviews &&
        this.licenseOverviews.hasOsaLicenses &&
        this.$featureFlag.showOsaSectionPatientDashboard
      );
    }
  },
  created() {
    this.filterCount = this.filterCountDefault;
    this.getCurrentPatient().then(() => {
      if (!this.isUserDeviceManager) {
        this.loadOsaOverviewDetails();
        this.fetchWLZStatus();
        this.fetchAccountabilities();
        this.fetchResults();
      }
    });
  },
  methods: {
    async loadOsaOverviewDetails() {
      await axios.get('osa/GetOsaOverview').then((response) => {
        if (response && response.data && response.data.Success) {
          this.osaOverviewDetails = response.data.OsaOverview;
        }
      });
    },
    /**
     * @function goToCreateAccountability - Redirecting prescirber to create accountability page
     * @param {string} - id
     */
    goToCreateAccountability(id) {
      let gender = this.patientGegevens.GeslachtId;
      let initial = this.patientGegevens.Voorletters;
      let lastName = this.patientGegevens.Achternaam;
      let dob = this.patientGegevens.GeboorteDatum;
      let policy = this.patientGegevens.Verzekering[0].Polisnummer;
      let payer = this.patientGegevens.Verzekering[0].Verzekeraar.Id;
      let address = this.patientGegevens.WoonAdres.AdresId;
      if (
        gender !== '' &&
        initial !== '' &&
        lastName !== '' &&
        dob !== '' &&
        policy !== '' &&
        payer !== '' &&
        address !== ''
      ) {
        this.setShowExtendLicensesBlock(false);
        this.startNewAccountability(id).then((response) => {
          navigateToAccountability(response.regular.accountabilityId);
        });
      } else {
        this.showPopUp = true;
      }
    },
    goToNieuweMachtiging() {
      this.showPopUp = true;
      let gender = this.patientGegevens.GeslachtId;
      let initial = this.patientGegevens.Voorletters;
      let lastName = this.patientGegevens.Achternaam;
      let dob = this.patientGegevens.GeboorteDatum;
      let policy = this.patientGegevens.Verzekering[0].Polisnummer;
      let payer = this.patientGegevens.Verzekering[0].Verzekeraar.Id;
      let address = this.patientGegevens.WoonAdres.AdresId;
      if (
        gender !== '' &&
        initial !== '' &&
        lastName !== '' &&
        dob !== '' &&
        policy !== '' &&
        payer !== '' &&
        address !== ''
      ) {
        router.push('/nieuwemachtiging');
      }
    },
    editPatientDetails(e) {
      this.showPopUp = false;
      e.preventDefault();
      this.setEndpoints('edit');
      var url = '/patientgegevens';
      router.push(url);
      return false;
    },
    goToCareProgram(id) {
      window.open(this.ssoDetails[id].url, '_blank');
    },
    getDemoId(type) {
      if (this.accountabilities.length < 6 && type === 'dropdown') {
        return 'licenseBtn';
      }
      if (this.accountabilities.length >= 6 && type === 'button') {
        return 'licenseBtn';
      }
      return '';
    },
    showPatientTransferPopUp() {
      this.changeUserForPatientTransferStatus = true;
    },
    closeOverlay() {
      this.showDeletePatientTransfer = false;
    },
    deleteUserOfTransferredCustomer() {
      dispatchCommand('UndoTransferredCustomer', {
        customerId: this.activeCustomerId,
        fromPpwRecId: this.activePpwRecId
      }).then(() => {
        this.closeOverlay();
      });
    },
    showDeletePatientTransferPopUp() {
      this.showDeletePatientTransfer = true;
    },
    getTrasferWlzBlockVisibility() {
      return this.wlzStatus || this.transferredTo || this.transferredFrom.length > 0 || this.transferredToDepartment;
    },
    getShowTransferButtonStatus() {
      return !this.transferredTo && this.transferredFrom.length === 0;
    },
    closePatientTransfer() {
      this.changeUserForPatientTransferStatus = false;
    },
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    departmentChange(item) {
      this.selectedDepartmentChange(item.ppwRecId).then((response) => {
        this.fetchDepartmentResults();
        this.fetchResults();
        pushDepartmentChangedEvent({});
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  &__view {
    padding: 0px !important;
  }
}
.patient-dashboard-actions {
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding-top: grid-gutter($breakpoint-key);
    }
  }
  .actions {
    :deep(.btn) {
      margin-left: 10px;
    }
  }
}
.patient-dashboard {
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding-top: grid-gutter($breakpoint-key);
    }
  }
  &__devices {
    table {
      table-layout: auto !important;
      margin-bottom: 25px;
    }
  }
  p {
    margin-top: 0px !important;
  }
  &__transfer {
    &__icons {
      fill: $teal_darkest;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  &__newMatching {
    position: relative;
    align-self: flex-end;
    z-index: 90;
  }
  .prescription {
    margin-top: 1rem; // remove this later
    &__button {
      @include max-screen(grid-breakpoint(md)) {
        margin-bottom: 1rem;
        align-self: flex-start;
      }
      @include min-screen(grid-breakpoint(md)) {
        align-self: flex-end;
      }
    }
  }
  .error-message {
    color: $red;
    font-weight: 700;
  }
  .callout {
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        padding-top: grid-gutter($breakpoint-key);
        padding-bottom: grid-gutter($breakpoint-key);
        margin-bottom: grid-gutter($breakpoint-key);
      }
    }

    background-color: $teal-lighter;
    justify-content: space-between;
  }

  .license-helper {
    padding-left: 24px;
    padding-right: 24px;
    small {
      color: #006983;
      a {
        color: $teal-darkest;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__medicalBag {
    margin: 0px 10px 0px 0px;
  }
  &__wlzStatus {
    display: inline;
    border-radius: 3px;
    a {
      color: $teal-darkest;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__transferStatus {
    border-radius: 3px;
    background-color: $white !important;
    border: solid 1px $gray-light;
  }

  .helper-margin {
    margin-top: 24px;
  }
}
</style>
