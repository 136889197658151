<template>
  <div class="pump-exchange-form">
    <div v-if="showReasonSelection" id="step1">
      <v-paragraph class="pump-exchange-form__heading"> Pomp wisselen </v-paragraph>
      <v-paragraph>Pomp met serienummer {{ data.serialNo }} wisselen.</v-paragraph>
      <div class="row">
        <div class="col-md-12">
          <legend>Reden waarom pomp gewisseld moet worden:</legend>
          <div class="form-group__radio">
            <input id="defect" name="type" type="radio" @change="checkExchangeReason" />
            <label for="defect"><span></span>Defect</label>
          </div>
          <div class="form-group__radio">
            <input id="incorrect" name="type" type="radio" @change="checkExchangeReason" />
            <label for="incorrect"><span></span>Foutieve aanmelding van pomp</label>
          </div>
          <div class="form-group__radio">
            <input id="maintenance" name="type" type="radio" @change="checkExchangeReason" />
            <label for="maintenance"><span></span>Onderhoud</label>
          </div>
          <div class="form-group__radio">
            <input id="otherReason" name="type" type="radio" @change="checkExchangeReason" />
            <label for="otherReason"><span></span>Overige redenen</label>
          </div>
        </div>
      </div>
      <div class="row grid-justify-content-right">
        <v-button md hollow transparent @click="cancelExchangePump"> Annuleren </v-button>
        <v-button md primary :disabled="!step1Validated" @click.once="gotoNextStep"> Volgende </v-button>
      </div>
    </div>
    <div v-else-if="showDefectFields || showTelephoneNumber || showOptionalRemark" id="step2">
      <v-paragraph class="pump-exchange-form__heading"> Pomp wisselen </v-paragraph>
      <div v-if="showDefectFields" class="row grid-margin">
        <div class="col-md-8">
          <v-input-date-picker
            ref="defectDate"
            type="date"
            name="defectDate"
            label="Vanaf welke dag is de pomp defect?*"
            autofocus
            custom-date-range
            @selected="handleDate"
          />
        </div>
      </div>
      <div v-if="showTelephoneNumber" class="row grid-margin">
        <div class="col-md-8">
          <v-input-group-simple-2
            id="TelephoneNumber"
            v-model:value="manualTelephoneNumber"
            initvalue=""
            label="Telefoonnummer voor retour"
            :rules="{ required: true, regex: /^0\d{9}$/ }"
          />
        </div>
      </div>
      <div v-if="showDefectFields" class="row grid-margin">
        <div class="col-md-8">
          <v-input-group-simple-2
            id="ErrorCode"
            v-model:value="errorCode"
            initvalue=""
            label="Foutcode van de pomp"
            :rules="{ required: false }"
          />
        </div>
      </div>
      <div v-if="showOptionalRemark" class="row pump-exchange-form__row_margin">
        <div class="col-md-10">
          <span class="pump-exchange-form__row_title">Opmerking</span>
        </div>
      </div>
      <div v-if="showOptionalRemark" class="row grid-margin">
        <div class="col-md-10">
          <textarea
            id="checkout-remarks"
            v-model="optionalRemark"
            class="pump-exchange-form__textarea"
            placeholder="Geef hier een toelichting."
            @input="setRemark($event.target.value)"
          />
        </div>
      </div>
      <div class="row grid-margin grid-justify-content-right">
        <v-button md hollow transparent @click="cancelExchangePump"> Annuleren </v-button>
        <v-button md primary @click="saveExchangePump"> Bevestig </v-button>
      </div>
    </div>
    <div v-else-if="showFinalPopup" id="step3">
      <div class="row">
        <v-paragraph class="pump-exchange-form__heading"> Bijna klaar… </v-paragraph>
      </div>
      <div class="row">
        <span>
          De pomp met serienummer: {{ data.serialNo }} is ontkoppeld. U kunt nu direct een andere pomp toewijzen aan de
          patient.
        </span>
      </div>
      <div class="row grid-justify-content-right">
        <v-button tagelement="button" md primary @click="gotoPumpAllocation"> Pomp toewijzen </v-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import { useForm } from 'vee-validate';
import { activePpwRecId$ } from '@/services';
import { ref } from 'vue';

export default {
  name: 'VPumpexchangeform',
  components: {
    VInputDatePicker
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const activePpwRecId = ref(null);
    activePpwRecId$.subscribe((value) => {
      activePpwRecId.value = value;
    });
    return {
      activePpwRecId
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      isExchange: false,
      pumpData: {},
      showReasonSelection: true,
      defectDate: new Date(),
      manualTelephoneNumber: '',
      errorCode: '',
      optionalRemark: '',
      showDefectFields: false,
      showTelephoneNumber: false,
      showOptionalRemark: false,
      showFinalPopup: false,
      step1Validated: false,
      exchangeType: ''
    };
  },
  computed: {},
  created() {
    this.pumpData = {
      DefectDate: '',
      TelephoneNumber: '',
      ErrorCode: '',
      OptionalRemark: '',
      SerialNo: this.data.serialNo,
      PumpID: this.data.equipmentID,
      PumpExchangeType: this.exchangeType,
      PpwRecId: this.activePpwRecId
    };
  },
  methods: {
    checkExchangeReason(e) {
      let checkedType = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      this.exchangeType = checkedType;
      if (checkedType === 'defect') {
        this.showDefectFields = true;
        this.showTelephoneNumber = true;
        this.showOptionalRemark = true;
      } else if (checkedType === 'maintenance') {
        this.showDefectFields = false;
        this.showTelephoneNumber = true;
        this.showOptionalRemark = true;
      } else {
        this.showDefectFields = false;
        this.showTelephoneNumber = false;
        this.showOptionalRemark = true;
      }
      this.step1Validated = true;
    },
    gotoNextStep() {
      this.showReasonSelection = false;
    },
    cancelExchangePump() {
      this.$emit('closeModal');
    },
    handleDate(key, date) {
      this.defectDate = date;
    },
    setRemark(remark) {
      this.optionalRemark = remark;
    },
    saveExchangePump() {
      // Set PumpData
      this.pumpData['DefectDate'] = this.defectDate;
      this.pumpData['ErrorCode'] = this.errorCode;
      this.pumpData['TelephoneNumber'] = this.manualTelephoneNumber;
      this.pumpData['OptionalRemark'] = this.optionalRemark;
      this.pumpData['ExchangeType'] = this.exchangeType;
      this.validateAllFields();
    },
    async validateAllFields() {
      const result = await this.validate();
      if (result.valid) {
        if (this.showDefectFields || this.showTelephoneNumber) {
          axios.post('Equipment/EmailToExchangePump', this.pumpData).then((response) => {
            this.showTelephoneNumber = false;
            this.showDefectFields = false;
            this.showOptionalRemark = false;
            this.showReasonSelection = false;
            this.showFinalPopup = true;
          });
        } else if (this.showOptionalRemark) {
          this.showOptionalRemark = false;
          this.showReasonSelection = false;
          this.showFinalPopup = true;
        }
      }
    },
    gotoPumpAllocation() {
      this.$emit('pumpExchangeTypeValue', this.exchangeType);
    }
  }
};
</script>
<style land="css">
.pump-exchange-form .form-group.form-group--error input {
  border: 2px solid red;
}
.pump-exchange-form .form-group {
  padding: 0px;
}
.pump-exchange-form .form-group-simple label {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
}
.pump-exchange-form .form-group--focused label {
  font-size: 12px;
  color: #484848;
  transform: none;
  width: auto;
  max-width: 100%;
}
.pump-exchange-form .form-group--hasvalue label {
  font-size: 12px;
  color: #484848;
  transform: none;
  width: auto;
  max-width: 100%;
}
.pump-exchange-form .form-group input {
  border-style: none;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px 0px 10px 15px;
  color: black;
  height: auto;
}
.pump-exchange-form .form-group .form-group__details:before,
.pump-exchange-form .form-group .form-group__details:after {
  background-color: transparent;
}
</style>
<style lang="scss" scoped>
.pump-exchange-form {
  legend {
    font-size: map-get(map-get($type-settings, 0), font-size);
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__heading {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: map-get(map-get($type-settings, 2), font-size);
    line-height: 1.2;
    color: $black;
  }

  &__row_margin {
    margin-bottom: 8px;
  }

  &__row_title {
    font-size: map-get(map-get($type-settings, -1), font-size);
    font-weight: normal;
    color: $gray-dark;
  }

  &__textarea {
    padding: 0px;
    height: 140px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid $gray;
    width: 100%;
    padding: 10px;
    resize: none;
  }
}
</style>
