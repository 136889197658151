<template>
  <form class="search__form" @submit.prevent="validateBeforeSubmit">
    <fieldset>
      <div class="search__wrapper" @submit="validateBeforeSubmit">
        <div class="row grid-margin grid-align-start">
          <div class="col-xs-12 col-md-2 search-selector">
            <label class="selectorLabel">Zoeken op</label>
            <m-select
              id="searchOptions"
              name="searchOptions"
              label=""
              :value="activeSearchMethod"
              :options="searchOptions"
              @change-option="setSearchMethod"
            />
          </div>
          <div v-if="!isSearchMethod(searchMethods.searchMyPatients)" id="step1" class="col-xs-12 col-md-3">
            <v-input-date
              id="dateOfBirth"
              ref="dateOfBirth"
              type="date"
              name="dateOfBirth"
              label="Geboortedatum"
              @update-validation="(value) => (isDateOfBirthValidated = value)"
              @input="nextInputCheck"
              :rules="{
                date: true
              }"
            />
          </div>
          <div
            v-if="isSearchMethod(searchMethods.searchMyPatients)"
            class="col-xs-12 col-md-6 search__form__patientSelect"
          >
            <label>Mijn patiënten</label>
            <model-list-select
              v-model="selectedPatient"
              placeholder="Zoek op naam, BSN, polisnummer, postcode of geboortedatum"
              :list="patientSearch"
              :option-text="searchText"
              option-value="patientId"
              :custom-text="customOptionText"
              @searchchange="updateSearchText"
            />
          </div>
          <div v-if="isSearchMethod(searchMethods.policynumber)" class="col-xs-12 col-md-3">
            <v-input-PolicyNumber
              id="policynumber"
              v-model="policynumber"
              ref="policynumber"
              label="Polisnummer"
              @update-validation="(value) => (isPolicyNumberValidated = value)"
              @input="nextInputCheck"
              :rules="{
                required: true,
                numeric: true
              }"
            />
          </div>
          <div v-if="isSearchMethod(searchMethods.bsn)" id="step2" class="col-xs-12 col-md-3">
            <v-input-BSN
              id="bsn"
              ref="bsn"
              v-model="bsnNumber"
              name="bsn"
              label="BSN"
              :rules="{
                required: true,
                min: 9,
                max: 9,
                isBsn: true
              }"
              @update-validation="(value) => (isBSNValidated = value)"
              @input="nextInputCheck"
            />
          </div>
          <div v-if="isSearchMethod(searchMethods.postalcode)" class="col-xs-12 col-md-2">
            <v-input-postalcode
              id="postalcode"
              ref="postalcode"
              v-model="postalcode"
              name="postalcode"
              label="Postcode"
              :rules="{
                required: true,
                regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
              }"
              @update-validation="(value) => (isPostalcodeValidated = value)"
              @input="nextInputCheck"
            />
          </div>
          <div v-if="isSearchMethod(searchMethods.postalcode)" class="col-xs-12 col-md-1">
            <v-input-housenumber
              ref="housenumber"
              id="housenumber"
              v-model="housenumber"
              name="housenumber"
              label="Huisnr."
              :rules="{
                required: true,
                numeric: true
              }"
              @update-validation="(value) => (isHousenumberValidated = value)"
              @input="nextInputCheck"
            />
          </div>
          <div v-if="isSearchMethod(searchMethods.postalcode)" class="col-xs-12 col-md-1">
            <v-input-group-simple
              label="Toev."
              inputid="housenumberAddition"
              placeholder="C"
              :rule="{ required: false }"
            />
          </div>
          <div class="col actions">
            <v-button v-if="!isSearchMethod(searchMethods.searchMyPatients)" id="step3" cta md class="searchBtn">
              <v-search-icon />
            </v-button>
            <m-button
              v-if="showCreateNewPatientButton"
              hollow
              icon-name="right-arrow"
              icon-position="end"
              class="computeActionBtn"
              @click="createNewPatient"
            >
              <template v-slot:text>Nieuwe patiënt aanmelden</template>
            </m-button>
          </div>
          <!-- Commented the mypatient search until design completed, will be reverted in future stories
        <div class="grid-align-start" v-else>
          <v-button cta md class="searchBtn" :class="{'btn--disabled': !searchText, 'computeActionBtn': true}" @click.prevent="updateSearchText(searchText)">
            <component :is="'VSearchIcon'" />
          </v-button>
        </div> -->
        </div>
      </div>
    </fieldset>
    <!--Pop up to show Suggention for search -->
    <generic-modal v-if="showPopUp">
      <template #header> Let op! </template>
      <template #body>
        <p>
          Zoeken op BSN geeft betere resultaten. Wanneer 'Zoek op postcode' geen resultaat geeft, raden wij u aan om
          alsnog een patiënt te selecteren via 'Zoek op BSN'.
        </p>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md primary transparent @click.prevent="setSearchMethod(searchMethods.bsn)">
          Zoek op BSN
        </v-button>
        <v-button tagelement="button" md primary @click.prevent="closeModal"> Zoek op postcode </v-button>
      </template>
    </generic-modal>
    <!--Pop up to show validation messages -->
    <generic-modal v-if="showValidationPopUp">
      <template #header> Gegevens niet compleet </template>
      <template #body>
        <p>
          Controleer of u de juiste geboortedatum in een DD-MM-JJJJ formaat met BSN-nummer (8 of 9 cijfers) of de
          postcode (4 cijfers en 2 letters) en huisnummer hebt ingevuld.
        </p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click.prevent="closeModal"> Ga verder </v-button>
      </template>
    </generic-modal>
  </form>
</template>

<script>
import { useStore, mapActions } from 'vuex';
import { computed, ref, onMounted, nextTick, watch } from 'vue';
import VInputDate from '@/components/shared/form/simple/InputDate';
import VInputBSN from '@/components/shared/form/simple/InputBSN';
import VInputPostalcode from '@/components/shared/form/simple/InputPostalcode';
import VInputHousenumber from '@/components/shared/form/simple/InputHousenumber';
import VInputPolicyNumber from '@/components/shared/form/simple/InputPolicyNumber';
import GenericModal from '@/components/shared/GenericModal';
import VSearchIcon from 'icons/Magnify';
import { ModelListSelect } from 'vue-search-select';
import moment from 'moment';
import router from '@/router';
import { customers$ } from '@/services';
import { useForm } from 'vee-validate';

export default {
  name: 'VSearchForm',
  components: {
    VInputDate,
    VInputBSN,
    VInputPostalcode,
    VInputHousenumber,
    VInputPolicyNumber,
    GenericModal,
    VSearchIcon,
    ModelListSelect
  },
  props: {
    showCreateNewPatientButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();
    const isSearchEnabled = ref(false);
    const isPostalcodeActive = computed(() => store.getters['search/getIsPostalcodeActive']);
    const results = computed(() => store.getters['search/getResults']);
    const allCustomers = ref(null);
    const setNoPatientFound = (value) => store.commit('search/setNoPatientFound', value);
    const setIsPostalcodeActive = (value) => store.commit('search/setIsPostalcodeActive', value);
    const setShowOneChildStatus = (value) => store.commit('navigation/setShowOneChildStatus', value);

    customers$.subscribe((value) => (allCustomers.value = value));

    return {
      setShowOneChildStatus,
      isPostalcodeActive,
      results,
      setNoPatientFound,
      setIsPostalcodeActive,
      allCustomers,
      isSearchEnabled,
      ...mapActions('patient', ['setEndpoints', 'setActivePatient'])
    };
  },
  data() {
    const { validate } = useForm();
    var searchMethods = {
      bsn: 'BSN',
      searchMyPatients: 'Mijn patiënten',
      policynumber: 'Polisnummer',
      postalcode: 'Postcode'
    };

    return {
      validate,
      searchMethods: searchMethods,
      activeSearchMethod: searchMethods.bsn,
      isDateOfBirthValidated: false,
      isBSNValidated: false,
      isPostalcodeValidated: false,
      isHousenumberValidated: false,
      isPolicyNumberValidated: false,
      searchHeight: 'auto',
      showPopUp: false,
      showValidationPopUp: false,
      searchOptions: ['BSN', 'Mijn patiënten', 'Polisnummer', 'Postcode'],
      searchText: '',
      patientSearch: [],
      selectedPatient: {},
      housenumber: null,
      policynumber: null,
      bsnNumber: null,
      postalcode: null
    };
  },
  computed: {},
  watch: {
    searchText(value) {
      this.$emit('showSearchAlert', false);
      let searchData = [];
      if (value.length > 1) {
        this.patientSearch = this.allCustomers;
      } else {
        this.patientSearch = [];
      }
      if (this.patientSearch && this.patientSearch.length > 0) {
        this.patientSearch.forEach((option) => {
          searchData.push(this.customOptionText(option));
        });
        let filteredOptions = searchData.filter((option) => option.toLowerCase().includes(value));
        if (filteredOptions && filteredOptions.length === 0) {
          this.$emit('showSearchAlert', true);
        } else {
          this.$emit('showSearchAlert', false);
        }
      }
    },
    selectedPatient(value) {
      if (value && value.patientId) {
        this.setActivePatient(value.patientId).then(() => {
          this.setShowOneChildStatus(false);
          router.push('/patientdashboard');
        });
      }
    },
    activeSearchMethod(value) {
      if (value !== this.searchMethods.searchMyPatients) {
        this.$emit('showSearchAlert', false);
      }
    }
  },
  created() {
    this.setSearchMethod(this.searchMethods.bsn);
  },
  unmounted() {
    this.setIsPostalcodeActive(true);
  },
  methods: {
    createNewPatient() {
      this.setEndpoints('new').then(() => {
        this.setShowOneChildStatus(false);
        router.push('/patientgegevens');
      });
    },
    customOptionText(option) {
      const keys = [
        'lastName',
        'initial',
        'tussenvoegsel',
        'dateOfBirth',
        'bsn',
        'postalCode',
        'city',
        'policyNumber',
        'customerNumber'
      ];
      let customOption = '';
      keys.forEach((key) => {
        if (option[key]) {
          if (['lastName', 'initial', 'tussenvoegsel'].includes(key)) {
            customOption =
              key === 'lastName' ? customOption + `${option[key]}` + ', ' : customOption + `${option[key]}`;
          } else if (key === 'dateOfBirth') {
            customOption = customOption + '|' + moment(`${option[key]}`, 'YYYY-MM-DD').format('DD-MM-YYYY');
          } else {
            customOption = customOption + '|' + `${option[key]}`;
          }
        }
      });
      return customOption;
    },
    closeModal() {
      this.showPopUp = false;
      this.showValidationPopUp = false;
    },
    async validateBeforeSubmit(e) {
      e.preventDefault();
      if (this.isSearchMethod(this.searchMethods.searchMyPatients)) {
        return;
      }
      // validate all the fields
      const formValid = await this.isFormValid();
      if (formValid) {
        this.$emit('formValidated', e.currentTarget.elements);
      } else {
        this.showValidationPopUp = true;
      }
    },
    setSearchMethod(searchMethod) {
      this.isSearchEnabled = false;
      if (this.activeSearchMethod === searchMethod) {
        return;
      }
      this.setNoPatientFound(false);
      this.activeSearchMethod = searchMethod;
      var isPostalcodeActive = this.activeSearchMethod === this.searchMethods.postalcode;
      this.setIsPostalcodeActive(isPostalcodeActive);
      this.showPopUp = isPostalcodeActive;
    },
    isSearchMethod(searchMethod) {
      return this.activeSearchMethod === searchMethod;
    },
    async nextInputCheck() {
      if (this.isSearchMethod(this.searchMethods.bsn)) {
        this.isSearchEnabled = this.isBSNValidated && this.isDateOfBirthValidated;
        return this.isSearchEnabled;
      } else if (this.isSearchMethod(this.searchMethods.postalcode)) {
        return (this.isSearchEnabled =
          this.isDateOfBirthValidated && this.isPostalcodeValidated && this.isHousenumberValidated);
      } else if (this.isSearchMethod(this.searchMethods.policynumber)) {
        return (this.isSearchEnabled = this.isDateOfBirthValidated && this.isPolicyNumberValidated);
      }
      return false;
    },
    nextInputPostalcode() {
      this.$refs.housenumber.focus();
    },
    async isFormValid() {
      const result = await this.validate();
      if (result.valid) {
        return true;
      } else {
        return false;
      }
    },
    updateSearchText(searchText) {
      if (this.isSearchMethod(this.searchMethods.searchMyPatients) && searchText) {
        this.searchText = searchText;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search__form {
  .selectorLabel {
    margin-bottom: 8px;
    line-height: normal;
  }
  .form-group-simple,
  .form-group-simple--completed {
    :deep(input) {
      color: $teal-darkest !important;
      height: 49px;
    }
    :deep(label) {
      color: $teal-darkest;
      margin-bottom: 15px;
    }
  }
  &__patientSelect {
    .ui.fluid.dropdown {
      height: 49px;
      line-height: line-height(0);
      color: $teal-darkest;
      border: solid 1px;
      border-color: $gray;
      border-radius: 3px;
      transition: opacity 0.2s ease-in-out;
      box-sizing: border-box;
      :deep(.text) {
        overflow: hidden !important;
        display: block;
        white-space: nowrap;
      }
    }
  }
  .actions {
    display: inline;
    margin-top: 34px;
  }
  .searchBtn {
    min-height: 48.5px;
    margin-right: 24px;
    float: left;
  }
  .computeActionBtn {
    float: left;
  }
  .search-selector {
    position: relative;
    :deep(.material-design-icon) {
      display: none;
    }
    .form-group {
      padding: 0;
      width: 100%;
      :deep(.form-group__details) {
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }

      :deep(select) {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        min-height: 50px;
        line-height: 1.5rem;
        color: $teal-darkest;
        padding-left: 10px;
        border: solid 1px;
        border-color: $gray;
        border-radius: 3px;
        transition: opacity 0.2s ease-in-out;
        box-sizing: border-box;
      }
    }
  }
  .btn {
    @include max-screen(grid-breakpoint(md)) {
      @include btn-lq;
    }
  }
  &__details {
    font-size: 16px;
    margin: 10px 0px 25px 0px;
    line-height: 1.5;
    color: $gray-darkest;
  }
  &__actions {
    margin-top: 20px;
    .backToBsn {
      border: none !important;
      padding-left: 0px;
    }
  }
  .btn--secondary {
    @include min-screen(grid-breakpoint(md)) {
      margin-top: 32px;
    }
  }
  .btn--link {
    align-self: flex-start;
  }
  fieldset {
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    legend {
      font-weight: 300;
      font-size: type-scale(5);
      margin-bottom: line-height(5);
      color: $white;
    }
    label {
      font-size: type-scale(0);
      color: $teal-darkest;
      font-weight: $form-group-simple-label-font-weight;
      margin-bottom: 15px;
      line-height: normal;
    }
  }
}
</style>
