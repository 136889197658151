<template>
  <div class="checkoutProduct" :class="{ relationalProduct: showRelationalProduct }">
    <!-- Alternative product card section -->
    <v-product-card
      id="altertnateProduct"
      v-if="showRelationalProduct && relationalProduct"
      :product="relationalProduct"
      :atpDetails="relationshipATPDetails"
      relational-product
      @add-relational-product="addRelationalProduct"
    ></v-product-card>
    <!-- Actual product card section -->
    <v-product-card
      id="availableProduct"
      :product="product"
      :available="!showRelationalProduct"
      :atpDetails="ATPDetails"
      @update="handleShoppingCartItemUpdate"
      @remove="handleShoppingCartItemRemove"
      @atp="getATPCheck"
    ></v-product-card>
  </div>
</template>

<script>
import VProductCard from './ProductCard';
import store from '@/store';

export default {
  name: 'CheckoutProduct',
  components: {
    VProductCard
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showRelationalProduct: false,
      ATPDetails: {},
      relationshipATPDetails: null,
      relationalProduct: null
    };
  },
  watch: {
    showRelationalProduct(val) {
      if (val) {
        this.findAvailableRelationalProduct();
      }
    }
  },
  created() {
    this.getATPCheck(this.product);
  },
  methods: {
    handleShoppingCartItemUpdate(item) {
      this.$emit('update', item);
    },
    handleShoppingCartItemRemove(item) {
      this.$emit('remove', item);
    },
    getATPCheck(product) {
      this.showRelationalProduct = false;
      this.relationalProduct = null;

      store
        .dispatch('cart/fetchATPDetail', { productId: product.productId, quantity: product.quantity })
        .then((response) => {
          if (response && response.data && response.data.ATPResponse) {
            this.showRelationalProduct = response.data.ATPResponse.ATPCheckColorCode === '7';
            this.ATPDetails = response.data.ATPResponse;
          }
        });
    },
    addRelationalProduct() {
      this.$emit('add', { productId: this.relationalProduct.productId, count: this.product.quantity });
      this.handleShoppingCartItemRemove(this.product);
    },
    async findAvailableRelationalProduct() {
      if (!this.product.relationalProducts) {
        return;
      }
      let productQuantity = this.product.quantity;
      let availableRelationalProduct = null;
      for (let relation of this.product.relationalProducts) {
        let atpCheck = await store.dispatch('cart/fetchATPDetail', {
          productId: relation.productId,
          quantity: productQuantity
        });
        if (!atpCheck || !atpCheck.data || !atpCheck.data.Success) {
          continue;
        }

        // not showing products with unknown delivery time as alternatives
        let result = atpCheck.data.ATPResponse.ATPCheckColorCode;
        if (result === '7') {
          continue;
        }

        // found first available alternative, no need to continue
        if (result === '1' || result === '2') {
          this.relationshipATPDetails = atpCheck.data.ATPResponse;
          availableRelationalProduct = relation;
          break;
        }

        if (
          !this.relationshipATPDetails ||
          (this.relationshipATPDetails && result < this.relationshipATPDetails.ATPCheckColorCode)
        ) {
          this.relationshipATPDetails = atpCheck.data.ATPResponse;
          availableRelationalProduct = relation;
        }
      }
      this.relationalProduct = availableRelationalProduct;
    }
  }
};
</script>

<style lang="scss" scoped>
.relationalProduct {
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  #availableProduct {
    :deep(.product) {
      border-bottom: none;
    }
  }
}
</style>
