<template>
  <div
    class="checkbox-group"
    :class="{
      'checkbox-group--error': error
    }"
  >
    <div class="checkbox-group__items">
      <input
        :id="inputid"
        v-model="value"
        class="checkbox-group__checkbox"
        :value="value"
        :name="inputid"
        type="checkbox"
        @change="change"
        @focus="focus"
        @blur="blur"
      />
      <label class="checkbox-group__label" :for="inputid">
        <span />
        <div class="checkbox-group__label__inner">
          {{ label }}<span v-if="rule.required && label" class="asterisk"> *</span>
          <slot />
        </div>
      </label>
      <v-check-icon class="checkbox-group__icon" />
    </div>
    <div class="checkbox-group__details">
      <div class="checkbox-group__messages">
        <div v-show="error" class="checkbox-group__error">* Dit veld is verplicht.</div>
      </div>
    </div>
  </div>
</template>

<script>
import VCheckIcon from 'icons/Check';

export default {
  name: 'VCheckboxGroup',
  components: {
    VCheckIcon
  },
  props: {
    label: {
      default: '',
      type: String
    },
    inputid: {
      required: true,
      type: String
    },
    initvalue: {
      default: false,
      type: Boolean
    },
    rule: {
      default: () => ({}),
      type: Object
    },
    error: {
      default: false,
      type: Boolean
    },
    errorMessage: {
      default: 'Error',
      type: String
    },
    overwriteValue: Boolean
  },
  data() {
    return {
      value: this.initvalue
    };
  },
  watch: {
    overwriteValue(newValue) {
      this.value = newValue;
    }
  },
  methods: {
    change(event) {
      this.$emit('update', event.target.checked);
    },
    focus() {
      this.isFocused = true;
    },
    blur() {
      this.isFocused = false;
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-group {
  $root: &;

  #{$root}__items {
    position: relative;
  }

  #{$root}__checkbox {
    width: 1.3em;
    margin: 0;
    padding: 0;
    display: none;
    opacity: 0;

    + .checkbox-group__label {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      line-height: 1.5em;
      color: $gray-dark;
      cursor: pointer;

      > span {
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin: 0px 5px 0 0;
        border: 2px solid $gray;
        border-radius: 3px;
        background: transparent;
        flex-shrink: 0;

        &:before {
          display: block;
          width: 1em;
          color: $white;
          font-size: 1em;
          line-height: 1em;
          text-align: center;
          margin: 1px 0 0 2px;
          text-shadow: 0 0 0.0714em rgba(0, 0, 0, 0.05);
          font-weight: bold;
        }
      }
    }

    &:checked + .checkbox-group__label,
    &[value='true'] + .checkbox-group__label {
      color: $gray-darkest;
      > span {
        background: $teal-darkest;
        border: 2px solid transparent;
      }
    }

    &:checked + .checkbox-group__label + .checkbox-group__icon,
    &[value='true'] + .checkbox-group__label + .checkbox-group__icon {
      fill: $white;
    }
  }

  #{$root}__label__inner {
    .asterisk {
      color: $red;
    }
  }

  &.checkbox-group--error {
    flex: 1 0;
    color: $form-group-error-color;
    transition: $form-group-error-transition;

    .checkbox-group__error {
      margin-left: 27px;
      font-size: $form-group-details-font-size;
      min-height: 26px;
      border-top: 1px solid $form-group-error-color;
      padding: 5px 0px 5px 0px;
      flex: 1 0;
      color: $form-group-error-color;
      transition: $form-group-error-transition;
    }
  }

  &__icon {
    position: absolute;
    top: 0px;
    left: 0;
    pointer-events: none;
    fill: transparent;
    height: 21px;
    :deep(svg) {
      padding: 0px 6px 4px 2px;
    }
    // main.scss contains global styling of icon svg
  }
}
</style>
