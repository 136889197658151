<template>
  <div class="dosage">
    <label :for="inputid" class="dosage__label">{{ label }}</label>
    <select :id="inputid" v-model="value" class="dosage__select">
      <option v-for="(option, optionKey) in options" :key="optionKey" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'VDosageSelect',
  props: {
    label: {
      default: '',
      type: String
    },
    inputid: {
      required: true,
      type: String
    },
    initialvalue: {
      type: [String, Number]
    },
    options: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      value: 1
    };
  },
  created() {
    this.value = this.initialvalue;
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.dosage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $gray-darkest;
  &__label {
    margin-right: 10px;
  }
}
</style>
