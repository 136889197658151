<template>
  <tbody class="license-row" :class="[{ 'is-expanded': isExpanded }, getStatusClassName()]">
    <tr
      ref="row"
      class="license-row__data"
      :class="{ 'license-row__clickable': isManagementEnabled }"
      @click="toggle(isManagementEnabled)"
    >
      <td v-if="displayStatusIcon" class="license-row__cell">
        <component :is="getStatusIcon(data.status)" :class="getStatusClassName()" />
      </td>
      <td v-else />
      <td v-if="data.trialLicense" class="license-row__cell">
        <div class="license-row__cell__trialLicense1">
          <div class="license-row__cell__trialLicense2">PROEFPLAATSING</div>
        </div>
      </td>
      <td v-else class="license-row__cell" />
      <td class="license-row__cell">
        <ul class="license-row__cell__type">
          <li v-for="(license, index) in data.licenses" :key="index" :class="getStatusClassName()">
            {{ license.therapyName }}
          </li>
        </ul>
      </td>
      <td class="license-row__cell">
        <ul class="license-row__cell__product-group">
          <li v-for="(license, index) in data.licenses" :key="index" :class="getStatusClassName()">
            {{ license.illnessDescription }}
          </li>
        </ul>
      </td>
      <td v-if="data.trialLicense" class="license-row__cell" :class="getStatusClassName()">-</td>
      <td v-else class="license-row__cell" :class="getStatusClassName()">
        {{ $formatDate(data.therapyStartDate) || $formatDate(data.startDate) }}
      </td>
      <td v-if="data.endDate !== null" class="license-row__cell" :class="getStatusClassName()">
        {{ $formatDate(data.endDate) }}
      </td>
      <td v-else class="license-row__cell license-row__center" :class="getStatusClassName()">-</td>
      <td v-if="isManagementEnabled" class="license-row__cell justify-content-end">
        <v-button v-if="!isExpanded" primary md @click.stop="toggle(isManagementEnabled)"> Toon meer </v-button>
        <v-button v-else primary md data-icon="cart" @click.stop="toggle(isManagementEnabled)"> Toon minder </v-button>
      </td>
      <td v-else />
    </tr>
    <tr v-show="isExpanded" class="license-row__details">
      <td class="license-row__cell" :colspan="data.trialLicense ? '' : '2'" />
      <td class="license-row__cell" :colspan="data.trialLicense ? '6' : '5'">
        <p ref="prescriber" class="license-row__cell__prescriber" :class="getStatusClassName()">
          Aangemaakt door <span class="license-row__cell__prescriber__author">{{ data.prescriber }}</span>
        </p>
        <div class="license-row__cell__actions">
          <!-- restart button //-->
          <v-button v-if="data.isRestartable" md primary icon-left hollow @click="editLicense()">
            <v-edit-icon />
            Herstarten
          </v-button>

          <!-- Edit button //-->
          <v-button v-if="data.isEditable && !isArchived" md primary icon-left hollow @click="editLicense()">
            <v-edit-icon />
            {{ getIsEditableButtonText() }}
          </v-button>

          <!-- Prolong button //-->
          <v-button
            md
            primary
            iconLeft
            hollow
            @click.prevent="extendLicense()"
            v-if="data.isNutritionLicense && !data.isQuestionSetChanged"
          >
            <v-calendar-icon />
            Verlengen
          </v-button>

          <!-- Stop button //-->
          <v-button
            md
            primary
            iconLeft
            hollow
            :disabled="data.hideStopLicenseButton"
            v-if="data.isStoppable && !data.hideStopLicenseButton"
            @click.prevent="
              $emit('stop', { licenseData: data, licenseId: data.licenses[0].id, equipments: data.equipments })
            "
          >
            <v-stop-icon />
            Stopzetten
          </v-button>

          <!-- Documenten button //-->
          <v-button md primary icon-left hollow @click="loadPdfDetails()">
            <v-download-icon />
            Documenten
          </v-button>

          <!-- OSA / POSA related buttons //-->
          <v-button v-if="data.isApproved === true" md hollow disabled> Akkoordverklaring verzonden </v-button>

          <v-button v-else-if="data.isReadyForApproval === true" md primary @click.prevent="goToApproval()">
            Rond de Akkoordverklaring af
          </v-button>

          <v-button v-if="data.showMedication" md primary icon-left hollow @click.prevent="goToMedication()">
            <v-pill-icon />
            Medicatiewijziging doorgeven
          </v-button>

          <v-button
            v-if="data.showDeliveryNote"
            tagelement="button"
            md
            primary
            icon-left
            hollow
            @click.prevent="goToDelivery()"
          >
            <v-receipt-icon />
            Afleverbon aanmaken
          </v-button>
        </div>
        <div v-if="data.isActivePumpEquipmentLicense && data.isAccountabilityTypeFrequency == false" class="pump-main-container">
          <p v-if="data.requireEquipmentAssignment || data.equipments.length" class="license-row__cell__document">
            Pomp
          </p>
          <div v-if="data.requireEquipmentAssignment" class="add-pump-container">
            <div class="col-md-6 warning-message">
              <warning-message warning="Let op!" :message="'Er is nog geen pomp toegewezen aan deze machtiging.'" />
            </div>
            <v-button md cta @click="assignPump()"> Pomp toewijzen </v-button>
          </div>
          <div v-if="data.equipments.length" class="add-pump-container">
            <div>
              <p>
                Omschrijving: <strong>{{ data.equipments[0].description }}</strong>
                <br />
                Serienummer: <b>{{ data.equipments[0].serialNumber }}</b>
              </p>
              <p>
                {{ data.equipments[0].pickUp.pumpPickupText }}
                <br />Pomp toegewezen door <b>{{ data.equipments[0].prescriberName }}</b> op
                <b>{{ moment(data.equipments[0].dateOfAllocation).format('DD-MM-YYYY') }}</b>
              </p>
              <v-button
                v-show="data.equipments[0].showPickup"
                tagelement="button"
                :class="{ 'btn--primary': true }"
                icon-left
                md
                class="pumpOverview-row__cell__button"
                @click="$emit('exchange', { id: data.equipments[0].equipmentId, licenseId: data.licenses[0].id })"
              >
                <swap-horizontal-icon />
                Pomp wisselen
              </v-button>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <!--Pop up to show extend license -->
    <generic-modal v-if="showExtendLicensePopUp" @close="closeModal">
      <template #header> Machtiging verlengen </template>
      <template #body>
        <h4>Machtiging verlergen van {{ $formatDate(data.endDate) }} naar:</h4>
        <div class="license__extnDate">
          <v-input-date-picker
            id="extnDate"
            ref="date"
            type="date"
            name="extnDate"
            autofocus
            :value="customStartDate"
            :custom-start-date="customStartDate"
            @update-validation="updateValidation"
          />
        </div>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md primary @click="closeModal()"> Annuleren </v-button>
        <v-button tagelement="button" md primary @click="extLicense()"> Bevestigen </v-button>
      </template>
    </generic-modal>
    <!--Pop up to show pdf details -->
    <generic-modal v-if="showPdfDetailsPopUp" :has-close-button="true" class="pdfDetailsPopUp" @close="closeModal">
      <template #body>
        <div class="row titleSection" :class="{ customPadding: showUploadedFiles }">
          <div class="col-md-6 license-row__pdfHeader">Documenten</div>
          <div v-if="!showUploadedFiles" class="col-md-6 grid-align-end">
            <v-button md primary icon-left hollow @click="openCompactFileUpload">
              <v-file-upload-icon />
              Document toevoegen
            </v-button>
          </div>
        </div>
        <div v-show="showUploadedFiles" class="no-padding">
          <v-input-file-upload-compact
            :id="'file' + data.accountabilityId"
            :uploaded-files-data="licensePdfDetails"
            :accountability-upload="true"
            :allowed-extenstions="allowedFileExtenstions"
            :extension-error-message="extensionErrorMessage"
            @file-added="showUploadedFileSection"
            @upload-success="saveUploadedFiles"
          />
        </div>
        <v-table id="licensePdfDetails">
          <thead>
            <v-header-row v-show="!showUploadedFiles" :data="pdfTableHeaders" />
          </thead>
          <tbody>
            <tr v-for="(item, index) in licensePdfDetails" :key="index" :class="{ fileUploadRow: !showUploadedFiles }">
              <td />
              <td>{{ item.documentType }}</td>
              <td class="licenseDownloadLink" @click="downloadLicensePdf(item)">
                {{ item.fileName }}
              </td>
              <td colspan="2" class="actionIcons" :class="{ alignIcon: showUploadedFiles }">
                <v-delete-icon
                  v-if="item.isDeletable"
                  v-tooltip="{ content: 'Document verwijderen', placement: 'left', html: false }"
                  @click.prevent="deleteAccountabilityPdf(item)"
                />
              </td>
            </tr>
            <tr v-if="!licensePdfDetails || (licensePdfDetails && licensePdfDetails.length === 0)">
              <td />
              <td colspan="4">
                Nog geen documenten toegevoegd. Voeg hier uw document toe om de machtiging compleet te maken.
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md transparent primary @click="closeModal()"> Sluiten </v-button>
      </template>
    </generic-modal>
  </tbody>
</template>

<script>
import shared from '@/components/shared/shared';
import router from '@/router';
import VMenuDownIcon from 'icons/MenuDown';
import VActiveIcon from 'icons/CheckCircle';
import VAlmostExpiredIcon from 'icons/Timelapse';
import VWarningIcon from 'icons/AlertCircle';
import WarningMessage from '@/components/shared/WarningMessage';
import VStopIcon from 'icons/Cancel';
import VEditIcon from 'icons/Pencil';
import VDownloadIcon from 'icons/Download';
import VFileUploadIcon from 'icons/FileUploadOutline';
import VInputFileUpload from '@/components/shared/form/simple/InputFileUpload';
import VInputFileUploadCompact from '@/components/shared/form/simple/InputFileUploadCompact';
import VCheckIcon from 'icons/Check';
import moment from 'moment';
import TruckDeliveryIcon from 'icons/TruckDelivery';
import SwapHorizontalIcon from 'icons/SwapHorizontal';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import { localDateToJson, stringToLocalDate } from '@/services/date.service';
import VPillIcon from 'icons/Pill';
import VReceiptIcon from 'icons/Receipt';
import GenericModal from '@/components/shared/GenericModal';
import VCircleIcon from 'icons/Circle';
import VCalendarIcon from 'icons/Calendar';
import VTable from '@/components/shared/table/Table';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VDeleteIcon from 'icons/Delete';
import axios from 'axios';

import { documentInfo$, invalidateLicenseOverviews$ } from '@/services';
import { computed, ref } from 'vue';
import { useStore, mapActions } from 'vuex';

export default {
  name: 'LicenseRow',
  components: {
    VMenuDownIcon,
    VActiveIcon,
    VAlmostExpiredIcon,
    VWarningIcon,
    VStopIcon,
    VEditIcon,
    VDownloadIcon,
    VFileUploadIcon,
    VInputFileUpload,
    VInputFileUploadCompact,
    VCheckIcon,
    VInputDatePicker,
    TruckDeliveryIcon,
    SwapHorizontalIcon,
    WarningMessage,
    VPillIcon,
    VReceiptIcon,
    GenericModal,
    VCircleIcon,
    VTable,
    VHeaderRow,
    VDeleteIcon,
    VCalendarIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    pumpRetrieveButton: {
      type: Boolean
    },
    enableManagement: {
      type: Boolean
    },
    displayStatusIcon: {
      type: Boolean
    },
    isArchived: {
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const documentInfo = ref(null);

    const siteCode = computed(() => store.getters['navigation/getSiteCode']);
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const setLicenseStopPopUpStatus = (value) => store.commit('accountability/setLicenseStopPopUpStatus', value);
    const saveStandAloneAccountability = (value) =>
      store.dispatch('accountability/saveStandAloneAccountability', value);
    const saveAccountabilityFiles = (value) => store.dispatch('accountability/saveAccountabilityFiles', value);

    documentInfo$.subscribe((value) => {
      documentInfo.value = value;
    });

    return {
      siteCode,
      patientGegevens,
      setLicenseStopPopUpStatus,
      saveStandAloneAccountability,
      saveAccountabilityFiles,
      ...mapActions({
        requestAccountabilityCopy: 'accountability/requestAccountabilityCopy'
      }),
      documentInfo
    };
  },
  data() {
    return {
      isExpanded: false,
      beforeExpanded: false,
      isTransitioning: false,
      rowHeight: 'auto',
      rowHeightCollapsed: 'auto',
      showExtendLicensePopUp: false,
      isDateValidated: false,
      extnDate: null,
      showPdfDetailsPopUp: false,
      customStartDate: null,
      formData: '',
      osaType: 'Evaluation',
      pdfTableHeaders: ['', 'Document Type', 'Omschrijving', '', ''],
      showUploadedFiles: false,
      licensePdfDetails: [],
      allowedFileExtenstions: ['pdf', 'jpg', 'jpeg', 'png'],
      extensionErrorMessage:
        'Het geüploade document heeft niet de juiste extensie. U kunt alleen PDF, JPEG, JPG, of PNG documenten uploaden.'
    };
  },
  computed: {
    isManagementEnabled() {
      return this.enableManagement;
    }
  },
  mounted() {
    // Auto expand when no equipment available
    if (this.data.requireEquipmentAssignment && router.currentRoute.value.name === 'patientdashboard') {
      this.isExpanded = true;
    }
  },
  created() {},
  methods: {
    deleteAccountabilityPdf(item) {
      this.showPdfDetailsPopUp = false;
      // Start a promise, call for a prompt
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Documenten verwijderen',
          message: 'U staat op het punt om document ' + item.fileName + ' definitief te verwijderen. Weet u het zeker?',
          resolve,
          reject
        });
      })
        .then((payload) => {
          // When prompt was confirmed
          axios
            .delete('Accountability/DeleteAccountabilityDocument', {
              params: {
                documentGUID: item.documentGUID,
                documentUUID: item.documentUUID
              }
            })
            .then((response) => {
              if (response && response.data) {
                this.$emitter.emit('alert', {
                  message: 'Document is succesvol verwijderd.',
                  type: 0
                });
                this.loadPdfDetails();
                this.showPdfDetailsPopUp = true;
              }
            })
            .catch(() => {
              this.$emitter.emit('alert', {
                message: 'Het verwijderen van dit document is niet gelukt. Probeer het later nogmaals.',
                type: 3
              });
              this.showPdfDetailsPopUp = true;
            });
        })
        .catch(() => {
          // When cancelled
          this.showPdfDetailsPopUp = true;
        });
    },
    openCompactFileUpload() {
      document.getElementById('file' + this.data.accountabilityId).click();
    },
    showUploadedFileSection(value) {
      this.showUploadedFiles = value;
    },
    closeModal() {
      this.showExtendLicensePopUp = false;
      this.showPdfDetailsPopUp = false;
    },
    fileExtention: shared.fileExtention,
    moment(...args) {
      return moment(...args);
    },
    toggle(canToggle) {
      if (canToggle) {
        this.isExpanded = !this.isExpanded;
      }
    },
    getStatusIcon(status) {
      if (this.data.requireEquipmentAssignment) {
        return VWarningIcon;
      } else {
        return VActiveIcon;
      }
    },
    getStatusClassName() {
      const status = this.data.status;
      switch (status) {
        case 'active':
          return 'status-active';
        case 'almostExpired':
          return 'status-active';
        case 'expired':
          return 'status-expired';
        default:
      }
    },
    saveUploadedFiles(fmData, licenceId, filetype) {
      let fileDetails = {
        licenseId: this.data.licenses[0].id,
        accountabilityId: this.data.accountabilityId,
        documentType: filetype,
        formData: fmData
      };
      this.saveAccountabilityFiles(fileDetails)
        .then((response) => {
          invalidateLicenseOverviews$();
          this.loadPdfDetails();
        })
        .catch((error) => {});
    },
    editLicense() {
      if (this.data.accountabilityId !== null) {
        this.$emit('edit', { id: this.data.accountabilityId });
        return;
      }
      this.setLicenseStopPopUpStatus(true);
      router.push('/nieuwemachtiging');
    },
    extendLicense() {
      if (this.data.accountabilityId !== null) {
        this.$emit('extend', { id: this.data.accountabilityId });
        return;
      }
      this.setLicenseStopPopUpStatus(true);
      router.push('/nieuwemachtiging');
    },
    extLicense() {
      const endDate = stringToLocalDate(this.extnDate);
      const endDateJson = localDateToJson(endDate);

      if (this.data.accountabilityId !== 0) {
        this.requestAccountabilityCopy({
          AccountabilityId: this.data.accountabilityId,
          IsAccountabilityUsedForAssigningMultiplePumps: false
        }).then((response) => {
          const copyAccountabilityId = response.data.Id;
          const startDate = new Date();
          const startDateJson = localDateToJson(startDate);

          this.startDate = moment(startDate).format('DD-MM-YYYY');
          const extLicenseDateDetails = {
            accountabilityId: copyAccountabilityId,
            startDate: startDateJson,
            endDate: endDateJson
          };
          this.saveStandAloneAccountability(extLicenseDateDetails).then(() => {
            this.showExtendLicensePopUp = false;
            this.$emitter.emit('alert', {
              message: 'Uw machtiging is met succes verlengt'
            });
            invalidateLicenseOverviews$();
          });
        });
      }
    },
    // Function to update the date value to stop equipment
    updateValidation(date) {
      this.isDateValidated = true;
      this.extnDate = date;
    },
    // Button text based on the portal site code
    getIsEditableButtonText() {
      if (this.data.isNutritionLicense) {
        return 'Wijzigen';
      } else if (this.data.endDate !== null) {
        return 'Wijzigen/verlengen';
      }
      return 'Wijzigen';
    },
    /**
     * @function goToApproval - Function to redirect prescriber to approval form
     */
    goToApproval() {
      let osaId = this.data.evaluationId;
      let osaSptId = this.data.evaluationSptId;
      const type = this.data.evaluationType;
      const licenseId = this.data.licenses[0].id;
      if (type === this.osaType) {
        if (osaId === null) {
          osaId = 0;
        }
        router.push('/osa/akkoordverklaring/' + type + '/' + osaId + '/' + licenseId);
      } else {
        if (osaSptId === null) {
          osaSptId = 0;
        }
        router.push('/osa/akkoordverklaring-posa-spt/' + type + '/' + osaSptId + '/' + licenseId);
      }
    },
    /**
     * @function goToMedication - Function to redirect prescriber to the medication form
     */
    goToMedication() {
      router.push('/verneveling/medicatiewijziging/' + this.data.licenses[0].id);
    },
    /**
     * @function goToDelivery - Function to redirect prescriber to delivery form
     */
    goToDelivery() {
      const licenseId = this.data.licenses[0].id;
      router.push('/osa/afleverbon/' + licenseId);
    },
    assignPump() {
      const licenseId = this.data.licenses[0].id;
      this.$emit('assignPump', licenseId);
    },
    loadPdfDetails() {
      axios
        .post('Accountability/GetAllAccountabilityDocuments', {
          licenseId: this.data.licenses[0].id,
          licenseExternalId: this.data.licenses[0].externalId,
          accountabilityId: this.data.licenses[0].accountabilityId,
          metaOnly: true
        })
        .then((response) => {
          if (response && response.data) {
            this.licensePdfDetails = response.data;
            this.showPdfDetailsPopUp = true;
          }
        });
    },
    async downloadLicensePdf(item) {
      if (item.document !== null) {
        this.downloadPdf({
          fileName: item.fileName,
          content: item.document
        });
        return;
      }
      axios
        .post('Accountability/GetSingleAccountabilityDocument', {
          documentGUID: item.documentGUID
        })
        .then((response) => {
          if (response && response.data) {
            this.downloadPdf({
              fileName: response.data.fileName,
              content: response.data.document
            });
          }
        });
    },
    downloadPdf(details) {
      const linkSource = `data:application/pdf;base64,${details.content}`;
      const downloadLink = document.createElement('a');
      const fileName = details.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
  }
};
</script>

<style lang="scss" scoped>
.license-row {
  .pump-main-container {
    padding: 30px 0 0 0;
  }
  &__pdfHeader {
    display: flex;
    font-size: 26px;
    font-weight: bold;
    justify-content: space-between;
  }
  #licensePdfDetails {
    width: calc(100% + 50px);
    max-width: calc(100% + 50px);
    margin: 16px -25px;
    .material-design-icon {
      fill: $teal-darkest;
      cursor: pointer;
    }
    :deep(th:first-child) {
      width: auto;
    }
    td {
      padding: $table-row-license-cell-padding;
    }
    > th:last-child,
    td:last-child {
      min-width: 230px;
      justify-content: flex-end;
      padding: 1rem 1.5rem 1rem 0.5rem;
    }
    .fileUploadRow {
      td {
        display: table-cell !important;
        min-width: unset !important;
      }
    }
    .licenseDownloadLink {
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
    }
    .actionIcons {
      text-align: left;
    }
    .alignIcon {
      span {
        float: right;
      }
    }
  }
  .pdfDetailsPopUp {
    .titleSection {
      padding: 8px 0px 0px 16px;
    }
    .customPadding {
      padding: 8px 0px 0px 8px !important;
    }
    :deep(.generic-modal) {
      min-width: 800px;
    }
  }
  .endDateStatus {
    .material-design-icon {
      fill: #d17001;
      float: left;
      margin: 5px 5px 0px 0px;
      height: 10px;
    }
  }
  &__clickable {
    cursor: pointer;
  }
  background-color: $table-row-background;
  border: $table-row-border;
  transition: $search-transition;
  &.status-almost-expired {
    background-color: $table-row-action-background;
  }
  .status-expired {
    color: $grayish-lighter !important;
    border-color: $grayish-lighter;
  }
  &__cell {
    color: $table-row-cell-color;
    font-size: $table-row-cell-font-size;
    line-height: $table-row-cell-line-height;
    justify-content: $table-row-cell-justify-content;
    padding: $table-row-license-cell-padding;
    vertical-align: $table-row-license-cell-vertical-align;

    a {
      text-decoration: underline;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      margin: 0 0 0 35px;
    }

    &__uploadDate {
      color: rgba(159, 159, 159, 0.87);
      margin: 0 0 0 35px;
    }

    &__trialLicense1 {
      width: 119px;
      border-radius: 3px;
      background-color: $cyan-darker;
    }

    &__trialLicense2 {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      color: $white;
    }

    img {
      position: absolute;
      height: 32px;
      margin: 0px;
    }
    @mixin list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    &__document {
      font-size: 20px !important;
      color: $table-row-license-cell-prescriber-color;
      font-size: $table-row-license-cell-prescriber-font-size;
      line-height: $table-row-license-cell-prescriber-line-height;
      margin: 0;
    }

    &__documentTitle {
      margin: 16px 0px;
    }

    &__type {
      @include list;
      font-weight: bold;
      color: $table-row-license-cell-type-color;
    }

    &__product-group {
      @include list;
    }

    &__prescriber {
      color: $table-row-license-cell-prescriber-color;
      font-size: $table-row-license-cell-prescriber-font-size;
      line-height: $table-row-license-cell-prescriber-line-height;
      margin: 0;

      &__author {
        font-weight: $table-row-license-cell-prescriber-font-weight;
      }
    }

    &__actions {
      margin-top: 15px;
      display: flex;

      .btn {
        outline: 0;
        margin-right: 10px;
      }
    }

    > .material-design-icon {
      display: flex;
      justify-content: center;
      fill: $black;

      :deep(svg) {
        display: block;
      }

      &.status-active {
        fill: $table-row-icon-check;
      }

      &.status-almost-expired {
        fill: $table-row-icon-timelapse;
      }

      &.status-expired {
        fill: $grayish-lighter;
      }
      &#alert-circle-icon {
        fill: $orange;
      }
    }

    .icon-menu-down {
      fill: $table-row-icon-menu-down;
      margin-top: 0;
      transition: $table-transition-rotate;
    }
  }
  &.is-expanded {
    .icon-menu-down {
      transform: rotate(-180deg);
    }
  }
  :deep(.form-group-simple input) {
    width: 100%;
    padding-left: 300px;
    margin-bottom: 100px;
  }
  .add-pump-container {
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    .warning-message {
      margin: 0 10px 0 0;
    }
    p {
      line-height: normal;
    }
  }
  :deep(.uploadBox) {
    padding: 0 !important;
  }
}
</style>
