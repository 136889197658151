<template>
  <div
    class="form-group-simple"
    :class="{
      'form-group-simple--completed': completed
    }"
  >
    <label for="Huisnummer">{{ label }}</label>

    <input
      :class="errorMessage ? 'error' : ''"
      v-model="inputValue"
      type="text"
      :name="name"
      placeholder="10"
      @blur="blur"
      @input="update($event.target.value)"
    />
    <div class="form-group-simple__details" v-if="errorMessage">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          <span> <v-error-icon></v-error-icon> {{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { ref, onBeforeMount, watch, getCurrentInstance } from 'vue';
import VErrorIcon from 'icons/AlertCircle';
import { generateYupSchema } from '@/composables/globalHelperFunctions';

export default {
  name: 'VInputHousenumberSimple',
  components: {
    VErrorIcon
  },
  props: {
    id: {
      default: '',
      type: String
    },
    autofocus: {
      default: false,
      type: Boolean
    },
    name: {
      default: 'Huisnummer',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    value: {
      default: null,
      type: String
    },
    rules: {
      default: null,
      type: Object
    }
  },
  setup(props) {
    const instance = getCurrentInstance();
    const inputValue = ref(props.value);
    const schema = ref(generateYupSchema(props));
    const { value, errorMessage, validate } = useField(props.id, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema(props);
      }
    );
    const completed = ref(false);

    const focus = () => {};

    const blur = () => {
      validate();
    };

    const update = async (updatedValue) => {
      inputValue.value = updatedValue;
      value.value = updatedValue;
      let result = await validate();
      instance.emit('updateValidation', result.valid);
    };

    onBeforeMount(() => {
      if (props.value) {
        value.value = ref(props.value);
      }
    });

    return {
      inputValue,
      completed,
      focus,
      blur,
      update,
      errorMessage
    };
  }
};
</script>
<style lang="scss" scoped>
.error {
  border: 1px solid $pure-red;
}
.form-group-simple {
  position: relative;
  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
  &__notification {
    color: $pure-red;
    .information-outline-icon {
      display: inline-flex;
      width: 16px;
      height: 16px;
      fill: $pure-red;
      vertical-align: bottom;
    }
  }
  &__tooltip {
    fill: $teal-darkest;
    display: inline-flex;
    vertical-align: middle;
  }
  .input-group-append {
    position: absolute;
    display: flex;
    right: 0;
    top: 32px;
  }
  .input-group-text {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 50px;
  }
}
</style>
