import { getCurrentUserIdentity } from './getCurrentUserIdentity';
import { userLoggedInEvent$, userLoggedOutEvent$ } from './events$';
import { from, of } from 'rxjs';
import { publishReplay, refCount, switchMap, map, mergeAll } from 'rxjs/operators';

const initCurrentUserIdentity$ = of(1).pipe(
  switchMap(v => from(getCurrentUserIdentity()))
);

export const isLoggedIn$ = of(
  initCurrentUserIdentity$.pipe(map(identity => {
    return identity != null;
  })),
  userLoggedInEvent$.pipe(map(e => true)),
  userLoggedOutEvent$.pipe(map(e => false))
)
.pipe(
  mergeAll(),
  publishReplay(1),
  refCount()
);
