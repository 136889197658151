<template>
  <section class="container medication">
    <div class="row">
      <v-heading :level="3">
        {{ customHeaderDetails.pageTitle }}
      </v-heading>
    </div>
    <!-- License data block-->
    <div class="row">
      <div class="medication__block col-xs-12">
        <div class="medication__block__header">
          {{ customHeaderDetails.medicationHeader }}
        </div>
        <div class="medication__block__content">
          <div class="row grid-margin medication__row">
            <div class="col-xs-12">
              <legend>
                U wilt een wijziging doorgeven in medicatie bij een bestaande machtiging. Met behulp van dit formulier
                kan de wijziging op de juiste manier verwerkt worden bij de zorgverzekeraar. Let op: dit is geen recept.
              </legend>
            </div>
            <div class="col-xs-6">
              <div class="form-group__date">
                <v-input-date-picker
                  id="medicationDate"
                  ref="date"
                  v-model="osaMedicationDetails.medicationDate"
                  type="date"
                  :label="customHeaderDetails.dateLabel"
                  name="medicationDate"
                  autofocus
                  osa-date
                  @selected="handleDate"
                />
              </div>
            </div>
            <div class="col-xs-6">
              <label for="selectLicense">Betreffende machtiging*:</label>
              <v-input-group-simple v-model="licenseId" inputid="licenseId" disabled :initvalue="licenseId" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Medication block-->
    <div class="row">
      <div class="medication__block col-xs-12">
        <div class="medication__block__header">
          {{ customHeaderDetails.medicationHeader }}
        </div>
        <div class="medication__block__content">
          <div class="row grid-margin medication__row">
            <div class="col-xs-12">
              <legend>Vink zowel de huidige als de nieuwe medicatie aan.</legend>
            </div>
            <div v-for="(medicationStatus, statusKey) in medicationLabels" :key="statusKey" class="col-xs-6">
              <h3>{{ medicationTitles[statusKey] }}</h3>
              <div v-for="(medicationType, typeKey) in medicationStatus" :key="statusKey + typeKey">
                <div v-if="medicationTitles[typeKey]" class="medication__sideHeading">
                  {{ medicationTitles[typeKey] }}
                </div>
                <v-checkbox-group
                  v-for="(medication, key) in medicationType"
                  :key="medication"
                  v-model="osaMedicationDetails[statusKey][typeKey][key]"
                  :class="{ medication__otherCheckbox: key === 'HypertoonZout' || key === 'Luchtwegverwijder' }"
                  :label="medication"
                  :inputid="statusKey + typeKey + key"
                  :initvalue="osaMedicationDetails[statusKey][typeKey][key]"
                  :overwrite-value="osaMedicationDetails[statusKey][typeKey][key]"
                  @input="change(...arguments, key, typeKey, statusKey)"
                >
                  <v-input-group-simple
                    type="text"
                    :disabled="!osaMedicationDetails[statusKey][typeKey][key]"
                    :inputid="statusKey + typeKey + key + 'text'"
                    v-if="key === 'HypertoonZout' || key === 'Luchtwegverwijder'"
                    sm
                    alt
                    @input="inputCheckBox(...arguments, key + 'Other', statusKey)"
                  />
                </v-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Final Medication block-->
    <div v-if="user" class="row">
      <div class="medication__block col-xs-12">
        <div class="medication__block__header">
          {{ customHeaderDetails.finalHeader }}
        </div>
        <div class="medication__block__content">
          <div class="row grid-margin medication__row">
            <div class="col-xs-6">
              <v-input-group-simple v-model="user.username" inputid="userName" disabled :initvalue="user.username" />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                inputid="date"
                disabled
                :model="$formatDate(currentDate)"
                :initvalue="$formatDate(currentDate)"
              />
            </div>
          </div>
          <div class="row grid-margin grid-align-center medication__row">
            <div class="medication__signature">
              <prescriber-signature :user-id="user.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons Block-->
    <div class="row grid-justify-content-right">
      <div class="medication__actions">
        <v-button md hollow class="medication__actions__cancelMedication" @click.prevent="cancelOSAMedication()">
          Annuleren
        </v-button>
        <v-button md cta class="medication__actions__saveMedication" @click.prevent="saveOSAMedication()">
          Toevoegen
        </v-button>
      </div>
    </div>
    <!-- Cancel pop up -->
    <generic-modal v-if="showCancelPopUp">
      <template #body>
        <p>Wilt u het formulier annuleren? De gegevens worden niet opgeslagen.</p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="closeModal"> Ga terug </v-button>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Annuleren </v-button>
      </template>
    </generic-modal>
    <!-- Success pop up -->
    <generic-modal v-if="showSuccessPopup">
      <template #body>
        <p>De medicatiewijziging is ter kennisgeving verzonden. Let op, dit is geen recept.</p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Naar patiënt dashboard </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import moment from 'moment';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import GenericModal from '@/components/shared/GenericModal';
import { currentUserIdentity$ } from '@/services';
import PrescriberSignature from '@/components/sections/prescriber/PrescriberSignature';
import { ref } from 'vue';

export default {
  name: 'VMedication',
  components: {
    VInputDatePicker,
    VCheckboxGroup,
    GenericModal,
    PrescriberSignature
  },
  setup() {
    const user = ref(null);

    currentUserIdentity$.subscribe((value) => (user.value = value));

    return {
      user
    };
  },
  data() {
    return {
      currentDate: new Date(),
      showCancelPopUp: false,
      showSuccessPopup: false,
      customHeaderDetails: {
        pageTitle: 'Wijzigingsformulier - Doorgeven medicatiewijziging bij verneveltherapie bij bestaande machtiging',
        medicationHeader: 'Wijziging medicatie',
        finalHeader: 'Ondertekenen en verzenden',
        dateLabel: 'Datum medicatiewijziging'
      },
      animate: false,
      searchLicenseText: 'Geen machtiging beschikbaar',
      medicationTitles: {
        currentMedication: 'Huidige medicatie',
        newMedication: 'Nieuwe medicatie',
        hypertonicSalt: 'Hypertoon zout:',
        respiratory: 'Luchtwegverwijder:'
      }
    };
  },
  computed: {
    licenseId() {
      return Number(router.currentRoute.value.params.licenseId);
    },
    medicationLabels() {
      const standardLabels = {
        medication: {
          Tadim: 'Tadim',
          TobramycineBramitob: 'Tobramycine (Bramitob)',
          TobramycineTobi: 'Tobramycine (Tobi)',
          TobramycineVantobra: 'Tobramycine (Vantobra)',
          AztreonamCayston: 'Aztreonam (Cayston)',
          Quinsair: 'Quinsair',
          Colistine: 'Colistine'
        },
        hypertonicSalt: {
          Mucoclear: 'Mucoclear',
          Hyaneb: 'Hyaneb',
          NaCl: 'NaCl',
          HypertoonZout: 'Anders, namelijk: '
        },
        respiratory: {
          Ventolin: 'Ventolin',
          Atrovent: 'Atrovent',
          Ipraxa: 'Ipraxa',
          Combivent: 'Combivent',
          Ipramol: 'Ipramol',
          Fluticason: 'Fluticason',
          Ipratropium: 'Ipratropium',
          Salbutamol: 'Salbutamol',
          Luchtwegverwijder: 'Anders, namelijk: ',
          Corticosterolid: 'Corticosterolid',
          Antimyotica: 'Antimyotica',
          Dnase: 'Dnase'
        }
      };
      return { currentMedication: Object.assign({}, standardLabels), newMedication: Object.assign({}, standardLabels) };
    },
    osaMedicationDetails() {
      const standardMedication = {
        medication: {
          Tadim: false,
          TobramycineBramitob: false,
          TobramycineTobi: false,
          TobramycineVantobra: false,
          AztreonamCayston: false,
          Quinsair: false,
          Colistine: false
        },
        hypertonicSalt: {
          Mucoclear: false,
          Hyaneb: false,
          NaCl: false,
          HypertoonZout: false
        },
        respiratory: {
          Ventolin: false,
          Atrovent: false,
          Ipraxa: false,
          Combivent: false,
          Ipramol: false,
          Fluticason: false,
          Ipratropium: false,
          Salbutamol: false,
          Luchtwegverwijder: false,
          Corticosterolid: false,
          Antimyotica: false,
          Dnase: false
        },
        HypertoonZoutOther: '',
        LuchtwegverwijderOther: ''
      };
      return {
        medicationDate: '',
        currentMedication: {
          ...standardMedication,
          medication: { ...standardMedication.medication },
          hypertonicSalt: { ...standardMedication.hypertonicSalt },
          respiratory: { ...standardMedication.respiratory }
        },
        newMedication: {
          ...standardMedication,
          medication: { ...standardMedication.medication },
          hypertonicSalt: { ...standardMedication.hypertonicSalt },
          respiratory: { ...standardMedication.respiratory }
        }
      };
    }
  },
  created() {},
  methods: {
    closeModal() {
      this.showSuccessPopup = false;
      this.showCancelPopUp = false;
    },
    /**
     * @function moment - Function to convert datetime to required specific format
     */
    moment(...args) {
      return moment(...args);
    },
    /**
     * @function goToPatientDashboard - Fuction to redirect prescriber to patient dashboard page
     */
    goToPatientDashboard() {
      router.push('/patientdashboard');
    },
    /**
     * @function cancelOSAMedication - Function to show cancel pop up
     */
    cancelOSAMedication() {
      this.showCancelPopUp = true;
    },
    /**
     * @function saveOSAMedication - Fucntion to save the Medication form details
     */
    saveOSAMedication() {
      let finalMedicationDetails = {
        ...this.osaMedicationDetails,
        currentMedication: Object.assign(
          {},
          this.osaMedicationDetails.currentMedication.medication,
          this.osaMedicationDetails.currentMedication.hypertonicSalt,
          this.osaMedicationDetails.currentMedication.respiratory
        ),
        newMedication: Object.assign(
          {},
          this.osaMedicationDetails.newMedication.medication,
          this.osaMedicationDetails.newMedication.hypertonicSalt,
          this.osaMedicationDetails.newMedication.respiratory
        )
      };
      finalMedicationDetails.currentMedication.HypertoonZoutOther =
        this.osaMedicationDetails.currentMedication.HypertoonZoutOther;
      finalMedicationDetails.currentMedication.LuchtwegverwijderOther =
        this.osaMedicationDetails.currentMedication.LuchtwegverwijderOther;
      finalMedicationDetails.newMedication.HypertoonZoutOther =
        this.osaMedicationDetails.newMedication.HypertoonZoutOther;
      finalMedicationDetails.newMedication.LuchtwegverwijderOther =
        this.osaMedicationDetails.newMedication.LuchtwegverwijderOther;
      finalMedicationDetails.licenseId = this.licenseId;
      finalMedicationDetails.medicationDate = moment(this.osaMedicationDetails.medicationDate, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      return axios.post('Medication/SaveMedication', finalMedicationDetails).then((response) => {
        if (response) {
          this.showSuccessPopup = true;
        }
      });
    },
    /**
     * @function handleDate - Function to handle / convert start date value as per requirement format
     */
    handleDate(key, value) {
      this.osaMedicationDetails[key] = value;
    },
    /**
     * @function inputCheckBox - Function to handle the checkbox value
     */
    inputCheckBox(id, value, key, statusKey) {
      this.osaMedicationDetails[statusKey][key] = value;
      this.$forceUpdate();
    },
    /**
     * @function change - Function to handle the checkbox interaction
     */
    change(active, key, typeKey, statusKey) {
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
.medication {
  color: $grayish-very-dark;
  &__block {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 0px 0px 30px 0px;
    &__header {
      background-color: $grayish-very-light;
      color: $grayish-very-dark;
      font-size: 18px;
      padding: 20px;
    }
    &__content {
      padding: 10px 10px 10px 10px;
    }
  }
  &__actions {
    display: flex;
    margin: 20px 0px 30px 0px;
    &__cancelMedication {
      margin-right: 25px;
    }
  }
  &__row {
    margin: 10px 0 10px 0;
    &__radio {
      display: inline-flex;
      span {
        margin: 10px 10px 0px 0px;
        float: left;
      }
    }
    td {
      padding-bottom: 20px;
    }
  }
  &__signature {
    margin: 0 auto;
    img {
      border: solid 2px $grayish-med-light;
      vertical-align: text-top;
      max-width: 350px !important;
      height: auto;
      width: 100%;
    }
  }
  &__sideHeading {
    margin: 10px 0px 10px 25px;
  }
  a {
    color: $teal-darkest;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin-bottom: 15px;
  }
  legend {
    color: $grayish-super-dark;
    margin: 10px 0 10px 0;
  }
  :deep(.form-group) {
    width: 100%;
    padding-top: 0px;
    &__details {
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
    }
    &__date {
      .form-group-simple input {
        padding-left: 30px;
        width: 100%;
        border-color: $gray-light;
      }
    }
    &__icon {
      right: 0px !important;
      top: 15px !important;
    }
  }
  &__otherCheckbox {
    :deep(.checkbox-group__items) {
      .checkbox-group__checkbox + .checkbox-group__label > span {
        margin-top: 15px !important;
      }
      .checkbox-group__icon {
        margin-top: 15px !important;
      }
    }
    :deep(.form-group-simple) {
      display: contents;
    }
  }
}
</style>
