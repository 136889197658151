import { fetchQuery } from '@/services/messaging/fetchQuery';
import { assignFeatureFlag } from '@/directives/FeatureFlags.js';
import router from '@/router';

export function getCurrentUserIdentity() {
  var response = fetchQuery('GetCurrentUserIdentity', {}).then((response) => {
    if (response && response.isSecurityPolicyExpired !== false) {
      router.push('/closeddepartment');
    }
    assignFeatureFlag(response);
    return response;
  });
  return response;
}
