<template>
  <section class="container news-detail">
    <div class="row">
      <article class="col-xs-12">
        <v-heading :level="1">{{ newsItem.Title }}</v-heading>
        <div>{{ moment(newsItem.PublishDate).locale('nl').format('DD MMMM YYYY') }}</div>
        <div class="newsbody" v-html="newsItem.Body"></div>
      </article>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { computed } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';

export default {
  name: 'v-news-detail',
  setup() {
    const store = useStore();
    const newsItem = computed(() => store.getters['news/getNewsItem']);
    const fetchNewsItem = async (value) => await store.dispatch('news/fetchNewsItem', value);

    return {
      newsItem,
      fetchNewsItem
    };
  },
  async created() {
    await this.fetchNewsItem(router.currentRoute.value.params.id);
  },
  methods: {
    moment(...args) {
      return moment(...args);
    }
  }
};
</script>

<style lang="scss">
.news-detail {
  max-width: 1280px;
  margin: auto;
  h1.heading {
    font-size: type-scale(3);
    font-weight: 900;
    margin: 0 0 0.4rem;
  }
  .newsbody {
    font-size: type-scale(0);
    line-height: line-height(2);
    color: $gray-darkest;
    margin: 1rem 0 0.4rem;
    a {
      font-weight: 900;
      color: $teal-darkest;
    }

    .image {
      clear: both;
      display: table;
      margin: 0.9em auto;
      min-width: 50px;
      text-align: center;

      &.image-style-side {
        float: right;
        margin-left: 1.5rem;
        max-width: 50%;
      }

      &.image-style-align-left {
        float: left;
        margin-right: 1.5rem;
      }

      img {
        max-width: 100%;
        min-width: 100%;
      }
    }
    img {
      display: block;
      height: auto;
    }
  }
}
</style>
