<template>
  <div class="mLayout">
    <mds-layout v-bind="$attrs" v-on="$attrs">
      <div v-if="$slots.navigation" slot="navigation"><slot name="navigation" /></div>
      <div v-if="$slots.header" slot="header"><slot name="header" /></div>
      <div v-if="$slots.main" slot="main"><slot name="main" /></div>
      <div v-if="$slots.aside" slot="aside"><slot name="aside" /></div>
      <div v-if="$slots.footer" slot="footer"><slot name="footer" /></div>
    </mds-layout>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { MdsLayout } from '@mds/layout';
export default {
  name: 'Layout',
  props: {},
  computed: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.mLayout {
  :deep(mds-layout) {
    display: contents;
  }
}
</style>
