<template>
  <div class="header">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'VHeader',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $grayish-very-light;

  h3 {
    font-size: 16px;
    color: $grayish-very-dark;
    font-weight: 400;
    margin: 20px;
  }
}
</style>
