import { publishReplay, switchMap, refCount, map } from 'rxjs/operators';
import { activeDepartment$ } from '../activeDepartment$';
import axios from 'axios'

export const customers$ = activeDepartment$.pipe(
  switchMap((activeDepartment) => axios.get('PatientSelecteren/GetAllPatients')),
  map(response => response.data),
  publishReplay(),
  refCount()
);
