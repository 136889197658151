
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    orderHistory: {},
    recentOrderHistory: {}
  },
  mutations: {
    setOrderHistory (state, payload) {
      state.orderHistory = payload
    },
    setRecentOrderHistory (state, payload) {
      state.recentOrderHistory = payload
    }
  },
  actions: {
    getOrderHistory ({ commit, state }) {
      return axios.get('/Orders/GetCustomerOrders').then((response) => {
        commit('setOrderHistory', response.data)
      })
    },
    getRecentOrderHistory ({ commit, state }) {
      return axios.get('/Orders/GetRecentlyOrderProducts').then((response) => {
        commit('setRecentOrderHistory', response.data.Products)
      })
    }
  },
  getters: {
    getOrderHistory: state => {
      return state.orderHistory
    },
    getRecentOrderHistory: state => {
      return state.recentOrderHistory
    }
  }
}
