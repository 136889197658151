<template>
  <tbody class="order-history__entry" :class="{ collapsed: collapsed }">
    <tr class="order-history__entry__data">
      <td v-if="!collapsed" @click.prevent="toggleDetails()">
        <v-plus-icon title="Toon meer" />
      </td>
      <td v-else @click.prevent="toggleDetails()">
        <v-minus-icon title="Toon minder" />
      </td>
      <td>{{ entry.orderNumber }}</td>
      <td>{{ entry.orderBy }}</td>
      <td>{{ entry.type }}</td>
      <td>{{ moment(entry.orderDate).format('DD-MM-YYYY') }}</td>
      <td v-if="entry.deliveryDate">
        {{ moment(entry.deliveryDate).format('DD-MM-YYYY') }}
      </td>
      <td v-else>Nog onbekend</td>
      <td v-if="computedIconStatus" class="order-history__entry__data__status">
        <component :is="entry.orderStatusIcon" :title="computedIconStatusTitle" :class="entry.orderStatusIcon" />
      </td>
      <td v-else class="order-history__entry__data__tracking">
        <a v-if="entry.orderStatusIcon" :href="entry.orderStatusIcon" target="_blank" rel="noopener">Volg bestelling</a>
      </td>
    </tr>
    <tr v-if="$slots.details && collapsed">
      <td colspan="7">
        <slot name="details" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import complete from 'icons/CheckCircle';
import incomplete from 'icons/Timelapse';
import VPlusIcon from 'icons/PlusBoxOutline';
import VMinusIcon from 'icons/MinusBoxOutline';
import moment from 'moment';

export default {
  name: 'PatientOrderHistoryEntry',
  components: {
    complete,
    incomplete,
    VPlusIcon,
    VMinusIcon
  },
  props: {
    entry: {
      type: Object,
      required: true,
      status: {
        type: Number,
        default: 0
      },
      id: {
        type: String,
        required: true
      },
      orderedby: {
        type: String
      },
      date: {
        type: Date
      },
      trackuri: {
        type: String
      }
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      collapsed: false
    };
  },
  computed: {
    computedIconStatus() {
      return this.entry.orderStatusIcon === 'complete' || this.entry.orderStatusIcon === 'incomplete';
    },
    computedIconStatusTitle() {
      return this.entry.orderStatusIcon === 'complete' ? 'Compleet' : 'Order wordt verwerkt';
    }
  },
  created() {
    if (this.index === 0) {
      this.collapsed = true;
    }
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },
    toggleDetails() {
      this.collapsed = !this.collapsed;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-history__entry {
  background: $white;
  border: 1px solid $gray-light;
  transition: border-color 0.25s;
  color: $gray-darkest;
  .material-design-icon {
    fill: $teal-darkest;
  }
  &.collapsed {
    border-color: $gray;
  }
  > tr {
    > th,
    > td {
      text-align: left;
      padding: 1rem 0.5rem;
    }
    // Order data row
    &.order-history__entry__data {
      > .order-history__entry__data__status {
        :deep(.material-design-icon) {
          margin-left: 14px;
          &.complete {
            color: $dark-green;
          }
          &.incomplete {
            color: $gray;
          }
          svg {
            fill: currentColor;
          }
        }
      }
      // Tracking link cell
      > .order-history__entry__data__tracking {
        a {
          color: $gray-dark;
          text-decoration: none;
          display: inline-flex;
          align-items: baseline;
          &:hover {
            color: $gray-darkest;
          }
          &:before {
            content: '›';
            font-size: 1.65em;
            line-height: 1px;
            align-self: center;
            margin-right: 0.2em;
            margin-top: -0.1em;
          }
        }
      }
    }
  }
}
</style>
