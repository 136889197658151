import { from } from 'rxjs';
import { activePpwRecId$ } from '@/services/activePpwRecId$';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { getDepartmentColleagues } from './getDepartmentColleagues';


export const departmentColleagues$ = activePpwRecId$.pipe(
  switchMap(v => from(getDepartmentColleagues())),
  publishReplay(1),
  refCount()
);
