import { currentUserIdentity$ } from '@/services';
import { first } from 'rxjs/operators';
import { app } from '@/main.js'

// Runs hand in hand when fetching CurentUserIdentity Rx Boilerplate
export function assignFeatureFlag(data) {
  if (data != null) {
    app.config.globalProperties.$featureFlag = data.featureFlags;
  }
  else
  app.config.globalProperties.$featureFlag = [];
}

// global variable that returns full list of Access Controls for FE and BE usage
export async function featureFlagInit() {
  var featureFlags = app.config.globalProperties.$featureFlag;
  if (featureFlags == null || featureFlags == []) {
    const currentUserIdentity = await currentUserIdentity$.pipe(first()).toPromise();
    featureFlags = currentUserIdentity.featureFlags;
  }
}

// lookup method to check if specific treatmentareas are allowed access
export async function featureFlagValidator(treatmentAreaIds) {
  const currentUserIdentity = await currentUserIdentity$.pipe(first()).toPromise();

  const userTreatmentAreas = currentUserIdentity.isOnePortal
    ? currentUserIdentity.allTreatmentAreas.map((ta) => ({ treatmentAreaId: ta.treatmentAreaId }))
    : currentUserIdentity.userTreatmentArea.map((ta) => ({ treatmentAreaId: ta.treatmentAreaId }));

  for (let i = 0; i < treatmentAreaIds.length; i++) {
    if (userTreatmentAreas.includes(treatmentAreaIds[i])) {
      return true;
    }
  }

  return false;
}
