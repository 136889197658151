import { from } from 'rxjs';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { fetchWebApi } from '@/services/messaging/fetchWebApi';
import { currentUserIdentity$ } from '@/services/authentication/currentUserIdentity$';

function getDocumentInfo() {
  return fetchWebApi("Current/PatientLicensesDocumentInfo", {});
}

export const documentInfo$ = currentUserIdentity$.pipe(
  switchMap(x => from(getDocumentInfo())),
  publishReplay(1),
  refCount()
);
