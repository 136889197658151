<template>
  <p class="paragraph">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'VParagraph'
}
</script>

<style lang="scss" scoped>
.paragraph {
  color: $paragraph-color;
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  margin-bottom: $paragraph-line-height;
  margin-top: 0;
}
</style>