import axios from 'axios';
import { storeActiveDepartment } from '@/services';

export default {
  namespaced: true,
  state: {
    pending: false,
    resultsDepartment: '',
    closeLicensePopUpDetails: {},
    resetPasswordDetails: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      acceptConditions: false
    }
  },
  mutations: {
    setDepartmentResults(state, payload) {
      state.resultsDepartment = payload;
    },
    setCloseLicensePopUpDetails(state, payload) {
      state.closeLicensePopUpDetails = payload;
    },
    setResetPasswordDetails(state, payload) {
      state.resetPasswordDetails[payload.key] = payload.value;
    }
  },
  actions: {
    fetchDepartmentResults({ commit }) {
      return axios.get('SelecteerAfdeling/Init').then((response) => {
        if (response.data.PopupHeader && response.data.PopupText) {
          let closeLicenseTextDetails = {
            header: response.data.PopupHeader,
            text: response.data.PopupText
          };
          commit('setCloseLicensePopUpDetails', closeLicenseTextDetails);
        }

        // Go on and have user select a department
        commit('setDepartmentResults', response.data);
        return response.data;
      });
    },
    selectedDepartmentChange({ commit }, value) {
      storeActiveDepartment(value);
    },
    submitCreateFirstPasswordForm({ commit, state }) {
      return axios.post('Password/ChangePassword', state.resetPasswordDetails);
    },
    submitResetPasswordForm({ commit, state }, data) {
      // Submit the request to reset ones password
      return axios.post('Password/SendValidationEmail', data);
    },
    validateSetPasswordToken({ commit, state }, data) {
      // Before choosing a new password, check whether e-mail token querystring is valid (on app load)
      // return new Promise((resolve, reject) => { resolve() })
      return axios.post('Password/CheckValidationToken', data);
    },
    submitSetPasswordForm({ commit, state }, data) {
      // Submit final request to set a new password
      return axios.post('Password/ResetPassword', data);
    }
  },
  getters: {
    getIsPending: (state) => {
      return state.pending;
    },
    getDepartmentResults: (state) => {
      return state.resultsDepartment;
    },
    getCloseLicensePopUpDetails: (state) => {
      return state.closeLicensePopUpDetails;
    },
    getResetPasswordDetails: (state) => {
      return state.resetPasswordDetails;
    }
  }
};
