const recursiveComponents = function* (component) {
  yield component;
  if (component && component.$children) {
    for (let i = 0; i < component.$children.length; i++) {
      const item = component.$children[i];
      yield* recursiveComponents(item);
    }
  }
};

export async function $validateAllComponents(comp) {
  const allComponents = recursiveComponents(comp);
  const validations = [];
  while (true) {
    const component = allComponents.next().value;
    if (!component) {
      break;
    }

    if (component.validate) {
      validations.push(component.validate());
    }
  }

  const validationStatus = await Promise.all(validations);
  const containsInvalid = validationStatus.filter((v) => v.valid === false).length > 0;
  const isValid = !containsInvalid;
  return isValid;
}
