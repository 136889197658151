<template>
  <v-overlay v-if="overlayActive">
    <div v-if="showFeedback" class="feedbackForm">
      <a @click="closeOverlay"><v-close-icon class="feedbackForm__closeIcon" /></a>
      <h3>{{ feedback.Title }}</h3>
      <div class="row grid-margin">
        <div class="col-xs-12 feedbackForm__text">
          {{ feedback.Description }}
        </div>
      </div>
      <div class="row grid-margin feedbackForm__feedbackHolder">
        <div class="col-xs-8 grid-justify-content-left">Zeer onwaarschijnlijk</div>
        <div class="col-xs-4 grid-justify-content-right">Zeer waarschijnlijk</div>
      </div>
      <div class="row grid-margin feedbackForm__feedbackHolder">
        <v-button
          v-for="n in 10"
          :key="n"
          tagelement="button"
          cta
          md
          hollow
          class="feedbackForm__feedbackRating"
          :class="getActiveFeedbackClass(n)"
          @click.prevent="activeFeedbackRating(n)"
        >
          {{ n }}
        </v-button>
      </div>
      <div class="row grid-margin">
        <div class="col-xs-12">
          <v-textarea-group
            v-model="feedbackText"
            :maxlength="'500'"
            :label="feedback.CommentText"
            :inputid="'FeedbackText'"
            @input="saveInput"
          />
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-xs-12">
          <v-checkbox-group :inputid="'UserConsent'" :label="''" :initvalue="userConsent" v-model="userConsent">
            <span class="feedbackForm__text">{{ feedback.ConsentText }}</span>
          </v-checkbox-group>
        </div>
      </div>
      <div class="row grid-margin grid-justify-content-right">
        <div class="col-xs-3">
          <v-button
            tagelement="button"
            secondary
            lq
            :disabled="feedbackRatings == 0 || feedbackText == ''"
            @click.prevent="saveFeedbackForm()"
          >
            Versturen
          </v-button>
        </div>
      </div>
    </div>
    <div v-if="showFeedbackSuccess" class="feedbackForm">
      <div class="row grid-margin">
        <div class="col-xs-12">
          <v-check-circle class="feedbackForm__checkCircle" />
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-xs-12 feedbackForm__text feedbackForm__textAlign">Dank voor uw feedback!</div>
      </div>
      <div class="row grid-margin">
        <div class="feedbackForm__imgHolder">
          <img src="@/static/img/succes_mascot.png" class="feedbackForm__icon" />
        </div>
      </div>
      <div class="row grid-margin grid-justify-content-right">
        <div class="col-xs-3">
          <v-button class="btn btn--secondary btn--lq" @click.prevent="closeSuccessOverlay()"> Sluiten </v-button>
        </div>
      </div>
    </div>
  </v-overlay>
</template>

<script>
import VOverlay from '@/components/shared/Overlay.vue';
import VCloseIcon from 'icons/Close';
import VCheckCircle from 'icons/CheckCircle';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VFeedbackform',
  components: {
    VOverlay,
    VCloseIcon,
    VCheckCircle,
    VCheckboxGroup
  },
  setup() {
    const store = useStore();
    const feedback = computed(() => store.getters['feedback/getFeedbackConfiguration']);
    const saveFeedback = (value) => store.dispatch('feedback/saveFeedback', value);

    return {
      feedback,
      saveFeedback
    };
  },
  data() {
    return {
      feedbackRatings: 0,
      feedbackText: '',
      userConsent: false,
      overlayActive: false,
      showFeedback: false,
      showFeedbackSuccess: false
    };
  },
  created() {
    this.$emitter.on('showFeedbackForm', () => this.showOverlay());
  },
  methods: {
    saveInput(event) {
      this.feedbackText = event.target ? event.target.value : null;
    },
    showOverlay() {
      this.overlayActive = true;
      this.showFeedback = true;
      this.showFeedbackSuccess = false;
    },
    closeOverlay() {
      this.resetFormData();
      this.overlayActive = false;
    },
    closeSuccessOverlay() {
      this.overlayActive = false;
    },
    activeFeedbackRating(rating) {
      this.feedbackRatings = rating;
    },
    saveFeedbackForm() {
      let feedbackInput = { NPSScore: this.feedbackRatings, Comment: this.feedbackText, OptIn: this.userConsent };
      this.saveFeedback(feedbackInput).then((response) => {
        if (response.data.Success) {
          this.resetFormData();
          this.showFeedback = false;
          this.showFeedbackSuccess = true;
        }
      });
    },
    change(userConsent) {
      this.userConsent = userConsent;
    },
    resetFormData() {
      this.feedbackText = '';
      this.feedbackRatings = 0;
      this.showFeedback = false;
    },
    getActiveFeedbackClass(rating) {
      if (rating >= 1 && rating <= 6) {
        if (this.feedbackRatings === rating) {
          return 'feedbackForm__activeFeedbackRating';
        }
        if (rating === 1) {
          return 'feedbackForm__feedbackRatingLeftCorner';
        }
      } else if (rating >= 7 && rating <= 8) {
        if (this.feedbackRatings === rating) {
          return 'feedbackForm__activeFeedbackRating';
        }
      } else if (rating >= 9 && rating <= 10) {
        if (this.feedbackRatings === rating) {
          return 'feedbackForm__activeFeedbackRating';
        }
        if (rating === 10) {
          return 'feedbackForm__feedbackRatingRightCorner';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.feedbackForm {
  &__feedbackRating {
    font-weight: normal;
    width: 10%;
    height: 47.5px;
    border: solid 1px $gray;
    color: $gray-darkest;
    font-size: 19px;
    border-radius: 0px !important;
  }

  &__feedbackRating:hover {
    background-color: $cyan-med;
    border: solid 1px $cyan-med;
    color: $white;
  }

  &__activeFeedbackRating {
    background-color: $cyan-med;
    border: solid 1px $cyan-med;
    color: $white;
  }

  &__feedbackRatingLeftCorner {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  &__feedbackRatingRightCorner {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  &__text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $grayish-med-dark;
  }

  &__textAlign {
    text-align: center !important;
  }

  &__closeIcon {
    float: right;
    margin: -20px;
    cursor: pointer;
    fill: $black;
    :deep(svg) {
      width: 32px !important;
      height: 32px !important;
    }
  }

  .form-group {
    display: block;
    width: 100%;
    .form-group__input {
      display: block;
      textarea {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  &__feedbackHolder {
    color: #138b77;
    margin: 0px;
  }

  &__imgHolder {
    border: 2px solid $cyan-darker;
    border-radius: 50%;
    margin: 10px auto 20px auto;
    box-shadow: 0px 0px 5px $cyan-darker;
  }

  img {
    width: 250px;
    height: 250px;
  }

  &__checkCircle {
    fill: $cyan-darker;
    :deep(svg) {
      width: 45px !important;
      height: 45px !important;
    }
  }
}
</style>
