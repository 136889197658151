<template>
  <div :class="getWarningClass()">
    <div class="row">
      <div class="warning-wrapper" :class="showClose ? 'col-xs-11' : 'col-xs-12'">
        <v-information-outline
          title="Information"
          v-if="isInformation"
          class="warning-message__informationIcon"
        ></v-information-outline>
        <v-alert title="Alert" v-if="!isUrgent && !isInformation" class="warning-message__icon"></v-alert>
        <v-bell-alert
          title="Alert"
          v-else-if="isUrgent && !isInformation"
          class="warning-message__urgent"
        ></v-bell-alert>
        <span class="warning-message__warning" v-if="warning && !html">{{ warning }}</span>
        <span class="warning-message__warning" v-if="warning && html" v-html="warning"></span>
        <span class="warning-message__warning warning-message__customLink" v-if="customLink && customLink.combined">
          Probeer te zoeken op
          <a href="" v-if="customLink.postalCode" @click.prevent="toggle('postalcode')">postcode + huisnummer</a>
          <a href="" v-if="customLink.policyNumber" @click.prevent="toggle('policynumber')">polisnummer</a>
          <span> of <a href="" v-if="customLink.bsn" @click.prevent="toggle('bsn')">BSN.</a></span>
          Is het een nieuwe patiënt? Meld de patiënt eerst aan.
        </span>
        <span class="warning-message__warning warning-message__customLink" v-if="customLink && !customLink.combined">
          Probeer te zoeken op
          <a href="" v-if="customLink.bsn" @click.prevent="toggle('bsn')">BSN.</a>
        </span>
      </div>
      <div class="col-xs-1" v-if="showClose">
        <v-close class="warning-message__close" @click.prevent="close"></v-close>
      </div>
    </div>
    <div v-if="message">
      <div v-if="!html" class="warning-message__msg">{{ message }}</div>
      <div v-else class="warning-message__msg" v-html="message"></div>
    </div>
  </div>
</template>

<script>
import VAlert from 'icons/AlertCircle';
import VBellAlert from 'icons/Bell';
import VClose from 'icons/Close';
import VInformationOutline from 'icons/InformationOutline';

export default {
  name: 'VWarningMessage',
  components: {
    VAlert,
    VBellAlert,
    VClose,
    VInformationOutline
  },
  props: {
    warning: {
      type: String,
      required: false
    },
    message: {
      type: String,
      required: false
    },
    html: {
      type: Boolean,
      default: false
    },
    isUrgent: {
      type: Boolean,
      default: false
    },
    isInformation: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    customLink: {
      type: Object,
      required: false
    }
  },
  methods: {
    getWarningClass() {
      let classType = 'col-xs-12 warning-message normal';
      if (this.isUrgent) {
        classType = 'col-xs-12 warning-message urgent';
      } else if (this.isInformation) {
        classType = 'col-xs-12 warning-message information';
      }
      return classType;
    },
    close() {
      this.$emit('close');
    },
    toggle(searchKey) {
      this.$emit('toggle', searchKey);
    }
  }
};
</script>
<style lang="scss" scoped>
.normal {
  color: $dark-orange;
  background-color: $yellowish-pale;
  border-color: $colonial-white;
}
.urgent {
  color: $persian-red;
  background-color: $cherub;
  border-color: $azelea;
}
.information {
  color: $smooth-blue;
  background-color: $lightest-blue;
  border-color: #afd4ff;
  font-style: italic;
  font-weight: 400;
  line-height: 14.52px;
  border-radius: 3px;
}
.warning-message {
  position: relative;
  padding: 10px 10px 10px 10px;
  &__customLink {
    span {
      > a {
        color: $dark-orange !important;
      }
    }
    > a {
      color: $dark-orange !important;
    }
    cursor: pointer;
    margin-left: 0px !important;
  }
  &__close {
    cursor: pointer;
    fill: $gray;
    align-self: end;
  }
  &__urgent {
    fill: $persian-red;
    display: inline-block;
  }
  &__icon {
    fill: $dark-orange;
    display: inline-block;
  }
  .material-design-icon {
    :deep(svg) {
      height: 20px !important;
      width: 20px !important;
    }
  }
  &__msg {
    font-size: 12px;
    margin-left: 24px;
  }
  &__warning {
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
    vertical-align: super;
  }
  &__informationIcon {
    fill: $smooth-blue;
    display: inline-block;
  }
}
.warning-wrapper {
  display: inline-block;
}
</style>
