<template>
  <div>
    <div class="row">
      <!-- list search answer-->
      <div class="mb" v-if="listSearchItems">
        <vue-select
          class="text default"
          :options="listSearchItems"
          v-model="selectedItem"
          @option:selected="save($event)"
          placeholder="Zoek op medicatie"
          label="text"
        >
          <template #no-options="{}">
            <a href="" @click="showInputTextBox">{{ warningMessage.text }}</a>
          </template>
        </vue-select>
      </div>
    </div>
    <div class="row" v-if="showWarningMessage">
      <div class="col-xs-12 col-md-6">
        <v-input-group-simple-2
          :rules="{ required: false }"
          id="manualInput"
          v-model:value="manualInput"
          type="text"
          @input="enableSaveButton($event)"
        >
        </v-input-group-simple-2>
      </div>
      <div class="col-xs-12 col-md-2">
        <v-button :disabled="disbleSaveButton" @click.prevent="saveManualValue" lg hollow>
          {{ buttonText }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import VButton from '@/components/shared/Button.vue';
import VueSelect from 'vue-select';

export default {
  name: 'ListSearchAnswerType',
  components: {
    VueSelect,
    VButton
  },
  props: {
    listSearchItems: {
      type: Array,
      default: null
    },
    accountabilityResult: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const showWarningMessage = ref(false);
    const closeDropDown = ref(false);
    const disbleSaveButton = ref(true);
    const buttonText = ref('Ga verder');
    const selectedItem = ref('');
    const manualInput = ref('');
    const warningMessage = ref({
      value: 0,
      text: 'Geen resultaat gevonden, klik hier om zelf een antwoord in te vullen.'
    });

    const checkAccountabilityResult = () => {
      if (props.accountabilityResult && props.accountabilityResult.ListSearchItem) {
        let selectedValues = props.listSearchItems.filter((x) => x.text == props.accountabilityResult.ListSearchItem);
        if (selectedValues && selectedValues.length > 0) {
          selectedItem.value = selectedValues[0];
          showWarningMessage.value = false;
        } else {
          manualInput.value = props.accountabilityResult.ListSearchItem;
          showWarningMessage.value = true;
          buttonText.value = 'Opgeslagen';
          disbleSaveButton.value = false;
        }
      }
    };
    watch(
      () => props.listSearchItems,
      (values) => {
        if (values) {
          checkAccountabilityResult();
        }
      }
    );
    return {
      showWarningMessage,
      closeDropDown,
      buttonText,
      selectedItem,
      manualInput,
      warningMessage,
      checkAccountabilityResult,
      disbleSaveButton
    };
  },
  methods: {
    saveManualValue() {
      if (this.manualInput) {
        this.$emit('selected', this.manualInput);
        this.buttonText = 'Opgeslagen';
      }
    },
    save(e) {
      this.showWarningMessage = false;
      this.manualInput = '';
      if (e.value) {
        this.$emit('selected', e.text);
      }
    },
    enableSaveButton(e) {
      if (e.target.value) {
        this.manualInput = e.target.value;
        this.disbleSaveButton = false;
      } else {
        this.manualInput = '';
        this.disbleSaveButton = true;
      }
    },
    showInputTextBox(e) {
      e.preventDefault();
      this.selectedItem = '';
      this.manualInput = '';
      this.showWarningMessage = true;
      this.closeDropDown = true;
    }
  }
};
</script>

<style lang="scss" scoped>
legend {
  font-weight: bold;
  line-height: 1.4;
  color: $gray-darkest;
  font-size: inherit;
}

.mb {
  margin-bottom: 30px;
}

.default {
  min-width: 650px;
  font-family: 'Lato';
}
</style>
