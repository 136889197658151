import { createRouter, createWebHistory } from 'vue-router';
import { pushToRouter$ } from '@/router/router$';
import { isLoggedIn$, formLogout, currentUserIdentity$, setAxiosPortalCode } from '@/services';
import { first } from 'rxjs/operators';
import { nextTick } from 'vue';

// Components
import PatientDashBoard from '@/components/sections/patient/PatientDashBoard';
import PumpOverview from '@/components/sections/pumpOverview/PumpOverview';
import NewsOverview from '@/components/sections/news/NewsOverview';
import NewsDetail from '@/components/sections/news/NewsDetail';
import MyDepartment from '@/components/sections/mydepartment/MyDepartment';
import PrescriberDetails from '@/components/sections/prescriber/PrescriberDetails';
import Releases from '@/components/sections/releases/Releases';
import LoginPage from '@/components/sections/login/LoginPage';
import ResetPassword from '@/components/sections/login/ResetPassword';
import SetPassword from '@/components/sections/login/SetPassword';
import CreateFirstPassword from '@/components/sections/login/CreateFirstPassword';
import MainStandalone from '@/components/sections/MainStandalone';
import Main from '@/components/sections/Main';
import PatientHeader from '@/components/sections/patient/PatientHeader';
import PatientDetails from '@/components/sections/patient/PatientDetails';
import PatientAccountability from '@/components/sections/patient/accountability/PatientAccountability';
import PatientAccountabilityOverview from '@/components/sections/patient/accountability/PatientAccountabilityOverview';
import MyPatients from '@/components/sections/mypatients/MyPatientsListing';
import Empty from '@/components/sections/Empty';
import Page from '@/components/sections/content/Page';
import Catalog from '@/components/sections/catalog/Index';
import ProductDetail from '@/components/sections/catalog/productDetail/Index';
import Checkout from '@/components/sections/catalog/cart/Checkout';
import OrderConfirmation from '@/components/sections/catalog/cart/OrderConfirmation';
import StandAloneAccountability from '@/components/sections/patient/accountability/StandAloneAccountability';
import NewAuthorization from '@/components/sections/patient/accountability/NewAuthorization';
import GegevensWijzigen from '@/components/sections/prescriber/GegevensWijzigen';
import PageNotFound from '@/components/shared/PageNotFound';
import PrescriberRegistration from '@/components/sections/prescriber/registration/registration';
import ColleagueRegistration from '@/components/sections/prescriber/registration/colleagueRegistration/ColleagueRegistration';
import PrescriberRegistrationSuccess from '@/components/sections/prescriber/registration/registrationSuccessPage';
import Evaluation from '@/components/sections/osa/evaluation';
import OSAApproval from '@/components/sections/osa/approval.vue';
import OSAMedication from '@/components/sections/osa/medication';
import OSADelivery from '@/components/sections/osa/delivery';
import PumpAssignment from '@/components/sections/patient/PumpAssignment';
import Report from '@/components/sections/report/report';
import ClosedDepartment from '@/components/shared/ClosedDepartment';
import BundleProducts from '@/components/sections/catalog/BundleProducts';
import BundlesPage from '@/components/sections/patient/accountability/BundlesPage';
import MedbisOrderPage from '@/components/sections/medbis/MedbisOrderPage';
import BrowserBackRedirectionModal from '@/components/shared/BrowserBackRedirectionModal';
import ServiceCenter from '@/components/sections/serviceCenter/ServiceCenter';

let popStateDetected = false;
window.addEventListener('popstate', () => {
  popStateDetected = true;
});
// Flag to prevent multiple dataLayer pushes
let initialLoadDataLayerPushed = false;

const router = new createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
      }, 2000);
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/inloggen',
      name: 'inloggen',
      component: LoginPage,
      meta: {
        auth: false,
        fullWidthHeader: true
      }
    },
    {
      path: '/login',
      redirect: { name: 'inloggen' }
    },
    {
      path: '/geenlogin',
      component: MainStandalone,
      meta: { auth: false },
      children: [
        {
          path: 'herstelwachtwoord',
          name: 'herstelwachtwoord',
          meta: { auth: false },
          component: ResetPassword
        },
        {
          path: 'setpassword/:token',
          name: 'setpassword',
          meta: { auth: false },
          component: SetPassword
        }
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        auth: false,
        fullWidthHeader: true
      }
    },
    { path: '/registratie', redirect: '/registreren' },
    {
      path: '/registreren',
      name: 'prescriberRegistration',
      component: PrescriberRegistration,
      meta: {
        auth: false,
        fullWidthHeader: true
      }
    },
    {
      path: '/registrerensucces',
      name: 'prescriberRegistrationSuccess',
      component: PrescriberRegistrationSuccess,
      meta: {
        auth: false,
        fullWidthHeader: true
      }
    },
    {
      path: '/collega-registreren',
      name: 'colleagueRegistration',
      component: ColleagueRegistration,
      meta: {
        auth: true,
        fullWidthHeader: true
      }
    },
    {
      path: '/closeddepartment',
      name: 'closeddepartment',
      components: {
        default: ClosedDepartment
      },
      props: { default: true,},
      meta: {
        auth: true,
        fullWidthHeader: true 
      }
    },
    {
      path: '/changepassword',
      component: MainStandalone,
      meta: { auth: true },
      children: [
        {
          path: '/changepassword',
          name: 'changepassword',
          meta: { auth: true },
          component: CreateFirstPassword
        }
      ]
    },
    {
      path: '/',
      meta: {
        auth: true
      },
      component: Main,
      children: [
        {
          path: '/',
          name: 'main',
          component: async function () {
            return import('@/components/sections/dashboard/Index');
          },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: 'patientgegevens',
          name: 'patientgegevens',
          components: {
            default: PatientDetails,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/browserbackdetection',
          name: 'browserback',
          components: {
            default: BrowserBackRedirectionModal,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/patientdashboard',
          name: 'patientdashboard',
          component: PatientDashBoard,
          meta: {
            auth: true,
            fullWidthHeader: true,
            showShoppingCart: true
          }
        },
        {
          path: '/pumpassignment/:type/:licenseId',
          name: 'pumpassignment',
          components: {
            default: PumpAssignment,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            isBrowserBackDisabled: true
          }
        },
        {
          path: '/mijnpatienten',
          redirect: '/mijnpatienten/a'
        },
        {
          path: '/mijnpatienten/:q',
          name: 'mijnpatienten',
          component: MyPatients,
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/afdelingpatienten',
          redirect: '/afdelingpatienten/a'
        },
        {
          path: '/afdelingpatienten/:q',
          name: 'departmentPatients',
          component: MyPatients,
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/pompenoverzicht',
          name: 'pompenoverzicht',
          component: PumpOverview,
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: 'machtiging/:id',
          name: 'machtiging',
          components: {
            default: PatientAccountability,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: false
          }
        },
        {
          path: '/nieuwemachtiging',
          name: 'nieuwemachtiging',
          components: {
            default: NewAuthorization,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: 'machtiging-overzicht/:id',
          name: 'machtigingoverzicht',
          components: {
            default: PatientAccountabilityOverview,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: false,
            isBrowserBackDisabled: true
          }
        },
        {
          path: 'nieuws',
          name: 'nieuws',
          component: NewsOverview,
          meta: { auth: true, fullWidthHeader: true }
        },
        {
          path: 'nieuwsdetail/:id',
          name: 'nieuwsdetail',
          component: NewsDetail,
          meta: { auth: true, fullWidthHeader: true }
        },
        {
          path: 'pagina/:id',
          name: 'page',
          component: Page,
          meta: { auth: true, fullWidthHeader: true }
        },
        {
          path: 'catalog',
          name: 'catalog',
          components: {
            default: Catalog,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'customer',
            showShoppingCart: true
          }
        },
        {
          path: 'catalog/product/:id',
          name: 'productDetail',
          components: {
            default: ProductDetail,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'customer',
            showShoppingCart: true,
            catalogFilters: true
          }
        },
        {
          path: 'catalog/baseProduct/:id',
          name: 'baseProductDetail',
          components: {
            default: ProductDetail,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'customer',
            showShoppingCart: true,
            catalogFilters: true
          }
        },
        {
          path: 'assortiment',
          name: 'assortiment',
          component: Catalog,
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'assortment'
          }
        },
        {
          path: 'assortiment/baseProduct/:id',
          name: 'assortimentBaseProductDetail',
          component: ProductDetail,
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'assortment',
            catalogFilters: true
          }
        },
        {
          path: 'aanvullenmagazijn',
          name: 'aanvullenmagazijn',
          components: {
            default: Catalog,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'replenishment',
            hidePatientDetails: true,
            showShoppingCart: true
          }
        },
        {
          path: 'aanvullenmagazijn/product/:id',
          name: 'aanvullenmagazijnProductDetail',
          components: {
            default: ProductDetail,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'replenishment',
            hidePatientDetails: true,
            showShoppingCart: true,
            catalogFilters: true
          }
        },
        {
          path: 'catalog/bundel/:id',
          name: 'bundleProducts',
          components: {
            default: BundleProducts,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'customer',
            showShoppingCart: true
          }
        },
        {
          path: 'aanvullenmagazijn/baseProduct/:id',
          name: 'aanvullenmagazijnBaseProductDetail',
          components: {
            default: ProductDetail,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            flow: 'replenishment',
            showShoppingCart: true,
            catalogFilters: true
          }
        },
        {
          path: 'verantwoording/:id',
          name: 'verantwoording',
          components: {
            default: StandAloneAccountability,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            isBrowserBackDisabled: true
          }
        },
        {
          path: 'bestellijst',
          name: 'bestellijst',
          components: {
            default: Checkout,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            checkout: true,
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: 'orderconfirmation',
          name: 'orderconfirmation',
          component: OrderConfirmation,
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: 'magazijnaanvullenBestellijst',
          name: 'aanvullenmagazijnCheckout',
          components: {
            default: Checkout,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            checkout: true,
            auth: true,
            fullWidthHeader: true,
            flow: 'replenishment',
            hidePatientDetails: true
          }
        },
        {
          path: '/mijnafdeling',
          name: 'MyDepartment',
          component: MyDepartment,
          meta: { auth: false, fullWidthHeader: true }
        },
        {
          path: '/gegevens',
          name: 'mydetails',
          component: PrescriberDetails,
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/releases',
          name: 'latestReleaseInformation',
          component: Releases,
          meta: { auth: false, fullWidthHeader: true }
        },
        {
          path: 'gegevensWijzigen',
          name: 'gegevensWijzigen',
          components: {
            default: GegevensWijzigen,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: { auth: true, fullWidthHeader: true }
        },
        {
          path: '/service',
          name: 'ServiceCenter',
          component: ServiceCenter,
          meta: { auth: false, fullWidthHeader: true }
        },
        {
          path: '/osa/evaluatie',
          name: 'evaluation',
          components: {
            default: Evaluation,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/osa/uitlezing',
          name: 'readout',
          components: {
            default: Evaluation,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/osa/evaluatie-spt',
          name: 'posa-spt',
          components: {
            default: Evaluation,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/osa/akkoordverklaring/:type/:id/:licenseId?',
          name: 'approval',
          components: {
            default: OSAApproval,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/osa/akkoordverklaring-posa-spt/:type/:id/:licenseId?',
          name: 'approval-posa-spt',
          components: {
            default: OSAApproval,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/verneveling/medicatiewijziging/:licenseId',
          name: 'medication',
          components: {
            default: OSAMedication,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/bestellenMedBis',
          name: 'medbisOrderPage',
          components: {
            default: MedbisOrderPage
          },
          props: { default: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/osa/afleverbon/:licenseId',
          name: 'delivery',
          components: {
            default: OSADelivery,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/machtigingondertekend',
          name: 'bundles',
          components: {
            default: BundlesPage,
            patientheader: PatientHeader
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        },
        {
          path: '/rapportage',
          name: 'report',
          components: {
            default: Report
          },
          props: { default: true },
          meta: {
            auth: true,
            fullWidthHeader: true,
            hidePatientDetails: true
          }
        },
        {
          path: '*',
          name: '',
          components: {
            default: PageNotFound
          },
          props: { default: true, patientheader: true },
          meta: {
            auth: true,
            fullWidthHeader: true
          }
        }
      ]
    }
  ]
});

// Handles the NavigationDuplicated error on router push to current URL (navigation departments)
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

async function pushToDataLayer() {
  const [isLoggedIn, currentUserIdentity] = await Promise.all([
    isLoggedIn$.pipe(first()).toPromise(),
    currentUserIdentity$.pipe(first()).toPromise()
  ]);

  if (isLoggedIn) {
    const treatmentAreaIds = currentUserIdentity.userTreatmentArea.map(
      (treatmentArea) => treatmentArea.treatmentAreaId
    );
    window.dataLayer.push({
      event: 'login',
      userData: {
        platform_user_id: currentUserIdentity.externalId,
        role: '',
        treatmentArea: treatmentAreaIds
      }
    });
  }
}

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.auth);
  if (to.path === '/logout') {
    await formLogout();
    router.push('/inloggen');
    next();
    return;
  }

  if (!authRequired) {
    next();
    return;
  }

  const isLoggedIn = await isLoggedIn$.pipe(first()).toPromise();
  if (!isLoggedIn) {
    next('/inloggen');
    return;
  } else {
    if (from.path === '/inloggen' && isLoggedIn) {
      await pushToDataLayer();
    }
    const lastStored = window.localStorage.getItem('lastSelectedPortalCode');
    setAxiosPortalCode(lastStored);
  }

  if (to.path !== '/changepassword') {
    const currentUserIdentity = await currentUserIdentity$.pipe(first()).toPromise();
    if (currentUserIdentity && currentUserIdentity.requirePasswordChange) {
      next('/changepassword');
      return;
    }
  }

  if (popStateDetected && from.meta.isBrowserBackDisabled) {
    popStateDetected = false;
    next('/browserbackdetection');
    return;
  }

  next();
});

// Subject to hold active customer details
router.afterEach(function (route) {
  nextTick(function () {
    pushToRouter$(route);
    if (!initialLoadDataLayerPushed) {
      pushToDataLayer();
      initialLoadDataLayerPushed = true;
    }
  });
});

export default router;
