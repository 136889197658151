<template>
  <div class="prescriberRegistrationStep4">
    <!-- step 4 -->
    <div class="row grid-align-center">
      <div
        v-if="prescriberRegistrationDetails.showRoleSelection"
        class="col-md-12 prescriberRegistrationStep4__stepTitle"
      >
        Bij welke praktijk(en) werkt u?
      </div>
      <div v-else class="col-md-12 prescriberRegistrationStep4__stepTitle">
        {{ prescriberRegistrationTexts.Step4.PageTitle }}
      </div>
    </div>
    <div class="row grid-align-center">
      <div
        v-if="prescriberRegistrationDetails.showRoleSelection"
        class="col-md-6 prescriberRegistrationStep4__btnContainer"
      >
        <!-- Practice section -->
        <v-button
          v-for="(practiceDetails, index) in prescriberRegistrationDetails.practice"
          :key="index"
          md
          class="prescriberRegistrationStep4__customButton"
          :class="[practiceDetails.show ? 'btn--primary' : 'btn--hollow']"
          @click.prevent="setPracticeActive(index)"
        >
          Praktijk {{ index + 1 }}
          <v-close-circle-icon
            v-if="prescriberRegistrationDetails.practice.length > 1"
            :class="[
              practiceDetails.show
                ? 'prescriberRegistrationStep4__customButton__activeIcon'
                : 'prescriberRegistrationStep4__customButton__inactiveIcon'
            ]"
            @click.prevent="deletePractice($event, index)"
          />
          <v-check-circle-icon v-else class="prescriberRegistrationStep4__customButton__activeIcon" />
        </v-button>
        <v-button
          hollow
          md
          class="prescriberRegistrationStep4__customButton addInstitution"
          @click.prevent="addPractice()"
        >
          Praktijk Toevoegen<v-plus-circle-icon class="prescriberRegistrationStep4__customButton__addInstitution" />
        </v-button>
      </div>
      <div v-else class="col-md-6 prescriberRegistrationStep4__btnContainer">
        <!-- Institutions section -->
        <v-button
          v-for="(institutionDetails, index) in prescriberRegistrationDetails.institutions"
          :key="index"
          md
          class="prescriberRegistrationStep4__customButton"
          :class="[institutionDetails.show ? 'btn--primary' : 'btn--hollow']"
          @click.prevent="setInstitutionActive(index)"
        >
          Werkgever {{ index + 1 }}
          <v-close-circle-icon
            v-if="prescriberRegistrationDetails.institutions.length > 1"
            :class="[
              institutionDetails.show
                ? 'prescriberRegistrationStep4__customButton__activeIcon'
                : 'prescriberRegistrationStep4__customButton__inactiveIcon'
            ]"
            @click.prevent="deleteInstitution($event, index)"
          />
          <v-check-circle-icon v-else class="prescriberRegistrationStep4__customButton__activeIcon" />
        </v-button>
        <v-button
          hollow
          md
          class="prescriberRegistrationStep4__customButton addInstitution"
          @click.prevent="addInstitution()"
        >
          Werkgever Toevoegen<v-plus-circle-icon class="prescriberRegistrationStep4__customButton__addInstitution" />
        </v-button>
      </div>
    </div>
    <div v-if="prescriberRegistrationDetails.showRoleSelection">
      <v-prescriber-practice-details
        v-for="(practiceDetails, index) in prescriberRegistrationDetails.practice"
        :key="index"
        ref="practiceDetails"
        :index="index"
        :practice="practiceDetails"
      />
    </div>
    <div v-else>
      <v-prescriber-institution-details
        v-for="(institutionDetails, index) in prescriberRegistrationDetails.institutions"
        :key="index"
        ref="institutionDetails"
        :index="index"
        :institution="institutionDetails"
      />
    </div>

    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep4__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep4__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button v-if="getStepActionVisibilityStatus('next')" lg cta @click.prevent="nextStep()">
        VOLGENDE STAP
      </v-button>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import VCloseCircleIcon from 'icons/Delete';
import VPlusCircleIcon from 'icons/PlusCircleOutline';
import VCheckCircleIcon from 'icons/CheckCircleOutline';
import VPrescriberInstitutionDetails from './registrationInstitutionDetails';
import VPrescriberPracticeDetails from './registrationPracticeDetails';

export default {
  name: 'VRegistrationStep4',
  components: {
    VCloseCircleIcon,
    VPlusCircleIcon,
    VCheckCircleIcon,
    VPrescriberInstitutionDetails,
    VPrescriberPracticeDetails
  },
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );

    const setPreviousStepActive = () => store.commit('prescriberRegistration/setPreviousStepActive');
    const setNextStepActive = () => store.commit('prescriberRegistration/setNextStepActive');
    const addInstitutionDetails = () => store.commit('prescriberRegistration/addInstitutionDetails');
    const addPracticeDetails = () => store.commit('prescriberRegistration/addPracticeDetails');
    const deleteInstitutionDetails = (index) => store.commit('prescriberRegistration/deleteInstitutionDetails', index);
    const deletePracticeDetails = (index) => store.commit('prescriberRegistration/deletePracticeDetails', index);
    const setInstitutionActiveStore = (index) => store.commit('prescriberRegistration/setInstitutionActive', index);
    const setPracticeActiveStore = (index) => store.commit('prescriberRegistration/setPracticeActive', index);

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prescriberRegistrationTexts,
      setPreviousStepActive,
      setNextStepActive,
      addInstitutionDetails,
      addPracticeDetails,
      deleteInstitutionDetails,
      deletePracticeDetails,
      setInstitutionActiveStore,
      setPracticeActiveStore
    };
  },
  data() {
    return {
      nextStepClicked: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {},
  methods: {
    getStepActionVisibilityStatus(action) {
      if (action === 'prev') {
        return this.prescriberRegistrationSteps.findIndex((step) => step.show) !== 0;
      }
      return this.prescriberRegistrationSteps.findIndex((step) => step.show) < 5;
    },
    previousStep() {
      this.setPreviousStepActive();
    },
    nextStep() {
      if (!this.nextStepClicked) {
        this.nextStepClicked = true;
        if (this.prescriberRegistrationDetails.showRoleSelection) {
          for (let i = 0; i < this.prescriberRegistrationDetails.practice.length; i++) {
            this.$refs.practiceDetails[i].validateAllFields();
          }
          setTimeout(() => {
            this.checkAllPracticeValidated();
          }, 1000);
        } else {
          for (let i = 0; i < this.prescriberRegistrationDetails.institutions.length; i++) {
            this.$refs.institutionDetails[i].validateAllFields();
          }
          setTimeout(() => {
            this.checkAllInstitutionsValidated();
          }, 1000);
        }
      }
    },
    addInstitution() {
      this.addInstitutionDetails();
    },
    addPractice() {
      this.addPracticeDetails();
    },
    deleteInstitution(event, index) {
      event.stopPropagation();
      event.preventDefault();
      this.deleteInstitutionDetails(index);
    },
    deletePractice(event, index) {
      event.stopPropagation();
      event.preventDefault();
      this.deletePracticeDetails(index);
    },
    setInstitutionActive(index) {
      this.setInstitutionActiveStore(index);
    },
    setPracticeActive(index) {
      this.setPracticeActiveStore(index);
    },
    checkAllInstitutionsValidated() {
      let checkInstitutionValidatedFalseIndex = -1;
      for (let j = 0; j < this.prescriberRegistrationDetails.institutions.length; j++) {
        if (!this.prescriberRegistrationDetails.institutions[j].validated) {
          checkInstitutionValidatedFalseIndex = j;
          break;
        }
        for (let k = 0; k < this.prescriberRegistrationDetails.institutions[j].departments.length; k++) {
          if (!this.prescriberRegistrationDetails.institutions[j].departments[k].validated) {
            checkInstitutionValidatedFalseIndex = j;
            break;
          }
        }
        if (checkInstitutionValidatedFalseIndex !== -1) {
          break;
        }
      }
      if (checkInstitutionValidatedFalseIndex < 0) {
        this.setNextStepActive();
      } else {
        this.nextStepClicked = false;
        this.setInstitutionActive(checkInstitutionValidatedFalseIndex);
        setTimeout(() => {
          this.$refs && this.$refs.institutionDetails[checkInstitutionValidatedFalseIndex]
            ? this.$refs.institutionDetails[checkInstitutionValidatedFalseIndex].validateAllFields()
            : null;
        }, 2000);
      }
    },
    checkAllPracticeValidated() {
      let checkPracticeValidatedFalseIndex = -1;
      for (let j = 0; j < this.prescriberRegistrationDetails.practice.length; j++) {
        if (!this.prescriberRegistrationDetails.practice[j].validated) {
          checkPracticeValidatedFalseIndex = j;
          break;
        }
        for (let k = 0; k < this.prescriberRegistrationDetails.practice[j].departments.length; k++) {
          if (!this.prescriberRegistrationDetails.practice[j].departments[k].validated) {
            checkPracticeValidatedFalseIndex = j;
            break;
          }
        }
        if (checkPracticeValidatedFalseIndex !== -1) {
          break;
        }
      }
      if (checkPracticeValidatedFalseIndex < 0) {
        this.setNextStepActive();
      } else {
        this.nextStepClicked = false;
        this.setPracticeActive(checkPracticeValidatedFalseIndex);
        setTimeout(() => {
          this.$refs && this.$refs.practiceDetails[checkPracticeValidatedFalseIndex]
            ? this.$refs.practiceDetails[checkPracticeValidatedFalseIndex].validateAllFields()
            : null;
        }, 2000);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.prescriberRegistrationStep4 {
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__btnContainer {
    margin: 0 auto;
    display: inline-block;
  }
  &__customButton {
    margin: 10px 10px 30px 0px;
    float: left;
    &__activeIcon {
      margin-left: 10px;
      :deep(svg) {
        fill: $teal-lighter !important;
      }
    }
    &__inactiveIcon {
      margin-left: 10px;
    }
    &__addInstitution {
      margin-left: 10px;
    }
    &__department {
      margin: 5px auto 30px auto;
      &__icon {
        margin-right: 10px;
        :deep(svg) {
          fill: $teal-darkest !important;
        }
      }
    }
  }
  &__actions {
    float: right;
    margin: 1% 25% 5% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  .addInstitution {
    border-style: dashed !important;
    font-weight: 500;
    font-size: 14px;
  }
}
</style>
