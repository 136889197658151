<template>
  <div
    class="form-group"
    :class="{
      'form-group--focused': isFocused,
      'form-group--hasvalue': value,
      'form-group--disabled': disabled,
      'form-group--error': errorMessage
    }"
  >
    <label :for="inputid">{{ label }}<span v-if="rule.required && label" class="asterisk"> *</span></label>
    <div class="form-group__input">
      <input
        :id="inputid"
        v-model="value"
        :disabled="disabled"
        :name="inputid"
        :maxlength="maxlength"
        :type="inputType"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @input="update"
        @focus="focus"
        @blur="blur"
      />
      <span v-if="showHidePasswordIcon">
        <v-eye-off
          v-if="!showPassword"
          class="passwordIcon greyColor"
          title="Toon wachtwoord"
          @click.prevent="changePasswordVisibility()"
        />
        <v-eye
          v-else
          title="Verberg wachtwoord"
          class="passwordIcon greenColor"
          @click.prevent="changePasswordVisibility()"
        />
      </span>
    </div>
    <div class="form-group__details" v-if="errorMessage">
      <div class="form-group__messages">
        <div class="form-group__error">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VEye from 'icons/EyeOutline';
import VEyeOff from 'icons/EyeOffOutline';
import { useField } from 'vee-validate';
import { generateYupSchema } from '@/composables/globalHelperFunctions';
import { ref, watch, onBeforeMount } from 'vue';

export default {
  name: 'VInputGroup',
  components: {
    VEye,
    VEyeOff
  },
  props: {
    label: {
      default: '',
      type: String
    },
    inputid: {
      default: 'inputid',
      type: String
    },
    maxlength: {
      default: '',
      type: String
    },
    initvalue: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    rule: {
      default: () => ({}),
      type: Object
    },
    error: {
      default: false,
      type: Boolean
    },
    type: {
      default: 'text',
      type: String
    },
    autocomplete: {
      default: 'off',
      type: String
    },
    overwriteValue: [String, null],
    placeholder: {
      default: '',
      type: String
    },
    showHidePasswordIcon: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const schema = ref(generateYupSchema({ label: props.label ? props.label : props.inputid, rules: props.rule }));
    const { value, errorMessage, validate } = useField(props.inputid, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema(props);
      }
    );
    watch(
      () => props.overwriteValue,
      (newValue) => {
        value.value = newValue;
      }
    );
    onBeforeMount(() => {
      if (props.initvalue) {
        value.value = ref(props.initvalue);
      }
    });

    return {
      value,
      errorMessage,
      validate
    };
  },
  data() {
    return {
      isFocused: false,
      showPassword: false,
      inputType: 'text'
    };
  },
  created() {
    this.inputType = this.type;
  },
  methods: {
    update(event) {
      this.value = event.target.value;
      this.$emit('update:modelValue', event.target.value);
    },
    focus() {
      this.isFocused = true;
    },
    blur() {
      this.isFocused = false;
      this.$emit('blur');
    },
    changePasswordVisibility(e) {
      this.showPassword = !this.showPassword;
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    }
  }
};
</script>
<style lang="scss" scoped>
.passwordIcon {
  fill: $black;
  z-index: 100;
  cursor: pointer;
}
.greyColor {
  fill: $gray !important;
}
.greenColor {
  fill: $dark-green !important;
}
.form-group {
  $root: &;
  .form-group__details:before {
    background-image: none;
  }
  &.form-group--disabled {
    background: $gray-lighter;
    border-radius: 8px;
    label {
      padding: 8px 0px 0px 15px;
    }
    input {
      padding: 10px 0px 0px 10px;
    }
  }
}
</style>
