<template>
  <form @submit.prevent="submitContactDetails">
    <v-heading v-if="hasPatientData" :level="3"> Patiëntgegevens </v-heading>
    <div v-if="hasPatientData" class="row grid-margin">
      <div class="col-xs-8">
        <v-input-group-simple-2
          id="patientName"
          v-model:value="patientName"
          :rules="{
            required: false
          }"
          :disabled="true"
          type="text"
          label="Naam"
        />
      </div>
      <div class="col-xs-4">
        <v-input-group-simple-2
          id="birthDate"
          v-model:value="birthDate"
          :rules="{
            required: false
          }"
          :disabled="true"
          type="text"
          label="Geboortedatum"
        />
      </div>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12">
        <label>Selecteer hieronder het behandelgebied waarover u een vraag/opmerking heeft *</label>
        <model-select
          id="treatmentAreaId"
          placeholder="Selecteer behandelgebied"
          v-model="treatmentAreaItem"
          :options="treatmentAreas"
          :selected-option="treatmentAreaItem"
          @blur="selectTratmentArea"
        >
        </model-select>
        <div class="form-group-simple__details" v-show="showTreatmentAreaErrorMessage">
          <div class="form-group-simple__messages">
            <div class="form-group-simple__error">* Dit veld is verplicht.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12">
        <label for="selectLicense">Wat is het onderwerp van uw vraag *</label>
        <model-select
          id="subjectId"
          placeholder="Selecteer onderwerp"
          search-text="search"
          v-model="subjectItem"
          :options="subjectTypes"
          :selected-option="subjectItem"
          @blur="selectSubject"
        >
        </model-select>
        <div class="form-group-simple__details" v-show="showSubjectErrorMessage">
          <div class="form-group-simple__messages">
            <div class="form-group-simple__error">* Dit veld is verplicht.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12">
        <legend>Uw bericht *</legend>
        <v-textarea-group
          inputid="Uw bericht"
          @input="saveInput"
          v-model="contactDetails.body"
          :overwrite-value="contactDetails.body"
        />
        <div v-show="showErrorMessage" class="form-group-simple__details">
          <div class="form-group-simple__messages">
            <div class="form-group-simple__error">* Dit veld is verplicht.</div>
          </div>
        </div>
      </div>
    </div>
    <v-button tagelement="button" cta md hollow :disabled="formSubmitted">Versturen</v-button>
  </form>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore, mapActions } from 'vuex';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';
import { ModelSelect } from 'vue-search-select';
import { tap } from 'rxjs/operators';
import { activePpwRecId$, availablePortals$, allTreatmentAreas$ } from '@/services';

export default {
  name: 'ContactForm',
  components: {
    ModelSelect
  },
  setup() {
    const store = useStore();
    const activePpwRecId = ref(null);
    const availablePortals = ref(null);
    const allTreatmentAreas = ref(null);
    const treatmentAreas = ref([]);

    const getTreatmentAreas = (values) => {
      values.forEach((element) => {
        let detail = {
          text: element.description,
          value: element.id
        };
        treatmentAreas.value.push(detail);
      });
    };

    activePpwRecId$.subscribe((value) => {
      activePpwRecId.value = value;
    });
    availablePortals$.subscribe((value) => {
      availablePortals.value = value;
    });
    allTreatmentAreas$.subscribe((value) => (allTreatmentAreas.value = value));
    allTreatmentAreas$
      .pipe(
        tap((value) => {
          getTreatmentAreas(value);
        })
      )
      .subscribe();
    const patientData = computed(() => store.getters['patient/getPatientGegevens']);
    const subjectTypes = computed(() => store.getters['contact/getSubjectTypes']);

    return {
      patientData,
      subjectTypes,
      activePpwRecId,
      availablePortals,
      allTreatmentAreas,
      treatmentAreas,
      ...mapActions({
        submitContactForm: 'contact/submitContactForm'
      })
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      contactDetails: {
        subject: '',
        body: '',
        ppwRecId: '',
        activePortalCode: '',
        treatmentAreaId: '0'
      },
      subjectItem: {
        value: null,
        text: null
      },
      treatmentAreaItem: {
        value: null,
        text: null
      },
      showErrorMessage: false,
      showTreatmentAreaErrorMessage: false,
      showSubjectErrorMessage: false,
      formSubmitted: false
    };
  },
  computed: {
    patientName() {
      if (this.patientData) {
        return `${this.aanhefFormat(this.patientData.GeslachtId)}${this.patientData.Voorletters} ${
          this.patientData.Achternaam
        }`;
      } else {
        return '';
      }
    },
    birthDate() {
      if (this.patientData) {
        const d = new Date(this.patientData.GeboorteDatum);
        return d.toLocaleString('nl', { day: '2-digit', month: 'long', year: 'numeric' });
      } else {
        return '';
      }
    },
    hasPatientData() {
      return Object.keys(this.patientData).length > 0;
    }
  },
  created() {},
  methods: {
    aanhefFormat(value) {
      if (value === 1) {
        return 'Dhr. ';
      } else if (value === 2) {
        return 'Mevr. ';
      } else {
        return '';
      }
    },
    submitContactDetails() {
      this.contactDetails.treatmentAreaId = this.treatmentAreaItem.value;
      this.contactDetails.subject = this.subjectItem.text;
      $validateAllComponents(this)
        .then((validated) => {
          if (this.contactDetails.treatmentAreaId == '0') {
            this.showTreatmentAreaErrorMessage = true;
            return;
          }
          if (!this.contactDetails.subject) {
            this.showSubjectErrorMessage = true;
            return;
          }
          if (!this.contactDetails.body) {
            this.showErrorMessage = true;
            return;
          }
          if (validated) {
            this.formSubmitted = true;
            this.contactDetails.ppwRecId = this.activePpwRecId;
            this.contactDetails.activePortalCode = this.availablePortals.activePortalCode;

            this.submitContactForm(this.contactDetails).then(() => {
              this.$emit('success');
              this.contactDetails = {
                subject: '',
                body: '',
                ppwRecId: '',
                activePortalCode: '',
                treatmentAreaId: '0'
              };
              this.subjectItem = {
                value: null,
                text: null
              };
              this.treatmentAreaItem = {
                value: null,
                text: null
              };
              this.formSubmitted = false;
            });
          } else {
          }
        })
        .catch(() => {});
    },
    saveInput(event) {
      this.contactDetails.body = event.target ? event.target.value : null;
      if (this.contactDetails.body) {
        this.showErrorMessage = false;
      } else {
        this.showErrorMessage = true;
      }
    },
    selectTratmentArea() {
      this.showTreatmentAreaErrorMessage = false;
    },
    selectSubject() {
      this.showSubjectErrorMessage = false;
    },
    resetForm() {
      (this.contactDetails = {
        subject: '0',
        body: '',
        treatmentAreaId: '0'
      }),
        (this.treatmentAreaItem = {
          value: '',
          text: ''
        });
      this.subjectItem = {
        value: '',
        text: ''
      };
      this.formSubmitted = false;
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  padding: 24px;
  legend {
    font-weight: $form-group-simple-label-font-weight;
    color: $gray-dark;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin-bottom: 14px;
  }
}
</style>
