<template>
  <div class="header-accountability">
    <div class="row grid-justify-content-end grid-align-center">
      <div class="col-xs-12 col-md-9 col-lg-11 col-width-left-col">
        <v-heading
          v-if="isNewAccountability"
          :level="3"
        >
          Machtiging aanvragen
        </v-heading>
        <v-heading
          v-if="!isNewAccountability"
          :level="3"
        >
          Machtiging wijzigen
        </v-heading>
        <v-heading :level="5">
          {{ title }}
        </v-heading>
      </div>
      <div class="col-xs-12 col-md-3 col-lg-1 col-width-right-col">
        <v-button
          link
          @click="openForwardModal"
        >
          <v-forward-icon class="icon-color-teal" />Doorsturen naar collega
        </v-button>
      </div>
    </div>
    <generic-modal v-if="fwAccountabilityPopUp">
      <template #header>
        {{ forwardAccountabilityPopup.PopupTitle }}
      </template>
      <template #body>
        <p>{{ forwardAccountabilityPopup.PopupText }}</p>
      </template>
      <template #footer>
        <router-link
          :to="'/patientdashboard'"
          class="btn btn--primary btn--md"
        >
          {{ forwardAccountabilityPopup.PopupButton }}
        </router-link>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { computed } from 'vue'
import VForwardModal from './ForwardModal'
import VForwardIcon from 'icons/ArrowRightBoldBox'
import GenericModal from '@/components/shared/GenericModal'

export default {
  name: 'PatientAccountabilityHeader',
  components: {
    VForwardIcon,
    GenericModal
  },
  props: {
    title: {
      default: '',
      type: String
    },
    isNewAccountability: {
      default: false,
      type: Boolean
    },
    accountabilityId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const forwardAccountabilityPopup = computed(() => store.getters['accountability/getForwardAccountabilityPopup']);

    return {
      forwardAccountabilityPopup,
      ...mapActions({
        forwardAccountability: 'accountability/forwardAccountability'
      }),
    }
  },
  data () {
    return {
      fwAccountabilityPopUp: false
    }
  },
  methods: {
    async openForwardModal () {
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Machtiging doorsturen',
          message: 'Naar welke collega wilt u de machtiging doorsturen?',
          confirmButton: 'Doorsturen',
          component: VForwardModal,
          resolve,
          reject
        })
      })
      .then((select) => {
        this.forwardAccountability({
          ppwRecId: select.colleagueSelected.ppwRecId,
          comment: select.comment,
          accountabilityId: this.accountabilityId
        }).then(() => {
          this.fwAccountabilityPopUp = true
        }).catch(() => {
          this.$emitter.emit('alert', { type: 3, message: 'Uw conceptaanvraag is mislukt.' })
        })
      })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-accountability {
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        padding: grid-gutter($breakpoint-key);
      }
    }

    background: $white;
    h3.heading {
      margin:0;
      font-weight:bold;
    }
    h5.heading {
      margin:0;
      font-weight:normal;
    }
  }
  .btn.btn--link {
    color: $teal-darkest;
    justify-content: flex-end;
    white-space: nowrap;
  }
  .icon-color-teal {
    padding-right: 6px;
    fill: $teal-darkest;
  }
  .col-width-left-col{
    flex: 0 0 81.66667%;
    max-width: 81.66667%;
  }
   .col-width-right-col{
    flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
</style>
