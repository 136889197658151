<template>
  <div class="prescriberRegistrationStep2">
    <!-- step 2 -->
    <div class="row grid-align-center">
      <div class="col-md-12 prescriberRegistrationStep2__stepTitle">
        {{ prescriberRegistrationTexts.Step2.PageTitle }}
      </div>
      <div class="col-md-6 prescriberRegistrationStep2__block">
        <div class="prescriberRegistrationStep2__block__header">Uw (zakelijke) gegevens</div>
        <p class="prescriberRegistrationStep2__stepSubtitle">
          Voor een correcte gegevensverwerking en verificatie van uw identiteit hebben wij uw correcte gegevens nodig.
          Indien uw gegevens niet juist zijn kunnen wij uw account niet aanmaken.
        </p>
        <div class="prescriberRegistrationStep2__block__content">
          <div class="row grid-padding prescriberRegistrationStep2__block__content__section">
            <div class="col-xs-4 prescriberRegistrationStep2__block__content__section__gender">
              <span class="form-group__radio">
                <input
                  id="prescriberGenderMale"
                  :checked="prescriberRegistrationDetails.titleId === 1"
                  name="gender"
                  type="radio"
                  value="De heer"
                  @change="checkRadioAnswer(1)"
                />
                <label for="prescriberGenderMale"><span></span>De heer</label>
              </span>
              <span class="form-group__radio">
                <input
                  id="prescriberGenderFemale"
                  :checked="prescriberRegistrationDetails.titleId === 2"
                  name="gender"
                  type="radio"
                  value="Mevrouw"
                  @change="checkRadioAnswer(2)"
                />
                <label for="prescriberGenderFemale"><span></span>Mevrouw</label>
              </span>
            </div>
            <div class="col-xs-4">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.initials"
                :initvalue="prescriberRegistrationDetails.initials"
                :rule="{ required: true }"
                reference="Voorletters"
                inputid="initials"
                placeholder="Voorletters*"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-4">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.firstName"
                :initvalue="prescriberRegistrationDetails.firstName"
                :rule="{ required: false }"
                inputid="firstName"
                reference="Voornaam"
                placeholder="Voornaam"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding prescriberRegistrationStep2__block__content__section">
            <div class="col-xs-4">
              <v-select-group
                v-model="prescriberRegistrationDetails.prePositionCode"
                selectid="prePositionCode"
                :overwrite-value="prescriberRegistrationDetails.prePositionCode"
                :initselected="prescriberRegistrationDetails.prePositionCode"
                @change="select($event)"
                placeholder="Selecteren Tussenvoegsel"
              >
                <option
                  v-for="(item, index) in prePositionsData"
                  :key="index"
                  :data-index="index"
                  :value="item.prePositionCode"
                >
                  {{ item.prePosition }}
                </option>
              </v-select-group>
            </div>
            <div class="col-xs-8">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.lastName"
                :initvalue="prescriberRegistrationDetails.lastName"
                :rule="{ required: true }"
                inputid="lastName"
                reference="Achternaam"
                placeholder="Achternaam*"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding prescriberRegistrationStep2__block__content__section">
            <div class="col-xs-7">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.maidenName"
                :initvalue="prescriberRegistrationDetails.maidenName"
                :rule="{ required: false }"
                inputid="maidenName"
                reference="Geboortenaam"
                placeholder="Geboortenaam (vh meisjesnaam)"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-5">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.dateOfBirth"
                :initvalue="prescriberRegistrationDetails.dateOfBirth"
                :rule="{ required: false, regex: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/ }"
                inputid="dateOfBirth"
                reference="Geboortedatum"
                placeholder="Geboortedatum (dd-mm-jjjj)"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding prescriberRegistrationStep2__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.phoneNumber"
                maxlength="10"
                :initvalue="prescriberRegistrationDetails.phoneNumber"
                :rule="{
                  required: false,
                  regex: /^0\d{9}$/
                }"
                inputid="phoneNumber"
                reference="Telefoonnummer (vast)"
                placeholder="Telefoonnummer (vast)"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.mobileNumber"
                maxlength="10"
                :initvalue="prescriberRegistrationDetails.mobileNumber"
                :rule="{ required: false, regex: /^0\d{9}$/ }"
                inputid="mobileNumber"
                reference="Telefoonnummer (mobiel)"
                placeholder="Telefoonnummer (mobiel)"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding prescriberRegistrationStep2__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="prescriberRegistrationDetails.email"
                :initvalue="prescriberRegistrationDetails.email"
                :rule="{ required: true, email: true, regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/ }"
                inputid="email"
                reference="E-mailadres"
                placeholder="Uw persoonlijke, zakelijke e-mailadres*"
                @blur="validateField"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSuccessMessage" class="row grid-align-center" :class="{ 'flipInX animated': animated }">
      <div class="col-md-12 prescriberRegistrationStep2__successMessage">
        <v-check-circle-icon class="prescriberRegistrationStep2__successMessage__icon" />
        <span>Bovenstaande gegevens zijn gecontroleerd</span>
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep2__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep2__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button v-if="getStepActionVisibilityStatus('next')" lg cta @click.prevent="nextStep()">
        VOLGENDE STAP
      </v-button>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import VCheckCircleIcon from 'icons/CheckCircle';
import { useForm } from 'vee-validate';

export default {
  name: 'VRegistrationStep2',
  components: {
    VCheckCircleIcon
  },
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );
    const prePositionsData = computed(() => store.getters['prescriberRegistration/getPrePositionsRecords']);

    const setPreviousStepActive = () => store.commit('prescriberRegistration/setPreviousStepActive');
    const setNextStepActive = () => store.commit('prescriberRegistration/setNextStepActive');
    const setPrescriberRegistrationDetailsStore = (value) =>
      store.commit('prescriberRegistration/setPrescriberRegistrationDetails', value);

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prescriberRegistrationTexts,
      prePositionsData,
      setPreviousStepActive,
      setNextStepActive,
      setPrescriberRegistrationDetailsStore
    };
  },
  data() {
    const { validate, handleSubmit } = useForm();
    return {
      validate,
      showSuccessMessage: false,
      animated: false,
      nextStepClicked: false,
      focusedField: null
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {},
  methods: {
    getStepActionVisibilityStatus(action) {
      if (action === 'prev') {
        return this.prescriberRegistrationSteps.findIndex((step) => step.show) !== 0;
      }
      return this.prescriberRegistrationSteps.findIndex((step) => step.show) < 5;
    },
    previousStep() {
      this.setPreviousStepActive();
    },
    nextStep() {
      if (!this.showSuccessMessage) {
        this.nextStepClicked = true;
        this.validateAllFields();
      }
    },
    validateField(ref, model) {
      this.savePrescriberRegistrationDetails({
        key: ref,
        value: model
      });
      this.nextStepClicked = false;
    },
    select(event) {
      if (event.currentTarget.value) {
        this.prescriberRegistrationDetails.prePosition =
          event.currentTarget.options[event.currentTarget.options.selectedIndex].text;
        this.prescriberRegistrationDetails.prePositionCode = event.currentTarget.value;
        this.savePrescriberRegistrationDetails({
          key: 'prePosition',
          value: event.currentTarget.options[event.currentTarget.options.selectedIndex].text
        });
        this.savePrescriberRegistrationDetails({
          key: 'prePositionCode',
          value: event.currentTarget.value
        });
      }
    },
    async validateAllFields() {
      const result = await this.validate();
      if (result.valid) {
        this.animated = true;
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.animated = false;
          this.setNextStepActive();
        }, 2000);
      } else {
        this.focusOnErrorField(result.results);
      }
    },
    focusOnErrorField(results) {
      const priorityFields = ['initials', 'lastName', 'email'];
      for (const fieldName of priorityFields) {
        if (!results[fieldName].valid) {
          this.focusedField = fieldName;
          break;
        }
      }
      if (this.focusedField) {
        document.querySelector('#' + this.focusedField).focus();
      }
    },
    checkRadioAnswer(value) {
      this.savePrescriberRegistrationDetails({
        key: 'titleId',
        value: value
      });
    },
    savePrescriberRegistrationDetails(prescriberRegistrationDetails) {
      this.setPrescriberRegistrationDetailsStore(prescriberRegistrationDetails);
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriberRegistrationStep2 {
  :deep(.form-group) {
    padding: 0px;
    label {
      display: none;
    }
  }
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__stepSubtitle {
    padding: 0 40px 0 40px;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__content {
      padding-bottom: 30px;
      &__section {
        margin: 0 15px 15px 15px;
        span {
          float: left;
          margin: 12px 8px 0 0;
        }
        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }
    }
  }
  &__actions {
    float: right;
    margin: 1% 25% 5% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  &__successMessage {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 30px 0px 30px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      vertical-align: middle;
      :deep(svg) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  :deep(.form-group__radio input[type='radio'] + label) {
    color: $gray-dark;
    font-size: 16px;
  }
}
</style>
