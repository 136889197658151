<template>
  <div class="form-group-simple uploadBoxCompact">
    <form role="form" enctype="multipart/form-data">
      <div v-if="!itemsAdded" class="uploadBoxMain">
        <div class="form-group">
          <div
            class="dropArea"
            :class="dragging ? 'dropAreaDragging' : ''"
            @ondragover="onChange"
            @dragenter="dragging = true"
            @dragend="dragging = false"
            @dragleave="dragging = false"
          >
            <div class="primaryMessage">
              {{ dropAreaPrimaryMessage }}
            </div>
            <input :id="id" type="file" :name="name" required multiple @change="onChange" />
            <div class="secondaryMessage">
              {{ dropAreaSecondaryMessage }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="uploadBoxMain">
        <v-table>
          <thead>
            <v-header-row :data="pdfTableHeaders" />
          </thead>
          <tbody>
            <tr v-for="(itemsName, index) in itemsNames" :key="index">
              <td />
              <td v-if="accountabilityUpload">
                <v-select-group
                  :value="fileType"
                  required
                  :initselected="fileType"
                  selectid="fileType"
                  @change="changeFileType"
                  placeholder="Selecteer documenttype"
                >
                  <option
                    v-for="(item, index) in uploadedFilesTypes"
                    :key="index"
                    :data-index="index"
                    :value="item.key"
                  >
                    {{ item.value }}
                  </option>
                  >
                </v-select-group>
              </td>
              <td v-else>Uitvoeringsverzoek</td>
              <td class="fileName">
                {{ itemsName.split('.').shift() }}
              </td>
              <td v-if="index === 0" colspan="2" :rowspan="itemsNames.length" class="uploadActions">
                <v-delete-icon
                  v-tooltip="{ content: 'Document verwijderen', placement: 'right', html: false }"
                  @click.prevent="removeItems"
                />
                <button
                  type="button"
                  class="btn btn--secondary btn--md"
                  :class="{
                    'btn--disabled':
                      itemsAdded < minItems ||
                      itemsAdded > maxItems ||
                      validationError ||
                      sizeError ||
                      fileAlreadyExistsError ||
                      specialCharectersError ||
                      isDocumentTypeSelectedComputed == false
                  }"
                  @click="onSubmit"
                >
                  {{ uploadButtonMessage }}
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div v-if="successMsg !== ''" class="successMsg">
                  {{ successMsg }}
                </div>
                <div v-if="errorMsg !== ''" class="errorMsg">
                  {{ fileUploadErrorMessage }}:<br />{{ errorMsg }} <br />{{ retryErrorMessage }}
                </div>
                <div v-if="itemsAdded && itemsAdded < minItems" class="errorMsg">
                  {{ minFilesErrorMessage }}: {{ minItems }}. <br />{{ retryErrorMessage }}
                </div>
                <div v-if="itemsAdded && itemsAdded > maxItems" class="errorMsg">
                  {{ maxFilesErrorMessage }}: {{ maxItems }}. <br />{{ retryErrorMessage }}
                </div>
                <div v-if="validationError || isIncorrectPdf" class="errorMsg">
                  {{ extensionErrorMessage }}
                </div>
                <div v-if="sizeError" class="errorMsg">
                  {{ sizeErrorMessage }}
                </div>
                <div v-if="fileAlreadyExistsError" class="errorMsg">
                  {{ fileExistsMessage }}
                </div>
                <div v-if="isDocumentTypeSelectedComputed == false" class="errorMsg">
                  {{ documentTypeNotSelectedError }}
                </div>
                <div v-if="specialCharectersError" class="errorMsg">
                  {{ specialCharectersErrorMessage }}
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <div v-if="isLoaderVisible" class="loader">
          <div class="loaderImg" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store';
import VTable from '@/components/shared/table/Table';
import VDeleteIcon from 'icons/Delete';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import axios from 'axios';

export default {
  name: 'InputFileUploadCompact',
  components: {
    VTable,
    VHeaderRow,
    VDeleteIcon
  },
  props: {
    minItems: {
      type: Number,
      default: 1
    },
    maxItems: {
      type: Number,
      default: 100
    },
    dropAreaPrimaryMessage: {
      type: String,
      default: 'Sleep hier uw bestand'
    },
    dropAreaSecondaryMessage: {
      type: String,
      default: '(max. 2MB; alleen .pdf)'
    },
    fileNameMessage: {
      type: String,
      default: 'Geüploade bestanden'
    },
    uploadButtonMessage: {
      type: String,
      default: 'Uploaden'
    },
    cancelButtonMessage: {
      type: String,
      default: 'Annuleren'
    },
    fileUploadErrorMessage: {
      type: String,
      default: 'An error has occurred'
    },
    minFilesErrorMessage: {
      type: String,
      default: 'Minimum files that need to be added to uploader'
    },
    maxFilesErrorMessage: {
      type: String,
      default: 'Het maximaal aantal te uploaden documenten is'
    },
    retryErrorMessage: {
      type: String,
      default: 'Verwijder de documenten door op annuleren te drukken en het opnieuw te proberen.'
    },
    extensionErrorMessage: {
      type: String,
      default: 'Het geüploade document heeft niet de juiste extensie. U kunt alleen .pdf documenten uploaden.'
    },
    sizeErrorMessage: {
      type: String,
      default: 'Het geüploade document is te groot. Het document mag niet groter zijn dan 2MB.'
    },
    specialCharectersErrorMessage: {
      type: String,
      default:
        "Het is niet toegestaan om speciale tekens (bijvoorbeeld:  é,è,ë,ê,ç,@,&,%,',”) te gebruiken in de bestandsnaam. De volgende tekens zijn wel toegestaan: ‘.' ‘,’ ‘-’ ‘_’ ‘(’ ')’. Pas de bestandsnaam aan en upload opnieuw."
    },
    allowedExtenstions: {
      type: Array,
      default: () => ['pdf']
    },
    fileExistsMessage: {
      type: String,
      default: 'Bestandsnaam met een zelfde titel is all geüpload. Past de titel aan of upload een ander document.'
    },
    documentTypeNotSelectedError: {
      type: String,
      default: 'Selecteer documenttype.'
    },
    uploadedFilesData: {
      type: Array,
      default: () => []
    },
    accountabilityUpload: {
      type: Boolean,
      default: false
    },
    registration: {
      type: Boolean,
      default: false
    },
    saveImagePreview: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'items'
    },
    name: {
      type: Number,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileType: 0,
      isIncorrectPdf: false,
      dragging: false,
      items: [],
      itemsAdded: '',
      itemsNames: [],
      itemsSizes: [],
      formData: new FormData(),
      maximumFileSize: 2,
      imageMaximumSize: 5,
      successMsg: '',
      errorMsg: '',
      isLoaderVisible: false,
      validationError: false,
      specialCharectersError: false,
      sizeError: false,
      fileAlreadyExistsError: false,
      pdfTableHeaders: ['', 'Document Type', 'Omschrijving', '', ''],
      imageExtensions: ['jpg', 'png', 'jpeg'],
      uploadedFilesTypes: []
    };
  },
  computed: {
    isDocumentTypeSelectedComputed() {
      return this.fileType !== 0 ? true : false;
    }
  },
  unmounted() {
    this.removeItems();
  },
  created() {
    this.loadDocumentType();
  },
  methods: {
    loadDocumentType() {
      axios.get('Accountability/GetAccountabilityAttachmentTypes').then((response) => {
        if (response && response.data) {
          this.uploadedFilesTypes = response.data;
        }
      });
    },
    changeFileType(event) {
      this.fileType = event ? parseInt(event.target.value) : 0;
    },
    // http://scratch99.com/web-development/javascript/convert-bytes-to-mb-kb/
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i === 0) return bytes + ' ' + sizes[i];
      if (parseInt(bytes / Math.pow(1024, i)) >= this.maximumFileSize && sizes[i] === 'MB') {
        this.sizeErrorMessage =
          'Het geüploade document is te groot. Het document mag niet groter zijn dan ' + this.maximumFileSize + 'MB.';
        this.$emit('update:size-error-message', this.sizeErrorMessage);
        this.sizeError = true;
      }
      return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    },
    onChange(e) {
      this.successMsg = '';
      this.errorMsg = '';
      let files = e.target.files || e.dataTransfer.files;
      this.itemsAdded = files.length;
      if (this.saveImagePreview) {
        store.commit('prescriberRegistration/setUploadedImagePreview', {
          pdf: files[0].name.split('.').pop().toLowerCase() === 'pdf',
          image: window.URL.createObjectURL(files[0])
        });
      }
      if (files.length !== 0) {
        this.$emit('fileAdded', true);
      }
      for (let x in files) {
        if (!isNaN(x)) {
          if (this.multiple) {
            let uploadedFile = e.target.files[x] || e.dataTransfer.files[x];
            this.items.push(uploadedFile);
          } else {
            this.items = e.target.files[x] || e.dataTransfer.files[x];
          }
          this.itemsNames[x] = files[x].name;
          // will be removed once pdfs have max 5mb size.
          if (this.imageExtensions.includes(files[x].name.split('.').pop().toLowerCase())) {
            this.maximumFileSize = this.imageMaximumSize;
          } else {
            this.maximumFileSize = 2;
          }
          if (!this.allowedExtenstions.includes(files[x].name.split('.').pop().toLowerCase())) {
            this.validationError = true;
            break;
          }
          let specialCharecterRegex = /[`À-ȕ!@#$%^&*+\=\[\]{};':"\\|<>\/?~]/;
          if (specialCharecterRegex.test(files[x].name.split('.')[0])) {
            this.specialCharectersError = true;
            break;
          }
          for (let i = 0; i < this.uploadedFilesData.length; i++) {
            if (
              files[x].name.split('.').shift().toLowerCase() === this.uploadedFilesData[i].title ||
              files[x].name.split('.').shift().toLowerCase() === this.uploadedFilesData[i].Title ||
              files[x].name.split('.').shift().toLowerCase() === this.uploadedFilesData[i].fileName.toLowerCase()
            ) {
              this.fileAlreadyExistsError = true;
              break;
            }
          }
          this.itemsSizes[x] = this.bytesToSize(files[x].size);
          if (this.registration) {
            this.$emit('saveFileName', this.items.name);
          }
        }
      }
      if (this.multiple) {
        for (let y in this.items) {
          this.formData.append('files', this.items[y]);
        }
      } else {
        this.formData.append('files', this.items);
      }
    },
    removeItems() {
      this.items = [];
      this.itemsAdded = '';
      this.itemsNames = [];
      this.itemsSizes = [];
      this.dragging = false;
      this.validationError = false;
      this.sizeError = false;
      this.fileAlreadyExistsError = false;
      this.isIncorrectPdf = false;
      this.specialCharectersError = false;
      this.formData = new FormData();
      this.$emit('fileAdded', false);
      this.fileType = 0;
    },
    onSubmit() {
      this.$emit('uploadSuccess', this.formData, { id: this.id, name: this.name }, this.fileType);
      this.removeItems();
    }
  }
};
</script>
<style lang="scss" scoped>
.uploadBoxCompact {
  table {
    max-width: calc(100% + 50px);
    width: calc(100% + 50px);
    margin: 16px -25px 0px -25px;
    tbody {
      border: dashed 1px #a5a5a5;
    }
    .material-design-icon {
      margin: 10px 0px 0px 10px;
      fill: $teal-darkest;
    }
    :deep(th:first-child) {
      width: auto;
    }
    td {
      padding: $table-row-license-cell-padding;
    }
    > th:last-child,
    td:last-child {
      min-width: 230px;
      justify-content: flex-end;
      padding: 1rem 1.5rem 1rem 0.5rem;
    }
    .uploadActions {
      .btn,
      span {
        float: right;
      }
    }
  }
  .primaryMessage {
    font-size: 16px;
    padding: 5px 0 5px 0;
  }
  .secondaryMessage {
    font-size: 12px;
  }
  .uploadBoxMain {
    width: 100%;
    text-align: left;
    padding: 0px;
  }
  /* Drag and drop */
  .dropArea {
    width: 100%;
    text-align: center;
    padding: 40px 0 40px 0;
  }
  .dropArea input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  /* End drag and drop */
  /* Loader */
  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    opacity: 0.9;
  }
  .loaderImg {
    border: 8px solid #eee;
    border-top: 8px solid #00adce;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* End Loader */
  .errorMsg {
    font-size: 14px;
    color: #b42314;
    padding: 0.5rem 2rem;
  }
  .successMsg {
    font-size: 14px;
  }
  .fileName {
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
