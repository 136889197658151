<template>
  <div class="mInput">
    <mds-input
      v-bind="$attrs"
      v-on="$attrs"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { MdsInput } from '@mds/input'

export default {
    name:  'MSelect',
    computed: {},
    created () {
    },
    methods: {
    }
}
</script>
