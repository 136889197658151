import { fetchQuery } from '@/services/messaging/fetchQuery';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { currentUserIdentity$ } from '@/services/authentication/currentUserIdentity$';

export const availablePortals$ = currentUserIdentity$.pipe(
  switchMap(_ => {
    if(_ === null) {
      return Promise.resolve([]);
    }

    return fetchQuery("GetAvailablePortals", { })
  }),
  publishReplay(1),
  refCount()
);
