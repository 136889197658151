<template>
  <section class="container catalog">
    <div v-if="isAssortmentFlow" class="row grid-margin catalog__assortiment">
      <div class="col-xs-12 col-sm-3">
        <h1>Assortiment</h1>
      </div>
      <div class="col-xs-12">
        <div class="catalog__assortiment__alert-wrapper">
          <v-info-outline-icon class="catalog__assortiment__alert-wrapper__infoIcon" />
          Wilt u producten bestellen? Selecteer dan eerst een patiënt en maak een machtiging aan via
          <router-link to="/"> Mijn Dashboard. </router-link>
        </div>
      </div>
    </div>
    <div v-if="!isReplenishmentFlow" class="row grid-margin">
      <div class="col-xs-12 col-sm-3">
        <h1>Product catalogus</h1>
      </div>
      <div id="catalogSearch" class="col-xs-12 col-sm-9 catalog__productSearch">
        <v-input-group-simple-2
          id="catalogProductSearch"
          v-model:value="form.text"
          :overwrite-value="form.text"
          :rules="{
            required: false
          }"
          placeholder="Zoeken naar producten"
          @input="saveProductSearchText($event)"
        />
        <sync-loader v-if="loading" :color="color" class="catalog__productSearch__search-icon" />
        <v-search-icon v-else class="catalog__productSearch__search-icon" />
      </div>
    </div>
    <div class="row grid-margin">
      <form v-if="!isReplenishmentFlow" class="col-xs-12 col-md-3">
        <v-filters
          v-if="filters && filters.length > 0"
          v-model="form.filters"
          :filters="filters"
          @update:model-value="search"
        />
        <v-favourites
          v-if="favourites && favourites.length > 0"
          v-model="form.favorites"
          :favourites="favourites"
          :value="form.favorites"
          @update:model-value="search"
        />
        <v-manufacturers
          v-if="manufacturers.length !== 0"
          v-model="form.manufacturers"
          :manufacturers="manufacturers"
          @update:model-value="search"
        />
        <v-overlay v-if="overlayActive">
          <v-favourites-edit-form :favourites="favourites" @close="closeOverlay" />
        </v-overlay>
        <v-categories
          v-if="categories.length !== 0 && activeDepartment"
          v-model="form.category"
          :categories="categories"
          @update:model-value="search"
        />
      </form>
      <div :class="!isReplenishmentFlow ? 'col-xs-9' : 'col-md-12'">
        <div v-if="activeTokens.length > 0 && !isReplenishmentFlow">
          <div class="col-xs-12 col-md-12">
            <v-tokens :tokens="activeTokens" :result-count="totalProductCount" />
          </div>
        </div>
        <v-bundles v-if="!isProductTextSearch && bundles && bundles.length > 0 && isCustomerFlow" :bundles="bundles" />
        <v-products
          v-if="!isProductTextSearch && searchResults && searchResults.length > 0"
          :base-products="searchResults"
          @load-more="loadMore()"
        />
        <v-search-products
          v-if="isProductTextSearch && searchResults && searchResults.length > 0"
          :search-products="searchResults"
          @load-more="loadMore()"
        />
        <div v-if="hasError === true">Er is een fout opgetreden.</div>
      </div>
    </div>
    <generic-modal v-if="showAssortimentPopUp && !catalogAssortimentStatus">
      <template #header> Assortiment </template>
      <template #body>
        <p>U kunt hier het hele assortiment bekijken en uw favoriete producten beheren.</p>
        <p>
          Wilt u een product bestellen? Selecteer dan eerst een patiënt en maak een nieuwe machtiging aan om producten
          te kunnen bestellen.
        </p>
      </template>
      <template #footer>
        <div class="modal-checkbox">
          <v-checkbox-group
            v-model="assortimentRememberStatus"
            class="checkbox-group"
            label="Laat dit bericht niet meer zien"
            :overwrite-value="assortimentRememberStatus"
            inputid="rememberAssortiment"
          />
        </div>
        <v-button tagelement="button" md primary @click="closeModal"> Naar assortiment </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import VProducts from './Products';
import VSearchProducts from './searchProducts/SearchProducts';
import VFilters from './Filters';
import VFavourites from './Favourites';
import VManufacturers from './Manufacturers';
import VCategories from './categories/Categories';
import VTokens from './Tokens';
import VFavouritesEditForm from './FavouritesEditForm';
import VBundles from './Bundles';
import VSearchIcon from 'icons/Magnify';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import GenericModal from '@/components/shared/GenericModal';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VInfoOutlineIcon from 'icons/InformationOutline';
import { Subject, from } from 'rxjs';
import { getReplenishmentBaseProducts, activeDepartment$, currentUserIdentity$ } from '@/services';
import {
  switchMap,
  refCount,
  publishReplay,
  map,
  concatMap,
  startWith,
  scan,
  publishBehavior,
  tap,
  catchError
} from 'rxjs/operators';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const filterAccountableProducts = { label: 'Toon alleen gemachtigde', tokenLabel: 'Gemachtigde' };

export default {
  name: 'Catalog',
  components: {
    VProducts,
    VSearchProducts,
    VFilters,
    VCategories,
    VTokens,
    VFavourites,
    VManufacturers,
    VFavouritesEditForm,
    VBundles,
    VSearchIcon,
    SyncLoader,
    GenericModal,
    VCheckboxGroup,
    VInfoOutlineIcon
  },
  beforeRouteLeave(to, from, next) {
    if (!to.meta.catalogFilters) {
      this.resetCatalogFormDetails();
    }
    next();
  },
  setup() {
    const currentUserIdentity = ref(null);
    const store = useStore();
    const activeDepartment = ref(null);
    const loading = ref(null);
    const hasError = ref(null);
    const searchResults = ref(null);
    const totalProductCount = ref(null);
    const loadingSubject$ = new Subject();
    const router = useRouter();
    const filters = ref([]);
    const showAssortimentPopUp = ref(null);
    const productsRequestSubject$ = new Subject();
    const productsLoadMoreSubject$ = new Subject();

    const favorites = computed(() => store.getters['catalog/getFavorites']);
    const categories = computed(() => store.getters['catalog/getNewCategories']);
    const overlayActive = computed(() => store.getters['catalog/getOverlay']);
    const favourites = computed(() => store.getters['catalog/getFavoriteCategories']);
    const bundles = computed(() => store.getters['catalog/getBundles']);
    const catalogAssortimentStatus = computed(() => store.getters['catalog/getCatalogAssortiment']);
    const manufacturers = computed(() => store.getters['catalog/getManufacturers']);
    const form = computed(() => store.getters['catalog/getCatalogFormDetails']);
    const patientDetails = computed(() => store.getters['patient/getPatientGegevens']);

    const setRememberAssortiment = (value) => store.commit('catalog/setRememberAssortiment', value);
    const setOverlayActive = (value) => store.commit('catalog/setOverlayActive', value);
    const resetCatalogFormDetails = () => store.commit('catalog/resetCatalogFormDetails');

    const isReplenishmentFlow = router.currentRoute.value.meta.flow === 'replenishment';
    const isAssortmentFlow = router.currentRoute.value.meta.flow === 'assortment';
    const isCustomerFlow = router.currentRoute.value.meta.flow === 'customer';

    if (store.state.navigation.siteCode !== 'MTF' && isCustomerFlow) {
      filters.value.push(filterAccountableProducts);
    }

    if (!isReplenishmentFlow && !isAssortmentFlow) {
      store.dispatch('patient/getCurrentPatient');
      store.dispatch('catalog/fetchBundles');
    }
    if (isAssortmentFlow) {
      showAssortimentPopUp.value = true;
    }

    if (!isReplenishmentFlow) {
      store.dispatch('catalog/fetchFilters');
      store.dispatch('catalog/fetchManufacturers');
      store.dispatch('catalog/fetchFavourites');
      store.dispatch('catalog/fetchCategories');
    }

    const searchResults$ = productsRequestSubject$.pipe(
      switchMap((request) =>
        productsLoadMoreSubject$.pipe(
          startWith(0),
          tap(() => {
            loadingSubject$.next(true);
          }),
          concatMap((_, pageNum) => {
            if (isReplenishmentFlow) {
              return getReplenishmentBaseProducts({ pageIndex: pageNum });
            } else {
              const url = request + '&pageIndex=' + (pageNum + 1);
              return from(axios.get(url)).pipe(
                catchError(() => {
                  return Promise.resolve({ data: { totalProducts: -1, error: true, products: [] } });
                }),
                map((r) => r.data)
              );
            }
          }),
          scan(
            (previous, p) => {
              if (!p) {
                return previous;
              }
              return {
                totalProducts: p.totalProducts,
                error: p.error,
                products: [
                  ...previous.products,
                  ...p.products.map((product) => Object.assign(product, { isExpanded: false }))
                ]
              };
            },
            { totalProducts: 0, products: [] }
          ),
          tap(() => loadingSubject$.next(false)),
          publishBehavior({
            totalProducts: -1,
            error: false,
            products: []
          }) /* initial value: e.g. at startup and when filters/categories/text change occur */,
          refCount()
        )
      ),
      publishReplay(1),
      refCount()
    );

    activeDepartment$.subscribe((value) => (activeDepartment.value = value));
    loadingSubject$.asObservable().subscribe((value) => (loading.value = value));
    searchResults$.pipe(map((r) => r.error)).subscribe((value) => (hasError.value = value));
    searchResults$.pipe(map((r) => r.products)).subscribe((value) => (searchResults.value = value));
    searchResults$.pipe(map((r) => r.totalProducts)).subscribe((value) => (totalProductCount.value = value));
    currentUserIdentity$.subscribe((value) => {
      currentUserIdentity.value = value;
    });

    return {
      currentUserIdentity,
      favorites,
      categories,
      overlayActive,
      favourites,
      bundles,
      catalogAssortimentStatus,
      manufacturers,
      form,
      patientDetails,
      setRememberAssortiment,
      setOverlayActive,
      resetCatalogFormDetails,
      activeDepartment,
      loading,
      hasError,
      searchResults,
      totalProductCount,
      isReplenishmentFlow,
      isAssortmentFlow,
      isCustomerFlow,
      filters,
      showAssortimentPopUp,
      productsRequestSubject$,
      productsLoadMoreSubject$
    };
  },
  data() {
    return {
      color: '#73c8d2',
      productSearchTextTimer: null,
      assortimentRememberStatus: false,
      filteredCategories: []
    };
  },
  computed: {
    filteredCategoryList() {
      if (this.form.filters.length > 0) {
        return this.filteredCategories;
      } else {
        return this.categories;
      }
    },
    isProductTextSearch() {
      return !!this.form.text;
    },
    activeTokens() {
      const result = [];

      if (this.form.text) {
        result.push({
          label: 'Zoekterm: ' + this.form.text,
          remove: () => {
            this.form.text = null;
            this.search();
          }
        });
      }

      if (this.form.filters.length > 0) {
        this.form.filters.forEach((filter, index) => {
          result.push({
            label: filter.tokenLabel,
            remove: () => {
              this.form.filters.splice(index, 1);
              this.search();
            }
          });
        });
      }

      if (this.form.favorites.length > 0) {
        this.form.favorites.forEach((favorite, index) => {
          result.push({
            label: 'Favoriet: ' + favorite.name,
            remove: () => {
              this.form.favorites.splice(index, 1);
              this.search();
            }
          });
        });
      }

      if (this.form.manufacturers.length > 0) {
        this.form.manufacturers.forEach((manufacturer, index) => {
          result.push({
            label: 'Fabrikant: ' + manufacturer,
            remove: () => {
              this.form.manufacturers.splice(index, 1);
              this.search();
            }
          });
        });
      }

      if (this.form.category) {
        result.push({
          label:
            this.activeDepartment
              ? this.form.category.description
              : this.form.category.omschrijving,
          remove: () => {
            this.form.category = null;
            this.search();
          }
        });
      }

      return result;
    }
  },
  watch: {
    'form.filters': {
      handler(newFilters, oldFilters) {
        if (!this.isAssortmentFlow) {
          this.categories = this.categories.filter((obj) =>
            this.currentUserIdentity.userTreatmentArea.map((area) => area.treatmentAreaId).includes(obj.treatmentAreaId)
          );
        }

        if (newFilters.length > 0 && newFilters.includes(filterAccountableProducts)) {
          this.filteredCategories = this.categories.filter((obj) =>
            this.store.state.patient.patientGegevens.TreatmentAreas.includes(obj.treatmentAreaId)
          );
        } else {
          this.filteredCategories = this.categories;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      // Build xhr request
      // Note: Search functionality requires additional refactoring.
      // Note 2: When replenishment flow is active this search url will not be used, instead the subject will trigger replenishment xhr request within the observable (see `subscriptions`).
      let apiCall = this.isProductTextSearch ? 'Catalogue/SearchProductFromCatalogue' : 'Catalogue/GetBaseProducts';

      // let apiCall = this.isProductTextSearch
      //   ? 'Products/Search'
      //   : 'Products/GetBaseProducts';

      // slight rule override when Favorite is active rather use Search Proc than GetBaseProduct (E40-7784)
      if (this.form.favorites.length > 0) {
        apiCall = 'Products/Search';
      }

      apiCall += this.isAssortmentFlow ? '?ignoreCustomer=true' : '?ignoreCustomer=false';

      apiCall += this.isAssortmentFlow ? '&customerId=null' : '&customerId=' + this.patientDetails.PatientId;

      if (this.isProductTextSearch) {
        apiCall += '&term=' + encodeURIComponent(this.form.text);
      }

      if (this.form.filters.includes(filterAccountableProducts)) {
        apiCall += '&IsLicensed=true';
      }

      if (this.form.favorites.length > 0) {
        this.form.favorites.forEach((favorite) => {
          apiCall += '&BundleIdList=' + favorite.bundleId;
        });
      }

      if (this.form.manufacturers.length > 0) {
        this.form.manufacturers.forEach((manufacturer) => {
          apiCall += '&manufacturer=' + manufacturer;
        });
      }

      if (this.form.category) {
        let groupIds =
          this.activeDepartment
            ? this.form.category.nestedCategoryIds
            : this.form.category.productGroepId;
        groupIds.forEach((groupId) => {
          apiCall += '&CategoryIdList=' + groupId;
        });
      }

      this.productsRequestSubject$.next(apiCall);
      return;
    },
    loadMore() {
      this.productsLoadMoreSubject$.next(true); // just move to next pageIndex, no need to pass index as it is handled within pipe.
    },
    closeModal() {
      this.showAssortimentPopUp = false;
      this.setRememberAssortiment(this.assortimentRememberStatus);
    },
    closeOverlay() {
      this.setOverlayActive(false);
    },
    saveProductSearchText(event) {
      if (this.productSearchTextTimer) {
        clearTimeout(this.productSearchTextTimer);
      }
      const text = event.target.value;
      this.productSearchTextTimer = setTimeout(() => {
        if (text.length >= 3) {
          this.form.text = text;
        } else {
          this.form.text = null;
        }

        this.search();
      }, 300);
    },
    getVisibilityStatus() {
      return !this.isReplenishmentFlow;
    }
  }
};
</script>
<style land="css">
.catalog .overlay > div {
  min-width: 800px;
  padding: 0px;
}
</style>

<style lang="scss" scoped>
.catalog {
  &__assortiment {
    fill: $smooth-blue;
    color: $smooth-blue;
    font-size: 15px;
    &__alert-wrapper {
      background-color: $lightest-blue;
      padding: 15px;
      font-style: italic;
      .material-design-icon {
        fill: $smooth-blue !important;
        display: inline-block;
        vertical-align: middle;
      }
      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  .modal-checkbox {
    display: flex;
    padding-right: 10px;
  }
  &__productSearch {
    position: relative;
    &__search-icon {
      position: absolute;
      fill: $gray-dark;
      right: 30px;
      top: 20%;
    }
  }
  h1 {
    color: $teal-darkest;
    font-weight: 300;
    font-size: type-scale(4);
    line-height: line-height(4);
    margin-top: 0;
  }
  .filters,
  .favourite,
  .manufacturers {
    margin-bottom: 32px;
  }
}
</style>
