<template>
  <section class="container pageNotFound">
    <div class="row grid-margin grid-align-center">
      <div class="col-xs-12 col-md-2 col-lg-4" />
      <div class="col-xs-12 col-md-8 col-lg-4">
        <img
          src="@/static/img/404.svg"
          alt="Page not found"
        >
        <v-warning-message
          warning="Sorry de pagina die u zoekt kan niet weergegeven worden"
          message="Controleer de URL of neem contact op via het <a href='/contactgegevens'>contactformulier.</a>"
          :html="true"
        />
      </div>
      <div class="col-xs-12 col-md-2 col-lg-4" />
    </div>
    
    <div class="row grid-justify-content-center">
      <v-button
        tagelement="button"
        md
        primary
        @click="goToDashboard()"
      >
        Terug naar dashboard
      </v-button>
    </div>
  </section>
</template>
<script>
import router from '@/router'
import VWarningMessage from '@/components/shared/WarningMessage'

export default {
  name: 'VPageNotFound',
  components: {
    VWarningMessage,
  },
  props: {},
  methods: {
     /**
     * @function goToDashboard - Fuction to redirect prescriber to patient dashboard page
     */
    goToDashboard () {
      router.push('/')
    }
  }
}
</script>
<style lang="scss">
  .pageNotFound a{
    color: $dark-orange
  }
</style>
