<template>
  <section class="product-select">
    <div class="row grid-margin">
      <div class="col-xs-12 col-sm-4">
        <v-heading :level="3"> Producten selecteren </v-heading>
      </div>
      <div class="col-xs-12 col-sm-3">
        <v-button hollow lg @click.prevent="cancelAccountability"> Annuleren </v-button>
      </div>
      <div class="col-xs-12 col-sm-5">
        <v-button
          cta
          lg
          :class="{ disabled: !(isLastAnswer && isAllowedToProceed && productsSelected.length > 0) }"
          @click.prevent="submitProductsSelected"
        >
          Controleren & Ondertekenen
        </v-button>
      </div>
    </div>
    <div class="row grid-margin">
      <div id="step4" class="col-xs-12 col-md-3">
        <v-filters v-if="filters.length > 0" :data="filters" @change-filter="submitFilter" />
        <v-button secondary lg @click.prevent="refreshFilters"> Reset </v-button>
      </div>
      <div class="col-xs-12 col-md-9">
        <v-products
          id="step5"
          :product-selection-accountability-id="productSelectionAccountabilityId"
          :data="products"
        />
        <v-heading v-if="productsSelected.length > 0" :level="3" class="product-select__selected-header">
          <v-file-plus class="product-select__icon" />Geselecteerde producten in deze machtiging
        </v-heading>
        <v-products-selected
          v-if="productsSelected.length > 0"
          id="step6"
          :product-selection-accountability-id="productSelectionAccountabilityId"
          :products-selected-daily-dosage="productsSelectedDailyDosage"
          :products-selected-no-daily-dosage="productsSelectedNoDailyDosage"
        />
      </div>
    </div>
    <div v-if="products.length > 0 || productsSelected.length > 0" class="row grid-margin grid-justify-content-right">
      <div class="col-xs-12 col-sm-3">
        <v-button hollow lg @click.prevent="cancelAccountability"> Annuleren </v-button>
      </div>
      <div class="col-xs-12 col-sm-5">
        <v-button
          id="step7"
          cta
          lg
          :class="{ disabled: !(isLastAnswer && isAllowedToProceed && productsSelected.length > 0) }"
          @click.once.prevent="submitProductsSelected"
        >
          Controleren & Ondertekenen
        </v-button>
      </div>
    </div>
    <!--Pop up to show validation messages -->
    <generic-modal v-if="showValidationPopUp">
      <template #header>
        {{ accountabilityTexts.AccountabilityValidationMessageTitle }}
      </template>
      <template #body>
        <p>{{ accountabilityTexts.AccountabilityValidationMessageText }}</p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click.prevent="closeModal"> Ga verder </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import { mapMutations, useStore } from 'vuex';
import { computed } from 'vue';
import router from '@/router';
import VFilters from './Filters';
import GenericModal from '@/components/shared/GenericModal';
import VProducts from './Products';
import VProductsSelected from './ProductsSelected';
import VFilePlus from 'icons/FilePlus';

export default {
  name: 'VSelectProduct',
  components: {
    VFilters,
    VProducts,
    VProductsSelected,
    VFilePlus,
    GenericModal
  },
  props: {
    accountabilityTexts: {
      type: Object,
      required: false
    },
    accountabilityId: {
      type: Number,
      required: true
    },
    productSelectionAccountabilityId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const state = store.state;

    const products = computed(() => store.getters['selectProduct/getProducts']);
    const productsSelectedNoDailyDosage = computed(
      () => store.getters['selectProduct/getProductsSelectedNoDailyDosage']
    );
    const productsSelectedDailyDosage = computed(() => store.getters['selectProduct/getProductsSelectedDailyDosage']);
    const productsSelected = computed(() => store.getters['selectProduct/getProductsSelected']);
    const filters = computed(() => store.getters['selectProduct/getFilters']);
    const isLastAnswer = computed(() => store.getters['accountability/getIsLastAnswer']);
    const isAllowedToProceed = computed(() => store.getters['accountability/getIsAllowedToProceed']);
    const productCode = computed(() => store.getters['selectProduct/getProductCode']);
    const illnessId = computed(() => store.getters['selectProduct/getIllnessId']);
    const defaultSearch = computed(() => store.getters['selectProduct/getDefaultSearch']);

    const removeProducts = () => store.commit('selectProduct/removeProducts');
    const fetchProducts = (value) => store.dispatch('selectProduct/fetchProducts', value);
    const fetchProductsSelected = (value) => store.dispatch('selectProduct/fetchProductsSelected', value);
    const fetchFilters = (value) => store.dispatch('selectProduct/fetchFilters', value);
    const setDefaultSearch = (value) => store.commit('selectProduct/setDefaultSearch', value);
    const postFilter = (value) => store.dispatch('selectProduct/postFilter', value);
    const cancelAccountabilityForm = (value) => store.dispatch('accountability/cancelAccountabilityForm', value);

    return {
      state,
      products,
      productsSelectedNoDailyDosage,
      productsSelectedDailyDosage,
      productsSelected,
      filters,
      isLastAnswer,
      isAllowedToProceed,
      productCode,
      illnessId,
      defaultSearch,
      removeProducts,
      fetchProducts,
      fetchProductsSelected,
      setDefaultSearch,
      fetchFilters,
      postFilter,
      cancelAccountabilityForm,
      ...mapMutations({
        reset: 'selectProduct/reset'
      })
    };
  },
  data() {
    return {
      showValidationPopUp: false
    };
  },
  watch: {
    /**
     * @function ProductCode
     * @param {number} productCode - Code that can be used to fetch products
     * @description Get or remove all the products when productCode changes
     */
    productCode(productCode) {
      if (productCode === null) {
        this.removeProducts();
      } else {
        this.fetchProducts({
          productCode: this.productCode,
          illnessId: this.illnessId
        });
      }
    }
  },
  created() {
    this.fetchProductsSelected(this.productSelectionAccountabilityId);
    this.fetchFilters(this.productSelectionAccountabilityId);
    this.fetchProducts({
      productCode: '',
      illnessId: ''
    });
  },
  methods: {
    /**
     * @function submitFilter
     * @param {object} filter
     * @param {object} filter.Answer.AnswerId - The selected option (answer) of the filter (question)
     * @param {number} filter.AccountabilityLineId
     * @description Submit a filter
     */
    async submitFilter(filter) {
      this.setDefaultSearch(false);
      await this.postFilter(filter);
      await this.fetchFilters(this.productSelectionAccountabilityId);
    },
    /**
     * @function submitProductsSelected
     * @description Submit all the products that are selected.
     */
    submitProductsSelected() {
      if (
        this.state.navigation.demo &&
        this.state.navigation.demoActivated &&
        this.state.navigation.demoFlow.createLicenseTwoColumn
      ) {
        router.push('/machtiging-overzicht/' + this.accountabilityId);
      }
      if (!(this.isLastAnswer && this.isAllowedToProceed && this.productsSelected.length > 0)) {
        this.showValidationPopUp = true;
      } else {
        router.push('/machtiging-overzicht/' + this.accountabilityId);
      }
    },
    async refreshFilters() {
      this.setDefaultSearch(true);
      this.state.selectProduct.filters[0].IsBeantwoord = false;
      this.state.selectProduct.filters[0].IsGeselecteerd = true;
      this.state.selectProduct.filters[0].ResultaatAnswers = [];
      var defaultFilter = this.state.selectProduct.filters.splice(0, 1);
      this.state.selectProduct.filters = defaultFilter;
      this.fetchProducts({
        productCode: '',
        illnessId: ''
      });
    },
    cancelAccountability() {
      this.cancelAccountabilityForm(this.accountabilityId).then(() => {
        router.push('/patientdashboard');
      });
    },
    closeModal() {
      this.showValidationPopUp = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.product-select {
  &__icon {
    fill: $teal-darkest;
    padding-right: 10px;
  }

  &__selected-header {
    margin-bottom: 5px;
  }

  .products {
    margin-bottom: 3rem;
  }

  &__validation_popup_details {
    font-size: 16px;
    margin: 10px 0px 25px 0px;
    line-height: 1.5;
    color: $gray-darkest;
  }

  &__validation_popup_actions {
    margin-top: 20px;
    .backToBsn {
      border: none !important;
      padding-left: 0px;
    }
  }
}
</style>
