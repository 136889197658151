<template>
  <section id="cart" v-clickout="cartClose" class="cart">
    <button class="cart__button" @click="cartOpen">
      <div :class="{ 'tada animated': animated }">
        <v-cart-icon />
        <span v-if="shoppingCart.items.length > 0" class="cart__ordersCount">{{ totalNrOfItems }}</span>
      </div>
    </button>
    <div v-if="cartOpenStatus" id="cartOrder" class="cart__orders">
      <template v-if="shoppingCart.items.length === 0">
        <span class="cart__orders__no-orders">Er zijn geen producten in uw bestellijst.</span>
      </template>
      <template v-if="shoppingCart.items.length > 0">
        <h1>Bestelling</h1>
        <table class="cart__orders__table">
          <tbody>
            <tr>
              <td colspan="2">
                <h1 class="cart__orders__quantity">Aantal</h1>
              </td>
              <td colspan="2">
                <a class="cart__orders__clearshoppingcart" @click="handleClearShoppingCart">Alles verwijderen</a>
              </td>
            </tr>
            <v-order
              v-for="(order, index) in shoppingCart.items"
              :key="index"
              :class="{ 'fadeInRight animated': getAnimationStatus(index) }"
              :data="order"
              @remove-order="removeOrder"
            />
          </tbody>
        </table>
        <v-button class="cart__orders__button" cta md @click.prevent="submitOrder"> Afronden </v-button>
      </template>
    </div>
  </section>
</template>

<script>
import router from '@/router';
import VCartIcon from 'icons/Cart';
import VOrder from './Order';
import { shoppingCart$, removeFromShoppingCart, clearShoppingCart, currentUserIdentity$ } from '@/services';
import { map, distinctUntilChanged, tap, skip } from 'rxjs/operators';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VCart',
  components: {
    VCartIcon,
    VOrder
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);
    const shoppingCartChanged = ref(null);
    const user = ref(null);
    const animated = ref(null);
    const orderAnimated = ref(null);
    const lastIndex = ref(null);

    const patientDetails = computed(() => store.getters['patient/getPatientGegevens']);
    const cartOpenStatus = computed(() => store.getters['cart/getCartOpenStatus']);
    const orderClicked = computed(() => store.getters['cart/getOrderClicked']);
    const getCurrentPatient = () => store.dispatch('patient/getCurrentPatient');
    const setCartOpenStatus = (value) => store.commit('cart/setCartOpenStatus', value);
    const setOrderClicked = (value) => store.commit('cart/setOrderClicked', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
      if (value) {
        shoppingCartChanged.value = value.items.length;
      }
    });

    currentUserIdentity$.subscribe((value) => {
      user.value = value;
    });

    const animateShoppingCartIcon = () => {
      window.scrollTo(0, 0);
      setCartOpenStatus(true);
      animated.value = true;
      setTimeout(() => {
        setCartOpenStatus(false);
        animated.value = false;
        orderAnimated.value = false;
        lastIndex.value = null;
      }, 3000);
    };

    shoppingCart$
      .pipe(
        map((m) => (m ? m.items.length : 0)),
        distinctUntilChanged(),
        skip(1), // skip first assignment always because component load event is triggered
        tap(animateShoppingCartIcon)
      )
      .subscribe();

    return {
      patientDetails,
      cartOpenStatus,
      orderClicked,
      getCurrentPatient,
      setCartOpenStatus,
      setOrderClicked,
      shoppingCart,
      shoppingCartChanged,
      user,
      animated,
      orderAnimated,
      lastIndex
    };
  },
  data() {
    return {
      created: true,
      patientId: 0
    };
  },
  computed: {
    totalNrOfItems() {
      return this.shoppingCart && this.shoppingCart.items.length ? this.shoppingCart.items.length : 0;
    }
  },
  created() {
    // Dispatching server calls to fetch Prescriber, Patient details
    if (router.currentRoute.value.name.indexOf('aanvullenmagazijn') < 0) {
      this.getCurrentPatient().then(() => {
        this.patientId = this.patientDetails.PatientId;
      });
    }
    this.created = false;
  },
  methods: {
    // Function to remove order from the cart
    removeOrder(cartId) {
      // We want the UI to be fast and not wait for debounced stuff.
      const item = this.shoppingCart.items.find((item) => {
        return item.cartId === cartId;
      });
      const index = this.shoppingCart.items.indexOf(item);
      if (index >= 0) {
        this.shoppingCart.items.splice(index, 1);
      }
      removeFromShoppingCart(this.shoppingCart, cartId);
    },
    handleClearShoppingCart() {
      clearShoppingCart(this.shoppingCart);
    },
    // Function to open/expand cart
    cartOpen() {
      this.setCartOpenStatus(true);
    },
    // Function to close cart
    cartClose() {
      if (this.orderClicked) {
        this.setOrderClicked(false);
        return;
      }
      this.setCartOpenStatus(false);
    },
    // Function to proceed checkout page
    submitOrder() {
      this.setCartOpenStatus(false);
      this.shoppingCart.items.forEach((item) => {
        this.$gtm.trackEvent({
          event: 'ButtonClick',
          event_category: 'Product cart',
          event_action: 'Bestellen',
          event_label: `${item.productId} ${item.productTitle}`,
          event_value: String(item.quantity)
        });
      });
      if (router.currentRoute.value.meta.flow === 'replenishment') {
        router.push('/magazijnaanvullenBestellijst');
      } else {
        router.push('/bestellijst');
      }
    },
    /**
     * @function getAnimationStatus
     */
    getAnimationStatus(index) {
      return index === this.lastIndex && this.orderAnimated;
    }
  }
};
</script>

<style lang="scss" scoped>
.cart {
  .material-design-icon {
    fill: $white;
    display: inline-block;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $teal-darkest;
    color: $white;
    width: 70px;
    height: 90px;
    border: none;
    align-self: flex-end;
    cursor: pointer;
    // Give the same padding to the button as the PatientHeader.vue
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        padding: grid-gutter($breakpoint-key) + 2 grid-gutter($breakpoint-key);
      }
    }
  }
  &__orders {
    display: flex;
    flex-direction: column;
    background: $white;
    position: absolute;
    top: 100%;
    right: 3px;
    width: 100vw;
    z-index: 1000;
    padding: 40px 20px 20px 0px;
    border-bottom: 20px solid #ffffff;
    border-left: 20px solid #ffffff;
    border-image-repeat: round;
    border-image-width: 0px 0px 20px 20px;
    background-clip: padding-box;
    margin-right: -3px;
    box-shadow: 0 0 5px;
    &__quantity {
      color: $gray-darkest !important;
      font-size: 16px !important;
      font-weight: bold !important;
      margin-bottom: 0px;
    }
    &__clearshoppingcart {
      display: inline;
      color: $teal-darkest;
      font-weight: bold;
      white-space: nowrap;
      cursor: pointer;
      text-decoration: underline;
    }
    @include min-screen(grid-breakpoint(sm)) {
      width: 497px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      height: 10px;
      width: 100vw;
      background: $teal-darkest;

      @include min-screen(grid-breakpoint(sm)) {
        width: 497px;
      }
    }
    &__table {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }
    &__button {
      align-self: flex-end;
    }
    h1 {
      color: $teal-darkest;
      font-weight: 500;
      font-size: type-scale(2);
      line-height: line-height(2);
      margin-top: 0;
    }
    &__no-orders {
      color: $gray-darkest;
      font-style: italic;
    }
  }
  &__ordersCount {
    height: 18px;
    width: 18px;
    background-color: $orange;
    border-radius: 50%;
    font-size: 11px;
    position: absolute;
    z-index: 99;
    margin: -6px 0px 0px -12px;
    padding-top: 3px;
  }
}
</style>
