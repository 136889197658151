<template>
  <section v-if="osaRowOverviewDetails" class="container approval">
    <!-- Header block-->
    <div class="row">
      <v-heading :level="3">
        {{ customHeaderDetails.pageTitle }}
      </v-heading>
    </div>
    <!-- Evaulation data block-->
    <div v-if="skipEvaluation" class="row">
      <div class="approval__block col-xs-12">
        <div class="approval__block__header">
          {{ customHeaderDetails.evaluationHeader }}
        </div>
        <div class="approval__block__content">
          <div class="row grid-margin approval__row">
            <div class="col-xs-12">
              <v-table id="osaDetailsRow">
                <tbody class="osa-details-row">
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__bold" colspan="2">
                      Therapietrouw (Uitgelezen gemiddelde over laatste 3 weken)
                    </td>
                  </tr>
                  <tr v-if="osaRowOverviewDetails.hasOwnProperty('OsaEvaluationId')" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">AHI</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.Ahi }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">
                      Hoeveel uur slaapt patiënt gemiddeld per nacht met het apparaat?:
                    </td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.AvgHoursSleep }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">
                      Hoeveel dagen per week slaapt de patiënt gemiddeld met het apparaat?:
                    </td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.AvgDaysSleep }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Heeft de patiënt gewenningsproblemen?:</td>
                    <td
                      v-if="osaRowOverviewDetails.HabituationProblems"
                      class="approval__cell approval__cellDetail approval__bold"
                    >
                      Ja
                    </td>
                    <td v-else class="approval__cell approval__cellDetail approval__bold">Nee</td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Hypersomnolentie:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.Hypersomnolence }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">
                      Hoe voelt de patiënt zich ten opzichte van zijn of haar klachten?:
                    </td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.PatientComplaintNote }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">
                      Hoe voelt de partner zich ten opzichte van de klachten van de patiënt?:
                    </td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.PartnerComplaintNote }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Eindoordeel patiënt:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.PatientsFinalAssessment }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Opmerkingen evaluatie:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.EvaluationComments }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__bold" colspan="2">Instellen apparatuur definitieve aanvraag</td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">CPAP</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.Cpap }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Auto CPAP - Min:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.AutoCpapMin }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Auto CPAP - Max:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.AutoCpapMax }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">BiPAP - IPAP:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.Ipap }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">BiPAP - EPAP:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.Epap }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Ramp instelling (minuten):</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.RampSettingsMinutes }}
                    </td>
                  </tr>
                  <tr v-if="getVisibilityStatus()" ref="row" class="approval__data">
                    <td class="approval__cell approval__cellDetail">Minimale ramp druk:</td>
                    <td class="approval__cell approval__cellDetail approval__bold">
                      {{ osaRowOverviewDetails.MinimalDisasterPressure }}
                    </td>
                  </tr>
                  <tr ref="row" class="approval__data">
                    <td class="approval__cell">
                      <p class="approval__bold">Evaluatie gereed voor akkoordverklaring:</p>
                      <p class="approval__bold">Evaluatie door:</p>
                      <p class="approval__bold">Datum evaluatie:</p>
                    </td>
                    <td v-if="user" class="approval__cell">
                      <p class="osa-details-row__header">
                        <component
                          :is="statusIcon"
                          :class="{ 'status-active': isApproved, 'status-inactive': !isApproved }"
                        />{{ statusText }}
                      </p>
                      <p class="approval__bold">
                        {{ user.username }}
                      </p>
                      <p class="approval__bold">
                        {{ $formatDate(osaRowOverviewDetails.EvaluationDate) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Research data block-->
    <div class="row">
      <div class="approval__block col-xs-12">
        <div class="approval__block__header">
          {{ customHeaderDetails.researchHeader }}
        </div>
        <div class="approval__block__content">
          <div v-if="osaRowOverviewDetails.hasOwnProperty('OsaEvaluationId')" class="row grid-margin approval__row">
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.continueCpapTherapy"
                label="Patiënt wil graag doorgaan met CPAP therapie"
                inputid="cpapTherapy"
                :initvalue="osaApprovalDetails.continueCpapTherapy"
                :overwrite-value="osaApprovalDetails.continueCpapTherapy"
                @update:model-value="validateForm()"
              />
            </div>
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.feelsBetterWithCpapTherapy"
                label="Patiënt voelt zich beter met CPAP therapie"
                inputid="feelsBetterWithCpapTherapy"
                :initvalue="osaApprovalDetails.feelsBetterWithCpapTherapy"
                :overwrite-value="osaApprovalDetails.feelsBetterWithCpapTherapy"
                @update:model-value="validateForm()"
              />
            </div>
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.reductionOfAhi"
                label="Patiënt heeft vermindering van Apneu Hypo Index (AHI)"
                inputid="reductionOfAhi"
                :initvalue="osaApprovalDetails.reductionOfAhi"
                :overwrite-value="osaApprovalDetails.reductionOfAhi"
                @update:model-value="validateForm()"
              />
            </div>
            <div v-if="showValidateError" class="col-xs-12 form-group-simple__details">
              <div class="form-group-simple__messages">
                <div class="form-group-simple__error">
                  U dient minimaal 2 opties te selecteren om de akkoordverklaring te kunnen afronden.
                </div>
              </div>
            </div>
            <div class="col-xs-12 form-group__date">
              <v-input-date-picker
                id="therapyStartDate"
                ref="date"
                type="date"
                label="Therapie startdatum akkoord per*:"
                name="therapyStartDate"
                autofocus
                osa-therapy-start-date
                @selected="handleDate"
              />
            </div>
          </div>
          <div v-if="!osaRowOverviewDetails.hasOwnProperty('OsaEvaluationId')" class="row grid-margin approval__row">
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.continuePosaSptTherapy"
                label="Patiënt wil graag doorgaan met POSA-SPT therapie"
                inputid="continuePosaSptTherapy"
                :initvalue="osaApprovalDetails.continuePosaSptTherapy"
                :overwrite-value="osaApprovalDetails.continuePosaSptTherapy"
                @update:model-value="validateForm()"
              />
            </div>
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.feelsBetterWithPosaSptTherapy"
                label="Patiënt voelt zich beter met POSA-SPT therapie"
                inputid="feelsBetterWithPosaSptTherapy"
                :initvalue="osaApprovalDetails.feelsBetterWithPosaSptTherapy"
                :overwrite-value="osaApprovalDetails.feelsBetterWithPosaSptTherapy"
                @update:model-value="validateForm()"
              />
            </div>
            <div class="col-xs-12">
              <div class="form-group__date">
                <v-input-date-picker
                  id="therapyStartDate"
                  ref="date"
                  type="date"
                  :label="customHeaderDetails.dateLabel"
                  name="therapyStartDate"
                  autofocus
                  osa-date
                  @selected="handleDate"
                />
              </div>
            </div>
            <div v-if="showValidatePOSAError" class="col-xs-12 form-group-simple__details">
              <div class="form-group-simple__messages">
                <div class="form-group-simple__error">
                  U dient minimaal 2 opties te selecteren om de akkoordverklaring te kunnen afronden.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Comments approval block-->
    <div class="row">
      <div class="approval__block col-xs-12">
        <div class="approval__block__header">
          {{ customHeaderDetails.commentHeader }}
        </div>
        <div class="approval__block__content">
          <div class="row grid-margin approval__row">
            <div class="col-xs-12">
              <textarea
                id="approvalComments"
                v-model="osaApprovalDetails.approvalComments"
                class="approval__textarea"
                placeholder="Beschrijf hier uw opmerking en/of motivatie"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Final approval block-->
    <div class="row">
      <div class="approval__block col-xs-12">
        <div class="approval__block__header">
          {{ customHeaderDetails.finalHeader }}
        </div>
        <div class="approval__block__content">
          <div class="row grid-margin approval__row">
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="osaApprovalDetails.isApproved"
                label="Akkoord voortzetten therapie"
                inputid="finalApproval"
                :initvalue="osaApprovalDetails.isApproved"
                :overwrite-value="osaApprovalDetails.isApproved"
                @update:model-value="updateFinalApproval()"
              />
            </div>
          </div>
          <div
            v-if="showSignatureBlock"
            class="row grid-margin grid-align-center approval__row approval__multipleWarrenters"
            :class="{ 'fadeInDown animated': animated }"
          >
            <div v-if="hasMultipleSignees" class="col-md-12">
              <v-select-group
                label="Beschikbare volmachtgevers"
                selectid="warranter"
                initselected=""
                @change="setSelectedSigneePpwRecId($event)"
                placeholder="Selecteer volmachtgever"
              >
                <option
                  v-for="(signee, index) in signees"
                  :key="index"
                  :data-index="index"
                  :initvalue="signee.ppwRecId"
                  :value="signee.ppwRecId"
                >
                  {{ signee.name }}
                </option>
              </v-select-group>
            </div>
          </div>
          <div
            v-if="selectedSignee && showSignatureBlock"
            class="row grid-margin approval__row"
            :class="{ 'fadeInDown animated': animated }"
          >
            <div class="col-xs-6">
              <v-input-group-simple
                inputid="userName"
                disabled
                :model="selectedSignee.name"
                :initvalue="selectedSignee.name"
                :overwrite-value="selectedSignee.name"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                inputid="date"
                disabled
                :model="$formatDate(currentDate)"
                :initvalue="$formatDate(currentDate)"
              />
            </div>
          </div>
          <div
            v-if="selectedSignee && showSignatureBlock"
            class="row grid-margin grid-align-center approval__row"
            :class="{ 'fadeInDown animated': animated }"
          >
            <div class="approval__signature">
              <prescriber-signature :user-id="selectedSignee.userId" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons Block-->
    <div class="row grid-justify-content-right">
      <div class="approval__actions">
        <v-button md hollow class="approval__actions__cancelApproval" @click.prevent="cancelOSAApproval()">
          Annuleren
        </v-button>
        <v-button
          md
          cta
          :disabled="!osaApprovalDetails.isApproved || !selectedSignee"
          @click.prevent="saveOSAApproval()"
        >
          Verzenden
        </v-button>
      </div>
    </div>
    <!-- Cancel pop up -->
    <generic-modal v-if="showCancelPopUp">
      <template #body>
        <p>Wilt u de evaluatie annuleren? De gegevens worden niet opgeslagen</p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="closeModal"> Ga terug </v-button>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Annuleer akkoordverklaring </v-button>
      </template>
    </generic-modal>
    <!-- Success pop up -->
    <generic-modal v-if="showSuccessPopup">
      <template #body>
        <p>
          Uw akkoordverklaring is verzonden en toegevoegd aan de machtiging van de patiënt. Zodra deze door ons is
          verwerkt zal de machtiging worden geactiveerd en niet langer het label ‘proefplaatsing’ dragen.
        </p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Naar patiënt dashboard </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VTable from '@/components/shared/table/Table';
import GenericModal from '@/components/shared/GenericModal';
import VActiveIcon from 'icons/CheckCircle';
import VCloseIcon from 'icons/CloseCircle';
import { router$ } from '@/router/router$';
import { currentUserIdentity$, getSignees, getOsaOverviewDetails } from '@/services';
import PrescriberSignature from '@/components/sections/prescriber/PrescriberSignature';
import { map, filter, publishReplay, switchMap, refCount } from 'rxjs/operators';
import moment from 'moment';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'VApproval',
  components: {
    VInputDatePicker,
    VCheckboxGroup,
    VHeaderRow,
    VTable,
    VActiveIcon,
    VCloseIcon,
    GenericModal,
    PrescriberSignature
  },
  setup() {
    const store = useStore();
    const user = ref(null);
    const osaRowOverviewDetails = ref(null);
    const signees = ref(null);

    const getCurrentPatient = () => store.dispatch('patient/getCurrentPatient');

    currentUserIdentity$.subscribe((identity) => {
      user.value = identity;
    });

    const osaApprovalRequiredParams$ = router$.pipe(
      map((route) => {
        if (route && route.params) {
          return {
            type: route.params.type,
            id: route.params.id,
            licenseId: route.params.licenseId ? route.params.licenseId : 0
          };
        }
      }),
      filter((osaApprovalRequiredParams) => !!osaApprovalRequiredParams)
    );

    const loadOverview = async (params) => {
      if (!params.type || !params.id || params.licenseId === null) {
        return;
      }
      const response = await getOsaOverviewDetails({
        osaType: params.type,
        osaId: params.id,
        licenseId: params.licenseId
      });
      return response;
    };

    const osaRowOverviewDetails$ = osaApprovalRequiredParams$.pipe(
      switchMap((osaApprovalRequiredParams) => loadOverview(osaApprovalRequiredParams)),
      publishReplay(1),
      refCount()
    );

    osaRowOverviewDetails$.subscribe((details) => {
      osaRowOverviewDetails.value = details;
    });

    const signees$ = osaRowOverviewDetails$.pipe(
      filter((osaRowOverviewDetails) => !!osaRowOverviewDetails && !!osaRowOverviewDetails.TreatmentAreaId), // Ensure osaRowOverviewDetails and TreatmentAreaId are defined
      switchMap((osaRowOverviewDetails) => getSignees(osaRowOverviewDetails.TreatmentAreaId)),
      publishReplay(1),
      refCount()
    );

    signees$.subscribe((signeesList) => {
      signees.value = signeesList;
    });

    return {
      getCurrentPatient,
      user,
      osaRowOverviewDetails,
      signees
    };
  },
  data() {
    return {
      currentDate: new Date(),
      showCancelPopUp: false,
      showSuccessPopup: false,
      showSignatureBlock: false,
      animate: false,
      showValidateError: false,
      showValidatePOSAError: false,
      selectedSigneePpwRecId: '',
      osaApprovalDetails:
        router.currentRoute.value.name === 'approval-posa-spt'
          ? {
              continuePosaSptTherapy: false,
              feelsBetterWithPosaSptTherapy: false,
              therapyStartDate: null,
              isApproved: false,
              approvalComments: ''
            }
          : {
              continueCpapTherapy: false,
              feelsBetterWithCpapTherapy: false,
              reductionOfAhi: false,
              isApproved: false,
              approvalComments: ''
            }
    };
  },
  computed: {
    skipEvaluation() {
      return router.currentRoute.value.params.id === 0 ? true : false;
    },
    customHeaderDetails() {
      const result = {
        pageTitle:
          router.currentRoute.value.name === 'approval-posa-spt'
            ? 'Akkoordverklaring levering definitieve POSA SPT apparatuur'
            : 'Akkoordverklaring levering definitieve CPAP apparatuur',
        evaluationHeader: 'Evaluatie gegevens',
        researchHeader: 'Onderzoeksgegevens',
        commentHeader: 'Opmerking en/of motivatie',
        finalHeader: 'Akkoordverklaring definitief maken'
      };
      if (router.currentRoute.value.name === 'approval-posa-spt') {
        result.dateLabel = 'Therapie startdatum akkoord per*:';
      }
      return result;
    },
    isApproved() {
      return this.osaRowOverviewDetails.IsApproved || this.osaRowOverviewDetails.IsReadyForApproval;
    },
    statusIcon() {
      return this.isApproved ? 'VActiveIcon' : 'VCloseIcon';
    },
    statusText() {
      return this.isApproved ? 'Therapie slaat aan' : 'Therapie slaat niet aan';
    },
    hasMultipleSignees() {
      return this.signees && this.signees.length > 1;
    },
    hasSingleSignee() {
      return this.signees && this.signees.length === 1;
    },
    selectedSignee() {
      if (this.hasSingleSignee) {
        return this.signees[0];
      } else if (this.hasMultipleSignees) {
        return this.signees.find((v) => v.ppwRecId == this.selectedSigneePpwRecId);
      }

      return null;
    }
  },
  created() {
    this.getCurrentPatient();
  },
  methods: {
    closeModal() {
      this.showCancelPopUp = false;
      this.showSuccessPopup = false;
    },
    /**
     * @function updateFinalApproval - Function to set/unset the final approval
     */
    updateFinalApproval() {
      if (this.osaApprovalDetails.isApproved) {
        this.showSignatureBlock = true;
        this.animated = true;
        setTimeout(() => {
          this.animated = false;
        }, 1000);
      } else {
        this.showSignatureBlock = false;
        this.selectedSigneePpwRecId = '';
      }
    },
    /**
     * @function goToPatientDashboard - Fuction to redirect prescriber to patient dashboard page
     */
    goToPatientDashboard() {
      router.push('/patientdashboard');
    },
    /**
     * @function cancelOSAApproval - Function to show cancel pop up
     */
    cancelOSAApproval() {
      this.showCancelPopUp = true;
    },
    /**
     * @function saveOSAApproval - Fucntion to save the approval form details
     */
    saveOSAApproval() {
      if (this.validateForm()) {
        this.osaApprovalDetails.SignedByUserId = this.selectedSignee.userId;
        let computedApiCall = 'osa/SaveEvaluationApprovalForm';
        if (router.currentRoute.value.name === 'approval-posa-spt') {
          this.osaApprovalDetails.therapyStartDate = moment(
            this.osaApprovalDetails.therapyStartDate,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD');
          this.osaApprovalDetails.osaEvaluationSptId = this.osaRowOverviewDetails.OsaEvaluationSptId;
          this.osaApprovalDetails.LicenseId = this.osaRowOverviewDetails.LicenseId;
          computedApiCall = 'EvaluationSpt/SaveEvaluationSptApprovalForm';
        } else {
          this.osaApprovalDetails.osaEvaluationId = this.osaRowOverviewDetails.OsaEvaluationId;
          this.osaApprovalDetails.LicenseId = this.osaRowOverviewDetails.LicenseId;
        }
        axios.post(computedApiCall, this.osaApprovalDetails).then((response) => {
          this.showSuccessPopup = true;
        });
      }
    },
    /**
     * @function getVisibilityStatus - Function to show or not the installment details
     */
    getVisibilityStatus() {
      return this.osaRowOverviewDetails && this.osaRowOverviewDetails.IsSettingChangedFromOriginal;
    },
    // Function to set therapy start date
    handleDate(key, value) {
      this.osaApprovalDetails[key] = value;
    },
    /**
     * @function validateForm - check the checkboxes checked or not as per the requirement
     */
    validateForm() {
      let count = 0;
      const keysToValidate =
        router.currentRoute.value.name === 'approval-posa-spt'
          ? ['continuePosaSptTherapy', 'feelsBetterWithPosaSptTherapy']
          : ['continueCpapTherapy', 'feelsBetterWithCpapTherapy', 'reductionOfAhi'];
      Object.keys(this.osaApprovalDetails).forEach((key) => {
        if (this.osaApprovalDetails[key] && keysToValidate.includes(key)) {
          count += 1;
        }
      });
      this.showValidateError = router.currentRoute.value.name !== 'approval-posa-spt' && count < 2 ? true : false;
      this.showValidatePOSAError = router.currentRoute.value.name === 'approval-posa-spt' && count < 2 ? true : false;
      return this.showValidateError || this.showValidatePOSAError ? false : true;
    },
    setSelectedSigneePpwRecId(event) {
      if (!event.currentTarget.value) {
        return;
      }
      this.selectedSigneePpwRecId = event.currentTarget.value;
    }
  }
};
</script>
<style lang="scss" scoped>
.approval {
  color: $grayish-very-dark;
  &__block {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 0px 0px 30px 0px;
    &__header {
      background-color: $grayish-very-light;
      color: $grayish-very-dark;
      font-size: 18px;
      padding: 20px;
    }
    &__content {
      padding: 10px 10px 10px 10px;
    }
  }
  &__actions {
    display: flex;
    margin: 20px 0px 30px 0px;
    &__cancelApproval {
      margin-right: 25px;
    }
  }
  &__row {
    margin: 10px 0 10px 0;
    td {
      padding-bottom: 20px;
    }
  }
  &__multipleWarrenters {
    :deep(.form-group) {
      padding: 0px;
      label {
        font-weight: 900;
        color: $gray-dark;
        position: initial;
        transform: none !important;
        line-height: normal;
        background: transparent;
      }
      &__icon {
        margin: 25px 10px 0 0;
      }
      .form-group__details {
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }
    }
  }
  &__textarea {
    min-height: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid $gray-light;
    padding: 10px;
  }
  &__signature {
    margin: 0 auto;
    img {
      border: solid 2px $grayish-med-light;
      vertical-align: text-top;
      max-width: 350px !important;
      height: auto;
      width: 100%;
    }
  }
  &__bold {
    font-weight: bold;
  }
  &__cellDetail {
    padding: 5px 10px 5px 10px !important;
  }
  &__cell {
    color: $table-row-cell-color;
    font-size: $table-row-cell-font-size;
    line-height: $table-row-cell-line-height;
    justify-content: $table-row-cell-justify-content;
    padding: 16px 10px;
    vertical-align: $table-row-license-cell-vertical-align;
    @mixin list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    > .material-design-icon {
      display: flex;
      justify-content: center;
      fill: $black;
      :deep(svg) {
        display: block;
      }
    }
  }
  .status-active {
    fill: $cyan-darker !important;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: top;
  }
  .status-inactive {
    fill: $pure-red !important;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: top;
  }
  a {
    color: $teal-darkest;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin-bottom: 15px;
  }
}
</style>
