import axios from 'axios'
export default {
  namespaced: true,
  state: {
    followedPatients: {
      patients : [],
      totalResult: 0
    }
  },
  mutations: {
    setFollowedPatients (state, payload) {
      state.followedPatients.patients = payload.patients;
      state.followedPatients.totalResult = payload.totalResult;
    }
  },
  actions: {
    getFollowedPatients ({ commit, state }, data) {
      let parameters = {query: data.query}
      parameters.page = data.page
      
      if (data.isDepartment) {
        parameters.isDepartment = data.isDepartment
      }
      return axios.get('/MyPatients/PatientSearch', {params: parameters}).then((response) => {
        commit('setFollowedPatients', response.data)
      })
    },
    saveUnfollowPatient ({ commit, state }, customerId) {
      // Make an API call to unfollow a patient & refresh the page
      return axios.post('MyPatients/UnfollowPatient?customerId=' + customerId).then((response) => {
        return response.data
      })
    }
  },
  getters: {
    followedPatients: state => {
      return state.followedPatients
    }
  }
}
