<template>
  <div class="overlay-large">
    <section>
      <h1>{{ title }}</h1>
      <button class="overlay-large__close" @click.prevent="close">
        <close-icon />
      </button>
      <div class="overlay-large__content">
        <slot />
      </div>
    </section>
  </div>
</template>
<script>
import closeIcon from 'icons/Close';
export default {
  name: 'VOverlaylarge',
  components: {
    closeIcon
  },
  props: {
    title: {
      type: String
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
.overlay-large {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  > section {
    position: relative;
    text-align: left;
    background: $white;
    width: 100%;
    max-height: calc(100% - 80px);
    max-width: 760px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    > h1 {
      font-size: type-scale(2);
      font-weight: normal;
      margin: 0;
      padding: 20px 50px 20px 20px;
      color: $teal-darkest;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    appearance: none;
    border: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    color: $teal-darkest;

    :deep(svg) {
      display: block;
      width: 24px;
      height: 24px;
      fill: currentColor;
    }
  }

  &__content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: align-self;
    padding: 10px 20px 20px;
  }
}
</style>
