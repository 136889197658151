import axios from 'axios';
import moment from 'moment';
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    prePositionsData: [],
    prescriberRegistrationDetails: {
      agbCode: null,
      bigCode: null,
      kpNumber: null,
      userType: false,
      titleId: 1,
      initials: '',
      firstName: '',
      prePosition: '',
      prePositionCode: '',
      lastName: '',
      maidenName: '',
      dateOfBirth: '',
      phoneNumber: '',
      mobileNumber: '',
      email: '',
      treatmentAreas: [
        { treatmentAreaId: '0001', treatmentArea: 'Diabetes', active: false },
        { treatmentAreaId: '1010', treatmentArea: 'Huisartsenpraktijk', active: false },
        { treatmentAreaId: '1002', treatmentArea: 'Incontinentie', active: false },
        { treatmentAreaId: '1003', treatmentArea: 'Infuus', active: false },
        { treatmentAreaId: '1004', treatmentArea: 'Luchtwegzorg', active: false },
        { treatmentAreaId: '1005', treatmentArea: 'Stoma', active: false },
        { treatmentAreaId: '1006', treatmentArea: 'Voeding', active: false },
        { treatmentAreaId: '1007', treatmentArea: 'Wond', active: false }
      ],
      institutions: [
        {
          name: '',
          agbCode: '',
          departments: [
            {
              name: '',
              email: '',
              depotNumber: '',
              prescriberTitleId: 1,
              prescriberInitials: '',
              prescriberFirstName: '',
              prescriberPrePosition: '',
              prescriberLastName: '',
              prescriberEmail: '',
              prescriberPhoneNumber: '',
              prescriberBigCode: '',
              prescriberExternalId: '',
              validated: false,
              showWarranterDetails: false,
              showSearchWarranterDetails: false,
              isAnswered: false,
              warranterSuccessMessage: false
            }
          ],
          postalCode: '',
          houseNumber: '',
          houseNumberAddition: '',
          streetName: '',
          city: '',
          country: 'NL',
          phoneNumber: '',
          show: true,
          validated: false
        }
      ],
      userName: '',
      signature: '',
      gpRoles: [
        { roleId: '1', role: 'Huisarts', active: false },
        { roleId: '2', role: 'Praktijkondersteuner (POH)', active: false },
        { roleId: '3', role: 'Assistent(e)', active: false },
        { roleId: '4', role: 'Verloskundige', active: false }
      ],
      showRoleSelection: false,
      practice: [
        {
          name: '',
          agbCode: '',
          departments: [
            {
              name: '',
              email: '',
              depotNumber: '',
              prescriberTitleId: 1,
              prescriberInitials: '',
              prescriberFirstName: '',
              prescriberPrePosition: '',
              prescriberLastName: '',
              prescriberEmail: '',
              prescriberPhoneNumber: '',
              prescriberBigCode: '',
              prescriberExternalId: '',
              validated: false,
              showWarranterDetails: false,
              showSearchWarranterDetails: false,
              isAnswered: false,
              warranterSuccessMessage: false
            }
          ],
          locations: [
            {
              locationName: '',
              locationEmail: '',
              validated: false
            }
          ],
          postalCode: '',
          houseNumber: '',
          houseNumberAddition: '',
          streetName: '',
          city: '',
          country: 'NL',
          phoneNumber: '',
          show: true,
          validated: false
        }
      ]
    },
    prescriberRegistrationSteps: [
      {
        id: 0,
        title: 'Stap 1.',
        description: 'AGB/BIG/KP-code',
        show: true,
        active: true
      },
      {
        id: 1,
        title: 'Stap 2.',
        description: 'Uw (zakelijke) gegevens',
        show: false,
        active: false
      },
      {
        id: 3,
        title: 'Stap 3.',
        description: 'Specialisatie',
        show: false,
        active: false
      },
      {
        id: 4,
        title: 'Stap 4.',
        description: 'Werkgever',
        show: false,
        active: false
      },
      {
        id: 5,
        title: 'Stap 5.',
        description: 'Handtekening en inloggegevens',
        show: false,
        active: false
      },
      {
        id: 6,
        title: 'Stap 6.',
        description: 'Gegevens controleren',
        show: false,
        active: false
      }
    ],
    uploadedFileName: null,
    imagePreview: null,
    signature: [],
    registrationTexts: '',
    colleagueRegistrationTexts: '',
    registrationSuccess: false,
    warranterSuccessMessage: null,
    registrationType: null
  },
  mutations: {
    resetPresciberRegistrationDetails(state) {
      state.prescriberRegistrationDetails = {
        bigCode: '',
        kpNumber: '',
        userType: false,
        titleId: 1,
        initials: '',
        firstName: '',
        prePosition: '',
        prePositionCode: '',
        lastName: '',
        maidenName: '',
        dateOfBirth: '',
        phoneNumber: '',
        mobileNumber: '',
        email: '',
        treatmentAreas: [
          { treatmentAreaId: '0001', treatmentArea: 'Diabetes', active: false },
          { treatmentAreaId: '1010', treatmentArea: 'Huisartsenpraktijk', active: false },
          { treatmentAreaId: '1002', treatmentArea: 'Incontinentie', active: false },
          { treatmentAreaId: '1003', treatmentArea: 'Infuus', active: false },
          { treatmentAreaId: '1004', treatmentArea: 'Luchtwegzorg', active: false },
          { treatmentAreaId: '1005', treatmentArea: 'Stoma', active: false },
          { treatmentAreaId: '1006', treatmentArea: 'Voeding', active: false },
          { treatmentAreaId: '1007', treatmentArea: 'Wond', active: false }
        ],
        institutions: [
          {
            name: '',
            agbCode: '',
            departments: [
              {
                name: '',
                email: '',
                depotNumber: '',
                prescriberTitleId: 1,
                prescriberInitials: '',
                prescriberFirstName: '',
                prescriberPrePosition: '',
                prescriberLastName: '',
                prescriberEmail: '',
                prescriberPhoneNumber: '',
                prescriberBigCode: '',
                prescriberExternalId: '',
                validated: false,
                showWarranterDetails: false,
                showSearchWarranterDetails: false,
                isAnswered: false,
                warranterSuccessMessage: false
              }
            ],
            postalCode: '',
            houseNumber: '',
            houseNumberAddition: '',
            streetName: '',
            city: '',
            country: 'NL',
            phoneNumber: '',
            show: true,
            validated: false
          }
        ],
        userName: '',
        signature: '',
        gpRoles: [
          { roleId: '1', role: 'Huisarts', active: false },
          { roleId: '2', role: 'Praktijkondersteuner (POH)', active: false },
          { roleId: '3', role: 'Assistent(e)', active: false },
          { roleId: '4', role: 'Verloskundige', active: false }
        ],
        showRoleSelection: false,
        practice: [
          {
            name: '',
            agbCode: '',
            departments: [
              {
                name: '',
                email: '',
                depotNumber: '',
                prescriberTitleId: 1,
                prescriberInitials: '',
                prescriberFirstName: '',
                prescriberPrePosition: '',
                prescriberLastName: '',
                prescriberEmail: '',
                prescriberPhoneNumber: '',
                prescriberBigCode: '',
                prescriberExternalId: '',
                validated: false,
                showWarranterDetails: false,
                showSearchWarranterDetails: false,
                isAnswered: false,
                warranterSuccessMessage: false
              }
            ],
            locations: [
              {
                locationName: '',
                locationEmail: '',
                validated: false
              }
            ],
            postalCode: '',
            houseNumber: '',
            houseNumberAddition: '',
            streetName: '',
            city: '',
            country: 'NL',
            phoneNumber: '',
            show: true,
            validated: false
          }
        ]
      };
    },
    setPreviousStepActive(state) {
      const currentActiveIndex = state.prescriberRegistrationSteps.findIndex((step) => step.show);
      state.prescriberRegistrationSteps.forEach((step) => {
        step.show = false;
        if (step.id < currentActiveIndex) {
          step.active = true;
        } else {
          step.active = false;
        }
      });
      state.prescriberRegistrationSteps[currentActiveIndex - 1].show = true;
      state.prescriberRegistrationSteps[currentActiveIndex - 1].active = true;
    },
    setNextStepActive(state) {
      const currentActiveIndex = state.prescriberRegistrationSteps.findIndex((step) => step.show);
      state.prescriberRegistrationSteps.forEach((step) => {
        step.show = false;
        if (step.id <= currentActiveIndex) {
          step.active = true;
        }
      });
      state.prescriberRegistrationSteps[currentActiveIndex + 1].show = true;
      state.prescriberRegistrationSteps[currentActiveIndex + 1].active = true;
    },
    setPrescriberRegistrationDetails(state, payload) {
      state.prescriberRegistrationDetails[payload.key] = payload.value;
    },
    setPrescriberTreatmentArea(state, payload) {
      const currentTreatmentIndex = state.prescriberRegistrationDetails.treatmentAreas.findIndex(
        (treatmentArea) => treatmentArea.treatmentAreaId === payload.id
      );
      state.prescriberRegistrationDetails.treatmentAreas[currentTreatmentIndex].active = payload.active;
    },
    setRoles(state, payload) {
      state.prescriberRegistrationDetails.gpRoles.forEach((role) => {
        if (role.roleId === payload.id) {
          role.active = true;
        } else {
          role.active = false;
        }
      });
    },
    setStep4(state, payload) {
      const currentIndex = state.prescriberRegistrationSteps.findIndex((step) => step.id === payload.id);
      state.prescriberRegistrationSteps[currentIndex].description = payload.value;
    },
    clearPrescriberTreatmentAreas(state) {
      state.prescriberRegistrationDetails.treatmentAreas.forEach((treatmentArea) => {
        treatmentArea.active = false;
      });
    },
    saveSignature(state, payload) {
      state.signature.push(payload);
    },
    deleteSignature(state, payload) {
      state.signature = payload;
    },
    setUploadedFileName(state, payload) {
      state.uploadedFileName = payload;
    },
    setPrescriberRegistrationInstitutionDetails(state, payload) {
      state.prescriberRegistrationDetails.institutions[payload.id][payload.details.key] = payload.details.value;
    },
    setPrescriberRegistrationPracticeDetails(state, payload) {
      state.prescriberRegistrationDetails.practice[payload.id][payload.details.key] = payload.details.value;
    },
    addPracticeDetails(state) {
      const defaultPracticeDetails = {
        name: '',
        agbCode: '',
        departments: [
          {
            name: '',
            email: '',
            depotNumber: '',
            prescriberTitleId: 1,
            prescriberInitials: '',
            prescriberFirstName: '',
            prescriberPrePosition: '',
            prescriberLastName: '',
            prescriberEmail: '',
            prescriberPhoneNumber: '',
            prescriberBigCode: '',
            prescriberExternalId: '',
            validated: false,
            showWarranterDetails: false,
            showSearchWarranterDetails: false,
            isAnswered: false,
            warranterSuccessMessage: false
          }
        ],
        locations: [
          {
            locationName: '',
            locationEmail: '',
            validated: false
          }
        ],
        postalCode: '',
        houseNumber: '',
        houseNumberAddition: '',
        streetName: '',
        city: '',
        country: 'NL',
        phoneNumber: '',
        show: false,
        validated: false
      };
      state.prescriberRegistrationDetails.practice.push(defaultPracticeDetails);
    },
    addInstitutionDetails(state) {
      const defaultInstitutionDetails = {
        name: '',
        agbCode: '',
        departments: [
          {
            name: '',
            email: '',
            depotNumber: '',
            prescriberTitleId: 1,
            prescriberInitials: '',
            prescriberFirstName: '',
            prescriberPrePosition: '',
            prescriberLastName: '',
            prescriberEmail: '',
            prescriberPhoneNumber: '',
            prescriberBigCode: '',
            prescriberExternalId: '',
            validated: false,
            showWarranterDetails: false,
            showSearchWarranterDetails: false,
            isAnswered: false,
            warranterSuccessMessage: false
          }
        ],
        postalCode: '',
        houseNumber: '',
        houseNumberAddition: '',
        streetName: '',
        city: '',
        country: 'NL',
        phoneNumber: '',
        email: '',
        show: false,
        validated: false
      };
      state.prescriberRegistrationDetails.institutions.push(defaultInstitutionDetails);
    },
    deletePracticeDetails(state, payload) {
      state.prescriberRegistrationDetails.practice.splice(payload, 1);
      if (payload === 0) {
        state.prescriberRegistrationDetails.practice[payload].show = true;
        return;
      }
      const currentActiveIndex = state.prescriberRegistrationDetails.practice.findIndex((step) => step.show);
      if (currentActiveIndex < 0) {
        state.prescriberRegistrationDetails.practice[payload - 1].show = true;
      }
    },
    deleteInstitutionDetails(state, payload) {
      state.prescriberRegistrationDetails.institutions.splice(payload, 1);
      if (payload === 0) {
        state.prescriberRegistrationDetails.institutions[payload].show = true;
        return;
      }
      const currentActiveIndex = state.prescriberRegistrationDetails.institutions.findIndex((step) => step.show);
      if (currentActiveIndex < 0) {
        state.prescriberRegistrationDetails.institutions[payload - 1].show = true;
      }
    },
    setPracticeActive(state, payload) {
      state.prescriberRegistrationDetails.practice.forEach((step, index) => {
        step.show = false;
        if (index === payload) {
          step.show = true;
        }
      });
    },
    setInstitutionActive(state, payload) {
      state.prescriberRegistrationDetails.institutions.forEach((step, index) => {
        step.show = false;
        if (index === payload) {
          step.show = true;
        }
      });
    },
    setPrescriberRegistrationDepartmentDetails(state, payload) {
      if (payload.type === 'practice') {
        state.prescriberRegistrationDetails.practice[payload.data.parentId].departments[payload.data.id][
          payload.data.details.key
        ] = payload.data.details.value;
      } else {
        state.prescriberRegistrationDetails.institutions[payload.data.parentId].departments[payload.data.id][
          payload.data.details.key
        ] = payload.data.details.value;
      }
    },
    setPrescriberRegistrationPracticeLocationDetails(state, payload) {
      state.prescriberRegistrationDetails.practice[payload.parentId].locations[payload.id][payload.details.key] =
        payload.details.value;
    },
    addDepartmentDetails(state, payload) {
      const defaultDepartmentDetails = {
        name: '',
        email: '',
        depotNumber: '',
        prescriberTitleId: 1,
        prescriberInitials: '',
        prescriberFirstName: '',
        prescriberPrePosition: '',
        prescriberLastName: '',
        prescriberEmail: '',
        prescriberPhoneNumber: '',
        prescriberBigCode: '',
        prescriberExternalId: '',
        validated: false,
        showWarranterDetails: false,
        showSearchWarranterDetails: false,
        isAnswered: false,
        warranterSuccessMessage: false
      };
      if (payload.type === 'practice') {
        state.prescriberRegistrationDetails.practice[payload.index].departments.push(defaultDepartmentDetails);
      } else {
        state.prescriberRegistrationDetails.institutions[payload.index].departments.push(defaultDepartmentDetails);
      }
    },
    addLocationDetails(state, payload) {
      const defaultLocationDetails = {
        locationName: '',
        locationEmail: '',
        validated: false
      };
      state.prescriberRegistrationDetails.practice[payload].locations.push(defaultLocationDetails);
    },
    deleteDepartmentDetails(state, payload) {
      if (payload.type === 'practice') {
        state.prescriberRegistrationDetails.practice[payload.parentId].departments.splice(payload.id, 1);
      } else {
        state.prescriberRegistrationDetails.institutions[payload.parentId].departments.splice(payload.id, 1);
      }
    },
    deleteLocationDetails(state, payload) {
      state.prescriberRegistrationDetails.practice[payload.parentId].locations.splice(payload.id, 1);
    },
    setUploadedImagePreview(state, payload) {
      state.imagePreview = payload;
    },
    setUploadedImageResponse(state, payload) {
      state.signature[0].formData = payload;
    },
    setPrescriberRegistrationTexts(state, payload) {
      state.registrationTexts = payload;
    },
    setColleagueRegistrationTexts(state, payload) {
      state.colleagueRegistrationTexts = payload;
    },
    setcolleagueRegistrationDetails(state, payload) {
      state.prescriberRegistrationDetails = payload;
    },
    setRegistrationSuccessStatus(state, payload) {
      state.registrationSuccess = payload;
    },
    setWarranterSuccessMessage(state, payload) {
      state.warranterSuccessMessage = payload;
    },
    setPrescriberData(state, payload) {
      state.prescriberRegistrationDetails.initials = payload.Initials;
      state.prescriberRegistrationDetails.firstName = payload.FirstName;
      state.prescriberRegistrationDetails.prePosition = payload.PrePosition;
      state.prescriberRegistrationDetails.lastName = payload.LastName;
      state.prescriberRegistrationDetails.maidenName = payload.MaidenName;
      state.prescriberRegistrationDetails.phoneNumber = payload.PhoneNumber;
      state.prescriberRegistrationDetails.mobileNumber = payload.MobileNumber;
      state.prescriberRegistrationDetails.email = payload.Email;
    },
    setPrePositions(state, payload) {
      state.prePositionsData = payload;
    },
    resetPrescriberData(state) {
      const resetKeys = [
        'initials',
        'firstName',
        'prePosition',
        'lastName',
        'maidenName',
        'phoneNumber',
        'mobileNumber',
        'email'
      ];
      resetKeys.forEach((key) => {
        state.prescriberRegistrationDetails[key] = '';
      });
    },
    updatePrescriberData(state, field) {
      state.prescriberRegistrationDetails[field.key] = field.value;
    },
    updateDepartmentData(state, field) {
      updateField(state.prescriberRegistrationDetails.institutions[0].departments[0], field);
    },
    setShowRoleSelection(state, payload) {
      state.prescriberRegistrationDetails.showRoleSelection = payload;
    }
  },
  actions: {
    savePrescriberRegistration({ state, commit }) {
      let userType = 1;
      if (state.prescriberRegistrationDetails.userType) {
        userType = 2;
      }
      if (
        state.prescriberRegistrationDetails.dateOfBirth &&
        state.prescriberRegistrationDetails.dateOfBirth.indexOf('-') === 2
      ) {
        state.prescriberRegistrationDetails.dateOfBirth = moment(
          state.prescriberRegistrationDetails.dateOfBirth,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
      }
      state.prescriberRegistrationDetails.userType = userType;
      state.prescriberRegistrationDetails.signature = state.signature[0].formData;
      if (!state.signature[0].upload) {
        state.prescriberRegistrationDetails.signature = state.prescriberRegistrationDetails.signature.substr(
          22,
          state.prescriberRegistrationDetails.signature.length
        );
      }
      const treatmentIndex = state.prescriberRegistrationDetails.treatmentAreas.findIndex(
        (treatmentArea) => treatmentArea.treatmentAreaId === '1010'
      );
      if (state.prescriberRegistrationDetails.treatmentAreas[treatmentIndex].active) {
        state.prescriberRegistrationDetails.treatmentAreas.forEach((area) => {
          area.active = true;
        });
      }
      state.prescriberRegistrationDetails.registrationType = 1;
      let computedDetails = state.prescriberRegistrationDetails;
      if (computedDetails.practice) {
        computedDetails.practice.forEach((practice) => {
          delete practice.locations;
          practice.locations = practice.departments;
          delete practice.departments;
        });
      }
      if (computedDetails.treatmentAreas[treatmentIndex].active) {
        computedDetails.institutions = null;
      } else {
        computedDetails.practice = null;
      }
      computedDetails.treatmentAreas[treatmentIndex].active = false;
      return axios
        .post('Registration/RegisterPrescriber', computedDetails)
        .then((response) => {
          if (response && response.data && response.data.Success) {
            commit('setRegistrationSuccessStatus', true);
          }
          return response.data;
        })
        .catch(() => {
          if (state.prescriberRegistrationDetails.userType === 2) {
            state.prescriberRegistrationDetails.userType = true;
          } else {
            state.prescriberRegistrationDetails.userType = false;
          }
          if (state.prescriberRegistrationDetails.dateOfBirth.indexOf('-') === 4) {
            state.prescriberRegistrationDetails.dateOfBirth = moment(
              state.prescriberRegistrationDetails.dateOfBirth,
              'YYYY-MM-DD'
            ).format('DD-MM-YYYY');
          }
        });
    },
    saveColleagueRegistration({ state, commit }) {
      state.prescriberRegistrationDetails.registrationType = 2;
      let userType = 1;
      if (state.prescriberRegistrationDetails.userType) {
        userType = 2;
      }
      state.prescriberRegistrationDetails.userType = userType;

      let dateOfBirth = state.prescriberRegistrationDetails.dateOfBirth;
      if (dateOfBirth && dateOfBirth.indexOf('-') === 2) {
        state.prescriberRegistrationDetails.dateOfBirth = moment(
          state.prescriberRegistrationDetails.dateOfBirth,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
      }

      return axios.post('Registration/RegisterColleague', state.prescriberRegistrationDetails).then((response) => {
        if (response && response.data) {
          commit('setRegistrationSuccessStatus', true);
        }
      });
    },
    saveUploadedFile({ state, commit }) {
      return axios.post('Registration/SignatureUpload', state.signature[0].formData).then((response) => {
        commit('setUploadedImageResponse', response.data);
      });
    },
    fetchRegistrationTexts({ commit }) {
      axios.get('Registration/RegistrationSitecoreTexts').then((response) => {
        if (response && response.data && response.data.Success) {
          commit('setPrescriberRegistrationTexts', response.data.RegistrationTexts);
        }
      });
    },
    fetchWarranterDetails({ state, commit }, warranterEmail) {
      return axios.get('Registration/WarranterDetails?warranterEmail=' + warranterEmail);
    },
    fetchPrescriberDataUsingCode({ state, commit }, details) {
      let api = details.isBigCode ? 'Registration/BigCodeLookUp?bigCode=' : 'Registration/AgbLookUp?agbCode=';
      return axios.get(api + details.code).then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.Success &&
          response.data.data.AllowRegistration
        ) {
          if (response.data.data.AccountRequest && response.data.data.AccountRequest.FirstName) {
            commit('setPrescriberData', response.data.data.AccountRequest);
          }
        } else {
          commit('resetPrescriberData');
        }
        return response.data.data.IsUserExists;
      });
    },
    fetchColleagueTexts({ commit }) {
      axios.get('Colleagues/RegisterColleaguesSitecoreTexts').then((response) => {
        if (response) {
          commit('setColleagueRegistrationTexts', response.data);
        }
      });
    },
    fetchAddressLookup({ commit }, { postcode, huisnummer, land }) {
      return axios.get('Registration/Lookup', {
        params: {
          Zipcode: postcode,
          Number: huisnummer,
          CountryCode: land
        }
      });
    },
    fetchPrePositions({ commit }) {
      axios.get('Registration/GetPrePositions').then((response) => {
        if (response && response) {
          commit('setPrePositions', response.data);
        }
      });
    }
  },
  getters: {
    getPrescriberRegistrationDetails: (state) => {
      return state.prescriberRegistrationDetails;
    },
    getPrePositionsRecords: (state) => {
      return state.prePositionsData;
    },
    getPrescriberRegistrationSteps: (state) => {
      return state.prescriberRegistrationSteps;
    },
    getUploadedFileName: (state) => {
      return state.uploadedFileName;
    },
    getUploadedImage: (state) => {
      return state.imagePreview;
    },
    getSignatureDetails: (state) => {
      return state.signature;
    },
    getPrescriberRegistrationTexts: (state) => {
      return state.registrationTexts;
    },
    getColleagueRegistrationTexts: (state) => {
      return state.colleagueRegistrationTexts;
    },
    getRegistrationSuccessStatus: (state) => {
      return state.registrationSuccess;
    },
    getWarranterSuccessMessage: (state) => {
      return state.warranterSuccessMessage;
    },
    getColleagueRegistrationDetails: (state) => {
      return state.prescriberRegistrationDetails;
    },
    getPrescriberData: (state) => {
      return getField(state.prescriberRegistrationDetails);
    },
    getDepartmentData: (state) => {
      return getField(state.prescriberRegistrationDetails.institutions[0].departments[0]);
    },
    getRoles: (state) => {
      return state.prescriberRegistrationDetails.gpRoles;
    }
  }
};
