<template>
  <fieldset v-if="manufacturers.length !== 0" id="catalogManufacturers" class="manufacturers">
    <div class="row manufacturers">
      <h1>Fabrikant</h1>
    </div>
    <v-checkbox-group
      v-for="(manufacturer, index) in manufacturersByCustomFilter"
      :key="index"
      :label="manufacturer"
      :inputid="'manufacturer' + index"
      :initvalue="selected.indexOf(manufacturer) > -1"
      :overwrite-value="selected.indexOf(manufacturer) > -1"
      @update="change(manufacturer, $event)"
    />
    <div v-if="manufacturers && manufacturers.length > filterCountDefault" class="row grid-padding">
      <div class="manufacturer-count-button" @click.prevent="toggleFilterCount">
        <template v-if="!filterCount">
          {{ filterCountButtonTextOptions.less }}
        </template>
        <template v-if="filterCount > 0">
          {{ filterCountButtonTextOptions.more }}
        </template>
        <arrow-icon :class="{ viewMore: !filterCount }" />
      </div>
    </div>
  </fieldset>
</template>

<script>
import VCheckboxGroup from './CheckboxGroup';
import ArrowIcon from 'icons/ArrowDown';

export default {
  name: 'VManufacturers',
  components: {
    VCheckboxGroup,
    ArrowIcon
  },
  props: {
    manufacturers: Array,
    value: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      filterCountButtonTextOptions: {
        more: 'Meer fabrikanten',
        less: 'Minder fabrikanten'
      },
      filterCountDefault: 5,
      filterCount: 5,
      selected: []
    };
  },
  computed: {
    /**
     * @returns {array} - list with manufacturers filtered by dynamic value of count and sliced by dynamic value of filtercount
     */
    manufacturersByCustomFilter() {
      let computedManufacturers = this.manufacturers;
      computedManufacturers.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
      });
      if (computedManufacturers.length > 0) {
        // Slice the manufacturers by the filter count if active else return all
        return this.filterCount ? computedManufacturers.slice(0, this.filterCount) : computedManufacturers;
      } else {
        return [];
      }
    }
  },
  methods: {
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    change(manufacturer, active) {
      if (active) {
        this.selected.push(manufacturer);
      } else {
        const index = this.selected.findIndex((item) => item.bundleId === manufacturer.bundleId);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
      this.$emit('update:modelValue', this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
.manufacturers {
  border: none;
  margin: 0;
  padding: 0;

  > h1 {
    font-size: type-scale(2);
    margin-top: 0;
    margin-bottom: 10px;
    color: $gray-darkest;
  }

  .checkbox-group {
    margin-bottom: 10px;
  }
  .material-design-icon {
    width: 1.2em;
    align-self: baseline;
  }
  .manufacturer-count-button {
    cursor: pointer;
    display: flex;
    flex-direction: initial;
    justify-content: flex-end;
    align-items: center;
    color: $teal-darkest;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 3px solid $orange;
    padding-bottom: 10px;
    margin-top: 10px;
    .material-design-icon {
      fill: $teal-darkest;
      margin: 0px 0px 0px 5px;
    }
    .viewMore {
      transform: rotate(180deg);
    }
  }
}
</style>
