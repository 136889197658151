<template>
  <div class="password-rules">
    <div class="password-rule">
      <v-check-icon v-show="hasLength" />
      <v-close v-show="!hasLength" />
      Langer dan 7 tekens
    </div>
    <div class="password-rule">
      <v-check-icon v-show="hasUppercase" />
      <v-close v-show="!hasUppercase" />
      Bevat een hoofdletter
    </div>
    <div class="password-rule">
      <v-check-icon v-show="hasLowercase" />
      <v-close v-show="!hasLowercase" />
      Bevat een kleine letter
    </div>
    <div class="password-rule">
      <v-check-icon v-show="hasNumber" />
      <v-close v-show="!hasNumber" />
      Bevat een cijfer
    </div>
    <div class="password-rule">
      <v-check-icon v-show="hasSpecial" />
      <v-close v-show="!hasSpecial" />
      Bevat een speciaal teken
    </div>
  </div>
</template>

<script>
  import VCheckIcon from 'icons/Check'
  import VClose from 'icons/Close'

  export default {
    name: 'VPasswordValidation',
    components: {
      VCheckIcon,
      VClose
    },
    props: {
      value: {
        type: String
      }
    },
    data () {
      return {
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        hasSpecial: false,
        hasLength: false
      }
    },
    watch: {
      value: function(val) {
        this.hasNumber = /\d/.test(val);
        this.hasLowercase = /[a-z]/.test(val);
        this.hasUppercase = /[A-Z]/.test(val);
        this.hasSpecial = /[!@#\$%\^\&*\)\(+=._-]/.test(val);
        this.hasLength = val.length > 7;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .password-rules {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      margin: 24px 0 0 32px;
    }

    .password-rule {
      display: inline-flex;
      align-items: center;
      color: $teal-darkest;
      .check-icon {
        fill: $medium-green;
        margin-right: 10px;
      }
      .close-icon {
        fill: $pure-red;
        margin-right: 10px;
      }
    }
  }
</style>