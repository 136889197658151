<template>
  <div
    class="checkbox-group"
    :class="{
      disabled: disabled,
      'checkbox-group--error': error
    }"
  >
    <div
      class="checkbox-group__items"
      :class="{
        disabled: disabled
      }"
    >
      <input
        v-bind="$attrs"
        v-on="$attrs"
        :id="inputid"
        v-model="value"
        class="checkbox-group__checkbox"
        :value="value"
        :disabled="disabled"
        :name="inputid"
        type="checkbox"
        @change="update"
        @focus="focus"
        @blur="blur"
      />
      <label class="checkbox-group__label" :for="inputid" @click.prevent="labelClick">
        <span />
        <div class="checkbox-group__label__inner">
          {{ label }}<span v-if="rule.required && label" class="asterisk"> *</span>
          <slot />
        </div>
      </label>
      <v-check-icon class="checkbox-group__icon" />
    </div>
    <div class="checkbox-group__details">
      <div class="checkbox-group__messages">
        <div v-show="error" class="checkbox-group__error">* Dit veld is verplicht.</div>
      </div>
    </div>
  </div>
</template>

<script>
import VCheckIcon from 'icons/Check';
import { ref, watch, onMounted, getCurrentInstance } from 'vue';

export default {
  name: 'VCheckboxGroup',
  components: {
    VCheckIcon
  },
  props: {
    label: {
      default: '',
      type: String
    },
    inputid: {
      required: true,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    initvalue: {
      default: false,
      type: Boolean
    },
    rule: {
      default: () => ({}),
      type: Object
    },
    error: {
      default: false,
      type: Boolean
    },
    errorMessage: {
      default: 'Error',
      type: String
    },
    overwriteValue: Boolean
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const value = ref(props.initvalue);

    watch(
      () => props.overwriteValue,
      (newValue) => {
        value.value = newValue;
      }
    );

    const update = (event) => {
      value.value = event.target.checked;
      instance.emit('update:modelValue', value.value);
    };

    const focus = () => {
      // Your focus logic here
    };

    const blur = () => {
      // Your blur logic here
      emit('blur');
    };

    const labelClick = () => {
      value.value = !value.value;
      emit('update:modelValue', value.value);
    };

    onMounted(() => {
      // Your mounted logic here
    });

    return {
      value,
      update,
      focus,
      blur,
      labelClick
    };
  }
};
</script>

<style lang="scss" scoped>
// Your styles remain unchanged
</style>

<style lang="scss" scoped>
.checkbox-group {
  $root: &;

  #{$root}__items {
    position: relative;
  }

  #{$root}__checkbox {
    cursor: pointer;
    width: 1.3em;
    height: 1.3em;
    position: absolute;
    margin: 0;
    padding: 0;
    opacity: 0;

    + .checkbox-group__label {
      line-height: 1.5em;
      color: $gray-dark;
      display: flex;
      font-size: 14px;

      > span {
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin: 0px 5px 0 0;
        border: 1px solid $gray;
        border-radius: 3px;
        background: $white;
        flex-shrink: 0;

        &:before {
          display: block;
          width: 1em;
          color: $white;
          font-size: 1em;
          line-height: 1em;
          text-align: center;
          margin: 1px 0 0 2px;
          text-shadow: 0 0 0.0714em rgba(0, 0, 0, 0.05);
          font-weight: bold;
        }
      }
    }

    &:checked + .checkbox-group__label,
    &[value='true'] + .checkbox-group__label {
      color: $gray-darkest;
      > span {
        background: $dark-green;
      }
    }
    &:disabled + .checkbox-group__label {
      pointer-events: none;
      > span {
        pointer-events: none;
        background: $gray-lighter;
      }
    }
    &:disabled + .checkbox-group__icon {
      background: $gray-lighter;
    }
  }

  #{$root}__label__inner {
    line-height: 20px !important;
    .asterisk {
      color: $red;
    }
  }

  &.checkbox-group--error {
    flex: 1 0;
    color: $form-group-error-color;
    transition: $form-group-error-transition;

    .checkbox-group__error {
      margin-left: 27px;
      font-size: $form-group-details-font-size;
      min-height: 26px;
      border-top: 1px solid $form-group-error-color;
      padding: 5px 0px 5px 0px;
      flex: 1 0;
      color: $form-group-error-color;
      transition: $form-group-error-transition;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 21px;
    :deep(svg) {
      padding: 0px 6px 4px 2px;
    }
    // main.scss contains global styling of icon svg
  }
}
</style>
