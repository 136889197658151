import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, refCount, publishReplay } from 'rxjs/operators';
import axios from "axios";

var storeKey = 'lastSelectedPortalCode';
const activePortalCodeSubject$ = new ReplaySubject(1);

// Exported helper method to push event
export function pushActivePortalCode (portalCode) {
  window.localStorage.setItem(storeKey, portalCode);
  setAxiosPortalCode(portalCode);
  activePortalCodeSubject$.next(portalCode);
}

// Exported observables
export const activePortalCode$ = activePortalCodeSubject$.pipe(
  distinctUntilChanged(),
  publishReplay(1),
  refCount()
).asObservable();

// trick: keep it until we made all api calls accept the customerId as normal argument
export function setAxiosPortalCode(portalCode) {
  axios.defaults.headers.common["portal"] = portalCode;
}
