<template>
  <div class="tokens">
    <span
      v-if="tokens && tokens.length > 0 && resultCount >= 0"
      v-show="tokens.length"
      class="tokens__count"
    >{{ resultCount }} {{ producten }}</span>
    <v-token
      v-for="(token, index) in tokens"
      :key="index"
      :name="token.label"
      @remove="token.remove()"
    />
  </div>
</template>

<script>
import VToken from './Token'

export default {
  name: 'VTokens',
  components: {
    VToken
  },
  props: {
    tokens: Array,
    resultCount: Number
  },
  computed: {
    producten () {
      return this.resultCount > 1 ? 'producten' : 'product'
    }
  }
}
</script>

<style lang="scss" scoped>
.tokens {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 48px;

  &__count {
    font-size: type-scale(2);
    font-weight: bold;
    color: $gray-darkest;
    margin-right: 16px;
  }

  .is-hidden {
    opacity: 0;
  }
}
</style>
