<template>
  <div v-if="patientFormData.Teksten && patientGegevens" class="container patient-details">
    <div class="row">
      <div class="col-md-12">
        <v-heading :level="1">
          {{ patientFormData.Teksten.Persoonsgegevens }}
        </v-heading>
      </div>
    </div>
    <form ref="patientForm" method="get" @submit="validateBeforeSubmit">
      <input
        v-if="patientGegevens.Id === '0' || patientGegevens.Id === null || patientGegevens.Id === ''"
        type="hidden"
        name="Id"
        value="0"
      />
      <input v-if="patientGegevens.Id !== '0'" type="hidden" name="Id" :value="patientGegevens.Id" />
      <p v-if="patientFormData.Melding">
        {{ patientFormData.Melding }}
      </p>
      <div id="step1" class="row grid-margin">
        <div class="col-md-12 patient-details__block">
          <v-header title="Wijzig hier de persoonsgegevens" />
          <div class="patient-details__block__content">
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-select-group
                  v-model="patientGegevens.GeslachtId"
                  :label="patientFormData.Teksten.Labels.Geslacht"
                  selectid="GeslachtId"
                  :rule="{
                    required: true
                  }"
                  :initselected="patientGegevens.GeslachtId"
                  @change="select($event, 'GeslachtId')"
                  placeholder="Kies"
                >
                  <option
                    v-for="(item, index) in patientFormData.StamData.Geslachten"
                    :key="index"
                    :data-index="index"
                    :value="item.GeslachtId"
                  >
                    {{ item.Naam }}
                  </option>
                </v-select-group>
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Voorletters"
                  v-model:value="patientGegevens.Voorletters"
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Voorletters"
                  @input="saveInput"
                />
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Voornaam"
                  v-model:value="patientGegevens.Voornaam"
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Voornaam"
                  @input="saveInput"
                />
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-select-group
                  :name="patientFormData.Teksten.Labels.Tussenvoegsel"
                  :label="patientFormData.Teksten.Labels.Tussenvoegsel"
                  :selectid="patientFormData.Teksten.Labels.Tussenvoegsel"
                  :initselected="patientGegevens.Tussenvoegsel"
                  placeholder="Selecteer een optie"
                  @change="select($event, 'Tussenvoegsel')"
                >
                  <option
                    v-for="(item, index) in patientFormData.StamData.PrePositions"
                    :key="index"
                    :data-index="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </v-select-group>
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Achternaam"
                  v-model:value="patientGegevens.Achternaam"
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Achternaam"
                  @input="saveInput"
                />
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Geboortedatum"
                  v-model:value="birthDate"
                  :rules="{
                    required: true,
                    max: 10
                  }"
                  :disabled="birthDate !== null"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Geboortedatum"
                  @input="saveInput"
                />
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Polisnummer"
                  v-model:value="patientGegevens.Polisnummer"
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Polisnummer"
                  :disabled="disabledFields.Polisnummer"
                  :overwrite-value="patientGegevens.Polisnummer"
                  @input="saveInput"
                />
              </div>
              <div class="col-md-4">
                <v-select-group
                  v-model="patientGegevens.VerzekeraarId"
                  :disabled="disabledFields.VerzekeraarId"
                  :label="patientFormData.Teksten.Labels.Verzekeraar"
                  :selectid="patientFormData.Teksten.Labels.Verzekeraar"
                  :rule="{
                    required: true
                  }"
                  :initselected="patientGegevens.VerzekeraarId"
                  :overwrite-value="patientGegevens.VerzekeraarId"
                  placeholder="Selecteer een optie"
                  @change="select($event, 'VerzekeraarId')"
                >
                  <option
                    v-for="(item, index) in patientFormData.StamData.Verzekeraars"
                    :key="index"
                    :data-index="index"
                    :value="item.PayerId"
                  >
                    {{ item.Name }}
                  </option>
                </v-select-group>
              </div>
              <div v-if="showDisabledBSN" class="col-md-4">
                <v-input-group-simple-2
                  id="BSNnummer"
                  v-model:value="patientGegevens.BSNnummer"
                  :rules="{ required: true, min: 9, max: 9, isBsn: true }"
                  :disabled="disabledFields.BSNnummer"
                  type="text"
                  :label="patientFormData.Teksten.Labels.BSNnummer"
                  @input="saveInput"
                />
              </div>
            </div>
            <div v-if="!showDisabledBSN" class="row grid-margin grid-align-center">
              <div class="col-md-4">
                <v-input-group-simple-2
                  v-if="!NoBSNNummer"
                  id="BSNnummer"
                  v-model:value="patientGegevens.BSNnummer"
                  :rules="{ required: true, min: 9, max: 9, isBsn: true }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.BSNnummer"
                  @input="saveInput"
                />
                <input v-if="!NoBSNNummer" id="HeeftBSNNummer" type="hidden" name="HeeftBSNNummer" value="true" />
                <v-select-group
                  v-if="NoBSNNummer"
                  v-model="patientGegevens.RedenGeenBSN"
                  :rule="{
                    required: true
                  }"
                  :label="patientFormData.Teksten.Labels.RedenGeenBSN"
                  selectid="RedenGeenBSN"
                  :initselected="patientGegevens.RedenGeenBSN"
                  @change="select($event, 'RedenGeenBSN')"
                  :placeholder="patientFormData.Teksten.Labels.SelecteerEenReden"
                >
                  <option
                    v-for="(item, index) in patientFormData.StamData.GeenBSNRedenen"
                    :key="index"
                    :data-index="index"
                    :value="index + 1"
                  >
                    {{ item }}
                  </option>
                </v-select-group>
                <input v-if="NoBSNNummer" id="HeeftBSNNummer" type="hidden" name="HeeftBSNNummer" value="false" />
              </div>
              <div class="col-md-4">
                <v-checkbox-group
                  v-model="NoBSNNummer"
                  :label="patientFormData.Teksten.Labels.GeenBSN"
                  inputid="NoBSNNummer"
                  :initvalue="NoBSNNummer"
                />
              </div>
            </div>
            <input
              v-if="
                patientGegevens.WoonAdres.AdresId === '0' ||
                patientGegevens.WoonAdres.AdresId === null ||
                patientGegevens.WoonAdres.AdresId === ''
              "
              type="hidden"
              name="WoonAdresId"
              value="0"
            />
            <input
              v-if="patientGegevens.WoonAdres.AdresId !== '0'"
              type="hidden"
              name="WoonAdresId"
              :value="patientGegevens.WoonAdres.AdresId"
            />
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="'WoonAdres' + patientFormData.Teksten.Labels.Postcode"
                  v-model:value="patientGegevens.WoonAdres.Postcode"
                  maxlength="7"
                  :rules="postCodeRule"
                  :data-vv-as="patientFormData.Teksten.Labels.Postcode"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Postcode"
                  @blur="autoFillAddress('WoonAdres')"
                  @input="saveAddressInput($event, 'WoonAdres', patientFormData.Teksten.Labels.Postcode)"
                />
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="'WoonAdres' + patientFormData.Teksten.Labels.Huisnr"
                  v-model:value="patientGegevens.WoonAdres.Huisnummer"
                  :rules="{
                    required: true
                  }"
                  :data-vv-as="patientFormData.Teksten.Labels.Huisnr"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Huisnr"
                  @blur="autoFillAddress('WoonAdres')"
                  @input="saveAddressInput($event, 'WoonAdres', patientFormData.Teksten.Labels.Huisnr)"
                />
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="'WoonAdres' + patientFormData.Teksten.Labels.Toevoeging"
                  v-model:value="patientGegevens.WoonAdres.Toevoeging"
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Toevoeging"
                  @input="saveAddressInput($event, 'WoonAdres', patientFormData.Teksten.Labels.Toevoeging)"
                />
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="'WoonAdres' + patientFormData.Teksten.Labels.Straatnaam"
                  v-model:value="patientGegevens.WoonAdres.Straatnaam"
                  :rules="{
                    required: true
                  }"
                  :data-vv-as="patientFormData.Teksten.Labels.Straatnaam"
                  type="text"
                  :disabled="disableAddress"
                  :label="patientFormData.Teksten.Labels.Straatnaam"
                  :overwrite-value="patientGegevens.WoonAdres.Straatnaam"
                  @input="saveAddressInput($event, 'WoonAdres', patientFormData.Teksten.Labels.Straatnaam)"
                />
              </div>
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="'WoonAdres' + patientFormData.Teksten.Labels.Woonplaats"
                  v-model:value="patientGegevens.WoonAdres.Woonplaats"
                  :rules="{
                    required: true
                  }"
                  :data-vv-as="patientFormData.Teksten.Labels.Woonplaats"
                  type="text"
                  :disabled="disableAddress"
                  :label="patientFormData.Teksten.Labels.Woonplaats"
                  :overwrite-value="patientGegevens.WoonAdres.Woonplaats"
                  @input="saveAddressInput($event, 'WoonAdres', patientFormData.Teksten.Labels.Woonplaats)"
                />
              </div>
              <div class="col-md-4">
                <v-select-group
                  v-model="patientGegevens.WoonAdres.Land"
                  :label="patientFormData.Teksten.Labels.Land"
                  selectid="WoonAdresLand"
                  :initselected="patientGegevens.WoonAdres.Land || 'NL'"
                  :rule="{
                    required: true
                  }"
                  @input="onCountryChange(...arguments, 'postCodeRule')"
                  @change="autoFillAddress('WoonAdres')"
                >
                  <option
                    v-for="(item, index) in patientFormData.StamData.Landen"
                    :key="index"
                    :data-index="index"
                    :value="item.IsoCode"
                  >
                    {{ item.Naam }}
                  </option>
                </v-select-group>
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col">
                <v-checkbox-group
                  v-model="differentShippingAddress"
                  label="Afwijkend afleveradres opgeven"
                  inputid="addressToggle"
                  :initvalue="differentShippingAddress"
                  :overwrite-value="differentShippingAddress"
                />
              </div>
            </div>
            <div v-if="differentShippingAddress">
              <v-heading :level="3">
                {{ patientFormData.Teksten.AfwijkendAfleveradres }}
              </v-heading>
              <input
                v-if="
                  patientGegevens.AfwijkendeAdres.AdresId === '0' ||
                  patientGegevens.AfwijkendeAdres.AdresId === null ||
                  patientGegevens.AfwijkendeAdres.AdresId === ''
                "
                type="hidden"
                name="AfwijkendeAdresId"
                value="0"
              />
              <input
                v-if="patientGegevens.AfwijkendeAdres.AdresId !== null"
                type="hidden"
                name="AfwijkendeAdresId"
                :value="patientGegevens.AfwijkendeAdres.AdresId"
              />
              <div class="row grid-margin">
                <div class="col-md-4">
                  <v-input-group-simple-2
                    :id="'AfwijkendeAdres' + patientFormData.Teksten.Labels.Postcode"
                    v-model:value="patientGegevens.AfwijkendeAdres.Postcode"
                    maxlength="7"
                    :rules="altPostCodeRule"
                    :data-vv-as="patientFormData.Teksten.Labels.Postcode"
                    type="text"
                    :label="patientFormData.Teksten.Labels.Postcode"
                    @blur="autoFillAddress('AfwijkendeAdres')"
                    @input="saveAddressInput($event, 'AfwijkendeAdres', patientFormData.Teksten.Labels.Postcode)"
                  />
                </div>
                <div class="col-md-4">
                  <v-input-group-simple-2
                    :id="'AfwijkendeAdres' + patientFormData.Teksten.Labels.Huisnr"
                    v-model:value="patientGegevens.AfwijkendeAdres.Huisnummer"
                    :rules="{
                      required: true
                    }"
                    :data-vv-as="patientFormData.Teksten.Labels.Huisnr"
                    type="text"
                    :label="patientFormData.Teksten.Labels.Huisnr"
                    @blur="autoFillAddress('AfwijkendeAdres')"
                    @input="saveAddressInput($event, 'AfwijkendeAdres', patientFormData.Teksten.Labels.Huisnr)"
                  />
                </div>
                <div class="col-md-4">
                  <v-input-group-simple-2
                    :id="'AfwijkendeAdres' + patientFormData.Teksten.Labels.Toevoeging"
                    v-model:value="patientGegevens.AfwijkendeAdres.Toevoeging"
                    :rules="{
                      required: false
                    }"
                    type="text"
                    :label="patientFormData.Teksten.Labels.Toevoeging"
                    @input="saveAddressInput($event, 'AfwijkendeAdres', patientFormData.Teksten.Labels.Toevoeging)"
                  />
                </div>
              </div>
              <div class="row grid-margin">
                <div class="col">
                  <v-input-group-simple-2
                    :id="'AfwijkendeAdres' + patientFormData.Teksten.Labels.Straatnaam"
                    v-model:value="patientGegevens.AfwijkendeAdres.Straatnaam"
                    :rules="{
                      required: true
                    }"
                    :data-vv-as="patientFormData.Teksten.Labels.Straatnaam"
                    :disabled="disableAlternateAddress"
                    type="text"
                    :label="patientFormData.Teksten.Labels.Straatnaam"
                    :overwrite-value="patientGegevens.AfwijkendeAdres.Straatnaam"
                    @input="saveAddressInput($event, 'AfwijkendeAdres', patientFormData.Teksten.Labels.Straatnaam)"
                  />
                </div>
                <div class="col">
                  <v-input-group-simple-2
                    :id="'AfwijkendeAdres' + patientFormData.Teksten.Labels.Woonplaats"
                    v-model:value="patientGegevens.AfwijkendeAdres.Woonplaats"
                    :rules="{
                      required: true
                    }"
                    :data-vv-as="patientFormData.Teksten.Labels.Woonplaats"
                    type="text"
                    :disabled="disableAlternateAddress"
                    :label="patientFormData.Teksten.Labels.Woonplaats"
                    :overwrite-value="patientGegevens.AfwijkendeAdres.Woonplaats"
                    @input="saveAddressInput($event, 'AfwijkendeAdres', patientFormData.Teksten.Labels.Woonplaats)"
                  />
                </div>
                <div class="col">
                  <v-select-group
                    v-model="patientGegevens.AfwijkendeAdres.Land"
                    :label="patientFormData.Teksten.Labels.Land"
                    selectid="AfwijkendeAdresLand"
                    :initselected="patientGegevens.AfwijkendeAdres.Land || 'NL'"
                    @input="onCountryChange(...arguments, 'altPostCodeRule')"
                    @change="autoFillAddress('AfwijkendeAdres')"
                  >
                    <option
                      v-for="(item, index) in patientFormData.StamData.Landen"
                      :key="index"
                      :data-index="index"
                      :value="item.IsoCode"
                    >
                      {{ item.Naam }}
                    </option>
                  </v-select-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <v-heading :level="1">
            {{ patientFormData.Teksten.Contactgegevens }}
          </v-heading>
        </div>
      </div>
      <div id="step2" class="row grid-margin">
        <div class="col-md-12 patient-details__block">
          <v-header title="Wijzig hier de contactgegevens" />
          <div class="patient-details__block__content">
            <div
              v-for="(customerPhoneNumber, index) in patientGegevens.CustomerPhoneNumbers"
              :key="index"
              class="row grid-margin"
            >
              <div class="col-md-2">
                <label :for="'telephoneNumberType' + index">Type *</label>
              </div>
              <div class="col-md-10">
                <label :for="'telephoneNumber' + index">{{
                  patientFormData.Teksten.Labels.Telefoonnummer + ' *'
                }}</label>
              </div>
              <div class="col-md-12">
                <div class="row grid-margin">
                  <div class="col-md-2 no-vertical-space">
                    <v-select-group
                      v-model="customerPhoneNumber.IsMobileNumber"
                      :selectid="'telephoneNumber' + index"
                      :initselected="customerPhoneNumber.IsMobileNumber"
                      @change="onContactTypeChange($event.target.value, index)"
                    >
                      <option
                        v-for="(item, index) in contactTypes"
                        :key="index"
                        :data-index="index"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </option>
                    </v-select-group>
                  </div>
                  <div class="col-md-1 no-vertical-space">
                    <vue3-country-intl
                      noDataText=""
                      placeholder=""
                      :only-value="true"
                      :ref="'TelefoonnummerLandCode' + index"
                      v-model="customerPhoneNumber.PhoneNumberDialCode"
                      @change="onTelephoneNumberSelect($event, index)"
                    >
                    </vue3-country-intl>
                  </div>
                  <div class="col-md-4 no-vertical-space">
                    <v-input-group-simple-2
                      :id="patientFormData.Teksten.Labels.Telefoonnummer + ' ' + index"
                      :rules="{
                        required: true,
                        regex: /^(?:\d{1,11}|(?=\d+ \d+$)[\d ]{3,12})$/,
                        numeric: true,
                        max: 999999999,
                        min: 111111111
                      }"
                      maxlength="9"
                      :value="getComputedPhoneNumber(customerPhoneNumber.PhoneNumber, index)"
                      type="text"
                      :fieldNameForErrorMessage="patientFormData.Teksten.Labels.Telefoonnummer"
                      placeholder="612345678"
                      @input="savePhoneNumberInput($event, 'CustomerPhoneNumbers', index)"
                    />
                  </div>
                  <div v-if="otherTelephoneNumber && index > 0" class="col-md-1 no-vertical-space grid-align-start">
                    <v-button
                      md
                      hollow
                      transparent
                      @click.prevent="showOtherTelephoneNumber(false, customerPhoneNumber.PhoneNumberId)"
                    >
                      <v-delete-circle-icon
                        v-tooltip="{ content: 'Verwijderen', placement: 'right', html: false }"
                        class="step3_icon"
                      />
                    </v-button>
                  </div>
                  <div v-if="!otherTelephoneNumber">
                    <v-button
                      lg
                      primary
                      icon-left
                      class="phoneNumberAlign"
                      @click.prevent="showOtherTelephoneNumber(true, customerPhoneNumber.PhoneNumberId)"
                    >
                      <plus-circle-icon class="step3_icon" />
                      Telefoonnummer toevoegen
                    </v-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.Labels.Emailadres"
                  v-model:value="patientGegevens.Emailadres"
                  :rules="{
                    required: !noEmailAddress,
                    email: true,
                    regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                  }"
                  type="text"
                  :label="patientFormData.Teksten.Labels.Emailadres"
                  placeholder="voorbeeld@email.com"
                  @input="saveInput"
                />
              </div>
              <div
                v-if="patientGegevens.Emailadres === null || patientGegevens.Emailadres === ''"
                class="col-md-12 grid-justify-content-center"
              >
                <v-checkbox-group
                  v-model="noEmailAddress"
                  :label="patientFormData.Teksten.Labels.NoEmailAddressCheckbox"
                  inputid="chkNoEmail"
                  :initvalue="noEmailAddress"
                />
              </div>
            </div>
            <div
              v-show="noEmailAddress && (patientGegevens.Emailadres === null || patientGegevens.Emailadres === '')"
              class="row grid-margin"
            >
              <div class="col-md-8">
                <v-warning-message
                  v-if="noEmailAddress"
                  :warning="patientFormData.Teksten.NoEmailAddressWarning"
                  :html="true"
                  :is-urgent="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <v-heading :level="1">
            {{ patientFormData.Teksten.CustomerNumberTitle }}
          </v-heading>
        </div>
      </div>
      <div id="step3" class="row grid-margin">
        <div class="col-md-12 patient-details__block">
          <v-header :title="patientFormData.Teksten.CustomerNumberDescription" />
          <div class="patient-details__block__content">
            <div class="row grid-margin">
              <div class="col-md-4">
                <v-input-group-simple-2
                  :id="patientFormData.Teksten.CustomerNumberTitle"
                  :rules="{
                    required: false,
                    numeric: true
                  }"
                  :value="customerNumber"
                  type="text"
                  :label="patientFormData.Teksten.CustomerNumberTitle"
                  @input="saveCustomerNumber"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <v-heading :level="1">
            {{ patientFormData.Teksten.GegevensTitle }}
          </v-heading>
        </div>
      </div>
      <div id="step4" class="row grid-margin">
        <div class="col-md-12 patient-details__block">
          <v-header title="Wijzig hier de toestemming voor het delen van gegevens" />
          <div class="patient-details__block__content">
            <div class="row grid-margin">
              <div class="col GegevensDelenText">
                {{ patientFormData.Teksten.GegevensInfo }}
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col">
                <a
                  v-if="patientFormData.Teksten.GegevensLinkText"
                  href=""
                  target="_blank"
                  class="btn btn--link"
                  @click.prevent="openGegevensLink(patientFormData.Teksten.GegevensLinkUrl)"
                >
                  <chevron-right class="icon-color-teal" />{{ patientFormData.Teksten.GegevensLinkText }}
                </a>
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col GegevensDelenText">
                {{ patientFormData.Teksten.GegevensFirstQuestion }}
              </div>
            </div>
            <div class="row grid-margin form-group__radio">
              <div class="col-md-2">
                <input
                  id="Q1Y"
                  v-model="patientGegevens.HasAgreedToShareData"
                  name="Question1Selection"
                  type="radio"
                  :value="true"
                  @change="checkRadioAnswer"
                />
                <label for="Q1Y"><span></span>Ja</label>
              </div>
              <div class="col-md-2">
                <input
                  id="Q1N"
                  v-model="patientGegevens.HasAgreedToShareData"
                  name="Question1Selection"
                  type="radio"
                  :value="false"
                  @change="checkRadioAnswer"
                />
                <label for="Q1N"><span></span>Nee</label>
              </div>
            </div>
            <div v-if="!patientGegevens.HasAgreedToShareData" class="row grid-margin">
              <div class="col">
                <v-warning-message
                  :warning="patientFormData.Teksten.GegevensWarningMessageQ1"
                  :html="true"
                  :is-urgent="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="computedShowCareProgramme" class="row">
        <div class="col-md-12">
          <v-heading :level="1"> Zorgprogramma </v-heading>
        </div>
      </div>
      <div v-if="computedShowCareProgramme" id="step5" class="row grid-margin">
        <div class="col-md-12 patient-details__block">
          <v-header title="Wijzig hier de toestemming voor het delen van gegevens" />
          <div class="patient-details__block__content">
            <div class="row grid-margin">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    {{ patientFormData.Teksten.RegisterText }}. {{ patientFormData.Teksten.CheckToAgreeText }}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <v-table v-for="(careProgram, careProgramKey) in careProgramDetails" :key="careProgramKey">
                  <v-care-programme-row
                    :data="careProgram"
                    :index="careProgramKey"
                    details-page
                    @care-program-action="careProgramAction"
                  />
                </v-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="step6" class="row">
        <v-button lg hollow transparent @click.prevent="goBack"> Annuleren </v-button>
        <v-button id="patientDetailsSave" lg cta tagelement="button"> Opslaan </v-button>
      </div>
    </form>
    <generic-modal v-if="newPatientIsSaved" :has-close-button="true" @close="closeModal">
      <template #header>
        {{ patientDetailsSuccessPopUpDetails.Title }}
      </template>
      <template #body>
        <p>{{ patientDetailsSuccessPopUpDetails.Message }}</p>
      </template>
      <template #footer>
        <router-link
          :to="patientDetailsSuccessPopUpDetails.LinkButtonUrl"
          class="btn btn--hollow btn--transparent btn--md"
        >
          {{ patientDetailsSuccessPopUpDetails.LinkButtonText }}
        </router-link>
        <router-link
          v-if="patientDetailsSuccessPopUpDetails.PrimaryButtonUrl"
          :to="patientDetailsSuccessPopUpDetails.PrimaryButtonUrl"
          class="btn btn--primary btn--md"
        >
          {{ patientDetailsSuccessPopUpDetails.PrimaryButtonText }}
        </router-link>
        <v-button v-else md primary @click.prevent="closeAndScrollTop">
          {{ patientDetailsSuccessPopUpDetails.PrimaryButtonText }}
        </v-button>
      </template>
    </generic-modal>
    <generic-modal v-if="covSanityCheckNotMatchedModel" :has-close-button="true" @close="closeModal">
      <template #header>
        {{ covSanityCheckNotMatchedModel.Title }}
      </template>
      <template #body>
        <p>{{ covSanityCheckNotMatchedModel.Message }}</p>
      </template>
      <template #footer>
        <!-- Close dialog, save regardless of cov sanity check //-->
        <v-button
          v-if="covSanityCheckNotMatchedModel.CovSanityCheckStatus === 'NotMatched'"
          md
          hollow
          transparent
          @click.prevent="submitFormRegardlessOfCovSanityCheck"
        >
          {{ covSanityCheckNotMatchedModel.LinkButtonText }}
        </v-button>
        <!-- Close dialog, scroll to top //-->
        <v-button
          v-if="covSanityCheckNotMatchedModel.CovSanityCheckStatus === 'NotMatched'"
          md
          primary
          @click.prevent="closeCovNotMatchedAndScrollTop"
        >
          {{ covSanityCheckNotMatchedModel.PrimaryButtonText }}
        </v-button>
        <!-- scenario 3 -->
        <!-- Close dialog, save regardless of cov sanity check //-->
        <v-button
          v-if="
            covSanityCheckNotMatchedModel.CovSanityCheckStatus === 'NotMatchedPolicynumberOrHealthcareInsuranceCompany'
          "
          md
          hollow
          transparent
          @click.prevent="submitFormRegardlessOfCovSanityCheck"
        >
          {{ covSanityCheckNotMatchedModel.LinkButtonText }}
        </v-button>
        <!-- Close dialog, overwrite what we have, submit //-->
        <v-button
          v-if="
            covSanityCheckNotMatchedModel.CovSanityCheckStatus === 'NotMatchedPolicynumberOrHealthcareInsuranceCompany'
          "
          md
          primary
          @click.prevent="closeCovNotMatchedAndScrollTop"
        >
          {{ covSanityCheckNotMatchedModel.PrimaryButtonText }}
        </v-button>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import router from '@/router';
import plusCircleIcon from 'icons/PlusCircle';
import chevronRight from 'icons/ChevronRight';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import moment from 'moment';
import VTable from '@/components/shared/table/Table';
import VCareProgrammeRow from './CareProgrammeRow';
import VWarningMessage from '@/components/shared/WarningMessage';
import VDeleteCircleIcon from 'icons/DeleteCircle';
import GenericModal from '@/components/shared/GenericModal';
import { pushActiveCustomerId } from '@/services';
import VHeader from '@/components/shared/Header.vue';
import axios from 'axios';
import { useStore, mapActions } from 'vuex';
import { computed } from 'vue';
import { $validateAllComponents } from '@/validate/index.js';
import { useForm } from 'vee-validate';

export default {
  name: 'PatientDetails',
  components: {
    VHeader,
    plusCircleIcon,
    VCheckboxGroup,
    chevronRight,
    VTable,
    VCareProgrammeRow,
    VWarningMessage,
    VDeleteCircleIcon,
    GenericModal
  },
  setup() {
    const store = useStore();
    const state = store.state;

    const currentPatientId = computed(() => store.getters['patient/getCurrentPatientId']);
    const patientFormData = computed(() => store.getters['patient/getAllPatientFormData']);
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const newPatientIsSaved = computed(() => store.getters['patient/getNewPatientIsSaved']);
    const careProgramDetails = computed(() => store.getters['patient/getCareProgramDetails']);
    const formPurpose = computed(() => store.getters['patient/getFormPurpose']);
    const siteCode = computed(() => store.getters['navigation/getSiteCode']);
    const customerNumber = computed(() => store.getters['patient/getCustomerNumber']);

    const setNewPatientIsSaved = (value) => store.commit('patient/setNewPatientIsSaved', value);
    const setCareProgramDetails = (value) => store.commit('patient/setCareProgramDetails', value);
    const setPatientGegevens = (value) => store.commit('patient/setPatientGegevens', value);
    const setAllPatientFormData = (value) => store.commit('patient/setAllPatientFormData', value);
    const setPatientGegevensPhoneNumber = () => store.commit('patient/setPatientGegevensPhoneNumber');
    const setPatientFollowStatus = (value) => store.dispatch('patient/setPatientFollowStatus', value);
    const fetchAddressLookup = (value) => store.dispatch('patient/fetchAddressLookup', value);
    const setCustomerMarketingPermissionSubOption = (value) =>
      store.commit('patient/setCustomerMarketingPermissionSubOption', value);
    const setCustomerMarketingPermission = (value) => store.commit('patient/setCustomerMarketingPermission', value);
    const setCustomerNumber = (value) => store.commit('patient/setCustomerNumber', value);

    return {
      state,
      currentPatientId,
      patientFormData,
      patientGegevens,
      newPatientIsSaved,
      careProgramDetails,
      formPurpose,
      siteCode,
      customerNumber,
      setNewPatientIsSaved,
      setCareProgramDetails,
      setPatientGegevens,
      setAllPatientFormData,
      setPatientGegevensPhoneNumber,
      setPatientFollowStatus,
      fetchAddressLookup,
      setCustomerMarketingPermissionSubOption,
      setCustomerMarketingPermission,
      setCustomerNumber,
      ...mapActions('patient', ['fetchAllPatientFormData', 'submitPatient'])
    };
  },
  data() {
    const { validate } = useForm();
    return {
      validate,
      differentShippingAddress: false,
      NoBSNNummer: false,
      disabledFields: {
        BSNnummer: false,
        Polisnummer: false,
        VerzekeraarId: false
      },
      showDisabledBSN: true,
      patientDetailsSuccessPopUpDetails: {},
      covSanityCheckNotMatchedModel: null,
      otherTelephoneNumber: false,
      selectedCountry: '',
      postCodeRule: {
        required: true,
        regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
      },
      altPostCodeRule: {
        required: true,
        regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
      },
      disableAddress: false,
      disableAlternateAddress: false,
      noEmailAddress: false,
      formSubmitted: false,
      contactTypes: [
        { value: false, text: 'Telefoon' },
        { value: true, text: 'Mobiel' }
      ]
    };
  },
  computed: {
    birthDate() {
      return this.patientGegevens.GeboorteDatum !== '0001-01-01T00:00:00'
        ? moment(this.patientGegevens.GeboorteDatum).format('DD-MM-YYYY')
        : null;
    },
    computedShowCareProgramme() {
      let status = false;
      Object.keys(this.careProgramDetails).forEach((key) => {
        if (this.patientGegevens.HasAgreedToShareData && this.careProgramDetails[key] !== null) {
          status = true;
        }
      });
      return status;
    }
  },
  unmounted() {
    this.setNewPatientIsSaved(false);
    this.setCareProgramDetails({});
    this.setPatientGegevens({});
    this.setAllPatientFormData({});
    this.setCustomerMarketingPermission(null);
  },
  created() {
    this.setNewPatientIsSaved(false);
    this.fetchAllPatientFormData().then(() => {
      this.showDisabledBSN = parseInt(this.patientGegevens.BSNnummer) > 0;
      this.NoBSNNummer = !this.patientGegevens.HeeftBSNNummer;
      if (this.patientGegevens.GeslachtId === 0) {
        this.patientGegevens.GeslachtId = null;
      }
      if (this.patientGegevens.RedenGeenBSN) {
        this.patientGegevens.RedenGeenBSN = this.patientGegevens.RedenGeenBSN.toString();
      }
      this.differentShippingAddress = typeof this.patientGegevens.AfwijkendeAdres.Straatnaam === 'string';
      if (this.patientGegevens.WoonAdres.Straatnaam !== null) {
        this.disableAddress = true;
      }
      if (this.patientGegevens.AfwijkendeAdres.Straatnaam !== null) {
        this.disableAlternateAddress = true;
      }
      this.showOverledenDatum = this.patientGegevens.PatientIsOverleden;
      Object.keys(this.disabledFields).forEach((key) => {
        if (this.patientGegevens[key] !== null) {
          this.disabledFields[key] = true;
        }
      });
      if (!this.patientGegevens.WoonAdres.Land) {
        this.patientGegevens.WoonAdres.Land = 'NL';
      }
      if (!this.patientGegevens.AfwijkendeAdres.Land) {
        this.patientGegevens.AfwijkendeAdres.Land = 'NL';
      }
      if (this.patientGegevens.CustomerPhoneNumbers.length === 0) {
        this.setPatientGegevensPhoneNumber();
      }
      this.otherTelephoneNumber = this.patientGegevens.CustomerPhoneNumbers.length === 1 ? false : true;
    });
  },
  methods: {
    onContactTypeChange(value, index) {
      this.patientGegevens.CustomerPhoneNumbers[index].IsMobileNumber = value;
    },
    onTelephoneNumberSelect(selectedCountry, index) {
      this.patientGegevens.CustomerPhoneNumbers[index].PhoneNumberCountryCode = selectedCountry.iso2.toUpperCase();
      this.patientGegevens.CustomerPhoneNumbers[index].PhoneNumberDialCode = selectedCountry.dialCode;
    },
    getComputedPhoneNumber(number, index) {
      if (number[0] === '0') {
        let computedValue = number.substr(1);
        this.patientGegevens['CustomerPhoneNumbers'][index]['PhoneNumber'] = computedValue;
        return computedValue;
      }
      return number;
    },
    savePhoneNumberInput(event, key, index) {
      this.patientGegevens[key][index]['PhoneNumber'] = event.target.value;
    },
    closeModal() {
      this.setNewPatientIsSaved(false);
      this.newPatientIsSaved = false;
      this.covSanityCheckNotMatchedModel = false;
    },
    validateBeforeSubmit(e) {
      this.formSubmitted = true;
      e.preventDefault();
      this.validateAllFields(e.currentTarget.elements);
    },
    goBack() {
      if (this.formPurpose !== 'new') {
        router.push('/patientdashboard');
      } else {
        router.push('/');
      }
    },
    submitFormRegardlessOfCovSanityCheck() {
      this.closeCovNotMatched();
      var patientForm = this.$refs.patientForm;
      return this.submitForm(patientForm.elements, {
        covSanityCheckResolveMethod: 'UseProvidedDetails'
      });
    },
    submitForm(elements, options) {
      this.submitPatient({ elements, options })
        .then((response) => {
          if (response.data.Success === true && response.data.Id) {
            pushActiveCustomerId(response.data.Id);
          }

          if (response.data.Success === true && response.data.successModel) {
            this.setNewPatientIsSaved(true);
            this.patientDetailsSuccessPopUpDetails = response.data.successModel;
            if (this.currentPatientId === '0') {
              // Follow current (new) patient
              this.followPatient();
            }
          } else if (response.data.Success === true && response.data.covSanityCheckNotMatchedModel) {
            // covSanityCheckNotMatchedModel: contains details for the popup
            this.covSanityCheckNotMatchedModel = response.data.covSanityCheckNotMatchedModel;
          }
          return response;
        })
        .then();
    },
    followPatient() {
      this.setPatientFollowStatus({ Follow: true });
    },
    validateAllFields(elements) {
      $validateAllComponents(this).then((validated) => {
        if (validated) {
          // submit patient
          this.submitForm(elements);
        } else {
          this.formSubmitted = false;
        }
      });
    },
    onCountryChange(country, key) {
      this.selectedCountry = country;
      if (country === 'BE') {
        // WoonAdresPostcode
        this[key] = {
          required: true,
          max: 4,
          numeric: true
        };
        return;
      }
      if (country === 'DE') {
        // WoonAdresPostcode
        this[key] = {
          required: true,
          max: 5,
          numeric: true
        };
      }
    },
    moment(...args) {
      return moment(...args);
    },
    autoFillAddress(key) {
      this.fetchAddressLookup({
        postcode: this.patientGegevens[key].Postcode,
        huisnummer: this.patientGegevens[key].Huisnummer,
        land: this.patientGegevens[key].Land
      })
        .then((response) => {
          if (response && response.data) {
            if (response.data.Street) {
              this.patientGegevens[key].Straatnaam = response.data.Street;
            }
            if (response.data.City) {
              this.patientGegevens[key].Woonplaats = response.data.City;
            }
          }
          if (response.data.Street) {
            if (key === 'WoonAdres') {
              this.disableAddress = true;
            } else {
              this.disableAlternateAddress = true;
            }
          }
        })
        .catch(() => {
          if (key === 'WoonAdres') {
            this.disableAddress = false;
          } else {
            this.disableAlternateAddress = false;
          }
        });
    },
    careProgramAction(actionDetails) {
      this.setCustomerMarketingPermissionSubOption(actionDetails);
    },
    checkRadioAnswer(e) {
      this.patientGegevens[e.currentTarget.name] = e.currentTarget.value;
      let radioButtonId = e.currentTarget.id;
      if (radioButtonId === 'Q1N') {
        this.setCustomerMarketingPermission(null);
      } else {
        this.setCustomerMarketingPermission('default');
      }
    },
    closeCovNotMatched() {
      this.covSanityCheckNotMatchedModel = null;
    },
    closeCovNotMatchedAndScrollTop() {
      this.patientGegevens.VerzekeraarId = this.covSanityCheckNotMatchedModel.CovHealthcareInsuranceCompany
        ? this.covSanityCheckNotMatchedModel.CovHealthcareInsuranceCompany.PayerId
        : null;
      this.patientGegevens.Polisnummer = this.covSanityCheckNotMatchedModel.CovPolicyNumber;
      this.covSanityCheckNotMatchedModel = null;
      window.scrollTo(0, 0);
    },
    openGegevensLink(url) {
      window.open(url, '_blank');
    },
    showOtherTelephoneNumber(displayFlag, phoneNumberId) {
      this.otherTelephoneNumber = displayFlag;
      if (!displayFlag && this.patientGegevens.CustomerPhoneNumbers.length > 1) {
        if (phoneNumberId !== 0) {
          axios.post('Customer/DeleteCustomerPhoneNumber', { PhoneNumberId: phoneNumberId }).then((response) => {
            if (response && response.data) {
              this.patientGegevens.CustomerPhoneNumbers.splice(-1);
            }
          });
        } else {
          this.patientGegevens.CustomerPhoneNumbers.splice(-1);
        }
      } else if (displayFlag && this.patientGegevens.CustomerPhoneNumbers.length === 1) {
        this.setPatientGegevensPhoneNumber();
      }
    },
    saveInput(event) {
      this.patientGegevens[event.target.id] = event.target.value;
    },
    saveCustomerNumber(event) {
      this.setCustomerNumber(event.target.value);
    },
    saveAddressInput(event, key, childKey) {
      this.patientGegevens[key][childKey] = event.target.value;
    },
    /**
     * @function select - Funtion that handles the select change
     */
    select(e, key) {
      this.patientGegevens[key] =
        key != 'Tussenvoegsel' && key != 'VerzekeraarId' ? parseInt(e.currentTarget.value, 10) : e.currentTarget.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.patient-details {
  color: $gray-dark;
  .vue-country-intl-inputer {
    :deep(.country-intl-input) {
      border-color: $gray !important;
      height: 49px !important;
    }
    :deep(.country-intl-label) {
      top: 7px !important;
    }
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    &__content {
      padding: 24px;
    }
  }
  table {
    margin-bottom: 24px !important;
    border-bottom: solid 2px #0000001a;
  }
  .form-group--disabled {
    :deep(label) {
      padding: 0px !important;
    }
    :deep(select),
    :deep(select:disabled) {
      background-color: #0000000d !important;
      border-color: $gray;
      min-height: 50px;
    }
  }
  .form-group,
  .form-group-simple,
  .form-group-simple--completed {
    :deep(input) {
      color: $teal-darkest !important;
      height: 49px;
    }
    :deep(label) {
      color: $teal-darkest;
      margin-bottom: 14px;
      font-weight: normal;
    }
    :deep(input:disabled) {
      background-color: #0000000d !important;
    }
  }
  :deep(.form-group) {
    padding: 0px;
    flex: none;
    label {
      position: initial;
      transform: none !important;
      line-height: normal;
      height: inherit;
      margin-bottom: 14px;
      font-size: $base-font-size;
      padding: 0px;
    }
    select {
      border-color: $gray;
    }
    .form-group__details {
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
    }
    &--disabled {
      background: none !important;
      background-color: #0000000d;
    }
  }
  .form-group-simple__error {
    .alert-circle-icon {
      display: inline-flex;
      width: 16px;
      height: 16px;
      fill: $pure-red;
      vertical-align: bottom;
    }
  }
}
:deep(.step3_icon) {
  .material-design-icon__svg {
    width: 35px !important;
    height: 35px !important;
  }
}
:deep(.checkbox-group disabled .checkbox-group__label) {
  color: $gray-light;
}
</style>
