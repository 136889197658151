<template>
  <div class="container bundlesPage">
    <div class="row grid-margin">
      <div>
        <legend class="bundlesPage__Title">
          {{ bundles.title }}
        </legend>
        <legend class="bundlesPage__details">
          {{ bundles.subTitle }}
        </legend>
        <legend
          v-if="bundles.illnessBundle && bundles.illnessBundle.length > 0"
          class="bundlesPage__details"
        >
          {{ bundles.bundleText }}
        </legend>
        <legend
          v-else
          class="bundlesPage__details"
        >
          {{ bundles.noBundleText }}
        </legend>
      </div>
    </div>
    <div
      v-if="bundles.illnessBundle && bundles.illnessBundle.length > 0"
      class="row grid-margin"
    >
      <v-bundles :bundles="bundles.illnessBundle" />
      <div>
        <legend class="bundlesPage__details">
          {{ bundles.navigateInfoText }}
        </legend>
      </div>
    </div>
    <div class="row grid-margin">
      <v-button
        tagelement="a"
        hollow
        md
        @click.prevent="$router.push('/patientdashboard')"
      >
        Ga naar dashboard van patiënt
      </v-button>
      <v-button
        v-if="showCatalog"
        tagelement="a"
        primary
        md
        @click.prevent="$router.push('/catalog')"
      >
        Naar catalogus
      </v-button>
    </div>
  </div>
</template>

<script>
import VBundles from '@/components/sections/catalog/Bundles'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
  name: 'Bundles',
  components: {
		VBundles
  },
  setup() {
    const store = useStore();
    const siteCode = store.state.navigation.siteCode;

    return {
      siteCode
    }
  },
  data () {
    return {
      bundles: {}
    }
  },
  computed: {
    showCatalog () {
      return this.siteCode !== 'MIF'
    }
  },
  created () {
    axios.get('Products/GetIllnessBundle?show=false').then((response) => {
      if (response && response.data) {
        this.bundles = response.data
      }
    })
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bundlesPage {
  a {
	  margin: 0px 20px 0px 0px;
	}
	&__Title {
    display: flex;
    font-size: 30px;
    color: $teal-darkest;
    font-weight: 300;
  }
	&__details{
		font-size: 16px;
		color: $gray-darkest;
		margin: 20px 0px 20px 0px; 
	}
}
</style>
