import * as yup from 'yup';

export function generateYupSchema(props) {
  let rules = props.rules;
  let name = props.label ? props.label : props.placeholder;
  if (props.useIdForErrorMessage) {
    name = props.id;
  }
  if (props.fieldNameForErrorMessage && props.fieldNameForErrorMessage.length) {
    name = props.fieldNameForErrorMessage;
  }
  let yupSchema = yup.string();
  if (!name) {
    name = 'Dit veld';
  }

  if (rules.required) {
    let errorMessage = name + ' is verplicht.';
    if (props.label === 'Geslacht') {
      errorMessage = 'Het is verplicht om het veld geslacht in te vullen.';
    }
    
    yupSchema = yupSchema.required(errorMessage);
  } else {
    yupSchema = yupSchema.nullable(); // Allow null values if not required
  }

  if (rules.email && rules.regex) {
    yupSchema = yupSchema.email(name + ' moet een geldig emailadres zijn.');
  }

  if (rules.regex) {
    yupSchema = yupSchema.trim().matches(rules.regex, name + ' waarde is ongeldig.');
  }

  if (rules.numeric) {
    yupSchema = yupSchema.test('is-numeric', name + ' mag alleen nummers bevatten.', function (value) {
      if (!value || value === '') return true;
      return yup.number().isValidSync(value);
    });
  }

  if (rules.numeric && rules.required) {
    yupSchema = yup
      .number()
      .typeError(name + ' mag alleen nummers bevatten.')
      .required(name + ' is verplicht.');
  }

  if (rules.max && !rules.numeric) {
    yupSchema = yupSchema.max(
      rules.max,
      name + ' mag niet groter zijn dan ' + props.rules.max.toString().length + ' karakters.'
    );
  }

  if (rules.min && !rules.numeric) {
    yupSchema = yupSchema.min(
      rules.min,
      'Het ' + name + ' mag minimaal ' + props.rules.min.toString().length + ' tekens bevatten.'
    );
  }

  if (rules.max && rules.numeric) {
    yupSchema = yupSchema.test(
      'is-max',
      name + ' mag niet groter zijn dan ' + rules.max.toString().length + ' karakters.',
      function (value) {
        if (!value || value === '') return true;
        return value <= rules.max;
      }
    );
  }

  if (rules.min && rules.numeric) {
    yupSchema = yupSchema.test('is-min', name + ' waarde is ongeldig.', function (value) {
      if (!value || value === '') return true;
      return value >= rules.min;
    });
  }

  if (rules.not_in) {
    yupSchema = yupSchema.notOneOf(rules.not_in, name + ' is ongeldig.');
  }

  if (rules.in) {
    yupSchema = yupSchema.oneOf(rules.in, name + ' moet een geldige waarde zijn.');
  }

  return yupSchema;
}
