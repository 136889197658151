<template>
  <section class="container mydepartment">
    <div class="row">
      <div class="col-xs-6">
        <v-heading :level="1"> Mijn afdeling </v-heading>
      </div>
      <div class="col-xs-6">
        <v-button
          class="btn btn--inverted btn--hollow btn--alt btn--md mydepartment__button"
          sm
          primary
          icon-left
          @click="$router.push('/collega-registreren')"
        >
          <v-plus-circle-icon />
          Collega toevoegen
        </v-button>
      </div>
    </div>
    <div v-if="myDepartmentTexts" class="row">
      <div class="mydepartment__block col-xs-12">
        <v-header title="Gegevens" />
        <legend>
          {{ myDepartmentTexts.institutionLabel }} <span>{{ myDepartmentTexts.institutionName }}</span>
        </legend>
        <legend>
          {{ myDepartmentTexts.departmentLabel }}<span>&nbsp;&nbsp;{{ myDepartmentTexts.departmentName }}</span>
        </legend>
        <table class="mydepartment__table">
          <thead>
            <tr>
              <th scope="col">Naam</th>
              <th scope="col">Mediq referentie</th>
              <th scope="col">E-mail</th>
              <th scope="col" class="alignCenter">Voorschrijver</th>
              <th scope="col">Onder volmacht van</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(colleague, index) in departmentColleagues" :key="index">
              <td>{{ colleague.name }}</td>
              <td>{{ colleague.externalId }}</td>
              <td>{{ colleague.email }}</td>
              <td class="alignCenter">
                <span v-show="colleague.isPrescriber"><v-check-circle class="mydepartment__iconCheck" /></span>
                <span v-show="!colleague.isPrescriber"><v-close-circle class="mydepartment__iconClose" /></span>
              </td>
              <td v-if="colleague.warranters">
                {{ colleague.warranters.join(', ') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <form @submit.prevent="submitComments">
      <div class="row">
        <div v-if="myDepartmentTexts" class="mydepartment__block col-xs-12">
          <v-header :title="myDepartmentTexts.feedbackHeader" />
          <legend>
            {{ myDepartmentTexts.feedbackInstruction }}
          </legend>
          <div class="mydepartment__block__feedback">
            <v-textarea-group v-model="comments" label="Uw bericht:" inputid="comments" @input="saveComments" />
            <div v-show="showErrorMessage" class="form-group-simple__details errorMessage">
              <div class="form-group-simple__messages">
                <div class="form-group-simple__error">* Dit veld is verplicht.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-justify-content-right">
        <v-button primary md tagelement="button" class="submitComments"> VERSTUREN </v-button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { departmentColleagues$, myDepartmentTexts$ } from '@/services';
import VCheckCircle from 'icons/CheckCircle';
import VCloseCircle from 'icons/CloseCircle';
import router from '@/router';
import VHeader from '@/components/shared/Header.vue';
import VPlusCircleIcon from 'icons/PlusCircle';
import { ref } from 'vue';

export default {
  name: 'Mydepartment',
  components: {
    VCheckCircle,
    VCloseCircle,
    VHeader,
    VPlusCircleIcon
  },
  setup() {
    const departmentColleagues = ref(null);
    const myDepartmentTexts = ref(null);

    departmentColleagues$.subscribe((value) => (departmentColleagues.value = value));
    myDepartmentTexts$.subscribe((value) => (myDepartmentTexts.value = value));

    return {
      ...mapActions({ submitedFeedback: 'patient/submitFeedback' }),
      departmentColleagues,
      myDepartmentTexts
    };
  },
  data() {
    return {
      comments: '',
      showErrorMessage: false
    };
  },
  created() {},
  methods: {
    saveComments(event) {
      this.comments = event.target ? event.target.value : '';
      this.showErrorMessage = this.comments !== '' ? false : true;
    },
    submitComments() {
      if (this.comments !== '') {
        this.submitedFeedback({ Comments: this.comments }).then((response) => {
          if (response === true) {
            this.$emit('success');
            this.$emitter.emit('alert', { message: 'Bedankt, uw suggestie is met succes verstuurd!' });
          } else {
            this.$emitter.emit('alert', {
              message: 'Oops het versturen ging niet helemaal goed. Laten we dit als eerste maar eens verbeteren.'
            });
          }
          setTimeout(() => {
            router.push('/');
          }, 2000);
        });
      } else {
        this.showErrorMessage = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mydepartment {
  .alignCenter {
    text-align: center;
    .material-design-icon {
      display: inline-block;
    }
  }
  &__heading {
    font-size: 16px;
  }
  &__button {
    @include min-screen(grid-breakpoint(md)) {
      align-self: flex-end;
    }
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 20px 0px 20px 0px;
    padding: 0px 0px 30px 0px;
    a {
      font-size: 16px;
      margin-top: 10px;
      color: $teal-darkest !important;
    }
    legend {
      margin: 15px 15px 15px 40px;
      font-size: 16px;
      span {
        margin-left: 50px;
      }
    }
    &__feedback {
      margin: 15px 40px;
    }
  }
  &__table {
    border-collapse: collapse;
    background: $white;
    table-layout: auto !important;
    margin: 20px 35px 20px 35px;
    th,
    td {
      vertical-align: middle;
      text-align: left;
      padding: 12px 20px !important;
    }
    thead {
      padding-bottom: 2px;
      background-color: $teal-lighter;
      td,
      th {
        background-color: $teal-lighter;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    tbody {
      th,
      td {
        vertical-align: middle !important;
        padding-top: 17px;
        padding-bottom: 32px !important;
      }
    }
  }
  &__iconCheck {
    fill: $teal-darkest;
  }
  &__iconClose {
    fill: $teal-lighter;
  }
  .submitComments {
    z-index: 9999;
  }
}
</style>
