<template>
  <transition name="prompt">
    <div v-if="promptActive" class="prompt">
      <div class="modal">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3" align="center">
              <stopwatch-icon class="icon-2x" />
              <div class="stopwatch">
                {{ stopWatch }}
              </div>
            </div>
            <div class="col-md-9">
              <div v-if="countDown > 0" class="message">
                U bent al 3 uur en 30 minuten inactief.<br />U wordt automatisch uitgelogd na 30 minuten.
              </div>
              <div v-else class="message">Uw sessie is beëindigd.<br />Klik hieronder om opnieuw in te loggen.</div>
              <v-button cta lg nowrap @click.prevent="resetLogin()">
                {{ text }}
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import StopwatchIcon from 'icons/Timer';

export default {
  name: 'VModal',
  components: {
    StopwatchIcon
  },
  data() {
    return {
      props: {},
      promptActive: false,
      countDown: 1800000,
      stopWatch: '30:00',
      text: 'ANNULEREN'
    };
  },
  created() {
    this.countDownTimer();
    this.$emitter.on('modal', (props) => {
      this.props = props
      if (!props.type) props.type = 0
      this.show()
    })
  },
  methods: {
    resetLogin() {
      if (this.promptActive && this.countDown <= 0) {
        window.location.reload();
      }
      this.promptActive = false;
      this.countDown = 1800000;
    },
    show() {
      this.promptActive = true;
    },
    countDownTimer() {
      var $this = this;
      setTimeout(() => {
        if ($this.countDown > 0) {
          let cd = ($this.countDown -= 5);
          $this.stopWatch = $this.msConversion(cd);
          $this.countDownTimer();
          this.text = 'ANNULEREN';
        } else {
          this.text = 'INLOGGEN';
        }
      }, 1);
    },
    msConversion(ms) {
      var seconds = ms / 1000;
      seconds = seconds % 3600;
      var minutes = parseInt(seconds / 60);
      seconds = seconds % 60;
      var digit = parseInt(seconds).toString();
      return minutes + ':' + (digit.length > 1 ? digit : '0' + digit);
    }
  }
};
</script>
<style>
.modal {
  width: 535px;
  background: #148c78 !important;
  max-width: 535px !important;
  color: #fff !important;
  padding: 30px 58px 24px 0px;
  -webkit-box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.message {
  padding-bottom: 24px;
}

.material-design-icon.icon-2x {
  height: 44px;
  width: 38px;
}

.material-design-icon.icon-2x > .material-design-icon__svg {
  height: 44px;
  width: 38px;
}

.stopwatch {
  padding: 15px;
}
</style>
