<template>
  <transition name="generic-modal-fade">
    <div class="generic-modal-backdrop">
      <div class="generic-modal">
        <header class="generic-modal-header">
          <h4 class="modal-header-title">
            <slot name="header" />
          </h4>
          <a v-if="hasCloseButton" @click="close"><v-close-icon /></a>
        </header>
        <div v-if="hasSubHeader" class="generic-modal-subheader">
          <h4 class="modal-subheader-title">
            <slot name="subheader" />
          </h4>
        </div>
        <main class="generic-modal-body">
          <div class="modal-content">
            <slot name="body" />
          </div>
        </main>
        <footer v-if="hasFooter" class="generic-modal-footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import VCloseIcon from 'icons/Close';

export default {
  name: 'Modal',
  components: { VCloseIcon },
  props: {
    hasCloseButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSubHeader() {
      return !!this.$slots.subheader;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.p,
h1,
h2,
h3,
h4,
span {
  color: $gray-darkest;
  margin: none !important;
  padding: none !important;
}

.modal-header-title {
  margin: 0;
}

.modal-content {
  font-size: 16px;
  margin: none !important;
  padding: none !important;
}

.generic-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.generic-modal {
  background: $white;
  width: 500px;
  max-height: 500px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.btn {
  margin: 10px !important;
}
.generic-modal-header,
.generic-modal-footer {
  color: $gray-darkest;
  display: flex;
}

.generic-modal-header {
  display: flex;
  font-size: 26px;
  padding: 25px 25px 0 25px;
  font-weight: bold;
  justify-content: space-between;
}

.generic-modal-footer {
  justify-content: flex-end;
  color: $gray-darkest;
  padding: 0px 25px 25px 25px;
}

.generic-modal-footer > button {
  border: 1px solid blue;
  flex: 1 1 auto;
}

.generic-modal-body {
  position: relative;
  padding: 0 25px 0 25px;
  color: $gray-darkest;
}

.generic-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.generic-modal-fade-enter,
.generic-modal-fade-leave-active {
  opacity: 0;
}

.generic-modal-fade-enter-active,
.generic-modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.material-design-icon {
  border: 1px transparent solid;
  fill: $black;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}
</style>
