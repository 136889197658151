<template>
  <div class="prescriberRegistrationStep5">
    <!-- step 5 -->
    <div class="row grid-align-center">
      <div class="col-md-12 prescriberRegistrationStep5__stepTitle">
        {{ prescriberRegistrationTexts.Step5.PageTitle }}
      </div>
      <div class="col-md-6 prescriberRegistrationStep5__block">
        <div class="prescriberRegistrationStep5__block__header">Inloggegevens</div>
        <div class="prescriberRegistrationStep5__block__content">
          <div class="row">
            <v-account-icon class="prescriberRegistrationStep5__block__content__accountIcon" />
            <div class="col-xs-11">
              <v-input-group-simple
                :reference="formConfig.userName.alias"
                v-model="formConfig.userName.model"
                :initvalue="formConfig.userName.model"
                :rule="formConfig.userName.rule"
                :inputid="formConfig.userName.ref"
                placeholder="Gewenste gebruikersnaam"
                @blur="savePrescriberRegistrationDetails"
              />
            </div>
            <div v-if="showUserNameExistAlert" class="form-group-simple__details alertMessage">
              <div class="form-group-simple__messages">
                <div class="form-group-simple__error">
                  Deze gebruikersnaam is al in gebruik. Gelieve een andere gebruikersnaam ingeven.
                </div>
              </div>
            </div>
            <div class="prescriberRegistrationStep5__block__content__userName">
              Uw wachtwoord ontvangt u van ons per mail.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-align-center">
      <div class="col-md-6 prescriberRegistrationStep5__block">
        <div class="prescriberRegistrationStep5__block__header">Uw handtekening</div>
        <div class="prescriberRegistrationStep5__block__content">
          <legend>
            Gebruik uw muis om hieronder uw handtekening te zetten of upload de digitale versie van uw handtekening
            vanaf uw computer.
          </legend>
          <div class="row prescriberRegistrationStep5__block__content__signatureActionsHolder">
            <v-button
              md
              class="prescriberRegistrationStep5__block__content__signatureActionsBtn prescriberRegistrationStep5__block__content__signaturePadBtn"
              :class="[signatureButton ? 'btn--primary activeBtn' : 'btn--hollow']"
              @click.prevent="toggle()"
            >
              Handmatig
            </v-button>
            <v-button
              md
              class="prescriberRegistrationStep5__block__content__signatureActionsBtn"
              :class="[signatureButton ? 'btn--hollow' : 'btn--primary activeBtn']"
              @click.prevent="toggle()"
            >
              Upload
            </v-button>
          </div>
          <div
            v-if="signatureDetails.length > 0"
            class="row grid-padding grid-justify-content-center prescriberRegistrationStep5__block__content__signatureDetails"
          >
            <div>
              <img src="@/static/img/img-icon.svg" />
              <a>{{ getFileName(signatureDetails[0]) }}</a>
              <v-delete
                class="prescriberRegistrationStep5__block__content__signatureDetails__delete"
                @click.prevent="deleteFile()"
              />
            </div>
          </div>
          <div v-else>
            <div
              v-if="signatureButton"
              class="row grid-align-center prescriberRegistrationStep5__block__content__signaturePad"
            >
              <v-input-digital-signature @save-signature="saveSignature" @undo-signature="undoSignature" />
            </div>
            <div v-else class="row grid-align-center prescriberRegistrationStep5__block__content__uploadFile">
              <v-input-file-upload
                :save-image-preview="imagePreview"
                :allowed-extenstions="allowedSignatureExtenstions"
                :extension-error-message="customExtenstionMessage"
                drop-area-secondary-message="(max. 2MB; alleen .pdf, .jpg, .jpeg, .tiff, .bmp of .png)"
                upload-button-message="Handtekening uploaden"
                :max-items="1"
                :registration="true"
                @upload-success="saveUploadedFiles"
                @save-file-name="saveUploadedFileName"
              />
            </div>
            <div class="form-group-simple__details">
              <div class="form-group-simple__messages">
                <div v-show="!isSignatureSelected" class="form-group-simple__error">
                  Voor registratie is een handtekening verplicht. Gelieve een handtekening tekenen of uploaden.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Google Recaptcha section -->
    <div class="row grid-align-center">
      <vue-recaptcha
        :sitekey="$googleRecaptchaSiteKey"
        class="prescriberRegistrationStep5__googleRecapthca"
        @verify="recaptchaTokenVerify"
      />
      <div v-show="showRecaptchaRequiredMessage" class="form-group-simple__details col-xs-12 grid-align-center">
        <div class="form-group-simple__messages">
          <div class="form-group-simple__error">* Dit veld is verplicht.</div>
        </div>
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep5__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep5__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button v-if="getStepActionVisibilityStatus('next')" lg cta @click.prevent="nextStep()">
        Controleer gegevens
      </v-button>
    </div>
  </div>
</template>
<script>
import { fetchQuery } from '@/services/messaging/fetchQuery';
import axios from 'axios';
import { useStore } from 'vuex';
import { computed } from 'vue';
import VAccountIcon from 'icons/AccountCircle';
import VInputDigitalSignature from '@/components/shared/form/simple/InputDigitalSignature';
import VInputFileUpload from '@/components/shared/form/simple/InputFileUpload';
import VDelete from 'icons/Delete';

export default {
  name: 'RegistrationStep5',
  components: {
    VAccountIcon,
    VInputDigitalSignature,
    VInputFileUpload,
    VDelete
  },
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const signatureDetails = computed(() => store.getters['prescriberRegistration/getSignatureDetails']);
    const uploadedFileName = computed(() => store.getters['prescriberRegistration/getUploadedFileName']);
    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );

    const setPreviousStepActive = () => store.commit('prescriberRegistration/setPreviousStepActive');
    const setNextStepActive = () => store.commit('prescriberRegistration/setNextStepActive');
    const deleteSignature = (value) => store.commit('prescriberRegistration/deleteSignature', value);
    const setUploadedFileName = (value) => store.commit('prescriberRegistration/setUploadedFileName', value);
    const saveSignatureStore = (value) => store.commit('prescriberRegistration/saveSignature', value);
    const saveUploadedFile = () => store.dispatch('prescriberRegistration/saveUploadedFile');
    const setPrescriberRegistrationDetails = (value) =>
      store.commit('prescriberRegistration/setPrescriberRegistrationDetails', value);

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      signatureDetails,
      uploadedFileName,
      prescriberRegistrationTexts,
      setPreviousStepActive,
      setNextStepActive,
      deleteSignature,
      setUploadedFileName,
      saveSignatureStore,
      saveUploadedFile,
      setPrescriberRegistrationDetails
    };
  },
  data() {
    return {
      formData: {
        signature: []
      },
      formConfig: {},
      signatureButton: true,
      isSignatureSelected: true,
      allowedSignatureExtenstions: ['pdf', 'jpg', 'png', 'jpeg', 'tiff', 'bmp'],
      customExtenstionMessage:
        'Het geüploade document heeft niet de juiste extensie. U kunt alleen .pdf, .jpg, .png, .jpeg, .tiff of .bmp documenten uploaden.',
      imagePreview: true,
      showUserNameExistAlert: false,
      showRecaptchaRequiredMessage: false,
      recaptchaStatus: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.formConfig = {
      userName: {
        model: this.prescriberRegistrationDetails.userName,
        ref: 'userName',
        label: 'Gewenste gebruikersnaam',
        attached: true,
        alias: 'Gewenste gebruikersnaam',
        rule: {
          required: false
        },
        error: false
      }
    };
  },
  methods: {
    getStepActionVisibilityStatus(action) {
      if (action === 'prev') {
        return this.prescriberRegistrationSteps.findIndex((step) => step.show) !== 0;
      }
      return this.prescriberRegistrationSteps.findIndex((step) => step.show) < 5;
    },
    previousStep() {
      this.setPreviousStepActive();
    },
    nextStep() {
      if (this.formData.signature.length === 0 && this.signatureDetails.length === 0) {
        this.isSignatureSelected = false;
        return;
      }
      if (!this.recaptchaStatus) {
        this.showRecaptchaRequiredMessage = true;
        return;
      }
      if (this.showUserNameExistAlert) {
        return;
      }
      if (this.isSignatureSelected) {
        this.setNextStepActive();
      }
    },
    toggle() {
      this.signatureButton = !this.signatureButton;
    },
    deleteFile() {
      this.formData['signature'] = [];
      this.deleteSignature([]);
      this.setUploadedFileName(null);
    },
    saveSignature(empty, data) {
      let details = {
        upload: false,
        formData: data,
        name: 'Handtekening_2020.png'
      };
      this.formData['signature'] = [];
      this.formData['signature'].push(details);
      this.saveSignatureStore(details);
      this.isSignatureSelected = true;
    },
    undoSignature() {},
    getFileName(dataFile) {
      if (dataFile.upload) {
        return this.uploadedFileName != null ? this.uploadedFileName : '';
      }
      return dataFile.name;
    },
    saveUploadedFiles(formData) {
      let details = {
        upload: true,
        formData: formData
      };
      this.formData['signature'] = [];
      this.formData['signature'].push(details);
      this.saveSignatureStore(details);
      this.isSignatureSelected = true;
      this.saveUploadedFile();
    },
    saveUploadedFileName(fileName) {
      this.setUploadedFileName(fileName);
    },
    savePrescriberRegistrationDetails(ref, model) {
      this.showUserNameExistAlert = false;
      this.setPrescriberRegistrationDetails({
        key: ref,
        value: model
      });
      axios.post('../qry/IsUsernameAlreadyExists', { Username: model }).then((response) => {
        if (response) {
          this.showUserNameExistAlert = response.data;
        }
      });
    },
    recaptchaTokenVerify(token) {
      this.showRecaptchaRequiredMessage = false;
      fetchQuery('VerifyRecaptcha', { recaptchaResponseKey: token }).then((response) => {
        if (response.success) {
          this.recaptchaStatus = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriberRegistrationStep5 {
  &__googleRecapthca {
    margin: 0 auto;
    padding: 25px ​25px 5px 25p;
  }
  .alertMessage {
    margin-left: 35px;
  }
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__content {
      padding: 30px;
      &__link {
        margin: 10px 0px 20px 0px;
      }
      a {
        font-size: 16px;
        color: $teal-darkest;
      }
      &__accountIcon {
        padding: 10px 10px 0px 0px;
      }
      &__userName {
        font-size: 14px;
        color: $grayish-very-dark;
        margin: 10px auto 0px auto;
      }
      legend {
        font-size: 16px;
        color: $grayish-very-dark;
        text-align: left;
      }
      &__signatureActionsHolder {
        float: right;
        margin: 15px 0px 10px 0px;
      }
      &__signatureActionsBtn {
        display: inline;
      }
      &__signaturePadBtn {
        margin-right: -10px;
      }
      &__signaturePad {
        margin: 70px 0px 0px 0px;
      }
      &__uploadFile {
        margin: 60px 0px 0px 0px;
      }
      &__signatureDetails {
        margin: 75px auto 0px auto;
        img {
          position: absolute;
          margin: -5px 0px 0px -30px;
        }
        a {
          text-decoration: none;
          cursor: default;
        }
        &__delete {
          float: right;
          margin: -3px 0px 0px 10px;
          cursor: pointer;
        }
      }
    }
    &__icon {
      position: relative;
      margin: -40px 10px 10px 10px;
      float: right;
    }
  }
  &__actions {
    float: right;
    margin: 3% 25% 5% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  .activeBtn {
    pointer-events: none;
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  :deep(.uploadBox) {
    width: 200% !important;
    padding: 25px 25px 10px 10px !important;
  }
  :deep(.form-group) {
    margin-left: 18px;
  }
}
:deep(.digital-signature) {
  width: 100% !important;
  &__signature-canvas {
    min-height: 250px !important;
    > canvas {
      min-height: 250px !important;
    }
  }
  &__actions {
    margin-top: 30px !important;
  }
}
</style>
