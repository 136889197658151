<template>
  <form
    method="get"
    class="password-change-form row grid-padding grid-justify-content-center"
    @submit.prevent="submitForm"
  >
    <v-heading class="col-xs-12" :level="1"> Wijzig hier uw wachtwoord </v-heading>
    <p class="col-xs-12">
      Uw nieuwe wachtwoord dient minimaal 8 karakters, 1 kleine letter, 1 hoofdletter, een speciaal karakter en 1 cijfer
      te bevatten.
    </p>
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 col-md-7">
          <div class="row grid-margin">
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Huidig wachtwoord"
                v-model:value="resetPasswordDetails.currentPassword"
                :rules="{
                  required: true
                }"
                class="input-password"
                data-name="currentPassword"
                placeholder="Huidig wachtwoord"
                type="password"
                label="Huidig wachtwoord"
                show-hide-password-icon
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Nieuw wachtwoord"
                v-model:value="resetPasswordDetails.newPassword"
                :rules="{
                  regex: regexPattern,
                  required: true,
                  not_in: [resetPasswordDetails.currentPassword]
                }"
                class="input-password"
                data-name="newPassword"
                placeholder="Nieuw wachtwoord"
                type="password"
                label="Nieuw wachtwoord"
                show-hide-password-icon
                :show-caps-lock-status="true"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Herhaal nieuw wachtwoord"
                v-model:value="resetPasswordDetails.confirmPassword"
                :rules="{
                  required: true,
                  in: [resetPasswordDetails.newPassword]
                }"
                class="input-password"
                data-name="confirmPassword"
                placeholder="Herhaal nieuw wachtwoord"
                type="password"
                label="Herhaal nieuw wachtwoord"
                show-hide-password-icon
                :show-caps-lock-status="true"
                @input="saveInput($event)"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5">
          <v-password-validation :value="resetPasswordDetails.newPassword" />
        </div>
      </div>
    </div>
    <div class="col-xs-8 col-md-5 row grid-margin">
      <div class="col-xs-12">
        <v-button type="submit" :disabled="isSubmitDisabled" tagelement="button" cta md> Wachtwoord wijzigen </v-button>
      </div>
    </div>
  </form>
</template>

<script>
import VPasswordValidation from '@/components/shared/PasswordValidation';
import { formLogout } from '@/services';
import { computed } from 'vue';
import { useStore, mapActions } from 'vuex';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'VSetPassword',
  components: {
    VPasswordValidation
  },
  setup() {
    const store = useStore();
    const resetPasswordDetails = computed(() => store.getters['login/getResetPasswordDetails']);
    const setResetPasswordDetails = (value) => store.commit('login/setResetPasswordDetails', value);

    return {
      resetPasswordDetails,
      setResetPasswordDetails,
      ...mapActions({
        submitCreateFirstPasswordForm: 'login/submitCreateFirstPasswordForm'
      })
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      showErrorMessage: false,
      isSubmitDisabled: true,
      regexPattern: /^(?=.*\d)(?=.*[!@#\$%\^\&*\)\(+=._-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/ //length > 7, at least one upper case, lower case, special symbol, number
    };
  },
  created() {
    this.$parent.$parent.pageTitle = 'Wachtwoord vergeten';
    this.$parent.$parent.navigationBackText = 'Terug naar Mijn dashboard';
  },
  methods: {
    submitForm() {
      $validateAllComponents(this).then((validated) => {
        if (validated) {
          this.submitCreateFirstPasswordForm()
            .then(() => {
              // Submission successful
              this.$emitter.emit('alert', {
                message: 'Uw nieuwe wachtwoord is succesvol ingesteld. U kunt hiermee nu inloggen.',
                type: 0,
                delay: 5000
              });
              formLogout().then((r) => {
                window.location.replace('/?' + Math.random());
              });
            })
            .catch((result) => {
              // Submission failed or denied:
              // 1. Original password invalid
              // 2. New password invalid (i.e. no matching criteria)
              // 3. Other, server-related reasons
              // TODO? We should distinguish between all these and inform and lead legitimate users
              const m = result.Message || 'Uw nieuwe wachtwoord kon niet worden ingesteld.';
              this.$emitter.emit('alert', { message: m, type: 3 });
            });
        }
      });
    },
    change(key, value) {
      this.setResetPasswordDetails({ key, value });
      if (value) {
        this.showErrorMessage = false;
      } else {
        this.showErrorMessage = true;
      }
    },
    /**
     * @function saveInput - Function to save input value to the store
     */
    saveInput(event) {
      this.enableSubmit();

      this.setResetPasswordDetails({
        key: event.target.dataset.name,
        value: event.target.value
      });
    },
    enableSubmit() {
      let passwordValid = this.regexPattern.test(this.resetPasswordDetails.newPassword);
      let passwordsMatch = this.resetPasswordDetails.newPassword === this.resetPasswordDetails.confirmPassword;
      let passwordNotExisting =
        this.resetPasswordDetails.newPassword !== this.resetPasswordDetails.currentPassword &&
        this.resetPasswordDetails.currentPassword.length;

      this.isSubmitDisabled = !passwordValid || !passwordsMatch || !passwordNotExisting;
    }
  }
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: type-scale(6) !important;
  font-weight: 700 !important;
  text-align: center;
  display: block;
  margin-top: 30px !important;

  + p {
    color: $teal-darkest;
    font-size: type-scale(0);
  }
}

.password-change-form {
  background-color: $white;
  border-radius: 19px;
  max-width: 776px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
