<template>
  <div
    class="form-group-simple"
    :class="{
      'form-group-simple--completed': completed
    }"
  >
    <label :for="id">{{ label }}</label>

    <input
      :class="errorMessage ? 'error' : ''"
      v-model="inputValue"
      type="text"
      :name="name"
      :placeholder="placeholder"
      maxlength="10"
      @blur="blur"
      @input="update($event.target.value)"
    />
    <div class="form-group-simple__details" v-if="errorMessage">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          <span> <v-error-icon></v-error-icon> {{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { useField } from 'vee-validate';
import { ref, onBeforeMount, watch, getCurrentInstance } from 'vue';
import VErrorIcon from 'icons/AlertCircle';
import * as yup from 'yup';

export default {
  name: 'VInputDateSimple',
  components: {
    VErrorIcon
  },
  props: {
    autofocus: {
      default: false,
      type: Boolean
    },
    name: {
      default: 'name',
      type: String
    },
    id: {
      default: 'id',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    placeholder: {
      default: 'dd-mm-jjjj',
      type: String
    },
    rules: {
      default: null,
      type: Object
    }
  },

  setup(props) {
    const instance = getCurrentInstance();
    const inputValue = ref(props.value);
    const completed = ref(false);
    const getNextDate = moment().add(1, 'days');

    const generateYupSchema = (props) => {
      let rules = props.rules;
      let yupSchema = yup.string();

      if (rules.date) {
        yupSchema = yup
          .date()
          .typeError('De datum moet in het formaat dd-mm-jjjj worden ingevoerd.')
          .min('01-01-1890', 'Datum moet tussen 01-01-1890 en ' + getNextDate.format('DD-MM-YYYY') + ' liggen.')
          .max(getNextDate, 'Datum moet tussen 01-01-1890 en ' + getNextDate.format('DD-MM-YYYY') + '  liggen.')
          .required('Dit veld is verplicht.');
      }
      return yupSchema;
    };

    const schema = generateYupSchema(props);
    const { value, errorMessage, validate } = useField(props.id, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema(props);
      }
    );
    const blur = () => {
      validate();
    };

    const update = async (updatedValue) => {
      updatedValue = updatedValue.replace(/[^\0-9]/gi, '');
      if (
        updatedValue.length === 8 &&
        updatedValue.indexOf('-') === -1 &&
        updatedValue.indexOf('/') === -1 &&
        updatedValue.indexOf('.') === -1
      ) {
        updatedValue = updatedValue.replace(/^(\d{2})(\d{2})/, '$1-$2-');
      }
      // If date is 12.12.2012 format
      if (updatedValue.indexOf('.') > -1) {
        updatedValue = updatedValue.replace(/[.]/g, '-');
      }
      inputValue.value = updatedValue;
      let validDate = new moment(updatedValue, 'DD-MM-YYYY');
      value.value = validDate.format('MM-DD-YYYY');
      let result = await validate();
      instance.emit('updateValidation', result.valid);
    };

    onBeforeMount(() => {
      if (props.value) {
        value.value = ref(props.value);
      }
    });

    return {
      inputValue,
      completed,
      focus,
      blur,
      update,
      errorMessage
    };
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  border: 1px solid $pure-red;
}

.form-group-simple {
  position: relative;

  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
}
</style>
