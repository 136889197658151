<template>
  <section v-if="OSADropDownDetails" class="container evaluation">
    <div class="row">
      <v-heading :level="3">
        {{ customHeaderDetails.pageTitle }}
      </v-heading>
    </div>
    <!-- Evaluation data block-->
    <div class="row">
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">
          {{ customHeaderDetails.blockHeader }}
        </div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-6">
              <div class="form-group__date">
                <v-input-date-picker
                  id="evaluationDate"
                  ref="date"
                  type="date"
                  :label="customHeaderDetails.dateLabel"
                  name="evaluationDate"
                  autofocus
                  osa-date
                  @selected="handleDate"
                />
              </div>
            </div>
            <div class="col-xs-6" v-if="showSettings">
              <label for="evaluationType">Type evaluatie*:</label>
              <model-select
                id="evaluationType"
                placeholder="Selecteer type evaluatie"
                :options="evaluationTypes"
                v-model="evaluationTypeItem"
                :selected-option="evaluationTypeItem"
                :selectedOption="evaluationTypeItem"
              >
              </model-select>
              <div class="form-group-simple__details" v-show="saveButtonClicked && !evaluationTypeItemStatus">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
            </div>
            <div class="col-xs-6">
              <label for="selectLicense">Betreffende OSA machtiging*:</label>
              <model-select
                id="licenseId"
                placeholder="Selecteer autorisatie"
                search-text="Geen machtiging beschikbaar"
                :options="OSADropDownDetails.computedLicenses"
                v-model="licenseItem"
                :selected-option="licenseItem"
              />
              <div v-show="saveButtonClicked && licenseItem.value === null" class="form-group-simple__details">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="licenseItem.value !== null"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInUp animated': animated && showUpload }"
          >
            <div class="col-xs-12">
              <label for="selectLicense">{{ customHeaderDetails.tableHeader }}</label>
              <v-table id="evaluationRow">
                <thead>
                  <v-header-row :data="licenseHeader" />
                </thead>
                <tbody v-if="licenseOverview" class="evaluationRow" :class="[{ 'is-expanded': isExpanded }]">
                  <tr ref="row" class="evaluationRow__data" @click="toggle()">
                    <td class="evaluationRow__cell">
                      <component
                        :is="getStatusIcon(licenseOverview.Status)"
                        :class="getStatusClassName(licenseOverview.Status)"
                      />
                    </td>
                    <td class="evaluationRow__cell">
                      {{ licenseOverview.TherapyName }}
                    </td>
                    <td class="evaluationRow__cell">
                      {{ licenseOverview.SegmentName }}
                    </td>
                    <td class="evaluationRow__cell">
                      {{ licenseOverview.LicenseId }}
                    </td>
                    <td class="evaluationRow__cell">
                      {{ moment(licenseOverview.StartDate).format('DD-MM-YYYY') }}
                    </td>
                    <td v-if="licenseOverview.EndDate !== null" class="evaluationRow__cell">
                      {{ moment(licenseOverview.EndDate).format('DD-MM-YYYY') }}
                    </td>
                    <td v-else class="evaluationRow__cell">-</td>
                    <td class="evaluationRow__cell justify-content-end">
                      <v-button v-if="isExpanded" class="expand" tagelement="button" md primary> Toon minder </v-button>
                      <v-button v-else class="expand" tagelement="button" md primary> Toon meer </v-button>
                    </td>
                  </tr>
                  <tr v-if="user" v-show="isExpanded && licenseOverview" ref="row">
                    <td />
                    <td colspan="6">
                      <p>Aangemaakt door {{ user.username }}</p>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Therapy adherence block-->
    <div class="row">
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">Therapietrouw</div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row">
            <div v-if="!showPOSAFields" class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.ahi.model"
                :initvalue="formConfig.ahi.model"
                :rule="formConfig.ahi.rule"
                :inputid="formConfig.ahi.ref"
                label="AHI*:"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <label :for="formConfig.avgHoursSleep.ref">
                Hoeveel uur slaapt de patiënt gemiddeld per nacht met het apparaat?*:
              </label>
              <v-select-group
                v-model="formConfig.avgHoursSleep.model"
                :selectid="formConfig.avgHoursSleep.ref"
                :error="formConfig.avgHoursSleep.error"
                :error-message="formConfig.avgHoursSleep.errorMessage"
                :rule="formConfig.avgHoursSleep.rule"
                :initselected="formConfig.avgHoursSleep.model"
                @change="select($event, 'avgHoursSleep')"
              >
                <option
                  v-for="(avgHoursSleep, index) in OSADropDownDetails.avgHoursSleep"
                  :key="'avgHoursSleep' + index"
                  :data-index="avgHoursSleep"
                  :value="avgHoursSleep"
                >
                  &nbsp;&nbsp;&nbsp;{{ avgHoursSleep }}
                </option>
              </v-select-group>
            </div>
            <div class="col-xs-6">
              <label :for="formConfig.avgDaysSleep.ref">
                Hoeveel dagen per week slaapt de patiënt gemiddeld met het apparaat?*:
              </label>
              <v-select-group
                v-model="formConfig.avgDaysSleep.model"
                :selectid="formConfig.avgDaysSleep.ref"
                :error="formConfig.avgDaysSleep.error"
                :error-message="formConfig.avgDaysSleep.errorMessage"
                :rule="formConfig.avgDaysSleep.rule"
                :initselected="formConfig.avgDaysSleep.model"
                @change="select($event, 'avgDaysSleep')"
              >
                <option
                  v-for="(avgDaysSleep, index) in OSADropDownDetails.avgDaysSleep"
                  :key="'avgDaysSleep' + index"
                  :data-index="avgDaysSleep"
                  :value="avgDaysSleep"
                >
                  &nbsp;&nbsp;&nbsp;{{ avgDaysSleep }}
                </option>
              </v-select-group>
            </div>
            <div
              id="habitationProblems"
              class="col-xs-6 evaluation__row__selectHabituationProblems evaluation__row__radio"
              tabindex="-1"
            >
              <label for="selectHabituationProblems">Heeft de patiënt gewenningsproblemen?*</label>
              <div>
                <span class="form-group__radio">
                  <input
                    id="habitationYes"
                    name="habitationProblems"
                    type="radio"
                    value="Ja"
                    @change="checkRadioAnswer('habitationProblems', true)"
                  />
                  <label for="habitationYes"><span></span>Ja</label>
                </span>
                <span class="form-group__radio">
                  <input
                    id="habitationNo"
                    name="habitationProblems"
                    type="radio"
                    value="Nee"
                    @change="checkRadioAnswer('habitationProblems', false)"
                  />
                  <label for="habitationNo"><span></span>Nee</label>
                </span>
              </div>
              <div v-show="saveButtonClicked && !habitationProblemsStatus" class="form-group-simple__details">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
            </div>
            <div v-if="!showPOSAFields" class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.complaintsWithApnea.model"
                :initvalue="formConfig.complaintsWithApnea.model"
                :error="formConfig.complaintsWithApnea.error"
                :error-message="formConfig.complaintsWithApnea.errorMessage"
                :rule="formConfig.complaintsWithApnea.rule"
                :inputid="formConfig.complaintsWithApnea.ref"
                label="Typerende klachten behorend bij apneu, niet anders verklaard:"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <label for="hypersomnolence">Hypersomnolentie*:</label>
              <v-select-group
                v-model="formConfig.hypersomnolence.model"
                selectid="Hypersomnolentie"
                :error="formConfig.hypersomnolence.error"
                :error-message="formConfig.hypersomnolence.errorMessage"
                :rule="formConfig.hypersomnolence.rule"
                :initselected="formConfig.hypersomnolence.model"
                @change="select($event, 'hypersomnolence')"
              >
                <option
                  v-for="hypersomnolence in OSADropDownDetails.Hypersomnolence"
                  :key="'hypersomnolence' + hypersomnolence.Key"
                  :data-index="hypersomnolence.Key"
                  :value="hypersomnolence.Key"
                >
                  &nbsp;&nbsp;&nbsp;{{ hypersomnolence.Value }}
                </option>
              </v-select-group>
            </div>
          </div>
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-6">
              <label for="patientComplaint">Hoe voelt de patiënt zich ten opzichte van zijn of haar klachten?*</label>
              <!-- used new plugin-->
              <star-rating
                id="patientComplaint"
                class="evaluation__rating"
                tabindex="-1"
                :max-rating="5"
                :item-size="45"
                :show-rating="false"
                :inline="true"
                :spacing="0"
                @update:rating="ratingDetails($event, 'patientComplaint')"
              >
              </star-rating>
              <div v-show="saveButtonClicked && !patientComplaintStatus" class="form-group-simple__details">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
            </div>
            <div class="col-xs-6" v-if="!showSettings">
              <label for="partnerComplaint">Hoe ervaart de partner de inzet van de therapie?</label>
              <!-- used new plugin-->
              <star-rating
                id="patientComplaint"
                class="evaluation__rating"
                tabindex="-1"
                :max-rating="5"
                :item-size="45"
                :show-rating="false"
                :inline="true"
                :spacing="0"
                @update:rating="ratingDetails($event, 'patientComplaint')"
              >
              </star-rating>
            </div>
          </div>
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.patientComplaintNote.model"
                :initvalue="formConfig.patientComplaintNote.model"
                :error="formConfig.patientComplaintNote.error"
                :error-message="formConfig.patientComplaintNote.errorMessage"
                :rule="formConfig.patientComplaintNote.rule"
                :inputid="formConfig.patientComplaintNote.ref"
                placeholder="Opmerking bij patiënt (optioneel)"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6" v-if="!showSettings">
              <v-input-group-simple
                v-model="formConfig.partnerComplaintNote.model"
                :initvalue="formConfig.partnerComplaintNote.model"
                :error="formConfig.partnerComplaintNote.error"
                :error-message="formConfig.partnerComplaintNote.errorMessage"
                :rule="formConfig.partnerComplaintNote.rule"
                :inputid="formConfig.partnerComplaintNote.ref"
                placeholder="Opmerking bij patiënt (optioneel)"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-margin evaluation__row" v-if="showPatientFinalAssessment">
            <div
              class="col-xs-12 evaluation__row__PatientsFinalAssessment evaluation__row__radio"
              id="patientsFinalAssessment"
              tabindex="-1"
            >
              <label for="patientsFinalAssessment">Eindoordeel patiënt*:</label>
              <span
                class="form-group__radio"
                v-for="patientsFinalAssessment in OSADropDownDetails.PatientsFinalAssessment"
                :key="'patientsFinalAssessment' + patientsFinalAssessment.Key"
                :data-index="'patientsFinalAssessment' + patientsFinalAssessment.Key"
              >
                <input
                  :id="'patientsFinalAssessment' + patientsFinalAssessment.Key"
                  @change="checkRadioAnswer('patientsFinalAssessment', patientsFinalAssessment.Key)"
                  name="patientsFinalAssessment"
                  type="radio"
                  :value="patientsFinalAssessment.Value"
                />
                <label :for="'patientsFinalAssessment' + patientsFinalAssessment.Key">
                  <span></span>{{ patientsFinalAssessment.Value }}
                </label>
              </span>
              <div v-show="saveButtonClicked && !patientsFinalAssessmentStatus" class="form-group-simple__details">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
              <div class="row">
                <div v-if="OSADetails.patientsFinalAssessment === 3">
                  <warning-message
                    warning="Let op!"
                    message="Vergeet niet na het toevoegen van de evaluatie de machtiging stop te zetten. U kunt geen akkoordverklaring ondertekenen"
                  ></warning-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Set up equipment block-->
    <div v-if="showSettings" class="row">
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">Gewijzigde instellingen apparatuur voor definitieve aanvraag</div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row evaluation__selectQuestion">
            <div class="col-xs-12">
              <label for="selectLicense">Zijn de instellingen gewijzigd t.o.v. de oorspronkelijke aanvraag?</label>
              <v-select-group selectid="selectQuestion" initselected="nee" @change="selectQuestion($event)">
                <option v-for="ans in ['ja', 'nee']" :key="ans" :data-index="ans" :value="ans">
                  &nbsp;&nbsp;&nbsp;{{ ans }}
                </option>
              </v-select-group>
            </div>
          </div>
          <div
            v-if="setUpEquipmentStatus === true"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInDown animated': animated && setUpEquipmentBlock }"
          >
            <div class="col-xs-12">
              <v-input-group-simple
                v-model="formConfig.cpap.model"
                :initvalue="formConfig.cpap.model"
                :error="formConfig.cpap.error"
                :error-message="formConfig.cpap.errorMessage"
                :rule="formConfig.cpap.rule"
                :inputid="formConfig.cpap.ref"
                label="CPAP:"
                placeholder="-"
                :show-append="true"
                append-text="H20"
                @blur="validateField"
              />
            </div>
          </div>
          <div
            v-if="setUpEquipmentStatus === true"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInDown animated': animated && setUpEquipmentBlock }"
          >
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.autoCpapMin.model"
                :initvalue="formConfig.autoCpapMin.model"
                :error="formConfig.autoCpapMin.error"
                :error-message="formConfig.autoCpapMin.errorMessage"
                :rule="formConfig.autoCpapMin.rule"
                :inputid="formConfig.autoCpapMin.ref"
                label="Auto CPAP - Min:"
                placeholder="-"
                :show-append="true"
                append-text="H20"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.autoCpapMax.model"
                :initvalue="formConfig.autoCpapMax.model"
                :error="formConfig.autoCpapMax.error"
                :error-message="formConfig.autoCpapMax.errorMessage"
                :rule="formConfig.autoCpapMax.rule"
                :inputid="formConfig.autoCpapMax.ref"
                label="Auto CPAP - Max:"
                placeholder="-"
                :show-append="true"
                append-text="H20"
                @blur="validateField"
              />
            </div>
          </div>
          <div
            v-if="setUpEquipmentStatus === true"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInDown animated': animated && setUpEquipmentBlock }"
          >
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.ipap.model"
                :initvalue="formConfig.ipap.model"
                :error="formConfig.ipap.error"
                :error-message="formConfig.ipap.errorMessage"
                :rule="formConfig.ipap.rule"
                :inputid="formConfig.ipap.ref"
                label="BiPAP - IPAP:"
                placeholder="-"
                :show-append="true"
                append-text="H20"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="formConfig.epap.model"
                :initvalue="formConfig.epap.model"
                :error="formConfig.epap.error"
                :error-message="formConfig.epap.errorMessage"
                :rule="formConfig.epap.rule"
                :inputid="formConfig.epap.ref"
                label="BiPAP - EPAP:"
                placeholder="-"
                :show-append="true"
                append-text="H20"
                @blur="validateField"
              />
            </div>
          </div>
          <div
            v-if="setUpEquipmentStatus === true"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInDown animated': animated && setUpEquipmentBlock }"
          >
            <div class="col-xs-6">
              <label for="rampSettingsMinutes">Ramp instellingen (minuten):</label>
              <v-select-group
                v-model="formConfig.rampSettingsMinutes.model"
                selectid="rampSettingsMinutes"
                :error="formConfig.rampSettingsMinutes.error"
                :error-message="formConfig.rampSettingsMinutes.errorMessage"
                :rule="formConfig.rampSettingsMinutes.rule"
                :initselected="formConfig.rampSettingsMinutes.model"
                @change="select($event, 'rampSettingsMinutes')"
              >
                <option
                  v-for="(rampSettingsMinutes, index) in OSADropDownDetails.rampSettingsMinutes"
                  :key="'rampSettingsMinutes' + index"
                  :data-index="rampSettingsMinutes"
                  :value="rampSettingsMinutes"
                >
                  &nbsp;&nbsp;&nbsp;{{ rampSettingsMinutes }}
                </option>
              </v-select-group>
            </div>
            <div class="col-xs-6">
              <label for="minimalDisasterPressure">Minimale ramp druk:</label>
              <v-select-group
                v-model="formConfig.minimalDisasterPressure.model"
                selectid="minimalDisasterPressure"
                :error="formConfig.minimalDisasterPressure.error"
                :error-message="formConfig.minimalDisasterPressure.errorMessage"
                :rule="formConfig.minimalDisasterPressure.rule"
                :initselected="formConfig.minimalDisasterPressure.model"
                @change="select($event, 'minimalDisasterPressure')"
              >
                <option
                  v-for="(minimalDisasterPressure, index) in OSADropDownDetails.minimalDisasterPressure"
                  :key="'minimalDisasterPressure' + index"
                  :data-index="minimalDisasterPressure"
                  :value="minimalDisasterPressure"
                >
                  &nbsp;&nbsp;&nbsp;{{ minimalDisasterPressure }}
                </option>
              </v-select-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- evaluation posa spt block-->
    <div v-if="showPOSAFields" class="row">
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">Evaluatie proefperiode / Definitieve aanvraag</div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-12 evaluation__row__radio" tabindex="-1">
              <ul>
                <li>Voldoende afname van percentage rugligging.</li>
                <li>Voldoende verbetering van symptomen.</li>
                <li>
                  Ik verklaar dat de bovengenoemde verzekerde bekend is met de toepassing van het aangevraagde
                  hulpmiddel en dat bij gebruik een verbetering bereikt wordt die niet op eenvoudiger wijze is te
                  verkrijgen.
                </li>
              </ul>
              <span class="form-group__radio">
                <input
                  id="termsAndConditions"
                  name="termsAndConditions"
                  type="radio"
                  :value="!showPOSAError"
                  @change="updateTermsAndConditions"
                />
                <label for="termsAndConditions"><span></span>Ja, ik ga akkoord met bovenstaande.</label>
              </span>
              <div v-show="saveButtonClicked && showPOSAError" class="form-group-simple__details">
                <div class="form-group-simple__messages">
                  <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Comments evaluation block-->
    <div v-if="showSettings || showPOSAFields" class="row">
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">Opmerkingen evaluatie</div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-12">
              <textarea
                id="evaluationComments"
                v-model="OSADetails.evaluationComments"
                class="evaluation__textarea"
                placeholder="Beschrijf hier uw opmerking en/of motivatie"
                rows="5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Final approval block-->
    <div
      class="row"
      v-if="
        (showSettings &&
          evaluationTypeItem.value &&
          evaluationTypeItem.value !== 1 &&
          OSADetails.patientsFinalAssessment !== 0 &&
          OSADetails.patientsFinalAssessment !== 3) ||
        showPOSAFields
      "
    >
      <div class="evaluation__block col-xs-12">
        <div class="evaluation__block__header">Definitief maken voor akkoordverklaring</div>
        <div class="evaluation__block__content">
          <div class="row grid-margin evaluation__row">
            <div class="col-xs-12">
              <v-checkbox-group
                v-model="OSADetails.isReadyForApproval"
                :initvalue="OSADetails.isReadyForApproval"
                :overwrite-value="OSADetails.isReadyForApproval"
                :label="customHeaderDetails.finalApprovalLabel"
                inputid="finalApproval"
                @input="updateFinalApproval(...arguments)"
              />
            </div>
          </div>
          <div
            v-if="user && OSADetails.isReadyForApproval"
            class="row grid-margin evaluation__row"
            :class="{ 'fadeInDown animated': animated && signatureBlock }"
          >
            <div class="col-xs-6">
              <v-input-group-simple v-model="user.username" inputid="userName" disabled :initvalue="user.username" />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple inputid="date" disabled :model="currentDate" :initvalue="currentDate" />
            </div>
          </div>
          <div
            v-if="OSADetails.isReadyForApproval && user && user.signature !== null"
            class="row grid-margin grid-align-center evaluation__row"
            :class="{ 'fadeInDown animated': animated && signatureBlock }"
          >
            <div class="evaluation__signature">
              <prescriber-signature :user-id="user.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons Block-->
    <div class="row grid-justify-content-right">
      <div class="evaluation__actions">
        <v-button md hollow class="evaluation__actions__cancelEvaluation" @click.prevent="cancelOSA()">
          Annuleren
        </v-button>
        <v-button md cta class="evaluation__actions__saveEvaluation" @click.prevent="saveOSA()"> Toevoegen </v-button>
      </div>
    </div>
    <!-- Cancel pop up -->
    <generic-modal v-if="showCancelPopUp">
      <template #body>
        <p>Wilt u de evaluatie annuleren? De gegevens worden niet opgeslagen</p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click="closeModal"> Terug </v-button>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Bevestigen </v-button>
      </template>
    </generic-modal>
    <!-- Success pop up -->
    <generic-modal v-if="showSuccessPopup">
      <template #body>
        <p>Uw evaluatie is opgeslagen en toegevoegd aan het patiënt dashboard</p>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click="goToPatientDashboard()"> Naar patiënt dashboard </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import moment from 'moment';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import PrescriberSignature from '@/components/sections/prescriber/PrescriberSignature';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import { useForm } from 'vee-validate';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VTable from '@/components/shared/table/Table';
import VActiveIcon from 'icons/CheckCircle';
import VAlmostExpiredIcon from 'icons/Timelapse';
import VDownloadIcon from 'icons/Download';
import StarRating from 'vue-star-rating';
import $ from 'jquery';
import shared from '@/components/shared/shared';
import GenericModal from '@/components/shared/GenericModal';
import { currentUserIdentity$, getOsaDropDownDetails, getOsaLicenseOverviewDetails } from '@/services';
import { from } from 'rxjs';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import WarningMessage from '@/components/shared/WarningMessage';

export default {
  name: 'Evaluation',
  components: {
    VInputDatePicker,
    VCheckboxGroup,
    VHeaderRow,
    VTable,
    VDownloadIcon,
    StarRating,
    VActiveIcon,
    VAlmostExpiredIcon,
    GenericModal,
    PrescriberSignature,
    WarningMessage
  },
  setup() {
    const store = useStore();
    const user = ref(null);
    const osaDropDownDetails = ref(null);

    const patientDetails = computed(() => store.getters['patient/getPatientGegevens']);
    const pdfFileToken = computed(() => store.getters['accountability/getPdfFileToken']);
    const pdf = computed(() => store.getters['accountability/getPdf']);

    const getCurrentPatient = () => store.dispatch('patient/getCurrentPatient');
    const saveAccountabilityFiles = (value) => store.dispatch('accountability/saveAccountabilityFiles', value);

    currentUserIdentity$.subscribe((value) => (user.value = value));
    from(getOsaDropDownDetails()).subscribe((details) => {
      osaDropDownDetails.value = details;
    });

    return {
      patientDetails,
      pdfFileToken,
      pdf,
      getCurrentPatient,
      saveAccountabilityFiles,
      user,
      OSADropDownDetails: osaDropDownDetails
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      formConfig: {},
      data: { FileList: [] },
      licenseItem: {
        value: null,
        text: null
      },
      OSADetails: {
        evaluationDate: '',
        userId: '',
        licenseId: '',
        customerId: '',
        ahi: '',
        avgHoursSleep: '',
        avgDaysSleep: '',
        habitationProblems: false,
        complaintsWithApnea: '',
        hypersomnolence: 0,
        patientComplaint: '',
        patientComplaintNote: '',
        partnerComplaint: 0,
        partnerComplaintNote: '',
        patientsFinalAssessment: 0,
        isSettingChangedFromOriginal: false,
        cpap: '',
        autoCpapMin: '',
        autoCpapMax: '',
        ipap: '',
        epap: '',
        rampSettingsMinutes: 0,
        minimalDisasterPressure: '',
        heatedHumidifier: true,
        evaluationComments: '',
        isReadyForApproval: false,
        skipEvaluation: false,
        type: null
      },
      saveButtonClicked: false,
      animated: false,
      signatureBlock: false,
      showUpload: false,
      setUpEquipmentStatus: false,
      setUpEquipmentBlock: false,
      licenseHeader: ['', 'Type', 'Product(-groep)', 'Kenmerk', 'Startdatum', 'Einddatum', ''],
      isExpanded: true,
      showCancelPopUp: false,
      showSuccessPopup: false,
      rating: 100,
      patientsFinalAssessmentStatus: false,
      patientComplaintStatus: false,
      habitationProblemsStatus: false,
      showPOSAError: true,
      licenseOverview: {},
      evaluationTypes: [
        { text: 'Tussentijdse evaluatie', value: 1 },
        { text: 'Definitieve evaluatie', value: 2 }
      ],
      evaluationTypeItem: {
        value: null,
        text: null
      },
      evaluationTypeItemStatus: false
    };
  },
  computed: {
    currentDate() {
      return moment(new Date()).format('DD-MM-YYYY');
    },
    showSettings() {
      return router.currentRoute.value.name === 'evaluation' ? true : false;
    },
    showPOSAFields() {
      return router.currentRoute.value.name === 'posa-spt' ? true : false;
    },
    customHeaderDetails() {
      let result = {
        pageTitle: 'Evaluatie toevoegen',
        blockHeader: 'Evaluatie kenmerken',
        dateLabel: 'Datum van uitgevoerde evaluatie*:',
        tableHeader: 'Machtiging en apparaat uitlezingen',
        finalApprovalLabel: 'Therapie succesvol, gereed voor akkoordverklaring'
      };
      if (router.currentRoute.value.name === 'readout') {
        result = {
          pageTitle: 'Uitlezing meetmoment toevoegen',
          blockHeader: 'Meetmoment gegevens',
          dateLabel: 'Datum van toevoegen meetmoment*',
          tableHeader: 'Apparaat uitlezingen',
          finalApprovalLabel: 'Therapie succesvol, gereed voor akkoordverklaring'
        };
      }
      if (router.currentRoute.value.name === 'posa-spt') {
        result = {
          pageTitle: 'Evaluatie POSA SPT toevoegen',
          blockHeader: 'Evaluatie kenmerken',
          dateLabel: 'Datum van uitgevoerde evaluatie*:',
          tableHeader: 'Apparaat uitlezingen',
          finalApprovalLabel: 'Therapie succesvol, gereed voor akkoordverklaring'
        };
      }
      return result;
    },
    showPatientFinalAssessment() {
      if (this.showSettings) {
        return this.evaluationTypeItem.value && this.evaluationTypeItem.value !== 1;
      } else {
        return true;
      }
    }
  },
  watch: {
    evaluationTypeItem(evaluationTypeItem) {
      this.evaluationTypeItemStatus = evaluationTypeItem.value !== null ? true : false;
      this.patientsFinalAssessmentStatus = evaluationTypeItem.value === 2 ? false : true;
      this.OSADetails.type = evaluationTypeItem.text;
    },
    licenseItem(licenseItem) {
      this.loadLicenseOverview(licenseItem.value);
      this.saveButtonClicked = false;
      this.OSADetails.licenseId = licenseItem.value;
      this.animated = true;
      this.showUpload = true;
      setTimeout(() => {
        this.animated = false;
        this.showUpload = false;
      }, 1000);
    }
  },
  created() {
    this.formConfig = {
      ahi: {
        model: this.OSADetails.ahi,
        ref: 'ahi',
        attached: true,
        alias: 'AHI',
        rule: {
          required: true,
          numeric: true,
          min: 0,
          max: 150
        },
        error: false
      },
      avgHoursSleep: {
        model: this.OSADetails.avgHoursSleep,
        ref: 'avgHoursSleep',
        attached: true,
        alias: 'Dit veld',
        rule: {
          required: true,
          numeric: true
        },
        error: false
      },
      avgDaysSleep: {
        model: this.OSADetails.avgDaysSleep,
        ref: 'avgDaysSleep',
        attached: true,
        alias: 'Dit veld',
        rule: {
          required: true,
          numeric: true
        },
        error: false
      },
      complaintsWithApnea: {
        model: this.OSADetails.complaintsWithApnea,
        ref: 'complaintsWithApnea',
        attached: true,
        alias: 'complaintsWithApnea',
        rule: {
          required: false
        },
        error: false
      },
      hypersomnolence: {
        model: this.OSADetails.hypersomnolence,
        ref: 'hypersomnolence',
        attached: true,
        alias: 'hypersomnolence',
        rule: {
          required: true,
          numeric: true
        },
        error: false
      },
      patientComplaint: {
        model: this.OSADetails.patientComplaint,
        ref: 'patientComplaint',
        attached: true,
        alias: 'Dit veld',
        rule: {
          required: true,
          numeric: true
        },
        error: false
      },
      patientComplaintNote: {
        model: this.OSADetails.patientComplaintNote,
        ref: 'patientComplaintNote',
        attached: true,
        alias: 'patientComplaintNote',
        rule: {
          required: false
        },
        error: false
      },
      partnerComplaintNote: {
        model: this.OSADetails.partnerComplaintNote,
        ref: 'partnerComplaintNote',
        attached: true,
        alias: 'partnerComplaintNote',
        rule: {
          required: false
        },
        error: false
      }
    };
    this.getCurrentPatient().then(() => {
      if (router.currentRoute.value.name === 'posa-spt') {
        delete this.formConfig.ahi;
        delete this.formConfig.complaintsWithApnea;
      }
    });
  },
  methods: {
    openFileInNewTab: shared.openFileInNewTab,
    fileExtention: shared.fileExtention,
    closeModal() {
      this.showSuccessPopup = false;
      this.showCancelPopUp = false;
    },
    /**
     * @function updateTermsAndConditions - Function to update the posa terms and condtions
     */
    updateTermsAndConditions(e) {
      this.showPOSAError = !e.currentTarget.checked;
    },
    /**
     * @function saveUploadedFiles - Function to save the uploded files
     */
    saveUploadedFiles(fmData) {
      let fileDetails = {
        licenseId: this.licenseOverview.LicenseId,
        accountabilityId: this.licenseOverview.AccountabilityId,
        formData: fmData
      };
      this.saveAccountabilityFiles(fileDetails).then((response) => {
        this.loadLicenseOverview(this.licenseOverview.value);
      });
    },
    /**
     * @function updateFinalApproval - Function to set/unset the final approval
     */
    updateFinalApproval(value) {
      if (value) {
        this.animated = true;
        this.signatureBlock = true;
        setTimeout(() => {
          this.animated = false;
          this.signatureBlock = false;
        }, 1000);
      }
    },
    /**
     * @function moment - Function to convert datetime to required specific format
     */
    moment(...args) {
      return moment(...args);
    },
    /**
     * @function checkRadioAnswer - Function to handle radio type fields prescriber interaction
     */
    checkRadioAnswer(key, value) {
      if (key === 'patientsFinalAssessment' && value) {
        this.patientsFinalAssessmentStatus = true;
        this.OSADetails.isReadyForApproval = value !== 3 ? true : false;
      }
      if (key === 'habitationProblems') {
        this.habitationProblemsStatus = true;
      }
      this.saveOSADetails({ key, value });
    },
    async loadLicenseOverview(licenseId) {
      this.licenseOverview = await getOsaLicenseOverviewDetails(licenseId);
    },
    /**
     * @function saveOSADetails - Function to set the field values to the Store
     */
    saveOSADetails(OSADetails) {
      this.OSADetails[OSADetails.key] = OSADetails.value;
    },
    /**
     * @function validateField - Function to validate the value enterd according to the vee-validate
     */
    validateField(ref, model) {
      this.saveOSADetails({
        key: ref,
        value: model
      });

      this.saveButtonClicked = false;
    },
    /**
     * @function validateAllFields - Function to validate all fields and set errors in any
     */
    async validateAllFields() {
      const result = await this.validate();
      if (
        result.valid &&
        this.licenseItem.value &&
        this.habitationProblemsStatus &&
        this.patientComplaintStatus &&
        this.patientsFinalAssessmentStatus
      ) {
        this.OSADetails.userId = this.user.id;
        this.OSADetails.customerId = this.patientDetails.PatientId;
        this.OSADetails.evaluationDate = moment(this.OSADetails.evaluationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        this.OSADetails.ahi = Number(this.OSADetails.ahi);
        this.OSADetails.avgHoursSleep = Number(this.OSADetails.avgHoursSleep);
        this.OSADetails.avgDaysSleep = Number(this.OSADetails.avgDaysSleep);
        if (this.OSADetails.rampSettingsMinutes) {
          this.OSADetails.rampSettingsMinutes = Number(this.OSADetails.rampSettingsMinutes);
        }
        let computedApiCall = 'osa/SaveEvaluation';
        if (router.currentRoute.value.name === 'evaluation') {
        }
        if (router.currentRoute.value.name === 'readout') {
          computedApiCall = 'osa/SaveReadout';
        }
        if (router.currentRoute.value.name === 'posa-spt') {
          if (this.showPOSAError) {
            return;
          }
          this.OSADetails.supinePositionPercentage = true;
          this.OSADetails.improvementInSymptoms = true;
          this.OSADetails.declaration = true;
          computedApiCall = 'EvaluationSpt/SaveEvaluationSpt';
          const keysToRemove = [
            'ahi',
            'complaintsWithApnea',
            'isSettingChangedFromOriginal',
            'cpap',
            'autoCpapMin',
            'autoCpapMax',
            'ipap',
            'epap',
            'rampSettingsMinutes',
            'minimalDisasterPressure',
            'heatedHumidifier'
          ];
          keysToRemove.forEach((key) => {
            delete this.OSADetails[key];
          });
        }
        axios.post(computedApiCall, this.OSADetails).then(() => {
          this.showSuccessPopup = true;
        });
      }
    },
    /**
     * @function handleDate - Function to handle / convert start date value as per requirement format
     */
    handleDate(key, value) {
      this.OSADetails[key] = value;
    },
    /**
     * @function goToPatientDashboard - Fuction to redirect prescriber to patient dashboard page
     */
    goToPatientDashboard() {
      router.push('/patientdashboard');
    },
    /**
     * @function validateAllFields - Function to validate all fields on form submit and Trigger post call
     */
    saveOSA() {
      this.saveButtonClicked = true;
      this.validateAllFields();
    },
    /**
     * @function selectQuestion - Function that extends the form and added set / unset extra config based on the user interaction
     */
    selectQuestion(e) {
      if (!e.currentTarget.value) {
        return;
      }
      let option = false;
      if (e.currentTarget.value === 'ja') {
        option = true;
        this.setUpEquipmentStatus = true;
        this.setUpEquipmentBlock = true;
        this.animated = true;
        this.extendFormConfig();
        setTimeout(() => {
          this.animated = false;
          this.setUpEquipmentBlock = false;
        }, 1000);
      } else {
        this.resetFormConfigValues();
        this.setUpEquipmentStatus = false;
        this.setUpEquipmentBlock = false;
      }
      this.OSADetails.isSettingChangedFromOriginal = option;
    },
    /**
     * @function select - Funtion that handles the select change
     */
    select(e, key) {
      if (!e.currentTarget.value || e.currentTarget.value == 0) {
        return;
      }
      this.saveOSADetails({ key, value: parseInt(e.currentTarget.value, 10) });
    },
    /**
     * @function toggle - Function that handles collapse functionality at license overview
     */
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    /**
     * @function cancelOSA - Function to show cancel pop up
     */
    cancelOSA() {
      this.showCancelPopUp = true;
    },
    /**
     * @function ratingDetails - Function to handle rating values
     */
    ratingDetails(value, key) {
      if (key === 'patientComplaint') {
        this.patientComplaintStatus = true;
      }
      this.saveOSADetails({ key, value });
    },
    /**
     * @function getStatusIcon - Function to get proper icon based on license status
     */
    getStatusIcon(status) {
      switch (status) {
        case 'active':
          return VActiveIcon;
        case 'almostExpired':
          return VAlmostExpiredIcon;
        case 'expired':
          return VActiveIcon;
        default:
      }
    },
    /**
     * @function getStatusClassName - Fucntion to get respective class based on license status
     */
    getStatusClassName(status) {
      switch (status) {
        case 'active':
          return 'status-active';
        case 'almostExpired':
          return 'status-almost-expired';
        case 'expired':
          return 'status-expired';
        default:
      }
    },
    /**
     * @function extendFormConfig - Function to extend form config
     */
    extendFormConfig() {
      $.extend(true, this.formConfig, {
        cpap: {
          model: this.OSADetails.cpap,
          ref: 'cpap',
          attached: true,
          alias: 'CPAP',
          rule: {
            required: false
          },
          error: false
        },
        autoCpapMin: {
          model: this.OSADetails.autoCpapMin,
          ref: 'autoCpapMin',
          attached: true,
          alias: 'Auto CPAP Min',
          rule: {
            required: false
          },
          error: false
        },
        autoCpapMax: {
          model: this.OSADetails.autoCpapMax,
          ref: 'autoCpapMax',
          attached: true,
          alias: 'Auto CPAP Max',
          rule: {
            required: false
          },
          error: false
        },
        ipap: {
          model: this.OSADetails.ipap,
          ref: 'ipap',
          attached: true,
          alias: 'BiPAP - IPAP',
          rule: {
            required: false
          },
          error: false
        },
        epap: {
          model: this.OSADetails.epap,
          ref: 'epap',
          attached: true,
          alias: 'BiPAP - EPAP',
          rule: {
            required: false
          },
          error: false
        },
        rampSettingsMinutes: {
          model: this.OSADetails.rampSettingsMinutes,
          ref: 'rampSettingsMinutes',
          attached: true,
          alias: 'Ramp instellingen',
          rule: {
            required: false
          },
          error: false
        },
        minimalDisasterPressure: {
          model: this.OSADetails.minimalDisasterPressure,
          ref: 'minimalDisasterPressure',
          attached: true,
          alias: 'Minimale ramp dunk',
          rule: {
            required: false
          },
          error: false
        }
      });
    },
    /**
     * @function resetFormConfigValues - Functon to reset the form config
     */
    resetFormConfigValues() {
      const resetConfigKeys = [
        'cpap',
        'autoCpapMin',
        'autoCpapMax',
        'ipap',
        'epap',
        'rampSettingsMinutes',
        'minimalDisasterPressure'
      ];
      for (let key in this.formConfig) {
        if (resetConfigKeys.includes(this.formConfig[key].ref)) {
          delete this.formConfig[key];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.evaluation {
  color: $gray-darkest;
  .ui.fluid.dropdown,
  .ui.selection.dropdown {
    min-height: 50px;
    padding: 16px 16px 16px 16px;
    border: 1px solid #999999;
    :deep(.dropdown.icon) {
      margin-top: -5px;
    }
  }
  &__block {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin: 0px 0px 30px 0px;
    &__header {
      background-color: $grayish-very-light;
      color: $grayish-very-dark;
      font-size: 18px;
      padding: 20px;
    }
    &__content {
      padding: 10px 10px 10px 10px;
    }
  }
  &__actions {
    display: flex;
    margin: 20px 0px 30px 0px;
    &__cancelEvaluation {
      margin-right: 25px;
    }
  }
  &__row {
    margin: 10px 0 10px 0;
    &__radio {
      display: inline-flex;
      outline: none !important;
      span {
        margin-right: 10px;
        float: left;
      }
    }
    td {
      padding-bottom: 20px;
    }
  }
  &__textarea {
    min-height: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid $gray-light;
    padding: 10px;
  }
  &__signature {
    margin: 0 auto;
    img {
      border: solid 2px $grayish-med-light;
      vertical-align: text-top;
      max-width: 350px !important;
      height: auto;
      width: 100%;
    }
  }
  a {
    color: $teal-darkest;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin-bottom: 14px;
  }
  .evaluationRow {
    background-color: $white;
    border: 1px solid $gray-light;
    transition: height 0.2s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    &__cell {
      color: $gray-darkest;
      line-height: 1.5rem;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 1rem 0.5rem;
      vertical-align: top;
      > .material-design-icon {
        display: flex;
        justify-content: center;
        fill: $dark-green;

        :deep(svg) {
          display: block;
        }

        &.status-active {
          fill: $table-row-icon-check;
        }

        &.status-almost-expired {
          fill: $table-row-icon-timelapse;
        }

        &.status-expired {
          fill: $grayish-lighter;
        }
      }
    }
  }
  legend {
    margin: 10px 0 10px 0;
  }
  &__date {
    :deep(.form-group-simple input) {
      padding-left: 30px;
      width: 100%;
      border-color: $gray;
    }
  }
  :deep(.form-group) {
    width: 100%;
    padding-top: 0px;
    &__icon {
      right: 0px;
      top: 15px;
    }
    &__details {
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
    }
    &__radio input[type='radio'] + label {
      font-weight: normal;
    }
  }
  :deep(.uploadBox) {
    margin: 25px 25px 25px 0px;
    padding: 0px !important;
  }
  &__selectQuestion {
    :deep(.form-group .form-group__details) {
      display: none !important;
    }
  }
  ul {
    line-height: 1.5em;
    color: #666666;
    word-break: break-word;
    margin-top: 0px;
    margin-bottom: 8px;
  }
}
</style>
