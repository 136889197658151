import * as yup from 'yup';

export function generateYupSchemaForBsn(props) {
  let rules = props.rules;
  let name = props.label ? props.label : props.placeholder;
  let yupSchema = yup.string();

  // section is used for custom validation
  function bsn11proofCheck(value) {
    if (!value) {
      return false;
    }

    let sum = value
      .split('') // make it an array
      .map((v) => v / 1) // force all numeric values
      .reverse() // reverse, because 11 proof check requires multiplication by reversed index (e.g. first value needs to be multiplied by <length of full string>)
      .reduce((current, numericValue, index) => {
        var multiplyBy =
          index === 0
            ? -1 // bsn 11 proof requires the ending numeric value to be multiplied by -1 instead of index+1 (please note that we reversed order earlier)
            : index + 1;

        return current + numericValue * multiplyBy; // return sum
      }, 0);

    return sum % 11 === 0; // 11 check
  }
  function isValidBsn(message) {
    return this.test('isValidBsn', message, function (value) {
      const { path, createError } = this;

      if (!value) {
        return createError({ path, message: message ?? 'BSN is verplicht.' });
      }
      if (value.length < 9) {
        return createError({ path, message: message ?? 'BSN moet minimaal 9 karakters zijn' });
      }
      if (!bsn11proofCheck(value)) {
        return createError({ path, message: message ?? 'BSN is niet correct' });
      }
      return true;
    });
  }
  yup.addMethod(yup.mixed, 'isValidBsn', isValidBsn);

  if (rules.isBsn) {
    yupSchema = yup.mixed().isValidBsn();
  }
  return yupSchema;
}
