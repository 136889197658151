<template>
  <form
    method="get"
    class="password-reset-form row grid-padding grid-justify-content-center"
    @submit.prevent="submitForm"
  >
    <v-heading class="col-xs-12" :level="1"> Wijzig hier uw wachtwoord </v-heading>
    <p class="col-xs-12">
      Uw nieuwe wachtwoord dient minimaal 8 karakters, 1 kleine letter, 1 hoofdletter, een speciaal karakter en 1 cijfer
      te bevatten.
    </p>
    <!-- Catch browser's autompletion guess (un/pw) -->
    <input type="text" style="display: none" />
    <input type="password" style="display: none" />
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 col-md-7">
          <div class="row grid-margin">
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Password"
                v-model:value="formData.Password"
                :rules="{
                  required: true,
                  regex: regexPattern
                }"
                class="input-password"
                data-vv-as="Nieuw wachtwoord"
                placeholder="Nieuw wachtwoord"
                type="password"
                label="Nieuw wachtwoord"
                show-hide-password-icon
                :show-caps-lock-status="true"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="PasswordConfirm"
                v-model:value="formData.PasswordConfirm"
                :rules="{
                  required: true,
                  in: [formData.Password]
                }"
                class="input-password"
                data-vv-as="Herhaal wachtwoord"
                placeholder="Herhaal wachtwoord"
                type="password"
                label="Herhaal wachtwoord"
                show-hide-password-icon
                :show-caps-lock-status="true"
                @input="saveInput($event)"
              />
            </div>
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="Securitycode"
                v-model:value="formData.Securitycode"
                :rules="{
                  required: true
                }"
                class="input-password"
                data-vv-as="Veiligheidscode"
                placeholder="Veiligheidscode"
                :show-tooltip="true"
                tooltip-text="Deze veiligheidscode wordt ter controle opnieuw gevraagd bij het invullen van een nieuw wachtwoord. De code mag zelf gekozen worden. Onthoud deze goed."
                type="text"
                label="Veiligheidscode"
                @input="saveInput($event)"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5">
          <v-password-validation :value="formData.Password" />
        </div>
      </div>
    </div>
    <div class="col-xs-8 col-md-5 row grid-margin">
      <div class="col-xs-12">
        <v-button type="submit" tagelement="button" cta md :disabled="!tokenValid || isSubmitDisabled">
          Wachtwoord wijzigen
        </v-button>
      </div>
    </div>
  </form>
</template>

<script>
import router from '@/router';
import { mapActions } from 'vuex';
import VPasswordValidation from '@/components/shared/PasswordValidation';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'VSetPassword',
  components: {
    VPasswordValidation
  },
  setup() {
    return {
      ...mapActions({
        submitSetPasswordForm: 'login/submitSetPasswordForm',
        validateSetPasswordToken: 'login/validateSetPasswordToken'
      })
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      formData: {
        Securitycode: '',
        Password: '',
        PasswordConfirm: '',
        Token: ''
      },
      tokenValid: false,
      regexPattern: /^(?=.*\d)(?=.*[!@#\$%\^\&*\)\(+=._-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, //length > 7, at least one upper case, lower case, special symbol, number
      isSubmitDisabled: true
    };
  },
  created() {
    this.$parent.$parent.pageTitle = 'Wachtwoord vergeten';
    this.$parent.$parent.navigationBackText = 'Terug naar inloggen';

    this.formData.Token = router.currentRoute.value.params.token;
    this.validateSetPasswordToken({ Token: this.formData.Token })
      .then(() => {
        this.tokenValid = true;
      })
      .catch(this.expiredTokenRedirect);
  },
  methods: {
    submitForm() {
      $validateAllComponents(this).then((validated) => {
        if (validated) {
          this.submitSetPasswordForm(this.formData)
            .then(() => {
              this.$emitter.emit('alert', { message: 'Uw wachtwoord is gewijzigd.', type: 0, delay: 5000 });
              router.push({ path: '/' });
            })
            .catch(() => {
              this.$emitter.emit('alert', { message: 'De veiligheidscode is onjuist.', type: 3 });
            });
        }
      });
    },
    expiredTokenRedirect() {
      this.$emitter.emit('alert', {
        message:
          'De link is verlopen, vraag a.u.b. uw wachtwoord nogmaals aan. De link is slechts 1 uur na aanvraag geldig',
        type: 3,
        delay: 6000
      });
      router.push({ name: 'herstelwachtwoord' });
    },
    /**
     * @function saveInput - Function to save input value to the store
     */
    saveInput(event) {
      this.enableSubmit();
      this.formData[event.target.id] = event.target.value;
    },
    enableSubmit() {
      let passwordValid = this.regexPattern.test(this.formData.Password);
      let passwordsMatch = this.formData.Password === this.formData.PasswordConfirm;

      this.isSubmitDisabled = !passwordValid || !passwordsMatch || !this.formData.Securitycode.length;
    }
  }
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: type-scale(6) !important;
  font-weight: 700 !important;
  text-align: center;
  display: block;
  margin-top: 30px !important;

  + p {
    color: $teal-darkest;
    font-size: type-scale(0);
  }
}
.password-reset-form {
  background-color: $white;
  border-radius: 19px;
  max-width: 776px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
