<template>
  <div
    class="form-group-simple digital-signature"
    :class="{
      'form-group-simple--completed': completed
    }"
  >
    <label v-if="label" :for="id">{{ label }}</label>
    <label v-if="desc" class="description">{{ desc }}</label>
    <VueSignaturePad :id="id" ref="signaturePad" class="digital-signature__signature-canvas" :name="name" />
    <div class="row grid-margin grid-justify-content-left digital-signature__actions">
      <div class="col-xs-12 col-sm-6">
        <a class="undoSign" @click="undoSignature">Handtekening opnieuw zetten</a>
      </div>
      <div class="col-xs-12 col-sm-6">
        <v-button lg secondary nowrap class="save-sign" @click.prevent="saveSignature"> Handtekening opslaan </v-button>
      </div>
    </div>
    <div class="form-group-simple__details" v-if="customErrorMessage">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          <span> <v-error-icon></v-error-icon> {{ customErrorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad';
import VErrorIcon from 'icons/AlertCircle';

export default {
  name: 'VInputDigitalSignature',
  components: {
    VueSignaturePad,
    VErrorIcon
  },
  props: {
    name: {
      default: 'signature',
      type: String
    },
    label: {
      default: 'signature',
      type: String
    },
    id: {
      default: '',
      type: String
    },
    desc: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      errors: '',
      completed: false,
      customErrorMessage: false
    };
  },
  methods: {
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (data) {
        this.customErrorMessage = '';
        this.$emit('saveSignature', isEmpty, data);
      } else {
        this.customErrorMessage = 'Signature is verplicht.';
      }
    },
    // Function to undo the change in the signature pad
    undoSignature() {
      this.customErrorMessage = '';
      this.$refs.signaturePad.undoSignature();
      this.$emit('undoSignature');
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  border: 1px solid $pure-red;
}
.form-group-simple {
  position: relative;
  &__error .alert-circle-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    fill: $pure-red;
    vertical-align: bottom;
  }
}
.digital-signature {
  width: 500px;
  .description {
    font-size: 15px;
    font-weight: normal;
  }
  &__signature-canvas {
    min-height: 300px;
    margin-top: 10px;
    border: solid 1px $grayish-darkish;
    background-color: $white;
    background-size: 15px 15px;
    background-image: linear-gradient(to right, $black -3px, transparent 1px),
      linear-gradient(to bottom, $black -3px, transparent 1px);
  }
  &__actions {
    margin-top: 10px;
    .btn {
      background-color: $teal-darkest;
    }
    .undoSign {
      font-size: 16px;
      line-height: 1.5rem;
      color: $teal-darkest;
      cursor: pointer;
      text-decoration: underline;
      margin-top: 0.75rem;
    }
  }
}
</style>
