import axios from 'axios'
export default {
  namespaced: true,
  state: {
    pageItem: {}
  },
  mutations: {
    setPageItem (state, payload) {
      state.pageItem = payload
    }
  },
  actions: {
    fetchPageItem ({commit, state}, Id) {
      return axios.get('/Page/Item/' + Id).then((response) => {
        commit('setPageItem', response.data.ArtikelDetails)
      })
    }
  },
  getters: {
    getPageItem: state => {
      return state.pageItem
    }
  }
}
