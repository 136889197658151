<template>
  <div class="wrapper">
    <table class="details-table">
      <thead>
        <tr>
          <th>Aantal</th>
          <th>Omschrijving</th>
          <th>Artikelcode</th>
          <th>Bestelnummer</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in products"
          :key="index"
        >
          <td>{{ product.quantity }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.productCode }}</td>
          <td>{{ product.productId }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row address-wrapper">
      <div class="orderDelivery">
        {{ deliveryaddress }}
      </div>
      <div v-if="!isArchived">
        <v-button
          v-if="showReOrder && getVisibilityStatus () && !patientDeceasedStatus "
          hollow
          md
          class="reOrderButton"
          @click.prevent="reOrder"
        >
          Bestelling herhalen
        </v-button>
        <div
          v-else-if="!isEquipmentOrder"
          class="orderDelivery"
        >
          Opnieuw bestellen niet mogelijk. Niet bestelbaar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'

import {
  shoppingCart$,
  addToShoppingCart,
  activePpwRecId$
} from '@/services';
import { ref } from 'vue';

export default {
  name: 'PatientOrderHistoryEntryProducts',
  props: {
    showReOrder: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array
    },
    deliveryaddress: {
      type: String
    },
    patientDeceasedStatus: {
      type: Boolean,
      default: false
    },
    isArchived: {
      default: false,
      type: Boolean
    },
    isEquipmentOrder: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);

    shoppingCart$.subscribe(value => shoppingCart.value = value);
    activePpwRecId$.subscribe(value => activePpwRecId.value = value);

    return {
      shoppingCart,
      activePpwRecId
    }
  },
  data () {
    return {}
  },
  methods: {
    reOrder () {
      this.products.forEach(product => {
        addToShoppingCart(this.shoppingCart, product.productId, product.quantity, this.activePpwRecId);
      })
      router.push('/bestellijst')
    },
    getVisibilityStatus () {
      let status = true
      for (let i = 0; i < this.products.length; i++) {
        if (!this.products[i].orderable) {
          status = false
          break;
        }
      }
      return status
    }
  }
}
</script>

<style lang="scss" scoped>
.reOrderButton {
  margin-top: 15px;
  float: right;
}
.address-wrapper {
  justify-content: space-between;
}
.orderDelivery {
  margin: 16px;
  font-size: 14px;
  color: $gray-dark;
}
.wrapper {
  padding: 0 16px;
}
.details-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f3f3f3;
  border-radius: 10px;
  :deep(tr) {
    > td, > th {
      padding: 1rem 0.5rem;
    }
    th:first-child, td:first-child {
      text-align: center;
      width: 80px;
    }
  }
}
</style>
