<template>
  <div>
    <div v-if="!isSubSearch">
      <section
        v-if="activeDepartment"
        ref="search"
        class="search"
        :class="isCollapsed"
        :style="{ height: searchHeight }"
      >
        <div class="container">
          <div class="row grid-margin grid-align-start">
            <div class="col">
              <legend>Patiënt zoeken</legend>
            </div>
          </div>
          <v-search-form
            ref="searchForm"
            :show-create-new-patient-button="noPatientFound && results.ToonKnopNieuwePatient"
            @show-search-alert="showSearchAlert"
            @form-validated="formValidated"
          />
          <div
            v-if="(noPatientFound && results.ToonKnopNieuwePatient) || showSearchAlertMessage"
            class="row grid-margin search__noPatienAlert"
            :class="{ 'pulse animated': animated }"
          >
            <v-warning-message
              :warning="computedWarningMessage"
              :html="false"
              :custom-link="getCustomLink()"
              @toggle="toggle"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <v-search-form
        ref="searchForm"
        :show-create-new-patient-button="noPatientFound && results.ToonKnopNieuwePatient"
        @show-search-alert="showSearchAlert"
        @form-validated="formValidated"
      />
      <div
        v-if="(noPatientFound && results.ToonKnopNieuwePatient) || showSearchAlertMessage"
        class="row grid-margin search__noPatienAlert"
        :class="{ 'pulse animated': animated }"
      >
        <v-warning-message
          :warning="computedWarningMessage"
          :html="false"
          :custom-link="getCustomLink()"
          @toggle="toggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { mapActions, useStore } from 'vuex';
import { computed, ref } from 'vue';
import VSearchForm from '@/components/sections/search/SearchForm';
import VWarningMessage from '@/components/shared/WarningMessage';
import { activeDepartment$ } from '@/services';
import { tap } from 'rxjs/operators';

export default {
  name: 'VSearch',
  components: {
    VSearchForm,
    VWarningMessage
  },
  props: {
    isSubSearch: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const activeDepartment = ref(null);

    const isCollapsed = computed(() => store.getters['search/getIsSearchCollapsed']);
    const isFormActive = computed(() => store.getters['search/getIsFormActive']);
    const noPatientFound = computed(() => store.getters['search/getNoPatientFound']);
    const patientFound = computed(() => store.getters['search/getPatientFound']);
    const searchfieldsObject = computed(() => store.getters['search/getSearchFields']);
    const results = computed(() => store.getters['search/getResults']);
    const isPostalcodeActive = computed(() => store.getters['search/getIsPostalcodeActive']);

    const setNoPatientFound = (value) => store.commit('search/setNoPatientFound', value);

    const initializeForDepartment = () => {
      store.dispatch('search/init');
      store.dispatch('search/resetSearch');
      store.dispatch('search/fetchWarning');
    };

    activeDepartment$.pipe(tap(initializeForDepartment)).subscribe((value) => {
      activeDepartment.value = value;
    });

    return {
      isCollapsed,
      isFormActive,
      noPatientFound,
      patientFound,
      searchfieldsObject,
      results,
      isPostalcodeActive,
      setNoPatientFound,
      activeDepartment,
      ...mapActions('search', ['init', 'resetSearch', 'fetchResults', 'searchAction', 'fetchWarning']),
      ...mapActions('login', ['logOut']),
      ...mapActions('patient', ['setEndpoints'])
    };
  },
  data() {
    return {
      searchHeight: 'auto',
      search: '',
      animated: false,
      showSearchAlertMessage: false
    };
  },
  computed: {
    computedWarningMessage() {
      if (this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.searchMyPatients)) {
        return 'Er is geen patiënt gevonden met het ingevoerde zoekwoord.';
      } else {
        return this.results.Teksten.Melding;
      }
    }
  },
  watch: {
    noPatientFound(value) {
      if (value) {
        this.animated = true;
        setTimeout(() => {
          this.animated = false;
        }, 1000);
      }
    },
    showSearchAlertMessage(value) {
      if (value) {
        this.animated = true;
        setTimeout(() => {
          this.animated = false;
        }, 1000);
      }
    }
  },
  methods: {
    showSearchAlert(status) {
      this.showSearchAlertMessage = status;
    },
    formValidated(elements) {
      this.searchAction(elements);
    },
    searchAgain() {
      this.resetSearch();
    },
    patientDashboard() {
      router.push('/patientdashboard');
    },
    patientDetails() {
      this.setEndpoints('new').then(() => {
        router.push('/patientgegevens');
      });
    },
    createNewPatient() {
      this.setEndpoints('new').then(() => {
        router.push('/patientgegevens');
      });
    },
    updateSearchHeight() {
      // fix the search height for the css transition
      this.searchHeight = this.$refs.search.clientHeight + 'px';
    },
    getCustomLink() {
      let customLink = {
        policyNumber: true,
        bsn: true,
        combined: true,
        postalCode: true
      };
      if (this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.searchMyPatients)) {
        customLink.combined = false;
      }
      if (this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.bsn)) {
        customLink.bsn = false;
        customLink.postalCode = false;
      }
      if (this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.policynumber)) {
        customLink.policyNumber = false;
      }
      if (this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.postalcode)) {
        customLink.postalCode = false;
        customLink.policyNumber = false;
      }
      return customLink;
    },
    toggle(searchkey) {
      this.setNoPatientFound(false);
      this.$refs.searchForm.setSearchMethod(this.$refs.searchForm.searchMethods[searchkey]);
    },
    getShowCreatePatientVisibilityStatus() {
      return !this.$refs.searchForm.isSearchMethod(this.$refs.searchForm.searchMethods.bsn);
    },
    demoValidated(elements) {
      this.createNewPatient();
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  &__view {
    padding: 0px;
  }
}

.search {
  background: $search-primary-background;
  transition: $search-transition;
  height: auto;
  width: 100%;
  legend {
    font-weight: bold;
    font-size: type-scale(5);
    color: $teal-darkest;
  }
  p {
    font-size: type-scale(0);
    line-height: line-height(0);
    color: $gray-darkest;
  }
  // stylling for the vue-search-select component
  :deep(.dropdown) {
    &.icon {
      display: none;
    }
    .menu {
      .item {
        font-size: $base-font-size;
        color: $teal-darkest;
      }
    }
    .text {
      font-style: italic;
      font-size: $base-font-size;
    }
    input.search {
      font-size: $base-font-size;
      line-height: 1.5rem;
      color: $gray-dark;
      padding-left: 10px;
    }
  }
  :deep(.selection) {
    &.active {
      &.dropdown {
        &:hover .menu {
          border-color: $white !important;
          box-shadow: none !important;
        }
        .menu {
          border-color: $white !important;
          box-shadow: none !important;
        }
      }
    }
    &.dropdown {
      input.search {
        font-size: $base-font-size;
        line-height: 1.5rem;
        color: $teal-darkest;
        padding-left: 10px;
      }
    }
  }
  @include min-screen(grid-breakpoint(md)) {
    height: $search-height;
  }
  &:not(.isCollapsed) {
    padding-top: 40px;
  }
  &.isCollapsed {
    height: $search-iscollapsed-height;
    * {
      opacity: 0;
    }
  }
  &__noPatienAlert {
    display: inline-flex;
    margin-bottom: 24px;
    :deep(.normal) {
      border-radius: 3px;
    }
  }
}
</style>
