<template>
  <div v-if="enablePrescriberChat" class="prescriberChat">
    <div v-if="showIframe" class="row">
      <div :class="{ 'fadeInUp animated': animateiframe }" class="close_icon" @click="hidePrescriberChatIframe()">
        X
      </div>
      <iframe
        :src="iframeURL"
        :height="330"
        :width="320"
        frameborder="1"
        class="iframe"
        :class="{ 'fadeInUp animated': animateiframe }"
      />
    </div>
    <div
      v-if="showTreatmentAreas"
      class="row treatmentAreaBlock"
      :class="{ 'fadeIn animated': animateTreatmentsStatus }"
    >
      <div class="col-md-12">
        <label class="heading inverted">Waar gaat de vraag over?</label>
      </div>
      <div class="row">
        <v-button
          v-for="treatmentArea in treatmentAreas"
          :key="treatmentArea"
          primary
          sm
          class="treatmentAreaBlock__btn"
          @click.prevent="setTreatmentArea(treatmentArea)"
        >
          {{ treatmentArea }}
        </v-button>
      </div>
    </div>

    <div
      v-if="showSkillAgentStatus"
      class="row treatmentAreaBlock"
      :class="{ 'fadeIn animated': animateSkillAgentStatus }"
    >
      <div class="col-md-12">
        <label class="heading inverted">{{ noSkillAgentMessage }}</label>
      </div>
    </div>

    <div @mouseleave="hideAvailability">
      <div v-if="showStatusMessage" class="row">
        <v-button
          class="status_popup"
          :class="{
            'fadeIn animated': animatechatstatus,
            'btn--disabled': !isChatActive,
            customAlignStatus: checkoutPageStatus
          }"
          @click.prevent="checkTreatmentAreas"
        >
          <span
            :class="{ status_popup__beforeHover: !popupContentHover, status_popup__afterHover: popupContentHover }"
            @mouseenter="getContentCss"
          >
            {{ chatStatusMessage }}
          </span>
        </v-button>
      </div>
      <div id="chat" class="row btnmascot" @mouseenter="showAvailability">
        <img v-if="isChatActive" src="@/static/img/chat_mascot_active.svg" />
        <img v-else src="@/static/img/chat_mascot_offline.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import { activeDepartment$ } from '@/services';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PrescriberChat',
  setup() {
    const store = useStore();
    const selectedDepartment = ref(null);

    const patientDetails = computed(() => store.getters['patient/getPatientGegevens']);
    const showIframe = computed(() => store.getters['prescriber/getPrescriberChatIframe']);
    const setPrescriberChatIframe = (value) => store.commit('prescriber/setPrescriberChatIframe', value);
    const fetchChatStatusMessage = () => store.dispatch('prescriber/fetchChatStatusMessage');

    activeDepartment$.subscribe((value) => {
      selectedDepartment.value = value;
    });

    return {
      patientDetails,
      showIframe,
      setPrescriberChatIframe,
      fetchChatStatusMessage,
      selectedDepartment
    };
  },
  data() {
    return {
      showStatusMessage: false,
      animatechatstatus: false,
      animateiframe: false,
      isChatActive: false,
      chatStatusMessage: '',
      iFrameHTML: '',
      iframeURL: '',
      popupContentHover: false,
      enablePrescriberChat: false,
      treatmentAreas: '',
      userExternalId: '',
      userEmail: '',
      userFullName: '',
      timeoutId: '',
      timeoutInMiliseconds: 10000,
      showTreatmentAreas: false,
      selectedTreatmentArea: '',
      animateTreatmentsStatus: false,
      showSkillAgentStatus: false,
      animateSkillAgentStatus: false,
      noSkillAgentMessage: '',
      checkoutPageStatus: false
    };
  },
  watch: {
    $route(to, from) {
      if (router.currentRoute.value.meta.checkout) {
        this.checkoutPageStatus = true;
      } else {
        this.checkoutPageStatus = false;
      }
    }
  },
  created() {
    this.fetchChatStatusMessage().then((response) => {
      if (response.data.isChatAvailable) {
        this.isChatActive = true;
        this.chatStatusMessage = response.data.activeChatMessage;
      } else {
        this.isChatActive = false;
        this.chatStatusMessage = response.data.inactiveChatMessage;
      }
      this.enablePrescriberChat = response.data.enablePrescriberChat;
      this.treatmentAreas = response.data.treatmentAreas;
      this.userExternalId = response.data.userExternalID;
      this.userEmail = response.data.userEmail;
      this.userFullName = response.data.userFullName;
      this.noSkillAgentMessage = response.data.noSkillAgentMessage;
    });
    if (this.showIframe) {
      this.showPrescriberChatIframe();
    }
    this.setupTimers();
  },
  methods: {
    getContentCss() {
      if (this.animatechatstatus === false) {
        this.popupContentHover = true;
      }
    },
    startTimer() {
      this.timeoutId = setTimeout(this.addShakeClass, this.timeoutInMiliseconds);
    },
    addShakeClass() {
      const element = document.getElementById('chat');
      if (element) {
        element.classList.add('chatShake');
      }
    },
    setupTimers() {
      document.addEventListener('scroll', this.resetTimer, false);
      document.addEventListener('mousedown', this.resetTimer, false);
      document.addEventListener('keypress', this.resetTimer, false);
      document.addEventListener('touchmove', this.resetTimer, false);
      if (this.isChatActive) {
        this.startTimer();
      }
    },
    resetTimer() {
      window.clearTimeout(this.timeoutId);
      if (this.isChatActive) {
        this.startTimer();
      }
    },
    showAvailability() {
      if (!this.showStatusMessage && !this.showIframe && !this.showTreatmentAreas) {
        this.animatechatstatus = true;
        this.showStatusMessage = true;
        setTimeout(() => {
          this.animatechatstatus = false;
        }, 1000);
      }
    },
    hideAvailability() {
      if (this.showStatusMessage) {
        setTimeout(() => {
          this.animatechatstatus = false;
          this.showStatusMessage = false;
        }, 3000);
      }
    },
    checkTreatmentAreas() {
      this.showStatusMessage = false;
      if (this.treatmentAreas.length > 1) {
        if (!this.showTreatmentAreas && !this.showIframe && !this.showStatusMessage) {
          this.animateTreatmentsStatus = true;
          this.showTreatmentAreas = true;
          setTimeout(() => {
            this.animateTreatmentsStatus = false;
            this.showTreatmentAreas = false;
          }, 5000);
        }
        return;
      } else {
        this.selectedTreatmentArea = this.treatmentAreas[0];
        this.setTreatmentArea(this.selectedTreatmentArea);
      }
    },
    setTreatmentArea(treatmentArea) {
      this.selectedTreatmentArea = treatmentArea;
      this.showTreatmentAreas = false;
      axios.get('Chat/IsSkillAgentAvailable?treatmentArea=' + this.selectedTreatmentArea).then((response) => {
        if (response.data.SkillAgentAvailable) {
          this.showPrescriberChatIframe();
        } else {
          this.animateSkillAgentStatus = true;
          this.showSkillAgentStatus = true;
          setTimeout(() => {
            this.animateSkillAgentStatus = false;
            this.showSkillAgentStatus = false;
          }, 5000);
        }
      });
    },
    showPrescriberChatIframe() {
      if (this.animatechatstatus === false) {
        if (this.patientDetails.ExternalId !== undefined) {
          this.iframeURL =
            'https://wechat.mediq.com/directstart/WC_Voeding/nl-nl?prescriberId=' +
            encodeURIComponent(this.userExternalId) +
            '&patientId=' +
            encodeURIComponent(this.patientDetails.ExternalId) +
            '&departmenId=' +
            encodeURIComponent(this.selectedDepartment.ppwRecId.split('-')[1]) +
            '&email=' +
            encodeURIComponent(this.userEmail) +
            '&name=U:&naam-voorschrijver=' +
            encodeURIComponent(this.userFullName) +
            '&treatmentarea=' +
            this.selectedTreatmentArea;
        } else if (this.selectedDepartment) {
          this.iframeURL =
            'https://wechat.mediq.com/directstart/WC_Voeding/nl-nl?prescriberId=' +
            encodeURIComponent(this.userExternalId) +
            '&patientId=null&departmenId=' +
            encodeURIComponent(this.selectedDepartment.ppwRecId.split('-')[1]) +
            '&email=' +
            encodeURIComponent(this.userEmail) +
            '&name=U:&naam-voorschrijver=' +
            encodeURIComponent(this.userFullName) +
            '&treatmentarea=' +
            this.selectedTreatmentArea;
        }
        if (!this.iframeURL) {
          this.setPrescriberChatIframe(false);
          return;
        }
        if (this.isChatActive) {
          this.animateiframe = true;
          this.setPrescriberChatIframe(true);
          setTimeout(() => {
            this.animateiframe = false;
          }, 1000);
        }
      }
    },
    hidePrescriberChatIframe() {
      this.setPrescriberChatIframe(false);
    }
  }
};
</script>
<style lang="scss" scoped>
.btnmascot {
  border: none;
  padding: 0 30px 0 0;
  right: 1%;
  bottom: 5%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  img {
    width: 80px;
  }
}
label {
  margin-bottom: 20px;
}
.treatmentAreaBlock {
  background-color: $aqua;
  border: none;
  border-radius: 15px;
  background-color: $aqua;
  margin-right: 115px;
  padding: 25px;
  position: fixed;
  right: 0%;
  bottom: 4%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  color: $white;
  max-width: 230px;

  &__btn {
    margin: 10px 15px 10px 0px;
    border-radius: 15px;
  }
  :after {
    content: '';
    position: absolute;
    top: 65%;
    right: 0%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: $aqua;
    border-right: 0;
    margin-top: -5px;
    margin-right: -15px;
  }
}
.status_popup {
  border: none;
  border-radius: 30px;
  background-color: $aqua;
  margin-right: 115px;
  padding: 10px 15px 10px 15px;
  position: fixed;
  right: 0%;
  bottom: 6%;
  color: $teal-darkest;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-left-color: $aqua;
    border-right: 0;
    margin-top: -9px;
    margin-right: -6px;
  }
}

.iframe {
  border: none;
  width: 420px;
  margin: 0px 10px 20px 0px;
  border-radius: 3px;
}
.close_icon {
  color: $cyan-mega-dark;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 15px;
}

@keyframes chatShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.chatShake {
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  animation-name: chatShake;
}
.customAlignStatus {
  bottom: 13%;
}
</style>
