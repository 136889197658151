import { from } from 'rxjs';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { fetchQuery } from '@/services/messaging/fetchQuery';
import { currentUserIdentity$ } from '@/services/authentication/currentUserIdentity$';

function getEquipmentTexts() {
  return fetchQuery("GetEquipmentTexts", {});
}

export const equipmentTexts$ = currentUserIdentity$.pipe(
  switchMap(x => from(getEquipmentTexts())),
  publishReplay(1),
  refCount()
);
