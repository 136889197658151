<template>
  <section class="prescriberRegistrationSuccess">
    <!-- Header section -->
    <div class="row prescriberRegistrationSuccess__header">
      <div class="prescriberRegistrationSuccess__logo">
        <img :src="portalLogo" alt="Mediq logo" />
      </div>
      <div class="prescriberRegistrationSuccess__title">Registratie</div>
    </div>
    <div v-if="animated" class="row grid-align-center" :class="{ 'flipInX animated': animated }">
      <div class="col-md-12 prescriberRegistrationSuccess__successMessage">
        <v-check-circle-icon class="prescriberRegistrationSuccess__successMessage__icon" />
        <span>Aanvraag van uw registratie is afgerond</span>
      </div>
    </div>
    <!-- Success message section -->
    <div class="row grid-align-center">
      <div class="col-md-6 prescriberRegistrationSuccess__block">
        <div class="prescriberRegistrationSuccess__block__header">
          <div class="prescriberRegistrationSuccess__block__header__details">
            Hallo {{ prescriberRegistrationDetails.initials }} {{ prescriberRegistrationDetails.prePosition }}
            {{ prescriberRegistrationDetails.lastName }}!
          </div>
          <div class="prescriberRegistrationSuccess__block__header__subDetails">Bedankt voor het inschrijven.</div>
        </div>
        <div class="prescriberRegistrationSuccess__block__content">
          <div class="prescriberRegistrationSuccess__block__content__details">
            <div class="col-xs-2 prescriberRegistrationSuccess__block__content__details__imgHolder">
              <img src="@/static/img/monitor-eye.svg" />
            </div>
            <div class="col-xs-10">
              <span v-if="prescriberRegistrationSuccessText.FinalPage">{{
                prescriberRegistrationSuccessText.FinalPage.FinalPageText1
              }}</span>
            </div>
          </div>
          <div class="prescriberRegistrationSuccess__block__content__details">
            <div class="col-xs-2 prescriberRegistrationSuccess__block__content__details__imgHolder">
              <img src="@/static/img/email-check.svg" />
            </div>
            <div class="col-xs-10">
              <span v-if="prescriberRegistrationSuccessText.FinalPage">{{
                prescriberRegistrationSuccessText.FinalPage.FinalPageText2
              }}</span>
            </div>
          </div>
          <div class="prescriberRegistrationSuccess__block__content__details">
            <div class="col-xs-2 prescriberRegistrationSuccess__block__content__details__imgHolder">
              <img src="@/static/img/file-edit.svg" />
            </div>
            <div class="col-xs-10">
              <span v-if="prescriberRegistrationSuccessText.FinalPage">{{
                prescriberRegistrationSuccessText.FinalPage.FinalPageText3
              }}</span>
            </div>
          </div>
          <div class="prescriberRegistrationSuccess__block__content__details">
            <legend
              v-if="prescriberRegistrationSuccessText.FinalPage"
              v-html="prescriberRegistrationSuccessText.FinalPage.FinalPageText4"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationSuccess__actions">
      <v-button lg hollow class="prescriberRegistrationSuccess__actions__previous" @click.prevent="goToMediq()">
        NAAR MEDIQ.NL
      </v-button>
      <v-button lg cta class="prescriberRegistrationSuccess__actions__demo" @click.prevent="goToSpecificPortal()">
        Demo
      </v-button>
    </div>
    <!--Success Image section -->
    <div class="row grid-align-center">
      <div class="col-md-9" />
      <div v-if="animated" class="col-md-3 prescriberRegistrationSuccess__successImage">
        <img src="@/static/img/mascotSuccess.svg" :class="{ 'slideInUp animated': animated }" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, useStore } from 'vuex';
import { computed } from 'vue';
import VCheckCircleIcon from 'icons/CheckCircle';
import router from '@/router';

export default {
  name: 'VPrescriberRegistrationSuccess',
  components: {
    VCheckCircleIcon
  },
  setup() {
    const store = useStore();

    const portalLogo = computed(() => store.getters['navigation/getPortalLogo']);
    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSuccessText = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );
    const registrationSuccessStatus = computed(
      () => store.getters['prescriberRegistration/getRegistrationSuccessStatus']
    );

    const resetPresciberRegistrationDetails = () =>
      store.commit('prescriberRegistration/resetPresciberRegistrationDetails');

    return {
      portalLogo,
      prescriberRegistrationDetails,
      prescriberRegistrationSuccessText,
      registrationSuccessStatus,
      resetPresciberRegistrationDetails,
      ...mapActions('navigation', ['fetchPortalLogo']),
      ...mapActions('prescriberRegistration', ['fetchRegistrationTexts'])
    };
  },
  data() {
    return {
      animated: false
    };
  },
  created() {
    this.fetchPortalLogo();
    this.fetchRegistrationTexts();
    if (!this.registrationSuccessStatus) {
      router.push('/registreren');
    }
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      this.animated = true;
    }, 2000);
    window.dataLayer.push({ event: 'prescriberRegistration', details: 'registrerensucces' });
  },
  unmounted() {
    this.resetPresciberRegistrationDetails();
  },
  methods: {
    goToMediq() {
      window.open('https://www.mediq.nl', '_self');
    },
    goToSpecificPortal() {
      window.open('https://voorschrijver.mediq.nl/', '_self');
    }
  }
};
</script>
<style lang="scss" scoped>
.prescriberRegistrationSuccess {
  position: absolute;
  width: 100%;
  background-color: $teal-lightest;
  min-height: 700px;
  color: $gray-darkest;
  &__logo {
    @include min-screen(grid-breakpoint(md)) {
      margin: 25px 0px 25px 50px;
    }
    img {
      display: block;
      max-height: 60px;
      margin: 0 auto;
      width: 161px;
      @include min-screen(grid-breakpoint(md)) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__header {
    background: $white;
  }
  &__progressSteps {
    margin: 30px 0px 30px 0px;
  }
  &__title {
    line-height: 5;
    padding: 10px;
    color: $gray-darkest;
    font-size: $base-font-size;
    font-weight: $heading-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 50px auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $gray-darkest;
      padding: 20px;
      text-align: center;
      &__details {
        font-size: 30px;
        padding: 20px;
      }
      &__subDetails {
        font-size: 18px;
        padding: 10px;
      }
    }
    &__content {
      padding: 40px 60px 40px 60px;
      &__details {
        color: $teal-darkest;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        padding: 15px;
        &__imgHolder {
          border: 2px solid $teal-darkest;
          border-radius: 24px;
          height: 45px;
          width: 45px;
          float: left;
          margin: -3px 25px 0px 0px;
          img {
            margin: 0px 10px -5px 0px;
            fill: $teal-darkest;
          }
        }
        img {
          margin: 10px 10px -8px 9px;
          fill: $teal-darkest;
        }
        span {
          margin: 0px;
        }
        legend {
          color: $gray-darkest;
          margin-top: 20px;
        }
      }
    }
  }
  &__actions {
    float: right;
    margin: 1% 27% 11% 0;
    &__previous {
      margin-right: 30px;
    }
    &__demo {
      display: none;
    }
  }
  &__successMessage {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 50px 0px 0px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      vertical-align: middle;
      :deep(svg) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__successImage {
    img {
      position: absolute;
      height: 500px;
      right: 14%;
      bottom: 0%;
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
}
</style>
