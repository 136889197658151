<template>
  <section class="container prescriber-details">
    <div class="row">
      <div class="col-xs-12 show">
        <v-heading :level="1"> Mijn gegevens </v-heading>
      </div>
      <div class="col-xs-12 show">
        <v-heading :level="3"> Persoonlijke gegevens </v-heading>
        <legend>Klik op de knop "Gegevens wijzigen" om uw eigen gegevens aan te passen</legend>
        <button @click.prevent="$router.push('/gegevenswijzigen')" class="btn btn--secondary btn--md updateProfile">
          Gegevens wijzigen
        </button>
      </div>
      <div class="col-xs-12 show">
        <v-heading :level="3"> Wachtwoord wijzigen </v-heading>
        <legend>
          Klik op de knop "Wachtwoord wijzigen" om uw wachtwoord opnieuw in te stellen. Voor veiligheidredenen moet u na
          het wijzigen van uw wachtwoord opnieuw inloggen.
        </legend>
        <button @click.prevent="$router.push('/changepassword')" class="btn btn--secondary btn--md updateProfile">
          Wachtwoord wijzigen
        </button>
      </div>
      <div
        v-if="
          twoFactorAuthenticationSettingsConfiguration &&
          currentUserIdentity &&
          currentUserIdentity.twoFactorAuthentication.enabled === false
        "
        class="col-xs-12 show"
      >
        <v-heading :level="3">
          {{ twoFactorAuthenticationSettingsConfiguration.title }}
        </v-heading>
        <legend>{{ twoFactorAuthenticationSettingsConfiguration.enable2FaDescription }}</legend>
        <button class="btn btn--secondary btn--md updateProfile" @click="openEnableAuthenticationModal = true">
          {{ twoFactorAuthenticationSettingsConfiguration.enable2FaButtonLabel }}
        </button>
      </div>
      <div
        v-if="
          twoFactorAuthenticationSettingsConfiguration &&
          currentUserIdentity &&
          currentUserIdentity.twoFactorAuthentication.enabled === true
        "
        class="col-xs-12 show"
      >
        <v-heading :level="3">
          {{ twoFactorAuthenticationSettingsConfiguration.title }}
        </v-heading>
        <legend>{{ twoFactorAuthenticationSettingsConfiguration.disable2FaDescription }}</legend>
        <button class="btn btn--secondary btn--md updateProfile" @click="openDisableAuthenticationModal = true">
          {{ twoFactorAuthenticationSettingsConfiguration.disable2FaButtonLabel }}
        </button>
      </div>
      <div class="col-xs-12 show">
        <v-heading :level="3"> Behandelgebieden </v-heading>
        <legend>Klik op de knop "Wijzigen" om uw behandelgebieden aan te passen</legend>
        <button class="btn btn--secondary btn--md updateProfile" @click="showTreatmentAreaDetails()">Wijzigen</button>
      </div>
      <table class="table">
        <colgroup>
          <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <fieldset
                v-for="(notification, notificationDetail) in prescriberPreferences"
                :key="notificationDetail"
                :data-index="notificationDetail"
              >
                <legend>{{ notification.text }}</legend>
                <div v-for="(option, index) in notification.options" :key="index" :data-index="index">
                  <div class="form-group__radio">
                    <input
                      :id="option.text + notification.id"
                      :checked="option.selected"
                      :name="notification.id"
                      :value="getValue(index)"
                      type="radio"
                      @change="checkRadioAnswer"
                    />
                    <label :for="option.text + notification.id"><span></span>{{ option.text }}</label>
                  </div>
                </div>
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
      <prescriber-enable-auth-modal
        v-if="openEnableAuthenticationModal"
        @close-authentication-modal="closeAuthenticationModal"
      />
      <prescriber-disable-auth-modal
        v-if="openDisableAuthenticationModal"
        @close-authentication-modal="closeAuthenticationModal"
      />
      <generic-modal v-if="showTreatmentAreas" :has-close-button="true" @close="closeTreatmentAreas">
        <template v-if="!showSuccessMessage" #header> Behandelgebieden wijzigen </template>
        <template v-else #header> Wijziging succesvol </template>
        <template #body>
          <p v-if="!showSuccessMessage">Hieronder kunt u behandelgebieden toevoegen of verwijderen.</p>
          <div v-if="showActiveTreatments">
            <h5>Overzicht behandelgebieden</h5>

            <div v-for="(treatmentArea, index) in treatmentAreas" :key="index">
              <v-button v-if="treatmentArea.active" md hollow class="prescriber-details__buttons">
                {{ treatmentArea.description }}
              </v-button>
            </div>
            <v-button md hollow class="prescriber-details__buttons" @click="addNewTreatments()">
              <v-plus-icon />Behandelgebieden toevoegen
            </v-button>
          </div>
          <div v-if="addTreatments">
            <h5>Selecteer behandelgebied</h5>

            <div class="row grid-margin">
              <div v-for="(treatmentArea, pos) in treatmentAreas" :key="pos" class="col-md-6">
                <v-checkbox-group
                  v-if="pos % 2 === 0"
                  v-model="treatmentArea.active"
                  :label="treatmentArea.description"
                  :inputid="'treatmentAreaLeft' + pos"
                  :initvalue="treatmentArea.active"
                  :overwrite-value="treatmentArea.active"
                />
                <v-checkbox-group
                  v-if="pos % 2 !== 0"
                  v-model="treatmentArea.active"
                  :label="treatmentArea.description"
                  :inputid="'treatmentAreaRight' + pos"
                  :initvalue="treatmentArea.active"
                  :overwrite-value="treatmentArea.active"
                />
              </div>
            </div>
          </div>
          <div v-if="showSuccessMessage">
            <p>{{ successMessage }}</p>
          </div>
        </template>
        <template #footer>
          <v-button v-if="showActiveTreatments" md primary @click="closeTreatmentAreas()"> Sluiten </v-button>
          <v-button v-if="addTreatments" md hollow transparent @click="closeTreatmentAreas()"> Annuleren </v-button>
          <v-button v-if="addTreatments" md primary @click="updateTreatmentAreas()"> Opslaan </v-button>
          <v-button v-if="showSuccessMessage" md primary @click="closeTreatmentAreas()"> Ok </v-button>
        </template>
      </generic-modal>
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import PrescriberEnableAuthModal from './PrescriberEnableAuthModal';
import PrescriberDisableAuthModal from './PrescriberDisableAuthModal';
import GenericModal from '@/components/shared/GenericModal';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VPlusIcon from 'icons/PlusCircle';
import { updateTreatmentAreas } from '@/services/treatmentAreas/updateTreatmentAreas';
import { getTreatmentAreaConfiguration } from '@/services/treatmentAreas/getTreatmentAreaConfiguration';
import { currentUserIdentity$, twoFactorAuthenticationSettingsConfiguration$, allTreatmentAreas$ } from '@/services';

export default {
  name: 'PrescriberDetails',
  components: {
    PrescriberEnableAuthModal,
    PrescriberDisableAuthModal,
    GenericModal,
    VCheckboxGroup,
    VPlusIcon
  },
  setup() {
    const store = useStore();
    const currentUserIdentity = ref(null);
    const twoFactorAuthenticationSettingsConfiguration = ref(null);
    const allTreatmentAreas = ref(null);

    const prescriberPreferences = computed(() => store.getters['prescriber/getPrescriberPreferences']);
    const fetchPrescriberPreferences = () => store.dispatch('prescriber/fetchPrescriberPreferences');
    const updatePrescriberPreferences = (value) => store.dispatch('prescriber/UpdatePrescriberPreferences', value);

    currentUserIdentity$.subscribe((value) => (currentUserIdentity.value = value));
    twoFactorAuthenticationSettingsConfiguration$.subscribe(
      (value) => (twoFactorAuthenticationSettingsConfiguration.value = value)
    );
    allTreatmentAreas$.subscribe((value) => (allTreatmentAreas.value = value));

    return {
      prescriberPreferences,
      fetchPrescriberPreferences,
      updatePrescriberPreferences,
      currentUserIdentity,
      twoFactorAuthenticationSettingsConfiguration,
      allTreatmentAreas
    };
  },
  data() {
    return {
      openEnableAuthenticationModal: false,
      openDisableAuthenticationModal: false,
      showTreatmentAreas: false,
      showActiveTreatments: false,
      addTreatments: false,
      showSuccessMessage: false,
      successMessage: ''
    };
  },
  computed: {
    userTreatmentAreaIds() {
      return this.currentUserIdentity.allTreatmentAreas.map((m) => m.treatmentAreaId);
    },
    treatmentAreas() {
      const utas = this.userTreatmentAreaIds;
      return this.allTreatmentAreas.map((m) => {
        return {
          ...m,
          active: utas.includes(m.id)
        };
      });
    }
  },
  created() {
    this.fetchPrescriberPreferences();
  },
  methods: {
    checkRadioAnswer(e) {
      const details = {
        id: parseInt(e.currentTarget.name),
        value: e.currentTarget.value
      };
      this.updatePrescriberPreferences(details);
    },
    closeAuthenticationModal() {
      this.openDisableAuthenticationModal = false;
      this.openEnableAuthenticationModal = false;
    },
    getValue(index) {
      return index !== 0;
    },
    showTreatmentAreaDetails() {
      this.showTreatmentAreas = true;
      this.showActiveTreatments = true;
      this.addTreatments = false;
      this.showSuccessMessage = false;
    },
    closeTreatmentAreas() {
      this.showTreatmentAreas = false;
    },
    updateTreatmentAreas() {
      updateTreatmentAreas({ UserTreatmentArea: this.treatmentAreas }).then(() => {
        getTreatmentAreaConfiguration({}).then((response) => {
          this.addTreatments = false;
          this.showSuccessMessage = true;
          this.successMessage = response.successMessage;
        });
      });
    },
    addNewTreatments() {
      this.showActiveTreatments = false;
      this.addTreatments = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriber-details {
  :deep(.checkbox-group__icon) {
    margin-top: 10px;
  }
  :deep(.checkbox-group__label__inner) {
    border: 1px solid $teal-darkest;
    padding: 10px;
    border-radius: 3px;
    width: 150px;
    text-align: center;
  }
  :deep(.checkbox-group .checkbox-group__checkbox + .checkbox-group__label > span),
  :deep(.checkbox-group .checkbox-group__checkbox:checked + .checkbox-group__label > span) {
    margin: 10px 10px 0px 0px;
  }
  &__buttons {
    margin-bottom: 20px;
  }
  .material-design-icon {
    margin-right: 15px;
  }
  :deep(.generic-modal) {
    max-height: 600px;
  }
  :deep(.form-group__radio input[type='radio'] + label) {
    color: $gray-dark;
    font-size: 16px;
    > span > span {
      display: none;
    }
  }
  .show {
    display: inline !important;
  }
  h3 {
    margin-top: 25px !important;
  }
  .heading {
    margin-bottom: 10px;
  }
  legend {
    font-weight: bold;
    line-height: 1.4;
    font-weight: 900;
    font-size: 18px;
    color: $teal-darkest;
  }
  .updateProfile {
    margin: 15px 15px 15px 0px;
  }
  // Width of each table column
  $table-columns: (
    1: 40px,
    2: 30%,
    3: 30%,
    4: 15%,
    5: 15%,
    6: 50px
  ) !default;
  table {
    table-layout: auto !important;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: left;

    // Generate classes for each column and assign width
    @each $table-column-key, $table-column-value in $table-columns {
      .table-col-#{$table-column-key} {
        width: $table-column-value;
      }
    }

    > thead > tr {
      background-color: rgba(0, 0, 0, 0.03);

      > td,
      > th {
        vertical-align: baseline;
        text-align: left;
        padding: 10px;
        color: $gray-darkest;
        font-size: type-scale(0);
        line-height: line-height(0);
        font-weight: normal;
      }
    }

    > tbody {
      > tr:first-child {
        > td,
        > th {
          vertical-align: baseline;
          text-align: left;
          padding: 10px 10px 10px 0px;
        }
      }

      > tr:last-child td {
        padding: 10px 10px 10px 0px;
      }

      &.action-required {
        background-color: $pink;
      }
    }
    .hidden {
      display: none;
    }
    fieldset {
      margin: 0;
      padding: 6px 0 20px;
      border: 0;

      div {
        display: inline-block;
        margin: 5px 20px 0px 5px;
      }

      legend {
        font-weight: bold;
        line-height: 1.4;
        font-weight: 900;
        font-size: 18px;
        color: $teal-darkest;
      }
    }
  }
}
</style>
