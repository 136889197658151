import axios from 'axios';
import { refreshShoppingCart } from './shoppingCartRefreshRequested$';

export function addBundlesToShoppingCart(shoppingCart, bundleIds) {
  if (shoppingCart === null) {
    throw 'ShoppingCart is empty';
  }

  var command = {
    customerId: shoppingCart.customerId,
    bundleIds: bundleIds
  };

  return axios.post('ShoppingCart/AddBundlesToShoppingCart', command).then((response) => {
    refreshShoppingCart();
    return response;
  });
}
