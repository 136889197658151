import { getFromWebAPi } from '@/services/messaging/fetchQuery';

export function getOsaOverviewDetails(osaDetails) {
  return getFromWebAPi("osa/GetOsaDetail?osaType=" + osaDetails.osaType + "&id=" + osaDetails.osaId + "&licenseId=" + osaDetails.licenseId).then((response) => {
    if (response && response.Success) {
      if (osaDetails.osaType === 'Evaluation') {
        return response.OsaEvaluationDetail
      } else if (osaDetails.osaType === 'Readout') {
        return response.OsaReadoutDetail
      } else {
        return response.OsaEvaluationSptDetail
      }
    }
  })
};
