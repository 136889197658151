<template>
  <img
    v-if="signature"
    :src="'data:image/jpg;base64,' + signature"
    alt="Handtekening"
  >
</template>

<script>
import { startWith, switchMap } from "rxjs/operators";
import { getUserSignature } from "@/services";
import { ref, watchEffect, onUnmounted } from "vue";
import { Subject, from } from 'rxjs';

export default {
  name: 'PrescriberSignature',
  props: {
    userId: Number
  },
  setup(props) {
    const signature = ref(null);
    const userIdSubject = new Subject();

    userIdSubject
      .pipe(
        startWith(props.userId),
        switchMap(value => from(getUserSignature(value)))
      )
      .subscribe({
        next: (result) => {
          signature.value = result;
        },
        error: (error) => {
        }
      });

    watchEffect(() => {
      userIdSubject.next(props.userId);
    });

    onUnmounted(() => {
      userIdSubject.complete();
    });

    return {
      signature
    };
  }
}
</script>

<style lang="scss" scoped>

</style>
