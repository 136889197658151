<template>
  <section class="container new-authorization">
    <div id="step1" class="row grid-margin">
      <div class="col-xs-6">
        <div v-for="(templateDetails, index) in evenIndexTemplates" :key="index">
          <table class="table">
            <thead class="head">
              <tr>
                <th>{{ templateDetails.TreatmentAreaDescription }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(template, index) in templateDetails.Templates" :key="index">
                <td @click="goToCreateAccountability(template.TemplateId)">{{ template.Naam }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-xs-6">
        <div v-for="(templateDetails, index) in oddIndexTemplates" :key="index">
          <table class="table">
            <colgroup>
              <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
            </colgroup>
            <thead class="head">
              <tr>
                <th>{{ templateDetails.TreatmentAreaDescription }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(template, index) in templateDetails.Templates" :key="index">
                <td @click="goToCreateAccountability(template.TemplateId)">{{ template.Naam }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Pop up to show stop license -->
    <generic-modal v-if="showPopUp">
      <template #header>
        {{ popUpText.header }}
      </template>
      <template #body>
        <p>{{ popUpText.text }}</p>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md transparent class="cancelPopUp" @click="backToDashboard()">
          Annuleren
        </v-button>
        <v-button tagelement="button" md primary @click="closeLicense()"> Doorgaan </v-button>
      </template>
    </generic-modal>
  </section>
</template>
<script>
import router from '@/router';
import GenericModal from '@/components/shared/GenericModal';
import { navigateToAccountability } from '@/services';

import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted } from 'vue';

export default {
  name: 'VNewauthorization',
  components: { GenericModal },
  setup() {
    const store = useStore();

    const treatmentWiseTemplates = computed(
      () => store.getters['accountability/getTreatmentAreaWiseAccountabilityTemplates']
    );
    const evenIndexTemplates = computed(() => treatmentWiseTemplates.value.filter((_, index) => index % 2 === 0));
    const oddIndexTemplates = computed(() => treatmentWiseTemplates.value.filter((_, index) => index % 2 !== 0));

    const showPopUp = computed(() => store.getters['accountability/getLicenseStopPopUpStatus']);
    const popUpText = computed(() => store.getters['login/getCloseLicensePopUpDetails']);

    const resetTreatmentAreaWiseTemplates = (value) =>
      store.commit('accountability/resetTreatmentAreaWiseTemplates', value);
    const setAccountabilities = (value) => store.commit('accountability/setAccountabilities', value);
    const setShowExtendLicensesBlock = (value) => store.commit('accountability/setShowExtendLicensesBlock', value);
    const startNewAccountability = (value) => store.dispatch('accountability/startNewAccountability', value);
    const setLicenseStopPopUpStatus = (value) => store.commit('accountability/setLicenseStopPopUpStatus', value);

    const getCurrentPatient = async () => await store.dispatch('patient/getCurrentPatient');
    const fetchAccountabilities = async () => await store.dispatch('accountability/fetchAccountabilities');

    onMounted(async () => {
      await getCurrentPatient();
      await fetchAccountabilities();
    });

    onUnmounted(() => {
      resetTreatmentAreaWiseTemplates();
      setAccountabilities([]);
    });
    const goToCreateAccountability = (id) => {
      setShowExtendLicensesBlock(false);
      startNewAccountability(id).then((response) => {
        navigateToAccountability(response.regular.accountabilityId);
      });
    };

    const backToDashboard = () => {
      router.push('/patientdashboard');
      setLicenseStopPopUpStatus(false);
    };

    const closeLicense = () => {
      setLicenseStopPopUpStatus(false);
    };

    return {
      treatmentWiseTemplates,
      evenIndexTemplates,
      oddIndexTemplates,
      showPopUp,
      popUpText,
      resetTreatmentAreaWiseTemplates,
      setAccountabilities,
      setShowExtendLicensesBlock,
      startNewAccountability,
      setLicenseStopPopUpStatus,
      getCurrentPatient,
      fetchAccountabilities,
      goToCreateAccountability,
      backToDashboard,
      closeLicense
    };
  }
};
</script>
<style lang="scss" scoped>
.new-authorization {
  table {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-bottom: 24px;
    > thead > tr {
      > th {
        font-size: map-get(map-get($type-settings, 2), font-size);
        color: $teal-darkest;
        text-align: left;
        padding: 16px;
      }
    }
    > tbody {
      > tr {
        > td,
        > th {
          padding: 8px 16px;
          cursor: pointer;
          line-height: $base-line-height;
          color: $gray-darkest;
        }
      }
    }
  }
}
</style>
