<template>
  <v-overlay v-if="overlayActive">
    <div class="todoEmailNotification">
      <div class="row grid-margin">
        <div class="col-xs-6 todoEmailNotification__header">Berichten ontvangen</div>
        <div class="col-xs-6">
          <a @click="closeOverlay"><v-close-icon class="todoEmailNotification__closeIcon" /></a>
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-xs-12 todoEmailNotification__text">
          Geef aan of u de berichten dagelijks of wekelijks wenst te ontvangen op het aangegeven e-mailadres.
        </div>
      </div>
      <div class="row grid-margin">
        <div class="col-xs-12">
          <div class="form-group__radio">
            <input
              id="dailyNotification"
              name="todoNotification"
              :checked="emailNotificationByDept.Wanttoreceiveemails === 2"
              type="radio"
              @change="notificationOption = 2"
            />
            <label for="dailyNotification"><span></span>Dagelijks</label>
          </div>
          <div class="form-group__radio">
            <input
              id="weeklyNotification"
              name="todoNotification"
              :checked="emailNotificationByDept.Wanttoreceiveemails === 1"
              type="radio"
              @change="notificationOption = 1"
            />
            <label for="weeklyNotification"><span></span>Wekelijks</label>
          </div>
        </div>
      </div>
      <div class="row grid-margin">
        <a class="todoEmailNotification__leftButton" @click="closeOverlay">Annuleren</a>
        <v-button
          class="btn btn--primary btn--lg todoEmailNotification__rightButton"
          :disabled="notificationOption === 0"
          @click="updateTodoEmailNotification()"
        >
          Opslaan
        </v-button>
      </div>
    </div>
  </v-overlay>
</template>

<script>
import VOverlay from '@/components/shared/Overlay.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import VCloseIcon from 'icons/Close';

export default {
  name: 'TodoEmailNotification',
  components: {
    VOverlay,
    VCloseIcon
  },
  setup() {
    const store = useStore();

    const emailNotificationByDept = computed(() => store.getters['prescriber/getEmailNotificationByDept']);
    const setEmailNotificationByDept = (value) => store.commit('prescriber/setEmailNotificationByDept', value);

    return {
      emailNotificationByDept,
      setEmailNotificationByDept
    };
  },
  data() {
    return {
      overlayActive: false,
      notificationOption: 0
    };
  },
  created() {
    this.$emitter.on('showTodoEmailNotification', () => this.showOverlay());
  },
  methods: {
    showOverlay() {
      this.overlayActive = true;
    },
    closeOverlay() {
      this.overlayActive = false;
      this.notificationOption = 0;
    },
    updateTodoEmailNotification() {
      this.setEmailNotificationByDept(this.notificationOption);
      this.closeOverlay();
    }
  }
};
</script>

<style lang="scss" scoped>
.todoEmailNotification {
  &__header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  &__text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  &__closeIcon {
    float: right;
    margin: -9px;
    cursor: pointer;
    fill: black;
    :deep(svg) {
      width: 32px !important;
      height: 32px !important;
    }
  }

  &__leftButton {
    text-decoration: none;
    font-weight: bold;
    color: #006983;
    margin-top: 15px;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 53%;
  }

  &__rightButton {
    position: relative;
    top: 0;
    left: 58%;
  }
}
</style>
