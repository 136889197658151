<template>
  <section class="container bundleProducts">
    <div class="row grid-margin">
      <v-button md primary class="bundleProducts__backButton" @click.prevent="goToCatalog()">
        <v-arrow-left-icon /> <span class="bundleProducts__text">Naar catalogus</span>
      </v-button>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12">
        <legend class="bundleProducts__title">
          {{ bundleDetails.bundleName }}
        </legend>
        <legend class="bundleProducts__subtitle">
          {{ bundleDetails.bundleDescription }}
        </legend>
      </div>
    </div>
    <div v-if="bundleDetails && bundleDetails.products && bundleDetails.products.length > 0" class="row grid-margin">
      <div class="col-md-12">
        <v-product-variants :product-variants="bundleDetails.products" :is-bundle="true" />
      </div>
    </div>
    <div class="bundleProducts__note">
      {{ bundleDetails.bundleNote }}
    </div>
    <div class="row grid-margin bundleProducts__buttons">
      <v-button md cta class="bundleProducts__orderButton" @click.prevent="orderBundleProducts">
        Bestelpakket bestellen
      </v-button>
    </div>
  </section>
</template>

<script>
import VArrowLeftIcon from 'icons/ArrowLeft'
import router from '@/router'
import axios from 'axios'
import { addIllnessBundleToShoppingCart, shoppingCart$ } from "@/services";
import VProductVariants from '@/components/sections/catalog/productDetail/ProductVariants'
import { ref } from 'vue'

export default {
  name: 'BundleProducts',
  components: {
    VArrowLeftIcon,
    VProductVariants
  },
  setup() {
    const shoppingCart = ref(null);

    shoppingCart$.subscribe(value => {
      shoppingCart.value = value;
    });

    return {
      shoppingCart,
    }
  },
  data() {
    return {
      bundleDetails: {}
    };
  },
  computed: {
    productVariantsAddedToBasket() {
      return this.productVariants;
    }
  },
  created() {
    axios
      .get('Products/GetIllnessBundleDetails?illnessBundleId=' + router.currentRoute.value.params.id)
      .then((response) => {
        if (response && response.data) {
          this.bundleDetails = response.data;
        }
      });
  },
  methods: {
    // Function to go back to the respective catalog page
    goToCatalog() {
      router.push('/catalog');
    },
    // Function to add all bundle products to the cart
    orderBundleProducts() {
      addIllnessBundleToShoppingCart(this.shoppingCart, router.currentRoute.value.params.id);
      this.$emitter.emit('alert', {
        message: 'Alle producten zijn toegevoegd aan de bestellijst.'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bundleProducts {
  .material-design-icon {
    margin-right: 10px;
  }
  &__text {
    @include screen(grid-breakpoint(sm), grid-breakpoint(lg)) {
      display: none;
    }
  }
  &__orderButton {
    z-index: 1999;
    position: fixed;
    top: 90%;
    align-self: flex-end;
    left: 75.6%;
  }
  &__backButton {
    margin: 5px 0px 20px 12px;
  }
  &__title {
    font-size: 18px;
    color: $teal-darkest;
    margin-bottom: 10px;
  }
  &__subTitle {
    font-size: 16px;
    color: $gray-darkest;
  }
  &__note {
    font-size: 12px;
    color: $gray-darkest;
    text-align: right;
    margin: -35px 15px 0px 0px;
  }
}
</style>
