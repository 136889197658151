<template>
  <div class="prescriberRegistrationStep3">
    <!-- step 3 -->
    <div class="row grid-align-center">
      <div class="col-md-12 prescriberRegistrationStep3__stepTitle">
        {{ prescriberRegistrationTexts.Step3.PageTitle }}
      </div>
    </div>
    <div class="row grid-align-center">
      <!-- Specialisatie Display Section -->
      <div class="col-md-6 prescriberRegistrationStep3__block">
        <div class="prescriberRegistrationStep3__block__header">Specialisatie toevoegen*</div>
        <div class="prescriberRegistrationStep3__block__content">
          <div class="row grid-padding">
            <div class="col-xs-8 prescriberRegistrationStep3__selectTreatmentArea">
              <v-select-group
                v-model="currentTreatmentAreaId"
                :overwrite-value="currentTreatmentAreaId"
                selectid="SpecializationId"
                :initselected="currentTreatmentAreaId"
                @change="changeSpecialization"
                placeholder="Selecteer hier uw specialisatie"
              >
                <option
                  v-for="(item, index) in prescriberRegistrationDetails.treatmentAreas"
                  :key="index + 1"
                  :data-index="index + 1"
                  :value="item.treatmentAreaId"
                >
                  {{ item.treatmentArea }}
                </option>
              </v-select-group>
            </div>
            <div class="col-xs-4">
              <v-button
                lg
                hollow
                :class="{ 'btn--disabled': !isTreatmentAreaSelected }"
                :disabled="disableTreatmentarea()"
                @click="selectSpecialization"
              >
                <v-plus-circle-icon class="prescriberRegistrationStep3__customButton__icon" />Toevoegen
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedTreatmentAreas.length > 0" class="row grid-align-center">
      <div class="col-md-6 prescriberRegistrationStep3__block">
        <div class="prescriberRegistrationStep3__block__header">Specialisatie*</div>
        <div class="prescriberRegistrationStep3__block__content">
          <div v-for="(item, index) in selectedTreatmentAreas" :key="index" class="row grid-padding">
            <div class="col-xs-8 prescriberRegistrationStep3__block__content__text">
              {{ item.treatmentArea }}
            </div>
            <div class="col-xs-4">
              <v-delete-icon
                :inputid="index"
                class="prescriberRegistrationStep3__icon"
                @click.prevent="deleteTreatmentArea(item.treatmentAreaId, index)"
              />
            </div>
            <hr v-if="selectedTreatmentAreas.length > 1 && selectedTreatmentAreas.length !== index + 1" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="prescriberRegistrationDetails.showRoleSelection" class="row grid-align-center">
      <div class="col-md-6 prescriberRegistrationStep3__block">
        <div class="prescriberRegistrationStep3__block__header">Rol</div>
        <div class="prescriberRegistrationStep3__block__content">
          <div class="row grid-padding">
            <div class="prescriberRegistrationStep3__block__content__role_text">Ik ben:</div>
          </div>
          <div v-for="(item, index) in roles" :key="index" class="row grid-padding">
            <div class="col-xs-8 prescriberRegistrationStep3__block__content__radio">
              <div class="form-group__radio">
                <input
                  :id="'rdbRole' + index"
                  :checked="item.active"
                  name="roles"
                  type="radio"
                  @change="checkRadioAnswer(item.roleId)"
                />
                <label :for="'rdbRole' + index"><span></span>{{ item.role }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep3__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep3__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button
        v-if="getStepActionVisibilityStatus('next')"
        lg
        cta
        :class="{ 'btn--disabled': selectedTreatmentAreas.length === 0 }"
        @click.prevent="nextStep()"
      >
        VOLGENDE STAP
      </v-button>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import VPlusCircleIcon from 'icons/PlusCircleOutline';
import VDeleteIcon from 'icons/Delete';

export default {
  name: 'RegistrationStep3',
  components: {
    VPlusCircleIcon,
    VDeleteIcon
  },
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );
    const roles = computed(() => store.getters['prescriberRegistration/getRoles']);

    const setPreviousStepActive = () => store.commit('prescriberRegistration/setPreviousStepActive');
    const setNextStepActive = () => store.commit('prescriberRegistration/setNextStepActive');
    const setRoles = (value) => store.commit('prescriberRegistration/setRoles', value);
    const setStep4 = (value) => store.commit('prescriberRegistration/setStep4', value);
    const setShowRoleSelection = (value) => store.commit('prescriberRegistration/setShowRoleSelection', value);
    const setPrescriberTreatmentArea = (value) =>
      store.commit('prescriberRegistration/setPrescriberTreatmentArea', value);
    const clearPrescriberTreatmentAreas = () => store.commit('prescriberRegistration/clearPrescriberTreatmentAreas');

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prescriberRegistrationTexts,
      roles,
      setPreviousStepActive,
      setNextStepActive,
      setRoles,
      setStep4,
      setShowRoleSelection,
      setPrescriberTreatmentArea,
      clearPrescriberTreatmentAreas
    };
  },
  data() {
    return {
      isTreatmentAreaSelected: false,
      selectedTreatmentAreas: [],
      currentTreatmentAreaId: null
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.checkTreatmentAreaSelectionStatus();
  },
  methods: {
    getStepActionVisibilityStatus(action) {
      if (action === 'prev') {
        return this.prescriberRegistrationSteps.findIndex((step) => step.show) !== 0;
      }
      return this.prescriberRegistrationSteps.findIndex((step) => step.show) < 5;
    },
    previousStep() {
      this.setPreviousStepActive();
    },
    nextStep() {
      this.setNextStepActive();
    },
    checkTreatmentAreaSelectionStatus() {
      for (let i = 0; i < this.prescriberRegistrationDetails.treatmentAreas.length; i++) {
        if (this.prescriberRegistrationDetails.treatmentAreas[i].active) {
          this.isTreatmentAreaSelected = true;
          if (
            this.selectedTreatmentAreas.findIndex(
              (treatmentArea) =>
                treatmentArea.treatmentAreaId === this.prescriberRegistrationDetails.treatmentAreas[i].treatmentAreaId
            ) === -1
          ) {
            this.selectedTreatmentAreas.push(this.prescriberRegistrationDetails.treatmentAreas[i]);
          }
        }
      }
    },
    checkRadioAnswer(roleId) {
      this.setRoles({ id: roleId, active: true });
    },
    changeSpecialization(e) {
      if (e.currentTarget.value === '0') {
        this.isTreatmentAreaSelected = false;
      } else {
        this.isTreatmentAreaSelected = true;
        this.currentTreatmentAreaId = e.target.value;
      }
    },
    selectSpecialization() {
      this.setStep4({ id: 4, value: 'Werkgever' });
      if (this.currentTreatmentAreaId === '1010') {
        this.clearTreatmentAreas();
        this.setShowRoleSelection(true);
        this.setStep4({ id: 4, value: 'Praktijk' });
      }
      this.setPrescriberTreatmentArea({ id: this.currentTreatmentAreaId, active: true });
      this.checkTreatmentAreaSelectionStatus();
      this.currentTreatmentAreaId = '0';
      this.isTreatmentAreaSelected = false;
    },
    deleteTreatmentArea(treatmentAreaId, index) {
      if (treatmentAreaId === '1010') {
        this.setRoles({ id: 0, active: false });
        this.setShowRoleSelection(false);
      }
      this.selectedTreatmentAreas.splice(index, 1);
      this.setPrescriberTreatmentArea({ id: treatmentAreaId, active: false });
      this.checkTreatmentAreaSelectionStatus();
      this.isTreatmentAreaSelected = false;
    },
    clearTreatmentAreas() {
      this.clearPrescriberTreatmentAreas();
      this.selectedTreatmentAreas = [];
      this.checkTreatmentAreaSelectionStatus();
      this.isTreatmentAreaSelected = false;
    },
    disableTreatmentarea() {
      return this.prescriberRegistrationDetails.showRoleSelection;
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriberRegistrationStep3 {
  .material-design-icon {
    fill: $teal-darkest;
  }
  hr {
    border: 0;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__content {
      padding: 30px;
      &__text {
        color: #333333;
        padding-top: 5px;
      }

      &__role_text {
        color: #333333;
        padding-top: 5px;
        font-weight: bold;
      }

      &__radio {
        :deep(.form-group) {
          label {
            padding-left: 10px;
            color: $gray-dark;
            font-size: 16px;
          }
        }
      }
    }
  }
  &__actions {
    float: right;
    margin: 1% 25% 5% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  &__customButton {
    &__icon {
      margin-right: 10px;
    }
  }
  &__icon {
    cursor: pointer;
  }
  :deep(.checkbox-group) {
    padding: 0px 0px 15px 0px;
  }
  :deep(.form-group) {
    padding: 0px;
    .form-group__details {
      &:before {
        background: none;
      }
      &:after {
        background: none;
      }
    }
    &__icon {
      margin: -5px 10px 0px 0px;
    }
    &--hasvalue label {
      display: none;
    }
  }
  &__selectTreatmentArea {
    :deep(.form-group) {
      label {
        padding-left: 10px;
        margin-top: -8px;
      }
    }
  }
}
</style>
