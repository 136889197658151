import { publishBehavior, switchMap, refCount } from 'rxjs/operators';
import { b2cShoppingCart$ } from './b2cShoppingCart$';
import { assortmentShoppingCart$ } from './assortmentShoppingCart$';
import { assortmentFeatureEnabled$ } from '../assortmentFeatureEnabled$'

// shoppingCart based on router$
export const shoppingCart$ = assortmentFeatureEnabled$.pipe(
  switchMap((isAssortment) => (isAssortment ? assortmentShoppingCart$ : b2cShoppingCart$)),
  publishBehavior({ items: [] }),
  refCount()
);
