<template>
  <section class="container page-detail">
    <div class="row">
      <article class="col-xs-12">
        <v-heading :level="1">
          {{ pageItem.Title }}
        </v-heading>
        <div>{{ pageItem.PublishDate }}</div>
        <div class="pagebody" v-html="pageItem.Body" />
      </article>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VPage',
  setup() {
    const store = useStore();

      const pageItem = computed(() => store.getters['content/getPageItem']);
      const fetchPageItem = async (value) => await store.dispatch('content/fetchPageItem', value);
      
      return {
        pageItem,
        fetchPageItem
      }
    },
    async created () {
      await this.fetchPageItem(router.currentRoute.value.params.id)
    },
    methods: {
      moment (...args) {
        return moment(...args)
      }
    }
  };
</script>

<style lang="scss" scoped>
.page-detail {
  h1.heading {
    font-size: type-scale(3);
    line-height: line-height(2);
    font-weight: 900;
    color: $teal-darkest;
    margin: 0 0 0.4rem;
  }
  .pagebody {
    font-size: type-scale(0);
    line-height: line-height(2);
    color: $gray-darkest;
    margin: 1rem 0 0.4rem;
    a {
      font-weight: 900;
      color: $teal-darkest;
    }
  }
  time {
    color: $gray;
    font-size: type-scale(0);
    line-height: line-height(0);
    margin-top: 0;
  }
}
</style>
