<template>
  <section class="container">
    <div class="browserBackRedirectionModal">
      <v-check-circle-icon class="browserBackRedirectionModal__icon" />
      <p>Machtiging is gereed, keer terug naar Patient dashboard</p>
      <div class="browserBackRedirectionModal__imgHolder">
        <img src="@/static/img/clipboardMediq.svg" />
      </div>
      <v-button
        md
        primary
        class="browserBackRedirectionModal__btn"
        tagelement="a"
        @click.prevent="$router.push('/patientdashboard')"
      >
        Naar Patiënt Dashboard
      </v-button>
    </div>
  </section>
</template>

<script>
import VCheckCircleIcon from 'icons/CheckCircle';

export default {
  name: 'BrowserBackRedirectionModal',
  components: {
    VCheckCircleIcon
  },
  props: {}
};
</script>

<style lang="scss" scoped>
.browserBackRedirectionModal {
  width: 500px;
  max-height: 500px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
  padding: 25px;
  margin: 0 auto;
  text-align: center;
  &__icon {
    fill: $dark-green !important;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: middle;
    :deep(svg) {
      width: 40px;
      height: 40px;
    }
  }
  &__btn {
    margin: 0 auto;
    width: max-content;
  }
  &__imgHolder {
    display: inline-block;
    img {
      width: 200px;
      height: 200px;
      margin-bottom: 25px;
    }
  }
}
</style>
