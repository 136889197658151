<template>
  <div class="osaDetails">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="3"> Meetmomenten </v-heading>
      </div>
      <div class="col-xs-12 col-md-6 grid-align-end">
        <v-button-dropdown
          :options="osaOptions"
          :button-props="{ hollow: true, inverted: true, alt: true, md: true, iconLeft: true }"
          class="osaDetails__button"
          @on-select="redirectToOsaPage"
        >
          <v-plus-circle-icon />Meetmoment toevoegen
        </v-button-dropdown>
      </div>
    </div>
    <v-table id="osaDetailsRow">
      <thead>
        <v-header-row :data="osaDetailsHeader" />
      </thead>
      <v-osa-details-row v-for="(row, index) in osaOverview" :key="index" :data="row" :index="index" />
    </v-table>
  </div>
</template>

<script>
import router from '@/router';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VTable from '@/components/shared/table/Table';
import VButtonDropdown from '@/components/shared/ButtonDropdown';
import VOsaDetailsRow from '@/components/sections/osa/osaDetailsRow';
import VPlusCircleIcon from 'icons/PlusCircle';

export default {
  name: 'VOsaDetails',
  components: {
    VHeaderRow,
    VTable,
    VButtonDropdown,
    VPlusCircleIcon,
    VOsaDetailsRow
  },
  props: {
    osaOverview: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      osaOptions: [
        { id: 0, title: 'Evaluatie OSA' },
        { id: 1, title: 'Uitlezing OSA' },
        { id: 2, title: 'Evaluatie POSA - SPT' }
      ],
      osaDetailsHeader: ['Datum invoer', 'Type', 'Stemming patiënt', 'AHI', 'Oordeel patiënt', '']
    };
  },
  computed: {},
  methods: {
    redirectToOsaPage(osaType) {
      if (osaType === 0) {
        router.push('/osa/evaluatie');
      }
      if (osaType === 1) {
        router.push('/osa/uitlezing');
      }
      if (osaType === 2) {
        router.push('/osa/evaluatie-spt');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.osaDetails {
  table {
    margin-bottom: 24px;
  }
  &__button {
    @include max-screen(grid-breakpoint(md)) {
      margin-bottom: 1rem;
      align-self: flex-start;
    }
    @include min-screen(grid-breakpoint(md)) {
      align-self: flex-end;
    }
    z-index: 99 !important;
  }
  :deep(.table__header > th:first-child) {
    width: inherit;
  }
}
</style>
