<template>
  <div class="prescriberRegistrationStep6">
    <!-- step 6 -->
    <div class="row grid-align-center">
      <div class="col-md-12 prescriberRegistrationStep6__stepTitle">
        {{ prescriberRegistrationTexts.Step6.PageTitle }}
      </div>
      <div class="col-md-6 prescriberRegistrationStep6__block">
        <div class="row prescriberRegistrationStep6__block__part">
          <div class="col-xs-12">
            <legend>Heeft u een AGB, BIG- of KP-nummer?</legend>
            <div v-if="prescriberRegistrationDetails.userType" class="prescriberRegistrationStep6__block__detail">
              Ik bezit geen AGB/BIG/KP-code en werk onder volmacht.
            </div>
          </div>
          <div
            v-if="prescriberRegistrationDetails.agbCode !== ''"
            class="row prescriberRegistrationStep6__block__section"
          >
            <span class="prescriberRegistrationStep6__block__title">AGB-nummer</span>
            <span class="prescriberRegistrationStep6__block__detail">{{ prescriberRegistrationDetails.agbCode }}</span>
          </div>
          <div
            v-if="prescriberRegistrationDetails.bigCode !== ''"
            class="row prescriberRegistrationStep6__block__section"
          >
            <span class="prescriberRegistrationStep6__block__title">BIG-nummer</span>
            <span class="prescriberRegistrationStep6__block__detail">{{ prescriberRegistrationDetails.bigCode }}</span>
          </div>
          <div
            v-if="prescriberRegistrationDetails.kpNumber !== ''"
            class="row prescriberRegistrationStep6__block__section"
          >
            <span class="prescriberRegistrationStep6__block__title">KP-nummer</span>
            <span class="prescriberRegistrationStep6__block__detail">{{ prescriberRegistrationDetails.kpNumber }}</span>
          </div>
        </div>
        <div class="row prescriberRegistrationStep6__block__part">
          <legend>Mijn gegevens</legend>
          <div class="col-xs-12">
            <div class="row prescriberRegistrationStep6__block__section">
              <span class="prescriberRegistrationStep6__block__title">Aanhef</span>
              <span
                v-if="prescriberRegistrationDetails.titleId === 1"
                class="prescriberRegistrationStep6__block__detail"
                >De heer</span
              >
              <span v-else class="prescriberRegistrationStep6__block__detail">Mevrouw</span>
              <span class="prescriberRegistrationStep6__block__title">Voorletters</span>
              <span class="prescriberRegistrationStep6__block__detail">{{
                prescriberRegistrationDetails.initials
              }}</span>
            </div>
            <div class="row prescriberRegistrationStep6__block__section">
              <span v-if="prescriberRegistrationDetails.firstName !== ''">
                <span class="prescriberRegistrationStep6__block__title">Voornaam</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.firstName
                }}</span>
              </span>
              <span v-if="prescriberRegistrationDetails.prePosition !== ''">
                <span class="prescriberRegistrationStep6__block__title">Tussenvoegsel</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.prePosition
                }}</span>
              </span>
              <span class="prescriberRegistrationStep6__block__title">Achternaam</span>
              <span class="prescriberRegistrationStep6__block__detail">{{
                prescriberRegistrationDetails.lastName
              }}</span>
            </div>
            <div
              v-if="prescriberRegistrationDetails.maidenName || prescriberRegistrationDetails.dateOfBirth"
              class="row prescriberRegistrationStep6__block__section"
            >
              <span v-if="prescriberRegistrationDetails.maidenName">
                <span class="prescriberRegistrationStep6__block__title">Geboortenaam</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.maidenName
                }}</span>
              </span>
              <span v-if="prescriberRegistrationDetails.dateOfBirth">
                <span class="prescriberRegistrationStep6__block__title">Geboortedatum</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.dateOfBirth
                }}</span>
              </span>
            </div>
            <div
              v-if="prescriberRegistrationDetails.phoneNumber || prescriberRegistrationDetails.mobileNumber"
              class="row prescriberRegistrationStep6__block__section"
            >
              <span v-if="prescriberRegistrationDetails.phoneNumber">
                <span class="prescriberRegistrationStep6__block__title">Telefoonnummer (vast)</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.phoneNumber
                }}</span>
              </span>
              <span v-if="prescriberRegistrationDetails.mobileNumber">
                <span class="prescriberRegistrationStep6__block__title">Telefoonnummer (mobiel)</span>
                <span class="prescriberRegistrationStep6__block__detail">{{
                  prescriberRegistrationDetails.mobileNumber
                }}</span>
              </span>
            </div>
            <div class="row prescriberRegistrationStep6__block__section">
              <span class="prescriberRegistrationStep6__block__title">Voorkeurs e-mailadres</span>
              <span class="prescriberRegistrationStep6__block__detail">{{ prescriberRegistrationDetails.email }}</span>
            </div>
          </div>
        </div>
        <div class="row prescriberRegistrationStep6__block__part">
          <legend>Specialisatie</legend>
          <div class="col-xs-12">
            <div class="row prescriberRegistrationStep6__block__section">
              <span class="prescriberRegistrationStep6__block__detail">{{ getTreatmentAreas() }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="prescriberRegistrationDetails.showRoleSelection && getRoles() !== ''"
          class="row prescriberRegistrationStep6__block__part"
        >
          <legend>Rol</legend>
          <div class="col-xs-12">
            <div class="row prescriberRegistrationStep6__block__section">
              <span class="prescriberRegistrationStep6__block__detail">{{ getRoles() }}</span>
            </div>
          </div>
        </div>
        <div v-if="prescriberRegistrationDetails.showRoleSelection">
          <div
            v-for="(practice, practiceIndex) in prescriberRegistrationDetails.practice"
            :key="'prac-' + practiceIndex"
            class="row prescriberRegistrationStep6__block__part"
          >
            <legend>Praktijk {{ practiceIndex + 1 }}</legend>
            <div class="col-xs-12">
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Naam praktijk</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.name }}</span>
                <span class="prescriberRegistrationStep6__block__title">AGB code</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.agbCode }}</span>
              </div>
              <div
                v-for="(department, departmentIndex) in practice.departments"
                :key="'practiceDept-' + departmentIndex"
                class="row prescriberRegistrationStep6__block__part"
              >
                <legend v-if="practice.departments.length > 1">Locatie {{ departmentIndex + 1 }}</legend>
                <legend v-else>Locatie</legend>
                <div class="col-xs-12">
                  <div class="row prescriberRegistrationStep6__block__section">
                    <span class="prescriberRegistrationStep6__block__title">Locatienaam</span>
                    <span class="prescriberRegistrationStep6__block__detail">{{ department.name }}</span>
                  </div>
                  <div class="row prescriberRegistrationStep6__block__section">
                    <span class="prescriberRegistrationStep6__block__title">Zakelijke e-mailadres</span>
                    <span class="prescriberRegistrationStep6__block__detail">{{ department.email }}</span>
                  </div>
                  <div v-show="department.showWarranterDetails">
                    <legend>Gegevens volmachtgever</legend>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">Aanhef</span>
                      <span v-if="department.prescriberTitleId === 1" class="prescriberRegistrationStep6__block__detail"
                        >De heer</span
                      >
                      <span v-else class="prescriberRegistrationStep6__block__detail">Mevrouw</span>
                      <span class="prescriberRegistrationStep6__block__title">Voorletters</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{
                        department.prescriberInitials
                      }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span v-if="department.prescriberPrePosition !== ''">
                        <span class="prescriberRegistrationStep6__block__title">Tussenvoegsel</span>
                        <span class="prescriberRegistrationStep6__block__detail">{{
                          department.prescriberPrePosition
                        }}</span>
                      </span>
                      <span class="prescriberRegistrationStep6__block__title">Achternaam</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{
                        department.prescriberLastName
                      }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">E-mailadres</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{ department.prescriberEmail }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">BIG nummer</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{ department.prescriberBigCode }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <legend>Locatie adres</legend>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Postcode</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.postalCode }}</span>
                <span class="prescriberRegistrationStep6__block__title">Huisnummer</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.houseNumber }}</span>
                <span v-if="practice.houseNumberAddition !== ''">
                  <span class="prescriberRegistrationStep6__block__title">Toevoeging</span>
                  <span class="prescriberRegistrationStep6__block__detail">{{ practice.houseNumberAddition }}</span>
                </span>
              </div>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Straatnaam</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.streetName }}</span>
                <span class="prescriberRegistrationStep6__block__title">Plaats</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.city }}</span>
                <span class="prescriberRegistrationStep6__block__title">Land</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.country }}</span>
              </div>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Telefoonnummer</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ practice.phoneNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(institution, institutionIndex) in prescriberRegistrationDetails.institutions"
            :key="'inst-' + institutionIndex"
            class="row prescriberRegistrationStep6__block__part"
          >
            <legend>Werkgever {{ institutionIndex + 1 }}</legend>
            <div class="col-xs-12">
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Naam werkgever</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.name }}</span>
                <span class="prescriberRegistrationStep6__block__title">AGB code</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.agbCode }}</span>
              </div>
              <div
                v-for="(department, departmentIndex) in institution.departments"
                :key="'instDept-' + departmentIndex"
                class="row prescriberRegistrationStep6__block__part"
              >
                <legend v-if="institution.departments.length > 1">Afdeling {{ departmentIndex + 1 }}</legend>
                <legend v-else>Afdeling</legend>
                <div class="col-xs-12">
                  <div class="row prescriberRegistrationStep6__block__section">
                    <span class="prescriberRegistrationStep6__block__title">Afdelingsnaam</span>
                    <span class="prescriberRegistrationStep6__block__detail">{{ department.name }}</span>
                  </div>
                  <div class="row prescriberRegistrationStep6__block__section">
                    <span class="prescriberRegistrationStep6__block__title">Persoonlijke e-mailadres</span>
                    <span class="prescriberRegistrationStep6__block__detail">{{ department.email }}</span>
                  </div>
                  <div class="row prescriberRegistrationStep6__block__section">
                    <span class="prescriberRegistrationStep6__block__title">Depotnummer</span>
                    <span class="prescriberRegistrationStep6__block__detail">{{ department.depotNumber }}</span>
                  </div>
                  <div v-show="department.showWarranterDetails">
                    <legend>Gegevens volmachtgever</legend>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">Aanhef</span>
                      <span v-if="department.prescriberTitleId === 1" class="prescriberRegistrationStep6__block__detail"
                        >De heer</span
                      >
                      <span v-else class="prescriberRegistrationStep6__block__detail">Mevrouw</span>
                      <span class="prescriberRegistrationStep6__block__title">Voorletters</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{
                        department.prescriberInitials
                      }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span v-if="department.prescriberPrePosition !== ''">
                        <span class="prescriberRegistrationStep6__block__title">Tussenvoegsel</span>
                        <span class="prescriberRegistrationStep6__block__detail">{{
                          department.prescriberPrePosition
                        }}</span>
                      </span>
                      <span class="prescriberRegistrationStep6__block__title">Achternaam</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{
                        department.prescriberLastName
                      }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">E-mailadres</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{ department.prescriberEmail }}</span>
                    </div>
                    <div class="row prescriberRegistrationStep6__block__section">
                      <span class="prescriberRegistrationStep6__block__title">BIG nummer</span>
                      <span class="prescriberRegistrationStep6__block__detail">{{ department.prescriberBigCode }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <legend>Bezoekadres</legend>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Postcode</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.postalCode }}</span>
                <span class="prescriberRegistrationStep6__block__title">Huisnummer</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.houseNumber }}</span>
                <span v-if="institution.houseNumberAddition !== ''">
                  <span class="prescriberRegistrationStep6__block__title">Toevoeging</span>
                  <span class="prescriberRegistrationStep6__block__detail">{{ institution.houseNumberAddition }}</span>
                </span>
              </div>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Straatnaam</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.streetName }}</span>
                <span class="prescriberRegistrationStep6__block__title">Plaats</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.city }}</span>
                <span class="prescriberRegistrationStep6__block__title">Land</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.country }}</span>
              </div>
              <div class="row prescriberRegistrationStep6__block__section">
                <span class="prescriberRegistrationStep6__block__title">Telefoonnummer</span>
                <span class="prescriberRegistrationStep6__block__detail">{{ institution.phoneNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row prescriberRegistrationStep6__block__part">
          <legend>Handtekening</legend>
          <div v-if="!signatureDetails[0].upload" class="col-xs-12">
            <div class="prescriberRegistrationStep6__block__part__signature">
              <img :src="signatureDetails[0].formData" alt="Prescriber Signature" />
            </div>
          </div>
          <div v-else class="col-xs-12 prescriberRegistrationStep6__block__part__signature">
            <img v-if="!prescriberUploadedImage.pdf" :src="prescriberUploadedImage.image" alt="Prescriber Signature" />
            <div v-else class="prescriberRegistrationStep6__block__part__signatureDetails">
              <img src="@/static/img/img-icon.svg" />
              <a>{{ getFileName(signatureDetails[0]) }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep6__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep6__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button lg cta @click.prevent="saveRegistration()"> Bevestigen </v-button>
    </div>
  </div>
</template>
<script>
import router from '@/router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'VRegistrationStep6',
  components: {},
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const prescriberUploadedImage = computed(() => store.getters['prescriberRegistration/getUploadedImage']);
    const signatureDetails = computed(() => store.getters['prescriberRegistration/getSignatureDetails']);
    const registrationSuccessStatus = computed(
      () => store.getters['prescriberRegistration/getRegistrationSuccessStatus']
    );
    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );
    const uploadedFileName = computed(() => store.getters['prescriberRegistration/getUploadedFileName']);
    const gpRoles = computed(() => store.getters['prescriberRegistration/getRoles']);

    const setPreviousStepActive = () => store.commit('prescriberRegistration/setPreviousStepActive');
    const savePrescriberRegistration = () => store.dispatch('prescriberRegistration/savePrescriberRegistration');

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prescriberUploadedImage,
      signatureDetails,
      registrationSuccessStatus,
      prescriberRegistrationTexts,
      uploadedFileName,
      gpRoles,
      setPreviousStepActive,
      savePrescriberRegistration
    };
  },
  data() {
    return {};
  },
  watch: {
    registrationSuccessStatus(value) {
      if (value) {
        router.push('/registrerensucces');
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {},
  methods: {
    getStepActionVisibilityStatus(action) {
      if (action === 'prev') {
        return this.prescriberRegistrationSteps.findIndex((step) => step.show) !== 0;
      }
      return this.prescriberRegistrationSteps.findIndex((step) => step.show) < 5;
    },
    previousStep() {
      this.setPreviousStepActive();
    },
    saveRegistration() {
      this.savePrescriberRegistration().then((response) => {
        if (response.Success) {
          window.dataLayer.push({
            event: 'prescriberRegistration',
            userData: { uuid: response.data.UUID },
            details: 'registrerensucces'
          });
        }
      });
    },
    getTreatmentAreas() {
      let area = '';
      this.prescriberRegistrationDetails.treatmentAreas.forEach((treatmentArea) => {
        if (treatmentArea.active) {
          if (area !== '') {
            area = area.concat(', ' + treatmentArea.treatmentArea);
          } else {
            area = treatmentArea.treatmentArea;
          }
        }
      });
      return area;
    },
    getRoles() {
      let role = '';
      this.gpRoles.forEach((r) => {
        if (r.active) {
          role = r.role;
        }
      });
      return role;
    },
    getFileName(dataFile) {
      if (dataFile.upload) {
        return this.uploadedFileName != null ? this.uploadedFileName : '';
      }
      return dataFile.name;
    }
  }
};
</script>
<style lang="scss" scoped>
.prescriberRegistrationStep6 {
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__block {
    margin: 0 auto 30px auto;
    legend {
      font-size: 24px;
      color: $teal-darkest;
      margin: 20px 0px 20px 0px;
    }
    &__title {
      font-size: 18px;
      color: $teal-darkest;
      font-weight: bold;
      padding-right: 15px;
    }
    &__detail {
      font-size: 18px;
      color: $grayish-very-dark;
      padding-right: 25px;
    }
    &__part {
      margin: 10px 0px 10px 0px;
      &__signature {
        img {
          width: 300px;
          height: 100px;
          margin: -5px 0px 0px 0px;
        }
        span {
          width: 300px;
          height: 100px;
          margin: -5px 0px 0px 0px;
        }
      }
      &__signatureDetails {
        margin: 0px;
        img {
          vertical-align: bottom;
          width: auto;
          height: auto;
        }
        a {
          text-decoration: none;
          cursor: default;
        }
      }
    }
    &__section {
      margin: 15px 0px 15px 0px;
    }
    &__icon {
      position: relative;
      margin: -40px 10px 10px 10px;
      float: right;
    }
  }
  &__actions {
    float: right;
    margin: 3% 29% 10% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
}
</style>
