import axios from 'axios'

export default {
    namespaced: true,
    state: {
        warehouses: [],
        currentWarehouseId: '',
        showWarning: false,
        pumpLendingSuccess: false,
        isPumpReturn: false
    },
    mutations: {
        setWarehouses(state, payload) {
            state.warehouses = payload;
        },
        setCurrentWarehouseId(state, payload) {
            state.currentWarehouseId = payload;
        },
        setShowWarning(state, payload) {
            state.showWarning = payload;
        },
        setShowWarning(state, payload) {
            state.showWarning = payload;
        },
        setPumpLendingSuccess(state, payload) {
            state.pumpLendingSuccess = payload;
        },
        setIsPumpReturn(state, payload) {
            state.isPumpReturn = payload;
        }
    },
    actions: {
        getWarehouseList ({ commit }, inputText) {
            let postalCode = "";
            let depotName = "";
            let postCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
            if (postCodeRegex.test(inputText))  {
                postalCode = inputText;
            }
            else {
                depotName = inputText;
            }
            postalCode = postalCode.replace(' ', '')
            axios.get('Equipment/GetWarehouseFromPostCode?PostalCode=' + postalCode + "&DepotName=" + depotName).then((response) => {
                commit('setWarehouses', response.data);

                if (response.data.length === 1) {
                    commit('setCurrentWarehouseId', response.data[0].warehouseId);
                }

                response.data.length ? commit('setShowWarning', false) : commit('setShowWarning', true)
            }).catch(() => {
               commit('setWarehouses', []);
            })
        },
        lendoutPump ({ commit }, equipmentId) {
            axios.post('Equipment/LendoutPump?WarehouseId=' + this.state.pumpOverview.currentWarehouseId + '&EquipmentId=' + equipmentId)
                .then((response) => {
                    response.data ? commit('setPumpLendingSuccess', true) : commit('setPumpLendingSuccess', false);
                })
        },
        pumpReturn ({ commit }, equipmentID) {
            axios.post('Equipment/ReturnPump?EquipmentId=' + equipmentID).then((response) => {
                if (response.data) {
                    commit('setIsPumpReturn', false);
                    this.$router.go()
                }
            })
        },
        updateCurrentWarehouseId({ commit }, id) {
            commit('setCurrentWarehouseId', id);
        }
    },
    getters: {
        getWarehouses: state => {
            return state.warehouses;
        },
        getCurrentWarehouseId: state => {
            return state.currentWarehouseId;
        },
        getShowWarning: state => {
            return state.showWarning;
        },
        getPumpLendingSuccess: state => {
            return state.pumpLendingSuccess;
        },
        getIsPumpReturn: state => {
           return state.isPumpReturn;
        }
    }
}