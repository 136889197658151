export default {
  /**
   * @function openFileInNewTab - open image in new tab
   */
  openFileInNewTab: function (fileData, fileTitle) {
    let file = fileData
    let type = 'data:image/png;base64,'
    let html = '<html>' +
        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
        '<body>' +
        '<iframe src="' + type + file + '"></iframe>' +
        '</body></html>'
    if (fileTitle.split('.').pop().toLowerCase() === 'jpg') {
      type = 'data:application/octet-stream;base64,'
      html = '<html>' +
        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
        '<body>' +
        '<img src="' + type + file + '" style="height: 100%"></img>' +
        '</body></html>'
    }
    if (fileTitle.split('.').pop().toLowerCase() === 'pdf') {
      type = 'data:application/pdf;base64,'
      html = '<html>' +
        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
        '<body>' +
        '<iframe src="' + type + file + '" type="application/pdf"></iframe>' +
        '</body></html>'
    }
    let a = window.open()
    a.document.write(html)
    a.focus()
    return false
  },
  /**
   * @function fileExtention -returns extention of the file
   */
  fileExtention (fileTitle) {
    return fileTitle.split('.').pop().toLowerCase()
  }
}
