<template>
  <p class="empty-message">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'VEmptyMessage'
}
</script>

<style lang="scss" scoped>
.empty-message {
  padding-top: 10px;
  position: absolute;
  color: $gray;
  font-style: italic;
}
</style>