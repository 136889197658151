<template v-if="prescriberRegistrationDetails.institutions[0].departments[0]">
  <div class="registrationDepartmentDetails row grid-align-center">
    <div class="col-md-6 registrationDepartmentDetails__block">
      <div v-if="!worksUnderWarrant" class="registrationDepartmentDetails__block__warranter">
        <label>Werkt uw collega op deze afdeling onder volmacht?*</label>
        <span class="registrationDepartmentDetails__block__warranter__radio form-group__radio">
          <input :id="'warranterTrue'" v-model="showWarrantRadio" :name="'warranter'" type="radio" :value="true" />
          <label class="warranter-label" :for="'warranterTrue'"><span></span>Ja</label>
        </span>
        <span class="registrationDepartmentDetails__block__warranter__radio form-group__radio">
          <input :id="'warranterFalse'" v-model="showWarrantRadio" :name="'warranter'" type="radio" :value="false" />
          <label class="warranter-label" :for="'warranterFalse'"><span></span>Nee</label>
        </span>
      </div>
      <div v-if="showEmailSearch || worksUnderWarrant" class="registrationDepartmentDetails__block__warranterDetails">
        <div class="registrationDepartmentDetails__block__header">Gegevens volmachtgever</div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-6">
            <v-input-group-simple-2
              id="searchEmail"
              v-model="searchEmail"
              :rules="{
                required: true,
                email: true,
                regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
              }"
              data-vv-as="E-mailadres volmachtgever"
              placeholder="E-mailadres volmachtgever*"
            />
          </div>
          <div class="col-xs-6">
            <v-button
              :disabled="!searchEmail.length"
              class="btn--secondary btn--lg searchemail_text"
              @click.prevent="getWarranterDetails(searchEmail)"
            >
              <img class="magnify-scan" src="@/static/img/magnify-scan.svg" />Controleer e-mailadres
            </v-button>
          </div>
        </div>
      </div>
      <div
        v-if="showWarranterDetails && openDepartmentDetailFields"
        class="registrationDepartmentDetails__block__warranterDetails"
      >
        <div v-if="warranterSuccessMessage" class="row grid-align-center" :class="{ 'flipInX animated': animated }">
          <div class="col-md-2 registrationDepartmentDetails__successMessage">
            <v-check-circle-icon class="registrationDepartmentDetails__successMessage__icon" />
          </div>
          <div class="col-md-10 registrationDepartmentDetails__successMessage">
            <span>
              E-mailadres geverifieerd. Uw volmachtgever ontvangt <br />
              per mail een bevestiging van deze volmachtaanvraag.
            </span>
          </div>
        </div>
        <div
          v-if="!warranterSuccessMessage"
          class="row grid-align-center registrationDepartmentDetails__block__content__section"
          :class="{ 'flipInX animated': animated }"
        >
          <div class="col-xs-12">
            <v-warning-message
              :warning="'Let op!'"
              :message="'Dit e-mailadres van uw volmachtgever is niet bij ons bekend. Om uw account te kunnen activeren dient uw volmachtgever zich tevens te hebben geregistreerd en bij ons bekend te zijn met het door uw ingevoerde e-mailadres.'"
              :html="true"
              :is-urgent="false"
            />
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4 registrationDepartmentDetails__block__content__section__gender">
            <span class="form-group__radio">
              <input
                id="'prescriberGenderWarranterMale'"
                v-model.number="prescriberTitleId"
                :name="'prescriberGender'"
                type="radio"
                value="1"
              />
              <label for="'prescriberGenderWarranterMale'"><span></span>De heer</label>
            </span>
            <span class="form-group__radio">
              <input
                id="'prescriberGenderWarranterFemale'"
                v-model.number="prescriberTitleId"
                :name="'prescriberGender'"
                type="radio"
                value="2"
              />
              <label for="'prescriberGenderWarranterFemale'"><span></span>Mevrouw</label>
            </span>
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberInitials"
              v-model:value="prescriberInitials"
              :disabled="warranterSuccessMessage"
              :rules="{
                required: true
              }"
              :overwrite-value="prescriberInitials"
              data-vv-as="Voorletters"
              placeholder="Voorletters*"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberPrePosition"
              v-model:value="prescriberPrePosition"
              :disabled="warranterSuccessMessage"
              :rules="{
                required: false
              }"
              :overwrite-value="prescriberPrePosition"
              data-vv-as="Tussenvoegsel"
              placeholder="Tussenvoegsel"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberLastName"
              v-model:value="prescriberLastName"
              :disabled="warranterSuccessMessage"
              :rules="{
                required: true
              }"
              :overwrite-value="prescriberLastName"
              data-vv-as="Achternaam"
              placeholder="Achternaam*"
            />
          </div>
        </div>
        <div class="row grid-padding registrationDepartmentDetails__block__content__section">
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberEmail"
              v-model:value="prescriberEmail"
              :disabled="true"
              :rules="{
                required: true,
                email: true,
                regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
              }"
              :overwrite-value="prescriberEmail"
              data-vv-as="E-mailadres volmachtgever"
              placeholder="E-mailadres volmachtgever*"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberPhoneNumber"
              v-model:value="prescriberPhoneNumber"
              :disabled="warranterSuccessMessage"
              :rules="{
                required: false
              }"
              :overwrite-value="prescriberPhoneNumber"
              data-vv-as="Telefoonnummer volmachtgever"
              placeholder="Telefoonnummer volmachtgever"
            />
          </div>
          <div class="col-xs-4">
            <v-input-group-simple-2
              id="prescriberBigCode"
              v-model:value="prescriberBigCode"
              :disabled="warranterSuccessMessage"
              :initvalue="prescriberBigCode"
              :rules="{
                required: false
              }"
              :overwrite-value="prescriberBigCode"
              data-vv-as="BIG-nummer"
              placeholder="BIG-nummer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { createHelpers } from 'vuex-map-fields';
import VCheckCircleIcon from 'icons/CheckCircle';
import VWarningMessage from '@/components/shared/WarningMessage';
import { useForm } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'prescriberRegistration/getColleagueData',
  mutationType: 'prescriberRegistration/updateColleagueData'
});

export default {
  name: 'ColleagueDepartmentDetails',
  components: {
    VCheckCircleIcon,
    VWarningMessage
  },
  props: {
    worksUnderWarrant: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const fetchWarranterDetails = (value) => store.dispatch('prescriberRegistration/fetchWarranterDetails', value);
    const createComputed = (path) => {
      return computed({
        get: () =>
          store.state.prescriberRegistration.prescriberRegistrationDetails.institutions[0].departments[0][path],
        set: (value) => store.commit('prescriberRegistration/updateDepartmentData', { path, value })
      });
    };
    const showWarranterDetails = createComputed('showWarranterDetails');
    const prescriberTitleId = createComputed('prescriberTitleId');
    const prescriberInitials = createComputed('prescriberInitials');
    const prescriberFirstName = createComputed('prescriberFirstName');
    const prescriberPrePosition = createComputed('prescriberPrePosition');
    const prescriberLastName = createComputed('prescriberLastName');
    const prescriberEmail = createComputed('prescriberEmail');
    const prescriberPhoneNumber = createComputed('prescriberPhoneNumber');
    const prescriberBigCode = createComputed('prescriberBigCode');
    const prescriberExternalId = createComputed('prescriberExternalId');
    const warranterSuccessMessage = createComputed('warranterSuccessMessage');
    return {
      prescriberRegistrationDetails,
      showWarranterDetails,
      prescriberTitleId,
      prescriberInitials,
      prescriberFirstName,
      prescriberPrePosition,
      prescriberLastName,
      prescriberEmail,
      prescriberPhoneNumber,
      prescriberBigCode,
      prescriberExternalId,
      warranterSuccessMessage,
      fetchWarranterDetails
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      animated: false,
      searchEmail: '',
      showEmailSearch: false,
      showWarrantRadio: false,
      openDepartmentDetailFields: false
    };
  },
  watch: {
    worksUnderWarrant(newVal) {
      this.showWarrantRadio = newVal;
    },
    showWarrantRadio(newVal) {
      if (newVal === true) {
        this.showEmailSearch = true;
        this.showWarranterDetails = false;
      } else {
        this.showEmailSearch = false;
        this.showWarranterDetails = false;
        this.openDepartmentDetailFields = false;
      }
    }
  },
  methods: {
    getWarranterDetails() {
      let warranterEmail = this.searchEmail;
      this.fetchWarranterDetails(warranterEmail).then((response) => {
        if (response) {
          this.openDepartmentDetailFields = true;
          let warranterDetails = response.data.WarranterDetails;
          this.prescriberTitleId = warranterDetails ? (warranterDetails.TitleId ? warranterDetails.TitleId : 1) : 1;
          this.prescriberInitials = warranterDetails ? warranterDetails.Initials : '';
          this.prescriberFirstName = warranterDetails ? warranterDetails.FirstName : '';
          this.prescriberPrePosition = warranterDetails ? warranterDetails.PrePosition : '';
          this.prescriberLastName = warranterDetails ? warranterDetails.LastName : '';
          this.prescriberEmail = warranterDetails ? warranterDetails.Email : warranterEmail;
          this.prescriberPhoneNumber = warranterDetails ? warranterDetails.PhoneNumber : '';
          this.prescriberBigCode = warranterDetails ? warranterDetails.BigCode : '';
          this.prescriberExternalId = warranterDetails ? warranterDetails.ExternalId : '';
          this.warranterSuccessMessage = warranterDetails ? warranterDetails.SuccessMessage : false;
          this.animated = true;
          this.showWarranterDetails = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.registrationDepartmentDetails {
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 24px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 24px;
    }
    &__warranter {
      display: flex;
      justify-content: center;
      background-color: $teal-lighter;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px 20px 10px 20px;
      text-align: center;
      color: $gray-darkest;
      &__radio {
        display: inline-block;
        vertical-align: sub;
      }
      legend {
        display: inline-block;
      }
    }
    &__warranterDetails {
      padding-bottom: 24px;
    }
    &__content {
      padding-top: 24px;
      padding-bottom: 24px;
      &__section {
        margin: 16px;
        span {
          float: left;
          margin: 12px 8px 0 0;
        }
        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }
    }
  }
  &__successMessage {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 24px 0px 24px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      margin-right: -100px;
      vertical-align: middle;
      :deep(svg) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .magnify-scan {
    filter: brightness(0) invert(1);
    height: 20px !important;
    width: 20px !important;
    margin-right: 15px;
  }
  :deep(.form-group-simple) {
    label {
      display: none;
    }
  }
  .warranter-label {
    padding: 0 5px 0 5px;
  }
}
</style>
