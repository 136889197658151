import axios from 'axios';
import { debounce, memoize} from 'lodash';

const updateShoppingCartItemOnServer = memoize(function(command) {
  return debounce(function (cmd) {
    axios.post('ShoppingCart/UpdateShoppingCartItem', cmd);
  }, 300)
},
  command => {
    return command.shoppingCartItemId
  }
);

export function updateShoppingCartItem(shoppingCart, item, ppwRecId) {
  if (shoppingCart === null) {
    throw 'ShoppingCart is empty';
  }

  var command = {
    shoppingCartItemId: item.cartId,
    quantity: item.quantity / 1,
    givenToPatient: item.givenToPatient,
    fillUpStock: item.fillUpStock,
    serialNumber: item.serialNumber,
    warehouseId: item.warehouseId,
    instruction: item.instructionInput,
    ppwRecId: ppwRecId
  };

  // We execute the update on the server with a memoize and debounce but do not refresh the shopping cart from server
  updateShoppingCartItemOnServer(command)(command);
}
