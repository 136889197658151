<template>
  <div class="header-row">
    <div v-if="checkGegevensWijzigen() && gegevens.PrescriberGegevens" class="patient-col patientname">
      <span v-if="gegevens.PrescriberGegevens.Voorletters">{{ gegevens.PrescriberGegevens.Voorletters }}</span>
      <span v-if="!gegevens.PrescriberGegevens.Voorletters">{{ gegevens.PrescriberGegevens.Voornaam }}</span>
      {{ gegevens.PrescriberGegevens.Tussenvoegsel }}
      {{ gegevens.PrescriberGegevens.Achternaam }}
      <pencil-icon
        v-tooltip="{ content: 'Gegevens wijzigen', placement: 'bottom', html: false }"
        class="icon-pencil"
        @click="editPatientDetails"
      />
    </div>
    <div v-else-if="showPatientDetails() && patientGegevens" class="patient-col">
      <div v-if="patientName">
        <router-link to="/patientdashboard" class="patientname" :class="{ inactiveStatus: patientGegevens.IsArchived }">
          {{ aanhefFormat(patientGegevens.GeslachtId) }}
          <span v-if="patientGegevens.Voorletters">{{ patientGegevens.Voorletters }}</span>
          <span v-if="!patientGegevens.Voorletters">{{ patientGegevens.Voornaam }}</span>
          <span v-if="patientGegevens.Tussenvoegsel">{{ patientGegevens.Tussenvoegsel }}</span>
          <span v-if="patientGegevens.Achternaam">{{ patientGegevens.Achternaam }}</span>
          <span v-if="patientGegevens.IsArchived"> - Inactief</span>
        </router-link>
        <div v-if="!patientGegevens.IsArchived" class="patientdetail">
          <pencil-circle-icon
            v-tooltip="{ content: 'Gegevens wijzigen', placement: 'bottom', html: false }"
            class="pencil-circle"
            @click="editPatientDetails"
          />
        </div>
      </div>
      <div />
    </div>
    <div v-if="checkGegevensWijzigen() && gegevens.PrescriberGegevens">
      {{ gegevens.PrescriberGegevens.BigNummer }}
    </div>
    <div v-if="patientGegevens.BSN && showPatientDetails() && !checkGegevensWijzigen()" class="hidden-md-and-down">
      {{ patientGegevens.BSN }}
    </div>
    <div
      v-if="patientGegevens.BSNnummer && showPatientDetails() && !checkGegevensWijzigen()"
      class="hidden-md-and-down"
    >
      {{ patientGegevens.BSNnummer }}
    </div>
    <div
      v-if="patientGegevens.GeboorteDatum && showPatientDetails() && !checkGegevensWijzigen()"
      class="hidden-md-and-down"
    >
      {{ moment(patientGegevens.GeboorteDatum).format('DD-MM-YYYY') }}
    </div>
    <div
      v-if="showPatientDetails() && patientGegevens.IsDeceased && !checkGegevensWijzigen()"
      class="hidden-md-and-down deceasedIconStyling"
    >
      <img src="@/static/img/christianity.svg" />
    </div>
    <div v-if="showPatientDetails() && !checkGegevensWijzigen()" class="ensurername hidden-md-and-down">
      {{ patientInsurer }}
    </div>
    <div v-if="isOrderConfirmation() && activeDepartment">Aanvullen magazijn {{ activeDepartment.name }}</div>
    <div
      v-else-if="checkGegevensWijzigen()"
      :class="{ removeCustomAlign: !showCart() }"
      class="anotherPatient hidden-sm-and-down"
    >
      <a class="btn btn--link" @click="searchAgain">
        <chevron-right class="icon-color-teal" /> Terug naar Mijn gegevens
      </a>
    </div>
    <div
      v-else-if="showPatientDetails()"
      :class="{ removeCustomAlign: !showCart() }"
      class="anotherPatient hidden-sm-and-down"
    >
      <a class="btn btn--link" @click="searchAgain">
        <chevron-right class="icon-color-teal" /> Andere patiënt selecteren
      </a>
    </div>
    <div v-else-if="isAssortment() && activeDepartment">Aanvullen magazijn {{ activeDepartment.name }}</div>
    <span v-if="showCart()" class="cartContainer">
      <v-cart />
    </span>
  </div>
</template>
<script>
import { useStore, mapActions } from 'vuex';
import { computed, ref } from 'vue';
import router from '@/router';
import moment from 'moment';
import pencilIcon from 'icons/Pencil';
import pencilCircleIcon from 'icons/PencilCircle';
import chevronRight from 'icons/ChevronRight';
import VCart from '@/components/sections/catalog/cart/Index';
import { activeDepartment$ } from '@/services';

export default {
  name: 'Patientheader',
  components: {
    chevronRight,
    pencilIcon,
    VCart,
    pencilCircleIcon
  },
  setup() {
    const store = useStore();
    const activeDepartment = ref(null);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const patientFormData = computed(() => store.getters['patient/getAllPatientFormData']);
    const formPurpose = computed(() => store.getters['patient/getFormPurpose']);
    const patientInsurer = computed(() => store.getters['patient/getPatientInsurer']);
    const gegevens = computed(() => store.getters['prescriber/getPrescriberData']);

    activeDepartment$.subscribe((value) => {
      activeDepartment.value = value;
    });

    return {
      patientGegevens,
      patientFormData,
      formPurpose,
      patientInsurer,
      gegevens,
      ...mapActions('search', ['resetSearch']),
      ...mapActions('patient', ['setEndpoints']),
      activeDepartment
    };
  },
  data() {
    return {
      patientEditButton: false,
      patientName: true
    };
  },
  created() {},
  methods: {
    // Function to show patient details or not based on route name
    showPatientDetails() {
      return router.currentRoute.value.meta.hidePatientDetails !== true;
    },
    // Function to reset search and redirect to home page
    searchAgain(e) {
      this.resetSearch();
      router.push('/');
    },
    // Function to edit patient details
    editPatientDetails(e) {
      e.preventDefault();
      this.setEndpoints('edit');
      var url = '/patientgegevens';
      router.push(url);
      return false;
    },
    // Function to convert datetime to required specific format
    moment(...args) {
      return moment(...args);
    },
    // Function to get prefix before the user
    aanhefFormat(value) {
      if (value === 1) {
        return 'Dhr. ';
      } else if (value === 2) {
        return 'Mevr. ';
      } else {
        return '';
      }
    },
    // Function to whether show the cart or not
    showCart() {
      return router.currentRoute.value.meta.showShoppingCart === true;
    },
    checkGegevensWijzigen() {
      return router.currentRoute.value.name === 'gegevensWijzigen';
    },
    isAssortment() {
      return router.currentRoute.value.flow === 'replenishment';
    },
    isOrderConfirmation() {
      return router.currentRoute.value.name === 'orderconfirmation';
    }
  }
};
</script>

<style lang="scss" scoped>
.inactiveStatus {
  pointer-events: none;
  background-color: $lightest-blue-grey;
  padding: 8px;
  border-radius: 3px;
}
.header-row {
  background: $aqua;
  @media (min-width: 1020px) and (max-width: 1240px) {
    .hidden-md-and-down {
      display: none !important;
    }
  }
  @media (max-width: 490px) and (min-width: 410px) {
  }
  width: 100%;
  height: 90px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding: grid-gutter($breakpoint-key) + 2 grid-gutter($breakpoint-key);
    }
  }
  .btn {
    display: flex;
    align-items: center;
  }
  > div {
    margin-bottom: 0;
    color: $gray-darkest;
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        padding: 0 grid-gutter($breakpoint-key) / 2;
      }
    }
  }
  &:after {
    content: '';
    border: 15px solid transparent;
    border-top-color: $search-primary-background;
    position: absolute;
    top: 100%;
    left: 7%;
    display: flex;
  }
}
.ensurername {
  text-transform: capitalize;
  white-space: nowrap;
  max-width: 320px;
  overflow: hidden;
}
.patientname,
.deviceOverview {
  text-decoration: none;
  color: $gray-darkest !important;
  white-space: nowrap;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  margin: 0.1em 5px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
  display: inline-block;
  overflow: hidden;
  span {
    margin-right: 2px;
    margin-left: 2px;
  }
}
.anotherPatient {
  a {
    color: $teal-darkest !important;
  }
  display: flex;
  position: absolute;
  right: 7%;
  align-items: center;
  @include max-screen(grid-breakpoint(md)) {
    right: 9%;
  }
  @include max-screen(grid-breakpoint(lg)) {
    right: 11%;
  }
}
.cartContainer {
  display: flex;
  position: absolute;
  right: 0%;
  align-items: center;
}
.removeCustomAlign {
  right: 0%;
}
.deceasedIconStyling {
  padding-right: 0px !important;
  padding-top: 0px !important;
}
.pencil-circle {
  fill: $teal-darkest;
  margin: -3px 0px 0px -3px;
  display: flex;
}
.editpatient,
.patientdetail {
  display: inline-block;
  cursor: pointer;
  background-color: $white;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  margin: 0px;
  .material-design-icon {
    width: 28px;
    height: 28px;
    :deep(svg) {
      width: 28px !important;
      height: 28px !important;
    }
  }
  &__content {
    margin: 0px 0px 0px 10px;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
