<template>
  <fieldset class="favourite">
    <div class="row favourite">
      <h1>Favorieten</h1>
      <v-button v-if="favourites.length > 0" hollow transparent @click.prevent="showOverlay">
        <pencil-circle-icon />
      </v-button>
    </div>
    <v-checkbox-group
      v-for="(favourite, index) in favourites"
      :key="index"
      :label="favourite.name"
      :inputid="'favourite.BundleId' + index"
      :initvalue="selected.indexOf(favourite) > -1"
      :overwrite-value="selected.indexOf(favourite) > -1"
      @update="change(favourite, $event)"
    />
    <div v-if="value.length > 0 && isCustomerFlow" class="row grid-padding">
      <v-button md cta class="favourite__orderBtn" @click.prevent="addSelectedFavoriteAllProducts()">
        Producten bestellen
      </v-button>
    </div>
  </fieldset>
</template>

<script>
import router from '@/router';
import VCheckboxGroup from './CheckboxGroup';
import pencilCircleIcon from 'icons/PencilCircle';
import { shoppingCart$, addBundlesToShoppingCart } from '@/services';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VFavouriten',
  components: {
    VCheckboxGroup,
    pencilCircleIcon
  },
  props: {
    favourites: Array,
    value: {
      default: () => [],
      type: Array
    }
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);

    const setOverlayActive = (value) => store.commit('catalog/setOverlayActive', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
    });

    return {
      setOverlayActive,
      shoppingCart
    };
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    isCustomerFlow() {
      return router.currentRoute.value.meta.flow === 'customer';
    }
  },
  methods: {
    showOverlay() {
      this.setOverlayActive(true);
    },
    change(favourite, active) {
      if (active) {
        this.selected.push(favourite);
      } else {
        const index = this.selected.findIndex((item) => item.bundleId === favourite.bundleId);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
      this.$emit('update:modelValue', this.selected);
    },
    /**
     * @function addSelectedFavoriteAllProducts - Function to add all products from the selected favourite to the cart
     */
    addSelectedFavoriteAllProducts() {
      const bundleIds = this.value.map((v) => v.bundleId);
      addBundlesToShoppingCart(this.shoppingCart, bundleIds);
      this.showAlert();
    },
    // Function to show an alert message
    showAlert() {
      this.$emitter.emit('alert', {
        message: 'Alle producten zijn toegevoegd aan de bestellijst.'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.favourite {
  border: none;
  margin: 0;
  padding: 0;
  > h1 {
    font-size: type-scale(2);
    margin-top: 0;
    margin-bottom: 10px;
    color: $gray-darkest;
  }
  .checkbox-group {
    margin-bottom: 10px;
  }
  .material-design-icon {
    width: 24px;
    margin-top: -10px;
    fill: $teal-darkest;
  }
}
</style>
