import moment from 'moment';

/**
 * @param {string} dateString - String value representing a date. The string should be in a format recognized by the Date.parse()
 */
export function formatDate(dateString) {
  if (!dateString) {
    return;
  }
  return moment(dateString).format('DD-MM-YYYY');
}
