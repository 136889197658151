import axios from 'axios'
import moment from 'moment'
import FileSaver from 'file-saver'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    preferences: [],
    currentDeptIndex: 0,
    emailNotificationStatusChanged: true,
    prescriberData: {},
    depts: null,
    pdf: null,
    showIframe: false,
    signatureSaved: false
  },
  mutations: {
    setPrescriberPreferences (state, payload) {
      state.preferences = payload
    },
    setPrescriberData (state, payload) {
      state.prescriberData = payload
    },
    setDeptCount (state, payload) {
      state.depts = payload
    },
    setEmailNotificationByDept (state, payload) {
      state.emailNotificationStatusChanged = false
      state.prescriberData.PrescriberAfdelingen[state.currentDeptIndex]['Wanttoreceiveemails'] = payload
    },
    setPdf (state, payload) {
      state.pdf = payload
    },
    setPrescriberChatIframe (state, payload) {
      window.localStorage.setItem('showIframe', payload)
      state.showIframe = payload
    },
    setSignatureSaved (state, payload) {
      state.signatureSaved = payload
    }
  },
  actions: {
    fetchPrescriberFAQUrl () {
      return axios.get('PrescriberFAQ/GetFAQConfiguration')
    },
    fetchPrescriberPreferences ({ commit, state }) {
      // Fetch prescriber preferences
      return axios.get('User/GetPreferences').then(response => {
        commit('setPrescriberPreferences', response.data.UserPreferences)
      })
    },
    UpdatePrescriberPreferences ({ state }, details) {
      return axios.post('User/SetPreference?id=' + details.id + '&value=' + details.value).then(response => {
        if (response.data.Success) {
          this.dispatch('prescriber/fetchPrescriberPreferences')
        }
      })
    },
    fetchPrescriberData ({ commit, state }) {
      return axios.get('User/GetPrescriberData').then(response => {
        commit('setPrescriberData', response.data)
        commit('setDeptCount', response.data.PrescriberAfdelingen.length)
      })
    },
    submitPrescriber ({ commit, state }, args) {
      this.dispatch('search/formToJSON', args.elements).then((prescriberFormObject) => {
        let birthDayFormat = moment(prescriberFormObject.GeboorteDatum, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00'
        let prescriberGegevens = {
          'ExternalID': parseInt(args.gegevens.PrescriberGegevens.ExternalID),
          'GeslachtId': parseInt(args.gegevens.PrescriberGegevens.GeslachtId, 10) !== parseInt(prescriberFormObject.GeslachtId, 10) ? parseInt(prescriberFormObject.GeslachtId, 10) : 0,
          'Voorletters': args.gegevens.PrescriberGegevens.Voorletters !== prescriberFormObject.Voorletters ? prescriberFormObject.Voorletters : '',
          'Voornaam': args.gegevens.PrescriberGegevens.Voornaam !== prescriberFormObject.Voornaam ? prescriberFormObject.Voornaam : '',
          'Tussenvoegsel': args.gegevens.PrescriberGegevens.Tussenvoegsel !== prescriberFormObject.Tussenvoegsel ? prescriberFormObject.Tussenvoegsel : '',
          'Achternaam': args.gegevens.PrescriberGegevens.Achternaam !== prescriberFormObject.Achternaam ? prescriberFormObject.Achternaam : '',
          'Geboortenaam': args.gegevens.PrescriberGegevens.Geboortenaam !== prescriberFormObject.GeboorteNaam ? prescriberFormObject.GeboorteNaam : '',
          'GeboorteDatum': (args.gegevens.PrescriberGegevens.GeboorteDatum !== birthDayFormat && args.gegevens.PrescriberGegevens.GeboorteDatum !== '') ? birthDayFormat : '',
          'BigNummer': args.gegevens.PrescriberGegevens.BigNummer !== prescriberFormObject.BigNumber ? prescriberFormObject.BigNumber : '',
          'AGBNummer': args.gegevens.PrescriberGegevens.AGBNummer !== prescriberFormObject.AGBNumber ? prescriberFormObject.AGBNumber : '',
          'TelefoonnummerVast': args.gegevens.PrescriberGegevens.TelefoonnummerVast !== prescriberFormObject.TelefoonnummerVast ? prescriberFormObject.TelefoonnummerVast : '',
          'TelefoonnummerMobiel': args.gegevens.PrescriberGegevens.TelefoonnummerMobiel !== prescriberFormObject.TelefoonnummerMobiel ? prescriberFormObject.TelefoonnummerMobiel : '',
          'EmailAdres': args.gegevens.PrescriberGegevens.EmailAdres !== prescriberFormObject.Emailadres ? prescriberFormObject.Emailadres : ''
        }
        let prescriberDepartment = []
        for (var i = 0; i < args.gegevens.PrescriberAfdelingen.length; i++) {
          var changedKey = args.gegevens.PrescriberAfdelingen[i]
          var originalKey = args.departments[i]
          if (args.showdept === true || originalKey.NaamWerkgever !== changedKey.NaamWerkgever || originalKey.AGBWerkgever !== changedKey.AGBWerkgever || originalKey.AfdelingenName !== changedKey.AfdelingenName || originalKey.AfdelingenEmail !== changedKey.AfdelingenEmail || originalKey.Depot !== changedKey.Depot || originalKey.Wanttoreceiveemails !== changedKey.Wanttoreceiveemails) {
            prescriberDepartment.push({
              NaamWerkgever: changedKey.NaamWerkgever,
              AGBWerkgever: changedKey.AGBWerkgever,
              AfdelingenName: changedKey.AfdelingenName,
              AfdelingenEmail: changedKey.AfdelingenEmail,
              Depot: changedKey.Depot,
              Wanttoreceiveemails: changedKey.Wanttoreceiveemails,
              PpwRecId: changedKey.PpwRecId
            })
          } else {
            prescriberDepartment.push({
              NaamWerkgever: '',
              AGBWerkgever: '',
              AfdelingenName: '',
              AfdelingenEmail: '',
              Depot: '',
              Wanttoreceiveemails: 0,
              PpwRecId: ''
            })
          }
        }
        let dataToBeSubmitted = {
          'PrescriberGegevens': prescriberGegevens,
          'PrescriberAfdelingen': prescriberDepartment
        }
        return axios.post('User/GeneratePrescriberDataPdf', dataToBeSubmitted,
          {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'})
          .then(response => {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
            FileSaver.saveAs(pdfBlob, 'export.pdf')
            commit('setPdf', response.data)
            router.push('/')
          })
      })
    },
    fetchChatStatusMessage ({ state }) {
      return axios.get('Chat/GetChatConfiguration')
    }
  },
  getters: {
    getEmailNotificationByDept: state => {
      return state.prescriberData.PrescriberAfdelingen[state.currentDeptIndex]
    },
    getPrescriberPreferences: state => {
      return state.preferences
    },
    getPrescriberData: state => {
      return state.prescriberData
    },
    getDeptCount: state => {
      return state.depts
    },
    getPdf: state => {
      return state.pdf
    },
    getPrescriberChatIframe: state => {
      if (window.localStorage.getItem('showIframe') === 'true') {
        state.showIframe = true
      }
      return state.showIframe
    },
    getSignatureSaved: state => {
      return state.signatureSaved
    }
  }
}
