import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import axios from "axios";

var storeKey = 'lastSelectedDepartment';
const lastStored = window.localStorage.getItem(storeKey);
setAxiosPpwRecId(lastStored);
const storedActiveDepartmentSubject$ = new BehaviorSubject(lastStored);

export function storeActiveDepartment (departmentId) {
  window.localStorage.setItem(storeKey, departmentId);
  setAxiosPpwRecId(departmentId);
  storedActiveDepartmentSubject$.next(departmentId);
}

export const storedActiveDepartment$ = storedActiveDepartmentSubject$.pipe(
  distinctUntilChanged()
).asObservable();

// trick: keep it until we made all api calls accept the ppwRecId as normal argument
function setAxiosPpwRecId(ppwRecId) {
  axios.defaults.headers.common["ppwrecid"] = ppwRecId;
}
