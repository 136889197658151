<template>
  <div class="overlay">
    <div>
      <slot />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'VOverlay'
  }
</script>
<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999 !important;

    .heading {
      margin-bottom: 8px;
    }

    > div {
      position: relative;
      z-index: 99999998 !important;
      text-align: left;
      padding: 2rem;
      background: $white;
      width: 100%;
      max-width: 600px;
      box-sizing: border-box;
    }
  }
</style>
