<template>
  <tr class="mypatients__row">
    <td class="deceadedIconStylling">
      <img
        v-if="patient.IsDeceased"
        src="@/static/img/christianity.svg"
      >
    </td>
    <th scope="row">
      <a
        href=""
        @click.prevent="selectPatient"
      >{{ `${ patient.Achternaam }, ${ patient.Voorletters } ${ (patient.Tussenvoegsel) ? ' ' + patient.Tussenvoegsel : '' }` }}</a>
    </th>
    <td>{{ aanhefFormat(patient.GeslachtId) }}</td>
    <td>{{ moment(patient.GeboorteDatum).format('DD-MM-YYYY') }}</td>
    <td>{{ patient.BSN }}</td>
    <td>{{ patient.WoonAdres.Postcode }}</td>
    <td>{{ patient.WoonAdres.Huisnummer }}</td>
    <td>{{ patient.WoonAdres.Woonplaats }}</td>
    <td class="justify-content-end">
      <v-button
        tagelement="button"
        md
        primary
        hollow
        @click.prevent="showModal"
      >
        Ontvolgen
      </v-button>
    </td>
    <generic-modal v-if="isModalVisible">
      <template #header>
        Patiënt ontvolgen
      </template>
      <template #body>
        <p>U staat op het punt om een patiënt te ontvolgen. Door het ontvolgen van een patiënt ontvangt u geen updates meer met betrekking tot zijn/haar behandeling. Weet u zeker dat u wilt doorgaan?</p>
      </template>
      <template #footer>
        <v-button
          tagelement="button"
          md
          hollow
          transparent
          @click="closeModal"
        >
          Annuleren
        </v-button>
        <v-button
          tagelement="button"
          md
          primary
          @click="SaveUnfollowPatient"
        >
          Patiënt toch ontvolgen
        </v-button>
      </template>
    </generic-modal>
  </tr>
</template>

<script>
import moment from 'moment'
import GenericModal from '@/components/shared/GenericModal'
import { currentUserIdentity$ } from '@/services';
import { ref } from 'vue'
import { useStore } from "vuex";

export default {
  components: { GenericModal },
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const user = ref(null);

    const saveUnfollowPatient = (value) => store.dispatch('mypatients/saveUnfollowPatient', value);

    currentUserIdentity$.subscribe(value => {
      user.value = value;
    });

    return {
      saveUnfollowPatient,
      user
    }
  },
  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    moment (...args) {
      return moment(...args)
    },
    selectPatient () {
      this.$emit('select', this.patient.PatientId)
    },
    aanhefFormat (value) {
      if (value === 1) {
        return 'M '
      } else if (value === 2) {
        return 'V '
      } else {
        return ''
      }
    },
    SaveUnfollowPatient () {
      this.showPatientOntvolgen = false;
      this.saveUnfollowPatient(this.patient.PatientId).then((response) => {
        if (response.Success) {
          this.$emit('query');
        }
      })
      this.isModalVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .mypatients__row {
    .deceadedIconStylling {
      padding-right: 0px !important;
      padding-top: 0px !important;
    }
    .deceadedIconStylling img {
      float: right;
      margin: -4px -4px -8px 0px;
    }
    .unfollow {
      padding: 10px !important;
    }
    th, td {
      vertical-align: middle;
      text-align: left;
      padding: 12px 20px !important;
      font-weight: normal;
    }
    a {
      color: $teal-darkest;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    thead {
      padding-bottom: 2px; // TODO: 2px margin-bottom
      td, th {
        background-color: $teal-lighter;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    tbody {
      tr { border: 1px solid $gray-light; }
      th, td {
        vertical-align: middle !important;
        padding-top: 17px;
        padding-bottom: 32px !important;
      }
    }
  }
</style>
