<script>
import { h } from 'vue';

export default {
  name: 'VButton',
  props: {
    tagelement: {
      default: 'button',
      type: String
    },
    anchor: {
      default: '#',
      type: String
    },
    primary: {
      default: false,
      type: Boolean
    },
    secondary: {
      default: false,
      type: Boolean
    },
    link: {
      default: false,
      type: Boolean
    },
    inverted: {
      default: false,
      type: Boolean
    },
    alt: {
      default: false,
      type: Boolean
    },
    cta: {
      default: false,
      type: Boolean
    },
    hollow: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    transparent: {
      default: false,
      type: Boolean
    },
    sm: {
      default: false,
      type: Boolean
    },
    md: {
      default: false,
      type: Boolean
    },
    lg: {
      default: false,
      type: Boolean
    },
    lq: {
      default: false,
      type: Boolean
    },
    iconLeft: {
      default: false,
      type: Boolean
    },
    iconRight: {
      default: false,
      type: Boolean
    },
    nowrap: {
      default: false,
      type: Boolean
    }
  },
  render() {
    return h(
      this.tagelement,
      {
        class: {
          'ga-track-btn': 'ga-track-btn',
          btn: 'btn',
          'btn--primary': this.primary,
          'btn--secondary': this.secondary,
          'btn--link': this.link,
          'btn--inverted': this.inverted,
          'btn--cta': this.cta,
          'btn--hollow': this.hollow,
          'btn--alt': this.alt,
          'btn--disabled': this.disabled,
          'btn--transparent': this.transparent,
          'btn--sm': this.sm,
          'btn--md': this.md,
          'btn--lg': this.lg,
          'btn--lq': this.lq,
          'btn--icon-left': this.iconLeft,
          'btn--icon-right': this.iconRight,
          'btn--nowrap': this.nowrap
        },
        href: this.anchor
      },
      [this.$slots.default()]
    );
  }
};
</script>

<style lang="scss">
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  border: $btn-border;
  border-radius: $btn-border-radius;
  transition: $btn-transition;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none !important;
  }

  > .material-design-icon svg {
    display: block;
    fill: currentColor;
  }

  &--icon-left > .material-design-icon {
    margin-right: 10px;
  }
  &--icon-right > .material-design-icon {
    margin-left: 10px;
  }

  &--sm {
    @include btn-size($btn-sm-width, $btn-sm-height, $btn-sm-padding, $btn-sm-text-transform);
  }

  &--md {
    @include btn-size($btn-md-width, $btn-md-height, $btn-md-padding, $btn-md-text-transform);
  }

  &--lg {
    @include btn-size($btn-lg-width, $btn-lg-height, $btn-lg-padding, $btn-lg-text-transform);
  }

  &--lq {
    @include btn-size($btn-lq-width, $btn-lq-height, $btn-lq-padding, $btn-lg-text-transform);
  }

  &--primary {
    @include btn-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-font-weight);
  }

  &--secondary {
    @include btn-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-secondary-font-weight);
  }

  &--cta {
    @include btn-variant($btn-cta-color, $btn-cta-bg, $btn-cta-border, $btn-cta-font-weight);
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--disabled {
    @include btn-variant($btn-disabled-color, $btn-disabled-bg, $btn-disabled-border, $btn-disabled-font-weight);
    pointer-events: none;
  }

  &--hollow {
    @include btn-variant($btn-hollow-color, $btn-hollow-bg, $btn-hollow-border, $btn-hollow-font-weight);
  }

  &--transparent {
    background: transparent;
    border-color: transparent;
  }

  &--hollow.btn--disabled {
    @include btn-variant(
      $btn-hollow-disabled-color,
      $btn-hollow-disabled-bg,
      $btn-hollow-disabled-border,
      $btn-hollow-disabled-font-weight
    );
  }

  &--hollow.btn--inverted {
    @include btn-variant(
      $btn-hollow-inverted-color,
      $btn-hollow-inverted-bg,
      $btn-hollow-inverted-border,
      $btn-hollow-inverted-font-weight
    );
  }

  &--hollow.btn--inverted.btn--alt {
    @include btn-variant(
      $btn-hollow-inverted-alt-color,
      $btn-hollow-inverted-alt-bg,
      $btn-hollow-inverted-alt-border,
      $btn-hollow-inverted-alt-font-weight
    );
  }

  &--link {
    justify-content: flex-start;
    height: auto;
    @include btn-link-variant($btn-link-primary-color, $btn-link-primary-font-weight);
    &:hover {
      text-decoration: underline;
    }
  }

  &--link.btn--inverted {
    height: auto;
    @include btn-link-variant($btn-link-secondary-color, $btn-link-secondary-font-weight);
  }
}
</style>
