<template>
  <tbody class="osa-details-row" :class="[{ 'is-expanded': isExpanded }]">
    <tr class="osa-details-row__data" ref="row" @click="toggle()">
      <td class="osa-details-row__cell">{{ moment(data.EvaluationDate).format('DD-MM-YYYY') }}</td>
      <td class="osa-details-row__cell">{{ data.OsaType }}</td>
      <td class="osa-details-row__cell"><img :src="getMoodRatioImage(data.PatientComplaint)" /></td>
      <td class="osa-details-row__cell" v-if="data.OsaType !== 'Evaluation-Spt'">{{ data.Ahi }}</td>
      <td class="osa-details-row__cell" v-else></td>
      <td class="osa-details-row__cell" v-if="data.PatientsFinalAssessment">
        <span class="osa-details-row__status" :class="getStatusClassName(data.PatientsFinalAssessment)"></span>
        {{ data.PatientsFinalAssessment }}
      </td>
      <td class="osa-details-row__cell" v-else></td>
      <td class="osa-details-row__cell justify-content-end">
        <v-button v-if="isExpanded" tagelement="button" md primary @click.stop="toggle()"> Toon minder </v-button>
        <v-button v-else tagelement="button" md primary @click.stop="toggle()"> Toon meer </v-button>
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="6">
        <p class="osa-details-row__header">Therapietrouw (Uitgelezen gemiddelde over laatste 3 weken)</p>
      </td>
    </tr>
    <tr
      class="osa-details-row__data"
      ref="row"
      v-if="isExpanded && osaRowOverviewDetails && data.OsaType !== 'Evaluatie-Spt'"
    >
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">AHI</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.Ahi }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && osaRowOverviewDetails">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">
        Hoeveel uur slaapt patiënt gemiddeld per nacht met het apparaat?:
      </td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.AvgHoursSleep }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && osaRowOverviewDetails">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">
        Hoeveel dagen per week slaapt de patiënt gemiddeld met het apparaat?:
      </td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.AvgDaysSleep }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && osaRowOverviewDetails">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">
        Heeft de patiënt gewenningsproblemen?:
      </td>
      <td
        class="osa-details-row__cell osa-details-row__cellDetail"
        colspan="2"
        v-if="osaRowOverviewDetails.HabituationProblems"
      >
        Ja
      </td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2" v-else>Nee</td>
    </tr>
    <tr v-if="isExpanded && osaRowOverviewDetails" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Hypersomnolentie:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.Hypersomnolence }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && osaRowOverviewDetails">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">
        Hoe voelt de patiënt zich ten opzichte van zijn of haar klachten?:
      </td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.PatientComplaint }}
      </td>
    </tr>
    <tr
      class="osa-details-row__data"
      ref="row"
      v-if="isExpanded && osaRowOverviewDetails && osaRowOverviewDetails.PatientComplaintNote"
    >
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Opmerking patiënt:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.PatientComplaintNote }}
      </td>
    </tr>
    <tr
      class="osa-details-row__data"
      ref="row"
      v-if="isExpanded && osaRowOverviewDetails && osaRowOverviewDetails.PartnerComplaint !== 0"
    >
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">
        Hoe voelt de partner zich ten opzichte van de klachten van de patiënt?:
      </td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.PartnerComplaint }}
      </td>
    </tr>
    <tr
      class="osa-details-row__data"
      ref="row"
      v-if="isExpanded && osaRowOverviewDetails && osaRowOverviewDetails.PartnerComplaintNote"
    >
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Opmerking partner:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.PartnerComplaintNote }}
      </td>
    </tr>
    <tr
      class="osa-details-row__data"
      ref="row"
      v-if="isExpanded && osaRowOverviewDetails && osaRowOverviewDetails.PatientsFinalAssessment"
    >
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Eindoordeel patiënt:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.PatientsFinalAssessment }}
      </td>
    </tr>
    <tr v-if="isExpanded && osaRowOverviewDetails" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Opmerkingen evaluatie:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.EvaluationComments }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="getVisibilityStatus()">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="6">
        <p class="osa-details-row__header">Instellen apparatuur definitieve aanvraag</p>
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">CPAP</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.Cpap }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Auto CPAP - Min:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.AutoCpapMin }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Auto CPAP - Max:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.AutoCpapMax }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">BiPAP - IPAP:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.Ipap }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">BiPAP - EPAP:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.Epap }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Ramp instelling (minuten):</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.RampSettingsMinutes }}
      </td>
    </tr>
    <tr v-if="getVisibilityStatus()" ref="row" class="osa-details-row__data">
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="4">Minimale ramp druk:</td>
      <td class="osa-details-row__cell osa-details-row__cellDetail" colspan="2">
        {{ osaRowOverviewDetails.MinimalDisasterPressure }}
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && data.OsaType === 'Evaluatie'">
      <td class="osa-details-row__cell" colspan="4">
        <span>
          <p>Evaluatie gereed voor akkoordverklaring:</p>
          <p class="osa-details-row__header">Evaluatie door:</p>
        </span>
      </td>
      <td class="osa-details-row__cell" colspan="2">
        <span>
          <p class="osa-details-row__header">
            <component :is="statusIcon" :class="{ 'status-active': isApproved, 'status-inactive': !isApproved }" />
            {{ statusText }}
          </p>
          <p v-if="user" class="osa-details-row__header">{{ user.username }}</p>
        </span>
      </td>
    </tr>
    <tr class="osa-details-row__data" ref="row" v-if="isExpanded && osaRowOverviewDetails">
      <td class="osa-details-row__cell" colspan="4">
        <p class="osa-details-row__header">Upload en bekijk recente uitlezingen:</p>
      </td>
      <td class="osa-details-row__cell" colspan="2">
        <!-- Documenten button //-->
        <v-button md primary iconLeft hollow @click="loadPdfDetails()">
          <icon :is="'VDownloadIcon'"></icon>
          Documenten
        </v-button>
      </td>
    </tr>
    <generic-modal :hasCloseButton="true" v-if="showPdfDetailsPopUp" @close="closeModal" class="pdfDetailsPopUp">
      <template #body>
        <div class="row titleSection" :class="{ customPadding: showUploadedFiles }">
          <div class="col-md-6 pdfHeader">Documenten</div>
          <div class="col-md-6 grid-align-end" v-if="!showUploadedFiles">
            <v-button md primary iconLeft hollow @click="openCompactFileUpload">
              <icon :is="'VFileUploadIcon'"></icon>
              Document toevoegen
            </v-button>
          </div>
        </div>
        <div class="no-padding" v-show="showUploadedFiles">
          <v-input-file-upload-compact
            :id="'file' + data.accountabilityId"
            :uploadedFilesData="licensePdfDetails"
            :accountabilityUpload="true"
            :allowedExtenstions="allowedFileExtenstions"
            :extensionErrorMessage="extensionErrorMessage"
            @file-added="showUploadedFileSection"
            @upload-success="saveUploadedFiles"
          ></v-input-file-upload-compact>
        </div>
        <v-table id="licensePdfDetails">
          <thead>
            <v-header-row :data="pdfTableHeaders" v-show="!showUploadedFiles"></v-header-row>
          </thead>
          <tbody>
            <tr v-for="(item, index) in licensePdfDetails" :key="index" :class="{ fileUploadRow: !showUploadedFiles }">
              <td></td>
              <td>{{ item.documentType }}</td>
              <td @click="downloadLicensePdf(item)" class="licenseDownloadLink">{{ item.fileName }}</td>
              <td colspan="2" class="actionIcons" :class="{ alignIcon: showUploadedFiles }">
                <v-delete-icon
                  v-if="item.isDeletable"
                  v-tooltip="{ content: 'Document verwijderen', placement: 'left', html: false }"
                  @click.prevent="deleteAccountabilityPdf(item)"
                ></v-delete-icon>
              </td>
            </tr>
            <tr v-if="!licensePdfDetails || (licensePdfDetails && licensePdfDetails.length === 0)">
              <td></td>
              <td colspan="4">
                Nog geen documenten toegevoegd. Voeg hier uw document toe om de machtiging compleet te maken.
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md transparent primary @click="closeModal()">Sluiten</v-button>
      </template>
    </generic-modal>
  </tbody>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import VActiveIcon from 'icons/CheckCircle';
import VDownloadIcon from 'icons/Download';
import VInputFileUpload from '@/components/shared/form/simple/InputFileUpload';
import VFileUploadIcon from 'icons/FileUploadOutline';
import VInputFileUploadCompact from '@/components/shared/form/simple/InputFileUploadCompact';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VDeleteIcon from 'icons/Delete';
import VTable from '@/components/shared/table/Table';
import moment from 'moment';
import VCloseIcon from 'icons/CloseCircle';
import FileSaver from 'file-saver';
import shared from '@/components/shared/shared';
import GenericModal from '@/components/shared/GenericModal';
import { currentUserIdentity$, getOsaOverviewDetails } from '@/services';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'OsaDetailsRow',
  components: {
    VActiveIcon,
    VDownloadIcon,
    VInputFileUpload,
    VCloseIcon,
    VFileUploadIcon,
    VInputFileUploadCompact,
    VHeaderRow,
    VTable,
    VDeleteIcon,
    GenericModal
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const store = useStore();
    const user = ref(null);

    const saveAccountabilityFiles = (value) => store.dispatch('accountability/saveAccountabilityFiles', value);

    currentUserIdentity$.subscribe((value) => {
      user.value = value;
    });

    return {
      store,
      saveAccountabilityFiles,
      user
    };
  },
  data() {
    return {
      isExpanded: false,
      animated: false,
      osaRowOverviewDetails: {},
      osaPDF: null,
      showUploadedFiles: false,
      showPdfDetailsPopUp: false,
      licensePdfDetails: [],
      pdfTableHeaders: ['', 'Document Type', 'Omschrijving', '', ''],
      allowedFileExtenstions: ['pdf', 'jpg', 'jpeg', 'png'],
      extensionErrorMessage:
        'Het geüploade document heeft niet de juiste extensie. U kunt alleen PDF, JPEG, JPG, of PNG documenten uploaden.'
    };
  },
  computed: {
    isApproved() {
      return this.osaRowOverviewDetails.IsApproved || this.osaRowOverviewDetails.IsReadyForApproval;
    },
    statusIcon() {
      return this.isApproved ? 'VActiveIcon' : 'VCloseIcon';
    },
    statusText() {
      return this.isApproved ? 'Therapie slaat aan' : 'Therapie slaat niet aan';
    }
  },
  created() {
    this.animated = true;
    setTimeout(() => {
      this.animated = false;
    }, 1000);
  },
  methods: {
    openFileInNewTab: shared.openFileInNewTab,
    fileExtention: shared.fileExtention,
    /**
     * @function moment - Convert date into desired format
     */
    moment(...args) {
      return moment(...args);
    },
    /**
     * @function toggle - Function to toggle the expand button
     */
    toggle() {
      if (!this.isExpanded) {
        this.fetchOSARowOverview();
      }
      setTimeout(() => {
        this.isExpanded = !this.isExpanded;
      }, 2000);
    },
    /**
     * @function fetchOSARowOverview - Fetch osa row overview
     */
    async fetchOSARowOverview() {
      let type = 'Evaluation';
      if (this.data.OsaType === 'Uitlezing') {
        type = 'Readout';
      }
      if (this.data.OsaType === 'Evaluation-Spt') {
        type = 'EvaluationSpt';
      }
      this.osaRowOverviewDetails = await getOsaOverviewDetails({
        osaType: type,
        osaId: this.data.OsaId,
        licenseId: 0
      });
    },
    /**
     * @function saveUploadedFiles - Function to upload file
     */
    saveUploadedFiles(fmData, licenceId, filetype) {
      let fileDetails = {
        licenseId: this.osaRowOverviewDetails.LicenseId,
        accountabilityId: this.osaRowOverviewDetails.AccountabilityId,
        documentType: filetype,
        formData: fmData
      };
      this.store
        .dispatch('accountability/saveAccountabilityFiles', fileDetails)
        .then((response) => {
          this.loadPdfDetails();
        })
        .catch((error) => {});
    },
    /**
     * @function getStatusClassName - Function to get class based on final Assesment
     */
    getStatusClassName(status) {
      switch (status) {
        case 'Doorgaan':
          return 'status-on';
        case 'Doorgaan na aanpassing(en)':
          return 'status-continue';
        case 'Stoppen':
          return 'status-stop';
        default:
      }
    },
    /**
     * @function getMoodRatioImage - Function to get mood image for rating
     */
    getMoodRatioImage(value) {
      return '/moodRatio' + value + '.svg';
    },
    /**
     * @function getVisibilityStatus - Function to show or not the installment details
     */
    getVisibilityStatus() {
      return (
        this.osaRowOverviewDetails &&
        this.osaRowOverviewDetails.IsSettingChangedFromOriginal &&
        this.isExpanded &&
        this.data.OsaType === 'Evaluatie'
      );
    },
    // Marker comment
    /**
     * @function goToApproval - Function to redirect prescriber to approval form
     */
    goToApproval() {
      let type = 'Evaluation';
      if (this.data.OsaType === 'Uitlezing') {
        type = 'Readout';
      }
      if (this.data.OsaType === 'Evaluation-Spt') {
        type = 'EvaluationSpt';
      }
      if (this.data.OsaType === 'Evaluatie') {
        router.push('/osa/akkoordverklaring/' + type + '/' + this.data.OsaId);
      } else {
        router.push('/osa/akkoordverklaring-posa-spt/' + type + '/' + this.data.OsaId);
      }
    },
    loadPdfDetails() {
      axios
        .post('Accountability/GetAllAccountabilityDocuments', {
          licenseId: this.osaRowOverviewDetails.LicenseId,
          accountabilityId: this.osaRowOverviewDetails.AccountabilityId,
          metaOnly: true
        })
        .then((response) => {
          if (response && response.data) {
            this.licensePdfDetails = response.data;
            this.showPdfDetailsPopUp = true;
          }
        });
    },
    async downloadLicensePdf(item) {
      if (item.document !== null) {
        this.downloadPdf({
          fileName: item.fileName,
          content: item.document
        });
        return;
      }
      axios
        .post('Accountability/GetSingleAccountabilityDocument', {
          documentGUID: item.documentGUID
        })
        .then((response) => {
          if (response && response.data) {
            this.downloadPdf({
              fileName: response.data.fileName,
              content: response.data.document
            });
          }
        });
    },
    downloadPdf(details) {
      const linkSource = `data:application/pdf;base64,${details.content}`;
      const downloadLink = document.createElement('a');
      const fileName = details.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    },
    deleteAccountabilityPdf(item) {
      this.showPdfDetailsPopUp = false;
      // Start a promise, call for a prompt
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Documenten verwijderen',
          message: 'U staat op het punt om document ' + item.fileName + ' definitief te verwijderen. Weet u het zeker?',
          resolve,
          reject
        });
      })
        .then((payload) => {
          // When prompt was confirmed
          axios
            .delete('Accountability/DeleteAccountabilityDocument', {
              params: {
                documentGUID: item.documentGUID,
                documentUUID: item.documentUUID
              }
            })
            .then((response) => {
              if (response && response.data) {
                this.$emitter.emit('alert', {
                  message: 'Document is succesvol verwijderd.',
                  type: 0
                });
                this.loadPdfDetails();
                this.showPdfDetailsPopUp = true;
              }
            })
            .catch(() => {
              this.$emitter.emit('alert', {
                message: 'Het verwijderen van dit document is niet gelukt. Probeer het later nogmaals.',
                type: 3
              });
              this.showPdfDetailsPopUp = true;
            });
        })
        .catch(() => {
          // When cancelled
          this.showPdfDetailsPopUp = true;
        });
    },
    openCompactFileUpload() {
      document.getElementById('file' + this.osaRowOverviewDetails.accountabilityId).click();
    },
    showUploadedFileSection(value) {
      this.showUploadedFiles = value;
    },
    closeModal() {
      this.showPdfDetailsPopUp = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.osa-details-row {
  background-color: $table-row-background;
  border: $table-row-border;
  transition: $search-transition;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  &__status {
    height: 24px;
    width: 24px;
    background-color: $grayish-gray;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    vertical-align: inherit;
    &.status-on {
      background-color: $cyan-darker;
    }
    &.status-continue {
      background-color: $orange;
    }
    &.status-stop {
      background-color: $pure-red;
    }
  }
  &__cellDetail {
    padding: 5px 10px 5px 10px !important;
  }
  &__cell {
    color: $table-row-cell-color;
    font-size: $table-row-cell-font-size;
    line-height: $table-row-cell-line-height;
    justify-content: $table-row-cell-justify-content;
    padding: 16px 8px;
    vertical-align: $table-row-license-cell-vertical-align;
    a {
      text-decoration: underline;
      font-size: 16px;
      color: $gray-darkest;
      cursor: pointer;
      margin: 0px 10px 0 35px;
      vertical-align: sub;
    }
    img {
      position: absolute;
      height: 32px;
      margin: 0px;
    }
    @mixin list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    > .material-design-icon {
      display: flex;
      justify-content: center;
      fill: $black;
      :deep(svg) {
        display: block;
      }
    }
  }
  #licensePdfDetails {
    width: calc(100% + 50px);
    max-width: calc(100% + 50px);
    margin: 16px -25px;
    .material-design-icon {
      fill: $teal-darkest;
      cursor: pointer;
    }
    :deep(th:first-child) {
      width: auto;
    }
    td {
      padding: $table-row-license-cell-padding;
    }
    > th:last-child,
    td:last-child {
      min-width: 230px;
      justify-content: flex-end;
      padding: 1rem 1.5rem 1rem 0.5rem;
    }
    .fileUploadRow {
      td {
        display: table-cell !important;
        min-width: unset !important;
      }
    }
    .licenseDownloadLink {
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
    }
    .actionIcons {
      text-align: left;
    }
    .alignIcon {
      span {
        float: right;
      }
    }
  }
  .pdfDetailsPopUp {
    .titleSection {
      padding: 8px 0px 0px 16px;
    }
    .customPadding {
      padding: 8px 0px 0px 8px !important;
    }
    :deep(.generic-modal) {
      min-width: 800px;
    }
  }
  .pdfHeader {
    display: flex;
    font-size: 26px;
    font-weight: bold;
    justify-content: space-between;
  }
  .status-active {
    fill: $cyan-darker !important;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: top;
  }
  .status-inactive {
    fill: $pure-red !important;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: top;
  }
  &__header {
    font-weight: bold;
  }
  label {
    font-weight: 900;
    color: $gray-dark;
    margin: 15px 15px 15px 10px;
  }
  legend {
    margin: 10px 0 10px 10px;
  }
}
</style>
