<template>
  <div class="favouritesEdit">
    <generic-modal v-if="confirmDeleteOverlay">
      <template #header> Let op! </template>
      <template #body>
        <p>Weet u zeker dat u de favorietenlijst wilt verwijderen?</p>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md primary transparent @click.prevent="closeConfirmDeleteOverlay()">
          Annuleren
        </v-button>
        <v-button tagelement="button" md primary @click.prevent="saveFavouriteEditForm()">Verwijderen</v-button>
      </template>
    </generic-modal>
    <generic-modal v-if="confirmCloseFavoriteList || confirmChangeActiveFavoriteList !== null">
      <template #header> Let op! </template>
      <template #body>
        <p>Wilt u sluiten zonder uw wijzigingen op te slaan?</p>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow md primary transparent @click.prevent="discardFavoriteEditForm()">
          Sluiten
        </v-button>
        <v-button tagelement="button" md primary @click.prevent="saveFavouriteEditForm()">Opslaan en sluiten</v-button>
      </template>
    </generic-modal>
    <div class="row" v-else>
      <div class="col-md-3 favouritesEdit__favouriteList">
        <div class="row favouritesEdit__favouriteListOrder">
          <h2>Favorieten</h2>
          <v-button
            v-if="isOrderAsc"
            md
            hollow
            transparent
            v-tooltip="{ content: 'Oplopend gesorteerd', placement: 'top' }"
            @click.prevent="orderFavorites()"
          >
            <v-order-alphabetical-ascending></v-order-alphabetical-ascending>
          </v-button>
          <v-button
            v-if="!isOrderAsc"
            md
            hollow
            transparent
            v-tooltip="{ content: 'Aflopend gesorteerd', placement: 'top' }"
            @click.prevent="orderFavorites()"
          >
            <v-order-alphabetical-descending></v-order-alphabetical-descending>
          </v-button>
        </div>
        <div class="row">
          <div class="col-md-12 favouritesEdit__favoriteListFilter">
            <m-input
              id="inputFilter"
              name="inputFilter"
              type="text"
              :value="favoriteFilterByValue"
              v-model="favoriteFilterByValue"
              @input="onFavoriteFilterInput($event.target.value)"
            >
            </m-input>
          </div>
          <div class="row col-md-12 favouritesEdit__favoriteListFilter">
            <v-button md cta :disabled="favoriteFilterByValue === null" @click.prevent="filterFavoriteList">
              Zoeken
            </v-button>
          </div>
          <div
            class="col-md-12 favouritesEdit__favouriteListItem"
            :class="{ isActive: activeIndex === index }"
            v-for="(favourite, index) in computedFavourites"
            :key="'favourite' + favourite.bundleId"
            @click.prevent="setFavouriteListActive(index, favourite.bundleId)"
          >
            {{ favourite.name }}
          </div>
        </div>
      </div>
      <div class="col-md-9 favouritesEdit__favouriteDetails">
        <div v-if="computedFavourites.length === 0">
          <div class="row grid-margin">
            <div class="col-md-12 grid-align-center">
              <img src="@/static/img/mascotNoResult.svg" class="favouritesEdit__mascot" />
            </div>
          </div>
          <div class="row grid-margin">
            <div v-if="favoriteFilterByValue === null" class="col-md-12 favouritesEdit__noListMessage">
              Er zijn nog geen favorietenlijsten aangemaakt. Klik in de catalogus op het hartje naast een product om een
              nieuwe lijst te starten.
            </div>
            <div v-else class="col-md-12 favouritesEdit__noListMessage">
              Er zijn geen favorietenlijsten gevonden met deze naam. Probeer een andere zoekterm of maak een nieuwe
              favorietenlijst aan.
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row grid-margin" v-if="activeIndex !== null">
            <div class="col-md-10">
              <m-input
                :key="`${computedFavourites.length}-${activeIndex}`"
                type="text"
                label="Favorietenlijst naam"
                position="top"
                placeholder="favorietenlijst"
                :value="activeIndex !== null ? computedFavourites[activeIndex].name : null"
                :model="activeIndex !== null ? computedFavourites[activeIndex].name : null"
                :required="true"
                :invalid="validationStatus"
                errorMessage="Dit veld is verplicht."
                @input="computeBundleName($event.target.value)"
              >
              </m-input>
            </div>
            <div class="deleteFavouriteList" v-if="favouriteProducts.length > 0">
              <v-button
                md
                hollow
                transparent
                iconLeft
                v-tooltip="{ content: 'Favorietenlijst verwijderen', placement: 'top' }"
                @click.prevent="deleteFavouriteList"
              >
                <v-delete-icon></v-delete-icon>
              </v-button>
            </div>
          </div>
          <div class="row grid-margin" v-if="favouriteProducts.length > 0">
            <div class="col-md-12">
              <table>
                <colgroup>
                  <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Artikel</th>
                    <th>Aantal</th>
                    <th>Beschrijving</th>
                    <th>Verpakking</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(favouriteProduct, productIndex) in favouriteProducts"
                    :key="'favouriteProduct' + favouriteProduct.productId"
                  >
                    <td v-if="favouriteProduct.imageUrl">
                      <div class="favouritesEdit__imgWrapper">
                        <img :src="favouriteProduct.imageUrl" />
                      </div>
                    </td>
                    <td v-else></td>
                    <td class="favouritesEdit__count">
                      <div class="favouritesEdit__count__quantity">
                        <v-minus
                          class="favouritesEdit__count__icon"
                          @click.prevent="reduceQuantity(productIndex)"
                        ></v-minus>
                        <input
                          type="number"
                          min="1"
                          max="999"
                          v-model="favouriteProduct.quantity"
                          maxlength="3"
                          autocomplete="off"
                        />
                        <v-plus
                          class="favouritesEdit__count__icon favouritesEdit__count__plus"
                          @click.prevent="increaseQuantity(productIndex)"
                        ></v-plus>
                      </div>
                    </td>
                    <td>{{ favouriteProduct.title }}</td>
                    <td>{{ favouriteProduct.packagingUnit }} {{ favouriteProduct.packagingLabel }}</td>
                    <td class="deleteFavouriteList">
                      <v-button
                        md
                        hollow
                        transparent
                        iconLeft
                        v-tooltip="{ content: 'Product verwijderen', placement: 'top' }"
                        @click.prevent="deleteFavouriteListProduct(productIndex)"
                      >
                        <v-delete-icon></v-delete-icon>
                      </v-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row grid-margin" v-else>
            <div class="col-md-12">
              <p>Selecteer een favorietenlijst om deze te bewerken.</p>
            </div>
          </div>
        </div>
        <div class="row grid-padding grid-justify-content-end">
          <v-button md hollow transparent @click.prevent="closeOverlay">Sluiten</v-button>
          <v-button
            md
            cta
            @click.prevent="saveFavouriteEditForm"
            :disabled="computedFavourites.length === 0 || !checkIfActiveListChangesExists()"
          >
            Opslaan
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VDeleteIcon from 'icons/Delete';
import VPlus from 'icons/Plus';
import VMinus from 'icons/Minus';
import VOrderAlphabeticalAscending from 'icons/OrderAlphabeticalAscending';
import VOrderAlphabeticalDescending from 'icons/OrderAlphabeticalDescending';
import GenericModal from '@/components/shared/GenericModal';
import structuredClone from 'core-js/stable/structured-clone.js';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VFavouritesEditform',
  components: {
    VDeleteIcon,
    VPlus,
    VMinus,
    GenericModal,
    VOrderAlphabeticalAscending,
    VOrderAlphabeticalDescending
  },
  props: {
    favourites: Array
  },
  setup() {
    const store = useStore();

    const computedApiDispatch = (computedApi, value) => store.dispatch(computedApi, value);
    const fetchFavourites = () => store.dispatch('catalog/fetchFavourites');
    const fetchFilteredFavourites = (value) => store.dispatch('catalog/fetchFavourites', value);
    const favoriteCategories = computed(() => store.getters['catalog/getFavoriteCategories']);

    return {
      favoriteCategories,
      store,
      computedApiDispatch,
      fetchFavourites,
      fetchFilteredFavourites
    };
  },
  data() {
    return {
      computedFavourites: [],
      favouriteProducts: [],
      activeIndex: null,
      productIndex: null,
      validationStatus: false,
      confirmDeleteOverlay: false,
      deleteFavouriteListStatus: false,
      deleteFavouriteListProductStatus: false,
      confirmCloseFavoriteList: false,
      confirmChangeActiveFavoriteList: null,
      favoriteFilterByValue: null,
      isOrderAsc: true
    };
  },
  computed: {},
  watch: {
    favoriteCategories(newVal, oldVal) {
      this.loadComputedFavourites();
    }
  },
  created() {},
  mounted() {
    this.loadComputedFavourites();
  },
  methods: {
    loadComputedFavourites() {
      if (Array.isArray(this.favoriteCategories)) {
        this.computedFavourites = structuredClone(this.favoriteCategories);
        this.computedFavourites.forEach((favourite) => {
          favourite.isDeleted = false;
          favourite.originalName = favourite.name;
        });
        if (!this.isOrderAsc) {
          this.computedFavourites.reverse();
        }
      } else {
        this.computedFavourites = [];
      }
    },

    setFavouriteListActive(index, bundleId) {
      this.store.dispatch('catalog/fetchFavouriteProducts', bundleId).then((res) => {
        if (res) {
          this.activeIndex = index;
          this.favouriteProducts = res;

          if (Array.isArray(this.favouriteProducts)) {
            this.favouriteProducts.forEach((product) => {
              product.isDeleted = false;
              product.originalQuantity = product.quantity;
            });
          }

          this.deleteFavouriteListStatus = false;
          this.deleteFavouriteListProductStatus = false;
        }
      });
      this.loadComputedFavourites();
    },
    closeConfirmDeleteOverlay() {
      this.confirmDeleteOverlay = false;
    },
    closeOverlay() {
      if (this.checkIfActiveListChangesExists()) {
        this.confirmCloseFavoriteList = true;
        return;
      }

      if (this.favoriteFilterByValue !== null || !this.isOrderAsc) {
        this.favoriteFilterByValue = null;
        this.isOrderAsc = true;
        this.filterFavoriteList();
      }

      this.$emit('close');
    },
    saveFavouriteEditForm() {
      if (!this.computedFavourites[this.activeIndex].name) {
        this.validationStatus = true;
        return;
      }

      let computedEditedProducts = [];
      this.favouriteProducts.forEach((bundleProduct) => {
        computedEditedProducts.push({
          productId: bundleProduct.productId,
          quantity: bundleProduct.quantity
        });
      });
      let computedEditDetails = {
        bundleId: this.computedFavourites[this.activeIndex].bundleId,
        name: this.computedFavourites[this.activeIndex].name,
        favouriteProducts: computedEditedProducts
      };
      let computedApi = 'catalog/UpdateFavoriteCategories';
      if (this.deleteFavouriteListStatus && this.computedFavourites[this.activeIndex].isDeleted) {
        computedApi = 'catalog/DeleteFavoriteList';
        computedEditDetails = {
          bundleId: this.computedFavourites[this.activeIndex].bundleId
        };
      }
      if (this.deleteFavouriteListProductStatus && this.favouriteProducts[this.productIndex].isDeleted) {
        computedApi = 'catalog/DeleteFavoriteListProduct';
        computedEditDetails = {
          bundleId: this.computedFavourites[this.activeIndex].bundleId,
          productId: this.favouriteProducts[this.productIndex].productId
        };
      }
      this.computedApiDispatch(computedApi, computedEditDetails).then((response) => {
        if (!response?.data) {
          this.$emitter.emit('alert', {
            message: 'Het is niet gelukt uw wijzigingen op te slaan. Probeer het later opnieuw.',
            type: 3
          });

          return;
        }

        if (this.confirmCloseFavoriteList) {
          this.confirmCloseFavoriteList = false;
          this.$emit('close');
        }

        if (this.confirmChangeActiveFavoriteList !== null) {
          this.activeIndex = this.confirmChangeActiveFavoriteList;
          this.confirmChangeActiveFavoriteList = null;
        }

        if (
          computedEditedProducts.length === 1 &&
          (this.deleteFavouriteListProductStatus || this.deleteFavouriteListStatus)
        ) {
          this.activeIndex = null;
          this.favouriteProducts = [];
        }

        this.favoriteFilterByValue = null;
        this.setFavouriteListActive(this.activeIndex, computedEditDetails.bundleId);
        this.store.dispatch('catalog/fetchFavourites').then((res) => {
          if (res && res.data) {
            this.loadComputedFavourites();
            this.closeConfirmDeleteOverlay();
          }
        });
        this.productIndex = null;
        this.deleteFavouriteListStatus = false;
        this.deleteFavouriteListProductStatus = false;
        this.$emitter.emit('alert', {
          message: 'Wijzigingen zijn opgeslagen.'
        });
      });
    },
    computeBundleName(value) {
      this.computedFavourites[this.activeIndex].name = value;
      this.validationStatus = value ? false : true;
    },
    deleteFavouriteList() {
      this.computedFavourites[this.activeIndex].isDeleted = true;
      this.deleteFavouriteListProductStatus = false;
      this.deleteFavouriteListStatus = true;
      this.confirmDeleteOverlay = true;

      let isFilteredAndNotDeletedExists = this.computedFavourites[this.activeIndex].some(
        (item) => item.isDeleted == false
      );
      if (this.favoriteFilterByValue !== null && !isFilteredAndNotDeletedExists) {
        this.favoriteFilterByValue = null;
        this.filterFavoriteList();
      }
    },
    /**
     * @function reduceQuantity
     */
    reduceQuantity(productIndex) {
      if (this.favouriteProducts[productIndex].quantity === 1) {
        return;
      }
      this.favouriteProducts[productIndex].quantity -= 1;
    },
    /**
     * @function increaseQuantity
     */
    increaseQuantity(productIndex) {
      if (this.favouriteProducts[productIndex].quantity === 999) {
        return;
      }
      this.favouriteProducts[productIndex].quantity += 1;
    },
    deleteFavouriteListProduct(productIndex) {
      this.productIndex = productIndex;
      this.favouriteProducts[productIndex].isDeleted = true;
      this.deleteFavouriteListProductStatus = true;
      this.deleteFavouriteListStatus = false;
      this.saveFavouriteEditForm();
    },
    checkIfActiveListChangesExists() {
      if (this.activeIndex === null) {
        return;
      }
      let activeList = this.computedFavourites[this.activeIndex];

      let isNameChanged = activeList ? activeList.name !== activeList.originalName : false;
      let isProductQuantitiesChanged = this.favouriteProducts.some(
        (product) => product.quantity !== product.originalQuantity
      );
      return isNameChanged || isProductQuantitiesChanged;
    },
    discardFavoriteEditForm() {
      this.favouriteProducts.forEach((product) => (product.quantity = product.originalQuantity));

      this.computedFavourites[this.activeIndex].name = this.computedFavourites[this.activeIndex].originalName;
      this.favoriteFilterByValue = null;

      if (this.confirmCloseFavoriteList) {
        this.confirmCloseFavoriteList = false;
        this.$emit('close');
      }

      if (this.confirmChangeActiveFavoriteList !== null) {
        this.activeIndex = this.confirmChangeActiveFavoriteList;
        this.confirmChangeActiveFavoriteList = null;
      }
    },
    filterFavoriteList() {
      this.store.dispatch('catalog/fetchFavourites', this.favoriteFilterByValue).then((res) => {
        if (res && res.data) {
          this.loadComputedFavourites();
          this.activeIndex = null;
          this.productIndex = null;
          this.favouriteProducts = [];
          this.deleteFavouriteListStatus = false;
          this.deleteFavouriteListProductStatus = false;
        }
      });
    },
    orderFavorites() {
      this.isOrderAsc = !this.isOrderAsc;
      this.computedFavourites.reverse((a, b) => a.name > b.name);
      this.activeIndex = null;
    },
    onFavoriteFilterInput(value) {
      this.favoriteFilterByValue = value.length ? value : null;
      if (this.favoriteFilterByValue === null) {
        this.filterFavoriteList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.favouritesEdit {
  max-height: 100vh;
  overflow-y: auto;
  color: $teal-darkest;
  .isActive {
    background-color: $white;
  }
  .deleteFavouriteList {
    align-self: center;
  }
  &__favouriteList {
    background-color: #0000000d;
  }
  &__favouriteListItem {
    padding: 16px 24px;
    cursor: pointer;
  }
  &__favoriteListFilter {
    padding: 4px 16px 16px 15px;
  }
  &__favouriteDetails {
    padding: 24px;
  }
  &__imgWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    img {
      max-height: 50px;
      max-width: 50px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }
  &__count {
    &__quantity {
      border: 1px solid $gray;
      height: 31px;
      border-radius: 3px;
      width: 90px;
    }
    width: 120px;
    padding: 20px 20px 20px 0px;
    &__icon {
      fill: $teal-darkest;
      float: left;
      padding-top: 2px;
      cursor: pointer;
    }
    &__plus {
      text-align: right;
    }
    input {
      max-width: 35px;
      border-right: 1px solid $gray;
      border-left: 1px solid $gray;
      border-top: none;
      border-bottom: none;
      text-align: center;
      height: 29px;
      float: left;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  &__favouriteListOrder {
    align-items: end;
  }
  h2 {
    margin: 0px;
    padding: 20px 0 4px 15px;
  }
  table {
    border-collapse: collapse;
    thead {
      td,
      th {
        padding: $table-row-license-cell-padding;
        border-bottom: 1px solid $grayish-very-dark;
      }
    }
    tbody {
      td {
        padding: $table-row-license-cell-padding;
        border-bottom: 1px solid $grayish-very-dark;
      }
    }
  }
  &__mascot {
    height: 200px;
    width: 200px;
  }
  &__noListMessage {
    text-align: center;
  }
}
</style>
