<script>
import { h } from 'vue';

export default {
  name: 'VHeading',
  props: {
    level: {
      type: Number,
      required: true
    },
    inverted: {
      type: Boolean,
      required: false
    },
    transition: {
      type: String,
      required: false
    }
  },
  render() {
    return h(
      'h' + this.level,
      {
        class: {
          heading: true,
          inverted: this.inverted
        }
      },
      [this.$slots.default()]
    );
  }
};
</script>

<style lang="scss" scoped>
.heading {
  display: flex;
  color: $heading-color;
  font-weight: $heading-font-weight;
  margin-top: $heading-margin-top;

  &.inverted {
    color: $heading-color-inverted;
  }
}

h1 {
  font-size: $heading1-font-size;
  line-height: $heading1-line-height;
  margin-bottom: $heading1-line-height;
}

h2 {
  font-size: $heading2-font-size;
  line-height: $heading2-line-height;
  margin-bottom: $heading2-line-height;
}

h3 {
  font-size: $heading3-font-size;
  line-height: $heading3-line-height;
  margin-bottom: $heading3-line-height;
}

h4 {
  font-size: $heading4-font-size;
  line-height: $heading4-line-height;
  margin-bottom: $heading4-line-height;
}

.stagger-down-enter-active,
.stagger-down-leave-active {
  transition: $heading-transition;
}

.stagger-down-enter,
.stagger-down-leave-to {
  opacity: 0;
  transform: translate(0px, -20px);
}

.stagger-up-enter-active,
.stagger-up-leave-active {
  transition: $heading-transition;
}

.stagger-up-enter,
.stagger-up-leave-to {
  opacity: 0;
  transform: translate(0px, 20px);
}
</style>
