<template>
  <div v-if="institution.show" class="registrationInstitutionDetails">
    <div class="row grid-align-center">
      <div class="col-md-6 registrationInstitutionDetails__block">
        <div class="registrationInstitutionDetails__block__header">Werkgever {{ index + 1 }}</div>
        <div class="registrationInstitutionDetails__block__content">
          <div class="row grid-padding registrationInstitutionDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.name"
                :initvalue="institution.name"
                :reference="formConfig.name.alias"
                :rule="formConfig.name.rule"
                :inputid="formConfig.name.ref + index"
                placeholder="Naam werkgever*"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.agbCode"
                :initvalue="institution.agbCode"
                :reference="formConfig.agbCode.alias"
                :rule="formConfig.agbCode.rule"
                :inputid="formConfig.agbCode.ref + index"
                placeholder="AGB-code werkgever*"
                @blur="validateField"
              />
              <div class="registrationInstitutionDetails__block__content__link">
                <a href="https://www.agbcode.nl/Webzoeker" target="_blank" noopener>AGB-code opzoeken</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-registration-department-details
      v-for="(departmentDetails, deptIndex) in institution.departments"
      :key="deptIndex"
      ref="departmentDetails"
      :index="deptIndex"
      :parent-index="index"
      :department-details="departmentDetails"
      :show-delete="institution.departments.length > 1"
      type="institutions"
    />
    <div class="row grid-align-center">
      <v-button hollow md class="registrationInstitutionDetails__department" @click.prevent="addDepartment()">
        <v-plus-circle-icon class="registrationInstitutionDetails__department__icon" /> Afdeling toevoegen
      </v-button>
    </div>
    <div class="row grid-align-center">
      <div class="col-md-6 registrationInstitutionDetails__block">
        <div class="registrationInstitutionDetails__block__address">Locatie adres</div>
        <div
          class="registrationInstitutionDetails__block__content registrationInstitutionDetails__block__addressContent"
        >
          <div class="row grid-padding registrationInstitutionDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.postalCode"
                maxlength="7"
                :initvalue="institution.postalCode"
                :reference="formConfig.postalCode.alias"
                :rule="formConfig.postalCode.rule"
                :inputid="formConfig.postalCode.ref + index"
                placeholder="Postcode (1234 AA)*"
                @blur="handleBlur"
              />
            </div>
            <div class="col-xs-3">
              <v-input-group-simple
                v-model="institution.houseNumber"
                :initvalue="institution.houseNumber"
                :reference="formConfig.houseNumber.alias"
                :rule="formConfig.houseNumber.rule"
                :inputid="formConfig.houseNumber.ref + index"
                placeholder="Huisnummer*"
                @blur="handleBlur"
              />
            </div>
            <div class="col-xs-3">
              <v-input-group-simple
                v-model="institution.houseNumberAddition"
                :reference="formConfig.houseNumberAddition.alias"
                :initvalue="institution.houseNumberAddition"
                :rule="formConfig.houseNumberAddition.rule"
                :inputid="formConfig.houseNumberAddition.ref + index"
                placeholder="Toevoeging"
                @blur="handleBlur"
              />
            </div>
          </div>
          <div class="row grid-padding registrationInstitutionDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.streetName"
                :initvalue="institution.streetName"
                :reference="formConfig.streetName.alias"
                :rule="formConfig.streetName.rule"
                :inputid="formConfig.streetName.ref + index"
                :disabled="formConfig.streetName.disabled"
                :overwrite-value="institution.streetName"
                placeholder="Straatnaam*"
                :input-event="inputEvent"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.city"
                :initvalue="institution.city"
                :reference="formConfig.city.alias"
                :rule="formConfig.city.rule"
                :inputid="formConfig.city.ref + index"
                :disabled="formConfig.city.disabled"
                :overwrite-value="institution.city"
                placeholder="Plaats*"
                :input-event="inputEvent"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding registrationInstitutionDetails__block__content__section">
            <div class="col-xs-6 registrationInstitutionDetails__selectLand">
              <v-select-group
                v-model="institution.country"
                selectid="(index+1)"
                :initselected="institution.country || 'NL'"
                @change="countryChange"
              >
                <option :key="'1'" :data-index="'1'" :value="'BE'">BE</option>
                <option :key="'2'" :data-index="'2'" :value="'DE'">DE</option>
                <option :key="'3'" :data-index="'3'" :value="'NL'">NL</option>
              </v-select-group>
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                v-model="institution.phoneNumber"
                max-length="10"
                :reference="formConfig.phoneNumber.alias"
                :initvalue="institution.phoneNumber"
                :rule="formConfig.phoneNumber.rule"
                :inputid="formConfig.phoneNumber.ref + index"
                placeholder="Telefoonnummer werk*"
                @blur="validateField"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate';
import VPlusCircleIcon from 'icons/PlusCircleOutline';
import VRegistrationDepartmentDetails from './registrationDepartmentDetails';
import { useStore } from 'vuex';

export default {
  name: 'VRegistrationInstitutionDetails',
  components: {
    VPlusCircleIcon,
    VRegistrationDepartmentDetails
  },
  props: {
    institution: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();

    const setPrescriberRegistrationInstitutionDetails = (value) =>
      store.commit('prescriberRegistration/setPrescriberRegistrationInstitutionDetails', value);
    const addDepartmentDetails = (value) => store.commit('prescriberRegistration/addDepartmentDetails', value);
    const fetchAddressLookup = (value) => store.dispatch('prescriberRegistration/fetchAddressLookup', value);

    return {
      setPrescriberRegistrationInstitutionDetails,
      addDepartmentDetails,
      fetchAddressLookup
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      formConfig: {},
      addressLookup: {
        City: null,
        Street: null
      },
      inputEvent: false,
      focusedField: null
    };
  },
  computed: {},
  watch: {
    addressLookup() {
      if (this.addressLookup.Street && this.addressLookup.City) {
        const streetRef = 'streetName' + this.index;
        const cityRef = 'city' + this.index;
        this.validateField(streetRef, this.addressLookup.Street);
        this.validateField(cityRef, this.addressLookup.City);
      }
    }
  },
  created() {
    this.formConfig = {
      name: {
        model: this.institution.name,
        ref: 'name',
        label: 'Naam werkgever',
        attached: true,
        alias: 'Naam werkgever',
        rule: {
          required: true
        },
        error: false
      },
      agbCode: {
        model: this.institution.agbCode,
        ref: 'agbCode',
        label: 'AGB-code werkgever',
        attached: true,
        alias: 'AGB-code werkgever',
        rule: {
          required: true,
          numeric: true,
          max: 99999999
        },
        error: false
      },
      postalCode: {
        model: this.institution.postalCode,
        ref: 'postalCode',
        label: 'Postcode (1234 AA)*',
        attached: true,
        alias: 'Postcode',
        rule: {
          required: true,
          regex: /^\d{4}\s?[A-Za-z]{2}$/i
        },
        berule: {
          regex: [/^[0-9]{4}$/]
        },
        derule: {
          regex: [/^[0-9]{5}$/]
        },
        error: false
      },
      houseNumber: {
        model: this.institution.houseNumber,
        ref: 'houseNumber',
        label: 'Huisnummer*',
        attached: true,
        alias: 'Huisnummer',
        rule: {
          required: true
        },
        error: false
      },
      houseNumberAddition: {
        model: this.institution.houseNumberAddition,
        ref: 'houseNumberAddition',
        label: 'Toevoeging',
        attached: true,
        alias: 'Toevoeging',
        rule: {
          required: false
        },
        error: false
      },
      streetName: {
        model: this.institution.streetName,
        ref: 'streetName',
        label: 'Straatnaam*',
        attached: true,
        alias: 'Straatnaam',
        rule: {
          required: true
        },
        error: false,
        disabled: false
      },
      city: {
        model: this.institution.city,
        ref: 'city',
        label: 'Plaats*',
        attached: true,
        alias: 'Plaats',
        rule: {
          required: true
        },
        error: false,
        disabled: false
      },
      country: {
        model: this.institution.country,
        ref: 'country',
        label: '',
        attached: true,
        rule: {
          required: false
        },
        error: false
      },
      phoneNumber: {
        model: this.institution.phoneNumber,
        ref: 'phoneNumber',
        label: 'Telefoonnummer (vast)',
        attached: true,
        alias: 'Telefoonnummer',
        rule: {
          required: true,
          regex: /^0\d{9}$/
        },
        error: false
      }
    };

    if (this.institution.streetName) {
      this.formConfig.streetName.disabled = true;
      this.formConfig.city.disabled = true;
    }
  },
  methods: {
    checkRadioAnswer(value) {
      let updatedGenderValue = {
        male: true,
        female: false
      };
      if (value === 0) {
        updatedGenderValue = {
          male: false,
          female: true
        };
      }
      this.savePrescriberRegistrationInstitutionDetails({
        id: this.index,
        details: {
          key: 'gender',
          value: updatedGenderValue
        }
      });
    },
    handleBlur(ref, model) {
      this.autoFillAddress();
      this.validateField(ref, model);
    },
    validateField(ref, model) {
      const actualReference = ref.substring(0, ref.length - 1);
      this.savePrescriberRegistrationInstitutionDetails({
        id: this.index,
        details: {
          key: actualReference,
          value: model
        }
      });
    },
    async validateAllFields() {
      const result = await this.validate();
      if (result.valid) {
        this.setValidationStatus(true);
      } else {
        this.focusOnErrorField(result.results);
      }
      for (let i = 0; i < this.institution.departments.length; i++) {
        if (this.$refs && this.$refs.departmentDetails && this.$refs.departmentDetails[i]) {
          this.$refs.departmentDetails[i].validateAllFields();
        }
      }
    },
    focusOnErrorField(results) {
      const priorityFields = ['name', 'agbCode', 'postalCode', 'houseNumber', 'streetName', 'city', 'phoneNumber'];
      for (const fieldName of priorityFields) {
        if (!results[fieldName + this.index].valid) {
          this.focusedField = fieldName + this.index;
          break;
        }
      }
      if (this.focusedField) {
        document.querySelector('#' + this.focusedField).focus();
      }
    },
    savePrescriberRegistrationInstitutionDetails(prescriberRegistrationInstitutionDetails) {
      this.setPrescriberRegistrationInstitutionDetails(prescriberRegistrationInstitutionDetails);
    },
    addDepartment() {
      this.addDepartmentDetails({ index: this.index, type: 'institutions' });
    },
    setValidationStatus(active) {
      this.savePrescriberRegistrationInstitutionDetails({
        id: this.index,
        details: {
          key: 'validated',
          value: active
        }
      });
    },
    autoFillAddress() {
      let postalCode = document.querySelector('#postalCode' + this.index).value;
      postalCode = postalCode.replace(' ', '');
      let houseNumber = document.querySelector('#houseNumber' + this.index).value;
      if (postalCode.length === 6 && houseNumber.length !== 0) {
        this.fetchAddressLookup({
          postcode: postalCode,
          huisnummer: houseNumber,
          land: 'NL'
        })
          .then((response) => {
            this.addressLookup = response.data;
            if (this.addressLookup.City === null) {
              this.formConfig.streetName.disabled = false;
              this.formConfig.city.disabled = false;
            } else {
              this.formConfig.streetName.disabled = true;
              this.formConfig.city.disabled = true;
            }
          })
          .catch(() => {
            this.addressLookup = { City: null, Street: null };
            this.formConfig.streetName.disabled = false;
            this.formConfig.city.disabled = false;
          });
      } else {
        this.formConfig.streetName.disabled = false;
        this.formConfig.city.disabled = false;
      }
    },
    countryChange(e) {
      this.savePrescriberRegistrationInstitutionDetails({
        id: this.index,
        details: {
          key: 'country',
          value: e.target.value
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.registrationInstitutionDetails {
  &__department {
    margin: 5px auto 30px auto;

    &__icon {
      margin-right: 10px;

      :deep(svg) {
        fill: $teal-darkest !important;
      }
    }
  }

  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;

    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }

    &__address {
      background-color: $teal-darkest;
      font-size: 18px;
      color: $white;
      padding: 20px;
    }

    &__addressContent {
      background-color: $teal-lighter;
    }

    &__content {
      padding-top: 30px;
      padding-bottom: 10px;

      &__section {
        margin: 15px;

        span {
          float: left;
          margin: 12px 8px 0 0;
        }

        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }

      &__link {
        margin: 10px 0px 20px 0px;
      }

      a {
        font-size: 16px;
        color: $teal-darkest;
      }
    }

    &__icon {
      position: relative;
      margin: -40px 10px 10px 10px;
      float: right;
    }
  }

  &__selectLand {
    padding-left: 10px;
  }

  .material-design-icon {
    fill: $teal-darkest;
  }

  :deep(.form-group) {
    padding: 0px;
    width: 100%;

    &__icon {
      margin: -5px 15px 0px 0px;
    }
  }

  &__selectLand {
    :deep(.form-group) {
      &__details {
        display: none;

        &:before {
          background: none;
        }

        &:after {
          background: none;
        }
      }
    }
  }
}
</style>
