<template>
  <div>
    <li class="searchProduct">
      <a class="searchProduct__link" href="" @click.prevent="goToProductDetailPage">
        <div class="searchProduct__img-wrapper">
          <img :src="searchProduct.imageUrl" @error="imageLoadError" />
        </div>
        <div class="searchProduct__text col">
          <strong class="searchProduct__text__name">{{ searchProduct.title }}</strong>
          <br />
          <strong class="searchProduct__text__manufacturer">
            Fabrikant: {{ searchProduct.manufName }}
            <span>Artikelcode: {{ searchProduct.productCode }}</span>
            <span> Verpakking: {{ searchProduct.packagingUnit }} {{ searchProduct.packagingLabel }}</span>
          </strong>
        </div>
        <div v-if="searchProduct.showOnly && !isAssortmentFlow" class="searchProduct__noordertext">Niet bestelbaar</div>
        <v-button
          v-show="!isAssortmentFlow && !searchProduct.showOnly && !searchProduct.showOnly"
          cta
          md
          data-icon="cart"
          @click.prevent="orderSearchProduct"
        >
          Bestellen
        </v-button>
      </a>
    </li>
  </div>
</template>

<script>
import router from '@/router';
import { shoppingCart$, addToShoppingCart, activePpwRecId$ } from '@/services';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'VSearchProduct',
  components: {},
  props: {
    searchProduct: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const patientId = store.state.patient.patientGegevens.PatientId;
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);

    const setOrderSuccessStatus = (value) => store.commit('catalog/setOrderSuccessStatus', value);
    const setOrderDetails = (value) => store.commit('catalog/setOrderDetails', value);
    const setOrderClicked = (value) => store.commit('cart/setOrderClicked', value);

    shoppingCart$.subscribe((cart) => {
      shoppingCart.value = cart;
    });

    activePpwRecId$.subscribe((id) => {
      activePpwRecId.value = id;
    });

    return {
      patientId,
      setOrderSuccessStatus,
      setOrderDetails,
      setOrderClicked,
      shoppingCart,
      activePpwRecId
    };
  },
  data() {
    return {
      baseProduct: true,
      count: 1
    };
  },
  computed: {
    isReplenishmentFlow() {
      return router.currentRoute.value.meta.flow === 'replenishment';
    },
    isAssortmentFlow() {
      return router.currentRoute.value.meta.flow === 'assortment';
    },
    isCustomerFlow() {
      return router.currentRoute.value.meta.flow === 'customer';
    }
  },
  created() {},
  methods: {
    imageLoadError() {
      console.warn('No product image');
    },
    goToProductDetailPage() {
      if (this.isCustomerFlow) {
        router.push('/catalog/baseProduct/' + this.searchProduct.baseProductId);
      }
      if (this.isReplenishmentFlow) {
        router.push('/aanvullenmagazijn/baseProduct/' + this.searchProduct.baseProductId);
      }
      if (this.isAssortmentFlow) {
        router.push('/assortiment/baseProduct/' + this.searchProduct.baseProductId);
      }
    },
    // Function to show an alert message
    showAlert() {
      this.setOrderSuccessStatus(true);
      this.setOrderDetails(this.searchProduct);
      setTimeout(() => {
        this.setOrderSuccessStatus(false);
        this.setOrderDetails({});
      }, 2500);
    },
    // Function to add product to the cart
    orderSearchProduct(e) {
      e.stopPropagation();
      this.setOrderClicked(true);
      let order = this.searchProduct;
      if (order) {
        order.addedToBasket = true;
        order.count = this.count;
        addToShoppingCart(this.shoppingCart, this.searchProduct.productId, this.count, this.activePpwRecId);
        this.showAlert(this.searchProduct.productId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.searchProduct {
  background: $white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.49);
  margin-bottom: 10px;
  padding: 16px;
  overflow: hidden;
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }
  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    min-height: 80px;
    min-width: 80px;

    img {
      max-height: 80px;
      max-width: 80px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }
  &__noordertext {
    color: $gray-darkest;
  }
  &__text {
    display: flex;
    flex-direction: column;
    &__name {
      color: $gray-darkest;
      font-weight: normal;
      font-size: type-scale(1);
      line-height: line-height(1);
      padding: 0px 10px 0px 10px;
    }

    &__manufacturer {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: $gray-dark;
      padding: 0px 10px 0px 10px;
      span {
        padding-left: 10px;
      }
    }
  }
}
</style>
