<template>
  <div
    class="form-group-simple"
    :class="{
      'form-group-simple--completed': completed,
      'form-group-simple--error': errorMessage,
      'form-group-simple--alt': alt
    }"
  >
    <label v-if="label !== ''" :for="inputid">{{ label }}</label>
    <input
      v-if="type === 'text'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="text"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :disabled="disabled"
      :autofocus="autofocus"
      @blur="blur"
      @input="update"
    />
    <input
      v-else-if="type === 'email'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="email"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autofocus="autofocus"
      @blur="blur"
    />
    <input
      v-else-if="type === 'number'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="number"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autofocus="autofocus"
      @blur="blur"
    />
    <input
      v-else-if="type === 'password'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="password"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autofocus="autofocus"
      @blur="blur"
    />
    <input
      v-else-if="type === 'url'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="url"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autofocus="autofocus"
      @blur="blur"
    />
    <input
      v-else-if="type === 'tel'"
      :id="inputid"
      ref="input"
      v-model="value"
      type="tel"
      :name="inputid"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autofocus="autofocus"
      @blur="blur"
    />
    <div v-if="showAppend" class="input-group-append">
      <div class="input-group-text">
        {{ appendText }}
      </div>
    </div>
    <div v-if="errorMessage" class="form-group-simple__details">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { generateYupSchema } from '@/composables/globalHelperFunctions';
import { ref, watch, onBeforeMount } from 'vue';

export default {
  name: 'VInputGroupSimple',
  props: {
    autofocus: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    alt: {
      default: false,
      type: Boolean
    },
    initvalue: {
      default: '',
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    inputid: {
      default: 'inputid',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    overwriteValue: [String, null],
    maxlength: {
      default: '',
      type: String
    },
    inputEvent: {
      default: true,
      type: Boolean
    },
    showAppend: {
      default: false,
      type: Boolean
    },
    appendText: {
      default: '',
      type: String
    },
    rule: {
      default: () => ({
        required: false
      }),
      type: Object
    },
    reference: {
      default: null,
      type: String
    }
  },
  setup(props) {
    const schema = ref(
      generateYupSchema({
        label: props.reference ? props.reference : props.label ? props.label : props.inputid,
        rules: props.rule
      })
    );
    const { value, errorMessage, validate } = useField(props.inputid, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema(props);
      },
      (overwriteValue) => {
        if (value != null) {
          value.value = overwriteValue;
          this.value = overwriteValue;
        }
      }
    );

    onBeforeMount(() => {
      if (props.initvalue) {
        value.value = props.initvalue;
      }
    });

    return {
      value,
      errorMessage,
      validate
    };
  },
  data() {
    return {
      completed: false
    };
  },
  watch: {
    overwriteValue(value) {
      this.value = value;
    }
  },
  methods: {
    blur() {
      if (this.value && this.value.length > 0) {
        this.completed = true;
        this.validate();
      }
      this.$emit('blur', this.inputid, this.value);
    },
    update(event) {
      event.stopPropagation();
      if (!this.inputEvent) {
        return;
      }
      this.validate();
      this.$emit('input', event.target.id, event.target.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.form-group-simple {
  position: relative;
  .input-group-append {
    position: absolute;
    display: flex;
    right: 0;
    top: 32px;
  }
  .input-group-text {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 50px;
  }
}
</style>
