<template>
  <div class="prescriberRegistrationStep1">
    <!-- step 1 -->
    <div class="row grid-align-center">
      <div v-if="prescriberRegistrationTexts.Step1" class="col-md-12 prescriberRegistrationStep1__stepTitle">
        {{ prescriberRegistrationTexts.Step1.PageTitle }}
      </div>
      <div class="col-md-4 prescriberRegistrationStep1__block">
        <div class="prescriberRegistrationStep1__block__header">AGB, BIG- of KP-nummer</div>
        <div class="prescriberRegistrationStep1__block__content">
          <p>
            Ten behoeve van een correcte financiële afhandeling hebben wij uw AGB-code (bij voorkeur), BIG-nummer of
            KP-nummer nodig, tenzij u onder volmacht werkt.
          </p>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <v-input-group-simple-2
                id="agbCode"
                v-model:value="fields.agbCode"
                :value="fields.agbCode"
                :overwrite-value="fields.agbCode"
                :rules="isAgbRequired"
                placeholder="AGB-nummer"
                data-vv-as="AGB-nummer"
                :disabled="fields.userType"
                @input="updateDetails('agbCode', $event)"
              />
              <div class="prescriberRegistrationStep1__block__content__link">
                <a href="https://www.vektis.nl/agb-register/zoeken" target="_blank" noopener>AGB-nummer opzoeken</a>
              </div>
            </div>
            <div
              v-tooltip="{
                content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                placement: 'right'
              }"
            >
              <information-circle-outline title=" " class="prescriberRegistrationStep1__block__icon" />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <v-input-group-simple-2
                id="bigCode"
                v-model:value="fields.bigCode"
                :value="fields.bigCode"
                :overwrite-value="fields.bigCode"
                :rules="isBigRequired"
                placeholder="BIG-nummer"
                data-vv-as="BIG-nummer"
                :disabled="fields.userType"
                @input="updateDetails('bigCode', $event)"
              />
              <div class="prescriberRegistrationStep1__block__content__link">
                <a href="https://zoeken.bigregister.nl/zoeken/kenmerken" target="_blank" noopener>
                  BIG-nummer opzoeken
                </a>
              </div>
            </div>
            <div
              v-tooltip="{
                content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                placement: 'right'
              }"
            >
              <information-circle-outline title=" " class="prescriberRegistrationStep1__block__icon" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <v-input-group-simple-2
                id="kpNumber"
                v-model:value="fields.kpNumber"
                :value="fields.kpNumber"
                :overwrite-value="fields.kpNumber"
                :rules="isKpRequired"
                placeholder="KP-nummer"
                data-vv-as="KP-nummer"
                :disabled="fields.userType"
                @input="updateDetails('kpNumber', $event)"
              />
              <div class="prescriberRegistrationStep1__block__content__link">
                <a
                  href="https://www.kwaliteitsregisterparamedici.nl/raadplegenregister/default.aspx"
                  target="_blank"
                  noopener
                >
                  KP-nummer opzoeken
                </a>
              </div>
            </div>
            <div
              v-tooltip="{
                content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                placement: 'right'
              }"
            >
              <information-circle-outline title=" " class="prescriberRegistrationStep1__block__icon" />
            </div>
          </div>
          <div class="row grid-margin">
            <div class="col-xs-12">
              <v-checkbox-group
                id="userType"
                v-model="fields.userType"
                :initvalue="fields.userType"
                :label="'Ik bezit geen AGB, BIG- of KP-nummer en werk onder volmacht van een voorschrijver.'"
                inputid="userType"
              />
            </div>
            <div v-show="fields.userType" class="col-md-12 col-xs-12">
              <v-warning-message
                v-if="prescriberRegistrationTexts.Step1"
                warning="Let op!"
                :message="prescriberRegistrationTexts.Step1.UserTypeWarning"
              />
            </div>
            <div v-show="showUserExistWarning" class="col-md-12 col-xs-12">
              <v-warning-message
                v-if="prescriberRegistrationTexts.Step1"
                warning="Let op!"
                :message="userExistWarning"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-align-center">
      <div class="col-md-4 col-xs-4 prescriberRegistrationStep1__info">
        <v-warning-message
          warning="Collega aanmelden?"
          message="Als u al een account heeft, kunt u na het <a href='/inloggen'>inloggen</a> op de pagina 'Mijn afdeling' via een verkort formulier uw collega toevoegen."
          html
          is-information
        />
      </div>
    </div>
    <!-- Buttons section -->
    <div class="row prescriberRegistrationStep1__actions">
      <v-button
        v-if="getStepActionVisibilityStatus('prev')"
        lg
        hollow
        class="prescriberRegistrationStep1__actions__previous"
        @click.prevent="previousStep()"
      >
        GA TERUG
      </v-button>
      <v-button
        v-if="getStepActionVisibilityStatus('next')"
        lg
        cta
        :class="{ 'btn--disabled': nextButtonDisabled }"
        @click.prevent="nextStep()"
      >
        {{ nextButtonText }}
      </v-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import InformationCircleOutline from 'icons/InformationOutline';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VWarningMessage from '@/components/shared/WarningMessage';
import { useForm } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'prescriberRegistration/getPrescriberData',
  mutationType: 'prescriberRegistration/updatePrescriberData'
});

export default defineComponent({
  name: 'VRegistrationStep1',
  components: {
    InformationCircleOutline,
    VCheckboxGroup,
    VWarningMessage
  },
  setup() {
    const { validate, handleSubmit } = useForm();

    const store = useStore();
    const nextStepClicked = ref(false);
    const codeLookup = ref(false);
    const showUserExistWarning = ref(false);
    const userExistWarning = ref('');

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );

    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );

    const prescriberRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationTexts']
    );

    const fields = computed(() => store.state.prescriberRegistration.prescriberRegistrationDetails);

    const isAgbRequired = computed(() => {
      if (!nextStepClicked.value) {
        return { required: false, numeric: true, max: 99999999 };
      }
      return !fields.value.bigCode && !fields.value.kpNumber && !fields.value.agbCode && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999 }
        : { required: false };
    });

    const isBigRequired = computed(() => {
      if (!nextStepClicked.value) {
        return { required: false, numeric: true, max: 99999999999 };
      }
      return !fields.value.kpNumber && !fields.value.agbCode && !fields.value.bigCode && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999999 }
        : { required: false };
    });

    const isKpRequired = computed(() => {
      if (!nextStepClicked.value) {
        return { required: false, numeric: true, max: 99999999999 };
      }
      return !fields.value.bigCode && !fields.value.agbCode && !fields.value.kpNumber && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999999 }
        : { required: false };
    });

    const nextButtonDisabled = computed(() => codeLookup.value);

    const nextButtonText = computed(() => (codeLookup.value ? 'Even wachten' : 'VOLGENDE STAP'));

    const updateDetails = (key, event) => {
      store.commit('prescriberRegistration/updatePrescriberData', { key, value: event.target.value });
      showUserExistWarning.value = false;
    };

    const getStepActionVisibilityStatus = (action) => {
      if (action === 'prev') {
        return prescriberRegistrationSteps.value.findIndex((step) => step.show) !== 0;
      }
      return prescriberRegistrationSteps.value.findIndex((step) => step.show) < 5;
    };

    const previousStep = () => {
      store.commit('prescriberRegistration/setPreviousStepActive');
    };

    const nextStep = async () => {
      if (fields.value.agbCode || fields.value.bigCode) {
        const result = await validate();
        if (result.valid) {
          codeLookUp();
        }
      } else {
        nextStepClicked.value = true;
        setTimeout(() => {
          validateAllFields();
        }, 2000);
      }
    };

    const codeLookUp = () => {
      nextStepClicked.value = false;
      codeLookup.value = true;
      let codeDetails = { code: fields.value.agbCode, isBigCode: false };
      if (!fields.value.agbCode && fields.value.bigCode) {
        codeDetails = { code: fields.value.bigCode, isBigCode: true };
      }
      store.dispatch('prescriberRegistration/fetchPrescriberDataUsingCode', codeDetails).then((response) => {
        userExistWarning.value = fields.value.agbCode
          ? 'Er is al een account aangemaakt met deze AGB-nummer. Log in met uw account of herstel uw accountgegevens'
          : 'Er is al een account aangemaakt met deze BIG-nummer. Log in met uw account of herstel uw accountgegevens.';
        showUserExistWarning.value = response;
        nextStepClicked.value = true;
        codeLookup.value = false;
        validateAllFields();
      });
    };

    const validateAllFields = async () => {
      const result = await validate();
      if (result.valid && !showUserExistWarning.value) {
        store.commit('prescriberRegistration/setNextStepActive');
      }
    };

    return {
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prescriberRegistrationTexts,
      fields,
      isBigRequired,
      isKpRequired,
      isAgbRequired,
      nextButtonDisabled,
      nextButtonText,
      showUserExistWarning,
      userExistWarning,
      updateDetails,
      getStepActionVisibilityStatus,
      previousStep,
      nextStep,
      codeLookUp,
      validateAllFields
    };
  }
});
</script>

<style lang="scss" scoped>
.prescriberRegistrationStep1 {
  &__stepTitle {
    margin: 15px 0px 30px 0px;
    font-size: map-get(map-get($type-settings, 5), font-size);
    color: $teal-darkest;
    text-align: center;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: map-get(map-get($type-settings, 1), font-size);
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__content {
      padding: 0px 30px 30px 30px;
      &__link {
        margin: 10px 0px 20px 0px;
      }
      a {
        font-size: map-get(map-get($type-settings, 0), font-size);
        color: $teal-darkest;
      }
    }
    &__icon {
      position: absolute;
      margin: 15px 10px 10px -30px;
    }
  }
  &__actions {
    float: right;
    margin: 1% 33% 5% 0;
    &__previous {
      margin-right: 30px;
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  &__info {
    margin: 0 auto;
  }
}
</style>
