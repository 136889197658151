import moment from 'moment'

export const defaultFormat = makeFormat('-')

function makeFormat (delimiter) {
  return ['DD', 'MM', 'YYYY'].join(delimiter)
}

function tryGetFormat (dateString) {
  if (dateString.indexOf('-')) {
    return makeFormat('-')
  }

  if (dateString.indexOf('/')) {
    return makeFormat('/')
  }

  if (dateString.indexOf('.')) {
    return makeFormat('.')
  }

  return makeFormat('')
}

export function stringToLocalDate (dateString) {
  var format = tryGetFormat(dateString)
  var m = moment(dateString, format)
  return new Date(m.valueOf())
}

export function localDateToJson (date) {
  // current json output is date parts only
  // - no time
  // - no time zone
  // time zone support needs to be defined if it is to be implemented.
  if (!(date instanceof Date)) {
    throw new Error('Parameter `date` is not a Date.')
  }

  return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')
}

// This function is used to tranlate day of week from English to any other specified locale
export function dayOfWeekTranslator (dt, locale) {
  let dayOfWeek = dt.toLocaleString(locale, {weekday: 'long'});
  return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)
}
