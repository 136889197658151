import { getCurrentUserIdentity } from './getCurrentUserIdentity';
import { isLoggedIn$ } from './isLoggedIn$';
import { of } from 'rxjs';
import { publishReplay, refCount, switchMap, mergeAll } from 'rxjs/operators';
import { twoFactorAuthenticationDisabledEvent$, twoFactorAuthenticationEnabledEvent$ } from '../crm/events$';

export const currentUserIdentity$ = of(
  isLoggedIn$,
  twoFactorAuthenticationEnabledEvent$,
  twoFactorAuthenticationDisabledEvent$
)
.pipe(
  mergeAll(),
  switchMap(x => getCurrentUserIdentity()),
  publishReplay(1),
  refCount()
);
