<template>
  <div class="mButton ga-track-btn">
    <mds-button
      :context="context"
      :type="type"
      :position="position"
      v-bind="icon && { icon }"
      :disabled="disabled"
      :ondark="ondark"
      v-on="$attrs"
    >
      <span slot="text"><slot name="text" /></span>
    </mds-button>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { MdsButton } from '@mds/button';

export default {
  name: 'MButton',
  props: {
    tagelement: {
      default: 'button',
      type: String
    },
    anchor: {
      default: '#',
      type: String
    },
    primary: {
      default: false,
      type: Boolean
    },
    secondary: {
      default: false,
      type: Boolean
    },
    emphasis: {
      default: false,
      type: Boolean
    },
    link: {
      default: false,
      type: Boolean
    },
    inverted: {
      default: false,
      type: Boolean
    },
    alt: {
      default: false,
      type: Boolean
    },
    cta: {
      default: false,
      type: Boolean
    },
    hollow: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    transparent: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    ondark: {
      type: Boolean,
      default: false
    },
    iconName: {
      default: '',
      type: String
    },
    iconPosition: {
      default: '',
      type: String
    }
  },
  computed: {
    context() {
      let buttonType = 'brand';
      if (this.secondary || this.hollow) {
        buttonType = 'neutral';
      }
      if (this.inverted) {
        buttonType = 'subtle';
      }
      if (this.emphasis) {
        buttonType = 'emphasis';
      }
      return buttonType;
    },
    type() {
      return this.tagelement === 'a' || this.link ? 'link' : this.tagelement === 'submit' ? 'submit' : 'button';
    },
    icon() {
      return this.iconName ? this.iconName : '';
    },
    position() {
      return this.iconPosition ? this.iconPosition : this.tagelement !== 'a' ? 'start' : 'end';
    }
  },
  created() {}
};
</script>
