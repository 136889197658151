<template>
  <li
    class="product"
    :class="{
      'product--default': !selected,
      'product--selected': selected,
      'product--alt': alt
    }"
  >
    <slot name="checkbox" />
    <div class="product__img-wrapper">
      <img
        v-if="!alt"
        :src="data.ImageURL"
        @error="imageLoadError"
      >
      <img
        v-if="alt"
        src="@/static/img/mediq-box.svg"
      >
    </div>
    <div class="product__text">
      <strong class="product__text__name">{{ data.Name }}</strong>
      <span class="product__text__desc">{{ data.LPH2_Tekst }}, {{ data.LPH3_Tekst }}</span>
    </div>
    <div
      v-if="tooltipMessage"
      v-tooltip="{content: tooltipMessage, placement: 'left-start', html: true}"
      class="product__Smaakbox"
    >
      <img src="@/static/img/Smaakbox.svg">
    </div>
    <div class="product__actions">
      <slot name="actions" />
    </div>
  </li>
</template>

<script>
export default {
  name: 'VProduct',
  props: {
    data: {
      default: () => {},
      type: Object
    },
    alt: {
      default: false,
      type: Boolean
    },
    selected: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      tooltipMessage: this.data.TooltipInformation
    }
  },
  methods: {
    imageLoadError () {
      console.log('Image without product')
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  $root: &;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $white;
  max-height: 150px;

  &.product--default {
    border: solid 1px $gray-light;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.product--selected {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  // Alternative styling for "smaakbox"
  &.product--alt {
    background: $teal-lighter;
    border: none;

    &.product--selected {
      // When the smaakbox is the selected product the background should be full width
      // The 17 pixels numbers is based on the styling of the zigzagwrapper.vue component
      margin-right: -17px;
      margin-left: -17px;
      padding-right: 17px;
      padding-left: 17px;
    }

    #{$root}__img-wrapper {
      min-width: 140px;
      min-height: 140px;
    }
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    min-height: 80px;
    min-width: 80px;

    img {
      max-height: 80px;
      max-width: 80px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }

  &__text { 
    flex-direction: column;
    flex: 1px;
    &__name {
      color: $gray-darkest;
      font-weight: 700; 
    }

    &__desc {
      display: flex;
      color: $gray-dark;
      font-size: type-scale(-1);
      line-height: line-height(-1);
    }
  }

  &__Smaakbox {
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
    text-align: left;
    min-height: 35px;
    min-width: 35px;

    img {
      max-height: 35px;
      max-width: 35px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }

  &__actions {
    margin-left: auto;

    > * {
      margin-left: 5px;
    }

    .btn[data-icon="delete"] {
      color: $teal-dark;
      transition: all 0.3s;

      &:hover {
        color: $teal-darkest;
      }

      &:last-child {
        margin-right: -5px;
      }
    }
  }

  $transition-delay: 0.3s;
  $transition-time: 0.3s;
  $transition-time-height: 0.5s;

  &.transition-down-up-enter-active,
  &.transition-up-down-enter-active {
    transition:
      transform $transition-time $transition-delay ease-in,
      opacity $transition-time $transition-delay ease-in,
      max-height $transition-time-height $transition-delay ease-in;
  }

  &.transition-down-up-leave-active,
  &.transition-up-down-leave-active {
    transition:
      transform $transition-time ease-in,
      opacity $transition-time ease-in,
      max-height $transition-time-height ease-in;
  }

  &.transition-down-up-leave-to,
  &.transition-up-down-leave-to,
  &.transition-down-up-enter,
  &.transition-up-down-enter {
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }

  &.transition-down-up-leave-to,
  &.transition-down-up-enter {
    transform: translateY(80px);
  }

  &.transition-up-down-leave-to,
  &.transition-up-down-enter {
    transform: translateY(-80px);
  }
}
</style>
