import { activeDepartment$ } from '@/services/activeDepartment$';
import { map } from 'rxjs/operators';

export const activePpwRecId$ = activeDepartment$.pipe(
  map(m => {
    if(m) {
      return m.ppwRecId;
    }
    else {
      return null;
    }
  })
);
