<template>
  <ul>
    <li
      v-for="(category, categoryKey) in categories"
      :key="categoryKey"
    >
      <div @click="select(category)">
        <label
          :for="category.category.categoryId"
          :class="{ active: category.expanded || category.active }"
        >{{ category.category.description }}</label>
        <v-menu-down-icon
          v-if="category.children && category.children.length > 0"
          :class="{ 'is-expanded': category.expanded }"
          class="icon-menu-down"
        />
      </div>
      <recursive-categories
        v-if="category.children && category.children.length > 0 && category.expanded"
        :categories="category.children"
        @input="onRecursiveCategoriesInput"
        @expand-state-changed="onExpandStateChanged"
      />
    </li>
  </ul>
</template>

<script>
import VMenuDownIcon from 'icons/MenuDown'

export default {
  name: 'RecursiveCategories',
  components: {
    VMenuDownIcon
  },
  props: {
    categories: Array,
    value: Array
  },
  data () {
    return {
      selectedItem: Object
    };
  },
  methods: {
    select (category) {
      if(this.selectedItem) {
        this.selectedItem.active = false;
      }

      this.selectedItem = category;
      category.active = true;
      const isExpandable = category.children && category.children.length > 0;

      if(isExpandable)
      {
        // expand it, do not select it
        this.selectedItem.expanded = !this.selectedItem.expanded;

        // Either select or expand
        this.$emit("expandStateChanged", [ category ]);
      }

      this.$emit("input", category);


      this.$forceUpdate();
    },
    onExpandStateChanged (value) {
      // One of our children changed and we need to tell our parent
      // It is "always" a children of our selected item, which makes it easy
      this.$emit("expandStateChanged", [ this.selectedItem, ...value]);
    },
    onRecursiveCategoriesInput (value) {
      this.$emit("input", value);
    }
  }
}
</script>

<style lang="scss" scoped>
.backToProducts {
  padding-top: 10px;
}

.categories {
  border: none;
  margin: 0;
  padding: 0;

  > h1 {
    font-size: type-scale(2);
    margin-top: 0;
    margin-bottom: 10px;
    color: $gray-darkest;
  }

  > ul {
    color: $gray-darkest;
    font-weight: bold;
    padding: 0;
    margin: 0;
    list-style-type: none;

    > li {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: solid 2px $gray;

      &:first-child {
        padding-top: 0;
      }

      > div {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      ul {
        color: $gray-dark;
        font-weight: normal;
        padding: 0;
        margin-left: 15px;
        margin-top: 25px;
        list-style-type: none;

        li {
          margin-bottom: 25px;

          > div {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  label {
    display: block;
    cursor: pointer !important;
  }

  input {
    position: absolute;
    left: -3000em;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .icon-menu-down {
    display: inline-table !important;
    fill: $teal-darkest;
    margin-top: 0;
    transition: transform 0.2s ease-in-out;
    margin-left: auto;

    &.is-expanded {
      transform: rotate(-180deg);
    }
  }

  .active {
    color: $teal-darkest;
    font-weight: bold;
  }
}
</style>
