<template>
  <generic-modal v-if="promptActive" class="genericModal">
    <template #header>
      <template v-if="props.title">
        {{ props.title }}
      </template>
    </template>
    <template #body>
      <template v-if="props.message">
        {{ props.message }}
      </template>
      <component
        :is="props.component"
        v-bind="props.componentProps"
        @prompt-component-data="storeComponentData"
        @prompt-component-readystate="setComponentReadystate"
      />
    </template>
    <template #footer>
      <v-button tagelement="button" md hollow transparent @click="cancel">
        {{ props.cancelButton }}
      </v-button>
      <v-button
        v-if="props.showConfirm"
        :disabled="confirmDisabled || checkCharacterLength"
        tagelement="button"
        md
        primary
        @click="confirm"
      >
        {{ props.confirmButton }}
      </v-button>
    </template>
  </generic-modal>
</template>

<script>
// TODO: pass generic props to component........

import vButton from '@/components/shared/Button.vue';
import GenericModal from '@/components/shared/GenericModal';

export default {
  name: 'Prompt',
  components: {
    vButton,
    GenericModal
  },
  data() {
    return {
      characterLimit: 160,
      promptActive: false,
      defaultProps: {
        confirmButton: 'Bevestigen',
        cancelButton: 'Annuleren',
        showConfirm: true
      },
      props: {},
      confirmDisabled: false,
      payload: null
    };
  },
  computed: {
    checkCharacterLength() {
      if (this.payload) {
        return this.payload.comment.length >= this.characterLimit + 1;
      } else {
        return null;
      }
    }
  },
  created() {
    this.$emitter.on('prompt', (props) => {
      this.props = {...this.defaultProps, ...props}
      this.show()
    })
  },
  methods: {
    closeModal() {
      this.promptActive = false;
    },
    show() {
      this.promptActive = true;
    },
    confirm() {
      this.closeModal();
      if (this.props.resolve) this.props.resolve(this.payload);
    },
    cancel() {
      this.closeModal();
      if (this.props.reject) this.props.reject();
    },
    storeComponentData(payload) {
      this.payload = payload;
    },
    setComponentReadystate({ ready }) {
      this.confirmDisabled = !ready;
    }
  }
};
</script>

<style lang="scss">
.genericModal {
  .generic-modal {
    > .generic-modal-body {
      padding: 25px !important;
      font-size: 16px;
    }
  }
}
</style>
