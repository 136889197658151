<template>
  <div v-if="licenseOverviews" class="license">
    <v-table id="licenseRow">
      <thead>
        <v-header-row :data="headers" :show-sort="true" @sort-by-field="sortLicensesByField" />
      </thead>
      <v-license-row
        v-for="(row, index) in displayedLicenseOverviews"
        :key="row.id"
        :data="row"
        :index="index"
        :enable-management="enableManagement"
        :display-status-icon="displayStatusIcon"
        :is-archived="isArchived"
        @stop="stopLicenses"
        @close-license="closeLicense"
        @edit="editLicenses"
        @retrieve="retrievePump"
        @extend="extendLicenses"
        @assign-pump="assignPump"
        @exchange="exchangePump"
      />
    </v-table>
    <!--Show More/Less Functionality -->
    <div v-if="licenseOverviews.length > filterCountDefault" class="row grid-justify-content-center">
      <div class="col-xs-12 col-md-12">
        <v-button hollow md class="filter-count-button" @click.prevent="toggleFilterCount">
          <template v-if="!filterCount">{{ filterCountButtonTextOptions.less }}</template>
          <template v-if="filterCount > 0">
            {{ filterCountButtonTextOptions.more }}
            <span v-if="licenseOverviews.length > filterCountDefault">({{ licenseOverviews.length }})</span>
          </template>
        </v-button>
      </div>
    </div>
    <v-overlay v-if="retrieveOverlay">
      <pump-retrieve-form
        :data="selectedPumpToRetrieve"
        @success="closeRetrieveOverlay"
        @close-modal="closeRetrieveOverlay"
      />
    </v-overlay>
    <v-overlay v-if="exchangeOverlay">
      <pump-exchange-form
        :data="selectedPumpToExchange"
        @pump-exchange-type-value="successExchangeOverlay"
        @close-modal="closeExchangeOverlay"
      />
    </v-overlay>
    <generic-modal v-if="showLicenseStop">
      <template #header>
        <div v-if="stopEquipmentDetails.length > 0 ? stopEquipmentDetails[0].equipmentType === 'RCC' : false">
          {{ stopLicenseDetailsModel.title }}
        </div>
      </template>
      <template #body>
        <div
          v-if="
            stopEquipmentDetails.length > 0 &&
            showTherapyNotWorkingOption == false &&
            stopEquipmentDetails[0].equipmentType !== 'RCC'
          "
        >
          <v-paragraph>
            Pomp {{ stopEquipmentDetails[0].serialNumber }} wordt opgehaald door
            <span v-if="stopEquipmentDetails[0].pickUp.canBePickedUpBy[0] === 'Mediq'">Mediq</span>
            <span v-else>Uw organisatie</span>.
          </v-paragraph>
        </div>
        <div class="license__stop__reasons">
          <h3>{{ stopLicenseDetailsModel.header }}</h3>
        </div>
        <div v-for="(reason, reasonIndex) in stopLicenseDetailsModel.reasons" :key="reasonIndex">
          <div class="form-group__radio">
            <input
              :id="'reason' + reason.key"
              v-model="stopReason"
              name="stopLicenseReason"
              :value="reason.key"
              type="radio"
            />
            <label :for="'reason' + reason.key"><span></span>{{ reason.value }}</label>
          </div>
        </div>
        <div class="license__stop__endDate">
          <v-input-date-picker
            id="stopDate"
            ref="date"
            type="date"
            name="stopDate"
            label="Datum beëindigen therapie"
            autofocus
            custom-date-range
            :disabled="disableDatepicker"
            :disable-future-dates="disableDatepickerFutureDates"
            @update-validation="updateValidation"
          />
        </div>
        <div v-if="stopEquipmentDetails.length > 0 && showTherapyNotWorkingOption == false">
          <div>
            <legend>Besmettingsgevaar</legend>
            <div class="form-group__radio">
              <input id="none" name="flammableOption" type="radio" checked @change="checkFlammableOption" />
              <label for="none"><span></span>Niet van toepassing</label>
            </div>
            <div class="form-group__radio">
              <input id="mrsa" name="flammableOption" type="radio" @change="checkFlammableOption" />
              <label for="mrsa"><span></span>MRSA</label>
            </div>
            <div class="form-group__radio">
              <input id="chemo" name="flammableOption" type="radio" @change="checkFlammableOption" />
              <label for="chemo"><span></span>Chemo</label>
            </div>
          </div>
          <div
            class="warning-message"
            v-if="stopEquipmentDetails.length > 0 ? stopEquipmentDetails[0].equipmentType === 'RCC' : false"
          >
            <warning-message warning="" :isInformation="true" :message="statusMessage"></warning-message>
          </div>
        </div>
        <div v-if="stopEquipmentDetails.length > 0 && showTherapyNotWorkingOption == false && showContactFields">
          <div class="row grid-margin">
            <div class="col-xs-8">
              <div class="license__stop__Address">
                <table class="table">
                  <colgroup>
                    <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>
                        <legend>Gewenste ophaaldres</legend>
                        <div class="form-group__radio">
                          <input
                            id="homeAddress"
                            name="deliveryaddress"
                            type="radio"
                            checked
                            @change="checkRadioAddress"
                          />
                          <label for="homeAddress"><span></span>Woonadres</label>
                        </div>
                        <div class="form-group__radio" v-if="deliveryAddress !== null">
                          <input id="deliveryAddress" name="deliveryaddress" type="radio" @change="checkRadioAddress" />
                          <label for="deliveryAddress"><span></span>Afwijkend afleveradres</label>
                        </div>
                        <div class="form-group__radio">
                          <input
                            id="differentAddress"
                            name="deliveryaddress"
                            type="radio"
                            @change="checkRadioAddress"
                          />
                          <label for="differentAddress"><span></span>Eénmalig afwijkend Ophaaladres</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <legend>Ophaaladres</legend>
                        <div v-if="SelectedAddress === 0">
                          {{ homeAddress.Straatnaam }} {{ homeAddress.Huisnummer }} {{ homeAddress.Toevoeging }}
                          <br />
                          {{ homeAddress.Postcode }} {{ homeAddress.Woonplaats }}
                          <br />
                          {{ homeAddress.Land }}
                        </div>
                        <div v-if="SelectedAddress === 1 && deliveryAddress !== null">
                          {{ deliveryAddress.Straatnaam }} {{ deliveryAddress.Huisnummer }}
                          {{ deliveryAddress.Toevoeging }}
                          <br />
                          {{ deliveryAddress.Postcode }} {{ deliveryAddress.Woonplaats }}
                          <br />
                          {{ deliveryAddress.Land }}
                        </div>
                        <div v-if="SelectedAddress && SelectedAddress === 2">
                          <div class="row">
                            <div class="col-xs-12">
                              <v-input-group
                                v-model="formConfig.Postcode.model"
                                maxlength="7"
                                :initvalue="formConfig.Postcode.model"
                                :rule="formConfig.Postcode.rule"
                                :label="formConfig.Postcode.label"
                                :inputid="formConfig.Postcode.ref"
                                @blur="autoFillAddress"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <v-input-group
                                v-model="formConfig.Huisnummer.model"
                                :initvalue="formConfig.Huisnummer.model"
                                :rule="formConfig.Huisnummer.rule"
                                :label="formConfig.Huisnummer.label"
                                :inputid="formConfig.Huisnummer.ref"
                                @blur="autoFillAddress"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <v-input-group
                                :key="uniqueKey"
                                v-model="formConfig.Straatnaam.model"
                                :initvalue="formConfig.Straatnaam.model"
                                :rule="formConfig.Straatnaam.rule"
                                :label="formConfig.Straatnaam.label"
                                :overwrite-value="formConfig.Straatnaam.model"
                                :inputid="formConfig.Straatnaam.ref"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <v-input-group
                                :key="uniqueKey"
                                v-model="formConfig.Woonplaats.model"
                                :initvalue="formConfig.Woonplaats.model"
                                :rule="formConfig.Woonplaats.rule"
                                :label="formConfig.Woonplaats.label"
                                :overwrite-value="formConfig.Woonplaats.model"
                                :inputid="formConfig.Woonplaats.ref"
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- Telephone number -->
          <div class="license__stop__telephone" v-if="formConfig.telephoneNumber">
            <v-input-group-simple-2
              :id="'WoonAdres' + formConfig.telephoneNumber.label"
              v-model:value="formConfig.telephoneNumber.model"
              :inputid="formConfig.telephoneNumber.ref"
              :rules="formConfig.telephoneNumber.rule"
              label="Contactgegevens"
              :placeholder="formConfig.telephoneNumber.label"
              @input="updateTelephone(formConfig.telephoneNumber.model)"
            />
          </div>
          <div class="horizontal_row">
            <hr />
          </div>
          <div class="license__stop__fields">
            <textarea
              id="license__stop-remarks"
              class="license__stop__textarea"
              placeholder="Opmerkingen (optioneel)"
              @input="setRemarks($event.target.value)"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row grid-justify-content-right license__stop__actionHolder">
          <v-button tagelement="button" md hollow transparent @click="cancelStopAutorization"> Annuleren </v-button>
          <v-button tagelement="button" md primary @click="confirmStopAutorization"> Bevestigen </v-button>
        </div>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VLicenseRow from './LicenseRow';
import VTable from '@/components/shared/table/Table';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import PumpRetrieveForm from '@/components/sections/pumpOverview/PumpRetrieveForm';
import PumpExchangeForm from '@/components/sections/pumpOverview/PumpExchangeForm';
import { localDateToJson, stringToLocalDate } from '../../../services/date.service';
import GenericModal from '@/components/shared/GenericModal';
import WarningMessage from '@/components/shared/WarningMessage';
import { currentUserIdentity$, navigateToAccountability, invalidateLicenseOverviews$ } from '@/services';

import { computed, ref } from 'vue';
import { useStore, mapActions } from 'vuex';
import { useForm } from 'vee-validate';

export default {
  name: 'Licenses',
  components: {
    VHeaderRow,
    VLicenseRow,
    VTable,
    VInputDatePicker,
    PumpRetrieveForm,
    PumpExchangeForm,
    GenericModal,
    WarningMessage
  },
  props: {
    licenseOverviews: {
      type: Array,
      default: () => []
    },
    patientId: {
      type: Number
    },
    enableManagement: {
      type: Boolean
    },
    displayStatusIcon: {
      type: Boolean
    },
    isArchived: {
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const user = ref(null);

    const pdfFileToken = computed(() => store.getters['accountability/getPdfFileToken']);
    const pdf = computed(() => store.getters['accountability/getPdf']);
    const homeAddress = computed(() => store.getters['patient/getPatientHomeAddress']);
    const deliveryAddress = computed(() => store.getters['patient/getPatientDeliveryAddress']);
    const differentAddress = computed(() => store.getters['patient/getPatientDifferentAddress']);
    const SelectedAddress = computed(() => store.getters['patient/getSelectedAddress']);
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const siteCode = computed(() => store.getters['navigation/getSiteCode']);
    const stopReasonCode = computed(() => store.getters['patient/getStopReason']);

    const closeLicenseWithoutAccountability = (value) =>
      store.dispatch('patient/closeLicenseWithoutAccountability', value);
    const fetchPatientAddress = (value) => store.dispatch('patient/fetchPatientAddress', value);
    const setShowExtendLicensesBlock = (value) => store.commit('accountability/setShowExtendLicensesBlock', value);
    const stopLicensesStore = (value) => store.dispatch('patient/stopLicenses', value);
    const fetchAvailableLicenses = () => store.dispatch('patient/fetchAvailableLicenses');
    const stopAllocatedPump = (value) => store.dispatch('patient/stopAllocatedPump', value);
    const fetchPumps = (value) => store.dispatch('patient/fetchPumps', value);
    const setSelectedAddress = (value) => store.commit('patient/setSelectedAddress', value);
    const fetchAddressLookup = (value) => store.dispatch('patient/fetchAddressLookup', value);

    currentUserIdentity$.subscribe((identity) => {
      user.value = identity;
    });

    store.dispatch('patient/fetchStopReason');

    return {
      pdfFileToken,
      pdf,
      homeAddress,
      deliveryAddress,
      differentAddress,
      SelectedAddress,
      patientGegevens,
      siteCode,
      stopReasonCode,
      closeLicenseWithoutAccountability,
      fetchPatientAddress,
      setShowExtendLicensesBlock,
      stopLicensesStore,
      fetchAvailableLicenses,
      stopAllocatedPump,
      fetchPumps,
      setSelectedAddress,
      fetchAddressLookup,
      ...mapActions({
        requestAccountabilityCopy: 'accountability/requestAccountabilityCopy'
      }),
      user
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      addressId: 0,
      oneTimeAddress: false,
      disableAddress: false,
      showLicenseStop: false,
      // option should be visible to respiratory Licenses
      showTherapyNotWorkingOption: false,
      isDateValidated: false,
      endDate: null,
      stopLicenseAccountabilityId: null,
      stopLicenseLicenseId: null,
      stopEquipmentDetails: [],
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      filterCountDefault: 5,
      filterCount: null,
      formConfig: {},
      retrieveOverlay: false,
      exchangeOverlay: false,
      selectedPumpToRetrieve: {},
      selectedPumpToExchange: {},
      stopPumpDetails: {},
      statusMessage: '',
      remarks: '',
      telephoneNumber: null,
      msra: false,
      flammableOption: 'Niet van toepassing',
      stopReason: '005',
      stopReasonText: '',
      overviewPumps: {},
      sortFieldDetails: null,
      showContactFields: true,
      uniqueKey: 0,
      headers: [
        '',
        '',
        'Type',
        'Product (-groep)',
        {
          title: 'Startdatum',
          valueFn: (item) => item.startDate || ''
        },
        {
          title: 'Einddatum',
          valueFn: (item) => item.endDate || ''
        },
        ''
      ]
    };
  },
  computed: {
    /**
     * @returns {array} - list with licenses filtered by dynamic value of count and sliced by dynamic value of filtercount
     */
    displayedLicenseOverviews() {
      let licenseOverviews = this.licenseOverviews;
      const sortFieldDetails = this.sortFieldDetails;
      if (sortFieldDetails) {
        licenseOverviews = licenseOverviews.sort(function (a, b) {
          var leftValue = sortFieldDetails.valueFn(a);
          var rightValue = sortFieldDetails.valueFn(b);

          if (sortFieldDetails.isAscending) {
            return leftValue.localeCompare(rightValue);
          } else {
            return rightValue.localeCompare(leftValue);
          }
        });
      }
      return this.filterCount ? licenseOverviews.slice(0, this.filterCount) : licenseOverviews;
    },
    disableDatepickerFutureDates() {
      return this.stopReason === '004' || this.stopReason === '003';
    },
    stopLicenseDetailsModel() {
      return {
        title: 'Machtiging stopzetten',
        header: 'Reden voor stopzetten',
        reasons: this.showTherapyNotWorkingOption
          ? this.stopReasonCode
          : this.stopReasonCode.filter((x) => x.key != '007' && x.key != '011')
      };
    },
    disableDatepicker() {
      return this.showTherapyNotWorkingOption ? true : false;
    }
  },
  watch: {
    SelectedAddress(value) {
      if (value === 2) {
        this.setFormConfig();
      }
    }
  },
  created() {
    this.fetchOverviewPumps();
    this.filterCount = this.filterCountDefault;
  },
  methods: {
    closeModal() {
      this.showLicenseStop = false;
    },
    closeRetrieveOverlay() {
      this.selectedPumpToRetrieve = {};
      this.retrieveOverlay = false;
    },
    closeExchangeOverlay() {
      this.selectedPumpToExchange = {};
      this.exchangeOverlay = false;
    },
    successExchangeOverlay(exchangeType) {
      var licenseId = this.selectedPumpToExchange['LicenseId'];
      const d = new Date();
      const stopDate = d.toJSON();

      axios
        .post('../cmd/DeallocateEquipmentForLicense', {
          LicenseId: licenseId,
          EquipmentId: this.selectedPumpToExchange['equipmentID'],
          EquipmentStopDate: stopDate,
          ExchangeType: exchangeType
        })
        .then((response) => {
          if (response && response.data) {
            this.selectedPumpToExchange = {};
            this.exchangeOverlay = false;
            router.push('/pumpassignment/patientdashboard/' + licenseId);
          }
        });
    },
    closeLicense({ id }) {
      // Start a promise, call for a prompt
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Machtiging stopzetten',
          message: 'Weet u zeker dat u deze machtiging wilt stopzetten?',
          resolve,
          reject
        });
      })
        // When prompt was confirmed
        .then((payload) => {
          this.closeLicenseWithoutAccountability(id).then((response) => {
            invalidateLicenseOverviews$();
            this.dispatchStopPumpCall();
          });
        })
        // When cancelled
        .catch(() => {});
    },
    stopLicenses({ licenseData, licenseId, equipments }) {
      this.stopEquipmentDetails = equipments;
      // Dispatching server calls to fetch patient addresses based on equipment type
      if (this.stopEquipmentDetails && this.stopEquipmentDetails.length > 0) {
        if (
          this.stopEquipmentDetails.length > 0 &&
          this.stopEquipmentDetails[0].equipmentType === 'RCC' &&
          !this.stopEquipmentDetails[0].directOrder
        ) {
          this.showContactFields = false;
          this.createAlertMessage('organisatie');
        } else {
          this.createAlertMessage('Mediq');
          this.getPatientAddress();
        }
      }
      this.showLicenseStop = true;
      (this.stopLicenseLicenseId = licenseId), (this.stopLicenseAccountabilityId = licenseData.accountabilityId);
      this.showTherapyNotWorkingOption = licenseData.showTherapyNotWorkingOption;
    },
    getPatientAddress() {
      this.fetchPatientAddress(this.patientId);
      this.formConfig = {
        telephoneNumber: {
          ref: 'telephoneNumber',
          label: 'Telefoonnummer',
          model: '',
          rule: {
            required: true,
            numeric: true
          },
          attached: true,
          error: false,
          errorMessage: ''
        }
      };
    },
    editLicenses({ id }) {
      this.setShowExtendLicensesBlock(false);
      // Call the server for a new accountability, based on data of the current, 'edited' one
      this.requestAccountabilityCopy({
        AccountabilityId: id,
        IsAccountabilityUsedForAssigningMultiplePumps: false
      })
        .then((response) => {
          const copyAccountabilityId = response.data.Id;
          // Request granted
          navigateToAccountability(copyAccountabilityId);
        })
        .catch((error) => {
          if (error !== 'cancel') {
            const m = error.response.data.Exception.Message;
            new Promise((resolve, reject) => {
              this.$emitter.emit('prompt', {
                title: 'Machtiging wijzigen',
                message: m,
                confirmButton: 'Naar patiënt dashboard',
                resolve,
                reject
              });
            })
              .then(() => {
                // Start a new accountability with no prefilled data. However, at the end the original still needs to be terminated!!!!!
                // TODO: when template no longer available (exception), then user needs a way to select one before starting anew
                //       the idea right now is that with the exception, backend includes a templateId property in the response in case they can
                //       determine which is the 'follow-up' template when the original is no longer valid
                //       the problem happens when backend cannot and we need to either render a template selection button in the exception overlay
                //       or we need to throw in the towel and tell the user they can't extend an accountability
                // store.dispatch('accountability/startNewAccountability', templateId).then((response) => router.push('/machtiging'))
              })
              .catch(() => {});
          }
        });
    },
    extendLicenses({ id }) {
      // Call the server for a new accountability, based on data of the current
      this.requestAccountabilityCopy({
        AccountabilityId: id,
        IsAccountabilityUsedForAssigningMultiplePumps: false
      }).then((response) => {
        const copyAccountabilityId = response.data.Id;
        navigateToAccountability(copyAccountabilityId, true);
      });
    },
    retrievePump(pumpData) {
      this.selectedPumpToRetrieve = this.getSpecificPump(pumpData);
      this.retrieveOverlay = true;
    },
    exchangePump(pumpData) {
      this.selectedPumpToExchange = this.getSpecificPump(pumpData);
      this.selectedPumpToExchange['LicenseId'] = pumpData.licenseId;
      this.exchangeOverlay = true;
    },
    // Function to update the date value to stop equipment
    updateValidation(date) {
      this.isDateValidated = true;
      this.endDate = date;

      const d = stringToLocalDate(date);
      const dJson = localDateToJson(d);

      this.stopPumpDetails['stopPumpAllocationDate'] = dJson;
    },
    cancelStopAutorization() {
      this.showLicenseStop = false;
      this.stopLicenseAccountabilityId = null;
      this.stopLicenseLicenseId = null;
      this.stopPumpDetails = {};
      this.stopReason = '005';
      this.stopEquipmentDetails = [];
      this.addressId = 0;
      this.telephoneNumber = null;
      this.IsOneTimeAddress = false;
      this.formConfig = {};
    },
    confirmStopAutorization(e) {
      e.stopPropagation();
      let stopReasonIndex = this.stopLicenseDetailsModel.reasons.findIndex((reason) => reason.key === this.stopReason);
      this.stopReasonText = this.stopLicenseDetailsModel.reasons[stopReasonIndex].value;

      if (this.isDateValidated) {
        const endDate = stringToLocalDate(this.endDate);
        const endDateJson = localDateToJson(endDate);
        const stopLicenseDetails = {
          AccountabilityId: this.stopLicenseAccountabilityId,
          LicenseId: this.stopLicenseLicenseId,
          EndDate: endDateJson,
          stopReasonCode: this.stopReason,
          stopReasonText: this.stopReasonText
        };

        if (this.stopEquipmentDetails.length > 0) {
          this.stopPumpDetails['pumpId'] = this.stopEquipmentDetails[0].equipmentId;
          if (this.homeAddress) {
            this.stopPumpDetails['AddressId'] = this.addressId > 0 ? this.addressId : this.homeAddress.AdresId;
          } else {
            this.stopPumpDetails['AddressId'] = this.addressId;
          }
          this.stopPumpDetails['IsOneTimeAddress'] = this.oneTimeAddress;
          this.stopPumpDetails['equipmentId'] = this.stopEquipmentDetails[0].equipmentId;
          this.stopPumpDetails['remarks'] = 'TelephoneNumber: ' + this.telephoneNumber;
          if (this.remarks !== '') {
            this.stopPumpDetails['remarks'] = this.stopPumpDetails['remarks'] + ',  remark: ' + this.remarks;
          }
          (this.stopPumpDetails['msra'] = this.msra),
            (this.stopPumpDetails['reasonOfContamination'] = this.showContactFields ? this.flammableOption : '');
          this.stopPumpDetails['remarks'] =
            this.stopPumpDetails['remarks'] + ',  Besmettingsgevaar: ' + this.flammableOption;
          if (this.SelectedAddress === 2) {
            for (let key in this.differentAddress) {
              if (this.formConfig.hasOwnProperty(key) && this.formConfig[key].model !== undefined) {
                this.differentAddress[key] = this.formConfig[key].model;
              }
            }
            this.differentAddress.Land = 'NL';
            this.stopPumpDetails['differentAddress'] = this.differentAddress;
            this.stopPumpDetails['oneTimeAddress'] = this.differentAddress;
            if (this.stopPumpDetails.hasOwnProperty('deliveryAddress')) {
              delete this.stopPumpDetails['deliveryAddress'];
            }
            if (this.stopPumpDetails.hasOwnProperty('homeAddress')) {
              delete this.stopPumpDetails['homeAddress'];
            }
          } else if (this.SelectedAddress === 1) {
            this.stopPumpDetails['deliveryAddress'] = this.deliveryAddress;
            if (this.stopPumpDetails.hasOwnProperty('differentAddress')) {
              delete this.stopPumpDetails['differentAddress'];
            }
            if (this.stopPumpDetails.hasOwnProperty('homeAddress')) {
              delete this.stopPumpDetails['homeAddress'];
            }
          } else {
            this.stopPumpDetails['homeAddress'] = this.homeAddress;
            if (this.stopPumpDetails.hasOwnProperty('differentAddress')) {
              delete this.stopPumpDetails['differentAddress'];
            }
            if (this.stopPumpDetails.hasOwnProperty('deliveryAddress')) {
              delete this.stopPumpDetails['deliveryAddress'];
            }
          }
          this.validateAllFields();
        } else {
          this.stopLicences(stopLicenseDetails);
        }
      }
    },
    stopLicences(stopLicenseDetails) {
      this.stopLicensesStore(stopLicenseDetails).then((response) => {
        this.fetchAvailableLicenses();
        this.showLicenseStop = false;
        this.stopLicenseLicenseId = null;
        if (this.stopEquipmentDetails.length > 0) {
          if (this.stopEquipmentDetails[0].equipmentType === 'RCC' && !this.stopEquipmentDetails[0].directOrder) {
            this.showAlert('RCC');
          } else {
            this.showAlert('R');
          }
        } else {
          this.showAlert('licenses');
        }
      });
    },
    showAlert(type) {
      this.cancelStopAutorization();
      let message = '';
      if (type === 'licenses') {
        message = 'Machtiging is succesvol stopgezet.';
      }
      if (type === 'RCC') {
        if (this.showContactFields) {
          message = 'pomp wordt opgehaald door Mediq';
        } else {
          message = 'Pomp wordt door uw eigen organisatie opgehaald.';
        }
      }
      if (type === 'R') {
        message = 'pomp wordt opgehaald door Mediq.';
      }
      this.$emitter.emit('alert', {
        message: message
      });
      this.stopPumpDetails = {};
      this.stopEquipmentDetails = [];
      invalidateLicenseOverviews$();
    },
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    // Function to dispatch server call to stop equipment
    dispatchStopPumpCall() {
      this.stopAllocatedPump(this.stopPumpDetails).then((response) => {
        let requiredIds = {
          PatientId: this.patientId
        };
        this.fetchAvailableLicenses();
        this.fetchPumps(requiredIds);
      });
    },
    // Funciton to set validations to the form fields in the page
    setFormConfig() {
      this.formConfig = {
        Postcode: {
          ref: 'Postcode',
          label: 'Postcode',
          model: '',
          rule: {
            required: true,
            regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/
          },
          attached: true,
          error: false,
          errorMessage: ''
        },
        Huisnummer: {
          ref: 'Huisnummer',
          label: 'Huisnummer',
          model: '',
          rule: {
            required: true
          },
          attached: true,
          error: false,
          errorMessage: ''
        },
        Straatnaam: {
          ref: 'Straatnaam',
          label: 'Straatnaam',
          model: '',
          rule: {
            required: true
          },
          attached: true,
          error: false,
          errorMessage: ''
        },
        Woonplaats: {
          ref: 'Woonplaats',
          label: 'Woonplaats',
          model: '',
          rule: {
            required: true
          },
          attached: true,
          error: false,
          errorMessage: ''
        },
        telephoneNumber: {
          ref: 'telephoneNumber',
          label: 'Telefoonnummer',
          model: '',
          rule: {
            required: true,
            regex: /^0\d{9}$/
          },
          attached: true,
          error: false,
          errorMessage: ''
        }
      };
    },
    // Function to validate all form fields
    async validateAllFields(elements) {
      const result = await this.validate();
      if (result.valid) {
        const endDate = stringToLocalDate(this.endDate);
        const endDateJson = localDateToJson(endDate);
        const stopLicenseDetails = {
          AccountabilityId: this.stopLicenseAccountabilityId,
          LicenseId: this.stopLicenseLicenseId,
          EndDate: endDateJson,
          stopReasonCode: this.stopReason,
          stopReasonText: this.stopReasonText
        };
        this.stopLicences(stopLicenseDetails);
        this.dispatchStopPumpCall();
      } else {
      }
    },
    // Function to update address details based on user interaction on address block
    checkRadioAddress(e) {
      let checkedAddress = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      this.oneTimeAddress = false;
      if (checkedAddress === 'homeAddress') {
        this.setSelectedAddress(0);
        if (this.homeAddress) {
          this.addressId = this.homeAddress.AdresId;
        }
        this.resetFormConfigValues();
      }
      if (checkedAddress === 'deliveryAddress') {
        this.setSelectedAddress(1);
        if (this.deliveryAddress) {
          this.addressId = this.deliveryAddress.AdresId;
        }
        this.resetFormConfigValues();
      }
      if (checkedAddress === 'differentAddress') {
        if (this.differentAddress) {
          this.addressId = this.differentAddress.AdresId;
        }
        this.oneTimeAddress = true;
        this.setSelectedAddress(2);
      }
    },
    // Function to update the flammable option based on the user interaction on the radio buttons
    checkFlammableOption(e) {
      let flammableOption = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      if (flammableOption === 'none') {
        this.flammableOption = 'Niet van toepassing';
        this.showContactFields = false;
        if (this.stopEquipmentDetails[0].directOrder) {
          this.showContactFields = true;
          this.createAlertMessage('Mediq');
        } else {
          this.createAlertMessage('organisatie');
        }
      }
      if (flammableOption === 'mrsa') {
        this.flammableOption = 'MRSA';
        if (this.stopEquipmentDetails[0].equipmentType === 'RCC') {
          this.showContactFields = true;
        }
      }
      if (flammableOption === 'chemo') {
        this.flammableOption = 'Chemo';
        if (this.stopEquipmentDetails[0].equipmentType === 'RCC') {
          this.showContactFields = true;
        }
      }
      if (this.stopEquipmentDetails[0].equipmentType === 'RCC' && flammableOption !== 'none') {
        this.showContactFields = true;
        this.getPatientAddress();
        this.createAlertMessage('Mediq');
      } else if (
        this.stopEquipmentDetails[0].equipmentType === 'RCC' &&
        flammableOption === 'none' &&
        !this.stopEquipmentDetails[0].directOrder
      ) {
        for (let key in this.formConfig) {
          delete this.formConfig[key];
        }
      }
    },
    createAlertMessage(organization) {
      if (organization != 'Mediq') {
        this.statusMessage =
          'Pomp ' + this.stopEquipmentDetails[0].serialNumber + ' wordt opgehaald door uw organisatie.';
      } else {
        this.statusMessage = 'Pomp ' + this.stopEquipmentDetails[0].serialNumber + ' wordt opgehaald door Mediq.';
      }
    },

    // Funtion to reset form config
    resetFormConfigValues() {
      for (let key in this.formConfig) {
        if (this.formConfig[key].ref !== 'telephoneNumber') {
          delete this.formConfig[key];
        }
      }
    },
    resetAddressFieldValidations() {
      for (let key in this.formConfig) {
        if (this.formConfig[key].ref !== 'telephoneNumber') {
          this.formConfig[key].error = false;
          this.formConfig[key].errorMessage = '';
        }
      }
    },
    // Function to change MSRA based on user interaction
    changeBesttings(active) {
      this.msra = active;
    },
    // Function to update remarks based on user interaction
    setRemarks(remarks) {
      this.remarks = remarks;
    },
    // Function to update telephone based on user interaction
    updateTelephone(telephone) {
      this.telephoneNumber = telephone;
    },
    /**
     * @function sortLicensesByField
     * @description sorting the licenses base on the field
     */
    sortLicensesByField(sortFieldDetails) {
      this.sortFieldDetails = sortFieldDetails;
      this.$forceUpdate();
    },
    assignPump(licenseId) {
      router.push('/pumpassignment/patientdashboard/' + licenseId);
    },
    fetchOverviewPumps() {
      axios.get('Equipment/GetPumpOverview').then(
        (response) => {
          this.overviewPumps = response.data;
        },
        (error) => {
          return error.response;
        }
      );
    },
    getSpecificPump(pumpData) {
      return this.overviewPumps.allPumps.find((pump) => pump.equipmentID === pumpData.id);
    },
    autoFillAddress() {
      if (this.formConfig.Postcode.model && this.formConfig.Huisnummer.model) {
        this.fetchAddressLookup({
          postcode: this.formConfig.Postcode.model,
          huisnummer: this.formConfig.Huisnummer.model,
          land: 'NL'
        }).then((response) => {
          let hasAddress = response.data.City !== null;
          this.uniqueKey = this.uniqueKey + 1;
          this.resetAddressFieldValidations();
          this.formConfig.Straatnaam.model = hasAddress ? response.data.Street : '';
          this.formConfig.Woonplaats.model = hasAddress ? response.data.City : '';
          if (this.formConfig.Postcode.model && this.formConfig.Huisnummer.model) {
            this.disableAddress = true;
          } else {
            this.disableAddress = false;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.license {
  .material-design-icon {
    fill: $black;
    float: left;
    position: absolute;
    margin-top: 43px;
    z-index: 1000;
    padding: 0px 0px 0px 5px;
  }
  legend {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
  }
  &__stop {
    &__actionHolder {
      margin-top: 24px;
    }
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-popover;
    display: flex;
    align-items: center;
    justify-content: center;
    table {
      width: 100%;
      margin-bottom: 0px !important;
      > tbody {
        background: $white;
        legend {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        > tr:first-child {
          > td,
          > th {
            vertical-align: baseline;
            text-align: left;
            padding: 5px 0px;
          }
        }

        > tr:last-child td {
          padding: 5px 0px 5px;
          font-style: italic;
        }
      }
    }
    &__textarea {
      padding: 0px;
      min-height: 95px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      border: 1px solid $gray-light;
      width: 100%;
      padding: 10px;
      margin: 0px 0px 15px 0px;
    }
    &__endDate {
      margin-top: 20px;
    }
    > section {
      position: relative;
      z-index: 1001;
      text-align: left;
      background: $white;
      width: 100%;
      max-width: 600px;
      box-sizing: border-box;

      h1 {
        margin: 0;
        font-size: $heading2-font-size;
        font-weight: $heading-font-weight;
        line-height: $heading2-line-height;
        color: $heading-color;
        margin-bottom: 8px;
      }

      p {
        color: $paragraph-color;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        margin: 0;
      }
    }
    &__actions {
      display: flex;
      margin-top: 30px;

      .btn {
        outline: 0;
        margin-right: 10px;
      }
    }
    &__telephone {
      :deep(.form-group label) {
        font-size: 14px;
        font-weight: normal;
        position: relative;
        top: 10px;
        left: 0px;
        padding: 0 !important;
        margin-bottom: 14px;
      }
    }
  }
  table {
    table-layout: auto !important;
    margin-bottom: 24px;
  }
  .filter-count-button {
    align-self: center;
  }
  .model-header {
    color: #333333;
    font-weight: bold;
    font-size: 26px;
  }
  .license__stop__telephone {
    width: 286px;
  }
  .horizontal_row {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
</style>
