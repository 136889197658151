<template>
  <div class="Navigation">
    <PdsNavigation v-bind="$attrs" @click="handleClick"></PdsNavigation>
  </div>
</template>
<script>
import router from '@/router';
export default {
  name: 'Navigation',
  setup() {
    return {};
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      if (event.target.getAttribute('href') === '/') {
        router.push('/');
      }
    }
  }
};
</script>
