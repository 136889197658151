import moment from 'moment';
import axios from 'axios';
import store from '@/store';
import { pushActiveCustomerId } from '@/services';
function fetchGraphqlVariables() {
  let variables = {};
  if (store.state.navigation.siteCode === 'MGP') {
    variables = {
      publicationId: 'Hf9Z4q3Fiu4Y5GAQm',
      api_token: '3b89bd8b94c1c14635457b2aaf4cde'
    };
  }
  if (store.state.navigation.siteCode === 'MIF') {
    variables = {
      publicationId: 'yCedRJQ3FcZYiLErT',
      api_token: '44191e22640a3945a73a0e5789e12d'
    };
  }
  if (store.state.navigation.siteCode === 'MTF') {
    variables = {
      publicationId: 'bbAuMpKA3XB85LeHr',
      api_token: '864d484bf72050fa00bfa5aee9210b'
    };
  }
  if (store.state.navigation.siteCode === 'WIS') {
    variables = {
      publicationId: 'PsDuLzqi3o75uNEJT',
      api_token: '4a4e9422f7debc51ab9f74b05e94d2'
    };
  }
  if (store.state.navigation.siteCode === 'XDD' || store.state.navigation.siteCode === 'XPP') {
    variables = {
      publicationId: 's5qigoCcQBkYXFAiG',
      api_token: '7d32dcd6b326a50735cb886e5f67d6'
    };
  }
  if (store.state.navigation.siteCode === 'MRT') {
    variables = {
      publicationId: '6J5qreMgENi8DzHn4',
      api_token: '53232808c90b3412516c7369c34259'
    };
  }
  variables.events = { sessionId: Math.floor(Math.random() * 1000000000) };
  return variables;
}
export default {
  namespaced: true,
  state: {
    patientGegevens: {},
    patientFormData: {},
    newPatientIsSaved: false,
    formPurpose: 'new',
    patientInsurer: '',
    endPoints: {
      init: 'PatientgegevensWijzigen/Init',
      save: 'PatientgegevensWijzigen/SaveExistingPatient'
    },
    services: {
      header: ['', 'Service Calls', 'Status', 'Datum'],
      data: {
        rows: []
      }
    },
    followStatus: false,
    pumps: {
      header: ['', 'Omschrijving', 'Serienummer', '', ''],
      title: '',
      buttonLabel: '',
      data: {
        rows: []
      }
    },
    pumpTypes: [
      { id: 0, title: 'Uit eigen depot' },
      { id: 1, title: 'Uit Mediq magazijn' }
    ],
    patientAddress: {},
    availablePumps: [],
    measurements: {
      header: ['', 'Datum invoer', 'Stemming patiënt', 'AHI', 'Stemming partner', ''],
      title: 'Meetmomenten',
      buttonLabel: 'Meetmoment toevoegen',
      data: {
        rows: []
      }
    },
    patientFollow: false,
    followUnFollowPatient: {},
    measurementTypes: [
      { id: 0, title: 'Uitlezingen' },
      { id: 0, title: 'Vragenlijst' }
    ],
    patientAvailableLicenses: [],
    customerMarketingPermission: null,
    defaultCustomerMarketingPermission: { DiaCare: false, StomaLife: false, VTPlus: false, WoundCare: false },
    careProgramDetails: {},
    sso: [],
    wlzStatus: false,
    currentPatientId: '',
    customerNumber: '',
    pumpAssignWhileCreateAccountability: false,
    serviceCenterDetails: {},
    serviceCenterSearchDetails: {},
    serviceCenterRelatedQuestions: {},
    stopReason: {}
  },
  mutations: {
    setAllPatientFormData(state, payload) {
      state.patientFormData = payload;
    },
    setPatientGegevens(state, payload) {
      state.patientGegevens = payload;
      if (state.patientGegevens.GeslachtId === null) {
        state.patientGegevens.GeslachtId = 0;
      }
    },
    setPatientGegevensPhoneNumber(state) {
      state.patientGegevens.CustomerPhoneNumbers.push({
        PhoneNumber: '',
        PhoneNumberCountryCode: 'NL',
        PhoneNumberDialCode: '31',
        IsMobileNumber: false,
        PhoneNumberId: 0,
        CustomerId: state.patientGegevens.Id
      });
    },
    setNewPatientIsSaved(state, payload) {
      state.newPatientIsSaved = payload;
    },
    setEndPoints(state, payload) {
      state.endPoints = payload;
    },
    setFormPurpose(state, payload) {
      state.formPurpose = payload;
    },
    setServices(state, payload) {
      state.services = payload;
    },
    setPatientFollowStatus(state, payload) {
      state.followStatus = payload;
    },
    setPatientInsurer(state, payload) {
      state.patientInsurer = payload;
    },
    setPatientAddress(state, payload) {
      state.patientAddress = payload;
      state.patientAddress.DifferentAddress = {
        AdresId: 0,
        Straatnaam: null,
        Huisnummer: null,
        Toevoeging: null,
        Postcode: null,
        Woonplaats: null,
        Land: null
      };
    },
    setSelectedAddress(state, addressValue) {
      state.patientAddress.SelectedAddress = addressValue;
    },
    setAvailablePumps(state, payload) {
      state.availablePumps = payload;
    },
    setPatientAvailableLicenses(state, payload) {
      state.patientAvailableLicenses = payload;
    },
    setRememberPatientFollow(state, payload) {
      // Setting remember patient follow to the application localStorage when ever user checked the remember me
      window.localStorage.setItem('patientFollow', payload);
    },
    setCustomerMarketingPermission(state, payload) {
      if (!payload) {
        state.customerMarketingPermission = payload;
        return;
      }
      state.customerMarketingPermission = state.defaultCustomerMarketingPermission;
    },
    setCustomerMarketingPermissionSubOption(state, payload) {
      if (!state.customerMarketingPermission) {
        if (state.patientGegevens.CustomerMarketingPermission) {
          state.customerMarketingPermission = state.patientGegevens.CustomerMarketingPermission;
        } else {
          state.customerMarketingPermission = state.defaultCustomerMarketingPermission;
        }
      }
      state.customerMarketingPermission[payload.key] = payload.active;
    },
    setCareProgramDetails(state, payload) {
      state.careProgramDetails = payload;
    },
    setFollowUnFollowPatient(state, payload) {
      state.followUnFollowPatient = payload;
    },
    setCareProgram(state, payload) {
      if (payload.CareProgramInfo !== null && store.state.navigation.userMarketingPermission) {
        let careProgramDetails = Object.assign({}, payload.CareProgramInfo);
        Object.keys(careProgramDetails).forEach((careProgramKey) => {
          careProgramDetails[careProgramKey].active = false;
        });
        Object.keys(careProgramDetails).forEach((careProgramKey) => {
          // E40-7258 currently removing VTPlus as per requirement may be in future will revert back
          if (!store.state.navigation.userMarketingPermission[careProgramKey] || careProgramKey === 'VTPlus') {
            delete careProgramDetails[careProgramKey];
          }
        });
        if (payload.CustomerMarketingPermission !== null) {
          Object.keys(careProgramDetails).forEach((careProgramKey) => {
            if (payload.CustomerMarketingPermission[careProgramKey]) {
              careProgramDetails[careProgramKey].active = payload.CustomerMarketingPermission[careProgramKey];
            }
          });
        }
        state.careProgramDetails = careProgramDetails;
      }
    },
    setSso(state, payload) {
      state.sso = payload;
    },
    setWLZStatus(state, payload) {
      state.wlzStatus = payload;
    },
    setCurrentPatientId(state, payload) {
      state.currentPatientId = payload;
    },
    setCustomerNumber(state, payload) {
      state.customerNumber = payload;
    },
    setServiceCenterDetails(state, payload) {
      state.serviceCenterDetails = payload;
    },
    setServiceCenterSearchDetails(state, payload) {
      state.serviceCenterSearchDetails = payload;
    },
    setServiceCenterRelatedQuestions(state, payload) {
      state.serviceCenterRelatedQuestions = payload;
    },
    setStopReason(state, payload) {
      state.stopReason = payload;
    }
  },
  actions: {
    fetchAllPatientFormData({ commit, state }) {
      return axios.post(state.endPoints.init, store.state.search.patientSearchArguments).then((response) => {
        commit('setAllPatientFormData', response.data);
        commit('setPatientGegevens', response.data.PatientGegevens);
        this.dispatch('patient/setPatientInsurer', response.data.PatientGegevens);
        commit('setPatientFollowStatus', response.data.PatientGegevens.IsFollow);
        commit('setCareProgram', response.data.PatientGegevens);
        commit('setCustomerNumber', response.data.CustomerNumber);
      });
    },
    setEndpoints({ commit, state }, action) {
      if (action === 'new') {
        pushActiveCustomerId(null);
        commit('setEndPoints', {
          init: 'PatientgegevensInvoeren/Init',
          save: 'PatientgegevensInvoeren/SaveNewPatient'
        });
        commit('setFormPurpose', 'new');
      } else if (action === 'edit') {
        commit('setEndPoints', {
          init: 'PatientgegevensWijzigen/Init',
          save: 'PatientgegevensWijzigen/SaveExistingPatient'
        });
        commit('setFormPurpose', 'edit');
      }
    },
    submitPatient({ commit, state }, { elements, options }) {
      return this.dispatch('search/formToJSON', elements).then((patientFormObject) => {
        let birthDayFormat = moment(patientFormObject.Geboortedatum, 'DD-MM-YYYY').format('YYYY-MM-DD') + 'T00:00:00';
        let deadDateFormat = '';
        if (patientFormObject.OverlijdensDatum) {
          deadDateFormat = moment(patientFormObject.OverlijdensDatum, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        let patientGegevens = {
          Id: parseInt(patientFormObject.Id, 10),
          GeslachtId: parseInt(patientFormObject.GeslachtId, 10),
          Voorletters: patientFormObject.Voorletters || '',
          Voornaam: patientFormObject.Voornaam || '',
          Tussenvoegsel:
            patientFormObject.Tussenvoegsel !== '0' && patientFormObject.Tussenvoegsel !== 'Selecteer een optie'
              ? patientFormObject.Tussenvoegsel
              : '',
          Achternaam: patientFormObject.Achternaam || '',
          GeboorteDatum: birthDayFormat,
          BSNnummer: patientFormObject.BSNnummer || '',
          HeeftBSNNummer: patientFormObject.HeeftBSNNummer === 'true',
          RedenGeenBSN: patientFormObject.RedenGeenBSN || '',
          VerzekeraarId: patientFormObject.Verzekeraar || '',
          Polisnummer: patientFormObject.Polisnummer || '',
          Uzovinummer: patientFormObject.Uzovinummer || '',
          WoonAdres: {
            AdresId: patientFormObject.WoonAdresId || '',
            Straatnaam: patientFormObject.WoonAdresStraatnaam?.trim() ?? '',
            Huisnummer: patientFormObject.WoonAdresHuisnummer || '',
            Toevoeging: patientFormObject.WoonAdresToevoeging || '',
            Postcode: patientFormObject.WoonAdresPostcode
              ? patientFormObject.WoonAdresPostcode.trim().replace(/(\d+)\s*([A-Za-z]+)/, (match, p1, p2) => {
                  return `${p1} ${p2.toUpperCase()}`;
                })
              : '',
            Woonplaats: patientFormObject.WoonAdresWoonplaats?.trim() ?? '',
            Land: patientFormObject.WoonAdresLand || ''
          },
          AfwijkendeAdres: {
            AdresId: patientFormObject.AfwijkendeAdresId || '',
            Straatnaam: patientFormObject.AfwijkendeAdresStraatnaam?.trim() ?? '',
            Huisnummer: patientFormObject.AfwijkendeAdresHuisnummer || '',
            Toevoeging: patientFormObject.AfwijkendeAdresToevoeging || '',
            Postcode: patientFormObject.AfwijkendeAdresPostcode
              ? patientFormObject.AfwijkendeAdresPostcode.trim().replace(/(\d+)\s*([A-Za-z]+)/, (match, p1, p2) => {
                  return `${p1} ${p2.toUpperCase()}`;
                })
              : '',
            Woonplaats: patientFormObject.AfwijkendeAdresWoonplaats?.trim() ?? '',
            Land: patientFormObject.AfwijkendeAdresLand || ''
          },
          CustomerPhoneNumbers: state.patientGegevens.CustomerPhoneNumbers,
          Emailadres: patientFormObject.Emailadres || '',
          PatientIsOverleden: patientFormObject.PatientIsOverleden === 'true',
          OverlijdensDatum: deadDateFormat || '',
          PatientGaatAkkoord: patientFormObject.PatientGaatAkkoord === 'true',
          HasAgreedToShareData: state.patientGegevens.HasAgreedToShareData
        };
        if (state.customerMarketingPermission) {
          patientGegevens.CustomerMarketingPermission = state.customerMarketingPermission;
        } else {
          patientGegevens.CustomerMarketingPermission = state.patientGegevens.CustomerMarketingPermission;
        }
        // set Current Patient Id
        commit('setCurrentPatientId', patientFormObject.Id);

        let dataToBeSubmitted = {
          PatientGegevens: patientGegevens,
          CovSanityCheckResolveMethod: (options && options.covSanityCheckResolveMethod) || 'None',
          CustomerNumber: state.customerNumber
        };
        return axios.post(state.endPoints.save, dataToBeSubmitted);
      });
    },
    setActivePatient({ commit, state }, data) {
      return axios.post('ZoekresultaatPatienten/Set', { PatientId: data }).then((response) => {
        pushActiveCustomerId(data);
        return response;
      });
    },
    getCurrentPatient({ commit, state }, data) {
      return axios.get('current/patient').then((response) => {
        commit('setPatientGegevens', response.data.Patient);
        this.dispatch('patient/setPatientInsurer', response.data.Patient);
        commit('setPatientFollowStatus', response.data.Patient.IsFollow);
        commit('setCareProgram', response.data.Patient);
        commit('setFollowUnFollowPatient', response.data.Patient.FollowUnFollowPatient);
      });
    },
    setPatientInsurer({ commit, state }, data) {
      // reset the patient insurer (when creating a new patient)
      commit('setPatientInsurer', '');
      // check for insurance name depending on the provided patient data
      if (parseInt(data.VerzekeraarId) > 0) {
        let activeVerzekering = state.patientFormData.StamData.Verzekeraars.find(
          (x) => parseInt(x.PayerId) === parseInt(data.VerzekeraarId)
        );
        if (activeVerzekering) {
          commit('setPatientInsurer', activeVerzekering.Name.toLowerCase());
        }
      }
      if (data.Verzekering) {
        if (data.Verzekering.length > 0) {
          commit('setPatientInsurer', data.Verzekering[0].Verzekeraar.Naam.toLowerCase());
        }
      }
    },
    /**
     * @function fetchServices
     * @description Requires access to CCP/VT+ service to make this call
     */
    fetchServices({ commit, state }) {
      // Empty current state rows
      state.services.data.rows = [];
      return axios.get('ServiceCalls/GetCalls', { params: { pagesize: 5, pagenumber: 1 } }).then(
        (response) => {
          Array.from(response.data.ServiceCallModel).forEach((row) => {
            // Row object that's going to be filled
            let newRow = {
              id: row.id,
              actionRequired: false,
              columns: [
                { type: '' },
                {
                  type: 'servicecall',
                  data: {
                    title: row.Title,
                    modified: row.LastModifiedDate
                  }
                },
                { type: 'status', data: '' },
                { type: 'date', data: row.ActionDate }
              ]
            };
            // Depending on the status different icons will be used
            if (row.Status === 'New') {
              newRow.columns[0].type = 'icon-timelapse';
              newRow.columns[2].data = 'Open';
            } else if (row.Status === 'ToCS') {
              newRow.columns[0].type = 'icon-timelapse';
              newRow.columns[2].data = 'Actie Mediq CS';
            } else if (row.Status === 'ToDietitian') {
              newRow.columns[0].type = 'icon-alert';
              newRow.columns[2].data = 'Actie Dietist';
              newRow.actionRequired = true;
            } else if (row.Status === 'Completed') {
              newRow.columns[0].type = 'icon-check';
              newRow.columns[2].data = 'Afgerond';
            }
            state.services.data.rows.push(newRow);
          });
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    setPatientFollowStatus({ commit, state }, data) {
      // data = { follow: true|false }
      // This patient can be followed and unfollowed
      return axios.post('MyPatients/Set', data).then((response) => {
        // Upon server succes the current status is returned and follow is adjusted
        commit('setPatientFollowStatus', data.Follow === true);
      });
    },
    stopLicenses({ commit, state }, stopLicenseDetails) {
      // Make an API call to terminate all licenses under an accountability ID
      return axios.post('License/CloseLicensesForAccountability', stopLicenseDetails);
    },
    closeLicenseWithoutAccountability(context, id) {
      return axios.post('License/CloseLicense', { LicenseId: id });
    },
    submitFeedback({ commit }, value) {
      return axios.post('Colleagues/Feedback?feedback=' + value.Comments).then(
        (response) => {
          return response.data.Success;
        },
        (error) => {
          return error.response;
        }
      );
    },
    fetchAddressLookup({ commit }, { postcode, huisnummer, land }) {
      return axios.get('Address/Lookup', {
        params: {
          Zipcode: postcode,
          Number: huisnummer,
          CountryCode: land
        }
      });
    },
    fetchPumps({ commit, state }, requiredIds) {
      return axios.get('Equipment/GetPumpList?patientId=' + requiredIds.PatientId).then(
        (response) => {
          // Empty the current state of the rows in the store
          state.pumps.title = response.data.title;
          state.pumps.buttonLabel = response.data.buttonLabel;
          state.pumps.data.rows = [];
          if (!response.data.pumpList) response.data.pumpList = [];
          // Loop over the response rows
          response.data.pumpList.forEach((row, i) => {
            state.pumps.data.rows.push(row);
          });
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    fetchPatientAddress({ commit, dispatch, state }, patientId) {
      axios.get('Equipment/GetPatientAddress?patientId=' + patientId).then((response) => {
        commit('setPatientAddress', response.data.PatientAddress);
      });
    },
    fetchAvailablePumps({ commit }, requiredDetails) {
      return axios.get('Equipment/GetPumpList?status=' + requiredDetails.Status).then(
        (response) => {
          commit('setAvailablePumps', response.data.pumpList);
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    fetchStopReason({ commit }) {
      return axios.get('Equipment/GetStopReason').then(
        (response) => {
          if (response != null) {
            commit('setStopReason', response.data);
            return response;
          }
        },
        (error) => {
          return error.response;
        }
      );
    },
    saveAllocatePump({ commit, dispatch }, requiredDetails) {
      return axios
        .post(
          'Equipment/SaveSelectedPump?patientId=' +
            requiredDetails.PatientId +
            '&pumpId=' +
            requiredDetails.PumpId +
            '&LicenseId=' +
            requiredDetails.LicenseId
        )
        .then(
          (response) => {
            dispatch('fetchPumps', requiredDetails);
            dispatch('fetchAvailableLicenses');
            return response;
          },
          (error) => {
            return error.response;
          }
        );
    },
    allocatePumpFromMediqDepot({ commit, dispatch }, requiredDetails) {
      return axios.post('Equipment/AllocatePumpFromMediqDepot', requiredDetails).then(
        (response) => {
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    getAdditionalPumps({ commit }, selectedOption) {
      return axios.get('Equipment/GetAdditionalPumpsForAssignment?SelectedOption=' + selectedOption);
    },
    stopAllocatedPump({ dispatch }, pumpDetails) {
      return axios.post('../cmd/DeallocateEquipmentForCustomer', pumpDetails).then(
        (response) => {
          return response.data;
        },
        (error) => {
          return error.response;
        }
      );
    },
    fetchAvailableLicenses({ commit, dispatch }) {
      return axios.get('Equipment/GetAvailableLicense').then((response) => {
        if (response.data && response.data.Success) {
          commit('setPatientAvailableLicenses', response.data.AvailableLicense);
        }
      });
    },
    fetchSsoDetails({ commit }) {
      return axios.get('Servicecalls/GetSSODetails').then((response) => {
        if (response.data && response.data.Success) {
          commit('setSso', response.data.SSO);
        }
      });
    },
    fetchWLZStatus({ commit }, patientId) {
      return axios.get('Current/UpdateWLZStatus?customerId=' + patientId).then((response) => {
        if (response.data) {
          commit('setWLZStatus', response.data.Success);
        }
      });
    },
    fetchPatientPumpConfiguration({ state }) {
      return axios.get('Equipment/GetPatientPumpConfiguration');
    },
    fetchServiceCenterDetails({ commit }) {
      let body = JSON.stringify({
        query: `{
            publication {
              collection_group (addCollectionInfo: true)
              announcement {
                id
                name
                content
              }
              mostpopular(first: 5) {
                id
                name
                slug
                content
                collections {
                  id
                }
              }
              collections {
                id
                name
                description
                slug
                mostpopularItems(first: 5) {
                  ... on Article {
                    id
                    name
                    content
                    collections {
                      id
                    }
                  }
                  ... on DT {
                    id
                    name
                    description
                  }
                }
              }
            }
          }`,
        variables: fetchGraphqlVariables()
      });

      return axios
        .post('https://api.polly.help/graphql', body, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': false,
            'Content-Type': 'application/json'
          },
          withCredentials: false
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            commit('setServiceCenterDetails', response.data.data);
          }
        });
    },
    fetchServiceCenterSearchFaqs({ commit }, searchText) {
      let body = JSON.stringify({
        query: `{
            search(query: "${searchText}", removeWordsIfNoResults: "allOptional") {
              results {
                id
                name
                slug
                content
                collections {
                  id
                }
              }
              searchinfo {
                nbHits
                query
                ms
              }
            }
          }`,
        variables: fetchGraphqlVariables()
      });

      return axios
        .post('https://api.polly.help/graphql', body, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': false,
            'Content-Type': 'application/json'
          },
          withCredentials: false
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            commit('setServiceCenterSearchDetails', response.data.data.search.results);
          }
        });
    },
    fetchServiceCenterRelatedFaqs({ commit }, details) {
      let body = JSON.stringify({
        query: `{
            article(id:"${details.articleId}") {
              name
              slug
              custom_fields
              content
              date_modified
              attachments {
                type
                label
                action
                order
              }
              collections {
                id
              }
              relatedArticles(collectionId:"${details.collectionId}") {
                id name content collections { id }
              }
            }
          }`,
        variables: fetchGraphqlVariables()
      });

      return axios
        .post('https://api.polly.help/graphql', body, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': false,
            'Content-Type': 'application/json'
          },
          withCredentials: false
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            commit('setServiceCenterRelatedQuestions', response.data.data.article.relatedArticles);
          }
        });
    }
  },
  getters: {
    getAllPatientFormData: (state) => {
      return state.patientFormData;
    },
    getPatientGegevens: (state) => {
      return state.patientGegevens;
    },
    getNewPatientIsSaved: (state) => {
      return state.newPatientIsSaved;
    },
    getPatientInsurer: (state) => {
      return state.patientInsurer;
    },
    getEndPoints: (state) => {
      return state.endPoints;
    },
    getFormPurpose: (state) => {
      return state.formPurpose;
    },
    getServices: (state) => {
      return state.services;
    },
    getPatientFollowStatus: (state) => {
      return state.followStatus;
    },
    getPumps: (state) => {
      return state.pumps;
    },
    getFollowUnFollowPatient: (state) => {
      return state.followUnFollowPatient;
    },
    getSpecificPump: (state) => (pumpData) => {
      return state.pumps.data.rows.find((pump) => pump.EquipmentID === pumpData.id);
    },
    getPatientHomeAddress: (state) => {
      return state.patientAddress.HomeAddress;
    },
    getPatientDeliveryAddress: (state) => {
      return state.patientAddress.DeliveryAddress;
    },
    getPatientDifferentAddress: (state) => {
      return state.patientAddress.DifferentAddress;
    },
    getSelectedAddress: (state) => {
      return state.patientAddress.SelectedAddress;
    },
    getPumpTypes: (state) => {
      return state.pumpTypes;
    },
    getAvailablePumps: (state) => {
      let availablePumps = [];
      if (state.availablePumps.length === 0) {
        availablePumps.push({ text: 'Geen pompen beschikbaar', value: 0 });
      }
      state.availablePumps.forEach((availablePump) => {
        let pumpDetail = {
          text: availablePump.SerialNo,
          value: availablePump.EquipmentID
        };
        availablePumps.push(pumpDetail);
      });
      return availablePumps;
    },
    getMeasurements: (state) => {
      return state.measurements;
    },
    getMeasurementTypes: (state) => {
      return state.measurementTypes;
    },
    getPatientFollow: (state) => {
      // Checking whether application localStorage already have patientFollow
      // If so save them to the state
      if (window.localStorage.getItem('patientFollow')) {
        state.patientFollow = window.localStorage.getItem('patientFollow');
      }
      return state.patientFollow;
    },
    getPatientAvailableLicenses: (state) => {
      let availableLicenses = [];
      if (state.patientAvailableLicenses.length === 0) {
        availableLicenses.push({ text: 'Geen machtiging beschikbaar', value: 0 });
      }
      state.patientAvailableLicenses.forEach((availableLicense) => {
        let licenseDetail = {
          text: availableLicense.Description + ' ( ' + availableLicense.LicenseId + ' )',
          value: availableLicense.LicenseId
        };
        availableLicenses.push(licenseDetail);
      });
      return availableLicenses;
    },
    getCareProgramDetails: (state) => {
      return state.careProgramDetails;
    },
    getSsoDetails: (state) => {
      let id = 0;
      state.sso.forEach((ssoProgram) => {
        ssoProgram.id = id;
        ssoProgram.title = ssoProgram.treatmentArea;
        id = id + 1;
      });
      return state.sso;
    },
    getWLZStatus: (state) => {
      return state.wlzStatus;
    },
    getCurrentPatientId: (state) => {
      return state.currentPatientId;
    },
    getCustomerNumber: (state) => {
      return state.customerNumber;
    },
    getServiceCenterDetails: (state) => {
      return state.serviceCenterDetails;
    },
    getServiceCenterSearchDetails: (state) => {
      return state.serviceCenterSearchDetails;
    },
    getServiceCenterRelatedQuestions: (state) => {
      return state.serviceCenterRelatedQuestions;
    },
    getStopReason: (state) => {
      return state.stopReason;
    }
  }
};
