import axios from 'axios';

/**
 * @typedef Filter
 * @type {object}
 * @property {number} id
 * @property {string} label
 * @property {boolean} active
 * @property {number} list
 */

/**
 * @typedef Token
 * @type {object}
 * @property {number} id
 * @property {string} label
 */

/**
 * @typedef Product
 * @type {object}
 * @property {number} id
 * @property {string} image
 * @property {string} title
 * @property {array} properties
 * @property {array} tokenIds
 * @property {number} categoryId
 */

export default {
  namespaced: true,
  state: {
    manufacturerList: [],
    /**
     * @param {Source{}}}
     */
    source: {},

    /**
     * @type {?number}
     */
    categories: [],

    newCategories: [],

    moreProducts: [],

    favorites: [],

    Id: 1,

    size: 20,

    Overlay: false,
    favoriteCategories: [],
    favouriteProducts: [],
    bundles: [],
    bundleId: null,
    orderSuccessStatus: false,
    orderDetails: {},
    catalogAssortiment: false,
    catalogFormDetails: {
      filters: [],
      favorites: [],
      category: null,
      text: null,
      manufacturers: []
    },
    previousSelectedCategories: []
  },
  mutations: {
    setRememberAssortiment(state, payload) {
      // Setting remember assortiment to the application localStorage when ever user checked the remember me
      window.localStorage.setItem('catalogAssortiment', payload);
      state.catalogAssortiment = payload;
    },
    setOverlayActive(state, active) {
      state.Overlay = active;
    },
    /**
     * @method setCategories
     * @param {object} state
     * @param {number} categories categories
     */
    setCategories(state, categories) {
      state.categories = categories;
    },
    /**
     * @method setNewCategories
     * @param {object} state
     * @param {number} categories categories
     */
    setNewCategories(state, categories) {
      state.newCategories = categories;
    },
    setFavouritesCategories(state, payload) {
      state.favoriteCategories = payload;
    },
    setFavouriteProducts (state, payload) {
      state.favouriteProducts = payload
    },
    setManufacturerList (state, payload) {
      state.manufacturerList = payload
    },
    setBundles(state, payload) {
      state.bundles = payload;
    },
    /**
     * @method setOrderSuccessStatus
     */
    setOrderSuccessStatus(state, payload) {
      state.orderSuccessStatus = payload;
    },
    /**
     * @method setOrderDetails
     */
    setOrderDetails(state, payload) {
      state.orderDetails = payload;
    },
    resetCatalogFormDetails(state) {
      state.catalogFormDetails = {
        filters: [],
        favorites: [],
        category: null,
        text: null,
        manufacturers: []
      };
      state.previousSelectedCategories = [];
    },
    setPreviousSelectedCategories(state, payload) {
      state.previousSelectedCategories = payload;
    }
  },
  actions: {
    fetchFilters({ commit }) {
      axios.get('Products/GetFilteredCategories').then((response) => {
        commit('setCategories', response.data.menu.items);
      });
    },
    UpdateFavoriteCategories({ state, dispatch }, data) {
      return axios.post('FavouriteProduct/UpdateFavouriteListAndProductQuantity', data).then(
        (response) => {
          dispatch('fetchFilters');
          dispatch('fetchManufacturers');
          dispatch('fetchCategories');
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    DeleteFavoriteList({ state, dispatch }, data) {
      return axios.post('FavouriteProduct/DeleteFavouriteList', data).then(
        (response) => {
          dispatch('fetchFilters');
          dispatch('fetchManufacturers');
          dispatch('fetchCategories');
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    DeleteFavoriteListProduct({ state, dispatch }, data) {
      return axios.post('FavouriteProduct/DeleteProductFromFavouriteList', data).then(
        (response) => {
          dispatch('fetchFilters');
          dispatch('fetchManufacturers');
          dispatch('fetchCategories');
          return response;
        },
        (error) => {
          return error.response;
        }
      );
    },
    fetchBundles({ commit, state }) {
      axios.get('Products/GetIllnessBundle').then((response) => {
        if (response && response.data) {
          commit('setBundles', response.data.illnessBundle);
        }
      });
    },
    fetchManufacturers({ commit }) {
      axios.get('Catalogue/GetManufacturers').then((response) => {
        commit('setManufacturerList', response.data);
      });
    },
    fetchFavourites({ commit }, filterBy = null) {
      return axios.post('Catalogue/GetFavourites', { productId: null, filterBy: filterBy }).then((response) => {
        commit('setFavouritesCategories', response.data);
        return response;
      });
    },
    fetchFavouriteProducts({ commit }, bundleId = 0) {
      return axios.post('Catalogue/GetFavouriteProducts?bundleId='+ bundleId).then((response) => {
        return response.data
      })
    },
    favouritesCategories({ commit }, { bundleId = 0, productId = null, filterBy = null }) {
      return axios
        .post('Catalogue/GetFavourites', {
          bundleId: bundleId,
          productId: productId,
          filterBy: filterBy
        })
        .then((response) => {
          commit('setFavouritesCategories', response.data);
          return response;
        });
    },
    fetchCategories({ commit }) {
      axios.get('Catalogue/GetCategories').then((response) => {
        commit('setNewCategories', response.data);
      });
    }
  },
  getters: {
    getCatalogAssortiment: (state) => {
      // Checking whether application localStorage already have catalogAssortiment
      // If so save them to the state
      if (window.localStorage.getItem('catalogAssortiment')) {
        state.catalogAssortiment = JSON.parse(window.localStorage.getItem('catalogAssortiment'));
      }
      return state.catalogAssortiment;
    },
    getFavorites: (state) => {
      return state.favorites;
    },
    getManufacturers: (state) => {
      return state.manufacturerList;
    },
    getCategories: (state) => {
      return state.categories;
    },
    getNewCategories: (state) => {
      return state.newCategories;
    },
    getOverlay: (state) => {
      return state.Overlay;
    },
    getFavoriteCategories: (state) => {
      return state.favoriteCategories;
    },
    getBundles: (state) => {
      return state.bundles;
    },
    getBundleId: (state) => {
      return state.bundleId;
    },
    getOrderSuccessStatus: (state) => {
      return state.orderSuccessStatus;
    },
    getOrderDetails: (state) => {
      return state.orderDetails;
    },
    getCatalogFormDetails: (state) => {
      return state.catalogFormDetails;
    },
    getPreviousSelectedCategories: state => {
      return state.previousSelectedCategories
    },
    getFavouriteProducts: state => {
      return state.favouriteProducts
    }
  }
}
