<template>
  <transition name="alert">
    <section v-if="overlayActive" role="alert" class="alert" :class="setClassName(props.type)">
      <confirmation-icon v-if="props.type === 0" title="Bevestiging:" />
      <informal-icon v-if="props.type === 1" title="Informatie:" />
      <warning-icon v-if="props.type === 2" title="Waarschuwing:" />
      <error-icon v-if="props.type === 3" title="Foutmelding:" />
      <strong>{{ props.message }}</strong>
    </section>
  </transition>
</template>

<script>
import confirmationIcon from 'icons/CheckCircle';
import informalIcon from 'icons/Information';
import warningIcon from 'icons/Alert';
import errorIcon from 'icons/AlertCircle';

export default {
  name: 'VAlert',
  components: {
    confirmationIcon,
    informalIcon,
    warningIcon,
    errorIcon
  },
  data() {
    return {
      overlayActive: false,
      props: {},
      delay: 3500,
      timer: null
    };
  },
  created() {
    this.$emitter.on('alert', (props) => {
      this.props = props
      if (!props.type) props.type = 0
      this.show()
    })
  },
  methods: {
    show() {
      this.overlayActive = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.close(), this.props.delay || this.delay);
    },
    close() {
      this.overlayActive = false;
    },
    setClassName(type) {
      switch (type) {
        case 0:
          return 'confirmation';
        case 1:
          return 'informal';
        case 2:
          return 'warning';
        case 3:
          return 'error';
        default:
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$alertTransitionDuration: 0.65s;

.alert {
  position: fixed;
  z-index: 9999;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: 0 50%;
  max-width: 400px;
  padding: 15px 30px;
  display: flex;
  box-sizing: border-box;
  color: $white;
  font-size: type-scale(2);
  line-height: 1.25;
  pointer-events: none;
  animation: $alertTransitionDuration popup;

  // Alert types

  &.confirmation {
    background: $dark-green;
  }

  &.informal {
    background: $dark-blue;
  }

  &.warning {
    background: $yellow;
  }

  &.error {
    background: $red;
  }

  // Animations

  &.alert-leave-active {
    transition: opacity $alertTransitionDuration, visibility $alertTransitionDuration;
  }

  &.alert-leave-to {
    opacity: 0;
    visibility: hidden;
  }

  @keyframes popup {
    0% {
      transform: scale(1) translateX(-50%);
    }
    15% {
      transform: scale(1.1) translateX(-50%);
    }
    80% {
      transform: scale(1) translateX(-50%);
    }
  }

  // Content

  strong {
    font-weight: normal;
    display: block;
  }

  :deep(.material-design-icon) {
    margin: -3px 12px -2px -15px;
    align-self: flex-start;

    svg {
      display: block;
      width: 28px;
      height: 28px;
      fill: currentColor;
    }
  }
}
</style>
