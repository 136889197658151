import { from } from 'rxjs';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { getInstitutionColleagues } from './getInstitutionColleagues';
import { activePpwRecId$ } from "../activePpwRecId$"

export const institutionColleagues$ = activePpwRecId$.pipe(
  switchMap(v => from(getInstitutionColleagues())),
  publishReplay(1),
  refCount()
);
