<template>
  <section class="container report">
    <div class="row">
      <v-heading :level="1"> Rapportage </v-heading>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12 col-md-10 report__search">
        <label class="report__labels">Zoeken</label>
        <v-input-group-simple-2
          id="reportSearch"
          v-model:value="searchDetails.searchText"
          :overwrite-value="searchDetails.searchText"
          :rules="{
            required: false
          }"
          placeholder="Zoeken op naam, geboortedatum of BSN"
          :show-search-icon="!(awaitingSearch || searchLoadingStatus)"
          @input="handleSyncLoader"
        />
        <sync-loader v-if="awaitingSearch || searchLoadingStatus" :color="color" class="report__search__search-icon" />
      </div>
    </div>
    <div class="row grid-margin">
      <div class="col-xs-12 col-md-2">
        <label class="report__labels">Therapie</label>
        <v-select-group
          v-model="searchDetails.therapy"
          :overwrite-value="searchDetails.therapy"
          :initselected="searchDetails.therapy"
          selectid="thearapyId"
          @change="changeSearch($event, 'therapy')"
        >
          <option value="Toon Alles">Toon Alles</option>
          <option
            v-for="(item, index) in therapies"
            :key="'therapy' + index"
            :data-index="'therapy' + index"
            :value="item.name"
          >
            {{ item.name }}
          </option>
        </v-select-group>
      </div>
      <div class="col-xs-12 col-md-2">
        <label class="report__labels">Machtigingsstatus</label>
        <v-select-group
          v-model="searchDetails.maintenanceStatus"
          :overwrite-value="searchDetails.maintenanceStatus"
          :initselected="searchDetails.maintenanceStatus"
          selectid="maintenanceStatusId"
          @change="changeSearch($event, 'maintenanceStatus')"
        >
          <option value="Toon Alles">Toon Alles</option>
          <option value="NotExpired">Actief</option>
          <option value="Expired">Inactief</option>
          <option value="AlmostExpired">Bijna verlopen</option>
        </v-select-group>
      </div>
      <div class="col-xs-12 col-md-2">
        <label class="report__labels">Verzekeraar</label>
        <v-select-group
          v-model="searchDetails.payer"
          :overwrite-value="searchDetails.payer"
          :initselected="searchDetails.payer"
          selectid="payerId"
          @change="changeSearch($event, 'payer')"
        >
          <option value="Toon Alles">Toon Alles</option>
          <option v-for="(item, index) in payers" :key="'payer' + index" :data-index="'payer' + index" :value="item.id">
            {{ item.name }}
          </option>
        </v-select-group>
      </div>
      <div class="col-xs-12 col-md-2">
        <v-input-date-picker
          id="startDate"
          ref="startDate"
          type="date"
          label="Startdatum"
          name="searchDetailsStartDate"
          autofocus
          empty-date
          @selected="handleDate"
          :value="searchDetails.startDate"
          :overwrite-value="searchDetails.startDate"
        />
      </div>
      <div class="col-xs-12 col-md-2">
        <v-input-date-picker
          id="endDate"
          ref="endDate"
          type="date"
          label="Einddatum"
          name="searchDetailsEndDate"
          autofocus
          empty-date
          @selected="handleDate"
          :value="searchDetails.endDate"
          :overwrite-value="searchDetails.endDate"
        />
      </div>
      <v-button class="btn btn--hollow btn--md report__resetFilters" @click.prevent="resetFilters">
        <v-reset-icon />
        Reset filters
      </v-button>
    </div>
    <div class="row">
      <v-table>
        <thead>
          <v-header-row
            :data="reportHeaders"
            :show-sort="true"
            :page="'Rapportage'"
            @sort-by-field="sortLicensesByField"
          />
        </thead>
        <tbody class="report__row">
          <tr
            v-for="(row, index) in pagedSortedFilteredLicenses"
            ref="row"
            :key="index"
            class="report__row__data"
            :index="index"
          >
            <td class="report__row__cell">
              <a href="#" class="report__row__customer" @click="selectPatient(row.customerId)">
                {{ row.customerName }}
              </a>
            </td>
            <td class="report__row__cell">
              {{ $formatDate(row.dob) }}
            </td>
            <td class="report__row__cell">
              {{ row.bsn }}
            </td>
            <td class="report__row__cell">
              {{ row.therapyName }}
            </td>
            <td class="report__row__cell">
              <div :class="getStatusColor(row.expirationStatus)">
                <v-circle-icon />
                {{ getComputedStatus(row.expirationStatus) }}
              </div>
            </td>
            <td class="report__row__cell">
              {{ row.payer }}
            </td>
            <td class="report__row__cell">
              {{ $formatDate(row.startDate) }}
            </td>
            <td v-if="row.endDate !== null" class="report__row__cell">
              {{ $formatDate(row.endDate) }}
            </td>
            <td v-else class="report__row__cell" />
          </tr>
        </tbody>
      </v-table>
    </div>
    <div class="row grid-align-middle">
      <paginate
        v-model="pageNumber"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="setPagination"
        :prev-text="'Volgende'"
        :next-text="'Vorige'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      />
    </div>
  </section>
</template>

<script>
import Paginate from 'vuejs-paginate-next';
import VTable from '@/components/shared/table/Table';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VResetIcon from 'icons/Refresh';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import VCircleIcon from 'icons/Circle';
import { activeLicensesByCurrentDepartment$ } from '@/services';
import { getAllPayers } from '@/services/crm/getAllPayers';
import { getTherapiesByActivePortal } from '@/services/accountabilities/getTherapiesByActivePortal';
import moment from 'moment';
import { mapActions, useStore } from 'vuex';
import { ref, onBeforeMount, reactive, watch } from 'vue';
import router from '@/router';
import { from } from 'rxjs';
import { formatDate } from '@/filters/dateUtils.js';
export default {
  name: 'Report',
  components: {
    VTable,
    VHeaderRow,
    VResetIcon,
    SyncLoader,
    Paginate,
    VInputDatePicker,
    VCircleIcon
  },
  setup() {
    const store = useStore();
    const activeLicenses = ref([]);
    const therapies = ref(null);
    const payers = ref(null);
    const searchDetails = reactive({
      searchText: null,
      therapy: 'Toon Alles',
      maintenanceStatus: 'Toon Alles',
      payer: 'Toon Alles',
      startDate: null,
      endDate: null
    });
    const pageNumber = ref(1);
    const defaultPageNumber = ref(1);
    const rowsPerPage = ref(25);
    const sortFieldDetails = ref(null);
    const pagedSortedFilteredLicenses = ref([]);
    const totalPages = ref(0);
    const setEndpoints = (value) => store.dispatch('patient/setEndpoints', value);
    const fetchSubscriptions = () => {
      activeLicensesByCurrentDepartment$.subscribe((value) => (activeLicenses.value = value));
      from(getTherapiesByActivePortal()).subscribe((value) => (therapies.value = value));
      from(getAllPayers(true)).subscribe((value) => (payers.value = value));
    };
    const sortLicensesByField = (details) => {
      sortFieldDetails.value = details;
      setPagination(defaultPageNumber.value);
    };
    const setPagination = (newPageNumber) => {
      pageNumber.value = newPageNumber;
      computeLicenses();
    };
    watch(
      () => activeLicenses.value,
      (newVal) => {
        if (newVal.length > 0) {
          setPagination(defaultPageNumber.value);
        }
      }
    );
    const changeSearch = (event, key) => {
      searchDetails[key] = event.target.value;
      setPagination(defaultPageNumber.value);
    };
    const computeLicenses = () => {
      let filteredLicenses = activeLicenses.value;
      if (searchDetails.searchText && searchDetails.searchText.length > 2) {
        const lowerCaseSearchText = searchDetails.searchText.toLowerCase();
        filteredLicenses = filteredLicenses.filter(
          (l) =>
            l.customerName.toLowerCase().includes(lowerCaseSearchText) ||
            (l.bsn !== null && l.bsn.includes(lowerCaseSearchText)) ||
            formatDate(l.dob).includes(lowerCaseSearchText)
        );
      }
      if (searchDetails.maintenanceStatus !== 'Toon Alles') {
        filteredLicenses = filteredLicenses.filter((l) => l.expirationStatus === searchDetails.maintenanceStatus);
      }
      if (searchDetails.therapy !== 'Toon Alles') {
        filteredLicenses = filteredLicenses.filter((l) => l.therapyName === searchDetails.therapy);
      }
      if (searchDetails.payer !== 'Toon Alles') {
        filteredLicenses = filteredLicenses.filter((l) => l.payerId === searchDetails.payer);
      }
      if (searchDetails.startDate && !searchDetails.endDate) {
        filteredLicenses = filteredLicenses.filter((l) => {
          const licenseStartDate = moment(formatDate(l.startDate), 'DD-MM-YYYY');
          console.log(licenseStartDate);
          const startDate = moment(searchDetails.startDate, 'DD-MM-YYYY');
          return moment(licenseStartDate).isSameOrAfter(startDate);
        });
      }
      if (searchDetails.endDate && !searchDetails.startDate) {
        filteredLicenses = filteredLicenses.filter((l) => {
          const licenseEndDate = moment(formatDate(l.endDate), 'DD-MM-YYYY');
          const endDate = moment(searchDetails.endDate, 'DD-MM-YYYY');
          return moment(licenseEndDate).isSameOrAfter(endDate);
        });
      }
      if (searchDetails.startDate && searchDetails.endDate) {
        filteredLicenses = filteredLicenses.filter((l) => {
          const licenseStartDate = moment(formatDate(l.startDate), 'DD-MM-YYYY');
          const licenseEndDate = moment(formatDate(l.endDate), 'DD-MM-YYYY');
          const startDate = moment(searchDetails.startDate, 'DD-MM-YYYY');
          const endDate = moment(searchDetails.endDate, 'DD-MM-YYYY');
          return moment(licenseStartDate).isSame(startDate) && moment(licenseEndDate).isSame(endDate);
        });
      }
      totalPages.value = Math.ceil(filteredLicenses.length / rowsPerPage.value);
      pagedSortedFilteredLicenses.value = filteredLicenses;
      const mappedField = sortFieldDetails.value
        ? sortFieldDetails.value.option === 'A-Z'
          ? 'customerName'
          : 'dob'
        : 'endDate';
      const isAscending = sortFieldDetails.value ? sortFieldDetails.value.isAscending : true;
      pagedSortedFilteredLicenses.value.sort(function (a, b) {
        const leftValue = a[mappedField];
        const rightValue = b[mappedField];
        if (!leftValue) {
          return 1;
        }
        if (!rightValue) {
          return -1;
        }
        if (isAscending) {
          return leftValue.localeCompare(rightValue);
        } else {
          return rightValue.localeCompare(leftValue);
        }
      });
      const licenses = pagedSortedFilteredLicenses.value;
      // paging filter
      const pageSize = rowsPerPage.value;
      // assume pageNumber starts at 0
      const skip = (pageNumber.value - 1) * pageSize;
      const take = skip + pageSize;
      pagedSortedFilteredLicenses.value = licenses.slice(skip, take);
    };
    const resetFilters = () => {
      for (let key in searchDetails) {
        if (['therapy', 'maintenanceStatus', 'payer'].includes(key)) {
          searchDetails[key] = 'Toon Alles';
        } else {
          searchDetails[key] = null;
        }
      }
      setPagination(defaultPageNumber.value);
    };
    const handleDate = (key, selectedDate) => {
      if (Object.keys(searchDetails).includes(key)) {
        // If the key exists, update its value
        searchDetails[key] = selectedDate;
        setPagination(defaultPageNumber.value);
      } else {
        // If the key doesn't exist, log an error or handle it accordingly
        console.error(`Key '${key}' does not exist in searchDetails.`);
      }
    };
    onBeforeMount(() => {
      fetchSubscriptions();
    });
    return {
      setEndpoints,
      ...mapActions('patient', ['setActivePatient']),
      activeLicenses,
      therapies,
      payers,
      searchDetails,
      changeSearch,
      pagedSortedFilteredLicenses,
      setPagination,
      defaultPageNumber,
      pageNumber,
      rowsPerPage,
      sortFieldDetails,
      sortLicensesByField,
      totalPages,
      computeLicenses,
      resetFilters,
      handleDate
    };
  },
  data() {
    return {
      reportHeaders: [
        'A-Z',
        'Geboortedatum',
        'BSN',
        'Therapie',
        'Machtigingstatus',
        'Verzekeraar',
        'Startdatum',
        'Einddatum'
      ],
      color: '#73c8d2',
      searchLoadingStatus: false,
      awaitingSearch: false,
      typeSleep: 0
    };
  },
  created() {},
  methods: {
    /**
     * @function handleSyncLoader
     * @description Function to handle sync loader
     */
    handleSyncLoader(event) {
      this.searchDetails.searchText = event.target.value;
      if (event.target.value.length > 2) {
        this.searchLoadingStatus = true;
        this.setPagination(this.defaultPageNumber);
      } else {
        this.searchLoadingStatus = false;
      }
      this.finishTyping(event.target.value);
    },
    /**
     * @function finishTyping
     * @description Function to check ther user typing
     */
    finishTyping(term) {
      // Based on research of the average CPM (200) typing speed
      // Haults request to wait till user is finished typing by calculating keystroke time
      this.typeSleep += 1;
      setTimeout(() => {
        this.typeSleep -= 1;
        if (this.typeSleep === 0) {
          this.awaitingSearch = false;
          if (term.length > 2) {
            if (this.searchLoadingStatus || term.length === 0) {
              this.searchLoadingStatus = false;
              this.finishTyping(term);
            }
          }
        }
      }, 300);
      this.awaitingSearch = true;
    },
    /**
     * @function getComputedStatus
     * @description Get the computed status based on the mainternanceStatus
     */
    getComputedStatus(status) {
      return status === 'NotExpired' ? 'Actief' : status === 'Expired' ? 'Inactief' : 'Bijna verlopen';
    },
    /**
     * @function getStatusColor
     * @description Get the status icon color based on the mainternanceStatus
     */
    getStatusColor(status) {
      return status === 'NotExpired'
        ? 'report__row__cell__green'
        : status === 'Expired'
        ? 'report__row__cell__red'
        : 'report__row__cell__orange';
    },
    selectPatient(PatientId) {
      this.setActivePatient(PatientId).then((result) => {
        if (result.data.IsActiveCustomer) {
          router.push('/patientdashboard');
        } else {
          this.setEndpoints('edit');
          router.push('/patientgegevens');
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.report {
  .pagination {
    margin: 40px auto;
  }
  :deep(.form-group) {
    padding: 0px;
    .form-group__details {
      &:before {
        background: none;
      }
      &:after {
        background: none;
      }
    }
    &--hasvalue label {
      display: none;
    }
    &--disabled {
      background: none !important;
      border-radius: 0 !important;
      label {
        padding: 0px !important;
      }
    }
  }
  :deep(.datepicker .vuejs3-datepicker__value) {
    border: none;
  }
  :deep(.datepicker label) {
    color: $teal-darkest;
  }
  &__labels {
    font-size: 18px;
    font-weight: bold;
    color: $teal-darkest;
    margin-bottom: 10px;
    overflow: hidden;
  }
  &__search {
    position: relative;
    &__search-icon {
      position: absolute;
      fill: $gray-dark;
      right: 5px;
      top: 45px;
    }
  }
  &__resetFilters {
    margin: auto auto 32px 16px;
  }
  &__row {
    background-color: $table-row-background;
    border: $table-row-border;
    transition: $search-transition;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &__data {
      background-color: $table-row-background;
      border: $table-row-border;
      transition: $search-transition;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &__cell {
      color: $table-row-cell-color;
      font-size: $table-row-cell-font-size;
      line-height: $table-row-cell-line-height;
      justify-content: $table-row-cell-justify-content;
      padding: $table-row-license-cell-padding;
      .material-design-icon {
        display: inline;
        margin: 0px 5px 0px 0px;
        vertical-align: middle;
        fill: $teal-darkest;
        :deep(svg) {
          width: 15px;
          height: 15px;
        }
      }
      &__green {
        .material-design-icon {
          fill: $cyan-darker;
        }
      }
      &__red {
        .material-design-icon {
          fill: $red;
        }
      }
      &__orange {
        .material-design-icon {
          fill: $orange;
        }
      }
      &__nocolor {
        .material-design-icon {
          fill: transparent;
        }
      }
    }
    &__customer {
      color: $teal-darkest;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
