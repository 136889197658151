import axios from 'axios';
import { refreshShoppingCart } from './shoppingCartRefreshRequested$';

export function addToShoppingCart(shoppingCart, productId, quantity, ppwRecId) {
  if (shoppingCart === null) {
    throw 'ShoppingCart is empty';
  }

  var command = {
      customerId: shoppingCart.customerId,
      productId: productId,
      quantity: quantity,
      ppwRecId: ppwRecId
  };

  return axios
    .post('ShoppingCart/AddToShoppingCart', command)
    .then((response) => {
      refreshShoppingCart();
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: response.data.ProductId,
              item_name: response.data.ProductName,
              item_brand: response.data.BrandOwner,   
              quantity: quantity
            }
          ]
        }
      });
      return response;
    });
}
