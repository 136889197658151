export * from './activePortalCode$'
export * from './activeDepartment$'
export * from './activePpwRecId$'
export * from './storedActiveDepartment$'
export * from './availableDepartments$'
export * from './assortmentFeatureEnabled$';
export * from './productsWithoutOrderingFeatureEnabled$';
export * from './shoppingCart/index';
export * from './products/index';
export * from './activeCustomer$';
export * from './customers/index';
export * from './authentication/index';
export * from './accountabilities/index';
export * from './ccp/index';
export * from './crm/index';
export * from './osa/index';
export * from './equipments/index';
export * from './messaging'
export * from './report/index';
export * from './orders/index';

import axios from 'axios'
import router from '@/router'


export * from './left$'
export * from './storedSecurityPolicySelection$'

export function initAxios () {
  axios.defaults.baseURL = '/api/sitecore/v2/'
  axios.defaults.withCredentials = true

  // all error handling should be taking care of here, when a error message should be displayed in the front-end pass it Promise.reject function, depending on the status.
  // See all the diffrent (error) results: https://efocus.atlassian.net/wiki/spaces/MEX/pages/159524849/1+Handling+results+from+APIv2
  // Add a response interceptor
  axios.interceptors.response.use((response) => {
    // always return a response when there's no error
    return response
  }, (error) => {
    // safe a reference to the original config.
    const originalRequest = error.config
    if (error.message === 'Network Error') {
      new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Er is een netwerk fout opgetreden',
          message: 'Controleer of uw internet verbinding nog goed werkt. Probeert u het anders op een later moment nog een keer. Onze excuses voor het ongemak',
          confirmButton: 'Ok',
          resolve,
          reject
        })
      }).then((payload) => {

      }).catch(() => {
        return Promise.reject(error)
      })
    } else if (error.response) {
      //  The request was made and the server responded with a status code
      //  that falls out of the range of 2xx
    } else if (error.request) {
      //  The request was made but no response was received
      //  `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      //  http.ClientRequest in node.js
      console.log(error.request)
    } else {
      //  Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    // Check for the status of the response
    if (error.response.status === 401 && !originalRequest._retry) {
      location.href = "/inloggen?" + Math.random();
    } else if (error.response.status === 400 && error.response.data.error === 'invalid_grant') {
      return Promise.reject(error)
    } else if (error.response.status === 403 && error.response.data.RedirectUrl === '/Selecteer-patient') {
      return new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Geen patiënt',
          message: error.response.data.Message,
          confirmButton: 'Selecteer patiënt',
          showConfirm: false,
          cancelButton: 'Ok',
          resolve,
          reject
        })
      }).then((payload) => {
        router.push('/')
      }).catch(() => {
        return Promise.reject(error)
      })
    } else if (error.response.status === 403 && error.response.data.RedirectUrl === '/Start-verantwoording') {
      return new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Geen verantwoording',
          message: error.response.data.Message,
          confirmButton: 'Naar patiënt dashboard',
          showConfirm: false,
          cancelButton: 'Ok',
          resolve,
          reject
        })
      }).then((payload) => {
        router.push('/patientdashboard')
      }).catch(() => {
        return Promise.reject(error)
      })
    } else if (error.response.status === 403 && error.response.data.RedirectUrl === '/Patientdashboard') {
      return new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Er is een fout opgetreden',
          message: error.response.data.Message,
          confirmButton: 'Naar dashboard',
          showConfirm: false,
          cancelButton: 'Ok',
          resolve,
          reject
        })
      }).then((payload) => {
        router.push('/')
      }).catch(() => {
        return Promise.reject(error)
      })
    } else if (error.response.status === 400 && error.response.data.Success === false && error.response.data.Message) {
      if (error.response && error.response.config && error.response.config.url && (error.response.config.url.indexOf('ShoppingCart/GetATPCheck') >= 0) ||
      error.response.data.IsFirstLogon) {
        return
      }
      return new Promise((resolve, reject) => {
        this.$emitter.emit('prompt', {
          title: 'Er is een fout opgetreden',
          message: error.response.data.Message,
          confirmButton: 'Naar dashboard',
          showConfirm: false,
          cancelButton: 'Ok',
          resolve,
          reject
        })
      }).then((payload) => {
        router.push('/')
      }).catch(() => {
        return Promise.reject(error)
      })
    } else {
      // Do something with response error
      return Promise.reject(error)
    }
  });
}
