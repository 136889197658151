<template>
  <section class="container pump-assignment">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="2"> Pomp toewijzen </v-heading>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="4"> Machtiging </v-heading>
      </div>
    </div>

    <v-licenses :license-overviews="licenseOverviews" :enable-management="false" :display-status-icon="false" />

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="4"> Selecteer pomp </v-heading>
      </div>
    </div>

    <div v-if="availablePumps.pumpList && availablePumps.pumpList.length > 0">
      <pump-search @reset-filters="computeAvailablePumps" @search-pump="searchPump" />
      <v-table v-if="computedAvailablePumps.pumpList && computedAvailablePumps.pumpList.length > 0">
        <thead>
          <v-header-row
            :data="pumpsHeader"
            :show-sort="true"
            :page="'PumpAssignment'"
            @sort-by-field="sortAvailablePumpsByField"
          />
        </thead>
        <v-pump-overview-row
          v-for="(row, index) in computedAvailablePumps.pumpList"
          :key="index"
          :data="row"
          :index="index"
          :user-warehouse-id="userWarehouseId"
          @select-pump="selectPump"
        />
      </v-table>
    </div>
    <div v-else-if="patientPumpConfiguration" class="pump-assignment__no-pumps">
      <v-table>
        <thead>
          <tr class="pump-assignment__no-pumps__cell_thead">
            <th />
          </tr>
        </thead>
        <tr>
          <td class="pump-assignment__no-pumps__cell_data">
            <v-warning-message
              :warning="patientPumpConfiguration.noPumpWarning"
              :message="patientPumpConfiguration.noPumpMessage"
              :html="false"
              :is-urgent="false"
            />
          </td>
        </tr>
      </v-table>
    </div>

    <div v-if="patientPumpConfiguration" class="pump-assignment__more-pumps">
      <v-table>
        <tr>
          <th />
        </tr>
        <tr>
          <th class="pump-assignment__more-pumps__question">
            <span>{{ patientPumpConfiguration.pumpSelectionQuestion }}</span>
          </th>
        </tr>
        <tr>
          <td class="pump-assignment__more-pumps__option form-group__radio">
            <input id="manualpump" name="options" type="radio" @change="getAdditionalPumps" />
            <label for="manualpump"><span></span>{{ patientPumpConfiguration.pumpSelectionOption1 }}</label>
          </td>
        </tr>
        <tr>
          <td class="pump-assignment__more-pumps__option form-group__radio">
            <input id="mediqpump" name="options" type="radio" @change="getAdditionalPumps" />
            <label for="mediqpump"><span></span>{{ patientPumpConfiguration.pumpSelectionOption2 }}</label>
          </td>
        </tr>
      </v-table>

      <v-table v-if="showManualPump_Warning">
        <tr>
          <td class="pump-assignment__no-pumps__warning_data">
            <v-warning-message
              :warning="patientPumpConfiguration.pumpSelectionOption1_Warning"
              :html="false"
              :is-urgent="false"
            />
          </td>
        </tr>
      </v-table>

      <v-table v-if="additionalPumps && additionalPumps.length > 0" class="pump-assignment__more-pumps__table">
        <thead>
          <v-header-row
            :data="pumpsHeader"
            :show-sort="true"
            :page="'PumpAssignment'"
            @sort-by-field="sortAdditionalPumpsByField"
          />
        </thead>
        <v-pump-overview-row
          v-for="(row, index) in additionalPumps"
          :key="index"
          :data="row"
          :index="index"
          :user-warehouse-id="userWarehouseId"
          @select-pump="selectPump"
          @set-serial-number="setSerialNumber"
        />
      </v-table>
    </div>

    <div v-if="showDeliveryDetails" class="row deliveryDetails">
      <div class="col-xs-12">
        <v-table>
          <tr class="deliveryDetails__header">
            <td class="deliveryDetails__header_text" colspan="2">Levering</td>
          </tr>
          <tbody>
            <tr>
              <td colspan="2">
                <legend>Wanneer moet Mediq de pomp leveren? *</legend>
                <div class="deliveryDetails__option form-group__radio">
                  <input id="TodayWithinFourHours" name="deliveryOptions" type="radio" @change="deliveryOptionSelect" />
                  <label for="TodayWithinFourHours"><span></span>Vandaag (binnen 4 uur)</label>
                </div>
                <div class="deliveryDetails__option form-group__radio">
                  <input id="TomorrowBeforeTwelve" name="deliveryOptions" type="radio" @change="deliveryOptionSelect" />
                  <label for="TomorrowBeforeTwelve"><span></span>Morgen (vóór 12:00 uur)</label>
                </div>
                <div class="deliveryDetails__option form-group__radio">
                  <input id="Other" name="deliveryOptions" type="radio" @change="deliveryOptionSelect" />
                  <label for="Other"><span></span>Anders, namelijk:</label>
                </div>
                <div v-if="showDeliveryDate" class="deliveryDetails__option">
                  <legend>Datum</legend>
                  <div>
                    <v-input-date-picker
                      ref="deliveryDate"
                      type="date"
                      name="deliveryDate"
                      label=""
                      autofocus
                      custom-date-range
                      @selected="handleDeliveryDate"
                    />
                  </div>
                </div>
                <div class="form-group-simple__details" v-if="showLeveringError">
                  <div class="form-group-simple__messages">
                    <div class="form-group-simple__error">
                      <span> <v-error-icon></v-error-icon> Dit veld is verplicht.</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <legend>Gewenste afleverlocatie</legend>
                <div class="deliveryDetails__option form-group__radio">
                  <input
                    id="homeAddress"
                    name="deliveryLocation"
                    type="radio"
                    checked
                    @change="deliveryLocationSelect"
                  />
                  <label for="homeAddress"><span></span>Woonadres</label>
                </div>
                <div
                  v-if="deliveryAddress && deliveryAddress.AdresId !== 0"
                  class="deliveryDetails__option form-group__radio"
                >
                  <input id="deliveryAddress" name="deliveryLocation" type="radio" @change="deliveryLocationSelect" />
                  <label for="deliveryAddress"><span></span>Afwijkend afleveradres</label>
                </div>
                <div class="deliveryDetails__option form-group__radio">
                  <input id="differentAddress" name="deliveryLocation" type="radio" @change="deliveryLocationSelect" />
                  <label for="differentAddress"><span></span>Eenmalig afwijkend afleveradres</label>
                </div>
              </td>
              <td>
                <legend>Afleveradres</legend>
                <div v-if="showHomeAddress">
                  {{ homeAddress.Straatnaam }} {{ homeAddress.Huisnummer }}
                  {{ homeAddress.Toevoeging }}
                  <br />
                  {{ homeAddress.Postcode }} {{ homeAddress.Woonplaats }}
                  <br />
                  {{ homeAddress.Land }}
                </div>
                <div v-if="showDeliveryAddress && deliveryAddress && deliveryAddress.AdresId !== 0">
                  {{ deliveryAddress.Straatnaam }}
                  {{ deliveryAddress.Huisnummer }}
                  {{ deliveryAddress.Toevoeging }}
                  <br />
                  {{ deliveryAddress.Postcode }}
                  {{ deliveryAddress.Woonplaats }}
                  <br />
                  {{ deliveryAddress.Land }}
                </div>
                <div v-if="showAddressDetails">
                  <div class="row">
                    <div class="col-xs-12">
                      <v-input-group-simple-2
                        id="Postcode"
                        v-model:value="differentAddress.PostCode"
                        type="text"
                        maxlength="7"
                        :rules="{
                          required: true,
                          regex: /^\d{4} [A-Z]{2}$/
                        }"
                        label="Postcode"
                        @blur="autoFillAddress()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <v-input-group-simple-2
                        id="Huisnummer"
                        v-model:value="differentAddress.Huisnummer"
                        type="text"
                        :rules="{
                          required: true
                        }"
                        label="Huisnummer"
                        @blur="autoFillAddress()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <v-input-group-simple-2
                        id="Toevoeging"
                        v-model:value="differentAddress.Toevoeging"
                        type="text"
                        :rules="{
                          required: false
                        }"
                        label="Toevoeging"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <v-input-group-simple-2
                        id="Straatnaam"
                        :key="prefillAddressDataKey"
                        v-model:value="differentAddress.Straatnaam"
                        type="text"
                        :rules="{
                          required: true
                        }"
                        label="Straatnaam"
                        :disabled="addressLookupSuccessful"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <v-input-group-simple-2
                        id="Woonplaats"
                        :key="prefillAddressDataKey"
                        v-model:value="differentAddress.Woonplaats"
                        type="text"
                        :rules="{
                          required: true
                        }"
                        label="Plaats"
                        :disabled="addressLookupSuccessful"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>

    <div v-if="showDeliveryDetails" class="row deliveryDetails">
      <div class="col-xs-12">
        <v-table>
          <tr class="deliveryDetails__header">
            <td class="deliveryDetails__header_text">Contactgegevens</td>
          </tr>
          <tbody>
            <tr>
              <td class="deliveryDetails__option">
                <v-input-group-simple-2
                  id="TelephoneNumber"
                  ref="manualTelephoneNumber"
                  v-model="manualTelephoneNumber"
                  :rules="{
                    required: true,
                    regex: /^0\d{9}$/
                  }"
                  type="text"
                  label="Telefoonnummer"
                />
                <v-textarea-group
                  v-model="optionalText"
                  inputid="Uw bericht"
                  label="Opmerkingen (optioneel)"
                  @input="setRemark"
                />
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>

    <div class="row justify-content-end">
      <v-button md primary :class="getButtonStatus()" @click.prevent="savePump"> Toewijzen </v-button>
      <v-button md hollow transparent @click.prevent="cancelPumpAssignment"> Annuleren </v-button>
    </div>

    <generic-modal v-if="reConfirmationPopup">
      <template #body>
        <p>
          U staat op het punt om te stoppen met het toewijzen van een pomp aan uw patiënt. Indien u op annuleren klikt
          wordt er geen pomp geleverd en komt er een actie voor pomp toewijzen op uw actielijst. Kies pomp toewijzen als
          u toch een pomp wenst toe te wijzen.
        </p>
      </template>
      <template #footer>
        <v-button md hollow transparent @click.prevent="prescriberRredirect('/patientdashboard')"> Annuleren </v-button>
        <v-button tagelement="button" md primary @click.prevent="cancelReConfirmationPopup"> Pomp toewijzen </v-button>
      </template>
    </generic-modal>

    <generic-modal v-if="showPopup">
      <template #header>
        {{ patientPumpConfiguration.popupHeader }}
      </template>
      <template #body>
        <p>{{ patientPumpConfiguration.popupText }}</p>
      </template>
      <template #footer>
        <v-button v-if="getAccountabilityFlowStatus()" md hollow transparent @click.prevent="createAccountability()">
          {{ patientPumpConfiguration.createNewAuthorizationLinkText }}
        </v-button>
        <v-button
          v-else
          md
          hollow
          transparent
          class="pump-assignment__more-pumps__cancel-pumpassignment"
          @click.prevent="prescriberRredirect('/')"
        >
          {{ patientPumpConfiguration.myDashboardLinkText }}
        </v-button>
        <v-button md primary @click.prevent="prescriberRredirect('/patientdashboard')">
          {{ patientPumpConfiguration.popupButton }}
        </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import VTable from '@/components/shared/table/Table';
import VLicenses from '@/components/sections/licenses/Index';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VPumpOverviewRow from '@/components/sections/pumpOverview/PumpOverviewRow';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import { useStore, mapActions } from 'vuex';
import { computed, ref } from 'vue';
import GenericModal from '@/components/shared/GenericModal';
import VWarningMessage from '@/components/shared/WarningMessage';
import { router$ } from '@/router/router$';
import PumpSearch from '@/components/sections/pumpOverview/PumpSearch';
import { useForm } from 'vee-validate';
import { from, of, combineLatest } from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { getLicenseOverview, activeCustomerId$, activePpwRecId$, navigateToAccountability } from '@/services';
import VErrorIcon from 'icons/AlertCircle';

export default {
  name: 'PumpAssignment',
  components: {
    VTable,
    VLicenses,
    VHeaderRow,
    VPumpOverviewRow,
    GenericModal,
    VWarningMessage,
    VInputDatePicker,
    PumpSearch,
    VErrorIcon
  },
  setup() {
    const store = useStore();
    const licenseId = ref(null);
    const accountabilityId = ref(null);
    const licenseOverview = ref(null);
    const customerId = ref(null);
    const patientPumpConfiguration = ref(null);
    const availablePumps = ref([]);
    const userWarehouseId = ref(null);
    const computedAvailablePumps = ref([]);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const user = computed(() => store.getters['navigation/getUser']);
    const homeAddress = computed(() => store.getters['patient/getPatientHomeAddress']);
    const differentAddress = computed(() => store.getters['patient/getPatientDifferentAddress']);
    const deliveryAddress = computed(() => store.getters['patient/getPatientDeliveryAddress']);

    const saveAllocatePump = (value) => store.dispatch('patient/saveAllocatePump', value);
    const allocatePumpFromMediqDepot = (value) => store.dispatch('patient/allocatePumpFromMediqDepot', value);
    const getAdditionalPumpsStore = (value) => store.dispatch('patient/getAdditionalPumps', value);
    const setShowExtendLicensesBlock = (value) => store.commit('accountability/setShowExtendLicensesBlock', value);
    const fetchAddressLookup = (value) => store.dispatch('patient/fetchAddressLookup', value);

    const licenseId$ = router$.pipe(map((route) => route && route.params.licenseId / 1));

    const customerId$ = activeCustomerId$;

    const licenseOverview$ = combineLatest([customerId$, activePpwRecId$, licenseId$]).pipe(
      switchMap(([customerId, ppwRecId, licenseId]) => {
        return licenseId && ppwRecId && customerId
          ? from(getLicenseOverview(customerId, ppwRecId, licenseId))
          : of(null);
      }),
      publishReplay(1),
      refCount()
    );

    const accountabilityId$ = licenseOverview$.pipe(map((license) => (license ? license.accountabilityId : null)));

    licenseId$.subscribe((id) => {
      licenseId.value = id;
    });

    accountabilityId$.subscribe((id) => {
      accountabilityId.value = id;
    });

    licenseOverview$.subscribe((overview) => {
      licenseOverview.value = overview;
    });

    customerId$.subscribe((id) => {
      customerId.value = id;
    });

    store.dispatch('patient/fetchPatientAddress', customerId.value);
    store.dispatch('patient/getCurrentPatient');
    store.dispatch('patient/fetchPatientPumpConfiguration').then((response) => {
      patientPumpConfiguration.value = response.data;
    });
    let requiredIds = {
      Status: 1
    };

    const computeAvailablePumps = () => {
      computedAvailablePumps.value = Object.assign([], availablePumps.value);
      computedAvailablePumps.value.pumpList = computedAvailablePumps.value.pumpList.sort((a, b) =>
        a.pumpDesc.localeCompare(b.pumpDesc)
      );
    };

    store.dispatch('patient/fetchAvailablePumps', requiredIds).then((response) => {
      availablePumps.value = response.data;
      computeAvailablePumps();
      userWarehouseId.value = response.data.userWarehouseId;
    });

    return {
      patientGegevens,
      user,
      homeAddress,
      differentAddress,
      deliveryAddress,
      saveAllocatePump,
      allocatePumpFromMediqDepot,
      getAdditionalPumpsStore,
      setShowExtendLicensesBlock,
      fetchAddressLookup,
      ...mapActions({
        requestAccountabilityCopy: 'accountability/requestAccountabilityCopy'
      }),
      licenseId,
      accountabilityId,
      licenseOverview,
      customerId,
      patientPumpConfiguration,
      availablePumps,
      userWarehouseId,
      computedAvailablePumps,
      computeAvailablePumps
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      pumpsHeader: ['', '', 'Omschrijving', 'Serienummer', 'Onderhoud nodig in'],
      pumpsHeadersForSorting: {
        Omschrijving: 'pumpDesc',
        Serienummer: 'serialNo',
        'Onderhoud nodig in': 'daysLeftForMaintenance'
      },
      emptyHeader: [''],
      isPumpSelected: false,
      pumpId: '',
      productId: '',
      showPopup: false,
      additionalPumps: [],
      additionalPumpSelected: false,
      serialNumber: '',
      manualTelephoneNumber: '',
      selectedOption: '',
      showManualPump_Warning: false,
      optionalText: '',
      addressLookup: null,
      prefillAddressDataKey: 0,
      selectedDeliveryOption: '',
      selectedDeliveryLocation: 'homeAddress',
      deliveryDate: null,
      showDeliveryDate: false,
      showHomeAddress: true,
      showDeliveryAddress: false,
      showDeliveryDetails: false,
      showAddressDetails: false,
      mediqPumpOptionSelected: false,
      reConfirmationPopup: false,
      showLeveringError: false
    };
  },
  computed: {
    serialNumberValidated() {
      if (this.selectedOption === 'manualpump' && this.serialNumber === '') {
        return false;
      }
      return true;
    },
    licenseOverviews() {
      if (!this.licenseOverview) {
        return [];
      }

      return [this.licenseOverview];
    },
    addressLookupSuccessful() {
      return this.addressLookup !== null;
    },
    deliveryDetailsValidated() {
      if (this.showDeliveryDetails == true && this.selectedDeliveryLocation !== '') {
        return true;
      }
      return false;
    }
  },
  watch: {
    selectedDeliveryOption(value) {
      if (value) {
        this.showLeveringError = false;
      }
    }
  },
  methods: {
    searchPump(searchDetails) {
      this.computeAvailablePumps();
      if (
        (!searchDetails.searchText || (searchDetails && searchDetails.searchText.length < 2)) &&
        !searchDetails.status
      ) {
        return;
      }
      if (searchDetails.searchText && searchDetails.searchText.length > 2) {
        const lowerCaseSearchText = searchDetails.searchText.toLowerCase();
        this.computedAvailablePumps.pumpList = this.computedAvailablePumps.pumpList.filter((l) => {
          return (
            l.pumpDesc.toLowerCase().includes(lowerCaseSearchText) ||
            (l.serialNo !== null && l.serialNo.toLowerCase().includes(lowerCaseSearchText))
          );
        });
      }
      if (searchDetails.status) {
        this.computedAvailablePumps.pumpList = this.computedAvailablePumps.pumpList.filter((l) => {
          if (searchDetails.status === 'Available') {
            return l.equipmentStatus === searchDetails.status || l.equipmentStatus === 'Lend';
          }
          if (searchDetails.status === 'Allocated') {
            return l.equipmentStatus === searchDetails.status || l.equipmentStatus === 'LendAndAssigned';
          }
          return l.equipmentStatus === searchDetails.status;
        });
      }
    },
    selectPump(data) {
      this.isPumpSelected = data.isPumpSelected;
      this.pumpId = data.pumpId;
      this.productId = data.productId;
      this.serialNumber = data.serialNumber;
      if (this.selectedOption === 'mediqpump') {
        this.showDeliveryDetails = true;
      } else {
        this.showDeliveryDetails = false;
      }
    },
    setSerialNumber(data) {
      this.serialNumber = data.serialNumber;
    },
    getButtonStatus() {
      if (this.mediqPumpOptionSelected && this.isPumpSelected && this.deliveryDetailsValidated) {
        return 'btn--cta';
      } else if (this.isPumpSelected && this.selectedOption !== 'mediqpump') {
        return 'btn--cta';
      } else {
        return 'btn--disabled';
      }
    },
    savePump() {
      if (this.mediqPumpOptionSelected && (!this.manualTelephoneNumber || !this.selectedDeliveryOption)) {
        if (!this.selectedDeliveryOption) {
          this.showLeveringError = true;
        }
        this.validate();
        return;
      }

      if (this.pumpId !== 0 && this.isPumpSelected) {
        let requiredDetails = {
          PatientId: this.patientGegevens.PatientId,
          PumpId: this.pumpId,
          LicenseId: this.licenseId
        };
        this.saveAllocatePump(requiredDetails).then(() => {
          this.showPopup = true;
        });
      } else if (this.isPumpSelected && this.serialNumberValidated) {
        let addressType = 0;
        let address = null;
        if (this.selectedDeliveryLocation === 'homeAddress') {
          addressType = 0;
          address = this.homeAddress;
        }
        if (this.selectedDeliveryLocation === 'deliveryAddress') {
          addressType = 1;
          address = this.deliveryAddress;
        }
        if (this.selectedDeliveryLocation === 'differentAddress') {
          addressType = 2;
          address = this.differentAddress;
        }
        let requiredDetails = {
          CustomerId: this.patientGegevens.PatientId,
          ProductId: this.productId,
          SerialNumber: this.serialNumber,
          LicenseId: this.licenseId,
          DeliveryOptions: this.selectedDeliveryOption,
          DeliveryDate: this.deliveryDate,
          AddressType: addressType,
          Address: address,
          Remark: this.optionalText,
          TelephoneNumber: this.manualTelephoneNumber
        };
        requiredDetails.DeliveryDate = requiredDetails.DeliveryDate
          ? moment(requiredDetails.DeliveryDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null;
        this.allocatePumpFromMediqDepot(requiredDetails).then(() => {
          if (this.getAccountabilityFlowStatus()) {
            this.showPopup = true;
          } else {
            router.push('/patientdashboard');
          }
        });
      }
    },
    getAdditionalPumps(e) {
      this.selectedOption = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      if (this.selectedOption === 'manualpump') {
        this.computeAvailablePumps();
        this.mediqPumpOptionSelected = false;
        this.additionalPumps = [];
        this.manualTelephoneNumber = '';
        this.showManualPump_Warning = true;
        this.isPumpSelected = false;
        this.showDeliveryDetails = false;
        this.showHomeAddress = true;
        this.showDeliveryAddress = false;
        this.showAddressDetails = false;
      } else {
        this.mediqPumpOptionSelected = true;
        this.serialNumber = '';
        this.showManualPump_Warning = false;
        this.getAdditionalPumpsStore(this.selectedOption).then((response) => {
          this.additionalPumps = response.data;
        });
      }
    },
    cancelPopup() {
      this.showPopup = false;
    },
    sortAdditionalPumpsByField(sortFieldDetails) {
      this.sortPumpsByField(sortFieldDetails, this.additionalPumps);
    },
    sortAvailablePumpsByField(sortFieldDetails) {
      this.sortPumpsByField(sortFieldDetails, this.computedAvailablePumps.pumpList);
    },
    sortPumpsByField(sortFieldDetails, pumpArray) {
      let key = sortFieldDetails.option;
      let value = this.pumpsHeadersForSorting[key];
      pumpArray.sort(function (a, b) {
        const leftValue = a[value];
        const rightValue = b[value];
        if (!leftValue) {
          return 1;
        }
        if (!rightValue) {
          return -1;
        }
        if (sortFieldDetails.isAscending) {
          if (typeof leftValue === 'number' && typeof rightValue === 'number') {
            return leftValue < rightValue ? -1 : 1;
          } else {
            return leftValue.localeCompare(rightValue);
          }
        } else {
          if (typeof leftValue === 'number' && typeof rightValue === 'number') {
            return leftValue > rightValue ? -1 : 1;
          } else {
            return rightValue.localeCompare(leftValue);
          }
        }
      });
    },
    createAccountability() {
      this.setShowExtendLicensesBlock(false);
      // Call the server for a new accountability, based on data of the current, 'edited' one
      this.requestAccountabilityCopy({
        AccountabilityId: this.accountabilityId,
        IsAccountabilityUsedForAssigningMultiplePumps: true
      })
        .then((response) => {
          const copyAccountabilityId = response.data.Id;
          // Request granted
          navigateToAccountability(copyAccountabilityId);
        })
        .catch((error) => {
          if (error !== 'cancel') {
            const m = error.response.data.Exception.Message;
            new Promise((resolve, reject) => {
              this.$emitter.emit('prompt', {
                title: 'Machtiging wijzigen',
                message: m,
                confirmButton: 'Naar patiënt dashboard',
                resolve,
                reject
              });
            })
              .then(() => {
                // Start a new accountability with no prefilled data. However, at the end the original still needs to be terminated!!!!!
                // TODO: when template no longer available (exception), then user needs a way to select one before starting anew
                //       the idea right now is that with the exception, backend includes a templateId property in the response in case they can
                //       determine which is the 'follow-up' template when the original is no longer valid
                //       the problem happens when backend cannot and we need to either render a template selection button in the exception overlay
                //       or we need to throw in the towel and tell the user they can't extend an accountability
                // store.dispatch('accountability/startNewAccountability', templateId).then((response) => router.push('/machtiging'))
              })
              .catch(() => {});
          }
        });
    },
    prescriberRredirect(path) {
      router.push(path);
    },
    getAccountabilityFlowStatus() {
      return router.currentRoute.value.params.type === 'verantwoording';
    },
    autoFillAddress() {
      this.fetchAddressLookup({
        postcode: this.differentAddress.PostCode,
        huisnummer: this.differentAddress.Huisnummer,
        land: 'NL'
      })
        .then((response) => {
          var hasAddress = response.data.City !== null;

          this.differentAddress.Straatnaam = hasAddress ? response.data.Street : '';
          this.differentAddress.Woonplaats = hasAddress ? response.data.City : '';

          this.addressLookup = hasAddress ? response.data : null;
          this.validateAllFields();
          this.prefillAddressDataKey += 1;
        })
        .catch(() => {
          this.addressLookup = null;
          this.differentAddress.Straatnaam = '';
          this.differentAddress.Woonplaats = '';
        });
    },
    async validateAllFields() {
      const result = await this.validate();
    },
    deliveryOptionSelect(e) {
      this.selectedDeliveryOption = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      this.showDeliveryDate = false;
      if (this.selectedDeliveryOption === 'Other') {
        this.showDeliveryDate = true;
      }
    },
    deliveryLocationSelect(e) {
      this.selectedDeliveryLocation = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      this.showHomeAddress = false;
      this.showDeliveryAddress = false;
      this.showAddressDetails = false;
      if (this.selectedDeliveryLocation === 'homeAddress') {
        this.showHomeAddress = true;
      } else if (this.selectedDeliveryLocation === 'deliveryAddress') {
        this.showDeliveryAddress = true;
      } else {
        this.showAddressDetails = true;
      }
    },
    handleDeliveryDate(key, date) {
      this.deliveryDate = date;
    },
    setRemark(event) {
      this.optionalText = event.target ? event.target.value : '';
    },
    cancelPumpAssignment() {
      this.reConfirmationPopup = true;
    },
    cancelReConfirmationPopup() {
      this.reConfirmationPopup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.pump-assignment {
  .form-group-simple__error {
    .material-design-icon {
      display: inline-flex;
      width: 16px;
      height: 16px;
      fill: #eb0000;
      vertical-align: bottom;
    }
  }
  &__no-pumps {
    background-color: $table-row-background;

    &__cell_data {
      padding: 30px 500px 30px 30px;
    }
    &__cell_thead {
      height: 45px;
      border: 1px solid $gray-light;
      background-color: $teal-lighter;
    }
    &__warning_data {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 100px;
    }
  }
  &__more-pumps {
    background-color: $table-row-background;
    font-size: 16px;
    color: $black;
    padding: 30px 0 30px 0;
    margin: 24px 0px;

    &__question {
      font-weight: bold;
      padding-left: 25px;
      padding-bottom: 20px;
      text-align: start;
    }
    &__option {
      padding-top: 2px;
      padding-left: 25px;
      padding-bottom: 10px;
    }
    &__button_enable {
      background-color: $orange;
      border: $orange;
    }
    &__button_disable {
      background-color: $gray-light;
      border: $gray-light;
    }
    &__cancel-pumpassignment {
      font-size: 18px;
      color: $teal-darkest;
      padding-right: 20px;
      padding-top: 10px;
    }
    &__table {
      margin-top: 30px;
      margin-bottom: -30px;
    }
  }
  .deliveryDetails {
    margin-bottom: 24px;
    table {
      legend {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
      }
      > tr {
        > td,
        > th {
          padding: 10px 20px 16px;
        }
      }
      > tbody {
        > tr {
          > td,
          > th {
            vertical-align: baseline;
            padding: 10px 20px 16px;
            width: 33%;
            max-width: 300px;
          }
        }
      }
    }
    border: 1px solid $gray-light;
    background: $white;
    &__header {
      background-color: $grayish-very-light;
      height: 50px;
    }
    &__header_text {
      font-size: 1.266rem;
      font-weight: 300;
    }
    &__question {
      font-weight: bold;
    }
    &__option {
      color: $gray-dark;
    }
  }
}
</style>
