<template>
  <div class="row full-width-header patient-accountability">
    <div class="col">
      <v-header
        v-if="accountabilityId"
        :title="title"
        :is-new-accountability="isNewAccountability"
        :accountability-id="accountabilityId"
      />
      <div id="step1" class="row grid-padding">
        <div id="step2" class="col-xs-12 col-md-12 accountability-column col-lg-6">
          <div class="accountability-column-questions" name="card-animation">
            <fieldset v-for="(question, indexquestion) in questions" :key="indexquestion" :data-index="indexquestion">
              <legend v-if="question.QuestionId != '1000'">
                {{ question.Tekst }}
              </legend>
              <p v-if="question.Opmerking.length">
                {{ question.Opmerking }}
              </p>
              <div v-for="(answer, index) in question.Antwoorden" :key="index" :data-index="index">
                <!--STANDARD (RADIO) QUESTION-->
                <div v-if="answer.TypeCode === 'Standard'" class="form-group__radio">
                  <input
                    :id="answer.AnswerId"
                    :checked="
                      question.ResultaatAnswers.length > 0 && question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                    "
                    :name="question.AccountabilityLineId"
                    type="radio"
                    @change="checkRadioAnswer($event, question.AccountabilityLineId)"
                  />
                  <label :for="answer.AnswerId"><span></span>{{ answer.AntwoordTekst }}</label>
                </div>
                <!--STANDARD (RADIO) QUESTION WITH OPEN (TextInput) -->
                <div v-if="answer.TypeCode === 'OpenStandaard'">
                  <div class="form-group__radio">
                    <input
                      :id="answer.AnswerId"
                      :checked="
                        checkRadioStatus(question.ResultaatAnswers, answer.AnswerId, question.AccountabilityLineId)
                      "
                      :name="question.AccountabilityLineId"
                      type="radio"
                      @change="checkedRadioAnswerStandard(answer.AnswerId, question.AccountabilityLineId)"
                    />
                    <label :for="answer.AnswerId"><span></span>{{ answer.AntwoordTekst }}</label>
                  </div>
                  <form
                    :id="'form' + answer.AnswerId"
                    class="openquestion"
                    style="display: none"
                    :name="question.AccountabilityLineId"
                    @submit="submitTypeQuestionOpen"
                  >
                    <div class="row grid-padding">
                      <div class="col-xs-12 col-md-8">
                        <div
                          v-if="
                            question.ResultaatAnswers.length > 0 &&
                            question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                          "
                        >
                          <v-input-group-simple
                            type="text"
                            :inputid="answer.AnswerId + 'txt'"
                            :initvalue="question.ResultaatAnswers[0].Resultaat"
                            sm
                            alt
                            @input="
                              changeRadioText(
                                $event,
                                question.ResultaatAnswers[0].Resultaat,
                                question.AccountabilityLineId
                              )
                            "
                          />
                        </div>
                        <div v-else>
                          <v-input-group-simple type="text" :inputid="answer.AnswerId + 'txt'" sm alt @input="input" />
                        </div>
                      </div>
                      <div
                        v-if="
                          question.ResultaatAnswers.length > 0 &&
                          question.ResultaatAnswers[0].AnswerId === answer.AnswerId
                        "
                      >
                        <div v-if="currentRadioAnswerId === answer.AnswerId && isRadioTextChanged === true">
                          <v-button
                            :id="answer.AnswerId + 'btn'"
                            type="submit"
                            lg
                            hollow
                            :disabled="getNextRadioButtonStatus(answer.AnswerId)"
                          >
                            Opslaan
                          </v-button>
                        </div>
                        <div v-else>
                          <v-button :id="answer.AnswerId + 'btn'" type="submit" lg hollow> Opgeslagen </v-button>
                        </div>
                      </div>
                      <div v-else>
                        <v-button
                          :id="answer.AnswerId + 'btn'"
                          type="submit"
                          lg
                          hollow
                          :disabled="getNextRadioButtonStatus(answer.AnswerId)"
                        >
                          Opslaan
                        </v-button>
                      </div>
                    </div>
                    <div
                      v-if="alertVisibityStatus(question.ResultaatAnswers, answer.AnswerId)"
                      class="row grid-padding"
                    >
                      <div :id="'div' + question.AccountabilityLineId" class="details">
                        <div class="error">
                          Het is verplicht dit veld in te vullen en op de knop 'Opslaan' te klikken.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <!--DROPDOWN QUESTION -->
                <v-select-group
                  v-if="answer.TypeCode === 'Dropdown'"
                  label="Maak een keuze"
                  :selectid="question.AccountabilityLineId.toString()"
                  :data-answerid="answer.AnswerId"
                  :initselected="question.ResultaatAnswers.length > 0 && question.ResultaatAnswers[0].AnswerListItemId"
                  @change="checkSelectAnswer($event)"
                >
                  <option
                    v-for="(option, indexoptions) in answer.AntwoordLijst"
                    :key="indexoptions"
                    :data-index="indexoptions"
                    :initvalue="option.AnswerListItemId"
                    :value="option.AnswerListItemId"
                    :data-answerid="answer.AnswerId"
                  >
                    {{ option.Omschrijving }}
                  </option>
                </v-select-group>
                <!--OPEN (TextInput) QUESTION -->
                <form
                  v-if="answer.TypeCode === 'Open'"
                  :name="question.AccountabilityLineId"
                  @submit="submitTypeQuestionOpen"
                >
                  <div class="row">
                    <div class="col-xs-12 col-md-8">
                      <div v-if="question.ResultaatAnswers.length > 0">
                        <v-input-group-simple
                          type="text"
                          :inputid="answer.AnswerId"
                          :initvalue="question.ResultaatAnswers[0].Resultaat"
                          sm
                          alt
                          @input="input"
                        />
                      </div>
                      <div v-else>
                        <v-input-group-simple type="text" :inputid="answer.AnswerId" sm alt @input="input" />
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <v-button
                        :id="answer.AnswerId + 'btn'"
                        type="submit"
                        lg
                        hollow
                        :disabled="getNextButtonStatus(answer.AnswerId)"
                      >
                        Opslaan
                      </v-button>
                    </div>
                  </div>
                </form>
                <!--CHECKBOX QUESTION -->
                <form
                  v-if="answer.TypeCode === 'Checkbox'"
                  :name="question.AccountabilityLineId"
                  @submit="submitCheckbox($event, answer.AnswerId)"
                >
                  <div class="row grid-margin">
                    <div class="col-xs-12">
                      <v-checkbox-group
                        v-for="(checkbox, index) in answer.AntwoordLijst"
                        :key="index"
                        :label="checkbox.Omschrijving"
                        :inputid="checkbox.AnswerListItemId"
                        :initvalue="getCheckboxStatus(question.ResultaatAnswers, checkbox.AnswerListItemId)"
                        :overwrite-value="checkbox.IsOpen"
                        :name="answer.AnswerId"
                      />
                    </div>
                    <div :id="'remark-' + question.AccountabilityLineId" class="col-xs-12 remark checkbox-msg">
                      *Selecteer ten minste één antwoord.
                    </div>
                    <div class="col-xs-4">
                      <v-button type="submit" lg hollow> Volgende vraag </v-button>
                    </div>
                  </div>
                </form>
                <div
                  v-if="
                    question.ResultaatAnswers.length > 0 &&
                    question.ResultaatAnswers[0].AnswerId === answer.AnswerId &&
                    answer.Opmerking
                  "
                  class="remark"
                  v-html="answer.Opmerking"
                />
              </div>
            </fieldset>
            <!--Start DATE QUESTION-->
            <div v-if="showStartDate()" class="row customStartDate form-group__date">
              <div class="col-xs-12 col-md-8">
                <v-input-date-picker
                  ref="startDate"
                  type="date"
                  name="startDate"
                  label="Startdatum therapie:"
                  autofocus
                  @selected="handleStartDate"
                />
              </div>
              <div>
                <v-button lg cta class="customStartDateSubmit" @click.prevent="customStartDateSubmit">
                  Afronden
                </v-button>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div>
              <div v-if="showSuccessMessage()" class="complete">
                <svg class="checked" style="width: 38px; height: 38px" viewBox="0 0 24 24">
                  <path fill="#fff" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                </svg>
                {{ accountabilityTexts.AccountabilityCompletionText }}
              </div>
              <div v-if="isLastAnswer && !isAllowedToProceed" class="complete notpossible">
                {{ accountabilityTexts.AccountabilityNonCompletionText }}
              </div>
            </div>
          </transition>
        </div>
        <div class="col-xs-12 col-md-12 product-column col-lg-6">
          <v-select-product
            v-if="!isSingleAccountability && productSelectionAccountabilityId"
            :accountability-texts="accountabilityTexts"
            :accountability-id="accountabilityId"
            :product-selection-accountability-id="productSelectionAccountabilityId"
          />
          <div v-if="isSingleAccountability" class="row grid-margin grid-justify-content-right">
            <div class="col-xs-12 col-sm-3">
              <v-button hollow lg @click.prevent="cancelAccountability"> Annuleren </v-button>
            </div>
            <div class="col-xs-12 col-sm-5">
              <v-button cta lg :class="{ greyOut: !isLastAnswer }" @click.prevent="signin">
                Controleren & Ondertekenen
              </v-button>
            </div>
          </div>
        </div>
      </div>
      <!--Pop up to show validation messages -->
      <generic-modal v-if="showValidationPopUp">
        <template #header>
          {{ accountabilityTexts.AccountabilityValidationMessageTitle }}
        </template>
        <template #body>
          <p>{{ accountabilityTexts.AccountabilityValidationMessageText }}</p>
        </template>
        <template #footer>
          <v-button tagelement="button" md primary @click.prevent="closeModal"> Ga verder </v-button>
        </template>
      </generic-modal>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import router from '@/router';
import GenericModal from '@/components/shared/GenericModal';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VSelectProduct from '@/components/sections/patient/accountability/selectProduct/Index';
import VButton from '@/components/shared/Button.vue';
import VHeader from '@/components/sections/patient/accountability/PatientAccountabilityHeader';
import VInputDatePicker from '@/components/shared/form/simple/InputDatePicker';
import { getAccountabilityRootStatus } from '@/services';
import { router$ } from '@/router/router$';
import { tap, map, filter, switchMap } from 'rxjs/operators';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

export default {
  name: 'PatientAccountability',
  components: {
    VButton,
    VHeader,
    VCheckboxGroup,
    VSelectProduct,
    VInputDatePicker,
    GenericModal
  },
  setup() {
    const store = useStore();
    const accountabilityId = ref(null);
    const productSelectionAccountabilityId = ref(null);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const questions = computed(() => store.getters['accountability/getQuestions']);
    const isLastAnswer = computed(() => store.getters['accountability/getIsLastAnswer']);
    const isAllowedToProceed = computed(() => store.getters['accountability/getIsAllowedToProceed']);
    const isSingleAccountability = computed(() => store.getters['accountability/getIsSingleAccountability']);
    const isNewAccountability = computed(() => store.getters['accountability/getIsNewAccountability']);
    const title = computed(() => store.getters['accountability/getTitleAccountability']);
    const isStartDate = computed(() => store.getters['accountability/getIsStartDate']);
    const accountabilityTexts = computed(() => store.getters['accountability/getAccountabilityTexts']);

    const setQuestions = (value) => store.commit('accountability/setQuestions', value);
    const resetProductsAndFilters = () => store.commit('selectProduct/resetProductsAndFilters');
    const setIsAllowedToProceed = (value) => store.commit('accountability/setIsAllowedToProceed', value);
    const setIsLastAnswer = (value) => store.commit('accountability/setIsLastAnswer', value);
    const getQuestions = async (value) => await store.dispatch('accountability/getQuestions', value);
    const setAnswer = async (value) => await store.dispatch('accountability/setAnswer', value);
    const cancelAccountabilityForm = (value) => store.dispatch('accountability/cancelAccountabilityForm', value);
    const setStartDateForTwoColumnAccountability = (value) =>
      store.commit('accountability/setStartDateForTwoColumnAccountability', value);

    const loadAccountability = async (accountabilityId) => {
      await getQuestions(accountabilityId);
    };

    const accountabilityId$ = router$.pipe(
      map((route) => route && route.params.id / 1),
      filter((accountabilityId) => !!accountabilityId),
      tap((accountabilityId) => loadAccountability(accountabilityId))
    );

    const productSelectionAccountabilityId$ = accountabilityId$.pipe(
      switchMap((accountabilityId) => getAccountabilityRootStatus(accountabilityId)),
      map((accountabilityRootStatus) => accountabilityRootStatus?.productSelection?.accountabilityId)
    );

    accountabilityId$.subscribe((id) => (accountabilityId.value = id));
    productSelectionAccountabilityId$.subscribe((id) => (productSelectionAccountabilityId.value = id));

    async () => await store.dispatch('patient/getCurrentPatient');

    return {
      patientGegevens,
      questions,
      isLastAnswer,
      isAllowedToProceed,
      isSingleAccountability,
      isNewAccountability,
      title,
      isStartDate,
      accountabilityTexts,
      setQuestions,
      resetProductsAndFilters,
      setIsAllowedToProceed,
      setIsLastAnswer,
      getQuestions,
      setAnswer,
      cancelAccountabilityForm,
      setStartDateForTwoColumnAccountability,
      accountabilityId,
      productSelectionAccountabilityId
    };
  },
  data() {
    return {
      startDate: null,
      isStartDateAnswered: false,
      showValidationPopUp: false,
      currentRadioAnswerId: 0,
      isRadioTextChanged: false
    };
  },
  watch: {
    isLastAnswer(value) {
      if (value) {
        // scroll to top of the page is the last answer got submitted
        if (!this.isStartDate) {
          this.scrollToTop();
        }
      }
    }
  },
  unmounted() {
    this.setQuestions({});
    this.resetProductsAndFilters();
    this.setIsAllowedToProceed(false);
    this.setIsLastAnswer(false);
  },
  methods: {
    alertVisibityStatus(resultList, ansId) {
      let status = true;
      if (resultList.length > 0 && resultList[0].AnswerId === ansId) {
        status = false;
      }
      return status;
    },
    changeRadioText(event, result, accountabilityLineId) {
      let answerId = event;
      let answerText = document.getElementById(answerId) ? document.getElementById(answerId).value.trimEnd() : '';
      this.currentRadioAnswerId = answerId.replace('txt', '');
      if (result !== answerText) {
        this.isRadioTextChanged = true;
      } else {
        this.isRadioTextChanged = false;
      }
    },
    checkRadioStatus(resultList, ansId, lineId) {
      let status = false;
      if (resultList.length > 0 && resultList[0].AnswerId === ansId) {
        if (resultList[0].AnswerId === ansId) {
          status = true;
          setTimeout(() => {
            this.checkedRadioAnswerStandard(ansId, lineId);
          }, 1000);
        }
      }
      return status;
    },
    checkRadioAnswer(e, nameId) {
      let formDivId = 'form' + e.currentTarget.name;
      let formDiv = document.getElementById(formDivId);
      if (formDiv) {
        formDiv.style.display = 'none';
      }
      let otherFormDivs = document.getElementsByName(nameId);
      otherFormDivs.forEach((form) => {
        if (form.localName === 'form') {
          form.style.display = 'none';
        }
      });
      let answerObject = {
        AccountabilityLineId: e.currentTarget.name,
        Resultaten: [
          {
            AnswerId: document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id,
            Resultaat: ' '
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    checkSelectAnswer(e) {
      if (e.currentTarget && e.currentTarget.options) {
        let answerObject = {
          AccountabilityLineId: e.currentTarget.name,
          Resultaten: [
            {
              AnswerId: e.currentTarget.options[e.currentTarget.selectedIndex].dataset.answerid,
              Resultaat: '',
              AnswerListItemId: e.currentTarget.options[e.currentTarget.selectedIndex].value
            }
          ]
        };
        this.submitAnswers(answerObject);
      }
    },
    submitTypeQuestionOpen(e) {
      e.preventDefault();
      let answerId = e.currentTarget.elements[0].id;
      answerId = answerId.replace('txt', '');
      if (this.isRadioTextChanged === true) {
        this.isRadioTextChanged = false;
      }
      let answerObject = {
        AccountabilityLineId: e.currentTarget.name,
        Resultaten: [
          {
            AnswerId: answerId,
            Resultaat: e.currentTarget.elements[0].value
          }
        ]
      };
      this.submitAnswers(answerObject);
    },
    async submitAnswers(answerObject) {
      answerObject.ForProductSelection = false;
      await this.setAnswer(answerObject);
      await this.getQuestions(this.accountabilityId);
      if (!this.isLastAnswer) {
        await this.scrollToBottom();
      }
    },
    cancelAccountability() {
      this.cancelAccountabilityForm(this.accountabilityId).then(() => {
        router.push('/patientdashboard');
      });
    },
    getCheckboxStatus(resultArray, checkboxId) {
      let state = false;
      for (let i = 0; i < resultArray.length; i++) {
        if (resultArray[i].AnswerListItemId === checkboxId) {
          state = true;
        }
      }
      return state;
    },
    submitCheckbox(e, answerId) {
      e.preventDefault();
      let result = [];
      for (let i = 0; i < e.currentTarget.elements.length - 1; i++) {
        if (e.currentTarget.elements[i].value === 'true') {
          result.push({
            AnswerListItemId: e.currentTarget.elements[i].id,
            Resultaat: e.currentTarget.elements[i].value,
            AnswerId: answerId
          });
        }
      }
      if (result.length > 0) {
        $('#remark-' + e.currentTarget.name).hide();
        let answerObject = {
          AccountabilityLineId: e.currentTarget.name,
          Resultaten: result
        };
        this.submitAnswers(answerObject);
      } else {
        $('#remark-' + e.currentTarget.name).show();
      }
    },
    getColumnClass(type) {
      if (type === 'accountability') {
        return this.isLastAnswer ? 'col-lg-4' : 'col-lg-8';
      }
      if (type === 'product') {
        return this.isLastAnswer ? 'col-lg-8' : 'col-lg-4';
      }
    },
    // Function to scroll to the bottom of the page
    async scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    },
    input(id, value) {
      id = id.replace('txt', '');
      let btnId = '#' + id + 'btn';
      if (value && value !== '') {
        $(btnId).removeClass('btn--disabled');
      } else {
        $(btnId).addClass('btn--disabled');
      }
    },
    getNextButtonStatus(id) {
      if (id) {
        let value = $(id).val();
        return value === undefined || value === '' || value === null;
      }
      return true;
    },
    getNextRadioButtonStatus(id) {
      if (id) {
        let txtId = id + 'txt';
        let value = document.getElementById(txtId) ? document.getElementById(txtId).value : '';
        return value === undefined || value === '' || value === null;
      }
      return true;
    },
    // Function to handle accountability radio standard type question answer
    checkedRadioAnswerStandard(radioId, nameId) {
      $('.remark').hide();
      let radioButton = document.getElementById(radioId);
      let formDivId = 'form' + radioId;
      let formDiv = document.getElementById(formDivId);
      let otherFormDivs = document.getElementsByName(nameId);
      otherFormDivs.forEach((form) => {
        if (form.localName === 'form') {
          form.style.display = 'none';
        }
      });
      if (radioButton) {
        formDiv.style.display = radioButton.checked ? 'block' : 'none';
      }
    },
    // Function to scroll to the bottom of the page
    async scrollToBottom() {
      $('html, body').animate({ scrollTop: $('.accountability-column-questions').height() }, 'slow');
    },
    // Function to show start date input field
    showStartDate() {
      return this.isLastAnswer && this.isStartDate;
    },
    // Function to handle start date value
    handleStartDate(key, date) {
      this.startDate = date;
      this.setStartDateForTwoColumnAccountability(date);
    },
    customStartDateSubmit() {
      this.isStartDateAnswered = true;
      this.scrollToTop();
    },
    showSuccessMessage() {
      if (this.isStartDate) {
        return this.isLastAnswer && this.isAllowedToProceed && this.isStartDateAnswered;
      }
      return this.isLastAnswer && this.isAllowedToProceed;
    },
    closeModal() {
      this.showValidationPopUp = false;
    },
    signin() {
      if (!this.isLastAnswer) {
        this.showValidationPopUp = true;
      } else {
        router.push('/machtiging-overzicht/' + this.accountabilityId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-animation-enter-active,
.card-animation-leave-active {
  transition: all 0.5s ease;
}
.card-animation-enter,
.card-animation-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.productOverlay {
  position: absolute !important;
  opacity: 1;
  div {
    display: none;
  }
}
.customStartDateSubmit {
  margin-top: 34px;
}
.checkbox-msg {
  display: none;
}
.full-width-header {
  width: 100%;
}
.main__view {
  padding: 0;
}
form.openquestion {
  padding-bottom: 16px;
}
fieldset {
  margin: 0;
  padding: 6px 0 20px;
  border: 0;

  legend {
    font-weight: bold;
    line-height: 1.4;
    color: $gray-darkest;
  }
}
.form-group #menu-down-icon {
  fill: $aqua;
}
:deep(.form-group label) {
  color: $gray-dark;
}
.accountability-column,
.product-column {
  position: relative;
  display: block;

  background-color: $teal-lighter;
  min-height: calc(100vh - 126px);
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding-top: grid-gutter($breakpoint-key);
    }
  }
}
.accountability-column {
  padding-bottom: 100px;
}
.remark {
  font-size: 0.8rem;
  color: $red;
  padding: 0 0 1rem calc(20px + 0.5rem);
}
.product-column {
  background-color: $teal-lightest;
}
.complete {
  margin-left: -25px;
  background: $dark-green;
  color: $white;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  position: fixed;
  &.notpossible {
    background: $yellow;
  }
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding: grid-gutter($breakpoint-key) - 8;
      bottom: grid-gutter($breakpoint-key) - 8;
    }
  }
  .checked {
    display: inline-block;
    margin-right: 10px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out, bottom 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  bottom: -100px;
}
.validation_popup_details {
  font-size: 16px;
  margin: 10px 0px 25px 0px;
  line-height: 1.5;
  color: $gray-darkest;
}

.validation_popup_actions {
  margin-top: 20px;
  .backToBsn {
    border: none !important;
    padding-left: 0px;
  }
}
.greyOut {
  color: $gray;
  background-color: $gray-light;
  border-color: $gray-light;
}
.patient-accountability .overlay > div {
  padding: 0px 25px 20px 25px !important;
}
.customStartDate {
  :deep(.form-group-simple input) {
    padding-left: 35px !important;
    width: 95%;
  }
  :deep(.vdp-datepicker__calendar) {
    position: relative !important;
    margin-bottom: 20px !important;
  }
}
.error {
  font-size: $form-group-details-font-size;
  padding-top: 10px;
  color: $red;
  transition: $form-group-error-transition;
  margin: 0px 0px 0px 25px;
}
</style>
