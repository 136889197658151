<template>
  <div>
    <section>
      <div class="row">
        <img src="@/static/img/ordersuccess.png" class="sucess_image" />
      </div>
      <div class="row success_text">Order is met succes verzonden!</div>
      <div class="row success_subtext">
        {{ orderConfirmationMessage }}
      </div>
      <div class="row success_row question_text">Overzicht van uw bestelling</div>
      <div class="row success_row">
        <v-table id="OrderConfirmationRow">
          <thead>
            <v-header-row :data="headers" :show-sort="false" />
          </thead>
          <tr v-for="(product, index) in productDetails" :key="index" class="order-confirmation-row_data">
            <td class="order-confirmation-row_cell numeric_alignment">
              {{ product.quantity }}
            </td>
            <td class="order-confirmation-row_cell">
              {{ product.name }}
            </td>
            <td class="order-confirmation-row_cell">
              {{ product.productCode }}
            </td>
            <td class="order-confirmation-row_cell">
              {{ moment(product.deliveryDate).format('DD-MM-YYYY') }}
            </td>
          </tr>
        </v-table>
      </div>
      <div class="row success_row">
        {{ orderInformativeMessage }}
      </div>
      <div class="row success_row">
        <div>
          <router-link to="/" class="btn btn--hollow btn--transparent btn--md success_buttons">
            Naar mijn Dashboard
          </router-link>
        </div>
        <div v-if="customerName !== ''">
          <router-link
            v-if="currentRoute !== 'aanvullenmagazijnCheckout'"
            to="/patientdashboard"
            class="btn btn--primary btn--md success_buttons"
          >
            Naar patiëntendashboard
          </router-link>
        </div>
      </div>
      <!-- Todo: 7987 - currently we are hiding the feedback block as per business requirement, we might remove this in coming weeks -->
      <div class="row success_row hidden">
        <div class="col-md-6 research__block">
          <div class="research__block__heading"><v-seach-icon />Wij stellen uw mening op prijs</div>
          <div class="research__block__desc">
            Ons voorschrijfsysteem wordt continue verbeterd. Wij doen dit graag samen met u! Wilt u mee denken in deze
            ontwikkelingen?
          </div>
          <div class="row">
            <v-button
              href="https://mediq.nl/zorgprofessional/gebruiksonderzoek"
              tagelement="a"
              target="_blank"
              class="btn btn--primary btn--md research__block__btns"
            >
              Meld u nu aan
            </v-button>
          </div>
        </div>
      </div>
      <div class="row success_row">
        <div class="col-md-6 medipoint__block">
          <div class="row grid-margin">
            <div class="col-md-8 medipoint__block__heading">Medipoint; thuiszorgwinkel in hulpmiddelen</div>
            <div class="col-md-4 grid-align-end">
              <img class="" alt="" src="@/static/img/medipoint-logo.svg" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              Bent u op zoek naar aanvullende hulpmiddelen? Neem dan even een kijkje bij onze partner: Medipoint
            </div>
          </div>
          <div class="row">
            <div class="medipoint__block__action">
              <v-button
                href="https://www.medipoint.nl/producten/volledig-assortiment.html?utm_campaign=mediq-medipoint&utm_source=mediq&utm_medium=website&utm_content=button-link"
                tagelement="a"
                md
                primary
                target="_blank"
                @click.once="gtmTrackForMediPoint"
              >
                Ga naar Medipoint
              </v-button>
            </div>
            <div class="col justify-content-end">
              <img alt="" src="@/static/img/medipoint-equipments.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="row barWrapper">
        <div class="col colored-bar" />
      </div>
    </section>
  </div>
</template>
<script>
import router from '@/router';
import { fetchQuery } from '@/services/messaging/fetchQuery';
import moment from 'moment';
import VHeaderRow from '@/components/shared/table/rows/HeaderRow';
import VTable from '@/components/shared/table/Table';
import VSeachIcon from 'icons/FeatureSearchOutline';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { shoppingCart$, clearShoppingCart } from '@/services';
export default {
  name: 'VOrderconfirmation',
  components: {
    VHeaderRow,
    VTable,
    VSeachIcon
  },
  setup() {
    const store = useStore();

    const orderConfirmationMessage = computed(() => store.getters['cart/getOrderConfirmationMessage']);
    const orderInformativeMessage = computed(() => store.getters['cart/getOrderInformativeMessage']);

    return {
      orderConfirmationMessage,
      orderInformativeMessage
    };
  },
  data() {
    return {
      headers: ['Aantal', 'Omschrijving', 'Artikelcode', 'Verwachte bezorgdatum'],
      currentRoute: '',
      shoppingCart: shoppingCart$,
      customerName: '',
      deliveryAddress: '',
      productDetails: [],
      departmentName: ''
    };
  },
  created() {
    clearShoppingCart(this.shoppingCart);
    this.currentRoute = router.currentRoute.value.name;
    let orderIds = router.currentRoute.value.query.orderId;
   
    fetchQuery('GetOrders', { orderIds: orderIds }).then((response) => {
      this.customerName = response[0].customerName;
      this.deliveryAddress = response[0].deliveryAddress;
      this.departmentName = response[0].departmentName;
      response.forEach((data) => {
        data.orderLineDetails.forEach((orderLine) => {
          this.productDetails.push(orderLine);
        });
      });
      const ecommerceData = this.getEcommerceData(response);

      window.dataLayer.push({
        event: "purchase",
        ecommerce: ecommerceData
      });
    });
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },
    gtmTrackForMediPoint() {
      if (this.$gtm) {
        this.$gtm.trackEvent({
          event: 'ButtonClick',
          event_category: 'Medipoint',
          event_action: 'ButtonClick'
        });
      }
    },
    getEcommerceData(orders) {
      const ecommerceData = [];
      orders.forEach(order => {
        let orderItems = []
        let ecommerceItem = {};
        ecommerceItem = {
          transaction_id: order.orderId,
          value: 0,
          currency: "EUR",
          items: []
        };

        order.orderLineDetails.forEach(detail => {
          orderItems.push({
            item_id: detail.productId,
            item_name: detail.name,
            item_brand: detail.brandOwner,
            price: 0,
            quantity: detail.quantity
          });
        });
        ecommerceItem.items = orderItems
        ecommerceData.push(ecommerceItem)
      });
      return Object.values(ecommerceData);
    }
  }
};
</script>
<style lang="scss" scoped>
.research__block {
  background: $white;
  padding: 30px;
  font-size: 16px;
  color: $teal-darkest;
  .material-design-icon {
    fill: $teal-darkest;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  &__heading {
    font-size: 25px;
    font-weight: bold;
    padding: 10px 15px 20px 0px;
  }
  &__desc {
    padding: 10px 15px 20px 35px;
  }
  &__detail {
    padding: 10px 15px 20px 30px;
  }
}
.barWrapper {
  padding-left: 5%;
}
.colored-bar {
  background-image: linear-gradient(#fed4da, #fed4da), linear-gradient(#38cbce, #38cbce),
    linear-gradient(#424e66, #424e66), linear-gradient(#ffb212, #ffb212), linear-gradient(#ed0c0c, #ed0c0c);
  background-repeat: no-repeat;
  background-size: 8% 100%, 16% 100%, 24% 100%, 32% 100%, 47.38% 100%;
  height: 8px;
}
.medipoint__block {
  background: $white;
  padding: 24px;
  color: $teal-darkest;
  &__heading {
    font-size: 20px;
    font-weight: bold;
  }
  img {
    max-width: 140px;
  }
  &__action {
    align-self: end;
  }
}
.sucess_image {
  height: 35vh;
  width: 100%;
  object-fit: cover;
  margin-top: -35px;
}
.success_buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}
.success_text {
  color: $teal-darkest;
  font-size: 25px;
  //position: fixed;
  font-weight: bold;
  padding-top: 3%;
  padding-left: 5%;
  //top: 25%;
  //left: 25%;
}
.success_subtext {
  color: $teal-darkest;
  font-size: 15px;
  padding-top: 1%;
  padding-left: 5%;
}
.success_row {
  padding-right: 5%;
  justify-content: left;
  padding-left: 5%;
  padding-top: 3%;
}
.question_text {
  color: $teal-darkest;
  font-size: 20px;
  font-weight: normal;
}
.order-confirmation-row_cell {
  color: $table-row-cell-color;
  font-size: $table-row-cell-font-size;
  line-height: $table-row-cell-line-height;
  justify-content: $table-row-cell-justify-content;
  padding: $table-row-license-cell-padding;
  vertical-align: $table-row-license-cell-vertical-align;
}
.order-confirmation-row_data {
  border: 1px solid $gray-light;
  background-color: $white;
}
.numeric_alignment {
  text-align: center;
}
</style>
