<template>
  <div>
    <div id="productVariants" class="product-description">
      <h1>
        <span v-if="baseProductDetail.manufName">{{ title }} - {{ baseProductDetail.manufName }}</span>
        <span v-else>{{ title }}</span>
      </h1>
      <div>
        <v-product-variants
          v-if="baseProductDetail.products && baseProductDetail.products.length > 0"
          :product-variants="baseProductDetail.products"
          :base-product="baseProductDetail"
          @order="order"
          @favourite-success="computeBaseProductDetails"
        />
      </div>
      <template v-if="baseProductDetail.products && baseProductDetail.products.length === 0">
        <v-warning-message
          :class="{ 'zoomOut animated': animated }"
          :warning="'Het is nog niet mogelijk om dit product te bestellen. Gelieve een ander product uit de catalogus te kiezen.'"
          :html="true"
          :is-urgent="false"
        />
      </template>
    </div>
    <div v-if="isPrompt" class="product-description__overlay_div">
      <div class="product-description__overlay_box">
        <h3 class="product-description__closeOverlay" @click="closeOverlay()">X</h3>
        <div class="product-description__v_overlay">
          <div class="row grid-margin">
            <div class="col product-description__overlay_title">Product valt niet binnen de machtiging(en).</div>
          </div>
          <div class="row grid-margin">
            <div class="col product-description__overlay_text">
              Zonder machtiging ontvangt de patiënt geen vergoeding van de zorgverzekeraar. Weet u zeker dat u wilt
              doorgaan?
            </div>
          </div>
          <div class="row grid-margin">
            <div class="col-xs-6">
              <v-button
                class="btn--hollow product-description__noBorder product-description__continue_button"
                @click.prevent="continueAddProduct"
              >
                Toch bestellen
              </v-button>
            </div>
            <div class="col-xs-2">&nbsp;</div>
            <div class="col-xs-4">
              <router-link to="/patientdashboard" class="btn btn--secondary product-description__dashboard_button">
                Machtiging aanmaken
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="product-description__invisible_div" @click="closeOverlay()" />
    </div>
  </div>
</template>

<script>
import VProductVariants from './ProductVariants';
import VWarningMessage from '@/components/shared/WarningMessage';
import router from '@/router';
import { addToShoppingCart, shoppingCart$, activePpwRecId$ } from '@/services';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ProductDescription',
  components: {
    VProductVariants,
    VWarningMessage
  },
  props: {
    title: String,
    productId: String,
    sapId: String,
    shortDescription: String,
    productUnit: String,
    baseProductDetail: Object
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);

    const setOrderSuccessStatus = (value) => store.commit('catalog/setOrderSuccessStatus', value);
    const setOrderDetails = (value) => store.commit('catalog/setOrderDetails', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
    });

    activePpwRecId$.subscribe((value) => {
      activePpwRecId.value = value;
    });

    return {
      setOrderSuccessStatus,
      setOrderDetails,
      shoppingCart,
      activePpwRecId
    };
  },
  data() {
    return {
      isPrompt: false,
      currentOrderDetails: {}
    };
  },
  computed: {
    isReplenishmentFlow() {
      return router.currentRoute.value.meta.flow === 'replenishment';
    },
    isAssortmentFlow() {
      return router.currentRoute.value.meta.flow === 'assortment';
    },
    isCustomerFlow() {
      return router.currentRoute.value.meta.flow === 'customer';
    }
  },
  methods: {
    // Function to show an alert message
    showAlert(productId) {
      this.setOrderSuccessStatus(true);
      let orderedProduct = this.baseProductDetail.products.find((x) => x.productId === productId);
      this.setOrderDetails(orderedProduct);
      setTimeout(() => {
        this.setOrderSuccessStatus(false);
        this.setOrderDetails({});
      }, 2500);
    },
    // Function to add product to the cart
    order({ productId, count }) {
      let order = this.baseProductDetail;
      if (order) {
        let isInsuredProd =
          this.baseProductDetail.products.filter((x) => x.productId === productId && x.isInsured === true).length > 0;
        if (!isInsuredProd && !this.isReplenishmentFlow) {
          this.isPrompt = true;
          this.currentOrderDetails = {
            productId: productId,
            amount: count
          };
        } else {
          this.isPrompt = false;
          order.isInsured = isInsuredProd;
          order.addedToBasket = true;
          order.count = this.count;
          addToShoppingCart(this.shoppingCart, productId, count, this.activePpwRecId);
          this.showAlert(productId);
        }
      }
    },
    continueAddProduct() {
      this.isPrompt = false;
      addToShoppingCart(
        this.shoppingCart,
        this.currentOrderDetails.productId,
        this.currentOrderDetails.amount,
        this.activePpwRecId
      );
      this.showAlert(this.currentOrderDetails.productId);
      this.currentOrderDetails = {};
    },
    closeOverlay() {
      this.isPrompt = false;
    },
    computeBaseProductDetails() {
      this.$emit('favouriteSuccess');
    }
  }
};
</script>

<style lang="scss" scoped>
.product-description {
  > h1 {
    display: flex;
    font-size: 20px;
    color: $teal-darkest;
    align-items: center;
    font-weight: 300;
  }
  strong {
    display: flex;
    align-items: center;
    align-self: flex-start;
    border-bottom: 1px solid $red;
    margin-bottom: 10px;
  }
  > div {
    display: flex;
    margin-bottom: 10px;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  table {
    display: block;
    margin-left: 20px;
    td:first-child {
      font-weight: bold;
    }
    td:last-child {
      padding-left: 10px;
    }
  }
  p {
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 0px !important;
  }
  &__v_overlay {
    width: 460px;
  }
  &__noBorder {
    border: none;
    margin: 5px 0px 0px 0px;
  }
  &__dashboard_button {
    width: 220px;
    height: 40px;
    border-radius: 3px;
    background-color: $teal-darkest;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }
  &__continue_button {
    width: 150px;
    height: 30px;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $cyan;
  }
  &__overlay_title {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $cyan;
    font-weight: 300;
  }
  &__overlay_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $gray-darkest;
  }
  &__closeOverlay {
    float: right;
    margin: -5px 0px 0px 0px;
    cursor: pointer;
  }
  &__overlay_div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999 !important;
  }
  &__overlay_box {
    position: relative;
    z-index: 99999998 !important;
    text-align: left;
    padding: 2rem;
    background: $white;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
  }
  &__invisible_div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
