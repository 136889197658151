<template>
  <form class="product-favourites" @submit.prevent="saveFavourites">
    <div class="row grid-margin">
      <div class="col-md-12">
        <h2>{{ computedHeading }}</h2>
      </div>
    </div>
    <div v-if="showNewFavourite !== null">
      <div v-if="showNewFavourite" class="row grid-margin">
        <div class="col-md-12">
          <m-input
            type="text"
            label="Favorietenlijst naam"
            position="top"
            placeholder="Nieuwe favorietenlijst"
            :value="newBundleName"
            :model="newBundleName"
            :required="showNewFavourite ? true : false"
            :invalid="validationStatus"
            error-message="Dit veld is verplicht."
            @input="computeBundleName($event.target.value)"
          />
        </div>
      </div>
      <div v-else class="row grid-margin">
        <div class="col-md-8">
          <m-select
            id="favoriteCategories"
            name="favoriteCategories"
            label="Selecteer hier je favorieten lijst"
            position="top"
            :required="showNewFavourite ? false : true"
            :value="selectedFavouriteId"
            :options="computedFavoriteCategories"
            :invalid="validationStatus"
            error-message="Dit veld is verplicht."
            @change-option="updateSelectedFavourite"
          />
        </div>
        <div class="col product-favourites__button">
          <v-button lg primary icon-left @click="toggleShowNewFavourite">
            <v-plus-circle-icon />
            Maak nieuwe lijst
          </v-button>
        </div>
      </div>
    </div>
    <div class="row grid-margin">
      <div class="col-md-12">
        <table>
          <colgroup>
            <col v-for="n in 1" :key="n" :class="'table-col-' + n++" />
          </colgroup>
          <thead>
            <tr>
              <th>Artikel</th>
              <th>Aantal</th>
              <th>Beschrijving</th>
              <th>Verpakking</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="computedProductImageUrl">
                <div class="product-favourites__imgWrapper">
                  <img :src="computedProductImageUrl" />
                </div>
              </td>
              <td v-else />
              <td class="product-favourites__count">
                <div class="product-favourites__count__quantity">
                  <v-minus class="product-favourites__count__icon" @click.prevent="reduceQuantity()" />
                  <input v-model="productQuantity" type="number" min="1" max="999" maxlength="3" autocomplete="off" />
                  <v-plus
                    class="product-favourites__count__icon product-favourites__count__plus"
                    @click.prevent="increaseQuantity()"
                  />
                </div>
              </td>
              <td>{{ product.title }}</td>
              <td>{{ product.packagingUnit }} {{ product.packagingLabel }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row grid-padding grid-justify-content-end">
      <v-button md primary hollow transparent @click="closeOverlay"> Annuleren </v-button>
      <v-button md cta :disabled="showNewFavourite === null"> Opslaan </v-button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import VPlus from 'icons/Plus';
import VMinus from 'icons/Minus';
import VPlusCircleIcon from 'icons/PlusCircle';
import { useStore } from 'vuex';

export default {
  name: 'VProductFavourites',
  components: {
    VPlus,
    VMinus,
    VPlusCircleIcon
  },
  props: {
    product: Object,
    baseProduct: Object
  },
  setup() {
    const store = useStore();

    const fetchFavourites = () => store.dispatch('catalog/fetchFavourites');

    return {
      fetchFavourites
    };
  },
  data() {
    return {
      favoriteCategories: [],
      newBundleName: null,
      productQuantity: 1,
      showNewFavourite: null,
      validationStatus: false,
      selectedFavouriteId: null
    };
  },
  computed: {
    computedProductImageUrl() {
      return this.product.imageUrl
        ? this.product.imageUrl
        : this.baseProduct.thumbnailImageUrl
        ? this.baseProduct.thumbnailImageUrl
        : null;
    },
    computedHeading() {
      return this.showNewFavourite ? 'Maak nieuwe lijst' : 'Toevoegen aan favorietenlijst';
    },
    computedFavoriteCategories() {
      let result = [];
      this.favoriteCategories.forEach((favourite) => {
        result.push({
          id: favourite.bundleId,
          value: favourite.name
        });
      });
      return result;
    }
  },
  mounted() {
    axios.post('Catalogue/GetFavourites', { filterBy: null }).then((response) => {
      if (response && response.data) {
        this.favoriteCategories = response.data;
        if (this.favoriteCategories.length > 0) {
          this.showNewFavourite = false;
          this.updateSelectedFavourite({
            id: this.favoriteCategories[0].bundleId,
            value: this.favoriteCategories[0].name
          });
        } else {
          this.showNewFavourite = true;
        }
      }
    });
  },
  methods: {
    computeValidationStatus() {
      const selectedCategories = this.favoriteCategories.filter((f) => f.isFavorite === true);
      if (
        (!this.newBundleName && selectedCategories.length === 0) ||
        (this.showNewFavourite && !this.newBundleName) ||
        (!this.showNewFavourite && selectedCategories.length === 0)
      ) {
        this.validationStatus = true;
      } else {
        this.validationStatus = false;
      }
      return this.validationStatus;
    },
    saveFavourites(e) {
      e.stopPropagation();
      if (this.computeValidationStatus()) {
        return;
      }
      if (this.showNewFavourite) {
        axios
          .post('FavouriteProduct/CreateFavouriteListWithProducts', {
            name: this.newBundleName,
            favouriteProducts: [
              {
                productId: this.product.productId,
                quantity: this.productQuantity
              }
            ]
          })
          .then((response) => {
            if (response && response.data) {
              this.resetFavourites();
            }
          });
      } else {
        axios
          .post('FavouriteProduct/AddProductToFavouriteList', {
            bundleId: this.selectedFavouriteId,
            productId: this.product.productId,
            quantity: this.productQuantity
          })
          .then((response) => {
            if (response && response.data) {
              this.resetFavourites();
            }
          });
      }
    },
    resetFavourites() {
      const selectedCategories = this.favoriteCategories.filter((f) => f.isFavorite === true);
      const isAdded = selectedCategories.length > 0 || (this.newBundleName && this.newBundleName.length > 0);
      this.fetchFavourites();
      this.$emit('success', isAdded);
    },
    /**
     * @function reduceQuantity
     */
    reduceQuantity() {
      if (this.productQuantity === 1) {
        return;
      }
      this.productQuantity -= 1;
    },
    /**
     * @function increaseQuantity
     */
    increaseQuantity() {
      if (this.productQuantity === 999) {
        return;
      }
      this.productQuantity += 1;
    },
    computeBundleName(value) {
      this.newBundleName = value;
      this.computeValidationStatus();
    },
    closeOverlay() {
      this.$emit('cancel');
    },
    updateSelectedFavourite(value) {
      this.favoriteCategories.forEach((favourite) => {
        if (favourite.bundleId === value.id) {
          favourite.isFavorite = true;
          this.selectedFavouriteId = value.id.toString();
        } else {
          favourite.isFavorite = false;
        }
      });
    },
    toggleShowNewFavourite() {
      this.showNewFavourite = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.product-favourites {
  color: $teal-darkest;
  table {
    border-collapse: collapse;
    thead {
      td,
      th {
        padding: $table-row-license-cell-padding;
        border-bottom: 1px solid $grayish-very-dark;
      }
    }
    tbody {
      td {
        padding: $table-row-license-cell-padding;
      }
    }
  }
  h2 {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__imgWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    img {
      max-height: 80px;
      max-width: 80px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }
  &__count {
    &__quantity {
      border: 1px solid $gray;
      height: 31px;
      border-radius: 3px;
      width: 90px;
    }
    width: 120px;
    padding: 20px 20px 20px 0px;
    &__icon {
      fill: $teal-darkest;
      float: left;
      padding-top: 2px;
      cursor: pointer;
    }
    &__plus {
      text-align: right;
    }
    input {
      max-width: 35px;
      border-right: 1px solid $gray;
      border-left: 1px solid $gray;
      border-top: none;
      border-bottom: none;
      text-align: center;
      height: 29px;
      float: left;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  &__button {
    justify-content: end;
  }
}
</style>
