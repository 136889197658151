<template>
  <div
    id="catalogProducts"
    class="products"
  >
    <div
      v-if="!topPage"
      class="products__scrollTop"
      @click.prevent="scrollToTop"
    >
      <v-scroll-top-icon />
    </div>
    <legend class="products__Title">
      Producten
    </legend>
    <transition-group
      class="products__list"
      tag="ul"
      name="transition-up-down"
    >
      <v-product
        v-for="baseProduct in computedArray"
        :key="baseProduct.baseProductId"
        :base-product="baseProduct"
      />
    </transition-group>
  </div>
</template>

<script>
import VProduct from './Product'
import VScrollTopIcon from 'icons/ArrowUpBoldBox'

export default {
  name: 'VProducts',
  components: {
    VProduct,
    VScrollTopIcon
  },
  props: {
    baseProducts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      bottom: false,
      topPage: false
    }
  },
  computed: {
    computedArray: function () {
      return this.baseProducts;
    }
  },
  watch: {
    bottom (bottom) {
      if (bottom) {
        this.$emit('loadMore')
      }
    }
  },
  created () {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
      this.topPage = this.topVisible()
    })
  },
  methods: {
    topVisible () {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      return scrollY === 0
    },
    bottomVisible () {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = Math.ceil(visible + scrollY) >= Math.ceil(pageHeight)
      return bottomOfPage
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  &__scrollTop {
    bottom: 0;
    right: 0;
    position: fixed;
    cursor: pointer;
    .material-design-icon {
      fill: $aqua;
      :deep(svg) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__Title {
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $teal-darkest;
    margin: 0px 0px 15px 0px;
  }
}
</style>
