<template>
  <section class="orderSuccessMessage">
    <div class="orderSuccessMessage__message">
      <table
        class="orderSuccessMessage__message__table"
        :class="{ orderSuccessMessage__message__customWidth: !cartOpenStatus }"
      >
        <tbody>
          <tr>
            <td><v-active-icon /></td>
            <td>1X</td>
            <td class="orderSuccessMessage__message__title">
              {{ orderDetails.title }}&nbsp;&nbsp; <span>is toegevoegd aan bestellijst.</span>
            </td>
            <td class="orderSuccessMessage__message__article">Art. nr: .: {{ orderDetails.productCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import VActiveIcon from 'icons/CheckCircle';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VOrderSuccessMessage',
  components: {
    VActiveIcon
  },
  setup() {
    const store = useStore();

    const orderDetails = computed(() => store.getters['catalog/getOrderDetails']);
    const cartOpenStatus = computed(() => store.getters['cart/getCartOpenStatus']);

    return {
      orderDetails,
      cartOpenStatus
    };
  }
};
</script>

<style lang="scss" scoped>
.orderSuccessMessage {
  font-size: 18px;
  font-weight: bold;
  color: $teal-darkest;
  span {
    color: $teal-darkest;
  }
  .material-design-icon {
    fill: $teal-darkest;
    display: inline-block;
  }
  // Give the same padding to the button as the PatientHeader.vue
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include max-screen(grid-breakpoint(sm)) {
      top: 3%;
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    background: $white;
    position: sticky;
    z-index: 999;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 10px;
    border-bottom: 20px solid $white;
    border-left: 20px solid $white;
    border-image-repeat: round;
    border-image-width: 0px 0px 20px 20px;
    background-clip: padding-box;
    margin-left: -1px;
    box-shadow: 0 0 5px;
    &__title {
      color: $aqua;
    }
    &__article {
      color: $gray-darkest;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -19px;
      height: 10px;
      background: $teal-darkest;

      @include min-screen(grid-breakpoint(sm)) {
        width: 102%;
      }
    }

    &__customWidth {
      width: 90% !important;
      max-width: 90% !important;
    }

    &__table {
      width: 60%;
      max-width: 60%;
      border-collapse: collapse;
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
</style>
