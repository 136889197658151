<template>
  <div class="topNavigation">
    <p-navigation
      @item-click="onItemClick"
      :menu="menuItems"
      :dropdown="dropDownOptions"
      :icons="iconMenuItems"
      :profile="profileMenuItems"
    ></p-navigation>
  </div>
</template>
<script>
import router from '@/router';
import { pushDepartmentChangedEvent } from './departmentChangedEventStream';
import { availableDepartments$, activeDepartment$, left$ } from '@/services';
import { tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { formLogout, currentUserIdentity$, availablePortals$, pushActivePortalCode } from '@/services';
import PNavigation from '@/components/shared/webcomponents/PrescriberDesignSystem/Navigation';
import { ref, onMounted } from 'vue';
import { useStore, mapActions } from 'vuex';
export default {
  name: 'TopNavigation',
  components: {
    PNavigation
  },
  setup() {
    const profileMenuItems = [
      { title: 'Mijn gegevens', href: '/gegevens' },
      { title: 'Log out', href: '/logout', type: 'Logout' }
    ];
    const store = useStore();
    const updateResults = (value) => store.dispatch('navigation/updateResults', value);
    const getFeedbackConfiguration = () => store.dispatch('feedback/getFeedbackConfiguration');
    const selectedDepartmentChange = (value) => store.dispatch('login/selectedDepartmentChange', value);
    const fetchDepartmentResults = () => store.dispatch('login/fetchDepartmentResults');
    const fetchResults = () => store.dispatch('navigation/fetchResults');
    const setPrescriberChatIframe = (value) => store.commit('prescriber/setPrescriberChatIframe', value);
    // Define reactive variables
    const menuItems = ref(null);
    const iconMenuItems = ref([]);
    const dropDownOptions = ref(null);
    const availableDepartments = ref(null);
    const activeDepartment = ref(null);
    const availablePortals = ref(null);
    const user = ref(null);
    const results = ref(null);
    const initNavigation = (results) => {
      menuItems.value =
        results.navigationItems.find((navigationItem) => navigationItem.type === 'Dashboard')?.childNodes || [];
      menuItems.value.forEach((obj) => {
        obj.href = obj.relativeUri;
      });
      // Check if navigation items with type 'AboutMediq' exist
      const aboutMediqItems = results.navigationItems.find((item) => item.type === 'AboutMediq');
      const aboutMediqOptions = aboutMediqItems ? aboutMediqItems.childNodes : [];
      // Check if navigation items with type 'ServiceAndContact' exist
      const serviceAndContactItems = results.navigationItems.find((item) => item.type === 'ServiceAndContact');
      const serviceAndContactHref = serviceAndContactItems ? serviceAndContactItems.relativeUri : '';
      // Push items to iconMenuItems.value
      if (aboutMediqItems) {
        iconMenuItems.value.push({
          title: 'Information',
          icon: 'InformationCircleIcon',
          type: 'AboutMediq',
          options: aboutMediqOptions
        });
      }
      if (serviceAndContactItems) {
        iconMenuItems.value.push({
          title: 'Service en Contacten',
          icon: 'QuestionMarkCircleIcon',
          type: 'ServiceAndContact',
          href: serviceAndContactHref
        });
      }
      const giveYourOpinionItems = results.navigationItems.filter((item) => item.type === 'GiveYourOpinion');
      const pienItems = results.navigationItems.filter((item) => item.type === 'Pien');
      iconMenuItems.value.find((navigationItem) => {
        if (navigationItem.type === 'AboutMediq') {
          navigationItem.options.push(...giveYourOpinionItems, ...pienItems);
          navigationItem.options.forEach((obj) => {
            obj.href = obj.relativeUri;
          });
        }
      });
      const instititionIds = availableDepartments.value.map(({ ppwRecId, name }) => ({
        ppwRecId: ppwRecId,
        title: name
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }));
      const addDepartmentSelector = instititionIds.map((id) => ({ ...id, id: 'department-selector' }));
      let institutionObject = {
        title: instititionIds.length > 0 ? instititionIds[0].title : '',
        child: addDepartmentSelector,
        class: 'department-items',
        icon: {
          element: 'img',
          attributes: {
            src: `/icons/building.svg`
          }
        }
      };
      let departmentArray = institutionObject.child;
      const selectedNavigationDepartment = departmentArray.find(
        (childItem) => childItem.ppwRecId === activeDepartment.value.ppwRecId
      );
      // Remove selected department from current index
      departmentArray.filter((item) => item !== selectedNavigationDepartment);
      departmentArray.forEach((obj) => {
        obj.current = obj.ppwRecId === selectedNavigationDepartment.ppwRecId ? true : false;
      });
      dropDownOptions.value = departmentArray;
    };
    // Fetch initial values
    const fetchInitialValues = () => {
      availableDepartments$.subscribe((value) => {
        availableDepartments.value = value;
      });
      activeDepartment$.subscribe((value) => {
        activeDepartment.value = value;
      });
      availablePortals$.subscribe((value) => {
        availablePortals.value = value;
      });
      currentUserIdentity$.subscribe((value) => {
        user.value = value;
      });
      const combinedStream = combineLatest([currentUserIdentity$, left$, availablePortals$]);
      combinedStream
        .pipe(
          tap((data) => {
            updateResults(data[1]).then(() => {
              const navdata = { ...data[1], user: data[0], availablePortals: data[2] };
              initNavigation(navdata);
            });
          })
        )
        .subscribe();
    };
    // Call fetchInitialValues when component is mounted
    onMounted(fetchInitialValues);
    return {
      menuItems,
      iconMenuItems,
      dropDownOptions,
      profileMenuItems,
      updateResults,
      getFeedbackConfiguration,
      selectedDepartmentChange,
      fetchDepartmentResults,
      fetchResults,
      setPrescriberChatIframe,
      availableDepartments,
      activeDepartment,
      availablePortals,
      user,
      results,
      ...mapActions('navigation', ['fetchResults']),
      ...mapActions('login', ['logOut', 'selectedDepartmentChange', 'fetchDepartmentResults']),
      initNavigation
    };
  },
  mounted() {
    this.getFeedbackConfiguration();
  },
  methods: {
    onItemClick(item) {
      if (!item) {
        return;
      }
      if (item.id === 'department-selector') {
        this.iconMenuItems = [];
        this.selectedDepartmentChange(item.ppwRecId).then(() => {
          router.push('/');
          this.fetchDepartmentResults();
          this.fetchResults();
          pushDepartmentChangedEvent({});
        });
      }
      if (item.type === 'Logout') {
        formLogout().then((r) => {
          this.setPrescriberChatIframe(false);
          window.location.replace('/?' + Math.random());
        });
      }
      if (item.type === 'GiveYourOpinion') {
        this.$emitter.emit('showFeedbackForm');
        return;
      }
      if (item.type === 'Pien') {
        window.open(item.href); // Added for E40-7629
        return;
      }
      if (item.type === 'SSO') {
        router.push('/');
        window.open(item.href, '_blank');
      }
      if (item.href === null || item.href === undefined) {
        router.push('/');
      } else {
        router.push(item.href);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.topNavigation {
  @import '@mds/prescriber-design-system/dist/pds-tailwind.css';
}
</style>
