<template>
  <tr class="order">
    <td class="order__count">
      <div class="order__count__quantity">
        <v-minus
          class="order__count__icon"
          @click.prevent="reduceQuantity()"
        />
        <input
          v-model="data.quantity"
          type="number"
          min="1"
          max="999"
          maxlength="3"
          autocomplete="off"
          @change="saveProductQuantity(data.quantity)"
        >
        <v-plus
          class="order__count__icon order__count__plus"
          @click.prevent="increaseQuantity()"
        />
      </div>
    </td>
    <td>
      <a
        href=""
        @click.prevent="goToPath(data.productId)"
      >{{ data.productTitle.toLowerCase() }}</a>
    </td>
    <td>Art. nr.: {{ data.productId }}</td>
    <td>
      <button
        class="order__delete"
        @click="removeOrder(data.cartId)"
      >
        <v-delete-icon
          title="Verwijder"
          class="order__icon"
        />
      </button>
    </td>
  </tr>
</template>

<script>
import router from '@/router'
import VPlus from 'icons/Plus'
import VMinus from 'icons/Minus'
import VDeleteIcon from 'icons/Delete'
import {
  shoppingCart$,
  updateShoppingCartItem,
  activePpwRecId$,
  assortmentFeatureEnabled$
} from '@/services';
import { ref } from 'vue';

export default {
  name: 'Order',
  components: {
    VDeleteIcon,
    VPlus,
    VMinus
  },
  props: {
    data: Object
  },
  setup() {
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);
    const assortmentFeatureEnabled = ref(null);

    shoppingCart$.subscribe(value => {
      shoppingCart.value = value;
    });

    activePpwRecId$.subscribe(value => {
      activePpwRecId.value = value;
    });

    assortmentFeatureEnabled$.subscribe(value => {
      assortmentFeatureEnabled.value = value;
    });

    return {
      shoppingCart,
      activePpwRecId,
      assortmentFeatureEnabled
    };
  },
  data () {
    return {
      lastValidQuantity: 0
    };
  },
  watch: {
    data: {
      handler: function(newValue) {
        this.lastValidQuantity = newValue.quantity;
      },
      immediate: true
    }
  },
  methods: {
    // Function to remove order
    removeOrder (cartId) {
      this.$emit('removeOrder', cartId)
    },
    // Function to redirect user to the specific product detail page
    goToPath (productId) {
      if (!this.assortmentFeatureEnabled) {
        router.push('/catalog/product/' + productId)
      } else {
        router.push('/aanvullenmagazijn/product/' + productId)
      }
    },
    /**
     * @function saveProductQuantity
     * @description Function to add new product quantity
     */
    saveProductQuantity (inputValue) {
      let regex = /^[0-9]{1,3}$/
      let quantity = parseInt(inputValue, 10)
      if(quantity <= 0) {
        quantity = 1;
      }
      if(quantity > 999) {
        quantity = 999;
      }
      if (!regex.test(quantity) || !quantity || quantity <= 0) {
        this.data.quantity = this.lastValidQuantity;
        return
      }
      this.lastValidQuantity = quantity;
      this.updateProductQuantity(quantity)
    },
    // Function to update product quantity
    updateProductQuantity (quantity) {
      this.data.quantity = quantity;
      updateShoppingCartItem(this.shoppingCart, this.data, this.activePpwRecId);
    },
    /**
     * @function reduceQuantity
     */
    reduceQuantity () {
      if (this.data.quantity === 1) {
        return
      }
      this.saveProductQuantity(this.data.quantity - 1)
    },
    /**
     * @function increaseQuantity
     */
    increaseQuantity () {
      if (this.data.quantity === 999) {
        return
      }
      this.saveProductQuantity(this.data.quantity + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  border-bottom: 1px solid $gray;
  > td {
    padding-top: 20px;
    padding-bottom: 10px;
    vertical-align: middle;
    white-space: normal;
    text-decoration: none;
    text-transform: capitalize;
  }
  a {
    text-decoration: none;
  }
  &__count {
    &__quantity {
      border: 1px solid $gray;
      height: 31px;
      border-radius: 3px;
      width: 90px;
    }
    width: 120px;
    padding: 20px 20px 20px 0px;
    &__icon {
      fill: $teal-darkest;
      float: left;
      padding-top: 2px;
      cursor: pointer;
    }
    &__plus {
      text-align: right;
    }
    input {
      max-width: 35px;
      border-right: 1px solid $gray;
      border-left: 1px solid $gray;
      border-top: none;
      border-bottom: none;
      text-align: center;
      height: 29px;
      float: left;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
  &__delete {
    width: 30px;
    border: none;
    background: transparent;
    margin-top: -6px;
  }
  &__icon {
    fill: $teal-darkest;
    margin-right: -20px;
    cursor: pointer;
  }
}
</style>
