import { fetchQuery } from '@/services/messaging/fetchQuery';
import { activeCustomerId$ } from '@/services/activeCustomer$';
import { activePpwRecId$ } from '@/services/activePpwRecId$';
import {
  combineLatest,
  from,
  of
} from "rxjs";
import {
  publishReplay,
  switchMap,
  refCount,
  publishBehavior,
  mergeAll,
  startWith
} from "rxjs/operators";
import {
  transferredCustomerEvent$,
  transferredCustomerWithdrawnEvent$
} from '@/services/crm/events$'

export function getCustomerSecurityPolicySettings({ customerId, ppwRecId }) {
  return fetchQuery("GetCustomerSecurityPolicySettings", { customerId, ppwRecId });
}


const reloadCustomerSecurityPolicySettings$ = of(
  transferredCustomerEvent$,
  transferredCustomerWithdrawnEvent$
).pipe(
  mergeAll()
);


export const customerSecurityPolicySettings$ = combineLatest([activeCustomerId$, activePpwRecId$]).pipe(
    switchMap(v => {
      const customerId = v[0];
      const ppwRecId = v[1];

      return reloadCustomerSecurityPolicySettings$.pipe(
        startWith(null),
        switchMap(() => from(getCustomerSecurityPolicySettings({ customerId, ppwRecId }))),
        publishBehavior(null),
        refCount()
      );
    }),
    publishReplay(1),
    refCount()
  );
