import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, refCount, publishReplay } from 'rxjs/operators';
import axios from "axios";

var storeKey = 'lastSelectedCustomer';
const lastStored = window.localStorage.getItem(storeKey);
setAxiosCustomerId(lastStored);
const activeCustomerIdSubject$ = new ReplaySubject(1);
activeCustomerIdSubject$.next(lastStored)

// Exported helper method to push event
export function pushActiveCustomerId (customerId) {
  window.localStorage.setItem(storeKey, customerId);
  setAxiosCustomerId(customerId);
  activeCustomerIdSubject$.next(customerId);
}

// Exported observables
export const activeCustomerId$ = activeCustomerIdSubject$.pipe(
  distinctUntilChanged(),
  publishReplay(1),
  refCount()
).asObservable();

// trick: keep it until we made all api calls accept the customerId as normal argument
function setAxiosCustomerId(customerId) {
  axios.defaults.headers.common["customerid"] = customerId;
}
