<template>
  <div class="patientRecentOrders">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <v-heading :level="3">
          Eerder besteld
        </v-heading>
      </div>
    </div>
    <div
      v-if="recentOrderHistory.length === 0"
      class="row grid-align-center grid-padding callout"
    >
      <div class="col-xs-8">
        Er zijn geen recente bestellingen geplaatst voor deze patiënt
      </div>
    </div>
    <div v-else>
      <table>
        <thead>
          <v-header-row :data="orderHeader" />
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in recentOrder"
            :key="index"
          >
            <td>{{ item.Quantity }}</td>
            <td v-if="item.Orderable">
              <a
                class="patientRecentOrders__link"
                href=""
                @click.prevent="goToProductDetailPage(item.BaseProductId)"
              >{{ item.Name }}</a>
            </td>
            <td
              v-else
              class="patientRecentOrders__customStrikeText"
            >
              <a
                class="patientRecentOrders__link"
                href=""
                @click.prevent="goToProductDetailPage(item.BaseProductId)"
              >{{ item.Name }}</a>
            </td>
            <td>{{ item.ProductCode }}</td>
            <td class="patientRecentOrders__icon">
              <v-clipboard-alert-icon
                v-if="!item.Insured"
                class="not-insured"
                title="Niet vergoed"
              /><v-clipboard-check-icon
                v-if="item.Insured"
                class="is-insured"
                title="Vergoed"
              />
            </td>
            <td>{{ moment(item.OrderDate).format('DD-MM-YYYY') }}</td>
            <td
              v-if="!patientGegevens.IsDeceased && !patientGegevens.IsArchived"
              class="justify-content-end"
            >
              <v-button
                v-if="(isInfusion === false || (!(isInfusion && item.ProductId.startsWith('17')))) && item.Orderable && isActiveLicense"
                md
                cta
                @click.prevent="order(item.ProductId, count)"
              >
                Bestellen
              </v-button>
              <span v-else-if="!item.Orderable">Niet bestelbaar</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="recentOrderHistory.length > filterCountDefault"
      class="row grid-justify-content-center"
    >
      <div class="col-xs-3 col-md-3">
        <v-button
          hollow
          md
          class="filter-count-button"
          @click="toggleFilterCount"
        >
          <template v-if="!filterCount">
            {{ filterCountButtonTextOptions.less }}
          </template>
          <template v-if="filterCount > 0">
            {{ filterCountButtonTextOptions.more }}&nbsp;
            <span v-if="recentOrderHistory.length > filterCountDefault">({{ recentOrderHistory.length }})</span>
          </template>
        </v-button>
      </div>
    </div>
    <!--Pop up to show order success with navigation buttons -->
    <generic-modal
      v-if="showPopUp"
      :has-close-button="true"
      @close="closeModal"
    >
      <template #header>
        Product is toegevoegd aan bestellijst.
      </template>
      <template #body>
        <p>Wilt u naar de catalogus om meer producten toe te voegen? Of ga naar de checkout om uw bestelling te versturen.</p>
        <v-warning-message
          v-if="!getProductInsuredStatus()"
          class="warning-message"
          warning="Let op!"
          message="Dit product wordt niet vergoed."
        />
      </template>
      <template #footer>
        <v-button
          tagelement="a"
          hollow
          md
          transparent
          @click.prevent="$router.push('/catalog')"
        >
          Ga naar catalogus
        </v-button>
        <v-button
          tagelement="a"
          md
          primary
          @click.prevent="$router.push('/bestellijst')"
        >
          Ga naar checkout
        </v-button>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import router from '@/router'
import { mapActions, useStore } from 'vuex'
import { computed, ref } from 'vue'
import VHeaderRow from '@/components/shared/table/rows/HeaderRow'
import moment from 'moment'
import VClipboardCheckIcon from 'icons/ClipboardCheck'
import VClipboardAlertIcon from 'icons/ClipboardAlert'
import VWarningMessage from '@/components/shared/WarningMessage'
import GenericModal from '@/components/shared/GenericModal'
import {
  shoppingCart$,
  addToShoppingCart,
  activePpwRecId$
} from '@/services';

export default {
  name: 'PatientRecentlyOrderedProducts',
  components: {
    VHeaderRow,
    VClipboardCheckIcon,
    VClipboardAlertIcon,
    VWarningMessage,
    GenericModal
  },
  props: {
    type: Boolean,
    image: String,
    title: String,
    description: String,
    productId: String,
    sapId: String,
    shortDescription: String,
    productUnit: String,
    baseProduct: Boolean,
    patientId: Number,
    isActiveLicense: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const storeSiteCode = store.state.navigation.siteCode;
    const shoppingCart = ref(null);
    const activePpwRecId = ref(null);

    const recentOrderHistory = computed(() => store.getters['patientOrderHistory/getRecentOrderHistory']);
    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);

    activePpwRecId$.subscribe(value => activePpwRecId.value = value);
    shoppingCart$.subscribe(value => shoppingCart.value = value);

    return {
      storeSiteCode,
      recentOrderHistory,
      patientGegevens,
      ...mapActions({
        getRecentOrderHistory: 'patientOrderHistory/getRecentOrderHistory'
      }),
      activePpwRecId,
      shoppingCart
    }
  },
  data () {
    return {
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      filterCountDefault: 5,
      filterCount: null,
      count: 1,
      showPopUp: false,
      clickedProductId: null
    }
  },
  computed: {
    /**
     * @returns {array} - list with orders filtered by dynamic value of count and sliced by dynamic value of filtercount
     */
    recentOrder () {
      const computedOrders = this.recentOrderHistory
      if (computedOrders.length > 0) {
        // Slice the orders by the filter count if active else return all
        return this.filterCount ? computedOrders.slice(0, this.filterCount) : computedOrders
      } else {
        return []
      }
    },
    isInfusion () {
      return this.storeSiteCode === 'MIF';
    },
    orderHeader () {
      let headers = ['Aantal', 'Omschrijving', 'Artikelcode', 'Vergoeding', 'Datum', ''];
      if (this.patientGegevens.IsDeceased || this.patientGegevens.IsArchived) {
         headers = ['Aantal', 'Omschrijving', 'Artikelcode', 'Vergoeding', 'Datum']
      }
      return headers
    }
  },
  created () {
    this.getRecentOrderHistory()
    this.filterCount = this.filterCountDefault
  },
  methods: {
    closeModal () {
      this.showPopUp = false
    },
    moment (...args) {
      return moment(...args)
    },
    checkPortal () {
      let hostname = window.location.host
      return hostname.includes('medischevoeding') !== true
    },
    /**
     * @function toggleFilterCount
     * @desc Toggles the filter count between null and filterCountDefault
     */
    toggleFilterCount () {
      this.filterCount = this.filterCount ? null : this.filterCountDefault
    },
    order (ProductId, count) {
      let order = this.recentOrderHistory
      if (order) {
        this.clickedProductId = ProductId
        order.Insured = this.getProductInsuredStatus()
        order.addedToBasket = true
        order.count = this.count
        addToShoppingCart(this.shoppingCart, ProductId, count, this.activePpwRecId);

        this.showPopUp = true
      }
    },
    getProductInsuredStatus () {
      return (this.recentOrderHistory.filter(x => x.ProductId === this.clickedProductId && x.Insured === true).length > 0)
    },
    goToProductDetailPage (baseProductId) {
      router.push('/catalog/baseProduct/' + baseProductId)
    }
  }
}
</script>

<style lang="scss" scoped>
.patientRecentOrders {
  &__icon {
    display: flex;
  }
  .warning-message {
    padding: 0 0 10px 0;
  }
  .filter-count-button {
    margin-bottom: 20px;
  }
  table {
    table-layout: auto;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 24px;

    > thead > tr {
      > td, > th {
        font-size: 16px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 15px 5px 15px 15px;
        text-align: left;
      }
    }

    > tbody {
      background: $white;

      > tr {
        border: 1px solid #cccccc;
        transition: height 0.2s ease-in-out;        
        td:first-child {
          color: $teal-darkest;
          text-align: center;
        }
        > td, > th {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $gray-darkest;
          padding: 1rem 0.5rem;
        }
      }
      .favorite-icon {
        fill: $cyan-dark;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
  &__center {
    text-align: center;
  }
  &__order {
    float: right;
    margin-right: 20px;
    background-color: $cyan-slightly-dark !important;
    color: $white !important;
  }
  .is-insured {
    text-align: center;
    margin-left: 30px;
    fill: $greenish;
  }
  .not-insured {
    text-align: center;
    margin-left: 30px;
    fill: $red;
  }
  .filter-count-button {
    align-self: center;
  }
  &__header {
    font-size: 22px;
    color: $teal-darkest;
  }
  &__actions {
    float: none;
    margin-top: 15px;
  }
  &__closeOverlay {
    float: right;
    margin: -25px -20px 0px 0px;
    cursor: pointer;
    font-size: 25px;
  }
  &__backToCatalog {
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
    color: $teal-darkest;
    font-weight: bold;
  }
  &__customStrikeText {
    text-decoration: line-through;
  }
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }
}
</style>
