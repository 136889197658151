<template>
  <div>
    <section class="main row">
      <div class="col" v-if="siteCode === 'XPP'">
        <top-navigation></top-navigation>
      </div>
      <navigation @sidebar-toggle-event="toggleSidebar" :auth="true" v-if="siteCode !== 'XPP'"></navigation>
      <div
        v-if="siteCode"
        class="col-xs-12"
        :class="{
          collapsed: sidebarCollapsed && siteCode !== 'XPP',
          sidebar: siteCode !== 'XPP'
        }"
      >
        <v-header>
          <div class="warningMessageHolder">
            <v-warning-message
              v-if="showWarningMessage()"
              :warning="warningDetails.warning"
              :html="true"
              :is-urgent="warningDetails.isUrgent"
              :show-close="true"
              @close="closeWarning()"
            />
          </div>
          <router-view :key="$route.fullPath" name="patientheader" />
        </v-header>
        <!-- product order success message component -->
        <v-order-success-message v-if="showOrderSuccess" :class="{ 'fadeInDown animated': showOrderSuccess }" />
        <router-view class="main__view" />
        <!-- Chat functionality component -->
        <v-prescriber-chat :class="{ main__prescriberchat: true, customAlignStatus: checkoutPageStatus }" />
      </div>
    </section>
    <v-prescriber-signature v-if="isLoggedIn && !signatureSaved" />
    <v-departments v-else-if="isLoggedIn && forceSecurityPolicySelection" @close="closeModal" />
  </div>
</template>

<script>
import VOrderSuccessMessage from '@/components/sections/catalog/cart/OrderSuccessMessage';
import Navigation from '@/components/sections/navigation/Navigation.vue';
import VHeader from '@/components/sections/Header';
import VDepartments from '@/components/sections/login/Departments';
import VWarningMessage from '@/components/shared/WarningMessage';
import router from '@/router';
import VPrescriberChat from '@/components/sections/PrescriberChat';
import VPrescriberSignature from '@/components/sections/PrescriberSignature';
import { isLoggedIn$, storeForceSecurityPolicySelection, currentUserIdentity$ } from '@/services';
import TopNavigation from '@/components/sections/navigation/TopNavigation';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'VMain',
  components: {
    Navigation,
    VOrderSuccessMessage,
    VHeader,
    VDepartments,
    VWarningMessage,
    VPrescriberChat,
    VPrescriberSignature,
    TopNavigation
  },
  beforeRouteUpdate(to, from, next) {
    this.routerCurrentPath = to.path;
    next();
  },
  setup() {
    const store = useStore();
    const showOrderSuccess = computed(() => store.getters['catalog/getOrderSuccessStatus']);
    const hamburgerMenuActive = computed(() => store.getters['navigation/gethamburgerMenuActive']);
    const warningDetails = computed(() => store.getters['search/getWarningDetails']);
    const signatureSaved = computed(() => store.getters['prescriber/getSignatureSaved']);
    const siteCode = computed(() => store.getters['navigation/getSiteCode']);

    return {
      showOrderSuccess,
      hamburgerMenuActive,
      warningDetails,
      signatureSaved,
      siteCode
    };
  },
  data() {
    // Subscribe to the observable on component creation
    let user = null;
    currentUserIdentity$.subscribe({
      next: (data) => {
        user = data;
      }
    });
    let isLoggedIn = null;
    isLoggedIn$.subscribe({
      next: (data) => {
        isLoggedIn = data;
      }
    });
    return {
      hideDesktopHamburgerMenu: true,
      showWarning: false,
      trackClose: false,
      animated: false,
      sidebarCollapsed: false,
      forceSecurityPolicySelection: true,
      checkoutPageStatus: false,
      user,
      isLoggedIn
    };
  },
  watch: {
    warningDetails(value) {
      if (value && value.warning && !this.trackClose) {
        this.showWarning = true;
      }
    },
    $route(to, from) {
      if (to.meta.checkout) {
        this.checkoutPageStatus = true;
      } else {
        this.checkoutPageStatus = false;
      }
    }
  },
  mounted() {
    this.sidebarCollapsed = JSON.parse(window.localStorage.getItem('sidebar-collapsed'));
    this.forceSecurityPolicySelection = JSON.parse(window.localStorage.getItem('forceSecurityPolicySelection'));
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    closeWarning() {
      this.showWarning = false;
    },
    showWarningMessage() {
      return router.currentRoute.value.name === 'main' && this.warningDetails.warning && this.showWarning;
    },
    closeModal() {
      storeForceSecurityPolicySelection(false);
      this.forceSecurityPolicySelection = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  padding-left: 300px;
  width: 100%;
  :deep(.warningMessageHolder) {
    margin-left: 10px;
  }
  &.collapsed {
    padding-left: 80px;
    width: 100%;
    :deep(.warningMessageHolder) {
      margin-left: 0px;
    }
  }
}
.main {
  background-color: $teal-lightest;
  min-height: 100vh;
  align-content: flex-start;
  .warning-message {
    border-radius: 0px;
  }
  &__view {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $teal-lightest;
  }
  &__prescriberchat {
    z-index: 10;
    position: fixed;
    bottom: 5%;
    right: 0%;
  }
  .customAlignStatus {
    bottom: 13%;
  }
}
</style>
