<template>
  <section class="container medbisOrderPage"> 
    <div class="row">
      <div class="col-xs-6">
        <v-heading :level="1">
          {{ pageTexts.header }}
        </v-heading>
      </div>
    </div>     
    <div class="row">
      <div class="medbisOrderPage__block col-xs-12">
        <v-header :title="pageTexts.subHeader" />
        <legend>{{ pageTexts.text }}</legend>
        <div class="medbisOrderPage__submit">
          <v-button
            primary
            md
            tagelement="button"
            @click.prevent="goToMedBIS()"
          >
            {{ pageTexts.button }}
          </v-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VHeader from '@/components/shared/Header.vue'
import axios from 'axios'

export default {
  name: 'MedbisOrderPage',
  components: {
    VHeader
  },
  data () {
    return {
      pageTexts: {}
    }
  },
  computed: {
  },
  created () {
    axios.get('medbis/GetMedbisConfiguration').then((response) => {
      if (response && response.data) {
        this.pageTexts = response.data
      }
    })
  },
  methods: {
    goToMedBIS () {
      window.open('https://medbis.mediq.nl/user/login');
    }
  }
}
</script>
<style lang="scss" scoped>
.medbisOrderPage {
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 20px 0px 20px 0px;
    padding: 0px 0px 30px 0px;
    legend {
      margin: 15px 15px 15px 40px;
      font-size: 16px;
    }
  }
  &__submit {
    margin: 10px 0px 0px 40px;
  }
} 
</style>
