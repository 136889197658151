<template>
  <div
    class="faq-row"
    :class="{ 'is-expanded': isExpanded }"
  >
    <tr @click="toggle">
      <td class="faq-row__cell">
        <v-menu-down-icon
          ref="iconMenuDown"
          class="icon-menu-down"
        />{{ index + 1 }}. {{ faq.question }}
      </td>
    </tr>
    <tr v-show="isExpanded">
      <td class="faq-row__detailCell">
        {{ faq.answer }}
      </td>
    </tr>
    <div
      v-if="index < totalFaq - 1"
      class="faq-row__divider"
    />
  </div>
</template>
<script>
import VMenuDownIcon from 'icons/ChevronDown'

export default {
  name: 'VFaqRow',
  components: {
    VMenuDownIcon
  },
  props: {
    faq: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number
    },
    totalFaq: {
      type: Number
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggle () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>
<style lang="scss" scoped>
.faq-row {
  &__cell {
    padding: 16px;
    &:hover {
      background: $grayish-very-light;
      cursor: pointer;
    }
  }
  &__detailCell {
    padding: 16px 56px;
  }
  &__divider {
    flex-grow: 1;
    border-bottom: solid 1px rgba(151, 151, 151, 0.27);
    margin: 0px 16px;
  }
  .icon-menu-down {
    display: flex;
    float: left;
    fill: $black;
    margin: 0px 16px 0px 0px;
    transition: $table-transition-rotate;
  }
  &.is-expanded {
    .icon-menu-down {
      transform: rotate(-180deg);
    }
  }
}
</style>
