<template>
  <li class="col-xs-6 bundle">
    <a class="bundle__link">
      <v-bundle-icon />
      <div class="bundle__text" @click.prevent="goToBundleProducts()">
        <strong class="bundle__text__name" :class="'btn_bundel_st_' + bundle.name">{{ bundle.name }}</strong>
        <strong class="bundle__text__desc">{{ bundle.description }}</strong>
      </div>
      <div>
        <v-button :class="'btn_bundel_ou01_' + bundle.name" md cta @click.prevent="addAllBundleProducts">
          Bestellen
        </v-button>
      </div>
    </a>
  </li>
</template>

<script>
import VBundleIcon from 'icons/Layers';
import { addIllnessBundleToShoppingCart, shoppingCart$ } from '@/services';
import router from '@/router';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'Bundle',
  components: {
    VBundleIcon
  },
  props: {
    bundle: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);

    const setCartOpenStatus = (value) => store.commit('cart/setCartOpenStatus', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
    });

    return {
      shoppingCart,
      setCartOpenStatus
    };
  },
  computed: {},
  methods: {
    /**
     * @function addAllBundleProducts
     * @description add all the base products to the cart
     */
    addAllBundleProducts() {
      if (this.isAuthorizationSignedPage()) {
        addIllnessBundleToShoppingCart(this.shoppingCart, this.bundle.illnessBundleId).then((response) => {
          this.setSignatureSaved(false);
          this.shoppingCart.items.forEach((item) => {
            this.$gtm.trackEvent({
              event: 'ButtonClick',
              event_category: 'Product cart',
              event_action: 'Bestellen',
              event_label: `${item.productId} ${item.productTitle}`,
              event_value: String(item.quantity)
            });
          });
        });
        router.push('/bestellijst');
      } else {
        addIllnessBundleToShoppingCart(this.shoppingCart, this.bundle.illnessBundleId);
        this.showAlert();
      }
    },
    // Function to show an alert message
    showAlert() {
      this.$emitter.emit('alert', {
        message: 'Alle producten zijn toegevoegd aan de bestellijst.'
      });
    },
    // Function to navigate BundleProducts page
    goToBundleProducts() {
      router.push('catalog/bundel/' + this.bundle.illnessBundleId);
    },
    isAuthorizationSignedPage() {
      return router.currentRoute.value.name === 'bundles';
    }
  }
};
</script>

<style lang="scss" scoped>
.bundle {
  .material-design-icon {
    fill: $white;
    float: left;
    margin: 0px 15px 0px 0px;
    height: 36px;
    width: 28px;
  }

  background: $dark-violet;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 10px 5px 10px 0px;
  padding: 20px;
  overflow: hidden;
  width: 100% !important;
  max-width: 100% !important;
  display: inline-block;

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    &__name {
      text-decoration: underline;
      margin: 0px 0px 10px 0px;
    }
    &__desc {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
