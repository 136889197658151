import axios from 'axios'

export default {
  namespaced: true,
  state: {
    favoritePrescribers: []
  },
  mutations: {
    setFavoritePrescribers (state, payload) {
      state.favoritePrescribers = payload
    }
  },
  actions: {
    fetchPrescriberFavorites ({ commit }) {
      axios.get('MyFavoriteUser/GetFavoriteUsers').then((response) => {
        if (response && response.data) {
          commit('setFavoritePrescribers', response.data)
        }
      })
    },
    savePrescriberFavorite ({ state }, details) {
      return axios.post('MyFavoriteUser/SaveFavoriteUser', details)
    }
  },
  getters: {
    getFavoritePrescribers: state => {
      let favoritePrescribers = []
      if (state.favoritePrescribers.length === 0) {
        favoritePrescribers.push({text: 'Geen favoriete voorschrijvers', value: 0})
      }
      state.favoritePrescribers.forEach((favoritePrescriber) => {
        let favoritePrescriberDetail = {
          ppwrecId: favoritePrescriber.PpwRecId,
          text: (favoritePrescriber.Initials ? favoritePrescriber.Initials + ', ' : '') + 
            favoritePrescriber.LastName + ' | ' + 
            (favoritePrescriber.BigCode ? favoritePrescriber.BigCode + ' | ' : '') + 
            favoritePrescriber.DepartmentName,
          value: favoritePrescriber.UserId
        }
        favoritePrescribers.push(favoritePrescriberDetail)
      })
      return favoritePrescribers
    }
  }
}
