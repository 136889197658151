<template>
  <div>
    <fieldset class="categories">
      <h1>Categorieën</h1>
      <recursive-categories
        :categories="transformedCategories"
        @expand-state-changed="onExpandStateChanged"
        @input="onRecursiveCategoriesInput"
      />
    </fieldset>
    <div v-if="value !== null" class="backToProducts">
      <v-button md primary cta @click.prevent="goToAllProducts()">
        <v-arrow-left-icon />
        Alle producten tonen
      </v-button>
    </div>
  </div>
</template>

<script>
import VArrowLeftIcon from 'icons/ArrowLeft';
import RecursiveCategories from './RecursiveCategories.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { currentUserIdentity$ } from '@/services';

export default {
  name: 'VCategories',
  components: {
    VArrowLeftIcon,
    RecursiveCategories
  },
  props: {
    categories: Array,
    value: Object,
    isAssortmentFlow: Boolean
  },
  setup() {
    const store = useStore();
    const currentUserIdentity = ref(null);
    const previousSelectedCategories = computed(() => store.getters['catalog/getPreviousSelectedCategories']);
    const setPreviousSelectedCategories = (value) => store.commit('catalog/setPreviousSelectedCategories', value);

    currentUserIdentity$.subscribe((value) => {
      currentUserIdentity.value = value;
    });

    return {
      currentUserIdentity,
      previousSelectedCategories,
      setPreviousSelectedCategories
    };
  },
  data() {
    return {
      activeCategory: null,
      transformedCategories: []
    };
  },
  watch: {
    value(val) {
      if (!val) {
        this.loadTransformedCategories();
      }
    },
    categories: {
      handler(newCategories, oldCategories) {
        this.loadTransformedCategories();
      }
    }
  },
  mounted() {
    this.loadTransformedCategories();
  },
  methods: {
    loadTransformedCategories() {
      const cb = (cats) => {
        const r = [];
        if (cats) {
          cats.forEach((c) => {
            let expandStatus = false;
            this.previousSelectedCategories.forEach((psc) => {
              if (psc.category.categoryId === c.categoryId) {
                expandStatus = true;
              }
            });
            if (this.value && this.value.categoryId === c.categoryId) {
              expandStatus = true;
            }
            r.push({
              expanded: expandStatus,
              active: expandStatus,
              category: c,
              children: cb(c.childItems)
            });
          });
        }

        return r;
      };
      if (this.isAssortmentFlow) {
        const result = cb(this.categories);
        this.transformedCategories = result;
      } else {
        const result = cb(
          this.categories.filter((obj) =>
            this.currentUserIdentity.userTreatmentArea.map((area) => area.treatmentAreaId).includes(obj.treatmentAreaId)
          )
        );
        this.transformedCategories = result;
      }
    },
    onExpandStateChanged(value) {
      if (this.previousSelectedCategories.length > 0) {
        // reset when they are not present in the `value`
        this.previousSelectedCategories.forEach((previousSelectedCategory) => {
          const i = value.indexOf(previousSelectedCategory);
          if (i < 0) {
            previousSelectedCategory.expanded = false;
          }
        });
      }

      this.setPreviousSelectedCategories(value);
    },
    onRecursiveCategoriesInput(value) {
      if (this.activeCategory) {
        this.activeCategory.active = false;
      }
      this.activeCategory = value;

      if (value) {
        value.active = true;
        this.$emit('update:modelValue', value.category);
      } else {
        this.$emit('update:modelValue', null);
      }
    },
    goToAllProducts() {
      this.onRecursiveCategoriesInput(null);

      if (this.previousSelectedCategories.length > 0) {
        // reset when they are not present in the `value`
        this.previousSelectedCategories.forEach((previousSelectedCategory) => {
          previousSelectedCategory.expanded = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.backToProducts {
  padding-top: 10px;
}

.categories {
  border: none;
  margin: 0;
  padding: 0;

  > h1 {
    font-size: type-scale(2);
    margin-top: 0;
    margin-bottom: 10px;
    color: $gray-darkest;
  }

  > ul {
    color: $gray-darkest;
    font-weight: bold;
    padding: 0;
    margin: 0;
    list-style-type: none;

    > li {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: solid 2px $gray;
      cursor: pointer !important;

      &:first-child {
        padding-top: 0;
      }

      > div {
        display: flex;
        align-items: center;
      }

      ul {
        color: $gray-dark;
        font-weight: normal;
        padding: 0;
        margin-left: 15px;
        margin-top: 25px;
        list-style-type: none;

        li {
          margin-bottom: 25px;

          > div {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  label {
    display: block;
    cursor: pointer !important;
  }

  input {
    position: absolute;
    left: -3000em;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .icon-menu-down {
    display: inline-table !important;
    fill: $teal-darkest;
    margin-top: 0;
    transition: transform 0.2s ease-in-out;
    margin-left: auto;

    &.is-expanded {
      transform: rotate(-180deg);
    }
  }

  .active {
    color: $teal-darkest;
    font-weight: bold;
  }
}
</style>
