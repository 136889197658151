import axios from 'axios';
import { refreshShoppingCart } from './shoppingCartRefreshRequested$';

export function removeFromShoppingCart(shoppingCart, shoppingCartItemId) {
  if (shoppingCart === null) {
    return;
  }

  // Get data from argument
  var command = {
    shoppingCartItemId: shoppingCartItemId
  };

  axios.post('ShoppingCart/RemoveFromShoppingCart', command).then((response) => {
    // force rebind of shopping cart
    refreshShoppingCart();
    return response;
  });
}
