<template>
  <div
    class="form-group form-group--textarea"
    :class="{
      'form-group--focused': isFocused,
      'form-group--hasvalue': value,
      'form-group--disabled': disabled,
      'form-group--error': error
    }"
  >
    <label :for="inputid">{{ label }}<span v-if="rule.required && label" class="asterisk"> *</span></label>
    <div class="form-group__input">
      <textarea
        :id="inputid"
        v-model="value"
        :disabled="disabled"
        :name="inputid"
        :maxlength="maxlength"
        tabindex="0"
        autocomplete="off"
        @input="update"
        @focus="focus"
        @blur="blur"
      />
    </div>

    <div v-show="error" class="form-group__details">
      <div class="form-group__messages">
        <div class="form-group__error">* Dit veld is verplicht.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTextareaGroup',
  props: {
    label: {
      default: '',
      type: String
    },
    inputid: {
      default: 'inputid',
      type: String
    },
    maxlength: {
      default: '',
      type: String
    },
    initvalue: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    rule: {
      default: () => ({}),
      type: Object
    },
    error: {
      default: false,
      type: Boolean
    },
    errorMessage: {
      default: 'Error',
      type: String
    },
    overwriteValue: [String, null]
  },
  data() {
    return {
      isFocused: false,
      value: this.initvalue
    };
  },
  watch: {
    overwriteValue(value) {
      if (value != null) {
        this.value = value;
      }
    }
  },
  methods: {
    update(event) {
      this.$emit('input', event.target.value);
    },
    focus() {
      this.isFocused = true;
    },
    blur() {
      this.isFocused = false;
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  resize: vertical;
}
</style>
