<template>
  <div
    class="form-group-simple datepicker"
    :class="{
      'form-group-simple--completed': completed,
      'form-group-simple--error': errorMessage
    }"
  >
    <label v-if="label" :for="id">{{ label }}</label>
    <datepicker
      :id="id"
      ref="input"
      v-model="value"
      type="text"
      :name="name"
      placeholder="dd-mm-jjjj"
      format="dd-MM-yyyy"
      :disabled-dates="disabledDates"
      :disabled="disabled"
      :custom-key="customKey"
      :language="'nl'"
      :highlighted="highlighted"
      @input="change"
      @blur="blur"
      @focusin="opened"
    />
    <div class="form-group-simple__details" v-if="errorMessage">
      <div class="form-group-simple__messages">
        <div class="form-group-simple__error">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import Datepicker from 'vuejs3-datepicker';
import { useField } from 'vee-validate';
import { generateYupSchema } from '@/composables/globalHelperFunctions';
import { ref, watch, onBeforeMount } from 'vue';

export default {
  name: 'VInputDatePicker',
  components: {
    Datepicker
  },
  props: {
    autofocus: {
      default: false,
      type: Boolean
    },
    name: {
      default: 'name',
      type: String
    },
    id: {
      default: 'id',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    customKey: {
      default: 0,
      type: Number
    },
    customObject: {
      default: null,
      type: Array
    },
    customDateRange: {
      default: false,
      type: Boolean
    },
    customDOB: {
      default: false,
      type: Boolean
    },
    osaTherapyStartDate: {
      default: false,
      type: Boolean
    },
    accountabiliyStartDate: {
      default: false,
      type: Boolean
    },
    customStartDate: {
      default: null,
      type: String
    },
    accountabiliyQuestionDate: {
      default: false,
      type: Boolean
    },
    osaDate: {
      default: false,
      type: Boolean
    },
    emptyDate: {
      default: false,
      type: Boolean
    },
    disableFutureDates: {
      default: false,
      type: Boolean
    },
    dateBetweenRange: {
      default: null,
      type: Number
    },
    disableNextDate: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    holidayDates: {
      default: null,
      type: Array
    },
    overwriteValue: {
      type: [String]
    }
  },
  emits: ['updateValidation', 'selected'],
  setup(props, context) {
    const rules = { required: true };
    if (props.emptyDate) {
      rules.required = false;
    }
    const schema = ref(
      generateYupSchema({
        label: props.label ? props.label : props.name,
        rules: rules
      })
    );
    const { value, errorMessage, validate } = useField(props.name, schema);

    watch(
      () => props.rules,
      () => {
        schema.value = generateYupSchema({
          label: props.label ? props.label : props.name,
          rules: rules
        });
      }
    );

    onBeforeMount(() => {
      value.value = props.emptyDate || props.customDOB ? null : props.value ? new Date(props.value) : new Date();
      if (!props.emptyDate) {
        change();
      }
    });

    const blur = (e) => {
      if (e.currentTarget.value.length > 0) {
        validate();
      }
    };

    const emitUpdateValidation = (temp) => {
      context.emit('updateValidation', temp);
    };

    const emitSelected = (temp, id, customKey, customObject) => {
      context.emit('selected', id, temp, customKey, customObject);
    };

    const change = () => {
      validate().then((result) => {
        if (result.valid) {
          emitUpdateValidation(moment(new Date(value.value)).format('DD-MM-YYYY'));
          emitSelected(moment(new Date(value.value)).format('DD-MM-YYYY'), props.id, props.customKey, props.customObject);
        }
      });
    };

    return {
      value,
      errorMessage,
      validate,
      blur,
      change
    };
  },
  data() {
    return {
      completed: false,
      highlighted: {
        dates: [new Date()]
      }
    };
  },
  computed: {
    disabledDates() {
      let disabledDates = {};
      let yesterday = new Date();
      disabledDates.to = new Date(yesterday.setDate(yesterday.getDate() - 1));
      if (this.customDateRange) {
        let customDateRangeDateFrom = new Date();
        disabledDates.from = new Date(customDateRangeDateFrom.setDate(customDateRangeDateFrom.getDate() + 7));
        if (store.state.navigation.siteCode === 'MIF') {
          let customDateRangeDateTo = new Date();
          disabledDates.to = new Date(customDateRangeDateTo.setDate(customDateRangeDateTo.getDate() - 8));
        }
        if (this.accountabiliyStartDate) {
          let customDateRangeDateFrom = new Date();
          disabledDates.from = new Date(customDateRangeDateFrom.setDate(customDateRangeDateFrom.getDate() + 14));
          if (store.state.navigation.siteCode === 'MIF') {
            let customDateRangeDateTo = new Date();
            disabledDates.to = new Date(customDateRangeDateTo.setDate(customDateRangeDateTo.getDate() - 15));
          }
        }
      } else if (this.customDOB) {
        // disable all date before 'to' date
        disabledDates.to = moment().subtract(100, 'years').toDate();
        // disable all date after 'from' date
        disabledDates.from = new Date();
      }
      if (this.accountabiliyQuestionDate) {
        let customDateRangeDateTo = new Date();
        disabledDates.to = new Date(customDateRangeDateTo.setDate(customDateRangeDateTo.getDate() - 15));
      }
      if (store.state.navigation.siteCode === 'MRT') {
        let customDateRangeDateTo = new Date();
        let customDateRangeDateFrom = new Date();
        disabledDates.to = new Date(customDateRangeDateTo.setYear(customDateRangeDateTo.getFullYear() - 5));
        disabledDates.from = new Date(customDateRangeDateFrom.setMonth(customDateRangeDateFrom.getMonth() + 3));
      }
      if (this.customStartDate !== null) {
        disabledDates.to = new Date(this.customStartDate);
      }
      if (this.osaDate) {
        let customDateRangeDateFrom = new Date();
        // disable all date before 'to' date
        disabledDates.to = moment().subtract(100, 'years').toDate();
        // disable all date after 'from' date
        disabledDates.from = new Date(customDateRangeDateFrom.setDate(customDateRangeDateFrom.getDate() + 3));
      }
      if (this.osaTherapyStartDate) {
        // disable all date before 'to' date
        disabledDates.to = moment().subtract(70, 'years').toDate();
        // disable all date after 'from' date
        disabledDates.from = moment().add(70, 'years').toDate();
      }
      if (this.disableFutureDates) {
        disabledDates.from = new Date();
      } else {
        disabledDates = {};
      }
      if (this.holidayDates) {
        let holidaysFormattedDates = [];
        this.holidayDates.forEach((element) => {
          holidaysFormattedDates.push(new Date(moment(element, 'DD-MM-YYYY')));
        });
        disabledDates.dates = holidaysFormattedDates;
      }
      if (this.dateBetweenRange) {
        disabledDates.from = new Date();
        disabledDates.from.setMonth(disabledDates.from.getMonth() + 2);
        disabledDates.from.setDate(disabledDates.from.getDate() - 1);
        disabledDates.to = new Date();
      }
      if (this.disableNextDate) {
        disabledDates.to.setDate(disabledDates.to.getDate() + 1);
      }
      return disabledDates;
    }
  },
  watch: {
    // Watch for changes in v-model value and emit event if it becomes null
    overwriteValue: {
      handler(newVal) {
        if (newVal) {
          const parsedDate = moment(newVal, 'DD-MM-YYYY', true);
          if (parsedDate.isValid()) {
            this.value = parsedDate.toDate();
          }
        } else {
          this.value = null;
        }
      }
    }
  },
  created() {
    if (!this.emptyDate) {
      this.change();
    }
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = Math.ceil(visible + scrollY) >= Math.ceil(pageHeight);
      return bottomOfPage;
    },
    opened(e) {
      e.preventDefault();
      if (this.bottomVisible()) {
        document.querySelectorAll('.vdp-datepicker__calendar').forEach((el) => {
          el.classList.add('positionClass');
        });
        setTimeout(() => {
          window.scrollBy({ top: 324, behavior: 'smooth' });
        }, 500);
      } else {
        document.querySelectorAll('.vdp-datepicker__calendar').forEach((el) => {
          el.classList.remove('positionClass');
        });
      }
    }
  }
};
</script>
<style lang="scss">
.datepicker {
  .vuejs3-datepicker__calendar .cell {
    line-height: 40px !important;
  }
  .vuejs3-datepicker__calendar-topbar,
  .vuejs3-datepicker__calendar .cell.highlighted,
  .vuejs3-datepicker__calendar .cell.selected {
    background-color: $teal-darkest !important;
  }
  .vuejs3-datepicker__value {
    width: 100%;
    border: 1px solid $gray;
    border-radius: 3px;
  }
  .positionClass {
    position: relative !important;
  }
  input {
    padding-left: 30px;
    cursor: pointer;
    border: 1px solid $gray;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
}
</style>
