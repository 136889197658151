<template>
  <section class="container mypatients">
    <div class="row grid-margin">
      <div class="col-xs-6 col-md-3">
        <div class="head">
          <h1>{{ computedPageTitle }}</h1>
          <p v-if="query == ''" class="subhead">Laatste 10 gevolgde patiënten</p>
          <p v-else class="subhead">De volgende patiënten werden gevonden</p>
        </div>
      </div>
      <div class="col-xs-6 col-md-9">
        <div class="row">
          <v-input-group-simple-2
            id="Searchbar"
            v-model="query"
            placeholder="Zoeken naar een patiënt op naam of BSN nummer"
            class="col-xs-12 col-md-12 col-lg-12"
            @keyup="onKeyUp"
          />
          <sync-loader v-if="isTyping" :color="color" :size="size" class="loader" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="initialized && followedPatients.patients.length == 0" class="fade-element">
        <div class="card">
          <h4 class="carditem">Er zijn geen patiënten gevonden met die naam, wilt u uw zoekopdracht verfijnen?</h4>
          <v-search class="carditem" :is-sub-search="true" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="followedPatients.patients.length > 0">
        <table class="mypatients__table">
          <col />
          <col />
          <col />
          <thead>
            <tr>
              <th />
              <th scope="col">Patiënt</th>
              <th scope="col" />
              <th scope="col">Geboortedatum</th>
              <th scope="col">BSN</th>
              <th scope="col">Postcode</th>
              <th scope="col">Huis nr.</th>
              <th scope="col">Plaats</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <v-listing-item
              v-for="(patient, index) in followedPatients.patients"
              id="step2"
              :key="index"
              :patient="patient"
              @query="getPatientData"
              @select="selectPatient"
            />
          </tbody>
        </table>
        <div v-show="followedPatients.totalResult - 1 > 1" class="row grid-align-middle">
          <paginate
            v-model="page"
            :page-count="followedPatients.totalResult - 1"
            :page-range="3"
            :margin-pages="2"
            :click-handler="setPagination"
            :prev-text="'Vorige'"
            :next-text="'Volgende'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import Paginate from 'vuejs-paginate-next';
import router from '@/router';
import VListingItem from '@/components/sections/mypatients/MyPatientsListingItem';
import vSearch from '@/components/sections/search/Search';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import { computed } from 'vue';
import { useStore, mapActions } from 'vuex';

export default {
  name: 'MyPatientsListing',
  components: {
    VListingItem,
    Paginate,
    SyncLoader,
    vSearch
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  setup() {
    const store = useStore();

    const followedPatients = computed(() => store.getters['mypatients/followedPatients']);
    const statePatientId = store.state.patient.patientGegevens.PatientId;
    const setEndpoints = (value) => store.dispatch('patient/setEndpoints', value);
    const getFollowedPatients = (value) => store.dispatch('mypatients/getFollowedPatients', value);

    return {
      followedPatients,
      statePatientId,
      setEndpoints,
      getFollowedPatients,
      ...mapActions('patient', ['setActivePatient'])
    };
  },
  data() {
    return {
      query: '',
      page: 1,
      totalResult: 1,
      typingTimer: null,
      isTyping: false,
      color: '#73c8d2',
      size: '8px',
      initialized: false
    };
  },
  computed: {
    computedPageTitle() {
      return router.currentRoute.value.name === 'mijnpatienten' ? 'Mijn patiënten' : 'Patiënten van afdeling';
    }
  },
  updated() {},
  created() {
    this.queryForPatients();
  },
  unmounted() {
    this.followedPatients.patients = [];
    this.followedPatients.totalResult = 0;
  },
  methods: {
    selectPatient(PatientId) {
      this.setActivePatient(PatientId).then((result) => {
        if (result.data.IsActiveCustomer) {
          router.push('/patientdashboard');
        } else {
          this.setEndpoints('edit');
          router.push('/patientgegevens');
        }
      });
    },
    queryForPatients() {
      let params = {};
      params.query = this.query;
      params.page = this.page;

      if (router.currentRoute.value.name !== 'mijnpatienten') {
        params.isDepartment = true;
      }

      this.getFollowedPatients(params).then((result) => {
        this.isTyping = false;
        this.initialized = true;
      });
    },
    onKeyUp() {
      clearTimeout(this.typingTimer);
      this.page = 1;
      this.isTyping = true;
      this.typingTimer = setTimeout(this.queryForPatients, 600);
    },
    getPatientData() {
      this.queryForPatients();
    },
    searchText(event) {
      this.query = event.target.value;
      this.queryForPatients();
    },
    setPagination(pageNumber) {
      this.isTyping = true;
      this.finalDevices = [];
      pageNumber = pageNumber > 0 ? pageNumber : 1;
      this.page = pageNumber;
      this.queryForPatients();
    }
  }
};
</script>

<style lang="scss" scoped>
.head {
  margin-top: -22px;
}

.subhead {
  margin-top: -17px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.card {
  border-radius: 10px;
  color: $teal-darkest;
  border-color: #73c8d2;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  .material-design-icon {
    fill: $teal-darkest;
    margin: 16px 16px 16px 16px;
    :deep(svg) {
      height: 28px;
      width: 28px;
    }
  }
  p {
    min-height: 150px;
  }
}

.carditem {
  margin-top: 10px;
  margin-left: 5px;
}

.loader {
  margin-left: -70px;
  margin-top: 10px;
}

.mypatients {
  h1 {
    color: $teal-darkest;
    font-weight: 300;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    background: $white;
    table-layout: auto !important;
    th,
    td {
      vertical-align: middle;
      text-align: left;
      padding: 12px 20px !important;
      font-weight: normal;
    }
    a {
      color: $teal-darkest;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    thead {
      tr {
        border: 1px solid $gray-light;
      }
      padding-bottom: 2px;
      td,
      th {
        background-color: $teal-lighter;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    tbody {
      tr {
        border: 1px solid $gray-light;
      }
      th,
      td {
        vertical-align: middle !important;
        padding-top: 17px;
        padding-bottom: 32px !important;
      }
    }
  }
}
</style>
