<template>
  <tbody class="pumpOverview-row" :class="[{ 'is-expanded': isExpanded }]">
    <tr
      ref="row"
      class="pumpOverview-row__data"
      :class="{ cursorClass: true, 'pumpOverview-row__pumpHover': isPumpHover }"
      @click="
        toggle();
        $emit('selectPump', {
          isPumpSelected: pumpSelected,
          pumpId: currentPumpId,
          productId: currentProductId,
          serialNumber: currentSerialNumber
        });
      "
      @mouseenter="getContentCss"
      @mouseleave="getContentCss"
    >
      <td v-if="pumpAssignmentPage" class="pumpOverview-row__cell">
        <div v-if="data.equipmentID" class="form-group__radio pumpOverview-row__cell__rdb">
          <input :id="'rdb' + data.equipmentID" name="rdbPump" type="radio" />
          <label :for="'rdb' + data.equipmentID"><span /></label>
        </div>
        <div v-else class="form-group__radio pumpOverview-row__cell__rdb">
          <input :id="'rdb' + data.pumpDesc.split('-')[0]" name="rdbPump" type="radio" />
          <label :for="'rdb' + data.pumpDesc.split('-')[0]"><span /></label>
        </div>
      </td>
      <td class="pumpOverview-row__cell">
        <ul class="pumpOverview-row__cell__desc">
          <li>
            <img :src="data.imageURL" class="pumpOverview-row__cell__img" @error="imageLoadError" />
          </li>
        </ul>
      </td>
      <td class="pumpOverview-row__cell">
        <ul v-if="pumpAssignmentPage" class="pumpOverview-row__cell__desc">
          <li>{{ computedPumpDesc }}</li>
        </ul>
        <ul v-else class="pumpOverview-row__cell__desc">
          <li>
            {{ data.pumpDesc }}
          </li>
        </ul>
      </td>
      <td class="pumpOverview-row__cell">
        <ul class="pumpOverview-row__cell__serialno">
          <li v-if="data.equipmentStatus === 'Onbekend'">
            <v-input-group-simple-2
              id="Serienummer"
              v-model:value="currentSerialNumber"
              :rules="{
                required: true
              }"
              type="text"
              placeholder="Serienummer*"
              class="pumpOverview-row__cell__textbox"
              @input="$emit('setSerialNumber', { serialNumber: currentSerialNumber })"
            />
          </li>
          <li v-else-if="data.equipmentStatus === 'Mediq'" />
          <li v-else>
            {{ data.serialNo }}
          </li>
        </ul>
      </td>
      <td class="pumpOverview-row__cell" v-if="overviewPage">
        <ul class="pumpOverview-row__cell__dynamicField">
          <li>
            <div :class="getStatusColor(data.equipmentStatus)">
              <v-circle-icon class="pumpOverview-row__cell__dynamicField__circle_class" />
              <span>{{ getStatusField(data.equipmentStatus) }}</span>
            </div>
          </li>
        </ul>
      </td>
      <td class="pumpOverview-row__cell">
        <ul class="pumpOverview-row__cell__dynamicField">
          <li>
            <div :class="getStatusColorForDaysLeftForMaintenance(data.daysLeftForMaintenance)">
              <icon :is="'VCircleIcon'" class="pumpOverview-row__cell__dynamicField__circle_class"></icon>
              <span v-if="data.daysLeftForMaintenance == null">Onbekend</span>
              <span v-else>{{ data.daysLeftForMaintenance + ' dagen' }}</span>
            </div>
          </li>
        </ul>
      </td>
      <td class="pumpOverview-row__cell justify-content-end" v-if="!pumpAssignmentPage">
        <div>
          <v-button
            v-if="isExpanded"
            tagelement="button"
            md
            primary
            @click.stop="
              toggle();
              $emit('selectPump', {
                isPumpSelected: pumpSelected,
                pumpId: currentPumpId,
                productId: currentProductId,
                serialNumber: currentSerialNumber
              });
            "
          >
            Toon minder
          </v-button>
          <v-button v-else tagelement="button" md primary @click.stop="toggle()"> Toon meer </v-button>
        </div>
      </td>
    </tr>
    <tr v-show="isExpanded" class="pumpOverview-row__details">
      <td class="pumpOverview-row__cell" colspan="4">
        <div v-if="overviewPage && (data.equipmentStatus === 'Lend' || data.equipmentStatus === 'LendAndAssigned')">
          <p ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Uitgeleend op
            <b>
              {{ $formatDate(data.lendoutDate) }} aan {{ data.lendoutWarehouseName }} -
              {{ data.lendoutWarehouseId }}
            </b>
          </p>
          <p
            v-if="userWarehouseId !== data.lendoutWarehouseId"
            ref="prescriber"
            class="pumpOverview-row__cell__prescriber"
          >
            Verantwoordelijk voor ophalen: <b>Uw organisatie</b>
          </p>
          <p v-else ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Verantwoordelijk voor ophalen: <b>{{ data.lendoutWarehouseDepartment }}</b>
          </p>
        </div>
        <div v-else-if="overviewPage">
          <p ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Toegewezen door <b>{{ data.prescriberName }}.</b>
          </p>
          <p ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Verantwoordelijk voor ophalen:
            <b><span v-if="data.equipmentType === 'RCC'">Uw organisatie</span><span v-else>Mediq</span></b>
          </p>
        </div>
        <div v-else>
          <p ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Na gebruik wordt deze pomp opgehaald door
            <span v-if="data.equipmentType === 'RCC'"> Uw organisatie </span>
            <span v-else> Mediq </span>.
          </p>
          <p ref="prescriber" class="pumpOverview-row__cell__prescriber">
            Pomp toegewezen door <span class="pump-row__cell__prescriber__author">{{ data.prescriberName }}</span> op
            <span class="pump-row__cell__prescriber__author">{{ $formatDate(data.dateOfAllocation) }}</span>
          </p>
        </div>
        <div class="pumpOverview-row__cell__actions">
          <v-button
            v-show="
              overviewPage &&
              data.equipmentStatus !== 'Available' &&
              data.equipmentStatus !== 'Lend' &&
              data.equipmentStatus !== 'LendAndAssigned'
            "
            tagelement="button"
            md
            icon-left
            hollow
            @click="toPatientDashboard"
          >
            <v-account-icon />
            Naar Patient
          </v-button>
          <v-button
            v-show="overviewPage && data.equipmentStatus !== 'Lend' && data.equipmentStatus !== 'LendAndAssigned'"
            tagelement="button"
            :class="{ 'btn--primary': true }"
            icon-left
            md
            class="pumpOverview-row__cell__button"
            @click="
              $emit('retrieve', { id: data.equipmentID });
              toggle();
            "
          >
            <v-truck-delivery-icon />
            Laten ophalen
          </v-button>
          <v-button
            v-show="overviewPage && data.equipmentStatus === 'Available' && data.equipmentStatus !== 'LendAndAssigned'"
            tagelement="button"
            :class="{ 'btn--primary': true }"
            icon-left
            md
            class="pumpOverview-row__cell__button"
            @click.prevent="pumpLending"
          >
            <img
              src="@/static/img/icons/arrow-top-right-thin-circle-outline.png"
              class="pumpOverviewLending__topRightArrowicon"
            />Uitlenen
          </v-button>
          <v-button
            v-show="
              overviewPage &&
              data.equipmentStatus === 'Lend' &&
              data.equipmentStatus !== 'LendAndAssigned' &&
              userWarehouseId !== data.lendoutWarehouseId
            "
            tagelement="button"
            :class="{ 'btn--primary': true }"
            icon-left
            md
            class="pumpOverview-row__cell__button"
            @click.prevent="showPumpReturnPopup"
          >
            <v-clipboard-arrow-left />
            Retour nemen
          </v-button>
        </div>
      </td>
      <td class="pumpOverview-row__cell" />
    </tr>
    <v-overlay v-if="isPumpLending">
      <div class="pumpOverviewLending">
        <div class="row grid-margin">
          <div class="col-xs-6 pumpOverviewLending__header">Pomp uitlenen</div>
          <div class="col-xs-6">
            <a @click="closeModal()"><v-close-icon class="pumpOverviewLending__closeIcon" /></a>
          </div>
        </div>
        <div class="row grid-margin">
          <div class="col-xs-12 pumpOverviewLending__text">
            Selecteer hieronder het depot aan wie u de pomp wilt uitlenen.
          </div>
        </div>
        <div class="row grid_margin">
          <div class="col-xs-12">
            <v-input-group
              v-model="lendingPostalCode"
              :rule="postCodeRule"
              inputid="Naam of postcode"
              placeholder="Zoek op naam of postcode (1234 AA) van het depot"
            />
            <v-search-icon class="pumpOverviewLending__search-icon" @click.prevent="getWarehouseListData" />
          </div>
        </div>
        <div class="row grid_margin">
          <div class="col-xs-12 pumpOverviewLending__selectDept pumpOverview-row__selectDept">
            <v-select-group
              v-show="warehouses.length > 0"
              v-model="currentWarehouseId"
              :overwrite-value="currentWarehouseId"
              selectid="WarehouseId"
              :initselected="'0'"
              @change.prevent="selectWarehouse($event)"
            >
              <option v-if="warehouses.length > 1" value="0">Selecteer depot</option>
              <option
                v-for="(item, index) in warehouses"
                :key="index + 1"
                :data-index="index + 1"
                :value="item.warehouseId"
              >
                {{ item.warehouseName }} - {{ item.postalCode }} - {{ item.warehouseId }}
              </option>
            </v-select-group>
            <v-warning-message
              v-show="showWarning"
              warning="Helaas is er geen depot gevonden met bovenstaand postcode."
              message="Probeer het nogmaals."
              :show-close="false"
            />
          </div>
        </div>
        <div class="row grid-margin">
          <a class="pumpOverviewLending__leftButton" @click="closeModal()">Annuleren</a>
          <v-button
            class="btn btn--primary btn--lg pumpOverviewLending__rightButton"
            :disabled="currentWarehouseId === '0' || currentWarehouseId === ''"
            @click.prevent="lendoutPump(data.equipmentID)"
          >
            Versturen
          </v-button>
        </div>
      </div>
    </v-overlay>

    <v-overlay v-if="pumpLendingSuccess">
      <div class="pumpOverviewLending">
        <div class="row grid-margin">
          <div class="col-xs-6 pumpOverviewLending__header">Pomp overgedragen</div>
          <div class="col-xs-6">
            <a @click="closeSucessModal()"><v-close-icon class="pumpOverviewLending__closeIcon" /></a>
          </div>
        </div>
        <div class="row grid-margin">
          <div class="col-xs-12">
            <v-check-circle class="pumpOverviewLending__checkicon" />
          </div>
        </div>
        <div class="row grid-margin">
          <div class="col-xs-12 pumpOverviewLending__successtext">Pomp is succesvol overgedragen!</div>
        </div>
        <div class="row grid-margin">
          <div class="col-xs-12">
            <img src="@/static/img/succes_mascot.png" class="pumpOverviewLending__icon" />
          </div>
        </div>
        <div class="row grid-margin">
          <v-button
            class="btn btn--primary btn--lg pumpOverviewLending__centerButton"
            @click.prevent="closeSucessModal"
          >
            Ga verder
          </v-button>
        </div>
      </div>
    </v-overlay>

    <v-overlay v-if="isPumpReturn">
      <div class="pumpOverviewLending">
        <div class="row grid-margin">
          <div class="col-xs-6 pumpOverviewLending__header">Pomp retour nemen</div>
          <div class="col-xs-6">
            <a @click="closeModal()"><v-close-icon class="pumpOverviewLending__closeIcon" /></a>
          </div>
        </div>
        <div class="row grid-margin">
          <div class="col-xs-12 pumpOverviewLending__return_text">
            U staat op het punt om deze pomp retour te nemen na uitleen. Weet u zeker dat u wilt doorgaan?
          </div>
        </div>
        <div class="row grid-margin">
          <a class="pumpOverviewLending__return_leftButton" @click="closeModal()">Annuleren</a>
          <v-button
            class="btn btn--primary btn--lg pumpOverviewLending__return_rightButton"
            @click.prevent="pumpReturn(data.equipmentID)"
          >
            Neem pomp retour
          </v-button>
        </div>
      </div>
    </v-overlay>
  </tbody>
</template>

<script>
import { mapActions, mapMutations, useStore } from 'vuex';
import { computed } from 'vue';
import router from '@/router';
import VTruckDeliveryIcon from 'icons/TruckDelivery';
import VCircleIcon from 'icons/Circle';
import VAccountIcon from 'icons/Account';
import VOverlay from '@/components/shared/Overlay.vue';
import VCloseIcon from 'icons/Close';
import VSearchIcon from 'icons/Magnify';
import VWarningMessage from '@/components/shared/WarningMessage';
import VCheckCircle from 'icons/CheckCircle';
import VClipboardArrowLeft from 'icons/ClipboardArrowLeft';
import { useForm } from 'vee-validate';

export default {
  name: 'PumpOverviewRow',
  components: {
    VTruckDeliveryIcon,
    VCircleIcon,
    VAccountIcon,
    VOverlay,
    VCloseIcon,
    VSearchIcon,
    VWarningMessage,
    VCheckCircle,
    VClipboardArrowLeft
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    userWarehouseId: {
      type: String,
      default: ''
    }
  },
  setup() {
    const store = useStore();

    const warehouses = computed(() => store.getters['pumpOverview/getWarehouses']);
    const currentWarehouseId = computed(() => store.getters['pumpOverview/getCurrentWarehouseId']);
    const showWarning = computed(() => store.getters['pumpOverview/getShowWarning']);
    const pumpLendingSuccess = computed(() => store.getters['pumpOverview/getPumpLendingSuccess']);
    const isPumpReturn = computed(() => store.getters['pumpOverview/getIsPumpReturn']);

    return {
      warehouses,
      currentWarehouseId,
      showWarning,
      pumpLendingSuccess,
      isPumpReturn,
      ...mapActions('patient', ['setActivePatient']),
      ...mapActions('pumpOverview', ['getWarehouseList', 'lendoutPump', 'pumpReturn', 'updateCurrentWarehouseId']),
      ...mapMutations({
        setWarehouses: 'pumpOverview/setWarehouses',
        setIsPumpReturn: 'pumpOverview/setIsPumpReturn',
        setShowWarning: 'pumpOverview/setShowWarning',
        setPumpLendingSuccess: 'pumpOverview/setPumpLendingSuccess'
      })
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      isExpanded: false,
      beforeExpanded: false,
      isTransitioning: false,
      rowHeight: 'auto',
      rowHeightCollapsed: 'auto',
      overviewPage: false,
      pumpAssignmentPage: false,
      isPumpHover: false,
      pumpSelected: false,
      currentPumpId: '',
      currentSerialNumber: '',
      currentProductId: '',
      isPumpLending: false,
      lendingPostalCode: '',
      postCodeRule: {
        required: true,
      },
      postcodeError: false,
      postCodeErrorMessage: 'Ingevoerde postcode is incorrect of bij ons niet bekend.'
    };
  },
  computed: {
    computedPumpDesc() {
      return this.data.equipmentID
        ? this.data.pumpDesc
        : this.data.pumpDesc.substring(this.data.pumpDesc.indexOf('-') + 1);
    }
  },
  created() {
    this.isPumpLending = false;
    if (router.currentRoute.value.name === 'pompenoverzicht') {
      this.overviewPage = true;
    }
    if (router.currentRoute.value.name === 'pumpassignment') {
      this.pumpAssignmentPage = true;
    }
  },
  methods: {
    toggle() {
      if (this.data.equipmentStatus !== 'Naar Mediq') {
        this.isExpanded = !this.isExpanded;
      }
      if (this.pumpAssignmentPage) {
        this.isExpanded = false;
        if (this.data.equipmentID) {
          let radioButton = document.getElementById('rdb' + this.data.equipmentID);
          radioButton.checked = true;
          this.currentProductId = 0;
        } else {
          let radioButton = document.getElementById('rdb' + this.data.pumpDesc.split('-')[0]);
          radioButton.checked = true;
          this.currentProductId = this.data.pumpDesc.split('-')[0];
        }
        this.pumpSelected = true;
        this.currentPumpId = this.data.equipmentID;
      } else {
        this.pumpSelected = false;
      }
    },
    getContentCss() {
      if (this.pumpAssignmentPage) {
        this.isPumpHover = !this.isPumpHover;
      }
    },
    toPatientDashboard() {
      this.setActivePatient(this.data.patientId).then(() => {
        router.push('/patientdashboard');
      });
    },
    imageLoadError() {
      console.warn('Image without product');
    },
    getStatusColor(equipmentStatus) {
      switch (equipmentStatus) {
        case 'Allocated':
          return 'pumpOverview-row__cell__dynamicField__unavailable';
        case 'Available':
          return 'pumpOverview-row__cell__dynamicField__available';
        case 'Naar Mediq':
          return 'pumpOverview-row__cell__dynamicField__unavailable';
        case 'Lend':
          return this.userWarehouseId === this.data.lendoutWarehouseId
            ? 'pumpOverview-row__cell__dynamicField__available'
            : 'pumpOverview-row__cell__dynamicField__unavailable';
        case 'LendAndAssigned':
          return 'pumpOverview-row__cell__dynamicField__unavailable';
        case '':
          return 'pumpOverview-row__cell__dynamicField__unknown';
        default:
          return 'pumpOverview-row__cell__dynamicField__nocolor';
      }
    },
    getStatusColorForDaysLeftForMaintenance(daysLeftForMaintenance) {
      if (daysLeftForMaintenance >= 30) return 'pumpOverview-row__cell__dynamicField__available';

      if (daysLeftForMaintenance < 30 && daysLeftForMaintenance >= 14)
        return 'pumpOverview-row__cell__dynamicField__unknown';

      if (daysLeftForMaintenance < 14) return 'pumpOverview-row__cell__dynamicField__unavailable';

      return 'pumpOverview-row__cell__dynamicField__nocolor';
    },
    getStatusField(equipmentStatus) {
      switch (equipmentStatus) {
        case 'Allocated':
          return 'Bij patiënt';
        case 'Available':
          return 'In depot';
        case 'Lend':
          return this.userWarehouseId === this.data.lendoutWarehouseId ? 'In depot, geleend' : 'Uitgeleend';
        case 'LendAndAssigned':
          return this.userWarehouseId === this.data.lendoutWarehouseId
            ? 'Bij patient, geleend'
            : 'Uitgeleend en bij patient';
        case '':
          return 'Unknown';
        default:
          return equipmentStatus;
      }
    },
    pumpLending() {
      this.isPumpLending = true;
    },
    closeModal() {
      this.setWarehouses([]);
      this.setIsPumpReturn(false);
      this.setShowWarning(false);
      this.isPumpLending = false;
      this.postcodeError = false;
    },
    closeSucessModal() {
      this.setPumpLendingSuccess(false);
      this.setWarehouses([]);
      this.setShowWarning(false);
      this.isPumpLending = false;
      this.postcodeError = false;
      this.$router.go();
    },
    selectWarehouse(e) {
      e.stopPropagation();
      let id = parseInt(e.currentTarget.value, 10);
      this.updateCurrentWarehouseId(id);
    },
    showPumpReturnPopup() {
      this.setIsPumpReturn(true);
    },
    getWarehouseListData() {
      this.validate().then(result => {
        if (result.valid) {
          this.getWarehouseList(this.lendingPostalCode);
        }
      });
    }
  }
};
</script>

<style lang="css">
.pumpOverview-row .pumpOverview-row__selectDept .form-group label {
  padding-left: 10px;
}
.pumpOverview-row .pumpOverview-row__selectDept .form-group--hasvalue label {
  display: none;
}
.pumpOverview-row .form-group input {
  line-height: 1.5rem;
  color: #666;
  padding-left: 10px;
  border: 1px solid;
  border-color: #999;
  border-radius: 3px;
  transition: opacity 0.2s ease-in-out;
  box-sizing: border-box;
  margin-right: 0;
  height: 49px;
}
</style>

<style lang="scss" scoped>
.pumpOverview-row {
  background-color: $table-row-background;
  border: $table-row-border;
  transition: $search-transition;
  .cursorClass {
    cursor: pointer;
  }
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__center {
    text-align: center;
  }
  &__backToDashboard {
    color: $teal-darkest;
    text-decoration: underline;
  }
  &__cell {
    padding: $table-row-license-cell-padding;
    &__detail {
      text-align: right;
      margin-right: 20px;
      font-style: italic;
      color: $teal-darkest;
    }
    &__button {
      float: right;
      margin-right: 20px;
      width: 183px;
      height: 45px;
      .material-design-icon {
        padding-right: 5px;
      }
    }
    &__expandIcon {
      float: right;
    }
    @mixin list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    &__type {
      @include list;
      font-weight: bold;
      color: $table-row-license-cell-type-color;
    }
    &__desc {
      @include list;
      color: $teal-darkest;
      font-weight: bold;
    }
    &__serialno {
      @include list;
    }
    &__textbox {
      width: 200px;
    }
    &__img {
      @include list;
      max-height: 75px;
      margin-left: 5px;
    }
    &__rdb {
      margin-top: 34px;
      margin-left: 20px;
      margin-right: -10px;
    }
    &__dynamicField {
      @include list;
      width: 180px;
      .material-design-icon {
        display: inline;
        :deep(svg) {
          width: 15px;
          height: 15px;
        }
      }
      &__available {
        .material-design-icon {
          fill: $cyan-darker;
        }
      }
      &__unavailable {
        .material-design-icon {
          fill: $red;
        }
      }
      &__unknown {
        .material-design-icon {
          fill: $orange;
        }
      }
      &__nocolor {
        .material-design-icon {
          fill: transparent;
        }
      }
    }
    &__prescriber {
      color: $table-row-license-cell-prescriber-color;
      font-size: $table-row-license-cell-prescriber-font-size;
      line-height: $table-row-license-cell-prescriber-line-height;
      margin-left: 5px;
      margin-top: 0px;
      &__author {
        font-weight: $table-row-license-cell-prescriber-font-weight;
      }
    }
    &__actions {
      margin-top: 15px;
      margin-left: 5px;
      display: flex;
      .btn {
        outline: 0;
        margin-right: 10px;
        .material-design-icon {
          padding-right: 0;
        }
      }
    }
    > span > .material-design-icon {
      display: flex;
      justify-content: center;
      :deep(svg) {
        display: block;
      }
    }
    .icon-menu-down {
      fill: $table-row-icon-menu-down;
      margin-top: 0;
      transition: $table-transition-rotate;
      cursor: pointer;
    }
  }
  &.is-expanded {
    .icon-menu-down {
      transform: rotate(-180deg);
    }
  }
  &__pumpHover {
    background-color: $teal-lighter;
  }
}
.pumpOverviewLending {
  &__header {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $teal-darkest;
  }
  &__text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $gray-darkest;
    padding-top: 10px;
  }
  &__successtext {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: $gray-darkest;
    padding-top: 10px;
  }
  &__closeIcon {
    float: right;
    margin: -9px;
    cursor: pointer;
    fill: $teal-darkest;
    :deep(svg) {
      width: 28px !important;
      height: 28px !important;
    }
  }
  &__search-icon {
    position: absolute;
    background: $teal-darkest;
    right: 33px;
    height: 49px;
    width: 48px;
    cursor: pointer;
    :deep(svg) {
      width: 40px !important;
      height: 40px !important;
      padding-left: 12px;
      padding-top: 6px;
    }
  }
  &__selectDept {
    padding-top: 8px;
  }
  &__leftButton {
    text-decoration: none;
    font-weight: bold;
    color: $teal-darkest;
    margin-top: 30px;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 53%;
  }
  &__rightButton {
    position: relative;
    top: 0;
    left: 58%;
    margin-top: 15px;
  }
  &__return_rightButton {
    position: relative;
    top: 0;
    left: 48%;
    margin-top: 15px;
  }
  &__return_leftButton {
    text-decoration: none;
    font-weight: bold;
    color: $teal-darkest;
    margin-top: 30px;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 43%;
  }
  &__return_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $gray-darkest;
    padding-top: 10px;
  }
  &__centerButton {
    position: relative;
    top: 0;
    left: 38%;
    margin-top: 15px;
  }
  &__icon {
    display: inline-block;
    margin: 0px 5px 0px 205px;
    vertical-align: middle;
    width: 125px;
    border: 2px solid $cyan-darker;
    border-radius: 60px;
    box-shadow: 0px 0px 5px $cyan-darker;
    position: relative;
    overflow: hidden;
    :deep(svg) {
      width: 20px;
      height: 20px;
    }
  }
  &__checkicon {
    fill: $dark-green;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    vertical-align: middle;
    :deep(svg) {
      width: 45px !important;
      height: 45px !important;
    }
  }
  &__topRightArrowicon {
    padding-right: 10px;
    width: 35px;
  }
}
</style>
