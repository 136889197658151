<template>
  <section class="contactdetails">
    <div class="row">
      <div class="col-xs-12">
        <v-header title="Contact" />
        <div class="contactdetails__content">
          <div
            class="contactdetails__phoneNumbers"
            v-html="contactInfo.PhoneNumbers"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VHeader from '@/components/shared/Header.vue'
import { computed } from 'vue'
import { useStore } from "vuex";

export default {
  name: 'ContactDetails',
  components: {
    VHeader
  },
  setup() {
    const store = useStore();
    
    const contactInfo = computed(() => store.getters['contact/getContactInfo']);
    store.dispatch('contact/fetchContactInfo');

    return {
      contactInfo
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.contactdetails {
  &__content {
    padding: 0px 24px 24px 24px;
  }
  &__phoneNumbers {
    :deep(td, th) {
      padding-right: 35px;
      padding-bottom: 15px;
      }
  }
}
</style>