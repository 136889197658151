<template>
  <section class="container release">  
    <div class="row">        
      <v-heading :level="1">
        Verbeteringen
      </v-heading>
    </div>
    <form @submit.prevent="validateBeforeSubmit">
      <div class="row">
        <div class="release__block col-xs-12">
          <div class="release__block__header">
            <h4>Recente verbeteringen</h4>
          </div>
          <legend v-html="releaseInfo.Content" />
        </div>
      </div>
      <div class="row">
        <div class="release__block col-xs-12">
          <div class="release__block__header">
            <h4>Suggesties</h4>
          </div>
          <legend>
            Heb je suggesties voor dit platform? Vul dan het formulier hieronder in. Je kan kan er ook voor kiezen om het bericht anoniem te versturen. Klik hiervoor het vinkje aan onder het bericht.
          </legend>
          <div class="release__block__feedback">
            <v-textarea-group
              v-model="formData[formConfig.comments.ref]"
              :error="!!formErrors[formConfig.comments.ref]"
              :error-message="formErrors[formConfig.comments.ref]"
              :rule="formConfig.comments.rule"
              :label="formConfig.comments.label"
              :inputid="formConfig.comments.ref"
              @blur="validateField(formConfig.comments.ref, formData[formConfig.comments.ref])"
            />
          </div>
          <legend>
            <v-checkbox-group
              v-model="formData[formConfig.anonymousEnabled.ref]"
              :initvalue="formConfig.anonymousEnabled.model"
              :error="!!formErrors[formConfig.anonymousEnabled.ref]"
              :error-message="formConfig.anonymousEnabled.errorMessage"
              :rule="formConfig.anonymousEnabled.rule"
              :label="formConfig.anonymousEnabled.label"
              :inputid="formConfig.anonymousEnabled.ref"
            />
          </legend>
        </div>
      </div>
      <div class="row grid-justify-content-right">
        <div class="col-xs-2">
          <v-button
            md
            primary
            tagelement="button"
          >
            VERSTUREN
          </v-button>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import { mapActions, useStore } from 'vuex'
import { computed } from 'vue'
import { useForm } from 'vee-validate';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup'
import router from '@/router'

export default {
  name: 'VReleases',
  components: {
    VCheckboxGroup
  },
  setup() {
    const store = useStore();
    const releaseInfo = computed(() => store.getters['releases/getReleaseInfo']);
    
    store.dispatch('releases/fetchReleaseInfo');

    return {
      releaseInfo,
      ...mapActions({submitedReleaseForm: 'releases/submitReleaseForm'})
    }
  },
  data () {
    const { validate } = useForm();

    return {
      validate,
      formData: {
        AnonymousEnabled: false
      },
      formErrors: {
        Comments: undefined
      }
    }
  },
  computed: {
    formConfig () {
      return {
        comments: {
          ref: 'Comments',
          label: 'Uw bericht:',
          attached: true,
          alias: 'Uw bericht:',
          rule: {
            required: true
          }
        },
        anonymousEnabled: {
          ref: 'AnonymousEnabled',
          alias: 'Anoniem insturen',
          label: 'Anoniem insturen',
          rule: {
            required: false
          },
          attached: true
        }
      }
    }
  },
  created () {

  },
  methods: {
    validateBeforeSubmit () {
      this.validateAllFields()
    },
   async validateAllFields () {
      const result = await this.validate();
      if (result.valid) {
          this.submitedReleaseForm(this.formData).then((response) => {
            if (response === true) {
              this.$emit('success')
              this.$emitter.emit('alert', { message: 'Bedankt, uw suggestie is met succes verstuurd!' })
            } else {
              this.$emitter.emit('alert', { message: 'Oops het versturen ging niet helemaal goed. Laten we dit als eerste maar eens verbeteren.' })
            }
            setTimeout(() => {
              router.push('/')
            }, 2000)
          })
        } else {
        }
      
    },
  }
}
</script>

<style lang="scss" scoped>
  .release {
    font-size: 16px;
    &__block {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $white;
      margin: 20px 0px 20px 0px;
      legend {
        padding: 15px 20px 15px 20px;
        color: $gray-darkest;
      }
      &__header {
        background-color: rgba(0, 0, 0, 0.03);
        h4 {
          color: $grayish-very-dark;
          font-size: 16px;
          font-weight: 400;
          padding: 0px 20px 0px 20px;
        }
      }
      &__feedback {
        padding: 0px 20px;
      }
    }
  } 
</style>
