<template>
  <div class="patientTransfer">
    <form v-if="showForm" class="patientTransfer__form" @submit.prevent="savePatientTransfer">
      <h4>
        {{ patientTransferPopUpTexts.Description }}
      </h4>
      <div v-if="showfavoritePrescribers" class="row grid-align-center">
        <div class="col-xs-12">
          <model-select
            v-model="selectedFavorite"
            placeholder="Mijn opgeslagen voorschrijvers"
            :options="favoritePrescribers"
            :selected-option="selectedFavorite"
            @blur="onSelect"
          />
        </div>
      </div>
      <div v-if="showSearch" class="row grid-margin grid-align-center">
        <div class="col-xs-12 patientTransfer__or">
          <v-search-dropdown
            :placeholder="patientTransferPopUpTexts.SearchByBigOrEmailTextFieldPlaceholder"
            suggest-header="Voorschrijver"
            :options="prescribers"
            suggest-additional-header="Afdeling"
            :additional-options="departments"
            @search-email="getSearchEmail"
            @select-option="selectPrescriberOrDepartment"
          />
        </div>
        <div class="form-group-simple__details">
          <div class="form-group-simple__messages">
            <div class="form-group-simple__error">
              {{ emailValidationMessage }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSuccessMessage" class="row grid-align-center" :class="{ 'flipInX animated': showSuccessMessage }">
        <div class="col-md-12 patientTransfer__successMessage">
          <v-check-circle-icon class="patientTransfer__successMessage__icon" />
          <span>Voorschrijver {{ selectedPrescriberFavorite }} succesvol gevonden!</span>
        </div>
      </div>
      <div v-if="showDepartments" class="row">
        <v-select-group
          selectid="patientTransferDepartments"
          :model="selectedDepartment"
          :initselected="selectedDepartment"
          @change="select($event)"
        >
          <option value="" disabled selected>&nbsp;&nbsp;&nbsp;Afdeling selecteren</option>
          <option
            v-for="(department, index) in prescribersDepartments"
            :key="'department' + index"
            :data-index="department.PpwRecId"
            :value="department.PpwRecId"
          >
            &nbsp;&nbsp;&nbsp;{{ department.DepartmentName }} - {{ department.InstitutionName }}
          </option>
        </v-select-group>
      </div>
      <transition name="fade">
        <div v-if="showComments">
          <v-textarea-group
            v-model="remarkComments"
            :class="{ redborder: charactersRemaining < 0 }"
            label="Op- of aanmerkingen voor uw collega"
            inputid="Op- of aanmerkingen voor uw collega"
            @input="saveInput"
          />
          <small v-if="allowedCharacterLimit" class="characters-remaining">
            <b>{{ charactersRemaining }} </b> tekens resterend
          </small>
          <small v-else class="characters-remaining__red">
            <b>{{ Math.abs(charactersRemaining) }} </b> tekens teveel
          </small>
        </div>
      </transition>
      <v-button
        v-if="showFavorite"
        class="patientTransfer__favoriteBtn"
        tagelement="button"
        md
        secondary
        icon-left
        @click.prevent="addFavorite()"
      >
        <heart />
        Toevoegen aan favorieten
      </v-button>
      <div v-if="showWarningMessage" class="row grid-align-center patientTransfer__warning">
        <v-warning-message
          :warning="warningDetails.warning"
          :message="warningDetails.message"
          :html="false"
          :is-urgent="false"
        />
      </div>
      <div class="row grid-justify-content-right">
        <div class="patientTransfer__btns">
          <v-button hollow transparent @click="closeOverlay"> Annuleren </v-button>
          <v-button primary lg :disabled="!enableTransferBtn"> Versturen </v-button>
        </div>
      </div>
    </form>
    <div v-if="showSuccess" class="patientTransfer__success">
      <v-check-circle-icon class="patientTransfer__success__icon" />
      <legend>Patiënt is succesvol overgedragen!</legend>
      <div class="row grid-margin">
        <div class="patientTransfer__success__imgHolder">
          <img
            src="@/static/img/succes_mascot.png"
            class="patientTransfer__success__icon"
            :class="{ 'slideInUp animated': showSuccess }"
          />
        </div>
      </div>
      <v-button class="patientTransfer__success__continue" tagelement="button" md primary @click="closeOverlay">
        Ga verder
      </v-button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import heart from 'icons/Heart';
import VCheckCircleIcon from 'icons/CheckCircle';
import VWarningMessage from '@/components/shared/WarningMessage';
import VSearchDropdown from '@/components/shared/SearchDropdown';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';
import { dispatchCommand, activeCustomerId$, activePpwRecId$, customerSecurityPolicySettings$ } from '@/services';

export default {
  name: 'VPatienttransferForm2',
  components: {
    heart,
    VCheckCircleIcon,
    VWarningMessage,
    VSearchDropdown
  },
  setup() {
    const store = useStore();
    const activeCustomerId = ref(null);
    const activePpwRecId = ref(null);
    const customerSecurityPolicySettings = ref(null);

    const patientGegevens = computed(() => store.getters['patient/getPatientGegevens']);
    const prescribers = computed(() => store.getters['patienttransfer/getPrescribersData']);
    const departments = computed(() => store.getters['patienttransfer/getDepartmentsData']);
    const favoritePrescribers = computed(() => store.getters['favoritePrescribers/getFavoritePrescribers']);
    const patientTransferPopUpTexts = computed(() => store.getters['patienttransfer/getPatientTransferPopUpTexts']);

    const savePrescriberFavorite = (value) => store.dispatch('favoritePrescribers/savePrescriberFavorite', value);
    const fetchPrescriberFavorites = () => store.dispatch('favoritePrescribers/fetchPrescriberFavorites');
    const fetchPrescribersOrDepartments = (value) =>
      store.dispatch('patienttransfer/fetchPrescribersOrDepartments', value);

    activeCustomerId$.subscribe((value) => (activeCustomerId.value = value));
    activePpwRecId$.subscribe((value) => (activePpwRecId.value = value));
    customerSecurityPolicySettings$.subscribe((value) => (customerSecurityPolicySettings.value = value));

    return {
      patientGegevens,
      prescribers,
      departments,
      favoritePrescribers,
      patientTransferPopUpTexts,
      savePrescriberFavorite,
      fetchPrescriberFavorites,
      fetchPrescribersOrDepartments,
      activeCustomerId,
      activePpwRecId,
      customerSecurityPolicySettings
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      selectedFavorite: {
        value: null,
        text: null
      },
      departmentId: null,
      characterLimit: 160,
      showSearch: true,
      isPrescribersEmpty: false,
      showComments: false,
      remarkComments: '',
      prescriberEmail: '',
      departmentPpwRecId: '',
      showSuccessMessage: false,
      showDepartmentList: false,
      selectedPrescriberFavorite: '',
      selectedPrescriberFavoriteId: '',
      selectedDepartment: '',
      showForm: true,
      showSuccess: false,
      showFavorite: false,
      showWarningMessage: false,
      showDepartments: false,
      showfavoritePrescribers: true,
      enableTransferBtn: false,
      prescribersDepartments: [],
      showPrescriberDropDown: false,
      emailValidationMessage: '',
      warningDetails: {
        warning: 'Helaas is er geen voorschrijver of afdeling gevonden met bovenstaande e-mailadres',
        message: 'Probeer het nogmaals.'
      },
      searchPrescribersStatus: false
    };
  },
  computed: {
    charactersRemaining() {
      return this.remarkComments ? this.characterLimit - this.remarkComments.length : this.characterLimit;
    },
    allowedCharacterLimit() {
      return this.remarkComments ? this.remarkComments.length <= this.characterLimit : this.characterLimit;
    },
    transferredTo() {
      return (this.customerSecurityPolicySettings && this.customerSecurityPolicySettings.transferredTo) || null;
    }
  },
  watch: {
    prescribers(value) {
      if (!Array.isArray(value)) {
        this.showWarningMessage = false;
        this.warningDetails.warning = value;
        return;
      }
      if (value.length === 1) {
        this.prescribersDepartments = value[0].DepartmentInstitution;
        this.selectedPrescriberFavoriteId = value[0].FavoritePresciberId;
        this.selectedPrescriberFavorite = value[0].Initials + ' ' + value[0].LastName;
        this.isPrescribersEmpty = false;
      }
      if (value.length === 0) {
        this.isPrescribersEmpty = true;
      }
      if (value.length > 1) {
        this.isPrescribersEmpty = false;
        this.showPrescriberDropDown = true;
      }
    },
    departments(value) {
      if (!Array.isArray(value)) {
        this.showWarningMessage = true;
        this.warningDetails.warning =
          'Helaas is er geen voorschrijver of afdeling gevonden met bovenstaande e-mailadres';
        return;
      }
      if (value.length === 1) {
        this.showPrescriberDropDown = false;
        this.showfavoritePrescribers = false;
      }
      if (value.length === 0 && this.isPrescribersEmpty) {
        this.showWarningMessage = true;
        this.warningDetails.warning =
          'Helaas is er geen voorschrijver of afdeling gevonden met bovenstaande e-mailadres';
      }
      if (value.length > 1) {
        this.showPrescriberDropDown = false;
        this.showfavoritePrescribers = false;
      }
    }
  },
  created() {
    this.fetchPrescriberFavorites();
  },
  methods: {
    saveInput(event) {
      this.remarkComments = event.target ? event.target.value : null;
    },
    onSelect() {
      this.enableTransferBtn = true;
      this.selectedPrescriberFavorite = '';
      this.selectedPrescriberFavoriteId = '';
      this.selectedDepartment = '';
      this.showPrescriberDropDown = false;
      this.showComments = true;
    },
    getSearchEmail(searchEmail, isValid, message) {
      this.prescriberEmail = searchEmail;
      this.emailValidationMessage = '';
      if (isValid) {
        this.searchPrescribersOrDepartments();
        this.emailValidationMessage = '';
      } else {
        this.emailValidationMessage = message;
      }
    },
    savePatientTransfer() {
      let cmd = {
        customerId: this.activeCustomerId,
        fromPpwRecId: this.activePpwRecId,
        comments: this.remarkComments,
        transferredToDepartmentId: 0
      };
      if (this.departmentId > 0) {
        cmd.transferredToDepartmentId = this.departmentId;
        this.selectedDepartment = this.departmentPpwRecId;
      }
      if (this.selectedFavorite.value) {
        cmd.toPpwRecId = this.selectedFavorite.ppwrecId;
      } else if (this.selectedDepartment) {
        cmd.toPpwRecId = this.selectedDepartment;
      }
      if (!cmd.toPpwRecId && this.departmentId < 1) {
        return;
      }
      if (this.transferredTo) {
        if (this.transferredTo.ppwRecId === cmd.toPpwRecId) {
          this.showWarningMessage = true;
          this.warningDetails.warning = 'Deze patient is al overgedragen aan de geselecteerde voorschrijver';
          return;
        }
      }

      dispatchCommand('TransferCustomerToUser', cmd).then(() => {
        this.showForm = false;
        this.showSuccess = true;

        /*if (error && error.response && error.response.data) {
          this.showWarningMessage = true
          this.warningDetails.warning = error.response.data.Message
        }*/
      });
    },
    closeOverlay() {
      this.prescribers = [];
      this.departments = [];
      this.$emit('success');
    },
    saveDetails(event) {
      this.prescriberEmail = event.target.value;
    },
    select(event) {
      if (event.target.value) {
        this.selectedDepartment = event.target.value;
        this.enableTransferBtn = true;
        this.showFavorite = true;
      }
    },
    selectPrescriberOrDepartment(event, isPrescriber) {
      if (isPrescriber) {
        this.selectedPrescriberFavoriteId = event.UserId;
        this.showDepartments = true;
        this.showSearch = true;
        this.showfavoritePrescribers = false;
        this.showComments = true;
        let prescriberIndex = this.prescribers.findIndex(
          (prescriber) => prescriber.UserId === parseInt(event.UserId, 10)
        );
        if (prescriberIndex > -1) {
          this.prescribersDepartments = this.prescribers[prescriberIndex].DepartmentInstitution;
        }
      } else {
        this.departmentId = event.DepartmentId;
        this.departmentPpwRecId = event.PpwRecId;
        this.enableTransferBtn = true;
        this.showSearch = true;
        this.showFavorite = true;
        this.showfavoritePrescribers = false;
        this.showComments = true;
      }
    },
    addFavorite() {
      let details = {
        PpwRecId: this.selectedDepartment
      };
      if (this.departmentId > 0) {
        this.selectedDepartment = this.departmentPpwRecId;
      }
      this.savePrescriberFavorite(details)
        .then((response) => {
          if (response && response.data) {
            this.fetchPrescriberFavorites();
            this.showSuccessMessage = false;
            this.showDepartments = false;
            this.prescriberEmail = '';
            this.showFavorite = false;
            this.showWarningMessage = false;
            this.warningDetails.warning = '';
          }
        })
        .catch((error) => {
          if (error && error.response) {
            this.showWarningMessage = true;
            this.warningDetails.warning = error.response.data.Message;
          }
        });
    },
    searchPrescribersOrDepartments() {
      this.searchPrescribersStatus = true;
      this.selectedFavorite = {
        value: null,
        text: null
      };

      this.showPrescriberDropDown = false;
      this.showDepartments = false;
      this.showSuccessMessage = false;
      this.prescribersDepartments = [];
      this.departments = [];
      this.prescribers = [];
      this.selectedPrescriberFavorite = '';
      this.selectedPrescriberFavoriteId = '';
      this.showWarningMessage = false;
      this.showFavorite = false;
      $validateAllComponents(this).then((result) => {
        if (!result) {
          return;
        }
        this.fetchPrescribersOrDepartments(this.prescriberEmail);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.patientTransfer {
  &__btns {
    display: flex;
  }
  h1 {
    font-size: 24px;
    color: $gray-darkest;
    font-weight: bold;
  }
  h4 {
    margin-top: 0px;
  }
  color: $gray-darkest;
  &__or {
    text-align: center;
    margin-top: 30px;
  }
  &__email {
    position: relative;
  }
  &__search {
    cursor: pointer;
    background: $gray-light;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 50px;
    border-radius: 0px 3px 3px 0px;
    .material-design-icon {
      margin: 12px 0px 0px 10px;
    }
    &__active {
      background: $teal-darkest;
    }
  }
  &__successMessage {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 30px 0px 30px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      vertical-align: middle;
      :deep(svg) {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__favoriteBtn {
    margin: 25px auto;
  }
  &__success {
    text-align: center;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      vertical-align: middle;
      :deep(svg) {
        width: 40px;
        height: 40px;
      }
    }
    legend {
      font-size: 18px;
      margin: 25px auto;
    }
    &__imgHolder {
      border: 2px solid $cyan-darker;
      border-radius: 50%;
      margin: 0px auto;
      box-shadow: 0px 0px 5px $cyan-darker;
      position: relative;
      overflow: hidden;
    }
    img {
      width: 125px;
      height: 125px;
    }
    &__continue {
      margin: 20px auto;
    }
  }
  &__warning {
    margin: 25px 0px 25px 0px;
  }
}
</style>
