<template>
  <section class="prescriberRegistration">
    <!-- Header section -->
    <div class="row prescriberRegistration__header">
      <div class="prescriberRegistration__logo">
        <router-link to="/">
          <img :src="portalLogo" alt="Mediq logo" />
        </router-link>
      </div>
      <div class="prescriberRegistration__title">Registratie</div>
    </div>
    <!-- Progress bar section -->
    <div class="row grid-align-center prescriberRegistration__progressSteps">
      <v-step-progress :steps="prescriberRegistrationSteps" />
    </div>
    <!-- step 1 -->
    <v-prescriberRegistration-step1 v-if="getStepVisibilityStatus(0)" />
    <!-- step 2 -->
    <v-prescriberRegistration-step2 v-if="getStepVisibilityStatus(1)" />
    <!-- step 3 -->
    <v-prescriberRegistration-step3 v-if="getStepVisibilityStatus(2)" />
    <!-- step 4 -->
    <v-prescriberRegistration-step4 v-if="getStepVisibilityStatus(3)" />
    <!-- step 5 -->
    <v-prescriberRegistration-step5 v-if="getStepVisibilityStatus(4)" />
    <!-- step 6 -->
    <v-prescriberRegistration-step6 v-if="getStepVisibilityStatus(5)" />
  </section>
</template>
<script>
import { useStore, mapActions } from 'vuex';
import { computed } from 'vue';
import VStepProgress from '@/components/shared/ProgressSteps';
import VPrescriberRegistrationStep1 from './registrationStep1';
import VPrescriberRegistrationStep2 from './registrationStep2';
import VPrescriberRegistrationStep3 from './registrationStep3';
import VPrescriberRegistrationStep4 from './registrationStep4';
import VPrescriberRegistrationStep5 from './registrationStep5';
import VPrescriberRegistrationStep6 from './registrationStep6';

export default {
  name: 'PrescriberRegistration',
  components: {
    VStepProgress,
    VPrescriberRegistrationStep1,
    VPrescriberRegistrationStep2,
    VPrescriberRegistrationStep3,
    VPrescriberRegistrationStep4,
    VPrescriberRegistrationStep5,
    VPrescriberRegistrationStep6
  },
  setup() {
    const store = useStore();

    const portalLogo = computed(() => store.getters['navigation/getPortalLogo']);
    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const prescriberRegistrationSteps = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationSteps']
    );
    const prePositionsData = computed(() => store.getters['prescriberRegistration/getPrePositionsRecords']);

    return {
      portalLogo,
      prescriberRegistrationDetails,
      prescriberRegistrationSteps,
      prePositionsData,
      ...mapActions('navigation', ['fetchPortalLogo']),
      ...mapActions('prescriberRegistration', ['fetchRegistrationTexts']),
      ...mapActions('prescriberRegistration', ['fetchPrePositions'])
    };
  },
  data() {
    return {};
  },
  created() {
    this.fetchPortalLogo();
    this.fetchRegistrationTexts();
    this.fetchPrePositions();
  },
  methods: {
    getStepVisibilityStatus(step) {
      return this.prescriberRegistrationSteps[step].show;
    }
  }
};
</script>
<style lang="scss" scoped>
.prescriberRegistration {
  position: absolute;
  width: 100%;
  background-color: $teal-lightest;
  min-height: 700px;
  color: $gray-darkest;
  &__logo {
    @include min-screen(grid-breakpoint(xs)) {
      margin: 25px 0px 25px 50px;
    }
    img {
      display: block;
      max-height: 60px;
      margin: 0 auto;
      width: 161px;
    }
  }
  &__header {
    background: $white;
  }
  &__progressSteps {
    margin: 30px 0px 30px 0px;
  }
  &__title {
    line-height: 5 !important;
    padding: 10px;
    color: $gray-darkest;
    font-size: $base-font-size;
    font-weight: $heading-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
}
</style>
