import axios from 'axios';

let resolveFetchResult = null;
const fetchResultPromise = new Promise(function (resolve, reject) {
  resolveFetchResult = resolve;
});

const store = {
  namespaced: true,
  state: {
    hamburgerMenuActive: true,
    results: {},
    showSearch: false,
    stamDataLanden: {},
    stamDataGeslacht: {},
    portalLogo: '/mediq_logo.svg',
    backgroundLogo: null,
    userMarketingPermission: false,
    registrationDetails: {},
    alertMessageDetails: {},
    warehouses: null,
    siteCode: '',
    showOneChildStatus: true
  },
  mutations: {
    setShowOneChildStatus(state, payload) {
      state.showOneChildStatus = payload;
    },
    setHamburgerMenuActive(state, payload) {
      state.hamburgerMenuActive = payload;
    },
    setStamDataLanden(state, payload) {
      state.stamDataLanden = payload;
    },
    setStamDataGeslacht(state, payload) {
      state.stamDataGeslacht = payload;
    },
    setBackgroundLogo(state, payload) {
      state.backgroundLogo = payload.bgImage;
    },
    setUserMarketingPermission(state, payload) {
      state.userMarketingPermission = payload;
    },
    setRegistrationDetails(state, payload) {
      state.registrationDetails = payload.registration;
    },
    setAlertMessageDetails(state, payload) {
      state.alertMessageDetails = {
        loginHighPrioMessage: payload.loginHighPrioMessage,
        internetExplorerAlertMessage: payload.internetExplorerAlertMessage
      };
    },
    setWarehouses(state, payload) {
      state.warehouses = payload;
    },
    setUserSiteCode(state, payload) {
      state.siteCode = payload;
    }
  },
  actions: {
    updateResults({ commit }, response) {
      resolveFetchResult(response);
      commit('setUserMarketingPermission', response.usermarketingpermission);
      commit('setWarehouses', response.warehouses);
      commit('setUserSiteCode', response.siteCode);
    },
    fetchResults({ commit }) {
      return fetchResultPromise;
    },
    fetchStamDataLanden({ commit }) {
      return axios.get('StamData/GetLanden').then((response) => {
        commit('setStamDataLanden', response.data);
      });
    },
    // Not used yet (maybe remove it from the /F34a/Init when necessary)
    fetchStamDataGeslacht({ commit }) {
      return axios.get('StamData/GetGeslachten').then((response) => {
        commit('setStamDataGeslacht', response.data);
      });
    },
    fetchPortalLogo({ commit }) {
      return axios.get('nav/GetPortalDetails').then((response) => {
        commit('setBackgroundLogo', response.data);
        commit('setRegistrationDetails', response.data);
        commit('setAlertMessageDetails', response.data);
      });
    }
  },
  getters: {
    getShowOneChildStatus: (state) => {
      return state.showOneChildStatus;
    },
    gethamburgerMenuActive: (state) => {
      return state.hamburgerMenuActive;
    },
    getStamDataLanden: (state) => {
      return state.stamDataLanden;
    },
    getStamDataGeslacht: (state) => {
      return state.stamDataGeslacht;
    },
    getPortalLogo: (state) => {
      return state.portalLogo;
    },
    getBackgroundLogo: (state) => {
      return state.backgroundLogo;
    },
    getUserMarketingPermission: (state) => {
      return state.userMarketingPermission;
    },
    getRegistrationDetails: (state) => {
      return state.registrationDetails;
    },
    getAlertMessageDetails: (state) => {
      return state.alertMessageDetails;
    },
    getWarehouses: (state) => {
      return state.warehouses;
    },
    getSiteCode: (state) => {
      return state.siteCode;
    }
  }
};

export default store;
