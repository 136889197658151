<template>
  <section class="login">
    <div class="row grid-padding">
      <div class="col-xs-12 col-md-4 col-lg-3">
        <div v-if="showLoginIssueAlert" class="loginIssueAlertMessage">
          <v-warning-message
            :class="{ 'zoomOut animated': animated }"
            :warning="alertMessageDetails.loginHighPrioMessage.message"
            :html="false"
            :is-urgent="false"
            :show-close="true"
            @close="closeWarning()"
          />
        </div>
        <div v-if="showIEAlert" class="alertMessageForIE">
          <v-warning-message
            :class="{ 'zoomOut animated': animated }"
            :warning="alertMessageDetails.internetExplorerAlertMessage.message"
            :html="true"
            :is-urgent="false"
          />
        </div>
        <div class="logo">
          <img :src="portalLogo" alt="Mediq logo" />
        </div>
        <username-password-login />
      </div>
      <generic-modal v-if="showBrowserRelatedPopup" :has-close-button="true" @close="closeModal">
        <template #header />
        <template #body>
          <p>
            Mediq maakt gebruik van beveiligingsopties die mogelijk niet door uw browser worden ondersteund. Wij raden u
            aan om spoedig een recente versie van uw browser te installeren.
          </p>
        </template>
      </generic-modal>
      <div
        class="col-xs-12 col-md-8 col-lg-9 loginimage show-from-md"
        :style="{ background: 'url(data:image/jpg;base64,' + backgroundLogo + ') center center no-repeat' }"
      />
    </div>
  </section>
</template>

<script>
import { ref, onBeforeMount, computed, watchEffect } from 'vue';
import { useBrowserDetect } from 'vue3-detect-browser';
import router from '@/router';
import GenericModal from '@/components/shared/GenericModal';
import UsernamePasswordLogin from './UsernamePasswordLogin';
import { isLoggedIn$, currentUserIdentity$ } from '@/services';
import VWarningMessage from '@/components/shared/WarningMessage';
import { combineLatest } from 'rxjs';
import { useStore, mapActions } from 'vuex';

export default {
  name: 'LoginPage',
  components: {
    GenericModal,
    UsernamePasswordLogin,
    VWarningMessage
  },
  setup() {
    const store = useStore(); // Use useStore to access the store
    const showLoginIssueAlert = ref(false);
    const animated = ref(false);
    const timer = ref(null);
    const currentUserIdentity = ref(null);
    const isLoggedIn = ref(false);
    const isSecurityPolicyExpired = ref(false);

    const portalLogo = computed(() => store.getters['navigation/getPortalLogo']);
    const backgroundLogo = computed(() => store.getters['navigation/getBackgroundLogo']);
    const alertMessageDetails = computed(() => store.getters['navigation/getAlertMessageDetails']);
    const { isFirefox, isChrome, isIE, isEdge, isSafari, meta } = useBrowserDetect();
    const showBrowserRelatedPopup = computed(() => {
      let status = false;
      if (
        (isChrome && meta.version < 30) ||
        (isFirefox && meta.version < 27) ||
        (isIE && meta.version < 11) ||
        (isEdge && meta.version < 12) ||
        (isSafari && meta.version < 7)
      ) {
        status = true;
      }
      return status;
    });

    const showIEAlert = computed(() => {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE ');
      const trident = ua.indexOf('Trident/');

      return (msie > 0 || trident > 0) &&
        alertMessageDetails.value.internetExplorerAlertMessage &&
        alertMessageDetails.value.internetExplorerAlertMessage.isActive
        ? true
        : false;
    });

    const subscriptions = () => {
      // currentUserIdentity subscription
      watchEffect(() => {
        currentUserIdentity.value = currentUserIdentity$.value;
      });

      // isLoggedIn subscription
      watchEffect(() => {
        isLoggedIn.value = isLoggedIn$.value;
      });
    };

    // Call subscriptions when component is mounted
    subscriptions();

    // Combined observable for result
    const result = combineLatest([isLoggedIn$, currentUserIdentity$]);
  
    currentUserIdentity$.subscribe((data) => {
      if (data && data.isSecurityPolicyExpired === true) {
        isSecurityPolicyExpired.value = true
        router.push('/closeddepartment');
      }
    });
    // Subscribe to combined observable
    result.subscribe((data) => {
      if (data[0] === true && data[1] && isSecurityPolicyExpired.value !== true)  {
        router.push('/');
      }
    });

    onBeforeMount(() => {
      clearTimeout(timer.value);
      store.dispatch('navigation/fetchPortalLogo').then(() => {
        if (alertMessageDetails.value.loginHighPrioMessage && alertMessageDetails.value.loginHighPrioMessage.isActive) {
          showLoginIssueAlert.value = true;
        }
      });

      timer.value = setTimeout(() => {
        window.location.replace(window.location.pathname + '?' + Math.random());
      }, 30 * 60 * 1000);
    });

    const { fetchPortalLogo } = mapActions('navigation', ['fetchPortalLogo']);

    const closeModal = () => {
      showBrowserRelatedPopup.value = false;
    };

    const closeOverlay = () => {
      showBrowserRelatedPopup.value = false;
    };

    const closeWarning = () => {
      animated.value = true;
      setTimeout(() => {
        animated.value = false;
        showLoginIssueAlert.value = false;
      }, 1000);
    };

    return {
      store,
      portalLogo,
      backgroundLogo,
      alertMessageDetails,
      showBrowserRelatedPopup,
      showIEAlert,
      showLoginIssueAlert,
      animated,
      closeModal,
      closeOverlay,
      closeWarning,
      fetchPortalLogo,
      currentUserIdentity,
      isLoggedIn,
      result
    };
  }
};
</script>

<style lang="scss" scoped>
.login {
  .loginIssueAlertMessage,
  .alertMessageForIE {
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        margin-left: -1 * grid-gutter($breakpoint-key);
        margin-right: -1 * grid-gutter($breakpoint-key);
      }
    }
  }
  .alertMessageForIE {
    height: 70%;
    position: relative;
    :deep(.normal) {
      background-color: $teal-darker;
      color: $blue-darker;
      font-weight: 400;
    }
    :deep(.warning-message__icon) {
      fill: $blue-darker;
    }
  }
  .logo {
    @include min-screen(grid-breakpoint(md)) {
      margin: 40px 10px;
    }

    img {
      display: block;
      height: 60px;
      margin: 0 auto;

      @include min-screen(grid-breakpoint(md)) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__header {
    margin: 30px 10px 25px 10px;
  }
  &__closeOverlay {
    float: right;
    margin: -25px -15px 0px 0px;
    cursor: pointer;
  }
  legend {
    font-size: 16px;
    line-height: 1.5;
  }
  &__greyColor {
    fill: $gray !important;
  }
  &__greenColor {
    fill: $dark-green !important;
  }
  .btn {
    margin-bottom: 1rem;
    @include min-screen(grid-breakpoint(md)) {
      margin-bottom: 0;
    }
  }
  p {
    color: $gray-darkest;
  }
  > .row {
    margin-bottom: 0;
    height: 100vh;
  }
  .loginimage {
    height: 100%;
    width: 100%;
    background-size: cover !important;
  }
  :deep(.form-group-simple input::-ms-reveal) {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}
</style>
