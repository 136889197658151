<template>
  <div>
    <v-button tagelement="button" md primary icon-left id="step1" :hollow="isFollowing" @click="toggleFollow">
      <account-minus-icon v-if="isFollowing" />
      <account-plus-icon v-else />
      {{ isFollowing ? 'Patiënt ontvolgen' : 'Patiënt volgen' }}
    </v-button>
    <generic-modal v-if="showFollowModal">
      <template #header>
        {{ followUnFollowPatient.Follow.Header }}
      </template>
      <template #body>
        <p>{{ followUnFollowPatient.Follow.Text }}</p>
      </template>
      <template #footer>
        <div class="modal-checkbox">
          <v-checkbox-group
            class="checkbox-group"
            label="Laat dit bericht niet meer zien"
            inputid="rememnerFollowPatient"
            @input="changeRememberFollowPatient(...arguments)"
          />
        </div>
        <v-button id="step2" tagelement="button" md primary @click="closeModal"> Ik begrijp het </v-button>
      </template>
    </generic-modal>
    <generic-modal v-if="showUnfollowModal" :has-close-button="true" @close="closeModal">
      <template #header>
        {{ followUnFollowPatient.UnFollow.Header }}
      </template>
      <template #body>
        <p>{{ followUnFollowPatient.UnFollow.Text }}</p>
      </template>
      <template #footer>
        <v-button tagelement="button" md hollow transparent @click="closeModal"> Annuleren </v-button>
        <v-button tagelement="button" md primary @click="toggleFollow01"> Patiënt toch ontvolgen </v-button>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import { mapActions, useStore } from 'vuex';
import { computed } from 'vue';
import accountPlusIcon from 'icons/AccountPlus';
import accountMinusIcon from 'icons/AccountMinus';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import GenericModal from '@/components/shared/GenericModal';

export default {
  name: 'PatientFollow',
  components: {
    accountPlusIcon,
    accountMinusIcon,
    VCheckboxGroup,
    GenericModal
  },
  props: {
    isFollowing: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();

    const patientFollow = computed(() => store.getters['patient/getPatientFollow']);
    const followUnFollowPatient = computed(() => store.getters['patient/getFollowUnFollowPatient']);
    const setRememberPatientFollow = (value) => store.commit('patient/setRememberPatientFollow', value);

    return {
      patientFollow,
      followUnFollowPatient,
      setRememberPatientFollow,
      ...mapActions({
        set: 'patient/setPatientFollowStatus'
      })
    };
  },
  data() {
    return {
      showFollowModal: false,
      showUnfollowModal: false
    };
  },
  created() {
    const self = this;
  },
  methods: {
    closeModal() {
      this.showFollowModal = false;
      this.showUnfollowModal = false;
    },
    toggleFollow01() {
      const data = { Follow: !this.isFollowing };
      this.showUnfollowModal = false;
      this.set(data);
    },
    toggleFollow() {
      const data = { Follow: !this.isFollowing };
      if (!this.isFollowing) {
        if (!this.patientFollow) {
          this.showFollowModal = true;
        }
        this.set(data);
      } else {
        this.showUnfollowModal = true;
      }
    },
    /**
     * @function changeRememberFollowPatient -  Function to set remember patient follow service true or false based on user interaction
     * @param {boolean} - active
     */
    changeRememberFollowPatient(active) {
      this.setRememberPatientFollow(active);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-group {
  display: flex;
  align-items: center;
}

.modal-checkbox {
  display: flex;
  vertical-align: middle;
  padding-right: 10px;
}
</style>
