<template>
  <div class="pump-retrieve-form">
    <v-paragraph class="pump-retrieve-form__heading"> Pomp laten ophalen </v-paragraph>
    <v-paragraph>{{ data.pumpDesc }} {{ data.serialNo }}</v-paragraph>
    <div class="row">
      <div class="col-md-12">
        <legend>Reden waarom pomp opgehaald moet worden:</legend>
        <div class="form-group__radio">
          <input id="maintainance" name="type" type="radio" checked @change="checkRadioAddress" />
          <label for="maintainance"><span></span>Onderhoud </label>
        </div>
        <div class="form-group__radio">
          <input id="repair" name="type" type="radio" @change="checkRadioAddress" />
          <label for="repair"><span></span>Reparatie</label>
        </div>
        <div v-if="isRepair" class="pump-retrieve-form__code">
          <v-input-group
            v-model="formConfig.ErrorCode.model"
            initvalue=""
            :rule="formConfig.ErrorCode.rule"
            :label="formConfig.ErrorCode.label"
            :inputid="formConfig.ErrorCode.ref"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <textarea
          id="remarks"
          class="pump-retrieve-form__textarea"
          placeholder="Opmerking (optioneel)"
          @input="setRemarks($event.target.value)"
        />
      </div>
    </div>
    <div class="row pump-retrieve-form__buttonsSection">
      <v-button md hollow transparent @click="cancelRetrievePump"> Annuleren </v-button>
      <v-button md primary @click.once="saveRetrievePump"> Bevestigen </v-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useForm } from 'vee-validate';
import { activePpwRecId$ } from '@/services';
import { ref } from 'vue';

export default {
  name: 'VPumpretrieveform',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const activePpwRecId = ref(null);
    activePpwRecId$.subscribe((value) => {
      activePpwRecId.value = value;
    });
    return {
      activePpwRecId
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      formConfig: {},
      isRepair: false,
      pumpData: {}
    };
  },
  computed: {},
  watch: {
    isRepair(value) {
      if (value) {
        this.setFormConfig();
      }
    }
  },
  created() {
    this.pumpData = {
      ErrorCode: '',
      Comment: '',
      SerialNo: this.data.serialNo,
      PumpID: this.data.equipmentID,
      PpwRecId: this.activePpwRecId
    };
  },
  methods: {
    cancelRetrievePump() {
      this.$emit('closeModal');
    },
    saveRetrievePump() {
      if (this.isRepair) {
        this.validateAllFields();
        this.$emit('closeModal');
      } else {
        axios.post('Equipment/EmailToRetrievePump', this.pumpData).then((response) => {
          if (response) {
            this.$emit('success');
            this.$emit('closeModal');
          }
        });
      }
    },
    setFormConfig() {
      this.formConfig = {
        ErrorCode: {
          ref: 'ErrorCode',
          label: 'Foutcode op pomp',
          model: this.pumpData.ErrorCode,
          rule: {
            required: false
          },
          attached: true,
          error: false,
          errorMessage: 'Vul de foutcode in die op de pomp is weergegeven.'
        }
      };
    },
    async validateAllFields(elements) {
      const result = await this.validate();
      if (result.valid) {
        for (let key in this.pumpData) {
          if (this.formConfig.hasOwnProperty(key) && this.formConfig[key].model !== undefined) {
            this.pumpData[key] = this.formConfig[key].model;
          }
        }
        axios.post('Equipment/EmailToRetrievePump', this.pumpData).then((response) => {
          if (response) {
            this.$emit('success');
            this.$emit('closeModal');
          }
        });
      }
    },
    resetFormConfigValues() {
      for (let key in this.formConfig) {
        this.formConfig[key].model = undefined;
      }
    },
    checkRadioAddress(e) {
      let checkedType = document.querySelector('input[name="' + e.currentTarget.name + '"]:checked').id;
      if (checkedType === 'repair') {
        this.isRepair = true;
      } else {
        this.isRepair = false;
      }
      this.resetFormConfigValues();
    },
    setRemarks(remarks) {
      this.pumpData['Comment'] = remarks;
    }
  }
};
</script>
<style land="css">
.pump-retrieve-form .form-group.form-group--error input {
  border: 2px solid red;
}
.pump-retrieve-form .form-group {
  padding: 0px;
}
.pump-retrieve-form .form-group-simple label {
  font-size: 14px;
  font-weight: normal;
}
.pump-retrieve-form .form-group label {
  position: absolute;
  top: -20px;
  left: 10px;
  background: white;
  width: auto;
  padding: 5px;
  font-size: 12px;
  color: #484848;
}
.pump-retrieve-form .form-group--focused label {
  font-size: 12px;
  color: #484848;
  transform: none;
  width: auto;
  max-width: 100%;
}
.pump-retrieve-form .form-group--hasvalue label {
  font-size: 12px;
  color: #484848;
  transform: none;
  width: auto;
  max-width: 100%;
}
.pump-retrieve-form .form-group input {
  border-style: none;
  border: 2px solid #006983;
  border-radius: 5px;
  padding: 10px 0px 10px 15px;
  color: black;
  height: auto;
}
.pump-retrieve-form .form-group .form-group__details:before,
.pump-retrieve-form .form-group .form-group__details:after {
  background-color: transparent;
}
</style>
<style lang="scss" scoped>
.pump-retrieve-form {
  &__code {
    margin-top: 15px;
  }
  &__textarea {
    padding: 0px;
    min-height: 95px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid $grayish-mega-dark;
    width: 100%;
    padding: 10px;
    margin: 10px 0px 30px 0px;
  }
  legend {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__heading {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
  }
  &__buttonsSection {
    float: right;
  }
}
</style>
