<template>
  <div class="prescriber-signature">
    <v-overlay v-if="showPopup">
      <h2>{{ title }}</h2>
      <v-paragraph class="prescriber-signature__paragraph">
        {{ message }}
      </v-paragraph>
      <div class="row">
        <div class="col-xs-3">
          <img
            src="@/static/img/mascot_signature.svg"
            class="prescriber-signature__mascot"
          >
        </div>
        <div class="col-xs-9">
          <v-input-digital-signature
            @save-signature="saveSignature"
            @undo-signature="undoSignature"
          />
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from 'axios'
import VInputDigitalSignature from '@/components/shared/form/simple/InputDigitalSignature'
import { useStore } from 'vuex'

export default {
  name: 'VPrescriberSignature',
  components: {
    VInputDigitalSignature
  },
  setup() {
    const store = useStore();

    const setSignatureSaved = (value) => store.commit('prescriber/setSignatureSaved', value);
    
    return {
      setSignatureSaved
    }
  },
  data () {
    return {
      showPopup: false,
      title: '',
      message: ''
    }
  },
  created () {
    axios.get('UserSignature/GetUserSignatureConfiguration').then((response) => {
      this.setSignatureSaved(!response.data.UserSignatureConfiguration.SignatureIsMissing)
      if (response.data.UserSignatureConfiguration.SignatureIsMissing) {
        this.title = response.data.UserSignatureConfiguration.Title
        this.message = response.data.UserSignatureConfiguration.Message
        this.showPopup = true
      } else {
        this.showPopup = false
      }
    })
  },
  methods: {
    saveSignature (empty, data) {
      data = data.substr(22, data.length)
      let formData = { 'PrescriberSignature': data }
      return axios.post('UserSignature/SaveUserSignature', formData,
        {
          headers: {
            'Accept': 'application/pdf'
          },
        })
        .then(response => {
          this.setSignatureSaved(true);
          return true;
        })
    },
    undoSignature () {
    }
  }
}
</script>

<style lang="scss" scoped>
.prescriber-signature {
  &__mascot {
    float: left;
  }

  h2 {
    color: $gray-darkest;
  }

  &__paragraph {
    color: #333333;
  }
  .digital-signature {
    width: 400px;
    height: 400px;
  }
}
</style>