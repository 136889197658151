<template>
  <div class="productCard">
    <div class="card">
      <div class="row grid-padding product">
        <div class="col-xs-12 col-md-9">
          <div class="row grid-padding">
            <div class="productCard__wrapper">
              <img :src="product.imageUrl" @error="imageLoadError" />
            </div>
            <div class="col">
              <div class="row grid-padding">
                <div class="col-md-12" v-if="relationalProduct">
                  <div class="relationalProductTag">Vervangend product</div>
                </div>
                <div class="col-md-12" v-if="!available">
                  <div class="notAvailableTag">Niet beschikbaar</div>
                </div>
                <div class="col-md-12 cursor">
                  <h3 @click.prevent="goToProductDetailPage()">{{ product.productTitle }}</h3>
                </div>
              </div>
              <div class="row grid-padding" v-if="!relationalProduct">
                <div class="col-md-12 productCard__details">
                  <span>
                    Artikelcode <b>{{ product.productCode }}</b>
                  </span>
                  <span>
                    Bestelnummer <b>{{ product.productId }}</b>
                  </span>
                  <span>
                    Verpakking <b>{{ product.packagingUnit }} {{ product.packagingLabel }}</b>
                  </span>
                </div>
              </div>
              <div class="row grid-padding" v-if="!relationalProduct">
                <div class="col-md-12 productCard__details">
                  <div v-if="!assortmentFeatureEnabled">
                    <div v-if="product.isReembursed">
                      <v-check-icon class="green" title="Verzekerd"></v-check-icon>Product wordt vergoed
                    </div>
                    <div v-else>
                      <v-alert-circle-icon class="red" title="niet verzekerd"></v-alert-circle-icon>Product wordt niet
                      vergoed
                    </div>
                  </div>
                  <div v-if="requireSerialNumber">
                    <v-input-group
                      maxlength="128"
                      v-model:value="product.serialNumber"
                      :rule="{ required: true }"
                      :initvalue="product.serialNumber"
                      class="serialNummer"
                      placeholder="Voer het serienummer van het aparaat in."
                      type="text"
                      ref="serialNumber"
                      name="serialNumber"
                      label="Serienummer*"
                      @input="updateShoppingCartItem"
                      :inputid="product.productId"
                    >
                    </v-input-group>
                  </div>
                </div>
              </div>
              <div class="row grid-padding" v-if="showTimeEstimation()">
                <div class="col-md-12 productCard__details">
                  <div>
                    <v-info-icon
                      class="orange infoIcon"
                      :title="atpDetails.hoverText"
                      v-if="showInfoIcon()"
                    ></v-info-icon>
                    <v-truck-icon
                      :class="getATPCheckStatusColorClass()"
                      :title="atpDetails.details"
                      class="truckIcon"
                      v-else
                    ></v-truck-icon>
                    {{ atpDetails.details }}
                  </div>
                </div>
              </div>
              <div class="row grid-padding" v-if="!relationalProduct">
                <div class="col-md-12 productCard__details">
                  <span v-if="product.instruction && !assortmentFeatureEnabled">
                    <v-checkbox-group
                      v-model="product.instructionInput"
                      :inputid="'Instructie-' + product.productId"
                      :initvalue="product.instructionInput"
                      :overwrite-value="product.instructionInput"
                      @update:model-value="updateShoppingCartItem()"
                      :disabled="product.givenToPatient"
                      :class="{ disabledBlock: product.givenToPatient }"
                    >
                      <span>Instructie bij levering gewenst</span>
                    </v-checkbox-group>
                  </span>
                  <span v-if="displayGivenToPatient">
                    <v-checkbox-group
                      v-model="product.givenToPatient"
                      :inputid="'levering-' + product.productId"
                      :initvalue="product.givenToPatient"
                      :overwrite-value="product.givenToPatient"
                      @update:model-value="updateShoppingCartItem()"
                    >
                      <span>Meegegeven aan patiënt</span>
                    </v-checkbox-group>
                  </span>
                  <span v-if="displayFillUpStock">
                    <v-checkbox-group
                      label="Magazijn bijvullen"
                      v-model="product.fillUpStock"
                      :inputid="'magazijn-' + product.productId"
                      :initvalue="product.fillUpStock"
                      :overwrite-value="product.fillUpStock"
                      @update:model-value="updateShoppingCartItem()"
                    >
                    </v-checkbox-group>

                    <span v-if="product.givenToPatient && product.fillUpStock && warehouses && warehouses.length > 1">
                      <select
                        class="warehouses"
                        ref="warehouses"
                        name="warehouses"
                        data-vv-as="Magazijn"
                        :rule="{ required: true }"
                        v-model="product.warehouseCode"
                      >
                        <option :value="undefined" disabled="disabled" selected="selected">Kies magazijn</option>
                        <option v-for="warehouse in warehouses" :key="warehouse.Id" :value="warehouse.Id">
                          {{ warehouse.Description }}
                        </option>
                      </select>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-3 grid-align-end">
          <div class="productCard__wrapper" v-if="relationalProduct">
            <m-button
              cta
              icon-name="shuffle"
              icon-position="start"
              class="computeActionBtn"
              @click.prevent="addRelationalProduct"
            >
              Product vervangen
            </m-button>
          </div>
          <div class="productCard__wrapper" v-else>
            Aantal
            <select
              class="quantity"
              v-model="product.quantity"
              @change="changeProductQuantiy"
              :initselected="product.quantity"
              :disabled="!available"
            >
              <option v-for="(quantity, index) in productQuantities" :key="index" :data-index="index" :value="quantity">
                {{ quantity }}
              </option>
              <option value="more">meer...</option>
            </select>
            <span @click="removeShoppingCartItem()"><v-delete-icon class="cursor"></v-delete-icon></span>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals section -->
    <generic-modal :has-close-button="true" v-if="showProductQuantityInput" @close="closeModal">
      <template #body>
        <h4>Vul hieronder het te bestellen aantal in en klik op OK</h4>
        <input
          class="productCard__productQuantityInput"
          type="text"
          v-model="dialogQuantity"
          maxlength="3"
          autocomplete="off"
        />
        <div class="productCard__alert" v-if="showMinAlert">
          <v-alert-circle-outline></v-alert-circle-outline>Bestelhoeveelheid per {{ product.minOrderQty }}
        </div>
      </template>
      <template #footer>
        <v-button tagelement="button" md primary @click.prevent="saveProductQuantity(dialogQuantity)">OK</v-button>
      </template>
    </generic-modal>
    <generic-modal v-if="deleteProductOverlay">
      <template #header> Let op! </template>
      <template #body>
        <p>{{ removeProductPopupMessage }}</p>
      </template>
      <template #footer>
        <v-button tagelement="button" hollow transparent md @click.prevent="closeModal">Annuleren</v-button>
        <v-button md primary @click.prevent="handleRemoveShoppingCartItem()">Bevestigen</v-button>
      </template>
    </generic-modal>
  </div>
</template>

<script>
import router from '@/router';
import VCheckIcon from 'icons/Check';
import VAlertCircleIcon from 'icons/AlertCircle';
import VAlertCircleOutline from 'icons/AlertCircleOutline';
import VTruckIcon from 'icons/Truck';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VInfoIcon from 'icons/Information';
import VDeleteIcon from 'icons/Delete';
import GenericModal from '@/components/shared/GenericModal';
import { assortmentFeatureEnabled$ } from '@/services';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ProductCard',
  components: {
    VCheckIcon,
    VAlertCircleIcon,
    VCheckboxGroup,
    VAlertCircleOutline,
    VTruckIcon,
    VInfoIcon,
    VDeleteIcon,
    GenericModal
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    relationalProduct: {
      type: Boolean,
      default: false
    },
    atpDetails: {
      type: Object
    },
    available: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useStore();
    const assortmentFeatureEnabled = ref(null);

    const removeProductPopupMessage = computed(() => store.getters['cart/getPopupMessage']);
    const warehouses = computed(() => store.getters['navigation/getWarehouses']);

    assortmentFeatureEnabled$.subscribe((value) => (assortmentFeatureEnabled.value = value));

    const siteCode = ref(store.state.navigation.siteCode);

    return {
      removeProductPopupMessage,
      warehouses,
      assortmentFeatureEnabled,
      siteCode
    };
  },
  data() {
    return {
      showMinAlert: false,
      showProductQuantityInput: false,
      lastNumericQuantity: this.product.quantity,
      dialogQuantity: 0,
      serialNumberErrorMessage: '* Dit veld is verplicht.',
      deleteProductOverlay: false
    };
  },
  computed: {
    hasWarehouses() {
      return this.warehouses && this.warehouses.length > 0;
    },
    productQuantities() {
      const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      if (quantities.indexOf(this.product.quantity) < 0) {
        quantities.push(this.product.quantity);
      }
      return quantities;
    },
    autoFillUpStockWhenGivenToPatient() {
      return this.product.supportAutoFillUpStockWhenGivenToPatient;
    },
    requireSerialNumber() {
      return this.product.supportSerialNumber && this.product.givenToPatient;
    },
    displayGivenToPatient() {
      return this.product.supportGivenToPatient;
    },
    displayFillUpStock() {
      return this.product.supportFillUpStock && this.product.givenToPatient && !this.autoFillUpStockWhenGivenToPatient;
    },
    /**
     * @function isInfuusPortal - function to check whether to portal is infuus or not
     */
    isInfuusPortal() {
      return this.siteCode === 'MIF';
    }
  },
  watch: {
    'product.quantity': {
      handler: function (newVal, oldVal) {
        if (!isNaN(newVal) && oldVal !== undefined && newVal != 0) {
          this.lastNumericQuantity = newVal / 1;
          this.$emit('atp', this.product);
        }
      }
    }
  },
  methods: {
    addRelationalProduct() {
      this.$emit('addRelationalProduct');
    },
    closeModal() {
      this.showProductQuantityInput = false;
      this.deleteProductOverlay = false;
    },
    getATPCheckStatusColorClass() {
      if (this.atpDetails.ATPCheckColorCode >= 1 && this.atpDetails.ATPCheckColorCode <= 2) {
        return 'green';
      }
      if (this.atpDetails.ATPCheckColorCode >= 3 && this.atpDetails.ATPCheckColorCode <= 6) {
        return 'orange';
      }
      if (this.atpDetails.ATPCheckColorCode >= 7) {
        return 'red';
      }
    },
    showInfoIcon() {
      return this.atpDetails.ATPCheckColorCode && ['2', '4', '6'].includes(this.atpDetails.ATPCheckColorCode);
    },
    // Function to throw console warning if there is not product image
    imageLoadError() {
      console.warn('No product image');
    },
    // Function to take user to the specific product detail page
    goToProductDetailPage() {
      if (!this.assortmentFeatureEnabled) {
        router.push('/catalog/product/' + this.product.productId);
        return;
      }
      router.push('/aanvullenmagazijn/product/' + this.product.productId);
    },
    // Function to restrict count of an input field
    restrictCount(count) {
      let regex = /^([0-9]+)$/;
      if (!regex.test(count) || !count || count <= 0) {
        this.product.quantity = this.product.minOrderQty;
        this.showMinAlert = true;
      }
      this.updateShoppingCartItem();
    },
    // Function to update product quantity
    updateShoppingCartItem() {
      this.$emit('update', this.product);
      this.showProductQuantityInput = false;
    },
    // Function to remove a product from the cart
    removeShoppingCartItem() {
      this.deleteProductOverlay = true;
    },
    handleRemoveShoppingCartItem() {
      this.$emit('remove', this.product);
    },
    // Function to reduce product quantity either by 1 or with specific amount
    reduceProductQuantity() {
      if (this.product.quantity === this.product.minOrderQty) {
        this.showMinAlert = true;
        return;
      }
      this.product.quantity = Math.min(Number(this.product.quantity) - 1);
      this.updateShoppingCartItem();
    },
    // Function to increase product quantity either by 1 or with specific amount
    increaseProductQuantity() {
      this.product.quantity = Math.min(Number(this.product.quantity) + 1);
      this.updateShoppingCartItem();
    },
    changeInstructionInput(selected) {
      this.product.instructionInput = selected;
    },
    /**
     * @function showTimeEstimation
     * @description Function to decide whether to show the time estimation or not
     */
    showTimeEstimation() {
      return this.atpDetails && this.atpDetails.ATPCheckColorCode;
    },
    /**
     * @function closeOverlay
     * @description Function to close the product quantity change pop up
     */
    closeOverlay() {
      if (this.customProductQuantity === 'more') {
        this.customProductQuantity = this.product.quantity;
      }
      this.showProductQuantityInput = false;
    },
    /**
     * @function saveProductQuantity
     * @description Function to add new product quantity
     */
    saveProductQuantity(inputValue) {
      let regex = /^([0-9]+)$/;
      if (!regex.test(inputValue) || !inputValue || inputValue <= 0) {
        this.showMinAlert = true;
        return;
      }
      this.showMinAlert = false;
      this.product.quantity = this.dialogQuantity / 1;
      this.updateShoppingCartItem();
      this.showProductQuantityInput = false;
    },
    /**
     * @function changeProductQuantiy
     * @description Function to change product quantity from the drop down or enable the custom quantity pop up
     */
    changeProductQuantiy(e) {
      if (e.currentTarget.value === 'more') {
        this.dialogQuantity = this.lastNumericQuantity;
        this.product.quantity = this.lastNumericQuantity;
        this.showProductQuantityInput = true;
        return;
      }
      this.updateShoppingCartItem();
    }
  }
};
</script>

<style lang="scss" scoped>
.productCard {
  color: $teal-darkest !important;
  .card {
    padding: 24px;
    background-color: $white;
    border-radius: 6px;
  }
  .product {
    border-bottom: 1px solid $alto;
    padding-bottom: 32px;
  }
  &__wrapper {
    height: 100%;
    min-width: 130px;
    display: flex;
    align-items: center;
    img {
      max-height: 130px;
      max-width: 130px;
      vertical-align: middle;
    }
  }
  &__details {
    display: inline-block;
    margin-bottom: 8px;
    span {
      padding-right: 24px;
    }
  }
  .material-design-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    fill: $teal-darkest;
    padding: 0px;
  }
  :deep(select) {
    &.quantity {
      text-align: center;
      width: 50px;
      border: 1px solid $gray;
      margin: 0 16px;
    }
    &.warehouses {
      width: 140px;
      margin-left: 15px;
      text-align-last: center;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    &::-ms-expand {
      display: block !important;
    }
  }
  &__productQuantityInput {
    width: 50px !important;
    text-align: center !important;
    padding: 10px !important;
  }
  .green {
    fill: $greenish;
  }
  .orange {
    fill: $orange-light;
  }
  .red {
    fill: $red;
  }
  &__alert {
    padding: 16px 0px;
    font-size: 12px;
  }
  :deep(.form-group) {
    label {
      margin: 10px 20px 0px 0px;
      color: $teal-darkest !important;
    }
    input {
      margin: 10px 20px 0px 0px;
      border-color: $teal-darkest !important;
    }
    &__details {
      display: none;
    }
  }
  :deep(.checkbox-group) {
    float: left;
    &__label {
      &__inner {
        color: $teal-darkest !important;
        line-height: 20px !important;
      }
      span {
        border-color: $teal-darkest !important;
      }
    }
  }
  .disabledBlock {
    .checkbox-group {
      &__checkbox + .checkbox-group__label > span {
        background: $gray-light !important;
      }
      &__icon {
        display: none !important;
      }
    }
  }
  .relationalProductTag {
    width: 170px;
    background-color: #ddffdd;
    color: #0c6720;
    border-radius: 5px;
    padding: 12px;
  }
  .notAvailableTag {
    width: 142px;
    background-color: #ffcece;
    color: #7d0101;
    border-radius: 5px;
    padding: 12px;
  }
}
</style>
