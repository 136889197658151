<template>
  <section v-if="baseProductDetail" class="container product-detail">
    <v-button md primary icon-left @click.prevent="goToCatalog()">
      <v-arrow-left-icon /> <span>Terug naar overzicht</span>
    </v-button>
    <div class="row grid-margin">
      <div id="step1" class="col-xs-12 col-md-12">
        <div class="row">
          <v-product-description
            class="col-md-12"
            :title="baseProductDetail.title"
            :base-product-detail="baseProductDetail"
            :product-id="baseProductDetail.productCode"
            :sap-id="baseProductDetail.sapId"
            :short-description="baseProductDetail.shortDescription"
            :product-unit="baseProductDetail.packageUnitPackageLabel"
            @favourite-success="loadDetails"
          />
        </div>
        <div v-if="alternativeBaseProducts && alternativeBaseProducts.length > 0">
          <div class="row">
            <h2>Gerelateerde producten</h2>
          </div>
          <div class="row grid-margin">
            <div class="col-xs-6 col-md-5 product">
              <a
                class="product__link"
                href=""
                @click.prevent="goToBaseProductDetailPage(alternativeBaseProducts[0].baseProductId)"
              >
                <div class="product__img-wrapper">
                  <img :src="alternativeBaseProducts[0].imageUrl" />
                </div>
                <div class="product__text">
                  <strong class="product__text__name">{{ alternativeBaseProducts[0].title }}</strong>
                  <strong class="product__text__manufacturer">{{ alternativeBaseProducts[0].manufName }}</strong>
                </div>
              </a>
            </div>
            <div v-if="alternativeBaseProducts.length > 1" class="col-xs-6 col-md-5 product">
              <a
                class="product__link"
                href=""
                @click.prevent="goToBaseProductDetailPage(alternativeBaseProducts[1].baseProductId)"
              >
                <div class="product__img-wrapper">
                  <img :src="alternativeBaseProducts[1].imageUrl" />
                </div>
                <div class="product__text">
                  <strong class="product__text__name">{{ alternativeBaseProducts[1].title }}</strong>
                  <strong class="product__text__manufacturer">{{ alternativeBaseProducts[1].manufName }}</strong>
                </div>
              </a>
            </div>
          </div>
          <div class="row grid-margin" />
          <div v-if="alternativeBaseProducts.length > 2" class="row grid-margin">
            <div class="col-xs-6 col-md-5 product">
              <a
                class="product__link"
                href=""
                @click.prevent="goToBaseProductDetailPage(alternativeBaseProducts[2].baseProductId)"
              >
                <div class="product__img-wrapper">
                  <img :src="alternativeBaseProducts[2].imageUrl" />
                </div>
                <div class="product__text">
                  <strong class="product__text__name">{{ alternativeBaseProducts[2].title }}</strong>
                  <strong class="product__text__manufacturer">{{ alternativeBaseProducts[2].manufName }}</strong>
                </div>
              </a>
            </div>
            <div v-if="alternativeBaseProducts.length > 3" class="col-xs-6 col-md-5 product">
              <a
                class="product__link"
                href=""
                @click.prevent="goToBaseProductDetailPage(alternativeBaseProducts[3].baseProductId)"
              >
                <div class="product__img-wrapper">
                  <img :src="alternativeBaseProducts[3].imageUrl" />
                </div>
                <div class="product__text">
                  <strong class="product__text__name">{{ alternativeBaseProducts[3].title }}</strong>
                  <strong class="product__text__manufacturer">{{ alternativeBaseProducts[3].manufName }}</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="row product-detail__description">
          <div class="col-md-8 infoLeft">
            <v-header title="Productinformatie" />
            <p @click="getImageDetails($event)" v-html="baseProductDetail.description" />
          </div>
          <div class="col-md-4" @click.prevent="showProductFullImage(baseProductDetail.imageUrl)">
            <vue-image-zoomer
              img-class="zoomImageWrapper"
              :regular="baseProductDetail.thumbnailImageUrl"
              :zoom="baseProductDetail.imageUrl"
            />
          </div>
        </div>
        <div class="row product-detail__description">
          <div class="col-md-8 infoLeft">
            <v-header title="Gebruiksadvies" />
            <p @click="getImageDetails($event)" v-html="baseProductDetail.manual" />
          </div>
        </div>
        <div v-if="!isAssortmentFlow && shoppingCart.items.length > 0" class="row grid-margin">
          <v-button cta md class="continue-ordering" @click.prevent="continueOrdering()">
            Bestelling afronden ({{ shoppingCart.items.length }})
          </v-button>
        </div>
        <generic-modal
          v-if="showProductImage"
          :has-close-button="true"
          class="product-detail__description__fullImage"
          @close="closeModal"
        >
          <template #body>
            <img :src="bigImageUrl" />
          </template>
        </generic-modal>
      </div>
    </div>
  </section>
</template>

<script>
import {
  shoppingCart$,
  activeCustomerId$,
  getBaseProductDetails,
  getBaseProductDetailsByProductId,
  getAlternativeBaseProducts
} from '@/services';
import VArrowLeftIcon from 'icons/ArrowLeft';
import VProductDescription from './ProductDescription';
import router from '@/router';
import VHeader from '@/components/shared/Header.vue';
import { BehaviorSubject } from 'rxjs';
import GenericModal from '@/components/shared/GenericModal';

import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BaseProductDetail',
  components: {
    VArrowLeftIcon,
    VProductDescription,
    VHeader,
    GenericModal
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'catalog') {
      this.resetCatalogFormDetails();
    }
    next();
  },
  props: {
    productDetails: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const store = useStore();
    const shoppingCart = ref(null);

    const navDemo =
      store.state.navigation.demo &&
      store.state.navigation.demoActivated &&
      store.state.navigation.demoFlow.catalogFlow;
    const cartOpenStatus = computed(() => store.getters['cart/getCartOpenStatus']);
    const isCustomerFlow = computed(() => router.currentRoute.value.meta.flow === 'customer');
    const activeCustomerId = computed(() => {
      return isCustomerFlow.value ? activeCustomerId$ : new BehaviorSubject(null);
    });

    const resetCatalogFormDetails = () => store.commit('catalog/resetCatalogFormDetails');
    const setCartOpenStatus = (value) => store.commit('cart/setCartOpenStatus', value);

    shoppingCart$.subscribe((value) => {
      shoppingCart.value = value;
    });

    return {
      navDemo,
      cartOpenStatus,
      resetCatalogFormDetails,
      setCartOpenStatus,
      shoppingCart,
      activeCustomerId,
      isCustomerFlow
    };
  },
  data() {
    return {
      baseProductDetail: null,
      alternativeBaseProducts: null,
      count: 1,
      min: 1,
      max: 999,
      showProductImage: false,
      bigImageUrl: ''
    };
  },
  computed: {
    isReplenishmentFlow() {
      return router.currentRoute.value.meta.flow === 'replenishment';
    },
    isAssortmentFlow() {
      return router.currentRoute.value.meta.flow === 'assortment';
    }
  },
  watch: {
    $route(to, from) {
      this.loadDetails();
    }
  },
  created() {
    this.loadDetails();
  },
  methods: {
    // Load the page with details
    loadDetails() {
      if (router.currentRoute.value.name === 'productDetail') {
        getBaseProductDetailsByProductId(router.currentRoute.value.params.id, this.isCustomerFlow).then((r) => {
          this.baseProductDetail = r;

          getAlternativeBaseProducts(r.baseProductId).then((a) => {
            this.alternativeBaseProducts = a;
          });
        });
      } else {
        getBaseProductDetails(router.currentRoute.value.params.id, this.isCustomerFlow).then((r) => {
          this.baseProductDetail = r;
        });
        getAlternativeBaseProducts(router.currentRoute.value.params.id).then((r) => {
          this.alternativeBaseProducts = r;
        });
      }
    },
    // Function to return required enum value based on the input
    getEnumValue(enumList, enumKey) {
      for (const key in enumList) {
        if (enumList.hasOwnProperty(key)) {
          if (key === enumKey) {
            return enumList[key];
          }
        }
      }
    },
    // Function to restrict count of a input field
    restrictCount(count) {
      let regex = /^([0-9]+)$/;
      if (!regex.test(count) || !count || count <= 0) {
        this.count = this.min;
      }
    },
    // Function to go back to the respective catalog page
    goToCatalog() {
      if (this.isCustomerFlow) {
        router.push('/catalog');
      } else if (this.isReplenishmentFlow) {
        router.push('/aanvullenmagazijn');
      } else if (this.isAssortmentFlow) {
        router.push('/assortiment');
      }
    },
    continueOrdering() {
      if (this.isReplenishmentFlow) {
        router.push('/magazijnaanvullenBestellijst');
      } else {
        router.push('/bestellijst');
      }
    },
    goToBaseProductDetailPage(baseProductId) {
      if (this.isReplenishmentFlow) {
        router.push('/aanvullenmagazijn/baseProduct/' + baseProductId);
      } else {
        router.push('/catalog/baseProduct/' + baseProductId);
      }
    },
    closeModal() {
      this.showProductImage = false;
    },
    showProductFullImage(imageUrl) {
      this.bigImageUrl = imageUrl;
      this.showProductImage = true;
    },
    getImageDetails(event) {
      if (event.target.tagName === 'IMG') {
        this.showProductFullImage(event.target.getAttribute('src').replace('afbeeldingenN', 'afbeeldingenM'));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(p > img) {
  cursor: zoom-in;
}
:deep(p > table > tbody) {
  cursor: zoom-in;
}
.product-detail {
  &__description {
    img {
      width: 300px !important;
      min-width: 300px !important;
      background: $white;
      border: solid 1px $gray;
      float: left;
      margin-top: 5px;
      cursor: pointer;
    }
    &__fullImage {
      img {
        width: 100%;
        max-height: 350px;
      }
    }
    .infoLeft {
      background: $white;
      margin-bottom: 24px;
      p {
        padding: 15px;
      }
    }
  }
  .material-design-icon {
    fill: $greenish;
  }
  h1,
  h2 {
    color: $teal-darkest;
    font-weight: 300;
    font-size: type-scale(2);
    line-height: line-height(2);
    margin-top: 0;
  }
  p,
  ul {
    color: $paragraph-color;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    margin-bottom: $paragraph-line-height;
    margin-top: 0;
  }
  ul {
    padding-left: 0;
    list-style-position: inside;
    text-transform: lowercase;
  }
  label {
    margin-left: 15px;
  }
  .continue-ordering {
    z-index: 999;
    position: fixed;
    top: 90%;
    right: 8%;
  }
}
.product {
  background: $white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.49);
  margin-bottom: 10px;
  padding: 10px 15px 10px 15px;
  overflow: hidden;

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    text-align: left;
    min-height: 80px;
    min-width: 80px;

    img {
      max-height: 80px;
      max-width: 80px;
      margin: auto;
      -ms-interpolation-mode: bicubic;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__name {
      color: $gray-darkest;
      font-weight: normal;
      font-size: 15px;
      line-height: line-height(1);
    }

    &__manufacturer {
      color: $gray-darkest;
      font-weight: normal;
      font-size: 12px;
      line-height: line-height(1);
    }

    &__props {
      margin: 0;
      padding: 0px 0px 0px 15px;
      list-style-type: disc;
      color: $gray;
      font-size: type-scale(-1);
      line-height: line-height(-1);
      font-style: italic;
      width: 100%;
      &__li {
        float: left;
        width: 50%;
        word-wrap: break-word;
      }
    }
  }

  &__action {
    margin-left: auto;
    font-weight: normal;
  }

  $transition-delay: 0.3s;
  $transition-time: 0.3s;
  $transition-time-height: 0.5s;

  &.transition-down-up-enter-active,
  &.transition-up-down-enter-active {
    transition: transform $transition-time $transition-delay ease-in, opacity $transition-time $transition-delay ease-in,
      max-height $transition-time-height $transition-delay ease-in;
  }

  &.transition-down-up-leave-active,
  &.transition-up-down-leave-active {
    transition: transform $transition-time ease-in, opacity $transition-time ease-in,
      max-height $transition-time-height ease-in;
  }

  &.transition-down-up-leave-to,
  &.transition-up-down-leave-to,
  &.transition-down-up-enter,
  &.transition-up-down-enter {
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }

  &.transition-down-up-leave-to,
  &.transition-down-up-enter {
    transform: translateY(80px);
  }

  &.transition-up-down-leave-to,
  &.transition-up-down-enter {
    transform: translateY(-80px);
  }
}
</style>
