import { fetchQuery } from '@/services/messaging/fetchQuery';
import router from '@/router'
import store from '@/store'

export async function navigateToAccountability(accountabilityId, asExtendLicense)
{
  const accountabilityTemplateType = await fetchQuery("GetAccountabilityTemplateType", {
    accountabilityId: accountabilityId
  });

  if (accountabilityTemplateType === "Single")
  {
    router.push('/verantwoording/' + accountabilityId)
  }
  else
  {
    if (asExtendLicense === true) {
      store.commit('accountability/setShowExtendLicensesBlock', true);
      router.push('/machtiging-overzicht/' + accountabilityId)
    }
    else {
      router.push('/machtiging/' + accountabilityId)
    }

  }
}
