import axios from 'axios'
import data from '@/components/sections/patientTransfer/data.json'

export default {
  namespaced: true,
  state: {
    data: {},
    prescibers: [],
    department:[],
    patientTransferPopUpTexts: {}
  },
  mutations: {
    setPatientTransfer (state, payload) {
      state.data = payload
    },
    setPrescribers (state, payload) {
      state.prescibers = payload
    },
    setDepartments (state, payload) {
      state.department = payload
    },
    setPatientTransferPopUpTexts (state, payload) {
      state.patientTransferPopUpTexts = payload
    }
  },
  actions: {
    initPatientTransfer ({ commit, state }, { name, birthdate }) {
      data.value.patientdata.name = name
      data.value.patientdata.birthdate = birthdate
      commit('setPatientTransfer', data)
    },
    submitTransferPatient ({ commit, state }, data) {
      return axios.post('MyPatients/Transfer', data)
    },
    fetchPrescribersOrDepartments ({ commit }, data) {
      axios.get('User/SearchUserOrDepartmentByEmail?searchText=' + encodeURIComponent(data)).then((response) => {
        if (response && response.data) {
          commit('setPrescribers', response.data.favoriteUser)
          commit('setDepartments', response.data.departmentList)
        }
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          commit('setPrescribers', error.response.data.Message)
        }
      })
    },
    fetchPatientTransferPopUpTexts ({ state, commit }) {
      axios.get('TransferredCustomer/TransferCustomerConfiguration').then((response) => {
        if (response && response.data) {
          commit('setPatientTransferPopUpTexts', response.data)
        }
      })
    },
    deleteUserOfTransferredCustomer ({ state }, details) {
      return axios.post('TransferredCustomer/UndoTransferredCustomer', details)
    }
  },
  getters: {
    getPatientTransfer: state => {
      return state.data
    },
    getPrescribersData: state => {
      return state.prescibers
    },
    getDepartmentsData: state => {
      return state.department
    },
    getPatientTransferPopUpTexts: state => {
      return state.patientTransferPopUpTexts
    }
  }
}
