<template>
  <div>
    <div v-if="gegevens && gegevens.PrescriberGegevens" class="container">
      <div v-if="showWarning" class="warningMessage" :class="{ 'fadeInDown animated': showWarning }">
        {{ gegevens.Teksten.PrescriberLabels.DisableEmailNotification }}
      </div>
      <form method="get" class="prescriber-details" @submit.prevent="validateBeforeSubmit">
        <v-heading :level="1">
          {{ gegevens.Teksten.PrescriberTitles.TitleBovenPagina }}
        </v-heading>
        <p class="editDetailsMessage">
          Indien u wijzigingen wilt doorgeven omtrent uw account zoals het aanpassen van een telefoonnummer, emailadres
          of BIG nummer, of als u graag toegevoegd wilt worden bij een andere afdeling, klik
          <a @click="showEditPopup">HIER</a>. De wijzigingen worden in de meeste gevallen binnen 5 werkdagen verwerkt.
        </p>
        <v-heading :level="3">
          {{ gegevens.Teksten.PrescriberTitles.SubTitle }}
        </v-heading>
        <div class="row grid-margin disabled">
          <div class="col">
            <v-select-group
              v-model="gegevens.PrescriberGegevens.GeslachtId"
              disabled
              :label="gegevens.Teksten.PrescriberLabels.Geslacht"
              selectid="GeslachtId"
              :rule="{
                required: true
              }"
              :initselected="gegevens.PrescriberGegevens.GeslachtId.toString()"
              @blur="checkValue()"
              @change="select($event, 'GeslachtId')"
            >
              <option
                v-for="(item, index) in gegevens.StamData.PrescriberGeslacht"
                :key="index"
                :data-index="index"
                :value="item.GeslachtId"
              >
                {{ item.Naam }}
              </option>
            </v-select-group>
          </div>
          <div class="col">
            <v-input-group-simple-2
              id="Voorletters"
              v-model:value="gegevens.PrescriberGegevens.Voorletters"
              disabled
              :rules="{
                required: true
              }"
              type="text"
              :label="gegevens.Teksten.PrescriberLabels.Voorletters"
              @input="saveInput"
              @blur="checkValue()"
            />
          </div>
          <div class="col">
            <v-input-group-simple-2
              id="Voornaam"
              v-model:value="gegevens.PrescriberGegevens.Voornaam"
              disabled
              :rules="{
                required: false
              }"
              type="text"
              :label="gegevens.Teksten.PrescriberLabels.Voornaam"
              @input="saveInput"
              @blur="checkValue()"
            />
          </div>
        </div>
        <div class="row disabled">
          <div class="col-xs-12">
            <div class="row grid-margin">
              <div class="col">
                <v-input-group-simple-2
                  id="Tussenvoegsel"
                  v-model:value="gegevens.PrescriberGegevens.Tussenvoegsel"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Tussenvoegsel"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="Achternaam"
                  v-model:value="gegevens.PrescriberGegevens.Achternaam"
                  disabled
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Achternaam"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="Geboortenaam"
                  v-model:value="gegevens.PrescriberGegevens.Geboortenaam"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Geboortenaam"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row disabled">
          <div class="col-xs-12">
            <div class="row grid-margin">
              <div class="col">
                <v-input-group-simple-2
                  id="GeboorteDatum"
                  v-model:value="birthDate"
                  disabled
                  :rules="{
                    required: false,
                    regex: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.GeboorteDatum"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="BigNummer"
                  v-model:value="gegevens.PrescriberGegevens.BigNummer"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.BigNummer"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="AGBNummer"
                  v-model:value="gegevens.PrescriberGegevens.AGBNummer"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.AGBNummer"
                  @input="saveInput"
                  @blur="checkValue()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-heading :level="3">
              {{ gegevens.Teksten.PrescriberTitles.Afdelingen }}
            </v-heading>
          </div>
          <v-button disabled primary md icon-left class="toevoegen" @click.prevent="insertAfdelingenRow">
            <plus-circle-icon />{{ gegevens.Teksten.PrescriberButtons.Toevoegen }}
          </v-button>
        </div>
        <div v-for="(afd, index) in deptsByCustomFilter" :key="index" class="row disabled">
          <div class="department_block" :class="index > 0 ? 'col-xs-11' : 'col-xs-12'">
            <div class="row grid-margin">
              <div class="col">
                <v-input-group-simple-2
                  :id="'NaamWerkgever' + index"
                  v-model:value="afd.NaamWerkgever"
                  disabled
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Naamwerkgever"
                  :overwrite-value="afd.NaamWerkgever"
                  @blur="checkStatusForDept(afd.NaamWerkgever, index, 'NaamWerkgever')"
                  @input="saveDepartmentInput(...arguments, index, 'NaamWerkgever')"
                />
                <div class="form-group-simple__details">
                  <div class="form-group-simple__messages">
                    <div v-show="IsNaamWerkgever[index] == true" class="form-group-simple__error">
                      * Dit veld is verplicht.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <v-input-group-simple-2
                  :id="'AGBWerkgever' + index"
                  v-model:value="afd.AGBWerkgever"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.AGBwerkgever"
                  :overwrite-value="afd.AGBWerkgever"
                  @blur="checkStatusForDept(afd.AGBWerkgever, index, 'AGBWerkgever')"
                  @input="saveDepartmentInput(...arguments, index, 'AGBWerkgever')"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  :id="'AfdelingenName' + index"
                  v-model:value="afd.AfdelingenName"
                  disabled
                  :rules="{
                    required: true
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Afdelingwerkgever"
                  :overwrite-value="afd.AfdelingenName"
                  @blur="checkStatusForDept(afd.AfdelingenName, index, 'AfdelingenName')"
                  @input="saveDepartmentInput(...arguments, index, 'AfdelingenName')"
                />
                <div class="form-group-simple__details">
                  <div class="form-group-simple__messages">
                    <div v-show="IsNaamafdelingen[index] == true" class="form-group-simple__error">
                      * Dit veld is verplicht.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col">
                <v-input-group-simple-2
                  :id="'AfdelingenEmail' + index"
                  v-model:value="afd.AfdelingenEmail"
                  disabled
                  :rules="{
                    required: false,
                    email: true,
                    regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Emailwerkgever"
                  :overwrite-value="afd.AfdelingenEmail"
                  @blur="checkStatusForDept(afd.AfdelingenEmail, index, 'AfdelingenEmail')"
                  @input="saveDepartmentInput(...arguments, index, 'AfdelingenEmail')"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  :id="'Depot' + index"
                  v-model:value="afd.Depot"
                  disabled
                  :rules="{
                    required: false
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.Depotwerkgever"
                  :overwrite-value="afd.Depot"
                  @blur="checkStatusForDept(afd.Depot, index, 'Depot')"
                  @input="saveDepartmentInput(...arguments, index, 'Depot')"
                />
              </div>
            </div>
            <div class="row grid-margin wanttoreceiveemails">
              <div class="col">
                <v-checkbox-group
                  :disabled="true"
                  :label="gegevens.Teksten.PrescriberLabels.Wanttoreceiveemails"
                  :model="afd.Wanttoreceiveemails === 0 ? false : true"
                  :inputid="'Wanttoreceiveemails' + index"
                  :initvalue="afd.Wanttoreceiveemails === 0 ? false : true"
                  :overwrite-value="afd.Wanttoreceiveemails === 0 ? false : true"
                  @click.prevent="emailNotification(index, 'Wanttoreceiveemails')"
                >
                  <pencil-circle-icon
                    class="pencil-circle"
                    :title="'Berichten ontvangen'"
                    @click.prevent.stop="emailNotificationEdit(index)"
                  />
                </v-checkbox-group>
              </div>
            </div>
          </div>
          <div v-if="index > 0" class="col-xs-1 grid-justify-content-center">
            <v-button disabled md hollow class="delete_button" @click.prevent="deleteDeptAlert(index)">
              <v-delete-icon />
            </v-button>
          </div>
        </div>
        <!--Show More/Less Functionality -->
        <div v-if="depts > filterCountDefault" class="row grid-justify-content-center">
          <div class="col-xs-12 col-md-12">
            <v-button hollow md class="filter-count-button" @click.prevent="toggleFilterCount">
              <template v-if="!filterCount">
                {{ filterCountButtonTextOptions.less }}
              </template>
              <template v-if="filterCount > 0">
                {{ filterCountButtonTextOptions.more }}&nbsp;
                <span v-if="depts.length > filterCountDefault">({{ depts.length }})</span>
              </template>
            </v-button>
          </div>
        </div>
        <generic-modal v-if="showPopUp">
          <template #header> Let op! </template>
          <template #body>
            <p>Weet u zeker dat u deze 'Afdeling en instelling' wilt verwijderen?</p>
          </template>
          <template #footer>
            <v-button md hollow transparent @click.prevent="closeModal"> Annuleren </v-button>
            <v-button md primary @click.prevent="deleteDeptSection"> Verwijderen </v-button>
          </template>
        </generic-modal>
        <div class="row disabled">
          <div class="col-xs-12">
            <div>
              <v-heading :level="3">
                {{ gegevens.Teksten.PrescriberTitles.ContactGegevens }}
              </v-heading>
              <v-heading :level="4" class="mobileNote">
                Voer het telefoonnummer in zónder speciale tekens zoals "+" of "-". Spaties zijn toegestaan mits ze op
                de juiste positie staan.
              </v-heading>
            </div>
          </div>
        </div>
        <div class="row disabled">
          <div class="col-xs-12">
            <div class="row grid-margin">
              <div class="col">
                <v-input-group-simple-2
                  id="TelefoonnummerVast"
                  v-model:value="gegevens.PrescriberGegevens.TelefoonnummerVast"
                  disabled
                  :rules="{
                    required: false,
                    numeric: true,
                    max: 9999999999
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.TelefoonnummerVast"
                  @blur="checkValue()"
                  @input="saveInput"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="TelefoonnummerMobiel"
                  v-model:value="gegevens.PrescriberGegevens.TelefoonnummerMobiel"
                  disabled
                  :rules="{
                    required: false,
                    numeric: true,
                    max: 9999999999
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.TelefoonnummerMobiel"
                  @blur="checkValue()"
                  @input="saveInput"
                />
              </div>
              <div class="col">
                <v-input-group-simple-2
                  id="EmailAdres"
                  v-model:value="gegevens.PrescriberGegevens.EmailAdres"
                  disabled
                  :rules="{
                    required: false,
                    email: true,
                    regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                  }"
                  type="text"
                  :label="gegevens.Teksten.PrescriberLabels.EmailAdres"
                  @blur="checkValue()"
                  @input="saveInput"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div>
              <v-heading :level="5">
                Wanneer u klikt op de 'Versturen' knop, worden uw gegevens ter verificatie verstuurd naar Mediq. Binnen
                enkele werkdagen worden uw wijzigingen doorgevoerd.
              </v-heading>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-7 col-md-3">
            <v-button tagelement="button" cta lg class="aanvraag_cls" disabled>
              {{ gegevens.Teksten.PrescriberButtons.Aanvraagversturen }}
            </v-button>
          </div>
          <div class="col-xs-1" />
          <div class="col-xs-4 col-md-3">
            <v-button disabled hollow lg @click.prevent="goBack">
              {{ gegevens.Teksten.PrescriberButtons.Annuleren }}
            </v-button>
          </div>
        </div>
        <generic-modal v-if="showDetailsEditPopup">
          <template #body>
            <v-heading :level="3"> Uw bericht: </v-heading>
            <div class="row grid-margin">
              <div class="col-xs-12">
                <v-input-group-simple-2
                  id="Onderwerp"
                  v-model:value="editDetails.subject"
                  :rules="{
                    required: true
                  }"
                  type="text"
                  label="Onderwerp"
                />
              </div>
            </div>
            <div class="row grid-margin">
              <div class="col-xs-12">
                <v-textarea-group
                  v-model="editDetails.body"
                  label="Uw bericht *"
                  inputid="Uw bericht"
                  @change="saveEditDetailsInput"
                />
                <div v-show="showPopupErrorMessage" class="form-group-simple__details">
                  <div class="form-group-simple__messages">
                    <div class="form-group-simple__error">* Dit veld is verplicht.</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <v-button md hollow transparent @click.prevent="closeModal"> Annuleren </v-button>
            <v-button md primary @click.prevent="sendDetails"> Versturen </v-button>
          </template>
        </generic-modal>
        <generic-modal v-if="showSuccessMessage" :has-close-button="true" @close="closeSuccessModal">
          <template #header> Bericht verstuurd! </template>
          <template #body>
            <div>
              <p>
                Het gegevensformulier is verstuurd en wordt spoedig in behandeling genomen. Voor meer informatie kijk
                ook op onze
                <router-link to="/service"> <span @click="closeSuccessModal">Veelgestelde vragen</span> </router-link>.
              </p>
            </div>
          </template>
          <template #footer>
            <v-button md primary @click="closeSuccessModal"> Ok </v-button>
          </template>
        </generic-modal>
      </form>
    </div>
  </div>
</template>
<script>
import router from '@/router';
import $ from 'jquery';
import { useStore, mapActions } from 'vuex';
import { computed } from 'vue';
import moment from 'moment';
import pencilCircleIcon from 'icons/PencilCircle';
import plusCircleIcon from 'icons/PlusCircleOutline';
import VDeleteIcon from 'icons/Delete';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import GenericModal from '@/components/shared/GenericModal';
import axios from 'axios';
import { useForm } from 'vee-validate';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'GegevensWijzigen',
  components: {
    pencilCircleIcon,
    plusCircleIcon,
    VDeleteIcon,
    VCheckboxGroup,
    GenericModal
  },
  setup() {
    const store = useStore();

    const gegevens = computed(() => store.getters['prescriber/getPrescriberData']);
    const pdf = computed(() => store.getters['prescriber/getPdf']);
    const setEmailNotificationByDept = (value) => store.commit('prescriber/setEmailNotificationByDept', value);

    return {
      store,
      gegevens,
      pdf,
      setEmailNotificationByDept,
      ...mapActions('prescriber', ['fetchPrescriberData', 'submitPrescriber'])
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      editDetails: {
        subject: 'Aanvraag gegevens wijziging voorschrijver',
        body: ''
      },
      showSuccessMessage: false,
      showPopupErrorMessage: false,
      showDetailsEditPopup: false,
      afdelingen: [],
      showWarning: false,
      originalAfdelingen: [],
      filterCountDefault: 2,
      filterCount: null,
      filterCountButtonTextOptions: {
        more: 'Toon meer',
        less: 'Toon minder'
      },
      saveButtonStatus: false,
      isDeptValidated: false,
      IsNaamWerkgever: [],
      IsNaamafdelingen: [],
      showAllDept: false,
      isVersturen: true,
      depts: 0,
      isDeptChanged: false,
      showPopUp: false,
      deptIndex: 0
    };
  },
  computed: {
    deptsByCustomFilter() {
      const computedDepts = this.afdelingen;
      if (computedDepts.length > 0) {
        return this.filterCount ? computedDepts.slice(0, this.filterCount) : computedDepts;
      } else {
        return [];
      }
    },
    birthDate: {
      get: function () {
        return this.gegevens.PrescriberGegevens.GeboorteDatum !== '0001-01-01T00:00:00' &&
          this.gegevens.PrescriberGegevens.GeboorteDatum !== ''
          ? moment(this.gegevens.PrescriberGegevens.GeboorteDatum).format('DD-MM-YYYY')
          : null;
      },
      set: function (newValue) {
        this.gegevens.PrescriberGegevens.GeboorteDatum = newValue;
      }
    }
  },
  created() {
    this.store.state.prescriber.emailNotificationStatusChanged = true;
    this.fetchPrescriberData().then(() => {
      this.originalAfdelingen = JSON.parse(JSON.stringify(this.gegevens.PrescriberAfdelingen));
      this.afdelingen = this.gegevens.PrescriberAfdelingen;
      this.depts = this.afdelingen.length;
    });
    this.filterCount = this.filterCountDefault;
  },
  methods: {
    saveEditDetailsInput(event) {
      const value = event.target.value;

      this.editDetails.body = value;
      if (value) {
        this.showPopupErrorMessage = false;
      } else {
        this.showPopupErrorMessage = true;
      }
    },
    sendDetails() {
      if (!this.editDetails.body) {
        this.showPopupErrorMessage = true;
        return;
      }
      axios
        .post('Registration/EmailUserDataChangeRequestToMdm', { content: this.editDetails.body })
        .then((response) => {
          if (response && response.data) {
            this.showDetailsEditPopup = false;
            this.showSuccessMessage = true;
          }
        });
    },
    showEditPopup() {
      this.showDetailsEditPopup = true;
    },
    toggleFilterCount() {
      this.filterCount = this.filterCount ? null : this.filterCountDefault;
    },
    closeModal() {
      this.showPopUp = false;
      this.showDetailsEditPopup = false;
      this.showPopupErrorMessage = false;
      this.showSuccessMessage = false;
    },
    closeSuccessModal() {
      this.showSuccessMessage = false;
      this.showAlert();
      router.push('/');
    },
    showAlert() {
      this.$emitter.emit('alert', {
        message:
          'Uw verzoek voor het wijzigen van uw gegevens zijn verzonden. Verwerking van de wijzigingen kunnen tot 5 werkdagen duren.'
      });
    },
    getButtonStatus() {
      return this.isVersturen && this.store.state.prescriber.emailNotificationStatusChanged;
    },
    checkValue() {
      $validateAllComponents(this).then((validated) => {
        this.isVersturen = !validated;
      });
    },
    checkStatusForDept(changedValue, index, key) {
      if (key === 'Wanttoreceiveemails') {
        this.gegevens.PrescriberAfdelingen[index][key] = changedValue;
      }
      if (this.originalAfdelingen.length === this.afdelingen.length) {
        if (changedValue !== this.originalAfdelingen[index][key]) {
          this.isVersturen = false;
          this.isDeptChanged = true;
        } else {
          this.isVersturen = true;
          this.isDeptChanged = false;
        }
      }
    },
    emailNotification(index, key) {
      return;
      this.store.state.prescriber.currentDeptIndex = index;
      if (this.deptsByCustomFilter[index][key] === 0) {
        this.$emitter.emit('showTodoEmailNotification');
      } else {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        this.setEmailNotificationByDept(0);
        this.showWarning = true;
        setTimeout(() => {
          this.showWarning = false;
        }, 3000);
      }
    },
    emailNotificationEdit(index) {
      return;
      this.store.state.prescriber.currentDeptIndex = index;
      this.$emitter.emit('showTodoEmailNotification');
    },
    sortedJson(js) {
      return js.sort((t1, t2) => (t1.NaamWerkgever < t2.NaamWerkgever ? -1 : 1));
    },
    insertAfdelingenRow() {
      this.afdelingen.push({
        NaamWerkgever: '',
        AGBWerkgever: '',
        AfdelingenName: '',
        AfdelingenEmail: '',
        Depot: '',
        Wanttoreceiveemails: 0,
        PpwRecId: ''
      });
      this.depts = this.depts + 1;
      this.showAllDept = true;
      this.isVersturen = false;
      this.afdelingen = this.sortedJson(this.afdelingen);
    },
    deleteDeptSection() {
      let index = this.deptIndex;
      this.afdelingen.splice(index, 1);
      this.depts = this.depts - 1;
      this.showAllDept = true;
      if (this.afdelingen.length !== this.originalAfdelingen.length) {
        this.isVersturen = false;
      } else {
        if (this.isDeptChanged === false) {
          this.isVersturen = true;
        }
      }
      this.deptIndex = 0;
      this.showPopUp = false;
    },
    deleteDeptAlert(index) {
      this.deptIndex = index;
      this.showPopUp = true;
    },
    goBack() {
      router.go(-1);
    },
    validateBeforeSubmit(e) {
      e.preventDefault();
      this.saveButtonStatus = true;
      this.ValidateOtherFields();
      this.validateAllFields(e.currentTarget.elements, this.originalAfdelingen, this.gegevens, this.showAllDept);
    },
    validateAllFields(elements, departments, gegevens, showAllDept) {
      return;
      $validateAllComponents(this)
        .then((validated) => {
          let args = { elements: elements, departments: departments, gegevens: gegevens, showdept: showAllDept };
          if (validated && this.isDeptValidated === false) {
            this.submitPrescriber(args);
            this.showAlert();
          } else {
            if (this.isDeptValidated === false) {
              this.submitPrescriber(args);
              this.showAlert();
            } else {
            }
          }
        })
        .catch(() => {});
    },
    ValidateOtherFields() {
      this.isDeptValidated = false;
      let afdelingenCount = 0;
      if (this.filterCount < this.afdelingen.length) {
        afdelingenCount = this.filterCount;
      } else {
        afdelingenCount = this.afdelingen.length;
      }
      for (var k = 0; k < afdelingenCount; k++) {
        this.IsNaamWerkgever[k] = false;
        this.IsNaamafdelingen[k] = false;
        let naamWerkgever = document.getElementById('NaamWerkgever' + k)
          ? document.getElementById('NaamWerkgever' + k).value
          : '';
        if (naamWerkgever === '') {
          this.isDeptValidated = true;
          this.IsNaamWerkgever[k] = true;
        } else {
          this.IsNaamWerkgever[k] = false;
        }
        let afdelingenName = document.getElementById('AfdelingenName' + k)
          ? document.getElementById('AfdelingenName' + k).value
          : '';
        if (afdelingenName === '') {
          this.isDeptValidated = true;
          this.IsNaamafdelingen[k] = true;
        } else {
          this.IsNaamafdelingen[k] = false;
        }
      }
    },
    saveInput(event) {
      this.gegevens.PrescriberGegevens[event.target.id] = event.target.value;
    },
    /**
     * @function select - Funtion that handles the select change
     */
    select(e, key) {
      this.gegevens.PrescriberGegevens[key] = parseInt(e.currentTarget.value, 10);
    },
    saveDepartmentInput(event, index, key) {
      this.gegevens.PrescriberAfdelingen[index][key] = event.target.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.prescriber-details {
  .editDetailsMessage {
    a {
      cursor: pointer;
      text-decoration: underline;
      color: $teal-darkest;
    }
  }
  :deep(.form-group) {
    padding: 0px;
    label {
      font-weight: 900;
      color: $gray-dark;
      position: initial;
      transform: none !important;
      line-height: normal;
      padding: 0;
      top: 0;
    }
    .form-group__icon {
      margin: 30px 10px 0 0;
    }
    .form-group__details:after,
    .form-group__details:before {
      content: none;
    }
  }
}
.mobileNote {
  margin-bottom: 5px !important;
  font-weight: 300 !important;
  font-weight: bold !important;
  font-size: 14px;
  color: $gray-darkest !important;
  line-height: 1.5em;
}
.header-row {
  .forSmallScreen {
    padding: 0px !important;
  }
  @media (min-width: 1020px) and (max-width: 1240px) {
    .hidden-md-and-down {
      display: none !important;
    }
  }
  @media (max-width: 490px) and (min-width: 410px) {
    .forSmallScreen {
      margin-left: auto !important;
    }
  }
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
    @include min-screen(grid-breakpoint($breakpoint-key)) {
      padding: grid-gutter($breakpoint-key) + 2 grid-gutter($breakpoint-key);
    }
  }
  .btn {
    display: flex;
    align-items: center;
  }
  > div {
    margin-bottom: 0;
    color: $gray-darkest;
    @each $breakpoint-key, $breakpoint-value in $grid-breakpoints {
      @include min-screen(grid-breakpoint($breakpoint-key)) {
        padding: 0 grid-gutter($breakpoint-key) / 2;
      }
    }
  }
  &:after {
    content: '';
    border: 10px solid transparent;
    border-top-color: $search-primary-background;
    position: absolute;
    top: 100%;
    left: 40px;
  }
}
.btn.btn--link.btn--inverted {
  color: $teal-darkest;
  justify-content: flex-end;
  white-space: nowrap;
}
.col.patient-col {
  display: flex;
  flex-direction: row;
}
.department_block {
  background: $white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.49);
  margin-bottom: 25px;
  padding: 20px 20px;
  overflow: hidden;
  border-radius: 3px;
}
.aanvraag_cls {
  width: 210px;
}
.delete_button {
  border: none;
}
.filter-count-button {
  align-self: center;
  margin: 20px 20px 20px 20px;
}
.popup {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.cancel {
  color: $cyan-med-dark;
  font-size: 14px;
  line-height: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  margin-top: 0.5rem;
  display: block;
  cursor: pointer;
}
.delete {
  display: flex;
  justify-content: center !important;
}
.wanttoreceiveemails {
  background-color: $teal-lighter;
  padding-top: 10px;
  margin-bottom: -37px;
  margin-left: -20px;
  margin-right: -20px;

  .material-design-icon {
    width: 35px;
    height: 35px;
    :deep(svg) {
      width: 35px !important;
      height: 35px !important;
    }
  }
}
.pencil-circle {
  fill: #006983;
  margin: -4px 0 0 0px;
  display: flex;
  float: right;
}
.warningMessage {
  font-size: 18px;
  font-weight: bold;
  color: #006983;
  display: flex;
  flex-direction: column;
  background: $white;
  position: sticky;
  z-index: 999;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  border-image-repeat: round;
  border-image-width: 0px 0px 20px 20px;
  background-clip: padding-box;
  margin: -35px 0px 10px -35px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -19px;
    height: 10px;
    background: $teal-darkest;

    @include min-screen(grid-breakpoint(sm)) {
      width: 101.5%;
    }
  }
}
.hideField {
  display: none;
}
:deep(.form-group.form-group--disabled) {
  background: none !important;
}
</style>
