<template>
  <section class="container news-overview">
    <v-heading :level="1">Nieuwsoverzicht</v-heading>
    <transition-group
      appear
      tag="section"
      name="fade"
      class="row grid-margin"
      @before-enter="beforeEnter"
      @enter="enter"
    >
      <article class="col-xs-12" v-for="(item, index) in newsItems" :key="item.Id" :data-index="index">
        <router-link :to="'/nieuwsdetail/' + item.Id" class="news--link">
          <v-heading :level="2">{{ item.Title }}</v-heading>
          <div class="time">{{ moment(item.PublishDate).locale('nl').format('DD MMMM YYYY') }}</div>
          <p class="time">{{ item.IntroText }}</p>
        </router-link>
      </article>
    </transition-group>
  </section>
</template>
<script>
import moment from 'moment';
import StaggerItems from '@/mixins/StaggerItems';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'v-news-overview',
  mixins: [StaggerItems],
  setup() {
    const store = useStore();
    const newsItems = computed(() => store.getters['news/getNewsItems']);
    const fetchNewsItems = async (value) => await store.dispatch('news/fetchNewsItems', value);

    return {
      newsItems,
      fetchNewsItems
    };
  },
  async created() {
    await this.fetchNewsItems(20);
  },
  methods: {
    moment(...args) {
      return moment(...args);
    }
  }
};
</script>
<style lang="scss" scoped>
.time {
  font-size: type-scale(0);
  line-height: line-height(0);
  color: $gray-darkest;
  margin-top: 0;
}

h2.heading {
  font-size: type-scale(2);
  line-height: line-height(2);
  font-weight: 900;
  color: $teal-darkest;
  margin: 0 0 0.4rem;
}

.news--link {
  text-decoration: none;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
}
</style>
