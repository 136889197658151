<template>
  <div v-if="practice.show" class="registrationPracticeDetails">
    <div class="row grid-align-center">
      <div class="col-md-6 registrationPracticeDetails__block">
        <div class="registrationPracticeDetails__block__header">Praktijk {{ index + 1 }}</div>
        <div class="registrationPracticeDetails__block__content">
          <div class="row grid-padding registrationPracticeDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.name.alias"
                v-model="practice.name"
                :initvalue="practice.name"
                :rule="formConfig.name.rule"
                :inputid="formConfig.name.ref + index"
                placeholder="Naam praktijk*"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.agbCode.alias"
                v-model="practice.agbCode"
                :initvalue="practice.agbCode"
                :rule="formConfig.agbCode.rule"
                :inputid="formConfig.agbCode.ref + index"
                placeholder="AGB-code praktijk*"
                @blur="validateField"
              />
              <div class="registrationPracticeDetails__block__content__link">
                <a href="https://www.agbcode.nl/Webzoeker" target="_blank" noopener>AGB-code opzoeken</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-registration-department-details
      v-for="(departmentDetails, deptIndex) in practice.departments"
      :key="'dept-' + deptIndex"
      ref="departmentDetails"
      :index="deptIndex"
      :parent-index="index"
      :department-details="departmentDetails"
      :show-delete="practice.departments.length > 1"
      type="practice"
    />
    <div class="row grid-align-center">
      <v-button hollow md class="registrationPracticeDetails__department" @click.prevent="addDepartment()">
        <v-plus-circle-icon class="registrationPracticeDetails__department__icon" /> Afdeling toevoegen
      </v-button>
    </div>
    <div class="row grid-align-center">
      <div class="col-md-6 registrationPracticeDetails__block">
        <div class="registrationPracticeDetails__block__address">Locatie adres</div>
        <div class="registrationPracticeDetails__block__content registrationPracticeDetails__block__addressContent">
          <div class="row grid-padding registrationPracticeDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.postalCode.alias"
                v-model="practice.postalCode"
                maxlength="7"
                :initvalue="practice.postalCode"
                :rule="formConfig.postalCode.rule"
                :inputid="formConfig.postalCode.ref + index"
                placeholder="Postcode (1234 AA)*"
                @blur="handleBlur"
              />
            </div>
            <div class="col-xs-3">
              <v-input-group-simple
                :reference="formConfig.houseNumber.alias"
                v-model="practice.houseNumber"
                :initvalue="practice.houseNumber"
                :rule="formConfig.houseNumber.rule"
                :inputid="formConfig.houseNumber.ref + index"
                placeholder="Huisnummer*"
                @blur="handleBlur"
              />
            </div>
            <div class="col-xs-3">
              <v-input-group-simple
                :reference="formConfig.houseNumberAddition.alias"
                v-model="practice.houseNumberAddition"
                :initvalue="practice.houseNumberAddition"
                :rule="formConfig.houseNumberAddition.rule"
                :inputid="formConfig.houseNumberAddition.ref + index"
                placeholder="Toevoeging"
                @blur="handleBlur"
              />
            </div>
          </div>
          <div class="row grid-padding registrationPracticeDetails__block__content__section">
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.streetName.alias"
                v-model="practice.streetName"
                :initvalue="practice.streetName"
                :rule="formConfig.streetName.rule"
                :inputid="formConfig.streetName.ref + index"
                :disabled="formConfig.streetName.disabled"
                :overwrite-value="practice.streetName"
                placeholder="Straatnaam*"
                :input-event="inputEvent"
                @blur="validateField"
              />
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.city.alias"
                v-model="practice.city"
                :initvalue="practice.city"
                :rule="formConfig.city.rule"
                :inputid="formConfig.city.ref + index"
                :disabled="formConfig.city.disabled"
                :overwrite-value="practice.city"
                placeholder="Plaats*"
                :input-event="inputEvent"
                @blur="validateField"
              />
            </div>
          </div>
          <div class="row grid-padding registrationPracticeDetails__block__content__section">
            <div class="col-xs-6 registrationPracticeDetails__selectLand">
              <v-select-group
                v-model="practice.country"
                selectid="(index+1)"
                :initselected="practice.country || 'NL'"
                @change="countryChange"
              >
                <option :key="'1'" :data-index="'1'" :value="'BE'">BE</option>
                <option :key="'2'" :data-index="'2'" :value="'DE'">DE</option>
                <option :key="'3'" :data-index="'3'" :value="'NL'">NL</option>
              </v-select-group>
            </div>
            <div class="col-xs-6">
              <v-input-group-simple
                :reference="formConfig.phoneNumber.alias"
                v-model="practice.phoneNumber"
                maxlength="10"
                :initvalue="practice.phoneNumber"
                :rule="formConfig.phoneNumber.rule"
                :inputid="formConfig.phoneNumber.ref + index"
                placeholder="Telefoonnummer werk*"
                @blur="validateField"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate';
import VPlusCircleIcon from 'icons/PlusCircleOutline';
import VRegistrationDepartmentDetails from './registrationDepartmentDetails';
import { useStore } from 'vuex';

export default {
  name: 'VRegistrationPracticeDetails',
  components: {
    VPlusCircleIcon,
    VRegistrationDepartmentDetails
  },
  props: {
    practice: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();

    const addDepartmentDetails = (value) => store.commit('prescriberRegistration/addDepartmentDetails', value);
    const setPrescriberRegistrationPracticeDetails = (value) =>
      store.commit('prescriberRegistration/setPrescriberRegistrationPracticeDetails', value);
    const addLocationDetails = (value) => store.commit('prescriberRegistration/addLocationDetails', value);
    const fetchAddressLookup = (value) => store.dispatch('prescriberRegistration/fetchAddressLookup', value);

    return {
      addDepartmentDetails,
      setPrescriberRegistrationPracticeDetails,
      addLocationDetails,
      fetchAddressLookup
    };
  },
  data() {
    const { validate } = useForm();
    return {
      focusedField: null,
      validate,
      formConfig: {},
      addressLookup: {
        City: null,
        Street: null
      },
      inputEvent: false
    };
  },
  computed: {},
  watch: {
    addressLookup() {
      if (this.addressLookup.Street && this.addressLookup.City) {
        const streetRef = 'streetName' + this.index;
        const cityRef = 'city' + this.index;
        this.validateField(streetRef, this.addressLookup.Street);
        this.validateField(cityRef, this.addressLookup.City);
      }
    }
  },
  created() {
    this.formConfig = {
      name: {
        model: this.practice.name,
        ref: 'name',
        label: 'Naam praktijk',
        attached: true,
        alias: 'Naam praktijk',
        rule: {
          required: true
        },
        error: false,
        errorMessage: 'Naam praktijk is verplicht.'
      },
      agbCode: {
        model: this.practice.agbCode,
        ref: 'agbCode',
        label: 'AGB-code praktijk',
        attached: true,
        alias: 'AGB-code praktijk',
        rule: {
          required: true,
          numeric: true,
          max: 99999999
        },
        error: false,
        errorMessage: 'AGB-code praktijk is verplicht.'
      },
      postalCode: {
        model: this.practice.postalCode,
        ref: 'postalCode',
        label: 'Postcode (1234 AA)*',
        attached: true,
        alias: 'Postcode',
        rule: {
          required: true,
          regex: /^\d{4}\s?[A-Za-z]{2}$/i
        },
        berule: {
          regex: [/^[0-9]{4}$/]
        },
        derule: {
          regex: [/^[0-9]{5}$/]
        },
        error: false,
        errorMessage: 'Postcode e-mailadres is verplicht.'
      },
      houseNumber: {
        model: this.practice.houseNumber,
        ref: 'houseNumber',
        label: 'Huisnummer*',
        attached: true,
        alias: 'Huisnummer',
        rule: {
          required: true
        },
        error: false,
        errorMessage: 'Huisnummer is verplicht.'
      },
      houseNumberAddition: {
        model: this.practice.houseNumberAddition,
        ref: 'houseNumberAddition',
        label: 'Toevoeging',
        attached: true,
        alias: 'Toevoeging',
        rule: {
          required: false
        },
        error: false,
        errorMessage: ''
      },
      streetName: {
        model: this.practice.streetName,
        ref: 'streetName',
        label: 'Straatnaam*',
        attached: true,
        alias: 'Straatnaam',
        rule: {
          required: true
        },
        error: false,
        errorMessage: 'Straatnaam is verplicht.',
        disabled: false
      },
      city: {
        model: this.practice.city,
        ref: 'city',
        label: 'Plaats*',
        attached: true,
        alias: 'Plaats',
        rule: {
          required: true
        },
        error: false,
        errorMessage: 'Plaats is verplicht.',
        disabled: false
      },
      country: {
        model: this.practice.country,
        ref: 'country',
        label: '',
        attached: true,
        rule: {
          required: false
        },
        error: false,
        errorMessage: ''
      },
      phoneNumber: {
        model: this.practice.phoneNumber,
        ref: 'phoneNumber',
        label: 'Telefoonnummer (vast)',
        attached: true,
        alias: 'Telefoonnummer',
        rule: {
          required: true,
          regex: /^0\d{9}$/
        },
        error: false,
        errorMessage: 'Telefoonnummer werk is verplicht.'
      }
    };
    if (this.practice.streetName) {
      this.formConfig.streetName.disabled = true;
      this.formConfig.city.disabled = true;
    }
  },
  methods: {
    addDepartment() {
      this.addDepartmentDetails({ index: this.index, type: 'practice' });
    },
    handleBlur(ref, model) {
      this.autoFillAddress();
      this.validateField(ref, model);
    },
    validateField(ref, model) {
      const actualReference = ref.substring(0, ref.length - 1);
      this.savePrescriberRegistrationPracticeDetails({
        id: this.index,
        details: {
          key: actualReference,
          value: model
        }
      });
    },
    async validateAllFields() {
      const result = await this.validate();
      if (result.valid) {
        this.setValidationStatus(true);
      } else {
        this.focusOnErrorField(result.results);
      }

      for (let i = 0; i < this.practice.departments.length; i++) {
        if (this.$refs && this.$refs.departmentDetails && this.$refs.departmentDetails[i]) {
          this.$refs.departmentDetails[i].validateAllFields();
        }
      }
    },
    focusOnErrorField(results) {
      const priorityFields = ['name', 'agbCode', 'postalCode', 'houseNumber', 'streetName', 'city', 'phoneNumber'];
      for (const fieldName of priorityFields) {
        if (!results[fieldName + this.index].valid) {
          this.focusedField = fieldName + this.index;
          break;
        }
      }
      if (this.focusedField) {
        document.querySelector('#' + this.focusedField).focus();
      }
    },
    savePrescriberRegistrationPracticeDetails(prescriberRegistrationPracticeDetails) {
      this.setPrescriberRegistrationPracticeDetails(prescriberRegistrationPracticeDetails);
    },
    addLocation() {
      this.addLocationDetails(this.index);
    },
    setValidationStatus(active) {
      this.savePrescriberRegistrationPracticeDetails({
        id: this.index,
        details: {
          key: 'validated',
          value: active
        }
      });
    },
    autoFillAddress() {
      let postalCode = document.querySelector('#postalCode' + this.index).value;
      postalCode = postalCode.replace(' ', '');
      let houseNumber = document.querySelector('#houseNumber' + this.index).value;
      if (postalCode.length === 6 && houseNumber.length !== 0) {
        this.fetchAddressLookup({
          postcode: postalCode,
          huisnummer: houseNumber,
          land: 'NL'
        })
          .then((response) => {
            this.addressLookup = response.data;
            if (this.addressLookup.City === null) {
              this.formConfig.streetName.disabled = false;
              this.formConfig.city.disabled = false;
            } else {
              this.formConfig.streetName.disabled = true;
              this.formConfig.city.disabled = true;
            }
          })
          .catch(() => {
            this.addressLookup = { City: null, Street: null };
            this.formConfig.streetName.disabled = false;
            this.formConfig.city.disabled = false;
          });
      } else {
        this.formConfig.streetName.disabled = false;
        this.formConfig.city.disabled = false;
      }
    },
    countryChange(e) {
      this.savePrescriberRegistrationPracticeDetails({
        id: this.index,
        details: {
          key: 'country',
          value: e.target.value
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.registrationPracticeDetails {
  &__department {
    margin: 5px auto 30px auto;
    &__icon {
      margin-right: 10px;
      :deep(svg) {
        fill: $teal-darkest !important;
      }
    }
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 30px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__address {
      background-color: $teal-darkest;
      font-size: 18px;
      color: $white;
      padding: 20px;
    }
    &__addressContent {
      background-color: $teal-lighter;
    }
    &__content {
      padding-top: 30px;
      padding-bottom: 10px;
      &__section {
        margin: 15px;
        span {
          float: left;
          margin: 12px 8px 0 0;
        }
        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }
      &__link {
        margin: 10px 0px 20px 0px;
      }
      a {
        font-size: 16px;
        color: $teal-darkest;
      }
    }
    &__icon {
      position: relative;
      margin: -40px 10px 10px 10px;
      float: right;
    }
  }
  &__selectLand {
    padding-left: 10px;
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  :deep(.form-group) {
    padding: 0px;
    width: 100%;
    &__icon {
      margin: -5px 15px 0px 0px;
    }
  }
  &__selectLand {
    :deep(.form-group) {
      &__details {
        display: none;
        &:before {
          background: none;
        }
        &:after {
          background: none;
        }
      }
    }
  }
  &__delete {
    fill: $teal-darkest;
    position: absolute;
    cursor: pointer;
    margin-top: -3%;
    right: 22%;
  }
}
</style>
