<template>
  <generic-modal v-if="canDisplayDepartmentSelector" :has-close-button="true" @close="closeModal">
    <template #header />
    <template #body>
      <div class="rte" v-html="resultsDepartment.WelkomTekst" />
      <form @submit="onSelectDepartment">
        <select v-model="ppwRecId" class="form-group">
          <option v-for="(item, index) in availableDepartments" :key="index" :data-index="index" :value="item.ppwRecId">
            {{ item.name }}
          </option>
        </select>
        <v-button class="department-submit-button" primary md @click="onSelectDepartment"> Selecteer </v-button>
      </form>
    </template>
  </generic-modal>
</template>
<script>
import GenericModal from '@/components/shared/GenericModal';
import { availableDepartments$, activePpwRecId$ } from '@/services';
import router from '@/router';
import { pushDepartmentChangedEvent } from '@/components/sections/navigation/departmentChangedEventStream';
import { computed, ref, watchEffect } from 'vue';
import { useStore, mapActions } from 'vuex';

export default {
  name: 'Departments',
  components: { GenericModal },
  setup() {
    const store = useStore();
    const availableDepartments = ref(null);
    const ppwRecId = ref(null);

    const resultsDepartment = computed(() => store.getters['login/getDepartmentResults']);

    const selectedDepartmentChange = (value) => store.dispatch('login/selectedDepartmentChange', value);
    const fetchDepartmentResults = () => store.dispatch('login/fetchDepartmentResults');
    const fetchResults = () => store.dispatch('navigation/fetchResults');

    // availableDepartments subscription
    // watchEffect(() => {
    //   availableDepartments.value = availableDepartments$.value;
    // });

    availableDepartments$.subscribe((value) => {
      availableDepartments.value = value;
    });

    activePpwRecId$.subscribe((value) => {
      ppwRecId.value = value;
    });

    const canDisplayDepartmentSelector = computed(() => {
      return availableDepartments.value && availableDepartments.value.length > 1;
    });

    return {
      resultsDepartment,
      availableDepartments,
      ppwRecId,
      selectedDepartmentChange,
      fetchDepartmentResults,
      fetchResults,
      canDisplayDepartmentSelector,
      ...mapActions('login', ['selectedDepartmentChange', 'fetchDepartmentResults'])
    };
  },
  data() {
    return {
      errorMessage: ''
    };
  },
  created() {
    if (!this.resultsDepartment) {
      this.fetchDepartmentResults();
    }
  },
  methods: {
    onSelectDepartment: function (e) {
      e.preventDefault();
      this.selectedDepartmentChange(this.ppwRecId).then((response) => {
        router.push('/');
        this.closeModal();
        this.fetchDepartmentResults();
        this.fetchResults();
        pushDepartmentChangedEvent({});
      });
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.department-submit-button {
  float: right;
  margin: 15px 0 15px 0;
}

.form-group {
  padding: 9px 0 9px;
  color: $gray-dark;
  width: 100%;
  border-radius: 3px;
  border: 1px solid $gray;
}
</style>
