import axios from 'axios'
export default {
  namespaced: true,
  state: {
    releaseInfo: {}
  },
  mutations: {
    setReleaseInfo (state, payload) {
      state.releaseInfo = payload
    }
  },
  actions: {
    submitReleaseForm ({ commit }, value) {
      let suggestion = {
        Suggestion: value.Comments,
        AnonymousUserEnabled: value.AnonymousEnabled
      }
      return axios.post('Release/PortalImprovementSuggestionEmail', suggestion).then((response) => {
        return response.data.Success
      }, (error) => {
        return error.response
      })
    },
    fetchReleaseInfo ({ commit }) {
      return axios.get('Release/GetLatestReleases').then((response) => {
        commit('setReleaseInfo', response.data)
      })
    }
  },
  getters: {
    getReleaseInfo: state => {
      return state.releaseInfo
    }
  }
}
