<template>
  <section class="contact">
    <div class="row">
      <div class="col-xs-12">
        <v-header title="Contactformulier" />
        <v-contact-form @success="showSuccessPopUp" />
      </div>
    </div>
    <generic-modal
      v-if="showSuccessMessage"
      :has-close-button="true"
      @close="closeSuccessMessage()"
    >
      <template #header>
        Bericht verstuurd!
      </template>
      <template #body>
        <div>
          <p>
            Het contactformulier is verstuurd en wordt binnen 2 tot 5 werkdagen in behandeling genomen. Voor meer informatie kijk ook op onze <router-link to="/service#Question">
              <span @click="closeSuccessMessage()">Veelgestelde vragen</span>
            </router-link>.
          </p>
        </div>
      </template>
      <template #footer>
        <v-button
          md
          primary
          @click="closeSuccessMessage()"
        >
          Ok
        </v-button>
      </template>
    </generic-modal>
  </section>
</template>

<script>
import VContactForm from './ContactForm.vue'
import GenericModal from '@/components/shared/GenericModal'
import VHeader from '@/components/shared/Header.vue'

export default {
  name: 'VPatienttransferbutton',
  components: {
    VContactForm,
    GenericModal,
    VHeader
  },
  data () {
    return {
      showSuccessMessage: false
    }
  },
  created () {
  },
  methods: {
    showSuccessPopUp () {
      this.showSuccessMessage = true
    },
    closeSuccessMessage () {
      this.showSuccessMessage = false
    }
  }
}
</script>