import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { localDateToJson, stringToLocalDate } from '../../../services/date.service'

export default {
  namespaced: true,
  state: {
    isDateValidated: false,
    isBSNNameValidated: false,
    isPostalcodeValidated: false,
    isPostalcodeActive: false,
    isSearchCollapsed: false,
    isFormActive: true,
    searchfields: {},
    results: '',
    multiplePatientsFound: false,
    patientSearchArguments: null,
    noPatientFound: false,
    patientFound: false,
    warningDetails: {}
  },
  mutations: {
    setIsDateValidated (state, payload) {
      state.isDateValidated = payload
    },
    setIsBSNNameValidated (state, payload) {
      state.isBSNNameValidated = payload
    },
    setIsSearchCollapsed (state, payload) {
      state.isSearchCollapsed = payload
    },
    setIsPostalcodeActive (state, payload) {
      state.isPostalcodeActive = !payload
    },
    toggleIsPostalcodeActive (state, payload) {
      state.isPostalcodeActive = !state.isPostalcodeActive
    },
    setFormActive (state, payload) {
      state.isFormActive = payload
    },
    setSearchfields (state, payload) {
      state.isFormActive = payload
    },
    setResults (state, payload) {
      state.results = payload
    },
    setMultiplePatientsFound (state, payload) {
      state.multiplePatientsFound = payload
    },
    setPatientSearchArguments(state, payload) {
      state.patientSearchArguments = payload;
    },
    setNoPatientFound (state, payload) {
      state.noPatientFound = payload
    },
    setPatientFound (state, payload) {
      state.patientFound = payload
    },
    setWarningDetails (state, payload) {
      state.warningDetails = payload
    }
  },
  actions: {
    init ({ commit }) {
      store.commit('patient/setNewPatientIsSaved', false)
      return axios.get('PatientSelecteren/Init')
          .then((response) => {
            if (response !== undefined) {
              commit('setResults', response.data)
              commit('setIsPostalcodeActive', response.data.IsUitgebreidZoeken)
            }
          })
    },
    fetchWarning ({ commit }) {
      return axios.get('SelecteerAfdeling/GetWarning').then((response) => {
        if (response.data) {
          let warningDetails = {
            'warning': response.data.Warning,
            'isUrgent': response.data.IsUrgent
          }
          commit('setWarningDetails', warningDetails)
        }
      })
    },
    fetchResults ({ commit, state }, data) {
      commit('setPatientSearchArguments', data);
      return axios.post('PatientSelecteren/Search', data).then((response) => {
        commit('setResults', response.data)
        if (response.data.Patienten === null || response.data.Patienten.length === 0) {
          commit('setMultiplePatientsFound', false)
          commit('setNoPatientFound', true)
          commit('setPatientFound', false)
        } else if (response.data.Patienten.length === 1 && !JSON.parse(response.config.data).Achternaam) {
          store.dispatch('patient/setActivePatient', response.data.Patienten[0].PatientId).then((result) => {
            commit('setMultiplePatientsFound', false)
            commit('setNoPatientFound', false)
            commit('setPatientFound', true)
            if (result.data.IsActiveCustomer) {
              router.push('/patientdashboard')
            } else {
              this.dispatch('patient/setEndpoints', 'edit')
              router.push('/patientgegevens')
            }
          })
        } else if (response.data.Patienten.length > 1 || JSON.parse(response.config.data).Achternaam) {
          commit('setMultiplePatientsFound', true)
          commit('setNoPatientFound', false)
          commit('setPatientFound', false)
        }
      }).catch((error) => {
        if (error.response.data.RedirectUrl === '/Gebruikersdashboard/Patientbestelling/Patient-invoeren' && error.response.status === 403) {
          this.dispatch('patient/setEndpoints', 'new')
          router.push('/patientgegevens')
        }
      })
    },
    searchAction ({ commit, state }, elements) {
      this.dispatch('search/formToJSON', elements).then((searchObject) => {
        const dob = stringToLocalDate(searchObject.dateOfBirth)
        const dobJson = localDateToJson(dob)

        searchObject.dateOfBirth = dobJson

        if (isNaN(searchObject.bsn)) {
          searchObject.Achternaam = searchObject.bsn
          delete searchObject.bsn
        }
        commit('setSearchfields', searchObject)
        this.dispatch('search/fetchResults', searchObject)
      })
    },
    resetSearch ({ commit }) {
      store.commit('patient/setPatientGegevens', {})
      commit('setMultiplePatientsFound', false)
      commit('setNoPatientFound', false)
      commit('setPatientFound', false)
    },
    formToJSON ({ commit, state }, data) {
      return [].reduce.call(data, (data, element) => {
        data[element.name] = element.value
        return data
      }, {})
    }
  },
  getters: {
    getIsDateValidated: state => {
      return state.isDateValidated
    },
    getIsBSNNameValidated: state => {
      return state.isBSNNameValidated
    },
    getIsSearchCollapsed: state => {
      return { isCollapsed: state.isSearchCollapsed }
    },
    getIsPostalcodeActive: state => {
      return state.isPostalcodeActive
    },
    getIsFormActive: state => {
      return state.isFormActive
    },
    getResults: state => {
      return state.results
    },
    getMultiplePatientsFound: state => {
      return state.multiplePatientsFound
    },
    getPatientSearchArguments: state => {
      return state.patientSearchArguments;
    },
    getNoPatientFound: state => {
      return state.noPatientFound
    },
    getPatientFound: state => {
      return state.patientFound
    },
    getSearchFields: state => {
      return state.searchfields
    },
    getWarningDetails: state => {
      return state.warningDetails
    }
  }
}
