import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import axios from 'axios';
import { currentUserIdentity$ } from '@/services/authentication/currentUserIdentity$';

export const availableDepartments$ = currentUserIdentity$.pipe(
  switchMap(_ => {
    if(_ !== null) {
      return axios.get('Nav/GetAvailableDepartments').then(response => response.data);
    }

    return Promise.resolve([]);
  }),
  publishReplay(1),
  refCount()
);
