<template>
  <section class="colleagueRegistration">
    <!-- Header section -->
    <form @submit.prevent="saveRegistration">
      <div class="row colleagueRegistration__header">
        <div class="colleagueRegistration__logo">
          <router-link to="/">
            <img alt="Mediq TEFA" src="@/static/img/mediq_logo.svg" />
          </router-link>
        </div>
        <div class="colleagueRegistration__title">Collega Registratie</div>
      </div>
      <div>
        <div class="row grid-align-center">
          <div class="col-md-12 colleagueRegistration__stepTitle">Heeft u een AGB, BIG- of KP-nummer?</div>
          <div class="col-md-6 colleagueRegistration__block">
            <div class="colleagueRegistration__block__header">AGB, BIG- of KP-nummer</div>
            <div class="colleagueRegistration__block__content" data-vv-scope="form1">
              <p>
                Het is verplicht een AGB. BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt of binnen een
                huisartspraktijk.
              </p>
              <div class="row">
                <div class="col-xs-12">
                  <v-input-group-simple-2
                    id="agbCode"
                    v-model:value="fields.agbCode"
                    :value="fields.agbCode"
                    :rules="isAgbRequired"
                    maxlength="11"
                    :overwrite-value="fields.agbCode"
                    data-vv-as="AGB-code"
                    placeholder="AGB-nummer"
                    :disabled="worksUnderWarrant"
                    @input="updateDetails('agbCode', $event)"
                  />
                  <div class="colleagueRegistration__block__content__link">
                    <a href="https://zoeken.bigregister.nl/zoeken/kenmerken" target="_blank" noopener>
                      AGB-nummer opzoeken
                    </a>
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                    placement: 'right'
                  }"
                >
                  <information-circle-outline title=" " class="colleagueRegistration__block__icon" />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <v-input-group-simple-2
                    id="bigCode"
                    v-model:value="fields.bigCode"
                    :rules="isBigRequired"
                    maxlength="11"
                    :overwrite-value="fields.bigCode"
                    data-vv-as="BIG-code"
                    placeholder="BIG-nummer"
                    :disabled="worksUnderWarrant"
                    @input="updateDetails('bigCode', $event)"
                  />
                  <div class="colleagueRegistration__block__content__link">
                    <a href="https://zoeken.bigregister.nl/zoeken/kenmerken" target="_blank" noopener>
                      BIG-nummer opzoeken
                    </a>
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                    placement: 'right'
                  }"
                >
                  <information-circle-outline title=" " class="colleagueRegistration__block__icon" />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <v-input-group-simple-2
                    id="kpNumber"
                    v-model:value="fields.kpNumber"
                    :rules="isKpRequired"
                    :overwrite-value="fields.kpNumber"
                    data-vv-as="KP-nummer"
                    placeholder="KP-nummer"
                    :disabled="worksUnderWarrant"
                    @input="updateDetails('kpNumber', $event)"
                  />
                  <div class="colleagueRegistration__block__content__link">
                    <a
                      href="https://www.kwaliteitsregisterparamedici.nl/raadplegenregister/default.aspx"
                      target="_blank"
                      noopener
                    >
                      KP-nummer opzoeken
                    </a>
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: 'Het is verplicht een AGB, BIG- of KP-nummer in te voeren, tenzij u onder volmacht werkt',
                    placement: 'right'
                  }"
                >
                  <information-circle-outline title=" " class="colleagueRegistration__block__icon" />
                </div>
              </div>
              <div class="row">
                <checkbox-group
                  id="userType"
                  inputid="userType"
                  v-model="worksUnderWarrant"
                  :initvalue="worksUnderWarrant"
                  :label="'Mijn collega bezit geen BIG- of KP-nummer en werkt onder volmacht.'"
                />
                <div v-show="worksUnderWarrant" class="col-xs-12">
                  <warning-message
                    warning="Let op!"
                    :message="'Om het account van uw collega te kunnen activeren dient diens volmachtgever eveneens een account te hebben geregistreerd.'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="colleagueDetails">
          <div class="row grid-align-center">
            <div class="col-md-6 colleagueDetails__block">
              <div class="colleagueDetails__block__header">(zakelijke) gegevens van uw collega</div>
              <p class="colleagueDetails__stepSubtitle">
                Voor een correcte gegevensverwerking en verificatie van de identiteit van uw collega hebben wij de
                correcte gegevens nodig. Indien de gegevens van uw collega niet correct zijn kunnen we zijn/haar account
                niet aanmaken.
              </p>
              <div class="colleagueDetails__block__content">
                <div class="row grid-padding colleagueDetails__block__content__section">
                  <div class="col-xs-4 colleagueDetails__block__content__section__gender">
                    <span class="form-group__radio">
                      <input
                        id="prescriberGenderMale"
                        :checked="fields.titleId === 1"
                        name="gender"
                        type="radio"
                        value="De heer"
                        @change="checkRadioAnswer(1)"
                      />
                      <label for="prescriberGenderMale"><span></span>De heer</label>
                    </span>
                    <span class="form-group__radio">
                      <input
                        id="prescriberGenderFemale"
                        :checked="prescriberRegistrationDetails.titleId === 2"
                        name="gender"
                        type="radio"
                        value="Mevrouw"
                        @change="checkRadioAnswer(2)"
                      />
                      <label for="prescriberGenderFemale"><span></span>Mevrouw</label>
                    </span>
                  </div>
                  <div class="col-xs-4">
                    <v-input-group-simple-2
                      id="initials"
                      v-model:value="fields.initials"
                      :rules="{
                        required: true
                      }"
                      data-vv-as="Voorletters"
                      placeholder="Voorletters*"
                      @input="updateDetails('initials', $event)"
                    />
                  </div>
                  <div class="col-xs-4">
                    <v-input-group-simple-2
                      id="firstName"
                      v-model:value="fields.firstName"
                      :rules="{
                        required: false
                      }"
                      maxlength="100"
                      data-vv-as="Voornaam"
                      placeholder="Voornaam"
                      @input="updateDetails('firstName', $event)"
                    />
                  </div>
                </div>
                <div class="row grid-padding colleagueDetails__block__content__section">
                  <div class="col-xs-4">
                    <v-select-group
                      v-model="prePositionCode"
                      selectid="prePositionCode"
                      :overwrite-value="rePositionCode"
                      :initselected="prePositionCode"
                      @change="select($event)"
                      placeholder="Selecteren Tussenvoegsel"
                    >
                      <option
                        v-for="(item, index) in prePositionsData"
                        :key="index"
                        :data-index="index"
                        :value="item.prePositionCode"
                      >
                        {{ item.prePosition }}
                      </option>
                    </v-select-group>
                  </div>
                  <div class="col-xs-8">
                    <v-input-group-simple-2
                      id="lastName"
                      v-model:value="fields.lastName"
                      :rules="{
                        required: true
                      }"
                      maxlength="100"
                      data-vv-as="Achternaam"
                      placeholder="Achternaam*"
                      @input="updateDetails('lastName', $event)"
                    />
                  </div>
                </div>
                <div class="row grid-padding colleagueDetails__block__content__section">
                  <div class="col-xs-7">
                    <v-input-group-simple-2
                      id="maidenName"
                      v-model:value="fields.maidenName"
                      :rules="{
                        required: false
                      }"
                      maxlength="100"
                      data-vv-as="Geboortenaam"
                      placeholder="Geboortenaam (vh meisjesnaam)"
                      @input="updateDetails('maidenName', $event)"
                    />
                  </div>
                  <div class="col-xs-5">
                    <v-input-group-simple-2
                      id="dateOfBirth"
                      v-model:value="fields.dateOfBirth"
                      :rules="{
                        required: false,
                        regex: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/
                      }"
                      maxlength="10"
                      data-vv-as="Geboortedatum"
                      placeholder="Geboortedatum (dd-mm-jjjj)"
                      @input="updateDetails('dateOfBirth', $event)"
                    />
                  </div>
                </div>
                <div class="row grid-padding colleagueDetails__block__content__section">
                  <div class="col-xs-6">
                    <v-input-group-simple-2
                      id="phoneNumber"
                      v-model:value="fields.phoneNumber"
                      maxlength="10"
                      :rules="{
                        required: false,
                        regex: /^0\d{9}$/
                      }"
                      data-vv-as="Telefoonnummer (vast)"
                      placeholder="Telefoonnummer (vast)"
                      @input="updateDetails('phoneNumber', $event)"
                    />
                  </div>
                  <div class="col-xs-6">
                    <v-input-group-simple-2
                      id="mobileNumber"
                      v-model:value="fields.mobileNumber"
                      maxlength="10"
                      :rules="{
                        required: false,
                        regex: /^0\d{9}$/
                      }"
                      data-vv-as="Telefoonnummer (mobiel)"
                      placeholder="Telefoonnummer (mobiel)"
                      @input="updateDetails('mobileNumber', $event)"
                    />
                  </div>
                </div>
                <div class="row grid-padding colleagueDetails__block__content__section">
                  <div class="col-xs-6">
                    <v-input-group-simple-2
                      id="email"
                      v-model:value="fields.email"
                      :rules="{
                        required: true,
                        email: true,
                        regex: /@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                      }"
                      data-vv-as="e-mailadres"
                      placeholder="E-mailadres van uw collega*"
                      @input="updateDetails('email', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <colleague-department-details
            ref="colleagueDepartmentDetails"
            v-if="prescriberRegistrationDetails.institutions"
            :works-under-warrant="worksUnderWarrant"
          />
        </div>
        <!-- Buttons section -->
        <div class="row grid-align-center colleagueRegistration__actions">
          <v-button
            class="btn--hollow btn--lg colleagueRegistration__actions__previous"
            @click.prevent="cancellationModal = true"
          >
            Ga terug
          </v-button>
          <v-button type="submit" class="btn--cta btn--lg"> Versturen </v-button>
        </div>
        <generic-modal v-if="cancellationModal">
          <template #header> Let op! </template>
          <template #body>
            <p>Wilt u het formulier annuleren? De gegevens worden niet opgeslagen.</p>
          </template>
          <template #footer>
            <v-button md hollow transparent @click.prevent="cancellationModal = false"> Ga terug </v-button>
            <v-button tag="button" md primary @click.prevent="$router.push('/mijnafdeling')">
              Terug naar mijn afdeling
            </v-button>
          </template>
        </generic-modal>
        <generic-modal v-if="successModal">
          <template #header> Aanvraag voor collega succesvol verzonden </template>
          <template #body>
            <p>{{ getColleagueRegistrationTexts.description }}</p>
          </template>
          <template #footer>
            <v-button md hollow transparent @click.prevent="$router.go(0)"> Nog een collega aanmelden </v-button>
            <v-button md primary @click.prevent="$router.push('/mijnafdeling')"> Afronden </v-button>
          </template>
        </generic-modal>
      </div>
    </form>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import GenericModal from '@/components/shared/GenericModal';
import InformationCircleOutline from 'icons/InformationOutline';
import CheckboxGroup from '@/components/shared/form/CheckboxGroup';
import ColleagueDepartmentDetails from '@/components/sections/prescriber/registration/colleagueRegistration/ColleagueDepartmentDetails';
import WarningMessage from '@/components/shared/WarningMessage';
import { $validateAllComponents } from '@/validate/index.js';

export default {
  name: 'ColleagueRegistration',
  components: {
    GenericModal,
    ColleagueDepartmentDetails,
    InformationCircleOutline,
    CheckboxGroup,
    WarningMessage
  },
  beforeRouteLeave(to, from, next) {
    this.resetPresciberRegistrationDetails();
    next();
  },
  setup() {
    const store = useStore();

    const prescriberRegistrationDetails = computed(
      () => store.getters['prescriberRegistration/getPrescriberRegistrationDetails']
    );
    const getColleagueRegistrationTexts = computed(
      () => store.getters['prescriberRegistration/getColleagueRegistrationTexts']
    );
    const prePositionsData = computed(() => store.getters['prescriberRegistration/getPrePositionsRecords']);

    const fields = computed(() => store.state.prescriberRegistration.prescriberRegistrationDetails);

    const isAgbRequired = computed(() => {
      return !fields.value.bigCode && !fields.value.kpNumber && !fields.value.agbCode && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999 }
        : { required: false };
    });

    const isBigRequired = computed(() => {
      return !fields.value.kpNumber && !fields.value.agbCode && !fields.value.bigCode && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999999 }
        : { required: false };
    });

    const isKpRequired = computed(() => {
      return !fields.value.bigCode && !fields.value.agbCode && !fields.value.kpNumber && !fields.value.userType
        ? { required: true, numeric: true, max: 99999999999 }
        : { required: false };
    });

    const saveColleagueRegistration = () => store.dispatch('prescriberRegistration/saveColleagueRegistration');
    const resetPresciberRegistrationDetails = () =>
      store.commit('prescriberRegistration/resetPresciberRegistrationDetails');

    store.dispatch('prescriberRegistration/fetchColleagueTexts');
    store.dispatch('prescriberRegistration/fetchPrePositions');

    const updateDetails = (key, event) => {
      store.commit('prescriberRegistration/updatePrescriberData', { key, value: event.target.value });
    };

    return {
      store,
      prescriberRegistrationDetails,
      fields,
      isAgbRequired,
      isBigRequired,
      isKpRequired,
      getColleagueRegistrationTexts,
      prePositionsData,
      saveColleagueRegistration,
      resetPresciberRegistrationDetails,
      updateDetails
    };
  },
  data() {
    const { validate } = useForm();

    return {
      validate,
      successModal: false,
      cancellationModal: false,
      worksUnderWarrant: false
    };
  },
  watch: {
    worksUnderWarrant(newVal) {
      this.store.commit('prescriberRegistration/updatePrescriberData', { key: 'userType', value: newVal });
    }
  },
  methods: {
    async saveRegistration() {
      const departmentValidate = await this.$refs.colleagueDepartmentDetails.validate();
      $validateAllComponents(this).then((validated) => {
        if (validated && departmentValidate.valid) {
          this.saveColleagueRegistration().then(() => {
            if (this.store.state.prescriberRegistration.registrationSuccess) {
              this.successModal = true;
            }
          });
        }
      });
    },
    select(event) {
      let value = event.target.value ? event.target.value : '';
      let text = event.target.value ? event.target.options[event.target.options.selectedIndex].text : '';
      this.store.commit('prescriberRegistration/updatePrescriberData', { key: 'prePosition', value: text });
      this.store.commit('prescriberRegistration/updatePrescriberData', { key: 'prePositionCode', value: value });
    },
    checkRadioAnswer(value) {
      this.store.commit('prescriberRegistration/updatePrescriberData', { key: 'titleId', value: value });
    }
  }
};
</script>

<style lang="scss" scoped>
.colleagueRegistration {
  position: absolute;
  background-color: $teal-lightest;
  color: $gray-darkest;
  width: 100%;
  .form-group {
    padding: 0px;
    label {
      display: none;
    }
  }
  &__logo {
    @include min-screen(grid-breakpoint(md)) {
      margin: 25px 0px 25px 50px;
    }
    img {
      display: block;
      max-height: 60px;
      margin: 0 auto;
      width: 161px;
      @include min-screen(grid-breakpoint(md)) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__header {
    background: $white;
  }
  &__progressSteps {
    margin: 24px 0px 24px 0px;
  }
  &__title {
    line-height: 5;
    padding: 10px;
    color: $gray-darkest;
    font-size: $base-font-size;
    font-weight: $heading-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  &__stepTitle {
    margin: 24px 0px 24px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 24px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 24px;
    }
    &__content {
      padding: 0px 24px 24px 24px;
      &__link {
        margin: 10px 0px 20px 0px;
      }
      a {
        font-size: 16px;
        color: $teal-darkest;
      }
    }
    &__icon {
      position: absolute;
      margin: 15px 10px 10px -30px;
    }
  }
  &__actions {
    float: right;
    margin: 1% 25% 5% 0;
    &__previous {
      margin-right: 24px;
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
  .checkbox-group {
    margin-bottom: 16px;
  }
}
.colleagueDetails {
  &__stepTitle {
    margin: 16px 0px 24px 0px;
    font-size: 28px;
    color: $teal-darkest;
    text-align: center;
  }
  &__stepSubtitle {
    padding: 0 40px 0 40px;
  }
  &__block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $white;
    margin: 0 auto 24px auto;
    &__header {
      background-color: $grayish-very-light;
      font-size: 18px;
      color: $grayish-very-dark;
      padding: 20px;
    }
    &__content {
      padding-bottom: 24px;
      &__section {
        margin: 0 15px 15px 15px;
        span {
          float: left;
          margin: 12px 8px 0 0;
        }
        &__gender {
          display: inline-block;
          padding-right: 16px !important;
        }
      }
    }
  }
  &__actions {
    float: right;
    margin: 1% 25% 5% 0;
    &__previous {
      margin-right: 24px;
    }
  }
  &__successMessage {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: $dark-green;
    text-align: center;
    margin: 24px 0px 24px 0px;
    &__icon {
      fill: $dark-green !important;
      display: inline-block;
      margin: 0px 5px 0px 0px;
      vertical-align: middle;
      :deep(svg) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .material-design-icon {
    fill: $teal-darkest;
  }
}
</style>
