let idleTimer = null;
let idleState = false;

export default {
  install(app, options = {}) {
    const { idleTime = 8 * 60 * 60 * 1000, onIdle = () => {} } = options;

    const resetTimer = () => {
      if (idleTimer) {
        clearTimeout(idleTimer);
      }
      idleTimer = setTimeout(() => {
        idleState = true;
        onIdle();
      }, idleTime);
    };

    const start = () => {
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
      window.addEventListener('click', resetTimer);
      resetTimer();
    };

    const stop = () => {
      clearTimeout(idleTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('click', resetTimer);
    };

    app.config.globalProperties.$idle = {
      start,
      stop,
    };

    app.mixin({
      mounted() {
        this.$idle.start();
      },
      beforeUnmount() {
        this.$idle.stop();
      },
    });
  },
};
