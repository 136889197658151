<template>
  <div :class="{ maindiv: !detailsPage }">
    <div class="row headertablerow">
      <div class="icon_alignment">
        <v-medical-bag-icon class="medicalbag_cls" />
      </div>
      <div class="col-xs-3 tbl_header">
        {{ data.Header }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 tabledata details" v-html="data.Text" />
    </div>
    <div class="row" :class="{ footertablerow: !detailsPage }">
      <div class="col-xs-12 tabledata">
        <v-checkbox-group
          :label="data.Footer"
          :inputid="'program-' + index"
          v-model="data.active"
          :initvalue="data.active"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';
import VMedicalBagIcon from 'icons/MedicalBag';

export default {
  name: 'CareProgrammmeRow',
  components: {
    VCheckboxGroup,
    VMedicalBagIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    index: {
      type: String,
      required: true
    },
    detailsPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  watch: {
    'data.active': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('careProgramAction', { key: this.index, active: this.data.active });
        }
      }
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.maindiv {
  border: solid 1px $gray-light;
}
.details {
  background: $white;
}
.tabledata {
  padding: 16px;
  color: #333333;
}
.footertablerow {
  border-top: solid 1px $gray-light;
  background-color: $teal-lighter;
}
.medicalbag_cls {
  height: 35px;
  fill: $teal-darkest;
  width: 25px;
}
.tbl_header {
  padding-top: 16px;
}
.icon_alignment {
  padding: 6px 8px;
}
</style>
