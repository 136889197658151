import { createStore } from 'vuex'; 
import searchStore from '@/components/sections/search/store.js'
import loginStore from '@/components/sections/login/store.js'
import navigationStore from '@/components/sections/navigation/store.js'
import patientStore from '@/components/sections/patient/store.js'
import patientTransferStore from '@/components/sections/patientTransfer/store.js'
import mypatientsStore from '@/components/sections/mypatients/store.js'
import patientOrderHistoryStore from '@/components/sections/patientOrderHistory/store.js'
import contactStore from '@/components/sections/contact/store.js'
import feedbackStore from '@/components/sections/feedback/store.js'
import accountabilityStore from '@/components/sections/patient/accountability/store.js'
import selectProductStore from '@/components/sections/patient/accountability/selectProduct/store.js'
import contentStore from '@/components/sections/content/store.js'
import newsStore from '@/components/sections/news/store.js'
import catalogStore from '@/components/sections/catalog/store.js'
import cartStore from '@/components/sections/catalog/cart/store.js'
import prescriberStore from '@/components/sections/prescriber/store.js'
import releasesStore from '@/components/sections/releases/store.js'
import prescriberRegistration from '@/components/sections/prescriber/registration/store.js'
import favoritePrescribers from '@/components/sections/prescriber/favoritePrescribers/store.js'
import pumpOverview from '@/components/sections/pumpOverview/store.js'

export default createStore({
  modules: {
    search: searchStore,
    login: loginStore,
    navigation: navigationStore,
    patient: patientStore,
    patienttransfer: patientTransferStore,
    patientOrderHistory: patientOrderHistoryStore,
    accountability: accountabilityStore,
    mypatients: mypatientsStore,
    selectProduct: selectProductStore,
    contact: contactStore,
    feedback: feedbackStore,
    content: contentStore,
    news: newsStore,
    catalog: catalogStore,
    cart: cartStore,
    prescriber: prescriberStore,
    releases: releasesStore,
    prescriberRegistration: prescriberRegistration,
    favoritePrescribers: favoritePrescribers,
    pumpOverview: pumpOverview
  }
})
