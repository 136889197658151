<template>
  <div tagelement="section" class="products-selected">
    <strong class="products-selected__daily-dosage">Geselecteerd voor totaal dagdosering</strong>
    <v-empty-message v-if="productsSelectedDailyDosage.length < 1">
      Geen producten geselecteerd voor totaal dagdosering
      <br />
      Selecteer producten hieronder om deze toe te voegen aan totaal dagdosering.
    </v-empty-message>
    <transition-group class="products-selected__list" tag="ul" :name="transitionDailyDosage">
      <v-product
        v-for="product in productsSelectedDailyDosage"
        :key="product.ProductId"
        :data="product"
        :alt="product.Smaakbox"
        selected
      >
        <template v-slot:checkbox>
          <div class="checkbox__wrapper">
            <v-checkbox-group
              v-if="product.DosageGroup"
              v-model="product.Basket_DosageGroup"
              :key="product.ProductId"
              :inputid="String(product.ProductId)"
              :initvalue="product.Basket_DosageGroup"
              @update:model-value="toggleDosageGroup(product.ProductId, transitions.downUp)"
            />
          </div>
        </template>
        <template v-slot:actions>
          <v-button
            class="button-delete"
            transparent
            data-icon="delete"
            @click.prevent="removeProduct(product.ProductId, transitions.upDown)"
          >
            <v-delete-icon />
          </v-button>
        </template>
      </v-product>
    </transition-group>
    <v-dosage-select
      v-if="!productsSelectedDailyDosage.length < 1"
      v-model="dailyDosage"
      class="products-selected__total-dosage-select"
      label="Totaal dagdosering:"
      inputid="dosage-select"
      :options="productsSelectedDailyDosage[0].UnitsOfMeasure"
      :initialvalue="
        typeof productDosageGroupAmount === 'string'
          ? productDosageGroupAmount
          : productsSelectedDailyDosage[0].UnitsOfMeasure[productDosageGroupAmount - 1]
      "
      @change="setDosageGroupAmount"
    />
    <hr />
    <strong class="products-selected__daily-dosage">Individuele dosering</strong>
    <v-empty-message v-if="productsSelectedNoDailyDosage.length < 1">
      Geen producten geselecteerd zonder totaal dagdosering
    </v-empty-message>
    <transition-group class="products-selected__list" tag="ul" name="transition-up-down">
      <v-product
        v-for="product in productsSelectedNoDailyDosage"
        :key="product.ProductId"
        :data="product"
        selected
        :alt="product.Smaakbox"
      >
        <template v-slot:checkbox>
          <div class="checkbox__wrapper">
            <v-checkbox-group
              v-if="product.DosageGroup"
              v-model="product.Basket_DosageGroup"
              :key="product.ProductId"
              :inputid="String(product.ProductId)"
              :initvalue="product.Basket_DosageGroup"
              @update:model-value="toggleDosageGroup(product.ProductId, transitions.downUp)"
            />
          </div>
        </template>
        <template v-slot:actions>
          <v-dosage-select
            :initialvalue="
              typeof product.Basket_DosageAmount === 'string'
                ? product.Basket_DosageAmount
                : product.UnitsOfMeasure[product.Basket_DosageAmount - 1]
            "
            :inputid="String(product.ProductId)"
            :options="product.UnitsOfMeasure"
            @change="setDosageAmount"
          />
          <v-button
            class="button-delete"
            transparent
            data-icon="delete"
            @click.prevent="removeProduct(product.ProductId, transitions.downUp)"
          >
            <v-delete-icon />
          </v-button>
        </template>
      </v-product>
    </transition-group>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import VProduct from './Product';
import VEmptyMessage from './EmptyMessage';
import VDeleteIcon from 'icons/Delete';
import VDosageSelect from './DosageSelect';
import VCheckboxGroup from '@/components/shared/form/CheckboxGroup';

export default {
  name: 'ProductsSelected',
  components: {
    VProduct,
    VDeleteIcon,
    VEmptyMessage,
    VDosageSelect,
    VCheckboxGroup
  },
  props: {
    productsSelectedDailyDosage: {
      type: Array,
      default: () => []
    },
    productsSelectedNoDailyDosage: {
      type: Array,
      default: () => []
    },
    productSelectionAccountabilityId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const store = useStore();

    const toggleBasketDosageGroup = computed(() => store.getters['selectProduct/toggleBasketDosageGroup']);
    const originalProductIds = computed(() => store.getters['selectProduct/getOriginalProductIds']);
    const productDosageGroupAmount = computed(() => store.getters['selectProduct/getProductDosageGroupAmount']);

    const toggleDosageGroupStore = (value) => store.dispatch('selectProduct/toggleDosageGroup', value);
    const addDosageGroupAmountToProducts = () => store.dispatch('selectProduct/addDosageGroupAmountToProducts');
    const postProductsSelected = (value) => store.dispatch('selectProduct/postProductsSelected', value);
    const removeProductSelected = (value) => store.dispatch('selectProduct/removeProductSelected', value);
    const setDosageAmountStore = (value) => store.dispatch('selectProduct/setDosageAmount', value);
    const setDosageGroupAmountStore = (value) => store.dispatch('selectProduct/setDosageGroupAmount', value);
    const moveProductStore = (value) => store.dispatch('selectProduct/moveProduct', value);

    return {
      toggleBasketDosageGroup,
      originalProductIds,
      productDosageGroupAmount,
      toggleDosageGroupStore,
      addDosageGroupAmountToProducts,
      postProductsSelected,
      removeProductSelected,
      setDosageAmountStore,
      setDosageGroupAmountStore,
      moveProductStore
    };
  },
  data() {
    return {
      /**
       * @member {string} transitionDailyDosage - String used by Vuejs to dynamically change transitions
       */
      transitionDailyDosage: 'transition-down-up',
      /**
       * @member {object} transitions
       * @property {string} transitions.upDown - Transition up out the list and then down into the target list
       * @property {string} transitions.upDown - Transition down out the list and then up into the target list
       * @desc The strings used are CSS classes that can be find in the Product component
       */
      transitions: {
        upDown: 'transition-up-down',
        downUp: 'transition-down-up'
      },
      /**
       * @member {number} dailydosage - Dailydosage that is currently selected, default: 1
       */
      dailyDosage: 1
    };
  },
  methods: {
    /**
     * @function toggleDosageGroup
     * @param {number} productId - Id of the product
     * @param {string} transition - The transition of the product that is removed
     * @desc Toggle the DosageGroup property of a product between true and false.
     * When adding/removing a product to the DosageGroup set the DosageGroupAmount
     * equal to the selected daily dosage amount.
     */
    toggleDosageGroup(productId, transition) {
      this.transitionDailyDosage = transition;
      this.addDosageGroupAmountToProducts();
      this.postProductsSelected(this.productSelectionAccountabilityId);
    },
    /**
     * @function removeProduct
     * @param {number} productId - Id of the product
     * @param {string} transition - The transition of the product that is removed
     * @desc Removes the product from the removeProductSelected array or move it to the products array
     */
    removeProduct(productId, transition) {
      this.transitionDailyDosage = transition;
      //  Evaluate if the product that needs to be removed was in the original products list.
      //  If not the product needs to be removed cause there is no list with the right product group
      if (this.originalProductIds.includes(productId)) {
        this.moveProduct(productId, 'productsSelected', 'products');
        this.postProductsSelected(this.productSelectionAccountabilityId);
      } else {
        // Remove the product from the removeProductSelected array
        this.removeProductSelected(productId);
      }
    },
    setDosageAmount(event) {
      this.setDosageAmountStore({
        productId: parseInt(event.target.id, 10),
        dosage: event.target.value
      });
      this.postProductsSelected(this.productSelectionAccountabilityId);
    },
    /**
     * @function setDosageGroupAmount
     * @param {number} dosage - Dosage of the product
     * @desc Set the dosage group amount.
     * When adding/removing a product to the DosageGroup set the DosageGroupAmount
     * equal to the selected daily dosage amount.
     */
    setDosageGroupAmount(event) {
      this.setDosageGroupAmountStore(event.target.value);
      this.addDosageGroupAmountToProducts();
      this.postProductsSelected(this.productSelectionAccountabilityId);
    },
    /**
     * @function moveProduct
     * @param {number} productId - productId of the product that needs to be moved
     * @param {string} from - Array in state from which the product to be moved
     * @param {string} to - Array in state where the product needs to be moved to
     */
    moveProduct(productId, from, to) {
      this.moveProductStore({
        productId: productId,
        from: from,
        to: to
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.products-selected {
  background: $white;
  border: 1px solid $gray-light;
  padding: 16px;
  &__daily-dosage {
    display: block;
    color: $gray-darkest;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__list {
    padding: 0;
    margin: 0;
    min-height: 100px;
  }

  hr {
    border-top: solid 2px $gray-dark;
  }

  .checkbox__wrapper {
    min-width: 28px;
  }

  .button-delete {
    padding: 0;
  }
}
</style>
