import { getFromWebAPi } from '@/services/messaging/fetchQuery';
import router from '@/router';

export async function getOsaDropDownDetails() {
  let type = router.currentRoute.value.name;
  let computedApiCall = 'osa/GetEvaluationDropDowns';
  if (type === 'readout') {
    computedApiCall = 'osa/GetReadoutDropDowns';
  }
  if (type === 'posa-spt') {
    computedApiCall = 'EvaluationSpt/GetEvaluationSptDropDowns';
  }
  return await getFromWebAPi(computedApiCall).then((response) => {
    let result = [];
    if (response && response.Success) {
      result =
        type === 'evaluation'
          ? response.EvaluationDropDowns
          : type === 'readout'
          ? response.ReadoutDropDowns
          : response.EvaluationSptDropDowns;
      result.avgHoursSleep = [];
      result.avgDaysSleep = [];
      result.computedLicenses = [];
      if (result.Licenses.length === 0) {
        result.computedLicenses.push({ text: 'Geen machtiging beschikbaar', value: 0 });
      } else {
        result.Licenses.forEach((availableLicense) => {
          let licenseDetail = {
            text: availableLicense.Description + ' ( ' + availableLicense.LicenseId + ' )',
            value: availableLicense.LicenseId
          };
          result.computedLicenses.push(licenseDetail);
        });
      }
      for (let avgSleep = 0; avgSleep <= 10; avgSleep++) {
        result.avgHoursSleep.push(avgSleep);
        if (avgSleep < 8) {
          result.avgDaysSleep.push(avgSleep);
        }
      }
      result.rampSettingsMinutes = ['Auto'];
      for (let rampMinutes = 0; rampMinutes <= 30; rampMinutes++) {
        result.rampSettingsMinutes.push(rampMinutes);
      }
      result.minimalDisasterPressure = ['4', '4,5', '5', '5,5', '6', '6,5', '7', '7,5', 8];
    }
    return result;
  });
}
